/** @format */

import {
    Avatar,
    Box,
    Button,
    CardContent,
    CardHeader,
    CircularProgress,
    DialogContent,
    FormControl,
    FormLabel,
    Grid,
    Hidden,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    TextField,
} from '@mui/material';
import {
    Message,
    OrderStatus,
    OrderType,
    Portfolio,
    PortfolioAPIClient,
    PortfolioIdentifier,
    Status,
    Stock,
    TradeOrder,
    TradeType,
} from 'api';
import React, { Component } from 'react';
import Card from '@mui/material/Card';
import { SelectField } from 'containers/common/SelectField';
import { LabelAndValue } from 'containers/common/LabelAndValue';
import InflyDialog from 'containers/common/InflyDialog';
import { AppContext } from 'util/appContext';
import withScreenSize from 'containers/HOC/WithScreenSize';
import { InflyMessage } from 'containers/common/InflyMessage';
import Typography from '@mui/material/Typography';
import { InflyIcon } from 'containers/common/InflyIcon';
import { ScreenSizeProp } from 'util/commonProps';
import { PortfolioSelect } from 'containers/common/PortfolioSelect';
import { ITextField } from 'containers/common/ITextField';
import CommonUtil from 'util/CommonUtil';

interface TradeStockOnlyProps extends ScreenSizeProp {
    symbol: string[];
    onSuccessReturn: (message: Message) => void;
    onCancel: () => void;
}

interface TradeStockOnlyState {
    portfolio?: Portfolio;
    tradeOrder: TradeOrder;
    message?: Message;
    orderSubmitted: boolean;
    orderStatus: OrderStatus[];
}

class MultiStockTrade extends Component<TradeStockOnlyProps, TradeStockOnlyState> {
    portfolioApiClient: PortfolioAPIClient;

    constructor(props: TradeStockOnlyProps) {
        super(props);
        this.portfolioApiClient = AppContext.getInstance().portfolioApiClient;
        this.state = {
            tradeOrder: new TradeOrder(),
            orderSubmitted: false,
            orderStatus: [],
        };
    }

    setTradeAmount = (amount?: number) => {
        let tradeOrder = this.state.tradeOrder;
        tradeOrder.maxAmount = amount;
        this.setState({ tradeOrder });
    };

    setTradeType = (tradeType: TradeType) => {
        let tradeOrder = this.state.tradeOrder;
        tradeOrder.tradeType = tradeType;
        this.setState({ tradeOrder });
    };

    setOrderType = (orderType: OrderType) => {
        let tradeOrder = this.state.tradeOrder;
        tradeOrder.orderType = orderType;
        this.setState({ tradeOrder });
    };

    setStopPrice = (stopPrice?: number) => {
        let tradeOrder = this.state.tradeOrder;
        tradeOrder.stopPrice = stopPrice;
        this.setState({ tradeOrder });
    };

    setLimitPrice = (limitPrice?: number) => {
        let tradeOrder = this.state.tradeOrder;
        tradeOrder.limitPrice = limitPrice;
        this.setState({ tradeOrder });
    };

    onPortfolioSelect = async (portfolioIdentifier: PortfolioIdentifier) => {
        let portfolio = await this.portfolioApiClient.getPortfolio(
            portfolioIdentifier.portfolioId,
            portfolioIdentifier.brokerType
        );
        let tradeOrder = this.state.tradeOrder;
        tradeOrder.portfolioId = portfolioIdentifier.portfolioId;
        tradeOrder.brokerType = portfolioIdentifier.brokerType;
        this.setState({ portfolio, tradeOrder });
        if (portfolio.buyingPower === undefined) {
            this.setState({
                message: Message.error(
                    'Portfolio is in bad state. Please select a different portfolio'
                ),
            });
        } else if (portfolio.buyingPower < 10) {
            this.setState({
                message: Message.error(
                    'This portfolio has less than $10 in buying power. Please select a different portfolio'
                ),
            });
        }
    };

    onDialogExecute = async (event: any) => {
        this.setState({ orderSubmitted: true });
        let tradeOrder = this.state.tradeOrder;
        let orderStatus: OrderStatus[] = this.state.orderStatus;
        for (let symbol of this.props.symbol) {
            try {
                let stock = Stock.getInstance(symbol);
                tradeOrder.security = stock;
                let status: OrderStatus = await this.portfolioApiClient.doTrade(
                    tradeOrder.portfolioId,
                    tradeOrder.brokerType,
                    tradeOrder
                );
                orderStatus.push(status);
                this.setState({ orderStatus });
            } catch (error) {
                let status = new OrderStatus();
                status.status = Status.ERROR;
                status.message = Message.fromError(error);
                orderStatus.push(status);
                this.setState({ orderStatus });
            }
        }
    };


    render() {
        const tradeTypeOptions = Object.keys(TradeType)
            .filter((option) => option !== 'SELL' && option !== 'COVER')
            .map((t) => {
                return { label: t.replace('_', ' '), value: t.toString() };
            });
        const orderTypeOptions = Object.keys(OrderType)
            .map((o) => {
                return { label: o.replace('_', ' '), value: o.toString() };
            })
            .filter((opt) => opt.value !== OrderType.CUSTOM_CONDITION);

        const tradeOrder = this.state.tradeOrder;
        const portfolio = this.state.portfolio;

        let isPortfolioGood = portfolio?.buyingPower && portfolio.buyingPower > 10;

        const isMobile = this.props.isMobile;
        return (
            <>
                <InflyDialog
                    open={true}
                    okText={!this.state.orderSubmitted ? 'Submit' : 'Close'}
                    onExecute={
                        !this.state.orderSubmitted ? this.onDialogExecute : this.props.onCancel
                    }
                    disabled={!isPortfolioGood}
                    onClose={this.props.onCancel}
                    title={`Trade Stocks`}
                >
                    <DialogContent>
                        <Stack gap={2}>
                            <InflyMessage
                                message={this.state.message}
                                onClose={() => this.setState({ message: undefined })}
                            />

                            <Typography>Trading {this.props.symbol.join(',')}</Typography>

                            <Box display={'flex'} justifyContent='space-between' gap={2}>
                                <Stack gap={2}>
                                    <PortfolioSelect
                                        currentSelection={this.state.portfolio?.toPortfolioIdentifier()}
                                        onPortfolioSelect={this.onPortfolioSelect}
                                    />

                                    <ITextField
                                        label='Max Amount'
                                        disabled={!isPortfolioGood}
                                        type={'number'}
                                        value={tradeOrder.maxAmount}
                                        onChange={this.setTradeAmount}
                                    />

                                    <SelectField
                                        id='TradeTypeOptions'
                                        label={'Trade Type'}
                                        disabled={!isPortfolioGood}
                                        value={tradeOrder.tradeType}
                                        onChange={(event: any) => {
                                            this.setTradeType(TradeType[event.target.value]);
                                        }}
                                        key='TradeTypeOptions_select'
                                        options={tradeTypeOptions}
                                    />

                                    <SelectField
                                        id='orderTypeOptions'
                                        disabled={!isPortfolioGood}
                                        label={'Order Type'}
                                        value={tradeOrder.orderType}
                                        onChange={(event: any) => {
                                            this.setOrderType(OrderType[event.target.value]);
                                        }}
                                        key='orderTypeOptions_select'
                                        options={orderTypeOptions}
                                    />

                                    {(tradeOrder.orderType === OrderType.STOP_ORDER ||
                                        tradeOrder.orderType ===
                                        OrderType.ONE_CANCEL_OTHER) && (
                                            <ITextField
                                                label='Stop Price'
                                                type={'number'}
                                                value={tradeOrder.stopPrice}
                                                onChange={this.setStopPrice}
                                            />
                                        )}

                                    {(tradeOrder.orderType === OrderType.LIMIT_ORDER ||
                                        tradeOrder.orderType ===
                                        OrderType.ONE_CANCEL_OTHER) && (
                                            <ITextField
                                                label='Limit Price'
                                                type={'number'}
                                                value={tradeOrder.limitPrice}
                                                onChange={this.setLimitPrice}
                                            />
                                        )}
                                </Stack>

                                {portfolio && (
                                    <Card>
                                        <CardHeader
                                            title={portfolio.name}
                                            titleTypographyProps={{ variant: 'body1' }}
                                        />
                                        <CardContent>
                                            <LabelAndValue
                                                label={'Available Cash'}
                                                value={portfolio.cashBalance}
                                            />
                                            <LabelAndValue
                                                label={'Buying Power'}
                                                value={portfolio.buyingPower}
                                            />
                                        </CardContent>
                                    </Card>
                                )}
                            </Box>

                            {this.state.orderSubmitted && (
                                <Card>
                                    <CardHeader
                                        title={'Status'}
                                        titleTypographyProps={{ variant: 'h5' }}
                                    />
                                    <CardContent>
                                        <List>
                                            {this.props.symbol.map((item, index) => {
                                                let color:
                                                    | 'action'
                                                    | 'disabled'
                                                    | 'inherit'
                                                    | 'primary'
                                                    | 'secondary'
                                                    | 'error'
                                                    | 'info'
                                                    | 'success'
                                                    | 'warning' = 'primary';
                                                if (this.state.orderStatus[index]) {
                                                    if (
                                                        this.state.orderStatus[index].status ===
                                                        Status.EXPIRED ||
                                                        this.state.orderStatus[index].status ===
                                                        Status.ERROR
                                                    ) {
                                                        color = 'error';
                                                    }
                                                    if (
                                                        this.state.orderStatus[index].status ===
                                                        Status.SCHEDULED
                                                    ) {
                                                        color = 'warning';
                                                    }
                                                }

                                                return (
                                                    <ListItem
                                                        alignItems='flex-start'
                                                        key={index}
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar
                                                                sx={(theme) => ({
                                                                    bgcolor:
                                                                        theme.palette.mode ===
                                                                            'light'
                                                                            ? theme.palette
                                                                                .common.white
                                                                            : theme.palette
                                                                                .common.black,
                                                                })}
                                                            >
                                                                {this.state.orderStatus[
                                                                    index
                                                                ] && (
                                                                        <InflyIcon
                                                                            name={this.state.orderStatus[
                                                                                index
                                                                            ].status.toLocaleLowerCase()}
                                                                            color={color}
                                                                        />
                                                                    )}
                                                                {!this.state.orderStatus[
                                                                    index
                                                                ] && (
                                                                        <CircularProgress
                                                                            size={15}
                                                                        />
                                                                    )}
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <Typography component={'b'}>
                                                                    {' '}
                                                                    Submitting{' '}
                                                                    {
                                                                        this.state.tradeOrder
                                                                            .tradeType
                                                                    }{' '}
                                                                    order for {item}{' '}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                this.state.orderStatus[index] &&
                                                                this.state.orderStatus[
                                                                    index
                                                                ]?.getDefaultMessage() && (
                                                                    <Typography
                                                                        variant='body2'
                                                                        sx={(theme) => ({
                                                                            color: theme
                                                                                .palette[
                                                                                color ?? 'info'
                                                                            ].main,
                                                                        })}
                                                                    >
                                                                        {
                                                                            this.state.orderStatus[
                                                                                index
                                                                            ]?.getDefaultMessage()
                                                                                .message
                                                                        }
                                                                    </Typography>
                                                                )
                                                            }
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </CardContent>
                                </Card>
                            )}
                        </Stack>
                    </DialogContent>
                </InflyDialog>

            </>
        );
    }
}

export default withScreenSize(MultiStockTrade);
