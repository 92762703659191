/** @format */

import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {  SymbolSummaryInfo, Message } from 'api';
import { Accordion, AccordionDetails, AccordionSummary, Box, DialogContent, FormControl, FormGroup, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import  { SelectField, SelectOption, selectOptionsFromAnyList } from '../common/SelectField';
import { DataParam } from 'api/models/trigger/DataParam';
import InflyDialog from 'containers/common/InflyDialog';
import { InflyMessage } from 'containers/common/InflyMessage';
import { ITextField } from 'containers/common/ITextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SymbolField } from 'containers/common/SymbolField';
import { IndicatorParamSpec, IndicatorSpec } from 'api/models/trigger/indicator';
import { AppContext } from 'util/appContext';
import { LoadingCard } from 'containers/common/LoadingCard';



type Props = {
    indicatorParam?: DataParam; // to preload with values.
    indicatorSpec: IndicatorSpec;
    onCancel: () => void;
    onSubmit: (dataParam: DataParam) => void;
}

export function IndicatorParamDialog(props: Props): React.JSX.Element {

   
    const [indicatorParam, setIndicatorParam] = useState<DataParam>( DataParam.newIndicatorIdParam(props.indicatorSpec.indicatorId));
    const [indicatorSecurity, setIndicatorSecurity] = useState<SymbolSummaryInfo>();
    const [message, setMessage] = useState<Message | null>(null);

    const [expanded, setExpanded] = useState(false);


    async function createDefaultBasedOnProps() {
        if(props.indicatorParam){
            let editDataParam: DataParam = props.indicatorParam.clone();
            setIndicatorParam(editDataParam);
            let symbol: string|undefined = editDataParam.get(DataParam.SECURITY)
            if(symbol){
                let appContext = AppContext.getInstance();
                let tickerSymbols: SymbolSummaryInfo[] = appContext.getGlobalState('SymbolField.AllSymbols');
                if (tickerSymbols === undefined) {
                    tickerSymbols = await appContext.marketDataApiClient.getAllSymbols();
                }
                let symbolSummary = tickerSymbols.find( (value) => value.symbol == symbol);
                setIndicatorSecurity(symbolSummary);
            }
            let optionalParamAccordianExpanded = editDataParam.get(DataParam.LOOKBACK) !== undefined || editDataParam.get(DataParam.SECURITY) !== undefined
            setExpanded(optionalParamAccordianExpanded);
        }else{
            let defaultParam = DataParam.newIndicatorIdParam(props.indicatorSpec.indicatorId);
            for (let [paramName, paramSpec] of props.indicatorSpec.params.entries()){
                if(paramSpec.defaultValue !== undefined){
                    defaultParam.setParam(paramName, paramSpec.defaultValue);
                }
            }
            setIndicatorParam(defaultParam);
        }
        
   }

    useEffect(() => {
        createDefaultBasedOnProps();
    }, [ props.indicatorSpec.indicatorId])

    if (indicatorParam === undefined){
        return <LoadingCard title='Indicator Params'/>
    }


    function onCancel() {
        props.onCancel()
    }

    function onSubmit() {
        props.onSubmit(indicatorParam)
    }

    function clearMessage() {
        setMessage(null);
    }


    function onParamChange(paramName: string, paramValue: any) {
        if(paramValue === undefined){
            indicatorParam.deleteParam(paramName); // unsetting optional params like looback
        }else{
            indicatorParam.setParam(paramName, paramValue);
        }
        setIndicatorParam(indicatorParam.clone())
    }

    function onAccordianExpandCollapse(event: React.SyntheticEvent<Element, Event>, isexpanded: boolean){
        setExpanded(isexpanded);
    }

   

    return (
        <div>
            <InflyDialog
                open={true}
                maxWidth={"md"}
                title={`Configure Indicator Parameter for ${props.indicatorSpec.indicatorId}`}
                okText={'Save'}
                onClose={onCancel}
                onExecute={onSubmit}
            >
                <DialogContent>

                    <Stack spacing={2}>

                        <InflyMessage message={message} onClose={clearMessage} />

                        <Typography component={'h6'}> {props.indicatorSpec.name} </Typography>

                        <Typography variant={'subtitle1'} color={'text.secondary'}>
                            {props.indicatorSpec.description}
                        </Typography>

                        <Grid container spacing={2}>

                            {
                                [...props.indicatorSpec.params.entries()]
                                    .filter( ([paramName, paramSpec]) => paramSpec.required === true)
                                    .map( ([paramName, paramSpec])  => {
                                        return <Grid item key={paramName} md={6} sm={12}>
                                            <IndicatorParamInputField 
                                                paramName={paramName} 
                                                paramVal={indicatorParam.get(paramName)}
                                                paramSpec={paramSpec} 
                                                onParamChange={paramValue => onParamChange(paramName, paramValue)} />
                                        </Grid>
                                    })
                            }

                        </Grid>

                        <Accordion expanded={expanded} onChange={onAccordianExpandCollapse}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Optional Parameters</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Stack  direction={'row'} justifyContent={'space-between'} gap={2}>


                                    <IndicatorParamInputField 
                                        paramName={DataParam.LOOKBACK} 
                                        paramVal={indicatorParam.get(DataParam.LOOKBACK)}
                                        paramSpec={props.indicatorSpec.params.get(DataParam.LOOKBACK)!} 
                                        onParamChange={paramValue => onParamChange(DataParam.LOOKBACK, paramValue)} />

                                    <SymbolField
                                        includeIndex={true}
                                        selectedSymbol={indicatorSecurity}
                                        onClear={() => onParamChange(DataParam.SECURITY, undefined) }
                                        onChange={(tickerSymbol: SymbolSummaryInfo) => onParamChange(DataParam.SECURITY, tickerSymbol.symbol) } />

                                </Stack>

                            </AccordionDetails>

                        </Accordion>

                    </Stack>

                </DialogContent>
            </InflyDialog>
        </div>
    );

}

type IndicatorParamInputFieldProps = {
    paramName: string;
    paramSpec: IndicatorParamSpec;
    paramVal: any;
    onParamChange: (paramVal: any) => void;
}
function IndicatorParamInputField(props: IndicatorParamInputFieldProps): React.JSX.Element {
    const { paramName, paramSpec } = props;

    if (paramSpec.options != undefined) {

        return <SelectField
            id={paramName}
            name={paramName}
            label={paramName}
            fullWidth={true}
            value={props.paramVal}
            allowUnset={paramSpec.required === false}
            onSelectChange={ (selectedValue: SelectOption) => {
                if(selectedValue === undefined){
                    props.onParamChange(undefined); // required to unset optional parameters like looback to null/undefined
                }else{
                    props.onParamChange(selectedValue.value);
                }        
            } }
            options={selectOptionsFromAnyList(paramSpec.options)}
        />
    } else {
        return <ITextField
            label={paramName}
            value={props.paramVal}
            onChange={props.onParamChange} />
    }
}


export default IndicatorParamDialog;
