/** @format */

import React, { Component } from 'react';
import { Box, FormControl, FormLabel, Input, Stack } from '@mui/material';
import { ClassAPIClient, Game, GameAPIClient, GameStatus, Message, ProfessorClass } from 'api';
import StudentSummary from './StudentsSummary';
import CommonUtil from 'util/CommonUtil';
import { InflyMessage } from 'containers/common/InflyMessage';
import { RouteProps } from 'util/commonProps';
import { SelectOption, SelectField } from 'containers/common/SelectField';
import DialogButton from 'containers/common/DialogButton';
import { LoadingCard } from 'containers/common/LoadingCard';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import { NoClassBox } from './NoClassBox';
import { AppContext } from 'util/appContext';
import AddClassBtn from './AddClassBtn';
import AddEditGameBtn from '../Game/AddEditGameBtn';

interface ClassProps extends RouteProps {
    classes?: any;
}

interface ProfessorDashBoardState {
    ready: boolean;
    message?: Message;

    allClasses: ProfessorClass[];
    currentClass?: ProfessorClass;

    allGames: Game[];
    currentGame?: Game;

    gameEndDate?: Date; // game end date used for startgame dialog
}

class ProfessorDashboard extends Component<ClassProps, ProfessorDashBoardState> {
    classAPIClient: ClassAPIClient;
    gameAPIClient: GameAPIClient;

    constructor(props: ClassProps) {
        super(props);
        this.classAPIClient = AppContext.getInstance().classApiClient;
        this.gameAPIClient = AppContext.getInstance().gameApiClient;
        this.state = {
            allClasses: undefined!,
            allGames: undefined!,
            ready: false,
        };
    }

    async componentDidMount() {
        let currentClass = undefined;
        try {
            let allClasses = await this.classAPIClient.listMyClasses();

            if (allClasses.length > 0) {
                currentClass = allClasses[0];
            }
            this.setState({ allClasses: allClasses, currentClass: currentClass });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }

        if (currentClass) {
            try {
                let allGames = await this.gameAPIClient.listClassGames(currentClass.name);
                let currentGame = undefined;
                if (allGames.length > 0) {
                    currentGame = allGames[0];
                }
                this.setState({ allGames: allGames, currentGame: currentGame });
            } catch (error) {
                this.setState({ message: Message.fromError(error) });
            }
        }

        this.setState({ ready: true });
    }

    onClassAddReturn = async (clazz?: ProfessorClass) => {
        if (clazz) {
            let allClasses = this.state.allClasses;
            allClasses.unshift(clazz);
            this.setState({ currentClass: clazz, allClasses: allClasses, allGames: [] });
        }
    };

    onClassDelete = async () => {
        let message: Message = await this.classAPIClient.deleteClass(this.state.currentClass!.name);
        let allClasses = await this.classAPIClient.listMyClasses();
        let currentClass = this.state.currentClass;

        currentClass = allClasses.find((c) => c.name == currentClass!.name); // we expect undefined if the class was deleted
        if (currentClass === undefined && allClasses.length > 0) {
            currentClass = allClasses[0];
        }

        this.setState({ message: message, allClasses: allClasses, currentClass: currentClass });
    };

    onClassSelectChange = (event: any): void => {
        let currentClass: ProfessorClass = this.state.allClasses.find(
            (cl) => cl.name === event.target.value
        )!;
        this.setState({ currentClass: currentClass });
    };

    createClassSelectOptions = (): SelectOption[] => {
        return this.state.allClasses.map((cz) => {
            return { label: cz.name, value: cz.name };
        });
    };

    // ---- Game Select Dropdown actions

    onGameSelectChange = (event: any): void => {
        let currentGame: Game = this.state.allGames.find(
            (game) => game.name === event.target.value
        )!;
        this.setState({ currentGame: currentGame });
    };

    createGameSelectOptions = (): SelectOption[] => {
        return this.state.allGames.map((game) => {
            return { label: game.name, value: game.name };
        });
    };

    onNewGameCreateEditReturn = async (game?: Game) => {
        if (game) {
            // if not cancelled
            let allGames = this.state.allGames;
            let index = allGames.findIndex((g) => g.gameId === game.gameId);
            if (index === -1) {
                // Note that we don't need to add class to the game because AddEditGameBtn automatically does it when we pass the classname props
                allGames.unshift(game);
                this.setState({ allGames: allGames, currentGame: game });
            } else {
                // This was a return from edit
                allGames[index] = game;
                this.setState({ allGames: allGames });
            }
        }
    };

    onGameDelete = async () => {
        let message = await this.gameAPIClient.deleteGame(this.state.currentGame!.gameId);
        let allGames = await this.gameAPIClient.listClassGames(this.state.currentClass!.name);
        let currentGame = this.state.currentGame;
        currentGame = allGames.find((g) => g.gameId === currentGame!.gameId); // we expect undefiend on operation success
        if (currentGame === undefined && allGames.length > 0) {
            // if operation succeeded and we have more games
            currentGame = allGames[0];
        }
        this.setState({ message, currentGame, allGames });
    };

    onStartGame = async () => {
        try {
            const gameStatus = await this.gameAPIClient.startGame(
                this.state.currentGame!.gameId,
                this.state.gameEndDate!.getTime()
            );

            // game object changes, we need to update it
            let currentGame: Game = await this.gameAPIClient.getGame(
                this.state.currentGame!.gameId
            );
            let allGames = this.state.allGames;
            let index = allGames.findIndex((g) => g.gameId === currentGame.gameId);
            allGames[index] = currentGame;

            this.setState({
                message: Message.info('Game status: ' + gameStatus),
                currentGame,
                allGames,
            });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    };

    onEndGame = async () => {
        try {
            const gameStatus = await this.gameAPIClient.endGame(this.state.currentGame!.gameId);
            // game object changes, we need to update it
            let currentGame: Game = await this.gameAPIClient.getGame(
                this.state.currentGame!.gameId
            );
            let allGames = this.state.allGames;
            let index = allGames.findIndex((g) => g.gameId === currentGame.gameId);
            allGames[index] = currentGame;

            this.setState({
                message: Message.info('Game status: ' + gameStatus),
                currentGame,
                allGames,
            });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    };

    render() {
        if (!this.state.ready) {
            return <LoadingCard title={'Professor Dashborad'}  />;
        }
        if (this.state.currentClass === undefined) {
            return <NoClassBox onNewClassCreate={this.onClassAddReturn} />;
        }

        return (
            <>
                <Stack gap={2}>
                    <InflyMessage
                        message={this.state.message}
                        onClose={() => this.setState({ message: undefined })}
                    />

                    <Stack id='ControlRow' direction={'row'} justifyContent='space-between'>
                        <Box display={'flex'} gap={1}>
                            <SelectField
                                label='Select Class'
                                value={this.state.currentClass.name}
                                options={this.createClassSelectOptions()}
                                onChange={this.onClassSelectChange}
                            />

                            <AddClassBtn label='New Class' onReturn={this.onClassAddReturn} />

                            <DialogButton
                                dialogtitle={`Delete ${this.state.currentClass.name} class`}
                                dialogprompt={`Are you sure do you want to delete ${this.state.currentClass.name}`}
                                color={'error'}
                                label={'Delete'}
                                onClick={async () => this.onClassDelete()}
                            />
                        </Box>

                        <Box display={'flex'} gap={1}>
                            <SelectField
                                label='Select Contest'
                                value={this.state.currentGame?.name}
                                options={this.createGameSelectOptions()}
                                onChange={this.onGameSelectChange}
                            />

                            {this.state.currentGame && (
                                <>
                                    {this.state.currentGame!.status !== GameStatus.STARTED && (
                                        <>
                                            <AddEditGameBtn
                                                label='Edit Contest'
                                                game={this.state.currentGame}
                                                className={this.state.currentClass.name}
                                                onCreateGameReturn={this.onNewGameCreateEditReturn}
                                            />

                                            <DialogButton
                                                onClick={this.onStartGame}
                                                label='Start Contest'
                                                color={'primary'}
                                                dialogtitle='Start Contest'
                                            >
                                                <FormControl
                                                    fullWidth
                                                    component='fieldset'
                                                    required
                                                >
                                                    <FormLabel component='legend'>
                                                        {' '}
                                                        End Date
                                                    </FormLabel>
                                                    <Input
                                                        type={'date'}
                                                        value={CommonUtil.getDateString(
                                                            this.state.gameEndDate
                                                        )}
                                                        onChange={(event: any) =>
                                                            this.setState({
                                                                gameEndDate: new Date(
                                                                    event.target.value
                                                                ),
                                                            })
                                                        }
                                                    />
                                                </FormControl>
                                            </DialogButton>
                                        </>
                                    )}
                                    {this.state.currentGame!.status === GameStatus.STARTED && (
                                        <DialogButton
                                            onClick={() => this.onEndGame()}
                                            label='End Contest'
                                            dialogtitle='End Contest'
                                            dialogprompt='Are you sure you want to end this contest?'
                                        />
                                    )}

                                    <DialogButton
                                        dialogtitle={`Delete Contest ${this.state.currentGame.name}`}
                                        dialogprompt={`Are you sure do you want to delete ${this.state.currentGame.name}`}
                                        color={'error'}
                                        label={'Delete'}
                                        onClick={async () => this.onGameDelete()}
                                    />
                                </>
                            )}

                            <AddEditGameBtn
                                className={this.state.currentClass.name}
                                label='Add Contest'
                                onCreateGameReturn={this.onNewGameCreateEditReturn}
                            />
                        </Box>
                    </Stack>

                    <StudentSummary
                        key={this.state.currentClass.name + this.state.currentGame?.name}
                        currentClass={this.state.currentClass}
                        currentGame={this.state.currentGame}
                        onMessage={(message) => this.setState({ message })}
                    />
                </Stack>
            </>
        );
    }
}

export default withNavBarAndAuth(ProfessorDashboard);
