/** @format */

import { MatchedTrade, Message, PortfolioAPIClient } from 'api';
import React, { Component } from 'react';

import InflyTable from 'containers/common/InflyTable';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import CommonUtil from 'util/CommonUtil';
import { InflyMessage } from 'containers/common/InflyMessage';
import { PortfolioIdentifierProps } from './PortfolioDashboard';
import { AppContext } from 'util/appContext';
import A from 'containers/common/Anchor';
import { BlinkTextComponent } from 'containers/common/BlinkComponet';

export interface MatchedTradesState {
    matchedTrades: MatchedTrade[];
    pageSize: number;
}

class ClosedPositions extends Component<PortfolioIdentifierProps, MatchedTradesState> {
    portfolioApiClient: PortfolioAPIClient;

    constructor(props: PortfolioIdentifierProps) {
        super(props);
        this.portfolioApiClient = AppContext.getInstance().portfolioApiClient;
        this.state = {
            matchedTrades: [],
            pageSize: 10,
        };
    }

    async componentDidMount() {
        this.loadMatchedTrades();
    }

    async loadMatchedTrades() {
        let matchedTrades = await this.portfolioApiClient.getMatchedTrades(
            this.props.currentPortfolio.portfolioId,
            this.props.currentPortfolio.broker
        );
        this.setState({ matchedTrades: matchedTrades });
    }

    getMatchedTradeCofDef = () => {
        return [
            { field: 'id', headerName: 'ID', hide: true },
            { field: 'securityType', headerName: 'Security Type', hide: true,},
            {
                field: 'symbol',
                headerName: 'Symbol',

                formatFunction: (value: any) => (
                    <A href={`/stockdetail/${value}`} newTab={true} id='href-cell'>
                        {value}
                    </A>
                ),

            },
            { field: 'position', headerName: 'Type' },
            { field: 'quantity', headerName: 'Qty' },

            { field: 'openDate', headerName: 'Open Date' },
            { field: 'closeDate', headerName: 'Close Date' },
            { field: 'openPrice', headerName: 'Avg Cost' },
            { field: 'closePrice', headerName: 'Close Price' },
            { field: 'pl', headerName: 'P/L', hide: true },
            {
                field: 'pc',
                headerName: 'Pct(%)',

                formatFunction: (value: any) => (
                    <BlinkTextComponent isTextColor={true} value={value}>
                        {value}
                    </BlinkTextComponent>
                ),
            },
        ];
    };

    getMatchedTradeRows = () => {
        return this.state.matchedTrades?.map((mt: MatchedTrade, index: any) => ({
            id: index,
            position: mt.position,
            quantity: mt.quantity,
            securityType: mt.security.securityType,
            openDate: CommonUtil.toEstString(mt.openDate).replace(/,/g, ' '),
            closeDate: CommonUtil.toEstString(mt.closeDate).replace(/,/g, ' '),
            openPrice: mt.openPrice,
            closePrice: mt.closePrice,
            symbol: mt.security.symbol,
            pl: mt?.profitLoss ?? 0,
            pc: mt?.percentChange ?? 0,
        }));
    };

    downloadClosedTradesAsCSV = () => {
        const headers = {
            symbol: 'Symbol',
            position: 'Type',
            quantity: 'Quantity',
            openDate: 'Open Date',
            closeDate: 'Close Date',
            openPrice: 'Open Cost',
            closePrice: 'Close Price',
            profitLoss: 'Profit/Loss',
            percentageChange: 'Percentage Change(%)',
        };
        let formattedData: object[] = [];
        this.state.matchedTrades?.forEach((mt: MatchedTrade, index: any) => {
            formattedData.push({
                symbol: mt.security.symbol,
                position: mt.position,
                quantity: mt.quantity,
                openDate: CommonUtil.toEstString(mt.openDate).replace(/,/g, ' '),
                closeDate: CommonUtil.toEstString(mt.closeDate).replace(/,/g, ' '),
                openPrice: mt.openPrice,
                closePrice: mt.closePrice,
                profitLoss: mt.profitLoss,
                percentageChange: mt.percentChange,
            });
        });
        CommonUtil.exportCSVFile(headers, formattedData, 'Closed Trade');
    };

    render() {
        return (
            <Card>
                <CardHeader
                    title={<>Closed&nbsp;Trade</>}
                    action={
                        <Button
                            className=''
                            id='downloadResult'
                            size={'small'}
                            onClick={(event: any) => {
                                event.preventDefault();
                                this.downloadClosedTradesAsCSV();
                            }}
                        >
                            {' '}
                            Download&nbsp;CSV
                        </Button>
                    }
                />
                <CardContent>
                    <InflyTable
                        title={'Orders'}
                        multipleSelection={false}
                        order={'desc'}
                        orderBy={'openDate'}
                        rows={this.getMatchedTradeRows()}
                        columns={this.getMatchedTradeCofDef()}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(value: number) =>
                            this.setState({
                                pageSize: value,
                            })
                        }
                        columnNameForSearch={'symbol'}
                    />
                </CardContent>
            </Card>
        );
    }
}

export default ClosedPositions;
