import { FINANCIAL_FIELD_DESC, FINANCIAL_GROUP_MAP, FinancialField, FinancialFieldGroup } from "api/models/trigger/FinancialField";
import { DataDisplayCard } from "containers/common/DataDisplayCard";
import React from "react";




export interface FundamentalMetricCardProps {
    fundamentalMetrics: Map<FinancialField, number>;
    type: FinancialFieldGroup;
}

export function FundamentalMetricCard(props: FundamentalMetricCardProps) {


    let title = props.type.replace("_", " ");

    let metricVals: Map<string, any> = new Map();
    let financialFields: FinancialField[] = FINANCIAL_GROUP_MAP.get(props.type)!;
    
    for(let f of financialFields){
        metricVals.set(FINANCIAL_FIELD_DESC.get(f)![0], props.fundamentalMetrics.get(f))
    }

    return (
        <DataDisplayCard title={title} data={metricVals} />
    )

}