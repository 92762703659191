import React from 'react';
import {useLocation, useNavigate, useParams,} from 'react-router-dom';
import { RouteProps } from 'util/commonProps';

const withRouter = <P extends object>(Component: React.ComponentType<P>) => {

    function ComponentWithRouterProp(props: Omit<P, keyof RouteProps>) {
        let location = useLocation(); // location object https://github.com/remix-run/history/blob/main/docs/api-reference.md#location
        let navigate = useNavigate(); // a function to navigate('/path')
        let params = useParams(); // url request params
        return (
            <Component
                {...props as P}
                 location={location} 
                 navigate={navigate} 
                 params={params}
            />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter