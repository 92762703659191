/** @format */

import {
    Button,
    ButtonProps,
    DialogContent,
    FormControl,
    FormHelperText,
    FormLabel,
    Stack,
    TextField,
} from '@mui/material';
import {
    ClassAPIClient,
    Game,
    GameAPIClient,
    Message,
    MessageType,
    ProfessorClass,
    UserAPIClient,
} from 'api';
import React, { Component, useEffect, useRef } from 'react';
import { AppContext } from 'util/appContext';
import InflyDialog from './InflyDialog';

import { InflyMessage } from './InflyMessage';

interface InflyInviteFriendsProps extends ButtonProps {
    label?: string;
    onSuccess?: (message: Message) => void;
    professorClass?: ProfessorClass; // set if inviting to class
    game?: Game; // set if inviting to game
}

interface InflyInviteFriendsState {
    open: boolean;
    toEmails: string;
    message?: Message;
}

export default class InviteFriends extends Component<
    InflyInviteFriendsProps,
    InflyInviteFriendsState
> {
    userApiClient: UserAPIClient;
    classApiClient: ClassAPIClient;
    gameApiClient: GameAPIClient;

    constructor(props: InflyInviteFriendsProps) {
        super(props);
        this.state = {
            open: false,
            toEmails: '',
        };
        this.userApiClient = AppContext.getInstance().userApiClient;
        this.classApiClient = AppContext.getInstance().classApiClient;
        this.gameApiClient = AppContext.getInstance().gameApiClient;
    }

    async inviteStudentsUsersByEmails(): Promise<any> {
        let message: Message;
        try {
            const emails = this.state.toEmails.split(',');

            let res: Map<string, string[]> | undefined = undefined;
            if (this.props.professorClass) {
                res = await this.classApiClient.inviteStudentsEmails(
                    this.props.professorClass.name,
                    emails
                );
            } else if (this.props.game) {
                res = await this.gameApiClient.inviteEmails(this.props.game.gameId, emails);
            } else {
                res = await this.userApiClient.inviteUsers(emails);
            }

            message = this.parseInvitationResponse(res!);

            if (message.type === MessageType.SUCCESS) {
                this.setState((prevState) => ({ ...prevState, open: false }));
                if (this.props.onSuccess) {
                    this.props.onSuccess(message);
                }
            }
            if (message.type !== MessageType.SUCCESS) {
                this.setState((prevState) => ({ ...prevState, message: message }));
            }
        } catch (error) {
            message = Message.fromError(error);
            this.setState((prevState) => ({ ...prevState, message: message }));
        }
    }

    parseInvitationResponse(res: Map<string, string[]>): Message {
        let message: Message = Message.info('Unknown error occurred. Please contact us');

        if (res.hasOwnProperty('invited') && res['invited'].length > 0)
            message = Message.success('Invitation Sent to [' + res['invited'].join(',') + ']. ');

        if (res.hasOwnProperty('exists') && res['exists'].length > 0)
            message = Message.info('Already invited [' + res['exists'].join(',') + ']. ');

        if (res['success'] && res['success'].length > 0)
            message = Message.success('Invitation Sent to [' + res['success'].join(',') + ']. ');

        if (res.hasOwnProperty('failed') && res['failed'].length > 0)
            message = Message.error('Could not invite [' + res['failed'].join(',') + '].');

        return message;
    }

    handleChangeEmails = (value: string) => {
        this.setState((prevState) => ({ ...prevState, toEmails: value }));
    };

    clearMessage = () => {
        this.setState((prevState) => ({ ...prevState, message: undefined }));
    };

    render() {
        const { open, toEmails, message } = this.state;
        const { label, onSuccess, professorClass, game, ...buttonRest } = this.props;

        const isGenericInvite = professorClass === undefined && game === undefined;
        const promtText = isGenericInvite
            ? 'Invite friends and get a FREE month for every PREMIUM or ELITE user you refer to Investfly'
            : professorClass
            ? 'Enter students email address below to invite them to your class'
            : ' Enter users email to invite to game';

        return (
            <>
                <InflyDialog
                    id={'inviteFriends'}
                    open={open}
                    isScreenSizeDependent={true}
                    onClose={() => this.setState({ open: false })}
                    title={'Invite Users to Investfly'}
                    okText={'Invite'}
                    onExecute={() => this.inviteStudentsUsersByEmails()}
                >
                    <DialogContent>
                        <Stack gap={2}>
                            <InflyMessage message={message} onClose={this.clearMessage} />
                            <FormHelperText> {promtText} </FormHelperText>
                            <InviteWithEmail
                                toEmails={toEmails}
                                handleChangeEmails={this.handleChangeEmails}
                            />
                        </Stack>
                    </DialogContent>
                </InflyDialog>

                <Button
                    {...buttonRest}
                    onClick={(event: any) => {
                        event.preventDefault();
                        this.setState({ open: true });
                    }}
                >
                    {label}
                </Button>
            </>
        );
    }
}

const moveCaretAtEnd = (e: any) => {
    var temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
};

interface InviteWithEmailProps {
    toEmails: string;
    handleChangeEmails: (value: string) => void;
}
export function InviteWithEmail(props: InviteWithEmailProps) {
    //text area loses focuse on every re-render, hence adding below code to keep focus back on the textarea
    const txtAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        const txtAreaElement = txtAreaRef.current;
        if (txtAreaElement !== null) txtAreaElement.focus();
    });

    return (
        <>
            <Stack>
                <FormLabel component='legend'>Enter Emails</FormLabel>
                <FormControl component='fieldset' fullWidth>
                    <TextField
                        multiline
                        key='inviteEmailTxtArea'
                        inputRef={txtAreaRef}
                        id='inviteEmailTxtArea'
                        placeholder={'abc@example.com,def@example.com'}
                        value={props.toEmails}
                        helperText={'Separate emails by comma or a space or new line'}
                        name={'emails'}
                        rows={10}
                        onFocus={moveCaretAtEnd}
                        onChange={(e) => {
                            props.handleChangeEmails(e.target.value);
                        }}
                    />
                </FormControl>
            </Stack>
        </>
    );
}
