import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { Box, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Chip, OutlinedInput, Select, FormHelperText } from '@mui/material';

export interface SelectOption {
    label: string;
    value: any;
}

export function selectOptionsFromString(arr: string[]): SelectOption[] {
    return arr.map(s => {
        return { label: s, value: s }
    })
}

export function selectOptionsFromAnyList(arr: any[]): SelectOption[] {
    return arr.map(s => {
        return { label: s.toString(), value: s }
    })
}

export function selectOptionsFromEnum(enumObj: any): SelectOption[] {
    return Object.entries(enumObj).map(([key, value]) => {
        return { label: key.replaceAll("_", " "), value: value };
    })
}

type Props = TextFieldProps & {
    options: SelectOption[],
    allowUnset?: boolean
    // pass undefined to indicate user "unset" the field. If required property is set to true, we never pass undefined
    // we aren't declaring "SelectOption|undefined" for the type because unsetting value is less common case, and if we 
    // declare the type of selectedOption as SelectOption|undefined, then we have to change all usages handlers to reflect
    // the same AND again cast to required by using ! operator for the all the common cases
    onSelectChange?: (selectedOption: SelectOption) => void;
    // the width of the dropdown field is determined  by the max width of the one of the option item. However, sometimes the label text has more characters
    // than the max option, which causes the label to clip. THis is used to force it  
    width?: string
};

export function SelectField(props: Props) {

    let { value, options, variant } = props;

    // If there are no options, then value can be undefined. If there are options, first value must be selected by caller
    // We cannot auto-select first value because it should be tracked in parent's state


    let finalOptions = [...options];
    let finalValue = value;

    if (finalOptions.length == 0) {
        finalOptions.push({ label: 'No Items', value: 'No Items' });
        finalValue = 'No Items';
    }else{
        if (value === undefined || props.allowUnset) { // when allowUnset=true, we should allow user to "unset" the value by setting to 'Select Value'
            finalOptions.unshift({ label: 'Select Value', value: 'Select Value' });
        }  
        if(value === undefined){
            finalValue = 'Select Value';
        }
    }

    // We use <TextField> instead of <Select> because if we use <Select>, we must wrap it with <FormControl> to set the label

    return (
        <TextField
            name={props.name}
            fullWidth={props.fullWidth}
            label={props.label}
            variant={variant ?? 'outlined'}
            disabled={props.disabled ?? props.options === undefined}
            value={finalValue}
            select
            size='small'
            sx={props.width !== undefined? {width: props.width}: {}}
            onChange={(event: any) => {
                let selectedOption;
                if (event.target.value === undefined){
                    selectedOption = undefined;
                }else{
                    selectedOption = props.options.find((so: SelectOption) => so.value.toString() == event.target.value)
                }
                
                if (props.onSelectChange) {
                    props.onSelectChange(selectedOption!);

                } else if (props.onChange) {
                    props.onChange(event);
                }
            }}
        >
            {finalOptions.map((option, index) => {
                return (
                    <MenuItem
                        key={option.label + '_option_' + index}
                        sx={{ fontSize: '1rem' }}
                        value={option.value}>
                        {option.label}
                    </MenuItem>
                );
            })}
        </TextField>
    );
}

type MultiSelectFieldProps = Omit<TextFieldProps, "onChange" | "value">  & {
    options: SelectOption[],
    value: any[],
    onSelectChange: (selectedOptions: SelectOption[]) => void;
};
export function MultiSelectField(props: MultiSelectFieldProps){

    let { value, options } = props;

    // If there are no options, then value can be undefined. If there are options, first value must be selected by caller
    // We cannot auto-select first value because it should be tracked in parent's state

    if (!options) options = [];
    if (options.length == 0) {
        options.push({ label: 'No Items', value: 'No Items' });
    }

    // without these menuprops, the dropdown covers the selected value
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          },
        },
      };

    return (

          <FormControl>
            <FormLabel id="demo-multiple-chip-label">{props.label}</FormLabel>
            <Select
              id="demo-multiple-chip"
              multiple
              value={value}
              onChange={(event: any) => {
                const selectedValues: string[] = event.target.value;
                const selectedOptions: SelectOption[] = selectedValues.map((selectedValue: string) => {
                    return props.options.find((so: SelectOption) => so.value.toString() == selectedValue)!;            
                })
                props.onSelectChange(selectedOptions);
            }}
              MenuProps={MenuProps}
              renderValue={(selected: any) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value: any) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {options.map((option, index) => {
                return (
                    <MenuItem
                        key={option.label + '_option_' + index}
                        sx={{ fontSize: '1rem' }}
                        value={option.value} >
                        {option.label}
                    </MenuItem>
                );
            })}
            </Select>
          </FormControl>
    )

}

export function RadioField(props: Props & {labelDirection?: 'row' | 'column'} ) {
    const { value, options, onSelectChange } = props;

    const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // event.target.value is always of type string
        const selectedOption = props.options.find((so: SelectOption) => so.value.toString() == event.target.value);
        if (onSelectChange) {
            onSelectChange(selectedOption!);
        }
    }

    const layout = props.labelDirection === 'row' ? {display: 'flex', flexDirection:'row', alignItems:'center', gap: 2} : {}

    return (
        <FormControl  sx={layout}>
            <FormLabel>{props.label}</FormLabel>
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText> }
            <RadioGroup
                name="visibility"
                value={value}
                row
                onChange={onRadioChange}>
                {options.map(t => {
                    return <FormControlLabel key={t.value} value={t.value} control={<Radio />} label={t.label} />
                })}

            </RadioGroup>
        </FormControl>
    )

}
