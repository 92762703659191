/** @format */

import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingCard } from 'containers/common/LoadingCard';
import StockChart from 'containers/StockDetails/StockChart';
import StockNewsList from 'containers/StockDetails/StockNews';
import StockOptionChain from 'containers/common/StockOptionChain';


import {
    BarInterval,
    Message,
    OptionQuote,
    OrderStatus,
    PendingOrder,
    PortfolioIdentifier,
    SecurityType,
    StockOption,
    SymbolSummaryInfo,
} from 'api';

import { AppContext } from 'util/appContext';
import InflyTabs, { InflyTab } from 'containers/common/InflyTabs';
import Trade, { TradeTabs } from 'containers/Trade/Trade';
import { FundamentalMetricCard } from './FundamentalMetricCard';
import { FinancialField, FinancialFieldGroup } from 'api/models/trigger/FinancialField';
import { StockSummary } from './StockSummary';
import ReactGA from 'react-ga4';

interface StockDetailProps {
    selectedSymbol: string
    selectedPortfolioId?: PortfolioIdentifier;

    onMessage: (message?: Message) => void;
}

export function StockDetailComp({ selectedSymbol, selectedPortfolioId, onMessage }: StockDetailProps): React.JSX.Element {

    // lift at least the summary info up, so child don't have to get it over and over again, most of them will need SymbolSummaryInfo.SecurityType  
    const [securitySummary, setSecuritySummary] = useState<SymbolSummaryInfo>();
    const [selectedOption, setSelectedOption] = useState<OptionQuote>();

    useEffect(() => {

        ReactGA.event('stock_detail_pageview', {});

        async function loadSecuritySummary() {
            let marketDataApiClient = AppContext.getInstance().marketDataApiClient;
            let summary: SymbolSummaryInfo = await marketDataApiClient.getSecuritySummary(selectedSymbol);
            setSecuritySummary(summary);
        }
        loadSecuritySummary();
    }, [selectedSymbol])


    const onTradeDialogReturn = (result: OrderStatus | PendingOrder | OrderStatus[] | undefined) => {
        setSelectedOption(undefined);
    }

    if (!securitySummary) {
        return <LoadingCard title={'Stock Summary'} />
    }


    return <Stack gap={1}>

        {selectedOption && <Trade
            showDialogOnly={true}
            security={securitySummary}
            stockOption={StockOption.createStockOption(selectedOption)}
            showOnly={[TradeTabs.OPTION]}
            onTradeDialogReturn={onTradeDialogReturn}
            portfolio={selectedPortfolioId} />
        }


        <StockSummary securitySummary={securitySummary} selectedPortfolioId={selectedPortfolioId} onMessage={onMessage} />


        <InflyTabs variant='scrollable' allowScrollButtonsMobile scrollButtons='auto'>

            <InflyTab label='Chart'>
                <Box component={'div'} id={'interactive-stock-chart'}>
                    <StockChart mode={'dark'} allowFullScreen symbol={securitySummary.symbol}
                    />
                </Box>
            </InflyTab>

            {securitySummary.type !== SecurityType.INDEX &&
                <InflyTab label='Options'>
                    <StockOptionChain
                        symbol={securitySummary.symbol}
                        onOptionQuoteSelect={(optionQuote: OptionQuote) => setSelectedOption(optionQuote)}
                    />
                </InflyTab>
            }

            {(securitySummary.type === SecurityType.STOCK) && (
                <InflyTab label='Financials'>
                    <FinancialDisplayComp symbol={securitySummary.symbol} />
                </InflyTab>
            )}

        </InflyTabs>

        <Box component={'div'}>
            <StockNewsList symbol={securitySummary.symbol} />
        </Box>




    </Stack>
}

export function FinancialDisplayComp({ symbol }: { symbol: string }): React.JSX.Element {

    const [financials, setFinancials] = useState<Map<FinancialField, number>>();

    useEffect(() => {
        async function loadFinancials() {
            let marketDataApiClient = AppContext.getInstance().marketDataApiClient;
            let financialsData = await marketDataApiClient.getFinancials(symbol);
            setFinancials(financialsData);
        }
        loadFinancials()

    }, [symbol])

    if (!financials) {
        return <LoadingCard title='Financials' />
    }


    return <Stack gap={2}>
        <Box>
            <Typography variant='body2'>All financials below are shown for the most recent quarter (TTM). Growths are quater-to-quarter growth</Typography>
        </Box>
        <Grid container spacing={1}>

            <Grid item xs={12} md={6}>
                <FundamentalMetricCard type={FinancialFieldGroup.INCOME_STATEMENT} fundamentalMetrics={financials} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FundamentalMetricCard type={FinancialFieldGroup.BALANCE_SHEET} fundamentalMetrics={financials} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FundamentalMetricCard type={FinancialFieldGroup.CASH_FLOW} fundamentalMetrics={financials} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FundamentalMetricCard type={FinancialFieldGroup.VALUATION_METRICS} fundamentalMetrics={financials} />
            </Grid>

        </Grid>
    </Stack>
}
