import {SecurityType} from '../../index';
import {InvestflyModel} from '../InvestflyModel';
import {SecurityPriceChange} from '../marketdata/security';


export class WatchList  {
    name: string;
    username: string;
    securityType: SecurityType;
    id: number;
    securities: SecurityPriceChange[];

    static createDefaultStockList(name: string): WatchList{
        let watchList = new WatchList();
        watchList.securityType = SecurityType.STOCK;
        watchList.securities = [];
        watchList.name = name;
        return watchList;
    }

    addSymbolForCreate(symbol: string){
        let securityPriceChange = new SecurityPriceChange();
        securityPriceChange.symbol = symbol;
        this.securities.push(securityPriceChange);
    }

    static parseJSON(obj: { [index: string]: any }): WatchList {
        let watchList = new WatchList();
        Object.assign(watchList, obj);
        watchList.id = obj.id;
        watchList.securities = InvestflyModel.parseList(obj.securities, SecurityPriceChange.parseJSON);
        return watchList;
    }

    symbolToString() {
        const symbols = this.securities.map(item => item.symbol);
        return symbols.toString();
    }
}