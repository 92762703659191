/** @format */

import React, { Component } from 'react';
import { Message, User, UserAPIClient, UserSession } from 'api';
import withRouter from 'containers/HOC/WithRouter';
import CommonUtil from 'util/CommonUtil';
import { AppContext } from 'util/appContext';
import { RouteProps, RouterAndThemeProps } from 'util/commonProps';
import SessionUtil from 'util/SessionUtil';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Stack,
    Typography,
} from '@mui/material';
import { DoneAll, ReportGmailerrorred } from '@mui/icons-material';
import DocumentHeader from 'containers/common/DocumentHeader';
import ReactGA from 'react-ga4';

interface ConfirmActivateAccountProps extends RouteProps {}

interface ConfirmActivateAccountState {
    message?: Message;
}

class ConfirmActivateAccount extends Component<
    ConfirmActivateAccountProps,
    ConfirmActivateAccountState
> {
    userAPIClient: UserAPIClient;

    constructor(props: ConfirmActivateAccountProps) {
        super(props);
        this.userAPIClient = AppContext.getInstance().userApiClient;
    }

    async componentDidMount() {
        const username = CommonUtil.getQueryParam(this.props, 'username');
        const activationCode = CommonUtil.getQueryParam(this.props, 'activationcode');

        if (username !== '' && activationCode !== '') {
            await this.activateAccount(username, activationCode);
        }
        ReactGA.event('activate_account', {});
    }

    activateAccount = async (username: string, activationCode: string) => {
        try {
            const session: UserSession = await this.userAPIClient.activate(
                username,
                activationCode
            );
            SessionUtil.saveSession(session);

            const user: User = await this.userAPIClient.getUserProfile(username);
            SessionUtil.saveUserInfo(user);
            ReactGA.set({ userId: user.username });

            if (!CommonUtil.isObjectEmpty(session)) {
                const href = SessionUtil.getAfterLoginPage(this.props);
                this.props.navigate(`${href}?fromActivate=1`);
            }
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
            console.log(error);
        }
    };

    render() {
        const message = this.state?.message;

        return (
            <DocumentHeader title={'Activate Account'}>
                {message && (
                    <Box p={'100px'}>
                        <Card>
                            <CardHeader title={'Activate Account'} />
                            <CardContent>
                                <Stack
                                    height='200px'
                                    alignItems='center'
                                    justifyContent='space-between'
                                >
                                    {!message.isError() && <DoneAll fontSize={'large'} />}
                                    {message.isError() && (
                                        <ReportGmailerrorred fontSize={'large'} />
                                    )}
                                    <Typography
                                        variant={'h6'}
                                        color={CommonUtil.getTypographyColor(message)}
                                    >
                                        {message.message ??
                                            'Unexpected error occurred. Please contact us'}
                                    </Typography>
                                    <CardActions>
                                        <Button
                                            size='small'
                                            onClick={(event: any) => {
                                                event.preventDefault();
                                                this.props.navigate('/');
                                            }}
                                        >
                                            Go Home
                                        </Button>
                                    </CardActions>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Box>
                )}
            </DocumentHeader>
        );
    }
}

export default withRouter(ConfirmActivateAccount);
