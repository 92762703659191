
export enum FinancialFieldGroup {
    VALUATION_METRICS = "VALUATION_METRICS",
    INCOME_STATEMENT= "INCOME_STATEMENT",
    BALANCE_SHEET= "BALANCE_SHEET",
    CASH_FLOW= "CASH_FLOW",
}

export enum FinancialField {

    OUTSTANDING_SHARES = "OUTSTANDING_SHARES",
    MARKET_CAP= "MARKET_CAP",


    // INCOME STATEMENT
    REVENUE= "REVENUE",
    COST_OF_REVENUE = "COST_OF_REVENUE",
    GROSS_PROFIT = "GROSS_PROFIT",
    OPERATING_EXPENSE= "OPERATING_EXPENSE",
    OPERATING_INCOME= "OPERATING_INCOME",
    NET_INCOME = "NET_INCOME",
    EPS = "EPS",

    // BALANCE SHEET: Assets = Liabilities + Equity
    CURR_ASSETS = "CURR_ASSETS",
    NON_CURR_ASSETS= "NON_CURR_ASSETS" ,
    ASSETS = "ASSETS",
    CURR_LIABILITIES= "CURR_LIABILITIES",
    NON_CURR_LIABILITIES = "NON_CURR_LIABILITIES",
    LIABILITIES = "LIABILITIES",
    EQUITY = "EQUITY",
    LONG_TERM_DEBT = "LONG_TERM_DEBT",



    //CASH FLOW
    OPEARTING_CASH_FLOW = "OPEARTING_CASH_FLOW",
    INVESTING_CASH_FLOW = "INVESTING_CASH_FLOW",
    FINANCING_CASH_FLOW = "FINANCING_CASH_FLOW",
    NET_CASH_FLOW = "NET_CASH_FLOW",

    // Valuation
    RETURN_ON_EQUITY = "RETURN_ON_EQUITY",
    RETURN_ON_ASSETS = "RETURN_ON_ASSETS",
    PRICE_TO_EARNINGS_RATIO = "PRICE_TO_EARNINGS_RATIO",
    PEG_RATIO = "PEG_RATIO",
    PRICE_TO_SALES_RATIO = "PRICE_TO_SALES_RATIO",
    PRICE_TO_BOOK_RATIO = "PRICE_TO_BOOK_RATIO",
    REVENUE_GROWTH = "REVENUE_GROWTH",
    EPS_GROWTH = "EPS_GROWTH",
    DEBT_EQUITY_RATIO = "DEBT_EQUITY_RATIO",
    CURRENT_RATIO = "CURRENT_RATIO",
    GROSS_MARGIN = "GROSS_MARGIN",
    PROFIT_MARGIN = "PROFIT_MARGIN",

    DIVIDEND_YIELD = "DIVIDEND_YIELD"

}

export const FINANCIAL_FIELD_DESC: Map<FinancialField, [string, string]> = new Map([
    [FinancialField.REVENUE, ["Revenue ($)", "Revenue"]],
    [FinancialField.COST_OF_REVENUE, ["Cost of Revenue($)", "Cost of Revenue"]],
    [FinancialField.GROSS_PROFIT, ["Gross Profit($)","Gross Profit"]],
    [FinancialField.OPERATING_EXPENSE, ["Operating Expenses($)","Operating Expenses"]],
    [FinancialField.OPERATING_INCOME, ["Operating Income($)","Operating Income"]],
    [FinancialField.NET_INCOME, ["Net Income($)","Net Income"]],
    [FinancialField.EPS, ["Earnings Per Share($)","Earnings Per Share"]],

    [FinancialField.ASSETS, ["Total Assets($)","Total Assets"]],
    [FinancialField.CURR_ASSETS, ["Current Assets($)","Current Assets"]],
    [FinancialField.NON_CURR_ASSETS, ["Non Current Assets($)","Non Current Assets"]],
    [FinancialField.CURR_LIABILITIES, ["Current Liabilities($)","Current Liabilities"]],
    [FinancialField.NON_CURR_LIABILITIES, ["Non Current Liabilities($)","Non Current Liabilities"]],
    [FinancialField.LIABILITIES, ["Liabilities($)","Liabilities"]],
    [FinancialField.LONG_TERM_DEBT, ["Long Term Debt($)","Long Term Debt"]],
    [FinancialField.EQUITY, ["Equity($)","Equity"]],

    [FinancialField.OPEARTING_CASH_FLOW, ["Operating Cash Flow($)","Operating Cash Flow"]],
    [FinancialField.INVESTING_CASH_FLOW, ["Investing Cash Flow($)","Investing Cash Flow"]],
    [FinancialField.FINANCING_CASH_FLOW, ["Financing Cash Flow($)","Financing Cash Flow"]],
    [FinancialField.NET_CASH_FLOW, ["Net Cash Flow($)","Net Cash Flow"]],

    [FinancialField.RETURN_ON_EQUITY, ["Return on Equity(%)","Return on Equity"]],
    [FinancialField.RETURN_ON_ASSETS, ["Return on Assets(%)","Return on Assets"]],
    [FinancialField.PRICE_TO_EARNINGS_RATIO, ["P/E Ratio","P/E Ratio"]],
    [FinancialField.PEG_RATIO, ["PEG Ratio","PEG Ratio"]],
    [FinancialField.PRICE_TO_SALES_RATIO, ["Price/Sales Ratio","Price/Sales Ratio"]],
    [FinancialField.PRICE_TO_BOOK_RATIO, ["Price/Book Ratio","Price/Book Ratio"]],
    [FinancialField.REVENUE_GROWTH, ["Revenue Growth(%)","Revenue Growth"]],
    [FinancialField.EPS_GROWTH, ["EPS Growth(%)","EPS Growth"]],
    [FinancialField.DEBT_EQUITY_RATIO, ["Debt/Equity Ratio","Debt/Equity Ratio"]],
    [FinancialField.CURRENT_RATIO, ["Current Ratio","Current Ratio"]],
    [FinancialField.GROSS_MARGIN, ["Gross Margin(%)","Gross Margin"]],
    [FinancialField.PROFIT_MARGIN, ["Profit Margin(%)","Profit Margin"]],
    [FinancialField.DIVIDEND_YIELD, ["Dividend Yield(%)","Dividend Yield"]]
]);

export const FINANCIAL_GROUP_MAP: Map<FinancialFieldGroup, FinancialField[]> = new Map([
    [FinancialFieldGroup.INCOME_STATEMENT, [FinancialField.REVENUE, 
                                            FinancialField.COST_OF_REVENUE, 
                                            FinancialField.GROSS_PROFIT, 
                                            FinancialField.OPERATING_EXPENSE, 
                                            FinancialField.OPERATING_INCOME,
                                            FinancialField.NET_INCOME,
                                            FinancialField.EPS
                                        ]
    ],
    [FinancialFieldGroup.BALANCE_SHEET, [FinancialField.ASSETS,
                                        FinancialField.CURR_ASSETS,
                                        FinancialField.NON_CURR_ASSETS,
                                        FinancialField.CURR_LIABILITIES,
                                        FinancialField.NON_CURR_LIABILITIES,
                                        FinancialField.LIABILITIES,
                                        FinancialField.LONG_TERM_DEBT,
                                        FinancialField.EQUITY
                                        ]
    ],
    [FinancialFieldGroup.CASH_FLOW, [
                                        FinancialField.OPEARTING_CASH_FLOW,
                                        FinancialField.INVESTING_CASH_FLOW,
                                        FinancialField.FINANCING_CASH_FLOW,
                                        FinancialField.NET_CASH_FLOW,
    ]],
    [FinancialFieldGroup.VALUATION_METRICS, [
                                        FinancialField.RETURN_ON_EQUITY,
                                        FinancialField.RETURN_ON_ASSETS,
                                        FinancialField.PRICE_TO_EARNINGS_RATIO,
                                        FinancialField.PEG_RATIO,
                                        FinancialField.PRICE_TO_SALES_RATIO,
                                        FinancialField.PRICE_TO_BOOK_RATIO,
                                        FinancialField.REVENUE_GROWTH,
                                        FinancialField.EPS_GROWTH,
                                        FinancialField.DEBT_EQUITY_RATIO,
                                        FinancialField.CURRENT_RATIO,
                                        FinancialField.GROSS_MARGIN,
                                        FinancialField.PROFIT_MARGIN,
                                        FinancialField.DIVIDEND_YIELD,
    ]]
]
); 

