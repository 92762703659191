/** @format */

import React, { Component } from 'react';
import {
    Message,
    OpenPosition,
    OrderType,
    PortfolioAPIClient,
    PositionType,
    TradeOrder,
    TradeType,
} from 'api';
import InflyTable from 'containers/common/InflyTable';
import CommonUtil from 'util/CommonUtil';
import DialogButton from 'containers/common/DialogButton';
import { Card, CardContent, CardHeader } from '@mui/material';
import AuthorizedElement from 'containers/common/AuthorizedElement';
import SessionUtil from 'util/SessionUtil';
import { SellRounded } from '@mui/icons-material';
import { PortfolioIdentifierProps } from './PortfolioDashboard';
import { AppContext } from 'util/appContext';
import A from 'containers/common/Anchor';
import { BlinkTextComponent } from 'containers/common/BlinkComponet';

interface OpenPositionTabState {
    isTradeTriggered: boolean;
    openPositions: OpenPosition[];
    pageSize: number;
}

class OpenPositionsComp extends Component<PortfolioIdentifierProps, OpenPositionTabState> {
    loggedInUsername: string;
    timer: any;
    TIME_OUT: number = 10000;
    portfolioApiClient: PortfolioAPIClient;

    constructor(props: PortfolioIdentifierProps) {
        super(props);
        this.loggedInUsername = SessionUtil.findSession()?.username!;
        this.portfolioApiClient = AppContext.getInstance().portfolioApiClient;
        this.state = {
            isTradeTriggered: false,
            pageSize: 10,
            openPositions: [],
        };
    }

    async componentDidMount() {
        await this.refreshOpenPositionData();
        this.timer = setInterval(() => this.refreshOpenPositionData(), this.TIME_OUT);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    async refreshOpenPositionData() {
        let openPositions = await this.portfolioApiClient.getPortfolioStocks(
            this.props.currentPortfolio.portfolioId,
            this.props.currentPortfolio.broker
        );
        this.setState({ openPositions: openPositions });
    }

    getCofDef = (): any => {
        const portfoliosOwner = this.props.currentPortfolio.userName;
        return [
            { field: 'id', headerName: 'ID', hide: true },
            { field: 'securityType', headerName: 'Security Type', hide: true },
            {
                field: 'symbol',
                headerName: 'Symbol',

                formatFunction: (value: any) => (
                    <A href={`/stockdetail/${value}`} newTab={true} id='href-cell'>
                        {value}
                    </A>
                ),
            },
            { field: 'position', headerName: 'Type' },
            { field: 'quantity', headerName: 'Qty' },
            { field: 'purchaseDate', headerName: 'Open Date' },
            { field: 'avgPrice', headerName: 'Avg Cost' },
            {
                field: 'currentPrice',
                headerName: 'Current Price',

                formatFunction: (value: any) => (
                    <BlinkTextComponent value={value}>{value}</BlinkTextComponent>
                ),
            },
            {
                field: 'currentValue',
                headerName: 'Current Value',

                formatFunction: (value: any) => (
                    <BlinkTextComponent value={value}>{value}</BlinkTextComponent>
                ),
            },
            {
                field: 'pl',
                headerName: 'P/L',
                hide: true,
            },
            {
                field: 'pc',
                headerName: 'Pct(%)',

                formatFunction: (value: any) => (
                    <BlinkTextComponent value={value} isTextColor={true}>
                        {value}
                    </BlinkTextComponent>
                ),
            },
            {
                field: 'sell_cover',
                headerName: 'Sell',
                hide: portfoliosOwner !== this.loggedInUsername,
            },
        ];
    };
    getOpenPositionRows = () => {
        const openPositions = this.state.openPositions;

        return openPositions?.map((op: OpenPosition, index: any) => {
            return {
                position: op.position,
                quantity: op.quantity,
                purchaseDate: CommonUtil.toEstString(op.purchaseDate).replace(/,/g, ' '),
                avgPrice: op.avgPrice,
                securityType: op.security.securityType,
                currentPrice: op?.currentPrice ?? 0,
                currentValue: op?.currentValue ?? 0,
                id: index,
                symbol: op.security.symbol,
                pl: op?.profitLoss ?? 0,
                pc: op?.percentChange ?? 0,
                sell_cover: this.createSellCoverButton(op),
            };
        });
    };

    createSellCoverButton = (op: OpenPosition) => {
        return (
            //Username is a getter function which holds the value of props.
            <AuthorizedElement authorizedUsername={this.props.currentPortfolio.userName}>
                <DialogButton
                    dialogtitle={`Close Position`}
                    buttonType={'iconButton'}
                    color={'primary'}
                    dialogprompt={`Are you sure you want to close this position for ${op.security.symbol}?`}
                    startIcon={<SellRounded fontSize={'small'} />}
                    onClick={() => this.closeOpenPosition(op)}
                />
            </AuthorizedElement>
        );
    };
    closeOpenPosition = async (position: OpenPosition) => {
        try {
            let order: TradeOrder = new TradeOrder();
            order.security = position.security;
            order.orderType = OrderType.MARKET_ORDER;
            order.quantity = position.quantity;
            order.tradeType =
                position.position === PositionType.LONG ? TradeType.SELL : TradeType.COVER;
            let orderStatus = await this.portfolioApiClient.doTrade(
                this.props.currentPortfolio.portfolioId,
                this.props.currentPortfolio.broker,
                order
            );
            this.props.onMessage(orderStatus.getDefaultMessage());
            this.refreshOpenPositionData();
        } catch (error) {
            this.props.onMessage(Message.fromError(error));
        }
    };

    render() {
        const rows = this.getOpenPositionRows();
        const cols = this.getCofDef();

        return (
            <Card>
                <CardHeader title={<>Open&nbsp;Position</>} />
                <CardContent>
                    <InflyTable
                        title={'open positions'}
                        columnNameForSearch={'symbol'}
                        multipleSelection={false}
                        order={'desc'}
                        orderBy={'purchaseDate'}
                        rows={rows}
                        onPageSizeChange={(value: number) =>
                            this.setState({
                                pageSize: value,
                            })
                        }
                        columns={cols}
                        pageSize={this.state.pageSize}
                    />
                </CardContent>
            </Card>
        );
    }
}

export default OpenPositionsComp;
