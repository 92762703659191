/** @format */

import { EventNoteTwoTone, VisibilityOff } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Box,
    Card,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AppConstant, Message, User, UserAPIClient, UserSession } from 'api';
import React, { Component } from 'react';
import { ActionButton } from 'containers/common/ActionButton';
import A from 'containers/common/Anchor';
import { InflyMessage } from 'containers/common/InflyMessage';
import desktopDark from 'static/images/desktop-logo-dark.png';
import desktopWhite from 'static/images/desktop-logo-white.png';
import { AppContext } from 'util/appContext';
import { RouteProps } from 'util/commonProps';
import CommonUtil from 'util/CommonUtil';
import SessionUtil from 'util/SessionUtil';
import withEmptySession from 'containers/HOC/RequireNoSession';
import withRouter from 'containers/HOC/WithRouter';
import DocumentHeader from 'containers/common/DocumentHeader';
import ReactGA from 'react-ga4';

const ROOT_DOMAIN = AppConstant.ROOT_DOMAIN;

interface LoginFormProps extends RouteProps {}

interface LoginFormState {
    userName: string;
    password: string;
    remember: boolean;
    message?: Message;
    showPassword: boolean;
}

class LoginForm extends Component<LoginFormProps, LoginFormState> {
    userAPIClient: UserAPIClient;

    constructor(props: LoginFormProps) {
        super(props);
        this.userAPIClient = AppContext.getInstance().userApiClient;
        this.state = {
            userName: '',
            password: '',
            remember: true,
            showPassword: false,
        };
    }

    componentDidMount() {
        this.getErrorMessageFromURL();
    }

    validateUserSessions = () => {};
    onChange = (event: any) => {
        if (event.target.name === 'remember') {
            this.setState((prevState) => ({ ...prevState, remember: !prevState.remember }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value,
            }));
        }
    };

    handleClickShowPassword = (event: any) => {
        event.preventDefault();

        this.setState((prevState) => ({ ...prevState, showPassword: !prevState.showPassword }));
    };

    handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    getErrorMessageFromURL() {
        const messageTxt = CommonUtil.getQueryParam(this.props, 'error');
        if (messageTxt.length > 0) {
            this.setState((prevState) => ({ ...prevState, message: Message.error(messageTxt) }));
        }
    }
    performLogin = async () => {
        const { userName, password, remember } = this.state;

        try {
            const session: UserSession = await this.userAPIClient.login(
                userName,
                password,
                remember
            );
            SessionUtil.saveSession(session);
            const user: User = await this.userAPIClient.getUserProfile(userName);
            SessionUtil.saveUserInfo(user);
            ReactGA.set({ userId: user.username });

            // Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            this.props.navigate(SessionUtil.getAfterLoginPage(this.props), { replace: true });
        } catch (err) {
            this.setState((prevState) => ({ ...prevState }));
            this.setState((prevState) => ({ ...prevState, message: Message.fromError(err) }));
        }
    };

    clearMessage() {
        this.setState({ message: undefined });
    }

    render() {
        const { userName, password, message, remember } = this.state;
        const mode = localStorage.getItem('theme');
        const logo = mode === 'dark' ? desktopWhite : desktopDark;
        const search = window.location.search;
        return (
            <DocumentHeader title={'User login'}>
                <Stack justifyContent='center' minHeight='100%' alignItems={'center'}>
                    <Card elevation={5}>
                        <Stack
                            flexDirection='column'
                            alignItems='center'
                            gap={2}
                            padding={[5, 4]}
                            justifyContent={'center'}
                        >
                            <A
                                href={`https://www.investfly.com/${this.props.location.search}`}
                                newTab
                            >
                                <Box component='img' mb={1} src={logo} alt='investfly logo' />
                            </A>
                            <Typography variant='body1' gutterBottom>
                                Login with your username
                            </Typography>
                            <Box
                                component={'form'}
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                                gap={1}
                                width={{ xs: '100%', md: 350 }}
                                marginTop={0.125}
                                noValidate
                            >
                                <InflyMessage
                                    message={message}
                                    onClose={() => this.clearMessage()}
                                />
                                <TextField
                                    margin='dense'
                                    required
                                    fullWidth
                                    size={'medium'}
                                    id='userName'
                                    value={userName}
                                    label='Username'
                                    name='userName'
                                    autoComplete='userName'
                                    onChange={this.onChange}
                                    autoFocus
                                />
                                <FormControl variant='outlined' size={'medium'} fullWidth>
                                    <InputLabel htmlFor='outlined-adornment-password'>
                                        Password
                                    </InputLabel>
                                    <OutlinedInput
                                        margin='dense'
                                        required
                                        name='password'
                                        label='Password'
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        id='password'
                                        size={'medium'}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                    edge='end'
                                                >
                                                    {this.state.showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        value={password}
                                        onChange={this.onChange}
                                        autoComplete='current-password'
                                    />
                                </FormControl>
                                <Grid
                                    container
                                    flexDirection={{
                                        sm: 'row',
                                        xs: 'column',
                                    }}
                                    justifyContent={{
                                        sm: 'space-between',
                                        xs: 'center',
                                    }}
                                    alignItems={{
                                        sm: 'center',
                                        xs: 'flex-start',
                                    }}
                                >
                                    <Grid item sm>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={remember}
                                                    onChange={this.onChange}
                                                    name='remember'
                                                    color='primary'
                                                />
                                            }
                                            label='Remember me'
                                        />
                                    </Grid>
                                    <Grid item sm>
                                        <Link
                                            href='/forgotpassword'
                                            variant='body2'
                                            sx={{ float: 'right' }}
                                        >
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                </Grid>
                                <ActionButton
                                    type='submit'
                                    size={'large'}
                                    fullWidth
                                    color='primary'
                                    onClick={this.performLogin}
                                    variant='contained'
                                    label="Sign in"
                                >
                                
                                </ActionButton>
                            </Box>
                            <Typography align={'center'}>
                                Not a member? Please click&nbsp;{' '}
                                <A href={`/register${search}`}>here</A>
                                &nbsp;to Register
                            </Typography>
                        </Stack>
                        <Stack
                            gap={2}
                            justifyContent='space-between'
                            padding={[2, 2.8]}
                            flexDirection={'row'}
                        >
                            <A href={`${ROOT_DOMAIN}${search}`}>©&nbsp;Investfly </A>

                            <A href={`${ROOT_DOMAIN}/contact${search}`}>Contact</A>

                            <A href={`${ROOT_DOMAIN}/terms-of-service${search}`}>Privacy & Terms</A>
                        </Stack>
                    </Card>
                </Stack>
            </DocumentHeader>
        );
    }
}

export default withRouter(withEmptySession(LoginForm));
