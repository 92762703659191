import {
    WatchList,
    Message,
    ScreenerResult,
    SymbolSummaryInfo,
    ScreenerModel,
    QuoteField,
    SecurityPriceChange,
} from 'api';
import { AppContext } from 'util/appContext';
import { Component, useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React from 'react';
import { SelectOption, SelectField } from 'containers/common/SelectField';
import { InflyIcon } from 'containers/common/InflyIcon';
import { DataGrid, GridApi, GridColDef, GridRenderCellParams, GridRowSelectionModel, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { MenuOption, MoreMenu } from 'containers/common/MoreMenu';
import { NewScreenerDialogButton, ScreenerFormDialog } from 'containers/Screener/ScreenerForm';
import { useNavigate } from 'react-router-dom';
import { SymbolField } from 'containers/common/SymbolField';
import SessionUtil from 'util/SessionUtil';
import { CreateWatchListDialog, DeleteWatchListDialog, NewWatchListBtn } from 'containers/WatchList/WatchListCreateDeleteDialog';
import { OPERATION, User, USER_LIMITS } from 'api';


export enum StockListCategory {
    WATCHLISTS = 'WATCHLISTS',
    DEFAULTS = 'DEFAULTS',
    SCREENERLISTS = 'SCREENERLISTS',
    EMPTY = 'EMPTY'
}

export interface StockListProps {
    // because the data comes from SecurityPriceChange or ScreenerResultRow, which only includes ticker symbol, we cannot pass Security object
    onStockSelection: (symbol: string) => void; 
}


interface StockListEntry extends SelectOption {
    // // since we show watchlist and screener on the same select, both watchlist and screener can have the same id
    // hence, we will use prefix to make value unique
    id: number;
    type: StockListCategory;
}

const GAINERSID = -1;
const LOSERSID = -2;


export function StockListCard(props: StockListProps): React.JSX.Element {

    const navigate = useNavigate();


    const gainersLosersOptions: StockListEntry[] = [{ label: "SP500 Gainers", value: GAINERSID, id: GAINERSID, type: StockListCategory.DEFAULTS },
    { label: "SP500 Losers", value: LOSERSID, id: LOSERSID, type: StockListCategory.DEFAULTS }]

    const [stockLists, setStockLists] = useState<StockListEntry[]>(gainersLosersOptions);

    const [selectedStockList, setSelectedStockList] = useState<StockListEntry>(gainersLosersOptions[0]);

    const [stockListData, setStockListData] = useState<SecurityPriceChange[]>();

    const [newScreenerDialogOpen, setNewScreenerDialogOpen] = useState(false);
    const [newWatchListDialogOpen, setNewWatchListDialogOpen] = useState(false);
    const [deleteWatchListDialogOpen, setDeleteWatchListDialogOpen] = useState(false);


    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);



    useEffect(() => {
        async function fetchScreenersWatchLists() {
            let watchListApiClient = AppContext.getInstance().watchListApiClient;
            let screenerAPIClient = AppContext.getInstance().screenerApiClient;
            const watchLists: WatchList[] = await watchListApiClient.listWatchLists();
            const screeners: ScreenerModel[] = await screenerAPIClient.getMyScreeners();

            let watchListOptions: StockListEntry[] = watchLists.map(wl => {
                return { label: wl.name, value: `${StockListCategory.WATCHLISTS}_${wl.id}`, id: wl.id, type: StockListCategory.WATCHLISTS }
            }
            )
            let screenerListOptions: StockListEntry[] = screeners.map(sc => {
                return { label: sc.name, value: `${StockListCategory.SCREENERLISTS}_${sc.screenerId}`, id: sc.screenerId, type: StockListCategory.SCREENERLISTS }
            })


            let allLists: StockListEntry[] = [...gainersLosersOptions, ...watchListOptions, ...screenerListOptions];
            
            if(watchListOptions.length == 0 && screenerListOptions.length == 0){
                let emptyOption = { label: "New Stock List", value: 0, id: 0, type: StockListCategory.EMPTY }
                allLists.splice(0, 0, emptyOption); // insert at index 0
            }
            setStockLists(allLists);

            let selectedFromSession: string|null = SessionUtil.getFromSessionStorage("SelectedStockList");
            let defaultSelected: StockListEntry = selectedFromSession ? JSON.parse(selectedFromSession) : allLists[0];
            if(defaultSelected.type == StockListCategory.SCREENERLISTS){
                // selected screener id that is stored in session could have been deleted from the screener list page
                let screenerAPIClient = AppContext.getInstance().screenerApiClient;
                try{
                    await screenerAPIClient.getScreener(defaultSelected.id); //
                }catch(error){
                    SessionUtil.removeFromLocalStorage('SelectedStockList');
                    defaultSelected = gainersLosersOptions[0]; // revert to gainers
                }
            }
            onStockListSelect(defaultSelected); // to load data, the default selected is already be set
        }
        fetchScreenersWatchLists();
    
    }, [])

    async function runScreener(screenerId: number) {
        let screenerAPIClient = AppContext.getInstance().screenerApiClient;
        let screenerResult: ScreenerResult = await screenerAPIClient.refreshScreener(screenerId);
        let screenerData: SecurityPriceChange[] = screenerResult.results.map(row => {
            const symbol = row.get("Symbol");
            const lastPrice = row.get(QuoteField.LASTPRICE);
            const percentChange = row.get(QuoteField.DAY_CHANGE_PCT);
            return { symbol, lastPrice, percentChange };
        })
        setStockListData(screenerData);

    }

    async function fetchWatchListStocks(watchListId: number) {
        let watchListApiClient = AppContext.getInstance().watchListApiClient;
        let watchList: WatchList = await watchListApiClient.getWatchList(watchListId);
        setStockListData(watchList.securities)
    }

    async function fetchGainersLosers(gainersLosersId: number) {
        let marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        let gainersLosers = gainersLosersId === GAINERSID ? await marketDataApiClient.getGainers() : await marketDataApiClient.getLosers();
        setStockListData(gainersLosers);
    }

    function onStockListSelect(selectedOption: SelectOption) {
        let selectedList = selectedOption as StockListEntry;
        let selectedId: number = selectedList.id;
        setSelectedStockList(selectedList);

        if(selectedList.type == StockListCategory.SCREENERLISTS || selectedList.type == StockListCategory.WATCHLISTS){
            SessionUtil.saveInSessionStorage("SelectedStockList", JSON.stringify(selectedList));
        }
        
        setStockListData(undefined);

        if (selectedList.type === StockListCategory.SCREENERLISTS) {
            runScreener(selectedId);
        } else if (selectedList.type === StockListCategory.WATCHLISTS) {
            fetchWatchListStocks(selectedId);
        } else if (selectedList.type === StockListCategory.DEFAULTS) {
            fetchGainersLosers(selectedId);
        }
    }


    function getColumnDef(): GridColDef[] {
        let columns: GridColDef[] = [
            { field: 'symbol', headerName: 'Symbol' },
            { field: 'lastPrice', headerName: 'LastPrice', flex:1 },
            { field: 'percentChange', headerName: 'Change(%)', flex:1, renderCell: (params: GridRenderCellParams) => {
                return <Typography  color={params.row.percentChange >= 0 ? 'common.green' : 'common.red'}>{params.row.percentChange}%</Typography>;
            } },
        ];

        if (selectedStockList.type == StockListCategory.WATCHLISTS) {
            columns.push({
                field: 'delete', width: 50, headerName: 'Del', renderCell: (params: GridRenderCellParams) => {
                    return <IconButton onClick={e => { e.stopPropagation(); deleteSymbolFromWatchList(params.row.symbol) }}><InflyIcon name='delete' /></IconButton>;
                }
            })
        }
        return columns;
    }

    function createMenuOptions(): MenuOption[] {
        let menuOptions: MenuOption[] = [
            { icon: 'screeners', label: 'New Screener', onClick: handleNewScreener },
            { icon: 'watchList', label: 'New WatchList', onClick: handleNewWatchList }
        ];
        if (selectedStockList.type == StockListCategory.SCREENERLISTS) {
            menuOptions.push({ icon:'openInNew', label: 'Go to Screener Page', onClick: handleViewScreener })
        }
        if(selectedStockList.type == StockListCategory.WATCHLISTS){
            menuOptions.push({ icon: 'delete', label: 'Delete WatchList', onClick: () => setDeleteWatchListDialogOpen(true) })
        }
        return menuOptions;
    }

    function handleViewScreener() {
        navigate(`/screener/${selectedStockList.id}`)
    }

    function handleNewScreener() {
        const userInfo: User = SessionUtil.findUserInfo() as User;
        const userType = userInfo.userType;
        const limit: number = USER_LIMITS[userType][OPERATION.CREATE_SCREENER] as number;
        const curCount = stockLists.filter(sl => sl.type == StockListCategory.SCREENERLISTS).length;
        if(curCount < limit){
            setNewScreenerDialogOpen(true);
        }else{
            navigate('/upgrade/pricing')
        }
        
    }
    function onNewScreenerDiaglogReturn(screener: ScreenerModel, screenerResult?: ScreenerResult, message?: Message) {
        setNewScreenerDialogOpen(false);
        let newScreenerListEntry: StockListEntry = { label: screener.name, value: `${StockListCategory.SCREENERLISTS}_${screener.screenerId}`, id: screener.screenerId, type: StockListCategory.SCREENERLISTS };
        setStockLists([...stockLists, newScreenerListEntry]);
        setSelectedStockList(newScreenerListEntry);
        SessionUtil.saveInSessionStorage("SelectedStockList", JSON.stringify(newScreenerListEntry));
        let screenerData: SecurityPriceChange[] = screenerResult!.results.map(row => {
            const symbol = row.get("Symbol");
            const lastPrice = row.get(QuoteField.LASTPRICE);
            const percentChange = row.get(QuoteField.DAY_CHANGE_PCT);
            return { symbol, lastPrice, percentChange };
        })
        setStockListData(screenerData);
    }

    function handleNewWatchList() {
        const userInfo: User = SessionUtil.findUserInfo() as User;
        const userType = userInfo.userType;
        const limit: number = USER_LIMITS[userType][OPERATION.CREATE_WATCHLIST] as number;
        const curCount = stockLists.filter(sl => sl.type == StockListCategory.WATCHLISTS).length;
        if(curCount < limit){
            setNewWatchListDialogOpen(true);
        }else{
            navigate('/upgrade/pricing')
        }
        
    }

    function onNewWatchListDialogReturn(watchList: WatchList) {
        setNewWatchListDialogOpen(false)
        let newWatchListEntry: StockListEntry = { label: watchList.name, value: `${StockListCategory.WATCHLISTS}_${watchList.id}`, id: watchList.id, type: StockListCategory.WATCHLISTS };
        setStockLists([...stockLists, newWatchListEntry]);
        setSelectedStockList(newWatchListEntry);
        SessionUtil.saveInSessionStorage("SelectedStockList", JSON.stringify(newWatchListEntry));
        setStockListData(watchList.securities)
    }

    function onDeleteWatchListDialogReturn(message: Message){
        setDeleteWatchListDialogOpen(false);
        let newStockLists = stockLists.filter(sle => !(sle.type === StockListCategory.WATCHLISTS && sle.id == selectedStockList.id));
        setStockLists(newStockLists);
        setSelectedStockList(gainersLosersOptions[0]);
        SessionUtil.saveInSessionStorage("SelectedStockList", JSON.stringify(gainersLosersOptions[0]));
        fetchGainersLosers(gainersLosersOptions[0].id);
    }

    async function onNewWatchListSymbolSelect(tickerSymbol: SymbolSummaryInfo) {
        let watchListApiClient = AppContext.getInstance().watchListApiClient;
        let watchList = await watchListApiClient.addSecurityToWatchList(selectedStockList.id, tickerSymbol.symbol);
        setStockListData(watchList.securities);
    }

    async function deleteSymbolFromWatchList(symbol: string) {
        let watchListApiClient = AppContext.getInstance().watchListApiClient;
        let watchList = await watchListApiClient.removeSecurityFromWatchList(selectedStockList.id, symbol);
        setStockListData(watchList.securities);
    }

    function CustomToolbar(): React.JSX.Element {
        return (
            <GridToolbarContainer>
                <SymbolField label='Add Symbol to WatchList' includeIndex={false} onClear={() => { }} onChange={onNewWatchListSymbolSelect} />
            </GridToolbarContainer>
        );
    }

    return (<>

        {newScreenerDialogOpen &&
            <ScreenerFormDialog onSubmit={onNewScreenerDiaglogReturn} onCancel={() => setNewScreenerDialogOpen(false)} />
        }

        {newWatchListDialogOpen &&
            <CreateWatchListDialog onCreate={onNewWatchListDialogReturn} onCancel={() => setNewWatchListDialogOpen(false)} />
        }

        {deleteWatchListDialogOpen && 
            <DeleteWatchListDialog watchListId={selectedStockList.id} onDelete={onDeleteWatchListDialogReturn} onCancel={() => setDeleteWatchListDialogOpen(false)} />
        }

        <Card>
            <CardHeader
                title={<SelectField label='Select List' options={stockLists} value={selectedStockList.value} onSelectChange={onStockListSelect} />}
                action={<MoreMenu menuOptions={createMenuOptions()} />}
            />

            <CardContent>

                {selectedStockList.type != StockListCategory.EMPTY && stockListData === undefined && 
                     <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
                     <CircularProgress />
                 </Stack>
                }

                { selectedStockList.type != StockListCategory.EMPTY && stockListData &&
                
                    <Stack spacing={2}>
                        
                        <Typography variant='body2'> Click on the stock row below to loads its chart and details </Typography>
                        <DataGrid
                            rows={stockListData}
                            getRowId={row => row.symbol}
                            columns={getColumnDef()}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            pageSizeOptions={[10]}
                            slots={selectedStockList.type === StockListCategory.WATCHLISTS ? {
                                toolbar: CustomToolbar,
                            } : {}}

                            onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                                if(newRowSelectionModel.length > 0){
                                    // this function is called also when datagrid loads with new data with unselected row, its undefined
                                    props.onStockSelection(newRowSelectionModel[0] as string)
                                }
                                
                            }}
                            rowSelectionModel={rowSelectionModel}
                        />
                    </Stack>
                
                }

                {selectedStockList.type == StockListCategory.EMPTY && 
                    <Stack gap={3}>
                       <Typography variant='body2'>Click the buttons below to create Stock List. After you create stock list, click on the stock symbol to load its details on the right pane</Typography>
                       
                       <Stack direction={'row'} gap={2}>
                            <NewScreenerDialogButton curScreenerCount={0} showSample onSubmit={onNewScreenerDiaglogReturn}/>
                            <Divider orientation='vertical' />
                            <NewWatchListBtn curWatchListCount={0} onCreate={onNewWatchListDialogReturn} />
                        </Stack>
                    </Stack>
                }

            </CardContent>

        </Card>

    </>);

}

