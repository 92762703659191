/** @format */

import { Button, Card, CardActions, CardContent, CardHeader, Paper } from '@mui/material';
import {
    AppConstant,
    BrokerAPIClient,
    BrokerType,
    PortfolioAPIClient,
    PortfolioIdentifier,
} from 'api';
import { Message } from 'api/models/common/message';
import { ActionButton } from 'containers/common/ActionButton';
import { InflyMessage } from 'containers/common/InflyMessage';
import React from 'react';
import { AppContext } from 'util/appContext';
import SessionUtil from 'util/SessionUtil';

interface PortfolioErrorCardProps {
    portfolio: PortfolioIdentifier;
    onMessage: (message: Message) => void;
}

export class PortfolioErrorCard extends React.Component<PortfolioErrorCardProps, {}> {
    portfolioAPIClient: PortfolioAPIClient;
    brokerAPIClient: BrokerAPIClient;

    constructor(props: PortfolioErrorCardProps) {
        super(props);
        this.portfolioAPIClient = AppContext.getInstance().portfolioApiClient;
        this.brokerAPIClient = AppContext.getInstance().brokerApiClient;
    }

    getTradierAuthUrl() {
        const username = SessionUtil.findSession()?.username;
        const tradierClientId = AppConstant.TRADIER_CLIENT_ID;
        return (
            'https://api.tradier.com/v1/oauth/authorize?client_id=' +
            tradierClientId +
            '&scope=read,market,trade,stream&state=' +
            username
        );
    }

    getCreateTradierAccountURL() {
        return 'https://brokerage.tradier.com/pricing';
    }

    getCreateTDAAccountURL() {
        return 'https://www.tdameritrade.com/why-td-ameritrade/free-stock-trading.html';
    }

    getTdAmeritradeAuthUrl() {
        const redirectUrl = AppConstant.TD_AMERITRADE_REDIRECT_URL;
        const clientId = AppConstant.TD_AMERITRADE_CLIENT_ID;
        return (
            'https://auth.tdameritrade.com/auth?response_type=code&redirect_uri=' +
            redirectUrl +
            '&client_id=' +
            clientId
        );
    }

    getAuthUrl = (): string => {
        const broker = this.props.portfolio.brokerType;
        if (broker === BrokerType.TRADIER) return this.getTradierAuthUrl();
        if (broker === BrokerType.TDAMERITRADE) return this.getTdAmeritradeAuthUrl();
        throw new Error('Unexpected broker type ' + broker);
    };

    onPortfolioDelete = async () => {
        try {
            await this.portfolioAPIClient.deleteVirtualPortfolio(this.props.portfolio.portfolioId);
        } catch (error) {
            this.props.onMessage(Message.fromError(error));
        }
    };

    render(): React.ReactNode {
        let message =
            this.props.portfolio.brokerType == BrokerType.INVESTFLY
                ? 'Your portfolio is in bad state and cannot be loaded. Please delete or contact u'
                : 'Connection to your broker account is broken. Please try to reconnect or contact us';

        return (
            <Card>
                <CardHeader title='Error' />
                <CardContent>
                    <InflyMessage message={Message.error(message)} />
                </CardContent>
                <CardActions>
                    {this.props.portfolio.brokerType === BrokerType.INVESTFLY && (
                        <ActionButton label='Delete' onClick={this.onPortfolioDelete} />
                    )}
                    {this.props.portfolio.brokerType !== BrokerType.INVESTFLY && (
                        <Button href={this.getAuthUrl()}>Reconnect</Button>
                    )}
                </CardActions>
            </Card>
        );
    }
}
