/** @format */

import React from 'react';
import { Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';

export default function Search(props: InputBaseProps) {
    const { onChange, fullWidth, ...rest } = props;
    return (
        <Box
            component={'div'}
            position='relative'
            border={1}
            borderRadius={2}
            width={'100%'}
            sx={(theme) => ({
                borderColor:
                    theme.palette.mode === 'light' ? `rgb(224, 227, 231)` : `rgb(19, 47, 76)`,
                background: theme.palette.background.paper,
                '&:hover': {
                    border: 1,
                    background:
                        theme.palette.mode === 'light'
                            ? `rgba(231, 235, 240, 0.7) none repeat scroll 0% 0%`
                            : 'rgba(19, 47, 76, 0.4) none repeat scroll 0% 0%',
                    borderColor:
                        theme.palette.mode === 'light' ? `rgb(205, 210, 215)` : `rgb(23, 58, 94)`,
                },
                float: 'right',
                [theme.breakpoints.up('sm')]: {
                    marginLeft: theme.spacing(1),
                    width: 'auto',
                },
            })}
        >
            <InputBase
                startAdornment={<SearchIcon />}
                size={'medium'}
                {...rest}
                onChange={(event) => {
                    if (typeof props.onChange === 'function') {
                        props?.onChange(event);
                    }
                }}
                sx={(theme) => ({
                    '& .MuiSvgIcon-root': {
                        width: '1.3em',
                        color: theme.palette.primary.main,
                        height: '0.998em',
                    },
                    '& .root': {
                        color: 'inherit',
                        padding: [1, 3],
                    },
                    '& .input': {
                        transition: 'width .3s',
                        width: '100%',
                        [theme.breakpoints.up('sm')]: {
                            width: `15ch`,
                            '&:focus': {
                                width: '20ch',
                            },
                        },
                    },
                })}
            />
        </Box>
    );
}
