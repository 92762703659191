import { Stack, Button, Typography, Card, CardHeader, CardContent, Paper, Divider } from "@mui/material";
import { Message, StrategyAPIClient, Portfolio, BrokerType, PortfolioIdentifierName, OPERATION, DateTimeUtils } from "api";
import { DeploymentLog, TradingStrategyModel } from "api/models/strategy/TradingStrategyModel";
import { DataDisplayCard } from "containers/common/DataDisplayCard";
import InflyDialog from "containers/common/InflyDialog";
import { InflyMessage } from "containers/common/InflyMessage";
import { PortfolioSelect } from "containers/common/PortfolioSelect";
import React, { useEffect, useRef, useState } from "react";
import { AppContext } from "util/appContext";
import A from "containers/common/Anchor";
import UpgradeCheckButton from "containers/common/UpgradeCheckButton";
import MonacoEditor from '@monaco-editor/react';
import { InflyIcon } from "containers/common/InflyIcon";


type StrategyDeploymentCardProps = {
    strategyModel: TradingStrategyModel
    onDeployUndeployComplete: (strategyModel: TradingStrategyModel) => void;
}



export function StrategyDeploymentCard({strategyModel, onDeployUndeployComplete}:StrategyDeploymentCardProps ): React.JSX.Element {

    const [deployDialogOpen, setDeployDialogOpen] = useState(false);
    const [undeployDialogOpen, setUndeployDialogOpen] = useState(false);

    const [portfolioName, setPortfolioName] = useState<string>();
    const [deploymentLogs, setDeploymentLogs] = useState<DeploymentLog[]>(strategyModel.deploymentLogs);

    const timerRef = useRef(0);

    function getDeploymentInfo(): Map<string, string> {
        let info = new Map();
       
        if(strategyModel?.portfolioId){
            info.set("Status", strategyModel!.portfolioId ? "Deployed" : "Undeployed");
            info.set("Deployed Portfolio", <A href={`/portfolio/${strategyModel!.brokerType}/${strategyModel!.portfolioId}`} >{portfolioName}</A> );
        }
        return info;
    }

    async function loadDeploymentLogs(){
        let strategyApiClient = AppContext.getInstance().strategyApiClient;
        let newDeploymentLogs = await strategyApiClient.getStrategyLogs(strategyModel.strategyId);
        setDeploymentLogs(newDeploymentLogs);
    }

    useEffect( () => {
        async function fetchPortfolioName(portfolioId: string, broker: BrokerType){
            let portfolioApiClient = AppContext.getInstance().portfolioApiClient;
            let portfolio = await portfolioApiClient.getPortfolio(portfolioId, broker);
            setPortfolioName(portfolio.name);
        }
        if(strategyModel.portfolioId && strategyModel.brokerType){
            fetchPortfolioName(strategyModel.portfolioId, strategyModel.brokerType);
        }

        if(timerRef.current == 0){
            let intervalId = setInterval(loadDeploymentLogs, 10000)
            timerRef.current = intervalId as any as number;
        }

        return () => {
            if(timerRef.current > 0){
                clearInterval(timerRef.current);
                timerRef.current = 0;
            }
        }

    }, [])

    function getDeployUndeployAction(): React.JSX.Element {
        if(strategyModel.portfolioId){
            return <Stack spacing={1} direction={"row"}>
                    {/* <Button onClick={() => {}}>Execution Logs</Button> */}
                    <Button onClick={e => setUndeployDialogOpen(true)}>Undeploy</Button>
                </Stack>
        }else{
            return <UpgradeCheckButton op={OPERATION.DEPLOY_STRATEGY} label='Deploy'>
                <Button onClick={e => setDeployDialogOpen(true)}>Deploy</Button>
            </UpgradeCheckButton> 
        }
    }

    function onDeployReturn( newStrategyModel: TradingStrategyModel, portfolioName: string ) {
        setDeployDialogOpen(false);
        setPortfolioName(portfolioName);
        onDeployUndeployComplete(newStrategyModel);      
    }

    function onUndeployReturn(newStrategyModel: TradingStrategyModel){
        setUndeployDialogOpen(false);
        setPortfolioName(undefined);
        onDeployUndeployComplete(newStrategyModel);
    }

    let deploymentLogsStr: string = ""
    if(deploymentLogs){
        deploymentLogsStr = deploymentLogs.map(log => `${DateTimeUtils.toEstString(log.date)} - ${log.level} - ${log.message}`).join("\n");
    }
    

    return(
        <Stack spacing={1}>

            { undeployDialogOpen && 
               <UnDeployStrategyDialogComp strategyModel={strategyModel} onUnDeploy={onUndeployReturn} onCancel={() => setUndeployDialogOpen(false)} />
            }

            {deployDialogOpen && 
                <DeployStrategyDialogComp strategyModel={strategyModel} onDeploy={onDeployReturn} onCancel={() => setDeployDialogOpen(false)}/>
            }

            <DataDisplayCard title='Deployment' 
                    data={getDeploymentInfo()} 
                    cardActionHeader={getDeployUndeployAction()}>

                    {strategyModel.portfolioId === undefined && 
                    
                    <Paper elevation={5}>
                            <Stack gap={3} padding={4}>
                                <Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography variant='h6'> Tip</Typography>
                                        <InflyIcon name='tip' />
                                    </Stack>
                                    <Divider/>
                                </Stack>
  
                                <Typography variant='body2'>
                                    Deploy your strategy to a virtual portfolio (i.e paper trading account) to forward-test your strategy. Finally, deploy it to your connected brokerage account to go live with your strategy.
                                </Typography>

                            </Stack>
                        </Paper>
                    }
            </DataDisplayCard>
            

            <Card>
                <CardHeader title='Deployment Logs' />
                <CardContent>
                    <MonacoEditor
                            value={deploymentLogsStr}
                            language='python'
                            width={`100%`}
                            height={800}
                            theme={'vs-dark'}
                            options={{
                                // lineHeight: 1,
                                // tabIndex: 4,
                                readOnly: true,
                                glyphMargin: false,
                                matchBrackets: 'always', // type is  'never' | 'near' | 'always'
                                lineNumbers: 'off', //type is LineNumbersType
                                minimap: { enabled: false }, // type is IEditorMinimapOptions
                                scrollbar: {
                                    // vertical: 'auto',
                                    // horizontal: 'hidden',
                                    // handleMouseWheel: true,
                                }, //type is IEditorScrollbarOptions
                                // tabSize: 4,
                                wordWrap: 'on',
                            }}
                            onChange={(value, event) => {
                            }}
                        />
                </CardContent>
                
            </Card>

        </Stack>)
}

// -----------------------------------------------------------DeployStrategyDialogComp--------------------------------------------------------------------


type DeployStrategyDialogProps = {
    strategyModel: TradingStrategyModel;
    onDeploy: (strategyModel: TradingStrategyModel, portfolioName: string) => void;
    onCancel: () => void;
}

function DeployStrategyDialogComp({strategyModel, onDeploy, onCancel}: DeployStrategyDialogProps): React.JSX.Element {

    const [selectedPortfolio, setSelectedPortfolio] = useState<PortfolioIdentifierName>();
    const [message, setMessage] = useState<Message>();


    async function onExecute(){
        let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
        if(selectedPortfolio === undefined){
            setMessage(Message.error("Please select a portfolio first"));
            return;
        }
        try{
            let newStrategyModel: TradingStrategyModel = await strategyApiClient.deployStrategy(strategyModel.strategyId, selectedPortfolio.portfolioId, selectedPortfolio.brokerType)
            onDeploy(newStrategyModel, selectedPortfolio.name);
        }catch(error){
            setMessage(Message.fromError(error));
        }
    }

    return <>
        <InflyDialog open title='Deploy Strategy' onClose={onCancel} onExecute={onExecute} okText='Deploy'>
            <Stack spacing={1}>
                <InflyMessage message={message} onClose={() => setMessage(undefined)} />
                <PortfolioSelect currentSelection={selectedPortfolio} onPortfolioSelect={portfolio => setSelectedPortfolio(portfolio)}/>
            </Stack>
        </InflyDialog>  
    </>
}


// -----------------------------------------------------------UnDeployStrategyDialogComp--------------------------------------------------------------------

export type UnDeployStrategyDialogProps = {
    strategyModel: TradingStrategyModel;
    onUnDeploy: (strategyModel: TradingStrategyModel) => void;
    onCancel: () => void;
}

export function UnDeployStrategyDialogComp({strategyModel, onUnDeploy, onCancel}: UnDeployStrategyDialogProps): React.JSX.Element {

    const [message, setMessage] = useState<Message>();

    async function onExecute(){
        try{
            let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
            let newStrategyModel = await strategyApiClient.unDeployStrategy(strategyModel.strategyId);
            onUnDeploy(newStrategyModel)
        }catch(error){
            setMessage(Message.fromError(error));
        }

    }

    return <>
        <InflyDialog open title='Undeploy Strategy' onExecute={onExecute} onClose={onCancel} okText='Undeploy'>
            <Stack spacing={1}>
                <InflyMessage message={message} onClose={() => setMessage(undefined)} />    
                <Typography>Are you sure you want to undeploy this strategy?</Typography>
            </Stack>
        </InflyDialog>
    </>
}


