export const AppConstant: {[index:string]: string} = {
    APP_URL: process.env.REACT_APP_ENV === 'production' ?  "https://app.investfly.com" : "https://app.finzle.com",
    ROOT_DOMAIN: process.env.REACT_APP_ENV === 'production'? "https://www.investfly.com" : "https://www.finzle.com",
    API_URL: process.env.REACT_APP_ENV === 'production' ?  "https://api.investfly.com" : (process.env.REACT_APP_ENV ==="dev" ? "http://localhost:8080": (process.env.NODE_ENV==="test" ? "http://api.finzle.com" : "https://api.finzle.com")),
    TRADIER_CLIENT_ID: process.env.REACT_APP_ENV === 'production' ?  "AOu9nvLAgjOuEsCpxwbrGVatm3GKlAHg" : "nrY2A0EhmBLFc3WxnaZwMI66GvPrbSI4",
    TD_AMERITRADE_REDIRECT_URL: process.env.REACT_APP_ENV === 'production'? "https://app.investfly.com/broker/tamer": "https://app.investfly.com/broker/tamer",
    TD_AMERITRADE_CLIENT_ID: process.env.REACT_APP_ENV === 'production'? "invflydefef12@AMER.OAUTHAP": "invflydefef12@AMER.OAUTHAP",
    TRADE_STATION_CLIENT_ID: "4854416E-FC19-4776-B7AB-618F0226C46B",
    TRADE_STATION_API_URL: process.env.REACT_APP_ENV === 'production'? "https://api.tradestation.com": "https://sim-api.tradestation.com",
    INVESTFLY_BROKER_FEE : '5',
    STRIPE_KEY: process.env.REACT_APP_ENV === 'production'?  "pk_live_xT3IaQR1tMqBUQmfbMvDDoiK" :  "pk_test_8fftUWj3bVNr544ttjogqagZ",
    GOOGLE_ANALYTICS_ID: process.env.REACT_APP_ENV === 'production'?  "G-QQE109DKMP" :  "G-M2JEJJKSV2",
} 