export  class InvestflyUtil  {


    static getEnumKeyByEnumValue<T extends {[index:string]:string}>(myEnum:T, enumValue:string):keyof T|null {
        let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
        return keys.length > 0 ? keys[0] : null;
    }

    static isStringEnum<T extends {[index:string]:string}>(myEnum:T, key:string): boolean {
        // Note that even when enum key and value are different like in ComparisionOperator.GREATER_THAN = '>'
        // JSON value thats serialized form backend will contain Key (not value), so checking for key membership is OK
        return Object.keys(myEnum).includes(key)
    }



    static encode(value: string): string {
        return  btoa(value);
    }
    static getAppToken() {
        const curTimeStamp = !Date.now ? new Date().getTime() : Date.now();
        return  this.encode(`${curTimeStamp}`);
    }

    static strToDate(str?:string): Date|undefined {
        if(str === undefined || str === null || str.length === 0) return undefined;
        return new Date(str);
    }

    static isNumber(str: string): boolean {
        let n: number = Number(str);
        return !isNaN(n)
    }

    static replaceArrayItem(arr: string[], search: string, replace: string): string[]{
        return arr.map(item => {
            if(item == search){
                return replace;
            }else{
                return item;
            }
        })
    }

    
}
