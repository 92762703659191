/** @format */

import React, { Component, useEffect, useState } from 'react';
import { Button, Chip, DialogContent, Paper, Stack, Typography } from '@mui/material';
import { Message, StockAlert, StockAlertAPIClient, TriggeredStock } from 'api';
import { AppContext } from 'util/appContext';
import InflyTable from 'containers/common/InflyTable';
import InflyDialog from 'containers/common/InflyDialog';
import InflyProgress from 'containers/common/InflyProgress';
import A from 'containers/common/Anchor';
import { InflyMessage } from 'containers/common/InflyMessage';
import { LoadingCard } from 'containers/common/LoadingCard';
import { ErrorCard } from 'containers/common/ErrorCard';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { MessageListenerProp } from 'util/commonProps';
import { on } from 'events';


export function GeneratedAlertsCountLink({stockAlert, onMessage}: {stockAlert: StockAlert} & MessageListenerProp): React.JSX.Element {

    const [genAlerts, setGenAlerts] = useState<TriggeredStock[]>();
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        async function loadGeneratedAlerts(){
            let stockAlertApiClient = AppContext.getInstance().stockAlertApiClient;
            try{
                let triggeredStocks = await stockAlertApiClient.getTriggeredStocks(stockAlert.alertId);
                setGenAlerts(triggeredStocks);
            }catch(error){
                onMessage(Message.fromError(error));
            }
        }
        loadGeneratedAlerts()
    }, [stockAlert]
    );

    if (!genAlerts){
        return <Typography>...</Typography>
    }

    if(genAlerts.length === 0){
        return <Typography>0</Typography>
    }


    return <>
        {showDialog && <GeneratedAlertsDialog genAlerts={genAlerts} onDialogClose={() => setShowDialog(false)}/>}
        <Button variant='text' onClick={() => setShowDialog(true)}>{genAlerts.length}</Button>
    </>

}


export function GeneratedAlertsDialog({genAlerts, onDialogClose}: {genAlerts: TriggeredStock[], onDialogClose: ()=>void}): React.JSX.Element {

    const getColumns = (): GridColDef[] => {
        return [
            {field: "id", headerName: "ID"},
            {field: "symbol", headerName: "Symbol"},
            {field: "date", headerName: "Alerted At", flex: 1, valueFormatter: (params: GridValueFormatterParams<Date>) => {
                return `${params.value.toLocaleString()}`;
            }}
        ]
    }

    const getRows = () => {
        return genAlerts!.map( (row, index) => {
            row["id"] = index;
            return row
        })
    }

    return <>
        <InflyDialog title='Generated Alerts' open={true} onSubmit={() => onDialogClose()} onClose={() => onDialogClose()}>
            <Paper>

            <Stack gap={2}>
                <DataGrid 
                    rows={getRows()} 
                    columns={getColumns()} 
                    columnVisibilityModel={{  id: false }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10]}
                />      
            </Stack>
                
            </Paper>

        </InflyDialog>
    </>



}
