/** @format */

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Message, UserAPIClient, UserError } from 'api';
import React, { Component } from 'react';
import { AppContext } from 'util/appContext';
import { RouterAndThemeProps } from 'util/commonProps';
import withRouter from 'containers/HOC/WithRouter';
import withEmptySession from 'containers/HOC/RequireNoSession';
import { ActionButton } from 'containers/common/ActionButton';
import { InflyMessage } from 'containers/common/InflyMessage';
import DocumentHeader from 'containers/common/DocumentHeader';
import { InflyIcon } from '../common/InflyIcon';

interface ForgetPasswordFormProps extends RouterAndThemeProps {}

interface ForgetPasswordFormState {
    email: string;
    message?: Message;
}

class ForgetPasswordForm extends Component<ForgetPasswordFormProps, ForgetPasswordFormState> {
    userAPIClient: UserAPIClient;

    constructor(props: ForgetPasswordFormProps) {
        super(props);
        this.userAPIClient = AppContext.getInstance().userApiClient;

        this.state = {
            email: '',
        };
    }

    onChange = (event: any) => {
        this.setState({ email: event.target.value });
    };

    performForgetPassword = () => {
        const { email } = this.state;

        this.userAPIClient
            .forgetPassword(email)
            .then((msgObj: Message) => {
                this.setState((prevState) => ({
                    ...prevState,
                    error: false,
                    message: Message.success(msgObj.message),
                }));
            })
            .catch((error) => {
                if (error instanceof UserError) {
                    this.setState((prevState) => ({
                        ...prevState,
                        error: true,
                        message: Message.fromError(error),
                    }));
                } else {
                    this.setState((prevState) => ({
                        ...prevState,
                        error: true,
                        message: Message.error(
                            'Unexpected Error Occurred. Please email us with browser console log'
                        ),
                    }));
                }
            });
    };

    clearMessage() {
        this.setState({ message: undefined });
    }

    render() {
        const { email, message } = this.state;

        return (
            <DocumentHeader title={'Forgot Password'}>
                <InflyMessage message={message} onClose={() => this.clearMessage()} />
                <Box p={'100px'}>
                    <Card>
                        <CardHeader title={'Forget Password'} />
                        <CardContent>
                            <Stack gap={1} alignItems={'flex-start'}>
                                <Typography variant='body1' gutterBottom>
                                    Lost your password? Please enter your email address.
                                    You will receive a link to create a new password via email
                                </Typography>

                                    
                                    <TextField
                                        id='standard-password-input'
                                        fullWidth
                                        label='Email'
                                        value={email}
                                        size={'medium'}
                                        onChange={this.onChange}
                                        margin='dense'
                                    />
                                    <ActionButton

                                        color='primary'
                                        onClick={this.performForgetPassword}
                                        size={'medium'}
                                        label='Reset Password'
                                    >
         
                                    </ActionButton>

                                    <Button
                                        size={'small'}
                                        variant='text'
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            this.props.navigate('/');
                                        }}
                                    >
                                        <InflyIcon name={'goBack'} fontSize={'small'} />
                                        &nbsp;Login
                                    </Button>
                                
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </DocumentHeader>
        );
    }
}

export default withRouter(withEmptySession(ForgetPasswordForm));
