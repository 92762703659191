/** @format */

import {
    BrokerAccount,
    BrokerType,
    Portfolio,
    PortfolioIdentifier,
    PortfolioIdentifierName,
} from 'api';
import {SelectField, SelectOption } from 'containers/common/SelectField';
import React, { useEffect, useState } from 'react';
import { AppContext } from 'util/appContext';


interface PortfolioSelectProps {
    currentSelection?: PortfolioIdentifier;
    onPortfolioSelect: (portfolioId: PortfolioIdentifierName) => void;
    isValid?: (portfolio: Portfolio | BrokerAccount) => boolean; 
}




export function PortfolioSelect(props:PortfolioSelectProps): React.JSX.Element{

    const [allPortfoliosAccounts, setAllPortfoliosAccounts] = useState<PortfolioIdentifierName[]>([]);

    useEffect(() => {

        async function fetchAllPortfolios(){

            let portfolioApiClient = AppContext.getInstance().portfolioApiClient;
            let brokerApiClient = AppContext.getInstance().brokerApiClient;
    
            let allPortfolios: PortfolioIdentifierName[] = [];
    
            const p1 = brokerApiClient.getMyConnectedBrokers();
            const p2 = portfolioApiClient.getMyPortfolios();
            let [brokerAccounts, porfolios] = await Promise.all([p1, p2]);
    
            for (let portfolio of porfolios) {
                let isValid = Boolean(props.isValid && props.isValid(portfolio));
                if (isValid) {
                    allPortfolios.push({
                        portfolioId: portfolio.portfolioId,
                        brokerType: portfolio.broker,
                        name: portfolio.name,
                    });
                }
            }
            for (let brokerAccount of brokerAccounts) {
                let isValid = Boolean(props.isValid && props.isValid(brokerAccount));
                if (isValid) {
                    allPortfolios.push({
                        portfolioId: brokerAccount.accountId,
                        brokerType: brokerAccount.brokerType,
                        name: brokerAccount.accountId,
                    });
                }
            }

            setAllPortfoliosAccounts(allPortfolios);

        }

        fetchAllPortfolios();

    }, [])

    const selectOptions: SelectOption[] = allPortfoliosAccounts.map( p => {
        return {label: p.name, value: `${p.brokerType}_${p.portfolioId}`}
    } )

    
    function onSelectChange(selectedOption: SelectOption){
        const brokerAndId: string[] = selectedOption.value.split("_");
        let selectedPortfolio: PortfolioIdentifierName|undefined = allPortfoliosAccounts.find(p => p.portfolioId == brokerAndId[1] && p.brokerType == BrokerType[brokerAndId[0]])
        props.onPortfolioSelect(selectedPortfolio!);
    }

    const selectedPortfolio = props.currentSelection? `${props.currentSelection.brokerType}_${props.currentSelection.portfolioId}` : undefined;


    return (<>

        <SelectField
                    style={{ minWidth: '150px' }}
                    label='Select Portfolio'
                    options={selectOptions}
                    value={selectedPortfolio}
                    onSelectChange={onSelectChange}
                />
    </>)
}
