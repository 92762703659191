import { Box, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import CommonUtil from 'util/CommonUtil';

export interface LabelValueData {
    label: string;
    value?: string | number |  React.ReactElement
}

export function LabelAndValue({ label, value }: LabelValueData) {

    let valueColor = 'text.primary';

    let displayValue = value;
    if (value && typeof value === 'number' ) {
        displayValue = CommonUtil.numberWithCommas(value);
        if (label.includes('%')) {
            displayValue = displayValue + '%';
            if (value > 0) {
                valueColor = 'common.green';
            } else if (value < 0) {
                valueColor = 'common.red';
            }
        }
        if (label.includes('$')) {
            displayValue = '$' + displayValue;
        }
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
            <Typography component={'span'} color={'text.primary'} variant={'body2'}>
                {label}
            </Typography>
            { value == undefined &&
                <Typography component={'span'} color={'text.primary'} variant={'body2'}>
                    'N/A'
                </Typography>
            }
            {value && (React.isValidElement(value))  &&
                value
            }
            {value && !React.isValidElement(value) &&
                <Typography component={'span'} color={valueColor} variant={'body2'}>
                    {displayValue ?? 'N/A'}
                </Typography>
            }

        </Box>
    );
}
