/*
* Represents user error. The message in the error is safe to report to end user
*/
export class UserError extends Error {
    constructor(message: string){
        super(message);
    }
}

export class NotFoundException extends UserError {}

export class NotAuthorizedException extends UserError {}

export class NotAuthenticatedException extends UserError {}

export class InternalServerException extends Error {}

export class BadRequestException extends UserError {}