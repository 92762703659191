/** @format */

import { Alert, Box, Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';

export interface ErrorCardProps {
    title?: string;
    message: string;
}

export function ErrorCard(props: ErrorCardProps) {
    return (
        <Card>
            <CardHeader title={props.title?? 'Error'} />
            <CardContent>
                <Alert severity='error'>{props.message}</Alert>
            </CardContent>
        </Card>
    );
}
