export enum JobStatus {
    NOT_STARTED = "NOT_STARTED",
    QUEUED = "QUEUED", 
    INITIALIZING = "INITIALIZING",
    RUNNING = "RUNNING", 
    COMPLETE = "COMPLETE",
    ERROR = "ERROR"
}
export class BacktestStatus {
    jobStatus: JobStatus;
    percentComplete?: number;

    constructor(jobStatus: JobStatus){
        this.jobStatus = jobStatus;
    }

    static parseJSON(obj: {[index:string]: any}): BacktestStatus{
        let backtestStatus = new BacktestStatus(obj.jobStatus);
        backtestStatus.percentComplete = obj.percentComplete;
        return backtestStatus;
    }

}