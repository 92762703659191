import {Message} from '../models/common/message';
import {BadRequestException, NotAuthorizedException, NotAuthenticatedException, NotFoundException, InternalServerException} from '../models/common/userError';



export const InflyClientId = 'investfly-client-id';
export const InflyClientToken = 'investfly-client-token';


export class RestAPIClient {

    // isDevMode: boolean = false;
    baseUrl: string;
    headers: Map<string, string>;
    // agent: https.Agent;

    static debug: boolean = false;

    constructor(baseUrl: string) {

        this.baseUrl = baseUrl;
        this.headers = new Map<string, string>();
        this.headers.set("Accept","*/*"); // getCustomCode return text/plain response, which we must accept
    }

    setHeader(key: string, value: string) {
        this.headers.set(key, value);
    }

    removeHeader(key: string) {
        this.headers.delete(key);
    }

    protected getHeaderJson(): any {
        return JSON.parse(JSON.stringify(Array.from(this.headers.entries())));
    }

    static replacer(key: string, value: any) {
        if(value instanceof Map) {
            return Object.fromEntries(value.entries())
        }else if (value instanceof Set){
            return [...value] // convert Set to List
        } 
        else {
          return value;
        }
    }
    

    async fetch(url: string, method: string, body: any|null, headers: Map<string, string>): Promise<any> {

        let payload = body ?  JSON.stringify(body, RestAPIClient.replacer) : body;
        if(typeof body === "string"){
            payload = body;
        }
        const fetchHeaders = new Headers();
        for (const [key, value] of headers) {
            fetchHeaders.append(key, value)
          }

        // based on body type setting header content-type.
        if(typeof body === "object" || body === null){
            fetchHeaders.append("Content-Type", "application/json");
        }else{
            fetchHeaders.append("Content-Type", "text/plain");
        }

        const request: {[index:string]: any}  = {
            method: method,
            body: payload!,
            headers: fetchHeaders
        };


        if(RestAPIClient.debug){
            console.log(`--------------------------------------------------------\n URL: ${this.baseUrl}${url}`);
            console.log(request);
        }

        const response: any = await fetch(`${this.baseUrl}${url}`, request);

        if(RestAPIClient.debug){
            console.log(response);
        }

        const status = response.status;
        const responseHeaders = response.headers;
        const reponseContentType: string = responseHeaders.get("Content-Type")

  
        let responseText: string = await response.text();
        if(RestAPIClient.debug){
            console.log(responseText);
        }


        if(!response.ok) {

            if(reponseContentType.includes('application/json')){
                let message: Message = Message.parseJSON(JSON.parse(responseText));
                responseText = message.message;
            }

            if(status === 400){
                throw new BadRequestException(responseText);
                
            } else if(status === 401) {
                this.removeHeader(InflyClientId);
                this.removeHeader(InflyClientToken);
                throw new NotAuthenticatedException(responseText);

            } else if(status === 403){
                throw new NotAuthorizedException(responseText);

            } else if(status === 404) {
                throw new NotFoundException(responseText);

            } else {
                throw new InternalServerException(responseText);
            }

        }else{

            if(responseText){ // server could return null, which translates to empty text
                if(reponseContentType.includes('text/plain')){
                    return responseText;
                }else{
                    return JSON.parse(responseText);
                }
            }else{
                return null;
            }

        }

    }

    get(url: string): Promise<any> {
        return this.fetch(url, "GET", null, this.headers);
    }

    async post(url: string, body?: any): Promise<any> {
        return this.fetch(url, "POST", body?body:null, this.headers);
    }

    async delete(url: string, body?:any): Promise<any> {
        return this.fetch(url, "DELETE", body ? body : null, this.headers);
    }
}