import {InvestflyUtil} from '../../utils/investflyUtil';
import {InvestflyModel} from '../InvestflyModel';
import {Security} from '../marketdata/security';
import {PositionType} from './positionType';

export class MatchedTrade extends InvestflyModel{
    security: Security;
    position: PositionType;
    openDate: Date;
    closeDate: Date;
    openPrice: number;
    closePrice: number;
    quantity: number;

    percentChange: number;
    profitLoss: number;

    static parseJSON(obj: {[index:string]: any}): MatchedTrade {
        let mt = new MatchedTrade();
        Object.assign(mt, obj);
        mt.openDate = InvestflyUtil.strToDate(obj.openDate)!;
        mt.closeDate = InvestflyUtil.strToDate(obj.closeDate)!;
        mt.security = Security.parseJSON(obj.security);
        return mt;
    }
}