/** @format */

import {
    Box,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    TextField,
} from '@mui/material';
import {
    MarketDataAPIClient,
    Message,
    OrderDuration,
    OrderType,
    PendingOrder,
    SecurityType,
    Stock,
    StockQuote,
    SymbolSummaryInfo,
    TradeOrder,
    TradeTrigger,
    TradeType,
} from 'api';
import React, { Component } from 'react';
import { SymbolField } from 'containers/common/SymbolField';
import Card from '@mui/material/Card';
import {SelectField} from 'containers/common/SelectField';
import { LabelAndValue } from 'containers/common/LabelAndValue';
import SampleTriggeredTradeOrdersComponent from './AutoTradeSamples';
import { TradeSharesType } from './Trade';
import { AppContext } from 'util/appContext';
import { SecurityFilterExpressionComp } from 'containers/Trigger/SecurityFilterExpressionComp';
import { SecurityTriggerExpression } from 'api/models/trigger/SecurityTriggerExpression';

interface StockTradeProps {
    tradeOrder: TradeOrder;
    presetSecurity?: SymbolSummaryInfo; // we need this to distinguish states when symbol field is set by user or when it comes preset from outside
    onTradeOrderChange: (tradeOrder: TradeOrder) => void;
    onMessage: (message: Message) => void; // passes up message that might generate as user interacts with the form
}

export interface StockTradeState {
    // this can be inferred from quantity vs maxamount, but when user has not set anything, quantity and amount are undefined, but the radio btn must still be selected
    tradeSharesType: TradeSharesType;
    quote?: StockQuote;
    tickerSymbol?: SymbolSummaryInfo; // since company name is not captured in TradeOrder, we need to store it
}

export default class StockTrade extends Component<StockTradeProps, StockTradeState> {
    marketDataApiClient: MarketDataAPIClient;

    constructor(props: StockTradeProps) {
        super(props);
        this.marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        this.state = {
            tradeSharesType: TradeSharesType.NO_OF_SHARES,
        };
    }

    async componentDidMount() {
        // If this was an edit pending order, pre-fill the state
        // if (this.isEditPendingOrder() || this.props.tradeOrder.security !== undefined) {
        //     let tickerSymbol = await this.marketDataApiClient.getSymbolDetail(
        //         this.props.tradeOrder.security.symbol
        //     );
        //     let quote = await this.marketDataApiClient.getStockQuote(tickerSymbol.symbol);
        //     this.setState({ quote: quote, tickerSymbol: tickerSymbol });
        // }

        let { tickerSymbol, quote } = this.state;
        if (this.isEditPendingOrder() && this.props.tradeOrder.security !== undefined) {
            console.log('tradeOrder', this.props.tradeOrder.security.symbol);
            tickerSymbol = await this.marketDataApiClient.getSecuritySummary(
                this.props.tradeOrder.security.symbol
            );
        }
        if (this.props.presetSecurity?.symbol) {
            console.log('presetSecurity', this.props.presetSecurity.symbol);
            tickerSymbol = this.props.presetSecurity;
        }

        if (tickerSymbol) {
            quote = await this.marketDataApiClient.getStockQuote(tickerSymbol.symbol);
        }
        this.setState({ tickerSymbol, quote });
    }

    updateTradeOrder = (updater: (tradeOrder: TradeOrder) => void) => {
        const tradeOrder: TradeOrder = this.props.tradeOrder;
        updater(tradeOrder);
        this.props.onTradeOrderChange(tradeOrder);
    };

    onExpressionChange = (expression?: SecurityTriggerExpression) => {
        this.updateTradeOrder( (tradeOrder: TradeOrder) => {
            if(tradeOrder.securityTrigger === undefined){
                tradeOrder.securityTrigger = new TradeTrigger();
            }
            tradeOrder.securityTrigger.trigger = expression!;
        })
    }

    onSymbolSelect = async (tickerSymbol: SymbolSummaryInfo) => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.security = new Stock();
            tradeOrder.security.securityType = tickerSymbol.type;
            tradeOrder.security.symbol = tickerSymbol.symbol;
        });

        let quote = await this.marketDataApiClient.getStockQuote(tickerSymbol.symbol);
        this.setState({ quote: quote, tickerSymbol: tickerSymbol });
    };

    onSymbolClear = () => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.security = undefined!;
        });
        this.setState({ tickerSymbol: undefined, quote: undefined });
    };

    setQuantityShares = (quantity: number) => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.quantity = quantity;
        });
    };

    setTradeAmount = (amount: number) => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.maxAmount = amount;
        });
    };

    setTradeType = (tradeType: TradeType) => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.tradeType = tradeType;
        });
    };

    setOrderDuration = (orderDuration: OrderDuration) => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.orderDuration = orderDuration;
        });
    };

    setOrderType = (orderType: OrderType) => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.orderType = orderType;
        });
    };

    setStopPrice = (stopPrice: number) => {
        if (stopPrice === 0) stopPrice = NaN;
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.stopPrice = stopPrice;
        });
    };

    setLimitPrice = (limitPrice: number) => {
        if (limitPrice === 0) limitPrice = NaN;
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.limitPrice = limitPrice;
        });
    };


    isEditPendingOrder = (): boolean => {
        return this.props.tradeOrder instanceof PendingOrder;
    };



    render() {
        const tradeTypeOptions = Object.keys(TradeType).map((t) => {
            return { label: t.replace('_', ' '), value: t.toString() };
        });

        const orderTypeOptions = Object.keys(OrderType).map((o) => {
            return { label: o.replace('_', ' '), value: o.toString() };
        });

        const isEditPendingOrder = this.isEditPendingOrder();
        return (
            <Box component={'form'} width={'100%'} pt={2}>
                <Grid container direction='column' justifyContent={'flex-start'}>
                    <Grid
                        item
                        xs={12}
                        container
                        direction={{ md: 'row', xs: 'column-reverse' }}
                        spacing={2}
                        justifyContent={'space-between'}
                    >
                        <Grid item md container spacing={2}>
                            <Grid
                                item
                                container
                                justifyContent={'space-between'}
                                spacing={1}
                                alignItems='center'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>Symbol</FormLabel>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <FormControl component={'fieldset'} fullWidth>
                                        <SymbolField
                                            selectedSymbol={this.state.tickerSymbol}
                                            includeIndex={false}
                                            disabled={isEditPendingOrder}
                                            onChange={this.onSymbolSelect}
                                            onClear={this.onSymbolClear}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {this.state.tickerSymbol?.name && (
                                <Grid item md={6} display={{ md: 'none', xs: 'block' }}>
                                    <Card>
                                        <CardContent>
                                            <Stack
                                                gap={1}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                            >
                                                <LabelAndValue
                                                    label={'Company Name'}
                                                    value={this.state.tickerSymbol?.name ?? 'N/A'}
                                                />
                                                <LabelAndValue
                                                    label={'Current Price'}
                                                    value={this.state.quote?.lastPrice ?? 'N/A'}
                                                />
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                            <Grid item container alignItems='center'>
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>Trade Share Type</FormLabel>
                                </Grid>
                                <Grid item>
                                    <FormControl component='fieldset' fullWidth>
                                        <RadioGroup
                                            row
                                            value={this.state.tradeSharesType}
                                            onChange={(event: any) => {
                                                switch (TradeSharesType[event.target.id]) {
                                                    case TradeSharesType.NO_OF_SHARES:
                                                        this.setQuantityShares(0);
                                                        this.setState({
                                                            tradeSharesType:
                                                                TradeSharesType.NO_OF_SHARES,
                                                        });
                                                        break;
                                                    case TradeSharesType.MAX_AMOUNT:
                                                        this.setTradeAmount(0);
                                                        this.setState({
                                                            tradeSharesType:
                                                                TradeSharesType.MAX_AMOUNT,
                                                        });
                                                        break;
                                                    default:
                                                        this.setQuantityShares(0);
                                                        this.setState({
                                                            tradeSharesType:
                                                                TradeSharesType.MAX_AMOUNT,
                                                        });
                                                }
                                            }}
                                        >
                                            <FormControlLabel
                                                value={TradeSharesType.NO_OF_SHARES}
                                                control={
                                                    <Radio id={TradeSharesType.NO_OF_SHARES} />
                                                }
                                                label='No of Shares'
                                                labelPlacement='end'
                                            />

                                            <FormControlLabel
                                                value={TradeSharesType.MAX_AMOUNT}
                                                control={<Radio id={TradeSharesType.MAX_AMOUNT} />}
                                                label='Cash Amount'
                                                labelPlacement='end'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent={'space-between'}
                                alignItems='center'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>
                                        {this.state.tradeSharesType ===
                                            TradeSharesType.NO_OF_SHARES && 'Quantity'}
                                        {this.state.tradeSharesType ===
                                            TradeSharesType.MAX_AMOUNT && 'Amount'}
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <FormControl component='fieldset' fullWidth>
                                        {this.state.tradeSharesType ===
                                            TradeSharesType.NO_OF_SHARES && (
                                            <TextField
                                                id='tradeSharesQuantityTxtField'
                                                size={'small'}
                                                type={'number'}
                                                fullWidth
                                                placeholder={'Enter Trade Quantity'}
                                                value={
                                                    this.props.tradeOrder.quantity?.toString() ?? ''
                                                }
                                                onChange={(event: any) =>
                                                    this.setQuantityShares(
                                                        Number(event.target.value)
                                                    )
                                                }
                                            />
                                        )}
                                        {this.state.tradeSharesType ===
                                            TradeSharesType.MAX_AMOUNT && (
                                            <TextField
                                                id='tradeSharesAmountTxtField'
                                                size={'small'}
                                                type={'number'}
                                                fullWidth
                                                placeholder={'Enter Trade Amount'}
                                                value={
                                                    this.props.tradeOrder.maxAmount?.toString() ??
                                                    ''
                                                }
                                                onChange={(event: any) =>
                                                    this.setTradeAmount(Number(event.target.value))
                                                }
                                            />
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent={'space-between'}
                                alignItems='center'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>Trade&nbsp;Type</FormLabel>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <FormControl component='fieldset' fullWidth>
                                        <SelectField
                                            id='TradeTypeOptions'
                                            label={'Trade'}
                                            fullWidth
                                            disabled={isEditPendingOrder}
                                            value={this.props.tradeOrder.tradeType}
                                            onChange={(event: any) => {
                                                this.setTradeType(TradeType[event.target.value]);
                                            }}
                                            key='TradeTypeOptions_select'
                                            options={tradeTypeOptions}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container alignItems='center'>
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>Order Duration</FormLabel>
                                </Grid>
                                <Grid item>
                                    <FormControl component='fieldset' fullWidth>
                                        <RadioGroup
                                            row
                                            onChange={(event: any) => {
                                                this.setOrderDuration(
                                                    OrderDuration[event.target.value]
                                                );
                                            }}
                                            value={this.props.tradeOrder.orderDuration}
                                        >
                                            <FormControlLabel
                                                value={OrderDuration.GTC}
                                                control={<Radio id='gtcOrderRadioBtn' />}
                                                label='Good Till Cancelled'
                                                labelPlacement='end'
                                            />

                                            <FormControlLabel
                                                value={OrderDuration.DAY}
                                                control={<Radio id='dayOrderRadioBtn' />}
                                                label='Day'
                                                labelPlacement='end'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent={'space-between'}
                                alignItems='center'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>Order&nbsp;Type</FormLabel>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <FormControl component='fieldset' fullWidth>
                                        <SelectField
                                            id='orderTypeOptions'
                                            label={'Order'}
                                            fullWidth
                                            value={this.props.tradeOrder.orderType}
                                            onChange={(event: any) => {
                                                this.setOrderType(OrderType[event.target.value]);
                                            }}
                                            key='orderTypeOptions_select'
                                            options={orderTypeOptions}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {(this.props.tradeOrder.orderType === OrderType.STOP_ORDER ||
                                this.props.tradeOrder.orderType === OrderType.ONE_CANCEL_OTHER) && (
                                <Grid
                                    item
                                    container
                                    justifyContent={'space-between'}
                                    alignItems='center'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormLabel component='legend'>Stop Price</FormLabel>
                                    </Grid>

                                    <Grid item xs={12} md={10}>
                                        <FormControl component='fieldset' fullWidth>
                                            <TextField
                                                id='stopPriceTxtField'
                                                size={'small'}
                                                placeholder='E.g: 15.0'
                                                type={'number'}
                                                label='Stop Price'
                                                value={
                                                    this.props.tradeOrder.stopPrice?.toString() ??
                                                    ''
                                                }
                                                onChange={(event: any) => {
                                                    this.setStopPrice(Number(event.target.value));
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}

                            {(this.props.tradeOrder.orderType === OrderType.LIMIT_ORDER ||
                                this.props.tradeOrder.orderType === OrderType.ONE_CANCEL_OTHER) && (
                                <Grid
                                    item
                                    container
                                    justifyContent={'space-between'}
                                    alignItems='center'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormLabel component='legend'>Limit Price</FormLabel>
                                    </Grid>

                                    <Grid item xs={12} md={10}>
                                        <FormLabel component='legend' />

                                        <FormControl component='fieldset' fullWidth>
                                            <TextField
                                                id='limitPriceTxtField'
                                                size={'small'}
                                                placeholder='E.g: 20.0'
                                                type={'number'}
                                                label='Limit Price'
                                                value={
                                                    this.props.tradeOrder.limitPrice?.toString() ??
                                                    ''
                                                }
                                                onChange={(event: any) => {
                                                    this.setLimitPrice(Number(event.target.value));
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {this.state.tickerSymbol?.name && (
                            <Grid item md={4} display={{ md: 'block', xs: 'none' }}>
                                <Card>
                                    <CardContent>
                                        <Stack
                                            flexDirection={{ md: 'column', xs: 'row' }}
                                            gap={1}
                                            justifyContent={{
                                                md: 'flex-start',
                                                xs: 'space-around',
                                            }}
                                        >
                                            <LabelAndValue
                                                label={'Company Name'}
                                                value={this.state.tickerSymbol?.name ?? 'N/A'}
                                            />
                                            <LabelAndValue
                                                label={'Current Price'}
                                                value={this.state.quote?.lastPrice ?? 'N/A'}
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}


                        {this.props.tradeOrder.orderType === OrderType.CUSTOM_CONDITION &&
                            <Grid item sm={12}>
                                <SecurityFilterExpressionComp required securityFilterExpression={this.props.tradeOrder.securityTrigger?.trigger} onChange={this.onExpressionChange}/>
                            </Grid>
                        }
                            

                    </Grid>
                </Grid>
            </Box>
        );
    }
}
