import { InvestflyModel } from "../InvestflyModel";
import { UserRole } from "./user";

export class UserSpec extends InvestflyModel {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    userRole: UserRole;

    referrer: string;
    address: string;

    static parseJSON(obj: {[index:string]: any} ): UserSpec {
        let userSpec = new UserSpec();
        Object.assign(userSpec, obj);
        return userSpec;
    }
}