import {InvestflyUtil} from "../../utils/investflyUtil";
import {UserSpec} from "./userSpec";
import {InvestflyModel} from "../InvestflyModel";

export enum UserType {
    UNCONFIRMED = "UNCONFIRMED",
    PREMIUM = "PREMIUM",
    ELITE = "ELITE",
    STANDARD = "STANDARD",
    DEACTIVATED = "DEACTIVATED"
}

export enum UserRole {
    TRADER = "TRADER",
    INSTRUCTOR = "INSTRUCTOR",
    AFFILIATE = "AFFILIATE",
    STUDENT = "STUDENT",
}

export class User extends UserSpec {
    userType: UserType;

    joinDate: Date;
    lastActive: Date;

    static parseJSON(obj: {[index:string]: any} ): User{
        // This only handles primitive types
        let user = new User();
        let userSpec = UserSpec.parseJSON(obj);
        Object.assign(user, userSpec);

        //If JSON returns string ('2015-01-18T18:28:09.000+0000') as joinDate
        // it will assign that string value to joinDate property of this instance. Note that TypeScript's Date typing
        // is a compile-time only, at runtime, since Javascript is untyped, all type information is lost
        user.joinDate = InvestflyUtil.strToDate(obj.joinDate)!;
        user.lastActive = InvestflyUtil.strToDate(obj.lastActive)!;

        //ENUMs must be explicitly converted too
        user.userType = (<any>UserType)[obj.userType];
        if(obj.userRole){
            user.userRole = (<any>UserRole)[obj.userRole];
        }


        // If a property of this object is a reference to another object of different class, then convert it recursively

        return user;
    }

}