import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Box, Chip, FilterOptionsState, Stack, TextField, Typography } from '@mui/material';
import { ApiClientFactory, InvestflyUtil, QuoteField, StockQuote } from 'api';
import React, { useEffect, useState } from 'react';
import IndicatorParamDialog from './IndicatorParamDialog';
import { DataParam, DataType } from 'api/models/trigger/DataParam';
import NumberDialog from './NumberDialog';
import CommonUtil from 'util/CommonUtil';
import { FINANCIAL_FIELD_DESC, FinancialField } from 'api/models/trigger/FinancialField';
import { FilterCondition } from 'api/models/trigger/SecurityTriggerExpression';
import { IndicatorSpec } from 'api/models/trigger/indicator';




enum Operator {
    PLUS = "+",
    MINUS = "-",
    DIVIDE = "/",
    MULTIPLY = "*",
    OPEN = "(",
    CLOSE = ")"
}


/*
* Generally, AutoComplete expects its Value to be the same type as Option becuase one(or more) options are selected and becomes value
* When freeSolo is used, Autocomplete also expects its Value type to be string[] becuase freeSolo allows aribtrary input as string
* However, in our case, Options are IndicatorSpec[] and Values are (logically) DataParam[] array. 
* But we use FreeSolo and set DataParam.alias instead in the value array, which works because its string[]. 
*/

type AutoCompleteOption = Operator | "Number" | IndicatorSpec | QuoteField | FinancialField;
enum AutoCompleteOptionType{
    OPERATOR = "OPERATOR",
    NUMBER = "NUMBER",
    INDICATOR = "INDICATOR",
    QUOTE = "QUOTE",
    FINANCIAL = "FINANCIAL",
    CUSTOM = "CUSTOM"
}

type FilterExpressionProps = {
    filterExpression: string[];
    dataParams: Map<string, DataParam>;
    onChange: (filterExpression: string[], changedParam?: DataParam) => void; // for operators add/remove, changedParam is NULL
    includeFinancials?: boolean
}




export function FilterExpressionComp(props: FilterExpressionProps): React.JSX.Element {

    const [indicators, setIndicators] = React.useState<IndicatorSpec[]>([])
    const [customIndicatorIds, setCustomIndicatorIds] = React.useState<Set<string>>(new Set())

    const [selectedIndicator, setSelectedIndicator] = useState<IndicatorSpec>();
    const [clickedDataParam, setClickedDataParam] = useState<DataParam>();

    const [selectedNumber, setSelectedNumber] = useState<number>();

    const [errorText, setErrorText] = useState<string|null>(null)

    // This is needed so that when user select a Number, and we show Number dialog, we can pre-populate with the number user already entered
    const [inputValue, setInputValue] = React.useState('');



    useEffect(()=> {
        async function fetchIndicators(){
            const indicatorApiClient = ApiClientFactory.getInstance().indicatorAPIClient;
            let indicators = await indicatorApiClient.listStandardIndicators()
            let customIndicators = await indicatorApiClient.listCustomIndicators();
            setIndicators([...indicators, ...customIndicators]);
            if(customIndicators.length > 0){
                setCustomIndicatorIds(new Set(customIndicators.map(i => i.indicatorId)))
            }
        }
        fetchIndicators();
    }, [])

    function getAutoCompleteOptionType(option: AutoCompleteOption): AutoCompleteOptionType{
        if(option instanceof IndicatorSpec){
            let spec =  option as IndicatorSpec;
            if (customIndicatorIds.has(spec.indicatorId)){
                return AutoCompleteOptionType.CUSTOM
            }else{
                return AutoCompleteOptionType.INDICATOR;
            }
        }else{
            // all enums are also strings
            let optionStr: string = option as string;
            if (optionStr == "Number"){
                return AutoCompleteOptionType.NUMBER
            }else if (Object.values<string>(QuoteField).includes(optionStr)) {
               return AutoCompleteOptionType.QUOTE;
            }else if (Object.values<string>(FinancialField).includes(optionStr)) {
                return AutoCompleteOptionType.FINANCIAL;
             }
            else{
                return AutoCompleteOptionType.OPERATOR;
            }
        }
    }

    function getAutoCompleteRenderOption(option: AutoCompleteOption): [string, string|undefined]{

        const type = getAutoCompleteOptionType(option);

        if(type == AutoCompleteOptionType.INDICATOR  || type == AutoCompleteOptionType.CUSTOM){
            const indicatorSpec: IndicatorSpec = option as IndicatorSpec;
            return [indicatorSpec.name, indicatorSpec.description];

        }else if(type == AutoCompleteOptionType.QUOTE){
            const quoteFieldStr = option as string;
            return [quoteFieldStr.replaceAll("_", " "), StockQuote.getQuoteFieldDesc(QuoteField[quoteFieldStr])];

        }else if(type == AutoCompleteOptionType.FINANCIAL){
            const financialField = FinancialField[option as string];
            const friendlyName = financialField.replaceAll("_", " ");
            const namedesc = FINANCIAL_FIELD_DESC.get(financialField);
            return [friendlyName, namedesc? namedesc[0] : friendlyName];

        }else{
            // works for Number
            return  [option as string, undefined]
        }
    }

    function filterAutoCompleteOption(  allOptions: AutoCompleteOption[], state: FilterOptionsState<AutoCompleteOption>  ) {
        // change to upper case since all symbols and names in the options are already uppercase
        let inputValue: string = state.inputValue.toUpperCase();
                                        
        if (/^\d+$/.test(inputValue)) {
            return [allOptions[6]]; // Number is the 7th item after 6 operators
        }else if (inputValue[0] == '-' && /^\d+$/.test(inputValue.substring(1))){
            return [allOptions[6]]; // Number is the 7th item after 6 operators, to support -12
        } 
        else {
            return allOptions.filter(
                (op: AutoCompleteOption) => {
                    const type = getAutoCompleteOptionType(op);
                    if(type == AutoCompleteOptionType.INDICATOR || type == AutoCompleteOptionType.CUSTOM){
                        const indicatorSpec: IndicatorSpec = op as IndicatorSpec;
                        return  indicatorSpec.name.toUpperCase().includes(inputValue) || indicatorSpec.indicatorId.toUpperCase().includes(inputValue);
                    }else{
                        // all others types are enums which are strings
                        const optionString: string = op as string;
                        return optionString.toUpperCase().includes(inputValue);
                    }
                }
            );
        }
    }

    
    function onExpressionChange(event: React.SyntheticEvent, value: (AutoCompleteOption|string)[], reason: AutocompleteChangeReason, details: AutocompleteChangeDetails<AutoCompleteOption> | undefined)  {

        // value will always be string[] because are using string[] as value for the AutoComplete
        // details.option will have the new item added/removed, but the type will be different. 
        
        if (reason === 'selectOption') {
            
            let selectedOption: AutoCompleteOption = details!.option;
            const type = getAutoCompleteOptionType(selectedOption);

            // the first two types - indicator and number will launch dialogs. For others, we simply update the expression in state
            if(type == AutoCompleteOptionType.INDICATOR || type == AutoCompleteOptionType.CUSTOM){
                setSelectedIndicator(selectedOption as IndicatorSpec);

            }else if(type == AutoCompleteOptionType.NUMBER){
                let selectedNumber: number =  CommonUtil.strToNumber(inputValue) as number;

                if(selectedNumber == undefined){
                    selectedNumber = 1; // when user selects 'NUMBER' from dropdown without typing any number
                }
                setSelectedNumber(selectedNumber); 

            }else{
                // these don't require dialog, but we update expression directly

                let changedDataParam:DataParam|undefined = undefined;
                const selectedOptionStr: string = selectedOption as string;
                if(type == AutoCompleteOptionType.OPERATOR){
                }else if (type == AutoCompleteOptionType.QUOTE){
                    changedDataParam  = DataParam.newQuoteParam(QuoteField[selectedOptionStr])
                }else if (type == AutoCompleteOptionType.FINANCIAL){
                    changedDataParam = DataParam.newFinancialParam(FinancialField[selectedOption as string])
                }
                
                // for quote and financial, DataParam factory method sets the alias to 'field' which matches the field uses to create select options
                props.filterExpression.push(selectedOptionStr);

                validateExpression(props.filterExpression);
                props.onChange(props.filterExpression, changedDataParam);
                
            }

            
        } else if (reason === 'removeOption') {
            let removedItem: string = details!.option as string;
            const newFilterExpression =  props.filterExpression.filter( (item: string) => removedItem !== item)
            let changedDataParam:DataParam|undefined = undefined;
            if(props.dataParams.has(removedItem)){
                changedDataParam = props.dataParams.get(removedItem)
            }
            validateExpression(newFilterExpression);
            props.onChange(newFilterExpression, changedDataParam);
        }
    }

    const onChipClick = (index: number, expressionItem: string) => {
        // TODO - if the expression contains the same dataparam with same alias at more than one place, like (sma5 + 2 - sma5),
        // the both sma5 act like one, if one is updated, another is updated, if one is deleted another is also deleted
        // to avoid this, we need also track passed index in clickedDataParam index
        let dataParam: DataParam|undefined = props.dataParams.get(expressionItem);
        if(dataParam){
            const dataType: DataType = dataParam.get(DataParam.DATATYPE);
            if(dataType == DataType.INDICATOR){
                const indicatorId: string = dataParam.get(DataParam.INDICATOR);
                const indicatorSpec: IndicatorSpec = indicators.find(spec => spec.indicatorId == indicatorId)!;
                setClickedDataParam(dataParam);
                setSelectedIndicator(indicatorSpec);
            }else if(dataType == DataType.CONST){
                const value: number = dataParam.get(DataParam.VALUE);
                setClickedDataParam(dataParam);
                setSelectedNumber(value);
            }
        }
    }


    function onIndicatorDialogReturn(dataParam: DataParam){ 
        let newFilterExpression: string[] = [...props.filterExpression];
        if(clickedDataParam !== undefined){
            const clickedIndicatorAlias: string = clickedDataParam.get(DataParam.ALIAS);
            newFilterExpression = InvestflyUtil.replaceArrayItem(newFilterExpression, clickedIndicatorAlias, dataParam.get(DataParam.ALIAS))
        }else{
            newFilterExpression.push(dataParam.get(DataParam.ALIAS));  
        }
         
        setSelectedIndicator(undefined);
        setClickedDataParam(undefined);

        validateExpression(newFilterExpression);
        props.onChange(newFilterExpression, dataParam);
    }

    function onNumberDialogReturn(value?: number, unit?: string){
        if(value !== undefined){

            let changedDataParam:DataParam|undefined = undefined;
            let expressionItem: string;
            let newFilterExpression: string[] = [...props.filterExpression];
            
            if(unit == undefined){
                expressionItem = value.toString();
            }else{
                changedDataParam = DataParam.newConstDataParam(value, unit);
                expressionItem = changedDataParam.get(DataParam.ALIAS)
            }

            if(clickedDataParam !== undefined){
                const clickedConstAlias: string = clickedDataParam.get(DataParam.ALIAS);
                newFilterExpression = InvestflyUtil.replaceArrayItem(newFilterExpression, clickedConstAlias, expressionItem);
            }else{
                newFilterExpression.push(expressionItem);
            }

            validateExpression(newFilterExpression);
            props.onChange(newFilterExpression, changedDataParam);
        }

        setClickedDataParam(undefined);
        setSelectedNumber(undefined);

    }


    function validateExpression(expression: string[]){
        // This validates expression in State which is ExpressionItem[], not in AutoComplete.value which is String[]

        const errors: string[] = FilterCondition.validateExpression(expression)
        if(errors.length === 0){
            setErrorText(null);
        }else{
            setErrorText(errors.join(","));
        }

    }


    const renderTags  = (value: any, getTagProps: any) => {
        return value.map((expressionItem: string, index: any) => {
            return (
                <Chip
                    {...getTagProps({ index })}
                    clickable={true}
                    key={index}
                    label={expressionItem}
                    onClick={(event: any) => {
                        event.preventDefault();
                        onChipClick(index, expressionItem);
                    }}
                />
            );
        });
    };


    // ++++++++++++++++++++++++++++++++++++++++++++++++


    let operators: Operator[] = Object.values(Operator);
    let quoteFields: QuoteField[] = Object.values(QuoteField);
    let allOptions: AutoCompleteOption[] = [...operators, "Number", ...quoteFields,  ...indicators]

    if(props.includeFinancials){
        let financialFields: FinancialField[] = Object.values(FinancialField);
        allOptions = [...allOptions, ...financialFields];
    }
    

    return <>

        {selectedIndicator !== undefined && 
            <IndicatorParamDialog 
                indicatorSpec={selectedIndicator}
                indicatorParam={clickedDataParam}
                onCancel={() => setSelectedIndicator(undefined)}
                onSubmit={onIndicatorDialogReturn}
            /> 
        } 

        {selectedNumber !== undefined && 
            <NumberDialog 
                value={selectedNumber} 
                unit={clickedDataParam? clickedDataParam.get(DataParam.UNIT): undefined}
                onClose={onNumberDialogReturn}/>
        }
        

        <Autocomplete
                        freeSolo
                        id="autocomplete"
                        size={'small'}
                        multiple
                        options={allOptions}

                        groupBy={(option: AutoCompleteOption) => {
                            return getAutoCompleteOptionType(option);
                        }}

                        getOptionLabel={ (option:any) => {
                            // This is called when dropdown opens for each item in the AutoCompleteOption
                            // This is also called when Value is updated, but in this case that passed in parameter
                            // will always be string becuase Autocomplete value is string[]
                            if (option instanceof IndicatorSpec){
                                // This has no efffect because we overwrite renderOption
                                return option.name;
                            }else{
                                return option;
                            }
                        }}

                        isOptionEqualToValue={(option, value) => false /* without this, if the same option(mainly +/- operator) is selected again, it will deselect it" */}
                        
                        renderOption={(__props, option: AutoCompleteOption) => {                       
                            const [primary, secondary] = getAutoCompleteRenderOption(option);
                            return <Box component='li' {...__props} key={crypto.randomUUID()}>
                                    <Stack>
                                        <Typography gutterBottom variant={'body1'} align={'left'}>
                                            {' '}
                                            {primary}
                                        </Typography>
                                        {secondary && <Typography gutterBottom variant={'caption'} align={'left'}>
                                            {' '}
                                            {secondary}
                                        </Typography>}
                                    </Stack>
                            </Box>
                        }}

                        filterOptions={filterAutoCompleteOption}

                        fullWidth
                        value={props.filterExpression}
                        onChange={onExpressionChange}

                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}

                        renderTags={renderTags}
                    
                        renderInput={(params) => (
                            <TextField  {...params}  label='Expression' error={errorText != null} helperText={errorText}/>
                        )}
       

                    />
    </>;

}