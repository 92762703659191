import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Stack, Typography } from "@mui/material";
import { Message, OPERATION, ScreenerAPIClient, ScreenerModel, ScreenerResult } from "api";
import { InflyIcon } from "containers/common/InflyIcon";
import { NewScreenerDialogButton, ScreenerFormDialog } from "./ScreenerForm";
import React, { useEffect, useState } from "react";
import { AppContext } from "util/appContext";
import EmptyCard from "containers/common/EmptyCard";
import { LoadingCard } from "containers/common/LoadingCard";
import UpgradeCheckButton from "containers/common/UpgradeCheckButton";

/**
 *  Sample Screener List is displayed in the base screener list page AND also on the new screenr dialog when New Screener is clicked from Dashboard
 */

export interface CreateSampleScreenerListProps {
    onCreateReturn: (
        screener: ScreenerModel,
        result?: ScreenerResult, // if only screener metadata is updated in Update mode, screeer won't be run
        message?: Message
    ) => void;
    screenerCount: number;
}

export function CreateSampleScreenerListComp({onCreateReturn, screenerCount} : CreateSampleScreenerListProps) {
    // Wrapper around SampleListComponent to create screener based on selected sample, so ScreenerList page is simplified
    const [dialogOpen, setDialogOpen] = useState(false);
    const [sampleModel, setSampleModel] = useState<ScreenerModel>();

    function onSampleSelect(sampleModel: ScreenerModel){
        setDialogOpen(true);
        setSampleModel(sampleModel);
    }
    function onCancel(){
        setDialogOpen(false);
    }

    return <>
    {dialogOpen && 
            <ScreenerFormDialog onSubmit={onCreateReturn} onCancel={onCancel} screener={sampleModel}/>
        }
     <SampleScreenerList onSampleSelect={onSampleSelect} screenerCount={screenerCount}/>
    </>
}


export interface SampleScreenerDialogProps {
    // the on* method are 3 dialog button handlers
    onSampleSelect: ( sampleModel: ScreenerModel) => void,
    onStartFromScratch: () => void;
    onCancel: () => void;
    screenerCount?: number; // to decide whether to show upgrade btn
}

export function SampleScreenerListDialog({onSampleSelect, onStartFromScratch, onCancel, screenerCount}: SampleScreenerDialogProps): React.JSX.Element {
    // wrapper around SampleList to show it in Dialog from NewScreener button
    return <Dialog open fullWidth maxWidth='lg'>
        <DialogTitle>Select Sample</DialogTitle>
        <DialogContent>
            <Paper>
                <SampleScreenerList onSampleSelect={onSampleSelect} screenerCount={screenerCount}/>
            </Paper>
            
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onCancel()}>Cancel</Button>
            <Button onClick={() => onStartFromScratch()}>Start from Scratch</Button>
        </DialogActions>
    </Dialog>
}

export interface SampleScreenerListProps {
    onSampleSelect: ( sampleModel: ScreenerModel) => void,
    screenerCount?: number; // to decide whether to show upgrade btn
}
export function SampleScreenerList({onSampleSelect, screenerCount}: SampleScreenerListProps): React.JSX.Element {

    const [sampleScreenerList, setSampleScreenerList] = useState<ScreenerModel[]>();
    const [curScreenerCount, setCurScreenerCount] = useState<number>();

    async function loadSampleList(){
        try{
            let screenerApiClient: ScreenerAPIClient = AppContext.getInstance().screenerApiClient;
            let sampleList: ScreenerModel[] = await screenerApiClient.getSampleScreeners();

            setSampleScreenerList(sampleList);
        }catch(error){
        }
    }
    useEffect( () => {
        loadSampleList();
    }, [])

    async function loadCurScreenerCount(){
        if(screenerCount === undefined){
            let screenerApiClient: ScreenerAPIClient = AppContext.getInstance().screenerApiClient;
            let myScreeners: ScreenerModel[] = await screenerApiClient.getMyScreeners();
            setCurScreenerCount(myScreeners.length)
        }else{
            setCurScreenerCount(screenerCount);
        }
    }

    useEffect( () => {
        loadCurScreenerCount();
    }, [screenerCount])

    if(sampleScreenerList == undefined || curScreenerCount == undefined){
        return  <LoadingCard title='Screener Samples' />
    }

    function createSampleScreenerCard(sampleModel: ScreenerModel): React.JSX.Element {
        return <Card raised elevation={5}>
            <CardContent>
                <Stack gap={2} >
                    <Stack direction={'row'} justifyContent={'space-between'}>
    
                        <Stack gap={1} direction={'row'}>
                            <InflyIcon name={'screeners'} />
                            <Typography variant='subtitle1' fontWeight={'bold'} color={"common.heading"}>{sampleModel.name}</Typography>
                        </Stack>
                        <UpgradeCheckButton op={OPERATION.CREATE_SCREENER} currentCount={curScreenerCount} label={'Select'}>
                            <Button onClick={() => onSampleSelect(sampleModel)}> Select </Button>
                        </UpgradeCheckButton>
                    </Stack>
                    <Typography variant='body2' >{sampleModel.description}</Typography>
                </Stack>
            </CardContent>
        </Card>
    }

    return <Stack gap={3}>
            <Typography variant='body2'> Stock screen allows you to use financial and technical indicators in complex expressions to filter stocks with the greatest potential.
                Create a new stock screen by selecting from one of the templates below or by clicking on 'New Screener' button above </Typography>
            <Grid container spacing={2}>
                {sampleScreenerList!.map(screenerModel => {
                    return <Grid key={screenerModel.name} item xs={12} md={6}>
                        {createSampleScreenerCard(screenerModel)}
                    </Grid>
                })}
            </Grid>
        </Stack>
}
