import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import CommonUtil from "util/CommonUtil";

/*
* This wrapper adds two convinience:
* 1. When the value is undefined initially (very common case when we expect input from users), then settign the value will generate error about component 
* changing from onControlled to Controlled Component. This component sets reasonable defaults to its alwasy controlled
* 2. onChange for TextField passes React.ChangeEvent. Instead, we overwrite onChange to return the actual value (string or number) from event.target.value
* 3. When the field value is empty string, it passes undefined to caller
*/

interface GenericValue<Type extends string | number| undefined > {
    onChange: (value:Type) => void;  // undefined is passed when user unsets the value
    value?: Type;
}

export type ITextFieldProps<Type extends string | number | undefined> =  Omit<TextFieldProps, "onChange" | "value"> & GenericValue<Type>

export function ITextField<Type extends string|number| undefined>(props: ITextFieldProps<Type>){

    let value  = props.value;
    if(value === undefined){
        value = '' as any;
    }


    return (<TextField 
        
        {...props} 
        value={value} 
        size="small"
        rows={props.multiline ? 2 : 1}


        onChange={ (event: any) => {
            let value: string = event.target.value;
            if(props.type === "number"){
                let val = CommonUtil.strToNumber(value);
                props.onChange(val as any);
                
            }else{
                if(value === ""){
                    props.onChange(undefined as any)
                }else{
                    props.onChange(value as any);
                }
                
            }
        }}
        />);

}