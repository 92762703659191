import {InvestflyModel} from "../InvestflyModel";


export class UserGameScore extends InvestflyModel {
    username: string;
    firstName: string;
    lastName: string;

    gameName: string;
    gameId: number;
    portfolioName: number;
    portfolioId: string;

    totalGamePoints: number;
    rank: number;

    public static  parseJSON(obj: {[index:string]: any} ): UserGameScore | undefined{
        if(obj === null || obj === undefined) return undefined;
        let userGameScore: UserGameScore = new UserGameScore();
        Object.assign(userGameScore, obj);
        return userGameScore;

    }
}