import {ApiClientFactory, BacktestAPIClient, BrokerAPIClient, ClassAPIClient, GameAPIClient, IndicatorAPIClient, MarketDataAPIClient, NotAuthenticatedException, PortfolioAPIClient, ScreenerAPIClient, StockAlertAPIClient, StrategyAPIClient, User, UserAPIClient, UserSession, WatchListAPIClient} from 'api';
import SessionUtil from './SessionUtil';
import ReactGA from 'react-ga4';

export interface GlobalStateListener {
    onGlobalStateChange: (key: string, value: any) => void;
}

export class AppContext {

    public static APP_STYLES: string = 'appstyles';

    private static INSTANCE?: AppContext;

    // the state has flat key (like Redis), we can use the dot notation to avoid conflict
    private globalState: Map<string, any>;

    // subscribers can also use prefix to work nicely with dot notation, E.g. subcribing to to "user" prefix
    // would get notification when "user.current" is changed or "user.type" is changed
    private stateListeners: Map<string, GlobalStateListener>;

    apiClientFactory: ApiClientFactory;

    private constructor(){
        console.log("APP INITIALIZING")
        this.apiClientFactory = ApiClientFactory.getInstance();
        this.globalState = new Map<string, any>();
        this.stateListeners = new Map<string, GlobalStateListener>();
    }

    public async establishSession(){
        let storedSession:UserSession|null = SessionUtil.findSession();
        if(storedSession){
            console.log("Browser Session Found");
            ReactGA.set({ userId: storedSession.username });
            this.apiClientFactory.setSession(storedSession);
            try{
                storedSession = await this.apiClientFactory.userApiClient.checkSession();
                SessionUtil.saveSession(storedSession!);

                let user: User| null = SessionUtil.findUserInfo();
                console.log("Session established with the server");

                if(!user) {
                    user = await this.apiClientFactory.userApiClient.getUserProfile(storedSession.username);
                    SessionUtil.saveUserInfo(user);
                }

            } catch(error) {
                console.log("Inactive browser session detected. Removing..");
                SessionUtil.clearSession();
            }
        }else{
            console.log("No Session Found");
        }
    }

    public static getInstance(): AppContext {
        if (this.INSTANCE) return this.INSTANCE as AppContext;
        this.INSTANCE = new AppContext();
        return this.INSTANCE;
    }

    public static reset(): void {
        this.INSTANCE = undefined;
    }

    public get classApiClient() : ClassAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.classApiClient);
    }

    public get userApiClient() : UserAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.userApiClient);
    }

    public get gameApiClient() : GameAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.gameApiClient);
    }

    public get portfolioApiClient() : PortfolioAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.portfolioApiClient);
    }

    public get brokerApiClient() : BrokerAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.brokerApiClient);
    }

    public get automationApiClient(): StrategyAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.strategyApiClient);
    }

    public get watchListApiClient(): WatchListAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.watchListApiClient);
    }

    public get screenerApiClient(): ScreenerAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.screenerApiClient);
    }

    public get stockAlertApiClient(): StockAlertAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.stockAlertAPIClient);
    }

    public get marketDataApiClient(): MarketDataAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.marketDataAPIClient);
    }

    public get indicatorApiClient() : IndicatorAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.indicatorAPIClient);
    }

    public get backestApiClient() : BacktestAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.backtestApiClient);
    }

    public get strategyApiClient() : StrategyAPIClient{
        return this.wrapWithAuthCheckProxy(this.apiClientFactory.strategyApiClient);
    }

    private wrapWithAuthCheckProxy(apiClient: any) {
        return new Proxy(apiClient, {
            get: function (target: any, prop: string, receiver: any) {
                if (typeof target[prop] === 'function') {
                    return new Proxy(target[prop], {
                        apply: (target: any, thisArg: any, argumentsList: any) => {
                            let output = Reflect.apply(target, thisArg, argumentsList);
                            if (output instanceof Promise) {
                                output.catch(error => {
                                if(error instanceof NotAuthenticatedException){
                                    console.log("REDIRECTING USER TO LOGIN PAGE ---> ");
                                    const currentUrl = window.location.pathname+window.location.search;
                                    window.location.replace('/?toPage='+window.encodeURIComponent(currentUrl));
                                }
                            })
                        }
                        return output;
                    }
                })
                }
                return Reflect.get(target,prop);
              },
        });
    }

    public clearGlobalState(key:string){
        this.globalState.delete(key);
        this.notify(key, null);
    }

    public updateGlobalState(key: string, value: any): any{
        this.globalState.set(key, value);
        this.notify(key, value);
    }

    public getGlobalState(key: string): any {
        return this.globalState.get(key);
    }

    public subscribeToState(key: string, listener: GlobalStateListener){
        this.stateListeners.set(key, listener);
    }

    public unsubscribeFromState(key: string){
        for (let skey of this.stateListeners.keys()) {
            if(skey === key){
                this.stateListeners.delete(skey);
            }
        }
    }

    private notify(updatedKey: string, value: any){
        for (let [key, listener] of this.stateListeners) {
            if(updatedKey.startsWith(key)){
                listener.onGlobalStateChange(updatedKey, value);
            }
        }
    }

}