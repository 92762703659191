/** @format */

import React, { Component } from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { UserType } from 'api';
import withRouter from 'containers/HOC/WithRouter';
import { RouteProps } from 'util/commonProps';
import { Feedback } from '../Feedback';

interface AccountTypeProps extends RouteProps {
    onDeactivateAccount: (proceed: boolean) => void;
    userType: UserType;
}

interface AccountTypeState {
    confirmDeactivateAccount: boolean;
}

class AccountType extends Component<AccountTypeProps, AccountTypeState> {
    constructor(props: AccountTypeProps) {
        super(props);
        this.state = { confirmDeactivateAccount: false };
    }

    onCloseConfirmDeactivateDialog = () => {
        this.setState({ confirmDeactivateAccount: false });
    };

    openConfirmDeactivateDialog = () => {
        this.setState({ confirmDeactivateAccount: true });
    };

    render() {
        const { confirmDeactivateAccount } = this.state;
        return (
            <Paper elevation={0} square={false} sx={{ paddingInline: 3 }}>
                <Typography variant={'h5'} align={'center'}>
                    {' '}
                    Account Type/Deactivate
                    <br />{' '}
                </Typography>
                <form>
                    <br />
                    <Grid container direction='column' spacing={2} justifyContent='flex-start'>
                        <Grid
                            container
                            item
                            direction='row'
                            spacing={2}
                            justifyContent='flex-start'
                        >
                            <Grid item xs={12}>
                                <Typography variant={'body2'}>
                                    Member Type:&nbsp;&nbsp;
                                    <Typography
                                        id='memberType'
                                        component={'label'}
                                        variant={'subtitle1'}
                                        color={'primary'}
                                    >
                                        {this.props.userType}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    id='upgradeAccountBtn'
                                    color={'primary'}
                                    onClick={(event: any) => {
                                        event.preventDefault();
                                        this.props.navigate('/upgrade/pricing');
                                    }}
                                >
                                    Change Plan
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} justifyContent='flex-start'>
                            <Grid item xs={12}>
                                <Typography variant='body2' gutterBottom>
                                    {' '}
                                    We are sad to see you leave. You can deactivate your account by
                                    clicking the button below.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Feedback
                                    purpose='feedback'
                                    id={'deactivateAccountBtn'}
                                    onSubmitComplete={this.props.onDeactivateAccount}
                                    dialogOkText={'Deactivate'}
                                >
                                    Deactivate
                                </Feedback>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <br />
            </Paper>
        );
    }
}

export default withRouter(AccountType);
