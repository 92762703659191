/** @format */

import {
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Stack,
    Toolbar,
    Tooltip,
} from '@mui/material';
import { UserRole, UserType } from 'api/models/user/user';
import { InflyIcon } from 'containers/common/InflyIcon';
import React from 'react';
import { NavLink } from 'react-router-dom';
import SessionUtil from 'util/SessionUtil';

const drawerWidth = 70;
const navBarBtnStyles: any = {
    navBarButton: {
        // minHeight: 48,
        // justifyContent: 'center',
        // px: 2.5,
    },
    navBarIcon: {
        // minWidth: 0,
        // mr: 'auto',
        // justifyContent: 'center',
    },
};

export default function DesktopSideNav() {
    const user = SessionUtil.findUserInfo();

    
    const drawerItems = [
        {
            iconName: 'dashboard_one',
            label: 'Dashboard',
            href: '/dashboard',
        },
        {
            iconName: 'strategies',
            label: 'Strategies',
            href: '/strategy/list',
        },
        {
            iconName: 'portfolioList',
            label: 'Portfolio Accounts',
            href: '/portfolio/list',
        },
        {
            iconName: 'screeners',
            label: 'Screeners',
            href: '/screener/list',
        },
        {
            iconName: 'indicator',
            label: 'Indicators',
            href: '/indicator/list',
        },
        {
            iconName: 'myGameList',
            label: 'Games',
            href: '/games',
        },
        {
            iconName: 'affiliate',
            label: 'Referrals',
            href: '/myreferrals',
        },
    ];

    if (user?.userRole === UserRole.INSTRUCTOR) {
        drawerItems.push({
            iconName: 'group',
            label: 'Classroom',
            href: '/professor/dashboard',
        });
    }

    return (
        <Drawer
            variant='permanent'
            open={false}
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    borderRight: 'unset',
                },
            }}
        >
            <Toolbar />
            {/* <Stack justifyContent='center' alignContent='space-between'> */}
            <List>
                {drawerItems?.map((item: any) => {
                    return (
                        <ListItem key={item.label} disableGutters>
                            <Tooltip title={item.label}>
                                <NavLink to={item.href}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <InflyIcon
                                                name={item.iconName}
                                                color={'action'}
                                                fontSize={'small'}
                                            />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </NavLink>
                            </Tooltip>
                        </ListItem>
                    );
                })}
            </List>
            {/* </Stack> */}
        </Drawer>
    );
}
