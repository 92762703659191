/** @format */

import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Slide,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import { OPERATION } from 'api';
import React from 'react';
import { ActionButton } from './ActionButton';
import UpgradeCheckButton from './UpgradeCheckButton';
import withScreenSize from 'containers/HOC/WithScreenSize';
import { InflyIcon } from './InflyIcon';
import { TransitionProps } from '@mui/material/transitions';
import { ScreenSizeProp } from 'util/commonProps';

type InflyDialogProps = DialogProps &
    ScreenSizeProp & {
        title?: string;
        tooltip?: string;
        disabled?: boolean;
        okText?: string;
        cancelText?: string;
        isScreenSizeDependent?: true;
        onExecute?: any; // when sumbit is clicked
        onClose?: any; // when cancel is clicked
        upgradeOperation?: OPERATION;
        currentCount?: number;
    };

interface State {}

const SlideTransitionComponent = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='left' ref={ref} {...props} />;
});

class InflyDialog extends React.Component<InflyDialogProps, State> {
    constructor(props: InflyDialogProps) {
        super(props);
        this.state = { isSubmitting: false };
    }

    render() {
        const {
            onClose,
            title,
            tooltip,
            id,
            disabled,
            okText,
            cancelText,
            upgradeOperation,
            currentCount,
            onExecute,
            children,
            isMobile,
            isScreenSizeDependent,
            ...rest
        } = this.props;

        if (isMobile && isScreenSizeDependent) {
            return (
                <Dialog
                    onClose={onClose}
                    fullScreen
                    {...rest}
                    TransitionComponent={SlideTransitionComponent}
                >
                    <AppBar
                        sx={(theme: Theme) => ({
                            position: 'sticky',
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.mode === 'light' ? 'black' : 'white',
                            marginBottom: 1,
                        })}
                    >
                        <Toolbar>
                            <IconButton
                                edge='start'
                                color='primary'
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    onClose();
                                }}
                                size={'medium'}
                                aria-label='close-back'
                            >
                                <InflyIcon name={'back'} fontSize={'small'} />
                            </IconButton>
                            <Typography marginLeft={2} flex={1} variant='h6' component='div'>
                                {`${title}`}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {children && children}
                    <br />
                    <br />
                    <DialogActions>
                        {(okText || typeof onExecute === 'function') &&
                            upgradeOperation === undefined && (
                                <ActionButton
                                    id={id + 'okbtn'}
                                    fullWidth
                                    disabled={disabled}
                                    size={'large'}
                                    onClick={onExecute}
                                    tooltip={tooltip ?? ''}
                                    label={okText ? okText : 'Yes'}
                                />
                            )}

                        {(okText || typeof onExecute === 'function') && upgradeOperation && (
                            <UpgradeCheckButton
                                op={upgradeOperation}
                                currentCount={currentCount}
                                // current no of portfolio, sreenrs etc. Passed when limit is a number, skipped when limit is a boolean
                                label={okText ? okText : 'Yes'}
                            >
                                <ActionButton
                                    id={id + 'okbtn'}
                                    disabled={disabled}
                                    fullWidth
                                    onClick={onExecute}
                                    tooltip={tooltip ?? ''}
                                    label={okText ? okText : 'Yes'}
                                />
                            </UpgradeCheckButton>
                        )}
                    </DialogActions>
                </Dialog>
            );
        }
        return (
            <Dialog onClose={onClose} id={id} {...rest} fullWidth>
                {title && <DialogTitle>{title}</DialogTitle>}

                {children && (
                    <DialogContent>
                        <Box m={1}>{children}</Box>
                    </DialogContent>
                )}

                <DialogActions>
                    {typeof onClose === 'function' && (
                        <Button
                            onClick={(event: any) => {
                                event.preventDefault();
                                onClose();
                            }}
                            autoFocus
                        >
                            {cancelText ? cancelText : 'Cancel' }
                        </Button>
                    )}

                    {(okText || typeof onExecute === 'function') &&
                        upgradeOperation === undefined && (
                            <ActionButton
                                id={id + 'okbtn'}
                                disabled={disabled}
                                onClick={onExecute}
                                tooltip={tooltip ?? ''}
                                label={okText ? okText : 'Yes'}
                            />
                        )}
                    {(okText || typeof onExecute === 'function') && upgradeOperation && (
                        <UpgradeCheckButton
                            op={upgradeOperation}
                            currentCount={currentCount}
                            // current no of portfolio, sreenrs etc. Passed when limit is a number, skipped when limit is a boolean
                            label={okText ? okText : 'Yes'}
                        >
                            <ActionButton
                                id={id + 'okbtn'}
                                disabled={disabled}
                                onClick={onExecute}
                                tooltip={tooltip ?? ''}
                                label={okText ? okText : 'Yes'}
                            />
                        </UpgradeCheckButton>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default withScreenSize(InflyDialog);
