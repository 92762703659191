import React, {  useEffect, useState } from 'react';
import MonacoEditor from '@monaco-editor/react';

import { AppContext } from 'util/appContext';
import { Message, IndicatorAPIClient, OPERATION } from 'api';
import { InflyMessage } from 'containers/common/InflyMessage';
import InflyDialog from 'containers/common/InflyDialog';
import { IndicatorSpec } from 'api/models/trigger/indicator';
import { Button, Paper, Stack, Typography } from '@mui/material';
import UpgradeCheckButton from 'containers/common/UpgradeCheckButton';
import A from 'containers/common/Anchor';
import { InflyIcon } from 'containers/common/InflyIcon';

const SAMPLE_INDICATOR = `# This is a self-documenting starter template to define custom indicators in Python Programming Language

# Following two imports are required
from investfly.models import *
from investfly.utils import *
# Import basic types, they aren't required but recommended
from typing import Any, List, Dict
# Following imports are allowed
import numpy as np
# Use https://github.com/ta-lib/ta-lib-python for technical indicators
import talib
# import math
# ! WARN ! Imports other than listed above are disallowed and won't pass validation

# Create a class that extends Indicator. The class name becomes the "indicator id", which must be globally unique
class SMAHeikin_CHANGE_ME(Indicator):
    def getIndicatorSpec(self) -> IndicatorSpec:
        # In this method, you must construct and return IndicatorSpec object that specifies
        # indicator name, description and any parameters it needs.
        indicator = IndicatorSpec("[Change Me]: SMA Heikin Ashi")
        indicator.description = "[ChangeMe]: SMA based on Heikin Ashi Candles"

        # This indicates that the indicator value will have the same unit as stock price and can be plotted
        # in the same y-axis as stock price as overlay. If the indicator results in a unit-less number like
        # ADX, you will set it to IndicatorValueType.NUMBER. Other possible values are PERCENT, RATIO, BOOLEAN
        indicator.valueType = IndicatorValueType.PRICE

        # Specify indicator parameters. For each parameter, you must provide IndicatorParamSpec with
        # paramType: one of [ParamType.INTEGER, ParamType.FLOAT, ParamType.STRING, ParamType.BOOLEAN]
        # The remaining properties of ParamSpec are optional
        # required: [True|False], defaults to True
        # options:List[Any]:  List of valid values . This will make the parameter input widget appear as a dropdown
        # validate: Optional function to validate parameter value
        indicator.addParam("period", IndicatorParamSpec(paramType=ParamType.INTEGER,
                                                        required=True,
                                                        defaultValue=5,
                                                        options=IndicatorParamSpec.PERIOD_VALUES))
        return indicator

    def computeSeries(self, params: Dict[str, Any], bars: List[Bar]) -> List[DatedValue]:
        # This example shows calculating SMA but using Heikin Ashi Candles. 
        # Convert the bars to Heikin Ashi
        heikinCandles = CommonUtils.toHeikinAshi(bars)
        # Utility method to extract dates and close as two Lists
        dates, close = CommonUtils.extractCloseSeries(bars)
        period = params["period"]
        smaSeries = talib.SMA(np.array(close), timeperiod=period)
        # You return List[DatedValue]. DatedValue object that has two properties: date: datetime, and value: float|int. Utility method is provided to do that
        # When the indicator is used in the strategy and screener, it only uses the last value of this indicator. However, you must return List of all values for two use cases
        # (1) To plot this indicator in the chart, (2) To use for backtest. Both of these use cases require all historical values of indicator
        return CommonUtils.createListOfDatedValue(dates, smaSeries)

`;


interface NewIndicatorBtn {
    onSuccess: (spec: IndicatorSpec) => void;
}

export function NewIndicatorBtn({onSuccess}: NewIndicatorBtn): React.JSX.Element {
    const [dialogOpen, setDialogOpen] = useState(false);

    const onSuccessHanlder = (newSpec: IndicatorSpec) => {
        setDialogOpen(false)
        onSuccess(newSpec);
    }

    return <>
        {dialogOpen && <IndicatorCreateEditDialog  onCancel={() => setDialogOpen(false)} onSuccess={onSuccessHanlder} />}  
        <UpgradeCheckButton op={OPERATION.ADD_CUSTOMINDICATOR} label="Custom Indicator">
            <Button onClick={() => setDialogOpen(true)}>New Custom Indicator</Button>
        </UpgradeCheckButton>  
        
    </>
}


interface IndicatorCreateEditDialogProps {
    indicatorId?: string; // undefined means created
    onCancel: () => void;
    onSuccess: (spec: IndicatorSpec) => void;
}
export function IndicatorCreateEditDialog({ indicatorId, onCancel, onSuccess }: IndicatorCreateEditDialogProps): React.JSX.Element {

    const [message, setMessage] = useState<Message>();
    const [code, setCode] = useState<string>();

    async function loadIndicatorCode(indicatorId: string | undefined) {
        if (indicatorId) {
            let indicatorApiClient: IndicatorAPIClient = AppContext.getInstance().indicatorApiClient;
            let indicatorCode = await indicatorApiClient.getCustomIndicatorCode(indicatorId)
            setCode(indicatorCode);
        } else {
            setCode(SAMPLE_INDICATOR)
        }
    }

    useEffect(() => {
        loadIndicatorCode(indicatorId)
    }, [indicatorId])

    const onDialogSubmit = async () => {
        try {
            let indicatorApiClient: IndicatorAPIClient = AppContext.getInstance().indicatorApiClient;
            let newSpec: IndicatorSpec = await indicatorApiClient.createUpdateCustomIndicator(code!)
            onSuccess(newSpec)
        } catch (error) {
            setMessage(Message.fromError(error))
        }
    }

    return <InflyDialog open title='Custom Indicator Code' maxWidth={"lg"} onClose={onCancel} onExecute={onDialogSubmit} okText={indicatorId ? 'Update': 'Create'}>

        <Stack gap={2}>

            <Paper elevation={5}>
                            <Stack gap={3} padding={4}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant='h6'> Tip</Typography>
                                    <InflyIcon name='tip' />
                                </Stack>
                                <Typography variant='body2'>
                                   We recommand to use investfly-sdk to develop Python based strategies and indicators locally using your favorite IDE (Pycharm, VSCode) and upload to Investfly using CLI. See  <A target="_blank" href="https://www.investfly.com/guides/docs/index.html">investfl-sdk api docs</A>  for more details
                                </Typography>
                            </Stack>
                        </Paper>

            <InflyMessage message={message} onClose={() => setMessage(undefined)} />


            <MonacoEditor
                value={code}
                language='python'
                width={`100%`}
                height={500}
                theme={'vs-dark'}
                options={{
                    // lineHeight: 1,
                    // tabIndex: 4,
                    glyphMargin: false,
                    matchBrackets: 'always', // type is  'never' | 'near' | 'always'
                    lineNumbers: 'on', //type is LineNumbersType
                    minimap: { enabled: false }, // type is IEditorMinimapOptions
                    scrollbar: {
                        // vertical: 'auto',
                        // horizontal: 'hidden',
                        // handleMouseWheel: true,
                    }, //type is IEditorScrollbarOptions
                    // tabSize: 4,
                    wordWrap: 'on',
                }}
                onChange={(value, event) => {
                    setCode(value)
                }}
            />

        </Stack>



    </InflyDialog>


}

