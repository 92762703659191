import {InvestflyModel} from '../InvestflyModel';
import {BrokerType, PortfolioIdentifierName} from './portfolio';

export class BrokerAccount extends InvestflyModel {
    accountId: string;
    username: string;
    brokerType: BrokerType;
    connected: boolean;

    static parseJSON(obj: {[index:string]: any} ): BrokerAccount {
        let brokerAccount = new BrokerAccount();
        Object.assign(brokerAccount, obj);
        return brokerAccount;
    }

    toPortfolioIdentifierName(): PortfolioIdentifierName {
        return {
            portfolioId: this.accountId,
            brokerType: this.brokerType,
            name: this.accountId,
        }
    }
}