import { Message } from "../models/common/message";
import { InvestflyModel } from "../models/InvestflyModel";
import { WatchList } from "../models/screener/watchlist";
import { RestAPIClient } from "./restAPIClient";

export class WatchListAPIClient {

    private restApiClient: RestAPIClient;
    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }
    
    async createWatchList(spec: WatchList): Promise<WatchList> {
        const res = await this.restApiClient.post("/watchlist/create", spec);
        return WatchList.parseJSON(res);
    }

    async deleteWatchList(id: number): Promise<Message> {
        const res = await this.restApiClient.delete("/watchlist/"+id);
        return Message.parseJSON(res);
    }

    async listWatchLists(): Promise<WatchList[]> {
        const res = await this.restApiClient.get("/watchlist/list");
        return InvestflyModel.parseList(res, WatchList.parseJSON);
    }

    async getWatchList(id: number): Promise<WatchList> {
        const res = await this.restApiClient.get("/watchlist/"+id);
        return WatchList.parseJSON(res);
    }

    async addSecurityToWatchList(id: number, ticker: string): Promise<WatchList> {
        const res = await this.restApiClient.post("/watchlist/"+id+"/add", ticker);
       return WatchList.parseJSON(res);
    }

    async removeSecurityFromWatchList(id: number, ticker: string): Promise<WatchList> {
        const res = await this.restApiClient.post("/watchlist/"+id+"/remove", ticker);
        return WatchList.parseJSON(res);
    }
}