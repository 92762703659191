/** @format */

import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { Component } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import A from 'containers/common/Anchor';
import { InflyMessage } from 'containers/common/InflyMessage';
import investfly from 'static/images/short_Investfly.png';
import { RouterAndThemeProps } from 'util/commonProps';
import SessionUtil from 'util/SessionUtil';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import { PricingController, PricingState } from './Pricing';
import DocumentHeader from 'containers/common/DocumentHeader';
import ReactGA from 'react-ga4';
import { Message, PaymentPlan, AppConstant, User, UserAPIClient, UserType } from 'api';
import { AppContext } from 'util/appContext';

export interface CheckoutState extends PricingState {
    _showPromoCode: boolean;
    promoCode: string;
    isLoadingApplyPromoCode: boolean;
    openStripeForm: boolean;
    message?: Message;
    discount?: number;
}

export class CheckoutController {
    _showPromoCode: boolean;
    promoCode: string;
    isLoadingApplyPromoCode: boolean;
    openStripeForm: boolean;
    message?: Message;

    pricingPlans: Map<UserType, PaymentPlan>;
    currentUser?: User;
    targetUserType?: UserType;
    targetPlan?: PaymentPlan;
    discount?: number;

    userApiClient: UserAPIClient;
    pricingController: PricingController;

    constructor(pricingController: PricingController) {
        this.userApiClient = AppContext.getInstance().userApiClient;
        this.pricingController = pricingController;
        this.promoCode = '';
        this._showPromoCode = false;
        this.openStripeForm = false;
        this.isLoadingApplyPromoCode = false;
        this.pricingPlans = pricingController.getState().pricingPlans;
    }

    async onPaymentSuccess(token: string): Promise<CheckoutState> {
        this.openStripeForm = false;
        try {
            let _state: PricingState = await this.pricingController.upgradeAccount(token);
            this.message = Message.success(_state?.message?.message as string);
        } catch (error) {
            this.message = Message.fromError(error);
        } finally {
            return this.getState();
        }
    }

    onStripeModalClose(): CheckoutState {
        this.openStripeForm = false;
        return this.getState();
    }

    handleIsLoadingApplyPromoCode(isValue: boolean): CheckoutState {
        this.isLoadingApplyPromoCode = isValue;
        return this.getState();
    }

    showPromoCode(): CheckoutState {
        this._showPromoCode = true;
        return this.getState();
    }

    clearPromoCode(): CheckoutState {
        this._showPromoCode = false;
        this.promoCode = '';
        return this.getState();
    }

    onPromoCodeChange(event: any): CheckoutState {
        this.promoCode = event.target.value;
        return this.getState();
    }

    clearMessage(): CheckoutState {
        this.message = undefined;
        return this.getState();
    }

    getState(): CheckoutState {
        const { pricingPlans, currentUser, targetUserType, targetPlan } =
            this.pricingController.getState();
        return {
            _showPromoCode: this._showPromoCode,
            promoCode: this.promoCode,
            isLoadingApplyPromoCode: this.isLoadingApplyPromoCode,
            openStripeForm: this.openStripeForm,
            message: this.message,
            pricingPlans,
            currentUser,
            targetUserType,
            targetPlan,
            discount: this.discount,
        };
    }
}
interface CheckoutProps extends RouterAndThemeProps {
    pricingController: PricingController;
}

class Checkout extends Component<CheckoutProps, CheckoutState> {
    pricingController: PricingController;
    checkoutController: CheckoutController;

    constructor(props: CheckoutProps) {
        super(props);

        this.pricingController = props.pricingController;
        this.checkoutController = new CheckoutController(this.pricingController);
        this.state = this.checkoutController.getState();
    }

    componentDidMount() {
        if (!this.pricingController.targetUserType) {
            this.props.navigate('/upgrade/pricing');
        }
        if (this.pricingController.UserType === UserType.STANDARD) {
            ReactGA.event('upgrade_checkout_view', {});
        }
    }

    onPaymentSuccess = (token: string) => {
        // this.onStripeModalClose();
        return this.checkoutController
            .onPaymentSuccess(token)
            .then((state: CheckoutState) =>
                this.setState(state, () => this.props.navigate('/upgrade/pricing'))
            );
    };

    onStripeModalClose = () => {
        this.setState(this.checkoutController.onStripeModalClose());
    };

    showPromoCode = (event: any) => {
        event.preventDefault();
        this.setState(this.checkoutController.showPromoCode());
    };

    clearPromoCode = (event: any) => {
        event.preventDefault();
        this.setState(this.checkoutController.clearPromoCode());
    };

    onPromoCodeChange = (event: any) => {
        this.setState(this.checkoutController.onPromoCodeChange(event));
    };

    applyCouponCode = (event: any) => {
        event.preventDefault();
        this.checkoutController.handleIsLoadingApplyPromoCode(true);
        this.pricingController.refreshPricingPlan(this.state.promoCode).then(() => {
            this.checkoutController.handleIsLoadingApplyPromoCode(false);
            this.setState(this.pricingController.getState());
        });
    };

    onAccountUpdateDialogClose = () => {
        this.setState(this.checkoutController.clearMessage());
        this.props.navigate('/upgrade/pricing');
    };

    onErrorDialogClose() {
        this.setState(this.checkoutController.clearMessage());
    }

    onToken = (token: any) => {
        ReactGA.event('upgrade_complete', {});
        this.onPaymentSuccess(token.id);
    };

    render() {
        const email = SessionUtil.findUserInfo()?.email;
        const { promoCode, _showPromoCode, targetPlan, targetUserType, discount, message } =
            this.state;
        const expiry = new Date();
        expiry.setDate(expiry.getDate()+7);

        const totalChargedToday = targetPlan?.freeTrial ? '$0' : `$${targetPlan?.amount}`;

        return (
            <DocumentHeader title={'Upgrade Checkout'}>
                <InflyMessage
                    message={message}
                    onClose={() => this.setState(this.checkoutController.clearMessage())}
                />
                <Stack mt={5} justifyContent={'center'} alignItems={'center'}>
                    <Card sx={{ maxWidth: 700 }}>
                        <CardHeader title='Upgrade Summary' />
                        <CardContent>
                            <InflyMessage
                                message={Message.info(
                                    `Downgrade anytime before your trial expires on ${expiry.toLocaleDateString()} to avoid charge.`
                                )}
                            />
                            <br />
                            <Grid
                                container
                                flexDirection={'column'}
                                spacing={2}
                                padding={2}
                                justifyContent={'center'}
                                alignItems='center'
                            >
                                <Grid item container justifyContent={'center'} alignItems='center'>
                                    <Grid item xs md>
                                        Plan
                                    </Grid>
                                    <Grid item xs md>
                                        <Typography align='right' variant='body1' fontWeight={600}>
                                            {targetUserType as UserType}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container justifyContent={'center'} alignItems='center'>
                                    <Grid item xs md>
                                        Amount charged now
                                    </Grid>
                                    <Grid item xs md>
                                        <Typography align='right' variant='body1' fontWeight={600}>
                                            $0.00
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container justifyContent={'center'} alignItems='center'>
                                    <Grid item xs md>
                                        Amount charged after trial
                                    </Grid>
                                    <Grid item xs md>
                                        <Typography align='right' variant='body1' fontWeight={600}>
                                            ${targetPlan?.amount}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Typography variant={'h5'}>
                                        {' '}
                                        <A href={'.#'} onClick={this.showPromoCode}>
                                            Have a promo code?
                                        </A>
                                    </Typography>
                                </Grid>
                                {_showPromoCode && (
                                    <Grid
                                        item
                                        container
                                        spacing={1}
                                        flexDirection={'column'}
                                        justifyContent='center'
                                    >
                                        <Grid item>
                                            <TextField
                                                id='outlined-basic'
                                                label='Promo Code'
                                                fullWidth
                                                name='promoCode'
                                                onChange={this.onPromoCodeChange}
                                                value={promoCode}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <ButtonGroup fullWidth>
                                                <Button
                                                    id='submitPromoCode'
                                                    type='submit'
                                                    onClick={this.applyCouponCode}
                                                >
                                                    Apply{' '}
                                                </Button>

                                                <Button
                                                    id='cancelSubmit'
                                                    color={'secondary'}
                                                    onClick={this.clearPromoCode}
                                                >
                                                    Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                        {discount && (
                                            <div>
                                                <Typography component={'p'} gutterBottom>
                                                    Congratulation! You can save $
                                                    {discount.toFixed(2)} from the promo code.
                                                </Typography>
                                            </div>
                                        )}
                                        <br />
                                    </Grid>
                                )}
                                <Grid item container justifyContent={'center'} alignItems='center'>
                                    <Grid item xs md>
                                        Total Charged Today
                                    </Grid>
                                    <Grid item xs md>
                                        <Typography align='right' variant='body1' fontWeight={600}>
                                            {totalChargedToday}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {/*stripe checkout a third party library*/}
                                    <StripeCheckout
                                        triggerEvent={'onClick'}
                                        email={email}
                                        // billingAddress
                                        description={`Upgrade to ${targetUserType} plan`}
                                        image={investfly}
                                        label={'Start FREE Trial'}
                                        locale='auto'
                                        name='Infly LLC'
                                        stripeKey={AppConstant.STRIPE_KEY}
                                        token={this.onToken}
                                        // zipCode
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Stack>
            </DocumentHeader>
        );
    }
}

export default withNavBarAndAuth(Checkout);
