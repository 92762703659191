/** @format */

import React, { Component } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    PaletteMode,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import CommonUtil from 'util/CommonUtil';
import {
    Bar,
    BrokerType,
    MarketDataAPIClient,
    Message,
    MessageType,
    PerformanceMetrics,
    PortfolioAPIClient,
} from 'api';
import { LoadingCard } from 'containers/common/LoadingCard';
import { BlinkTextComponent } from 'containers/common/BlinkComponet';
import PortfolioChart from './PortfolioChart';
import withScreenSize from '../HOC/WithScreenSize';
import { AppContext } from '../../util/appContext';
import { ErrorCard } from '../common/ErrorCard';
import { ScreenSizeProp } from 'util/commonProps';
import { PortfolioIdentifierProps } from './PortfolioDashboard';
import { DataDisplayCard } from 'containers/common/DataDisplayCard';

export interface PerformanceMetricState {
    cardContentHeight: number | string;
    isFullscreenEnabled: boolean;
    performance: PerformanceMetrics;
    ready: boolean;
}

interface PerformanceProps extends PortfolioIdentifierProps, ScreenSizeProp {}

class Performance extends Component<PerformanceProps, PerformanceMetricState> {
    marketDataApiClient: MarketDataAPIClient;
    portfolioApiClient: PortfolioAPIClient;

    constructor(props: PerformanceProps) {
        super(props);
        this.marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        this.portfolioApiClient = AppContext.getInstance().portfolioApiClient;

        this.state = {
            cardContentHeight: 390,
            isFullscreenEnabled: false,
            ready: false,
            performance: undefined!,
        };
    }

    async componentDidMount() {
        this.loadPerformance();
    }

    async loadPerformance() {
        try {
            let performance = await this.portfolioApiClient.getPerformance(
                this.props.currentPortfolio.portfolioId
            );
            this.setState({ performance: performance });
        } catch (error) {
            this.props.onMessage(Message.fromError(error));
        } finally {
            this.setState({ ready: true });
        }
    }

    render() {
        const performance = this.state.performance;

        if (!this.state.ready) {
            return <LoadingCard title={'Performance'}  />;
        }

        let portfolioReturn: Map<string, number> = new Map();
        portfolioReturn.set('Net Return (%)', performance.netReturn);
        portfolioReturn.set('AnnualizedReturn Return (%)', performance.annualizedReturn);
        portfolioReturn.set('Profit Factor', performance.profitFactor);
        portfolioReturn.set('Max Drawdown (%)', performance.maxDrawdownPct);


        let trades: Map<string, number> = new Map();
        trades.set('Total Trades', performance.totalTrades);
        trades.set('Win Ratio (%)', performance.winRatioPct);
        trades.set('Avg. Profit Per Trade (%)', performance.avgProfitPerTradePct);
        trades.set('Avg Loss Per Trade (%)', performance.avgLossPerTradePct);
        trades.set('Expected Return Per Trade (%)', performance.avgLossPerTradePct);



        return (
            <>
                <Card id={'performance'}>
                    <CardHeader title='Performance' />

                    <CardContent>
                        <Stack gap={2}>
                            <PortfolioChart
                                dailyEquityValue={performance.portfolioValues}
                                onMessage={this.props.onMessage}
                            />

                            <Grid container direction='row' spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <DataDisplayCard title='Return' data={portfolioReturn} />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <DataDisplayCard title='Trades' data={trades} />
                                </Grid>

                            </Grid>
                        </Stack>
                    </CardContent>
                </Card>
            </>
        );
    }
}

export default withScreenSize(Performance);
