import {RestAPIClient} from "./restAPIClient";
import {ScreenerResult, ScreenerModel} from "../models/screener/screener";
import { Message } from "../models/common/message";
import { InvestflyModel } from "../models/InvestflyModel";


export class ScreenerAPIClient {

    private restApiClient: RestAPIClient;
    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }

    async getSampleScreeners(): Promise<ScreenerModel[]>{
        const res = await  this.restApiClient.get("/screener/sample");
        return  InvestflyModel.parseList(res, ScreenerModel.parseJSON);
    }

    async getMyScreeners():Promise<ScreenerModel[]>{
        const res = await  this.restApiClient.get("/screener/list");
        return  InvestflyModel.parseList(res, ScreenerModel.parseJSON);
    }

    async getUserScreeners(username: string): Promise<ScreenerModel[]>{
        const res = await  this.restApiClient.get(`/screener/${username}/list`);
        return  InvestflyModel.parseList(res, ScreenerModel.parseJSON);
    }

    async getScreener(id: number): Promise<ScreenerModel>{
        const json = await this.restApiClient.get(`/screener/${id}`);
        return ScreenerModel.parseJSON(json);
    }


    async runScreener( screenerRequest: ScreenerModel): Promise<ScreenerResult>{
        let url: string  = "/screener/run";
        const json = await this.restApiClient.post(url, screenerRequest);
        return ScreenerResult.parseJSON(json);
    }

    async refreshScreener(screenerId: number) : Promise<ScreenerResult> {
        const json = await this.restApiClient.post("/screener/"+screenerId+"/refresh", null);
        return ScreenerResult.parseJSON(json);
    }

    async updateScreenerMetadata(screenerModel: ScreenerModel): Promise<ScreenerModel>{
        const json = await this.restApiClient.post("/screener/"+screenerModel.screenerId+"/update", screenerModel);
        return ScreenerModel.parseJSON(json);
    }

    async deleteScreener(screenerId: number) : Promise<Message> {
        const json = await this.restApiClient.delete("/screener/"+screenerId);
        return Message.parseJSON(json);
    }


   

}