/** @format */

import Button from '@mui/material/Button';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import React from 'react';
import InflyProgress from './InflyProgress';
import { IconButton, Tooltip } from '@mui/material';

type Props = DefaultComponentProps<any> & {
    label?: string;
    onClick: () => Promise<any> | any; // ActionButton in used in InflyDiaglog Execute, which may not call server side api
    startIcon?: React.ReactNode;
    disabled?: boolean;
    className?: string;
    color?: 'primary' | 'error' | 'secondary';
    buttonType?: 'iconButton' | 'button';
};

type State = {
    inProgress: boolean;
};

export class ActionButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { inProgress: false };
    }

    handleClick = (event: any) => {
        this.setState({ inProgress: true });
        let promise = this.props.onClick();
        console.log(promise);
        if (promise !== undefined && promise instanceof Promise) {
            promise.finally(() => this.setState({ inProgress: false }));
        } else {
            this.setState({ inProgress: false });
        }
    };

    render() {
        const { children, label, disabled, buttonType, startIcon, tooltip, variant, ...rest } =
            this.props;

        if (buttonType === 'iconButton') {
            return (
                <IconButton
                    {...rest}
                    title={tooltip}
                    onClick={this.handleClick}
                    id={`iconButton_${label}`}
                    disabled={this.state.inProgress || Boolean(disabled)}
                    size='large'
                >
                    {startIcon as React.ReactNode}
                    {label as string}
                </IconButton>
            );
        }
        return (
            <>
                <Button
                    {...rest}
                    title={tooltip}
                    startIcon={startIcon}
                    onClick={this.handleClick}
                    disabled={this.state.inProgress || Boolean(disabled)}
                >
                    {label}
                    {this.state.inProgress && <InflyProgress />}
                </Button>
            </>
        );
    }
}
