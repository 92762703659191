import { Box, Container } from "@mui/material";
import { ApiClientFactory } from "api";
import { SecurityTriggerExpression } from "api/models/trigger/SecurityTriggerExpression";
import { SecurityUniverseSelector, SecurityUniverseType } from "api/models/trigger/SecurityUniverseSelector";
import { ListItemDisplayCard } from "containers/common/ListItemDisplayCard";
import { MultiSelectField, SelectField, selectOptionsFromEnum } from "containers/common/SelectField";
import { FilterExpressionComp } from "containers/Trigger/FilterExpressionComp";
import IndicatorParamDialog from "containers/Trigger/IndicatorParamDialog";
import { SecurityFilterExpressionComp } from "containers/Trigger/SecurityFilterExpressionComp";
import { SecurityUniverseSelectorComp } from "containers/Trigger/SecurityUniverseSelectorComp";
import React, { useEffect, useState } from "react";

export function TestPage(): React.JSX.Element{

    const [ready, setReady] = React.useState(false);

    useEffect(() => {

        async function authenticate(){
            const restApiClient = ApiClientFactory.getInstance().restApiClient;
            if(restApiClient.headers.size < 2){
                await ApiClientFactory.getInstance().userApiClient.login("testuser", "welcome1")
            }
            setReady(true)
        }
    
        authenticate();
        
    })

    const json = {"filterGroups":[{"filterConditions":[{"leftCondition":["marketcap"],"rightCondition":["10B"],"operator":"GREATER_THAN"}]},{"filterConditions":[{"leftCondition":["sma5"],"rightCondition":["sma10"],"operator":"GREATER_THAN"}]}],"dataParams":{"marketcap":{"field":"MARKET_CAP","datatype":"FINANCIAL"},"sma10":{"indicator":"SMA","barinterval":"ONE_MINUTE","period":10,"datatype":"INDICATOR"},"10B":{"datatype":"CONST","value":1.0E10},"sma5":{"indicator":"SMA","barinterval":"ONE_MINUTE","period":5,"datatype":"INDICATOR"}}};
    
    const [securityFilterExpression, setSecurityFilterExpression] = React.useState(SecurityTriggerExpression.parseJSON(json));


    function onSecurityExpressionChange(expr: SecurityTriggerExpression){
        setSecurityFilterExpression(expr.clone());
    }


    const [universeSelector, setUniverseSelector] = useState(SecurityUniverseSelector.createDefault());

    function onUniverseSelectorChange(universe: SecurityUniverseSelector){
        console.log(universe);
        setUniverseSelector(universe.clone())
    }


    return(
        <Container>

            { /* ready && <SecurityFilterExpressionComp 
                securityFilterExpression={securityFilterExpression} 
        onChange={onSecurityExpressionChange}/> */}

        {/* <SecurityUniverseSelectorComp securityUniverseSelector={universeSelector} onChange={onUniverseSelectorChange} />  */}

        <Box sx={{width: 400}}>
      
        </Box>


   
            
        </Container>
    )
}