/** @format */

import { FormControl, FormLabel, Grid, Paper, TextField, Typography } from '@mui/material';
import { Message, UserType } from 'api';
import React, { Component } from 'react';
import withRouter from 'containers/HOC/WithRouter';
import { ActionButton } from 'containers/common/ActionButton';
import { InflyMessage } from 'containers/common/InflyMessage';
import { RouteProps } from 'util/commonProps';

interface UserProfileProps extends RouteProps {
    onSubmit: Function;
    firstName: string;
    lastName: string;
    address: string;
    userType: UserType;
}

interface UserProfileState {
    firstName: string;
    lastName: string;
    address: string;
    userType: UserType;
    message?: Message;
}

class UserProfile extends Component<UserProfileProps, UserProfileState> {
    constructor(props: UserProfileProps) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            address: '',
            userType: UserType.UNCONFIRMED,
        };
    }
    componentDidMount(): void {
        this.setState({
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            address: this.props.address,
            userType: this.props.userType,
        });
    }

    updateAccount = async () => {
        let { firstName, lastName, address } = this.state;
        return this.props.onSubmit(firstName, lastName, address);
    };

    clearMessage = () => {
        this.setState({ message: undefined });
    };

    render() {
        const { firstName, lastName, address } = this.state;
        return (
            <Paper elevation={0} square={false} sx={{ paddingInline: 3 }}>
                <Typography variant={'h5'} align={'center'}>
                    Update Your Profile{' '}
                </Typography>
                <br />
                <form>
                    {this.state.message && (
                        <InflyMessage
                            message={this.state.message}
                            onClose={() => this.clearMessage()}
                        />
                    )}
                    <Grid container spacing={1} justifyContent='flex-start'>
                        <Grid xs={12} md={6} item>
                            <FormControl component='fieldset' fullWidth>
                                <FormLabel component='legend'> First Name </FormLabel>
                                <TextField
                                    size={'small'}
                                    variant={'outlined'}
                                    onChange={(event) =>
                                        this.setState({ firstName: event.target.value })
                                    }
                                    value={firstName}
                                    id={'firstName'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <FormControl component='fieldset' fullWidth>
                                <FormLabel component='legend'>Last Name</FormLabel>
                                <TextField
                                    size={'small'}
                                    variant={'outlined'}
                                    onChange={(event) =>
                                        this.setState({ lastName: event.target.value })
                                    }
                                    value={lastName}
                                    id={'lastName'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} item>
                            <FormControl component='fieldset' fullWidth>
                                <FormLabel component='legend'> Address </FormLabel>
                                <TextField
                                    size={'small'}
                                    variant={'outlined'}
                                    rows={4}
                                    multiline
                                    onChange={(event) =>
                                        this.setState({ address: event.target.value })
                                    }
                                    value={address}
                                    id={'address'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <ActionButton
                                color={'primary'}
                                id='update_account_btn'
                                onClick={() => {
                                    return this.updateAccount();
                                }}
                            >
                                Update
                            </ActionButton>
                        </Grid>
                    </Grid>
                    <br />
                </form>
            </Paper>
        );
    }
}

export default withRouter(UserProfile);
