/** @format */

import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import React, { Component } from 'react';
import { RouterAndThemeProps } from 'util/commonProps';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import './pricing.css';
import DialogButton from 'containers/common/DialogButton';
import { InflyMessage } from 'containers/common/InflyMessage';
import cardList from 'static/images/cardsList.png';
import DocumentHeader from 'containers/common/DocumentHeader';
import CommonUtil from 'util/CommonUtil';
import { Feedback } from '../User/Feedback';
import ReactGA from 'react-ga4';

import { UserType, Message, PaymentPlan, UserError, User, UserAPIClient, PaymentDetail } from 'api';
import { AppContext } from 'util/appContext';
import SessionUtil from 'util/SessionUtil';

export interface PricingState {
    pricingPlans: Map<UserType, PaymentPlan>;
    currentUser?: User;
    targetUserType?: UserType;
    targetPlan?: PaymentPlan;
    message?: Message;
}

export class PricingController {
    pricingPlans: Map<UserType, PaymentPlan>;
    currentUser?: User;

    targetUserType?: UserType;
    promoCode: string = '';
    message?: Message;
    userApiClient: UserAPIClient;

    constructor() {
        this.userApiClient = AppContext.getInstance().userApiClient;
        this.pricingPlans = new Map<UserType, PaymentPlan>();
    }

    async loadUserProfilePricingPlans(username: string) {
        this.currentUser = await this.userApiClient.getUserProfile(username);
        await this.refreshPricingPlan('');
        return true;
    }

    async refreshPricingPlan(promoCode: string): Promise<PricingState> {
        this.promoCode = promoCode;
        try {
            this.pricingPlans = await this.userApiClient.getQualifyingPlans(promoCode);
            const freePlan: PaymentPlan = new PaymentPlan();
            freePlan.amount = 0;
            this.pricingPlans.set(UserType.STANDARD, freePlan);
        } catch (error) {
            this.message = Message.fromError(error);
        }
        return this.getState();
    }

    async upgradeDowngradeAccount(): Promise<PricingState> {
        if (
            this.targetUserType === UserType.ELITE ||
            (this.targetUserType === UserType.PREMIUM && this.UserType === UserType.STANDARD)
        ) {
            return this.upgradeAccount();
        } else {
            return this.downgradeAccount(this.targetUserType!);
        }
    }

    async upgradeAccount(token?: string): Promise<PricingState> {
        if (!this.targetUserType) {
            throw new UserError('Target plan is not selected');
        }

        const paymentDetail: PaymentDetail = new PaymentDetail();
        if (token && token !== '') {
            paymentDetail.token = token;
        }
        paymentDetail.userType = this.targetUserType!;
        paymentDetail.paymentPlan = this.pricingPlans.get(this.targetUserType!)!;
        if (this.promoCode !== '') {
            paymentDetail.couponCode = this.promoCode;
        }
        try {
            const message = await this.userApiClient.changeMyUserType(paymentDetail);
            this.currentUser = await this.userApiClient.getUserProfile(this.currentUser!.username);

            SessionUtil.saveUserInfo(this.currentUser);
            this.message = message;
        } catch (error) {
            this.message = Message.fromError(error);
        }
        return this.getState();
    }

    async downgradeAccount(userType: UserType): Promise<PricingState> {
        const paymentDetail: PaymentDetail = new PaymentDetail();
        paymentDetail.userType = userType;

        if (userType === UserType.PREMIUM) {
            paymentDetail.paymentPlan = this.pricingPlans.get(userType)!;
        }
        try {
            this.message = await this.userApiClient.changeMyUserType(paymentDetail);
            this.currentUser = await this.userApiClient.getUserProfile(this.currentUser!.username);
        } catch (error) {
            this.message = Message.fromError(error);
        }
        return this.getState();
    }
    set TargetUserType(value: UserType | undefined) {
        this.targetUserType = value;
    }

    get PricingPlans() {
        return this.pricingPlans;
    }
    get CurrentUser() {
        return this.currentUser;
    }
    get UserType(): UserType {
        return this.currentUser?.userType as UserType;
    }

    get CurrentPlan(): PaymentPlan {
        return this.pricingPlans.get(this.currentUser!.userType)!;
    }

    get TargetPlan(): PaymentPlan | undefined {
        if (!this.targetUserType) return undefined;
        return this.pricingPlans.get(this.targetUserType);
    }
    setMessage(message: Message): PricingState {
        this.message = message;
        return this.getState();
    }
    getState(): PricingState {
        return {
            pricingPlans: this.pricingPlans,
            currentUser: this.currentUser,
            targetUserType: this.targetUserType,
            targetPlan: this.TargetPlan,
            message: this.message,
        };
    }
}

interface UpgradeDowngradeBtnProps {
    currentType: UserType;
    currentPlan: PaymentPlan;
    targetType: UserType;
    targetPlan: PaymentPlan;
    onCheckoutClick: any;
}

function UpgradeDowngradeBtn(props: UpgradeDowngradeBtnProps) {
    let checkoutBtnTxt = '';
    let isDisabled: boolean = false;
    let isDowngrade: boolean = false;
    if (props.currentType === props.targetType) {
        checkoutBtnTxt = 'Current Plan';
        isDisabled = true;
    } else {
        if (
            props.targetType === UserType.ELITE ||
            (props.targetType === UserType.PREMIUM && props.currentType === UserType.STANDARD)
        ) {
            checkoutBtnTxt = props.targetPlan.freeTrial ? 'Free Trial' : 'Upgrade';
        } else {
            isDowngrade = true;
            checkoutBtnTxt = 'Downgrade';
        }
    }

    let goToCheckOutPage: boolean = props.currentPlan.amount === 0 && props.targetPlan.amount > 0;

    if (props.targetType === UserType.STANDARD) {
        return (
            <Feedback
                purpose='feedback'
                id={`standard_btn`}
                onSubmitComplete={(proceed) => props.onCheckoutClick()}
                disabled={isDisabled}
                sx={{
                    lineHeight: '1.3em',
                    borderRadius: '.3rem !important',
                    fontSize: ' 1rem !important',
                    padding: {
                        sm: '.5rem 1rem',
                        xs: '.75rem 2rem',
                    },
                    width: 'max-content',
                }}
                dialogOkText={`${checkoutBtnTxt}`}
                variant={'outlined'}
                color={'error'}
            >
                {checkoutBtnTxt}
            </Feedback>
        );
    } else {
        if (goToCheckOutPage) {
            return (
                <Button
                    variant="contained"
                    disabled={isDisabled}
                    id={`${props.targetType}_btn`}
                    onClick={(event: any) => {
                        event.preventDefault();
                        props.onCheckoutClick();
                    }}
                    sx={{
                        lineHeight: '1.3em',
                        borderRadius: '.3rem !important',
                        fontSize: ' 1rem !important',
                        padding: {
                            sm: '.5rem 1rem',
                            xs: '.75rem 2rem',
                        },
                        width: 'max-content',
                    }}
                    color={isDowngrade ? 'error' : 'secondary'}
                >
                    {checkoutBtnTxt}
                </Button>
            );
        } else {
            return (
                <DialogButton
                    id={`${props.targetType}_btn`}
                    disabled={isDisabled}
                    dialogtitle={`Upgrade to ${props.targetType}`}
                    color={isDowngrade ? 'error' : 'secondary'}
                    sx={{
                        lineHeight: '1.3em',
                        borderRadius: '.3rem ',
                        fontSize: ' 1rem ',
                        padding: {
                            sm: '.5rem 1rem',
                            xs: '.75rem 2rem',
                        },
                    }}
                    onClick={() => props.onCheckoutClick()}
                    label={checkoutBtnTxt}
                >
                    <Typography variant='body1' gutterBottom>
                        You will be moved to {props.targetType} plan effective immediately. The cost
                        for the current billing cycle will be prorated. Please click Authorize to
                        continue.
                    </Typography>
                </DialogButton>
            );
        }
    }
}

interface PaymentProps extends RouterAndThemeProps {
    pricingController: PricingController;
}

interface PaymentState {
    message?: Message;
}

class Payment extends Component<PaymentProps, PaymentState> {
    pricingController: PricingController;

    constructor(props: PaymentProps) {
        super(props);
        this.pricingController = props.pricingController;
        const fromActivate = CommonUtil.getQueryParam(props, 'fromActivate');

        if (fromActivate && fromActivate === '1')
            this.pricingController.setMessage(
                Message.info(
                    'Your account has now been activated. Select a membership level for a FREE one month trial. You can easily downgrade anytime'
                )
            );
        this.state = this.pricingController.getState();
    }

    componentDidMount() {
        if (this.pricingController.UserType === UserType.STANDARD) {
            ReactGA.event('upgrade_pricing_view', {});
        }
    }

    onCheckoutClick(targetUserType: UserType) {
        const currentPlan: PaymentPlan = this.pricingController.CurrentPlan;

        this.pricingController.TargetUserType = targetUserType;
        const targetPlan = this.pricingController.TargetPlan;

        // Anytime we go from FREE -> PAID plan, we need to redirect to checkout page
        // Due to coupons, even PREMIUM/ELITE can be FREE in some cases.
        // Hence, instead of using PlanType, we use amount to make determination
        if (currentPlan?.amount == 0 && targetPlan?.amount !== 0) {
            // going from FREE -> PAID
            return this.props.navigate(`/upgrade/Checkout`);
        } else {
            return this.pricingController
                .upgradeDowngradeAccount()
                .then(({ message }) => this.setState({ message }));
        }
    }

    clearMessage() {
        this.setState({ message: undefined });
    }

    render() {
        const { message } = this.state;
        const currentPlan: PaymentPlan = this.pricingController.CurrentPlan;
        const currentType = this.pricingController.UserType;

        let pricingPlans = this.pricingController.PricingPlans;

        const standardPlan: PaymentPlan = pricingPlans.get(UserType.STANDARD) as PaymentPlan;
        const premiumPlan: PaymentPlan = pricingPlans.get(UserType.PREMIUM) as PaymentPlan;
        const elitePlan: PaymentPlan = pricingPlans.get(UserType.ELITE) as PaymentPlan;
        const tiers = [
            {
                title: UserType.STANDARD,
                subheader: 'Explore Investfly',
                price: standardPlan.getAmountString(),
                description: [
                    <span>
                        <b>1</b> Virtual Portfolio.
                    </span>,
                    <span>
                        <b>1</b> Stock Screener.
                    </span>,
                    <span>
                        <b>1</b> Stock Alert.
                    </span>,
                    <span>
                        <b>1</b> Triggered Trade Order.
                    </span>,
                    <>
                        <p>Supported Securities: </p>{' '}
                        <p>
                            &nbsp;&nbsp;<b>STOCK, ETF</b>.
                        </p>
                    </>,
                ],
                buttonText: 'Free Trial',
                paymentPlan: standardPlan,
                buttonVariant: 'contained',
            },
            {
                title: UserType.PREMIUM,
                subheader: 'Develop automated trading strategy',
                price: premiumPlan.getAmountString(),
                description: [
                    <span>
                        <b>5</b> Virtual Portfolio.
                    </span>,
                    <span>
                        <b>5</b> Stock Screeners.
                    </span>,
                    <span>
                        <b>5</b> Stock Alerts.
                    </span>,
                    <span>
                        <b>Unlimited </b>Triggered Trade Orders.
                    </span>,
                    <>
                        <p>Supported Securities: </p>{' '}
                        <p>
                            &nbsp;&nbsp;<b>STOCK, ETF, OPTION, FUTURE.</b>
                        </p>
                    </>,
                    <span>
                        Run <b> Automated </b>Trading Strategy.
                    </span>,
                    <span>
                        <b>Backtest </b>Trading Strategy.
                    </span>,
                    <span>
                        Create and host <b>games</b>.
                    </span>,
                ],
                buttonText: 'Free Trial',
                paymentPlan: premiumPlan,
                buttonVariant: 'contained',
            },
            {
                title: UserType.ELITE,
                subheader: 'Connect broker and deploy strategy',
                price: elitePlan.getAmountString(),
                description: [
                    <span>
                        <b>10</b> Virtual Portfolio.
                    </span>,
                    <span>
                        <b>10</b> Stock Screener.
                    </span>,
                    <span>
                        <b>10</b> Stock Alert.
                    </span>,
                    <span>
                        <b>Unlimited </b>Triggered Trade Orders
                    </span>,
                    <>
                        <p>Supported Securities: </p>{' '}
                        <p>
                            &nbsp;&nbsp;<b>STOCK, ETF,OPTION, FUTURE</b>.
                        </p>
                    </>,
                    <span>
                        Run <b>Automated </b>Trading Strategy.
                    </span>,
                    <span>
                        <b>Backtest </b>Trading Strategy.
                    </span>,
                    <span>
                        Create and host <b>games</b>.
                    </span>,
                    <span>
                        Connect live <b>broker account</b>.
                    </span>,
                ],
                buttonText: 'Free Trial',
                paymentPlan: elitePlan,
                buttonVariant: 'contained',
            },
        ];
        return (
            <DocumentHeader title={'Upgrade Account'}>
                <InflyMessage message={message} onClose={() => this.clearMessage()} />
                <Container disableGutters maxWidth='sm' component='main' sx={{ pt: 8, pb: 6 }}>
                    <Typography
                        component='h3'
                        align='center'
                        color='text.primary'
                        gutterBottom
                        fontWeight={400}
                        fontSize={'3rem'}
                    >
                        Investing Pricing Plans
                    </Typography>
                    <Typography
                        component='p'
                        align='center'
                        color=' #016b6a'
                        letterSpacing='1px'
                        fontSize='1.25rem !important'
                    >
                        <Typography
                            component={'strong'}
                            fontWeight='bold'
                            fontSize='1.25rem !important'
                        >
                            7 days risk-free trial.
                        </Typography>{' '}
                        No Hidden Fees.
                    </Typography>
                </Container>

                <Container maxWidth={false} component='main'>
                    <Grid container spacing={3} justifyContent='center'>
                        {tiers.map((tier) => (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={tier.title} xs={12} sm={6} md={4} height={'auto'}>
                                <Card
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        boxShadow: 5,
                                        p: {
                                            md: '3rem',
                                            sm: '1rem',
                                            xs: '0',
                                        },

                                        borderTop: '10px solid',
                                        borderColor: () => {
                                            if (tier.title === UserType.STANDARD) return '#1f7ccc';
                                            if (tier.title === UserType.PREMIUM) return '#0B807C ';
                                            if (tier.title === UserType.ELITE) return '#ff7557';
                                        },
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    <CardHeader
                                        title={tier.title}
                                        subheader={tier.subheader}
                                        titleTypographyProps={{
                                            fontSize: '1rem',
                                            color: (theme) =>
                                                theme.palette.mode === 'light'
                                                    ? '#1f1f24'
                                                    : theme.palette.grey[200],
                                            fontWeight: 700,
                                            letterSpacing: '1.5px',
                                            textTransform: 'uppercase',
                                        }}
                                        subheaderTypographyProps={{
                                            variant: 'h6',
                                            color: (theme) =>
                                                theme.palette.mode === 'light'
                                                    ? '#626266'
                                                    : theme.palette.grey[200],
                                            fontSize: '1rem',
                                            fontWeight: 400,
                                            letterSpacing: 'normal',
                                            textTransform: 'none',
                                        }}
                                        sx={{
                                            backgroundColor: 'transparent',
                                            pb: 1,
                                        }}
                                    />
                                    <CardContent>
                                        <Stack
                                            flexDirection='row'
                                            justifyContent='flex-start'
                                            alignItems='baseline'
                                            marginBottom={2}
                                            color='#1f1f24'
                                            fontSize={2}
                                            lineHeight={'50px'}
                                            fontWeight={700}
                                            letterSpacing={'-.05em'}
                                            margin={[0, 0, 0.5, 0]}
                                        >
                                            <Typography
                                                component='span'
                                                variant='h6'
                                                color='text.primary'
                                            >
                                                {tier.price}
                                            </Typography>
                                        </Stack>
                                        <CardActions
                                            sx={{
                                                p: {
                                                    xs: 2,
                                                    sm: 1,
                                                },
                                            }}
                                        >
                                            <UpgradeDowngradeBtn
                                                currentType={currentType}
                                                currentPlan={currentPlan}
                                                targetType={tier.title}
                                                targetPlan={tier.paymentPlan}
                                                onCheckoutClick={() =>
                                                    this.onCheckoutClick(tier.title)
                                                }
                                            />
                                        </CardActions>
                                        <br />
                                        <br />
                                        <Box
                                            component={'ul'}
                                            sx={{
                                                listStyle: 'none',
                                                p: 0,
                                                marginBottom: '1.5rem',
                                                paddingBlock: '0.25rem',
                                                borderTop: '1px solid',
                                                borderColor: (theme) =>
                                                    theme.palette.mode === 'light'
                                                        ? '#dee2e6'
                                                        : '#373434',
                                            }}
                                        >
                                            {tier.description.map((line, index) => (
                                                <Box
                                                    component={'li'}
                                                    key={tier.title + index}
                                                    sx={{
                                                        fontWeight: 400,
                                                        letterSpacing: 'normal',
                                                        marginBottom: '1em',
                                                        textUnderlineOffset: '0.1em',
                                                        p: 0,
                                                    }}
                                                >
                                                    {line}
                                                </Box>
                                            ))}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <br />
                    <Stack flexDirection='row' justifyContent={'center'} gap={'5px'}>
                        <img
                            src='https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_111x69.jpg'
                            height='24'
                            alt='PayPal Logo'
                            style={{ marginTop: '1px' }}
                        />
                        <img src={cardList} height='26px' alt='Pay With Card' />
                        <Typography
                            component='i'
                            className='fa fa-cc-jcb'
                            aria-hidden='true'
                            style={{ fontSize: '1.6rem' }}
                        />
                    </Stack>
                    <br />
                </Container>
            </DocumentHeader>
        );
    }
}

export default withNavBarAndAuth(Payment);
