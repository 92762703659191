import CommonUtil from 'util/CommonUtil';
import {SecurityType} from '../marketdata/security';
import { SecurityTriggerExpression } from '../trigger/SecurityTriggerExpression';


export class ScreenerModel {
    screenerId: number;
    username: string;
    name: string;
    description?: string;
    isPublic: boolean;

    screenerScope: ScreenerScope;
    query: SecurityTriggerExpression

    oneTimeQuery: boolean = false

    static parseJSON(obj: {[index:string]: any}): ScreenerModel {
        let screenerModel = new ScreenerModel()
        Object.assign(screenerModel, obj)
        screenerModel.screenerScope = ScreenerScope.parseJSON(obj.screenerScope);
        screenerModel.query = SecurityTriggerExpression.parseJSON(obj.query);

        return screenerModel;

    }

    static createDefault(): ScreenerModel {
        let obj = new ScreenerModel();
        obj.isPublic  = false;
        obj.screenerScope = ScreenerScope.getStockScope();
        obj.query = SecurityTriggerExpression.createDefault();
        return obj;
    }

    clone(): ScreenerModel {
        let clone = new ScreenerModel();
        Object.assign(clone, this);
        clone.screenerScope = this.screenerScope.clone();
        clone.query = this.query.clone();
        return clone;
    }

    validate(): string[] {
        let errors: string[] = [];
        if (!this.name){
            errors.push("Screener Nameis required")
        }
        errors = [...errors, ...this.screenerScope.validate()]
        errors = [...errors, ...this.query.validate()]
        return errors
    }
}

export class ScreenerScope {
    securityType: SecurityType // only STOCK or ETF is supported
	industries?: string[]; // only valid if securityType is STOCK

    static parseJSON(obj: {[index:string]: any}): ScreenerScope {
        let scope = new ScreenerScope();
        Object.assign(scope, obj);
        if(obj.industries === undefined && scope.securityType  == SecurityType.STOCK){
            scope.industries = [];
        }
        return scope;
    }

    static getStockScope(): ScreenerScope {
        let scope = new ScreenerScope();
        scope.securityType = SecurityType.STOCK;
        scope.industries = [];
        return scope;
    }

    static getEtfScope(): ScreenerScope {
        let scope = new ScreenerScope();
        scope.securityType = SecurityType.ETF;
        scope.industries = undefined;
        return scope;
    }

    setSecurityType(securityType: SecurityType){
        this.securityType = securityType;
        if(this.securityType == SecurityType.ETF){
            this.industries = undefined;
        }else{
            this.industries = []
        }
    }

    validate(): string[] {
        let errors: string[] = [];
        if(!this.securityType){
            errors.push("ScreenerScope.SecurityType [Stock|ETF] must be selected");
        }
        return errors;
    }

    clone(): ScreenerScope {
        let clone = new ScreenerScope();
        clone.securityType = this.securityType;
        if(this.industries){
            clone.industries = [...this.industries];
        }

        return clone;
    }
}


export class ScreenerResult {

    screenerId: Number
    results: Array<Map<string, any>> = []

    static parseJSON(obj: {[index:string]: any}): ScreenerResult {
        let screenerResult = new ScreenerResult()
        screenerResult.screenerId = obj.screenerId;
        screenerResult.results = [];
        for(let row of obj.results){
            let map: Map<string, any> = new Map();
            for (let [key, value] of Object.entries(row)){
                if (typeof value == 'number' && !Number.isInteger(value)){
                    map.set(key, CommonUtil.roundToTwoDecimal(value));
                }else{
                    map.set(key, value);
                }
            }
            screenerResult.results.push(map);
        }
        return screenerResult

    }
}

export class SecurityMetricValues  {
    security: string;
    metrics: Map<string, any> = new Map();
}
