import { Validator } from "../../utils/Validator";
import {InvestflyModel} from "../InvestflyModel";

export enum TimeUnit {
    MINUTES = "MINUTES",
    HOURS = "HOURS",
    DAYS = "DAYS"
}

export class TimeInterval extends InvestflyModel {

    value: number;
    unit: TimeUnit;

    static fromValue(value: number, unit: TimeUnit): TimeInterval {
        let timeInterval:TimeInterval = new TimeInterval();
        timeInterval.value = value;
        timeInterval.unit = unit;
        return timeInterval;
    }


    static parseJSON(obj: any): TimeInterval  {
        let timeInterval:TimeInterval = new TimeInterval();
        Object.assign(timeInterval, obj);

        timeInterval.unit = (<any>TimeUnit)[obj.unit];
        return timeInterval;
    }

    clone(): TimeInterval {
        let clone = new TimeInterval();
        Object.assign(clone, this);
        return clone;
    }

    toMillis(): number {
        let multiplier: number = 1;

        if(this.unit === TimeUnit.MINUTES) multiplier = 60*1000;
        else if(this.unit === TimeUnit.HOURS) multiplier = 60*60*1000;
        else if(this.unit === TimeUnit.DAYS) multiplier = 24*60*60*1000;
        
        return this.value * multiplier;
    }

    compareTo(anotherInterval: TimeInterval): number {
        // return +1 if this interval is greater than another interval, -1 if less than, 0 if they are equual
        let thisMillis = this.toMillis();
        let anotherMillis = anotherInterval.toMillis();
        if(thisMillis > anotherMillis) return 1;
        else if(thisMillis < anotherMillis) return -1;
        else return 0;
    }

    isGreaterThan(anotherInterval: TimeInterval): boolean {
        return this.compareTo(anotherInterval) === 1;
    }

    isLessThan(anotherInterval: TimeInterval): boolean {
        return this.compareTo(anotherInterval) === -1;
    }

    isEqualTo(anotherInterval: TimeInterval): boolean {
        return this.compareTo(anotherInterval) === 0;
    }

    isLessThanOrEqualTo(anotherInterval: TimeInterval): boolean {
        let compareVal = this.compareTo(anotherInterval);
        return compareVal === -1 || compareVal === 0;
    }

    toString(): string {
        return `${this.value} ${this.unit}`;
    }

    validate(){
        Validator.validateNotNull("TimeInterval", this.value);
        Validator.validateNotNull("TimeIntervalUnit", this.unit);
        Validator.validatePositive("TimeInterval", this.value);
    }

}