/** @format */

import { Box, Container } from '@mui/material';
import InflyTabs, { InflyTab } from 'containers/common/InflyTabs';
import React, { Component } from 'react';
import { InflyMessage } from 'containers/common/InflyMessage';
import { AppContext } from 'util/appContext';
import { RouterAndThemeProps } from 'util/commonProps';
import SessionUtil from 'util/SessionUtil';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import DeactivateAccount from './AccountType';
import ChangeCardDetails from './ChangeCardDetails';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePasswordForm';
import UserProfile from './UserProfile';
import DocumentHeader from 'containers/common/DocumentHeader';
import { Message, UserAPIClient, UserType } from '../../../api';

export class SettingController {
    firstName: string;
    lastName: string;
    username: string;
    address: string;
    email: string;
    userType: UserType;
    message?: Message;
    userApiClient: UserAPIClient;
    myreferred: any[];

    constructor(username: string) {
        this.userType = UserType.UNCONFIRMED;
        this.firstName = this.lastName = this.address = this.email = '';
        this.username = username;
        this.userApiClient = AppContext.getInstance().userApiClient;
    }

    init(): Promise<SettingState> {
        return this.fetchProfile();
    }

    async fetchProfile(): Promise<SettingState> {
        try {
            const user = await this.userApiClient.getUserProfile(this.username);
            this.firstName = user.firstName;
            this.userType = UserType[user.userType];
            this.lastName = user.lastName;
            this.address = user.address;
            this.email = user.email;
        } catch (error) {
            this.message = Message.fromError(error);
        }
        return this.getState();
    }

    updateAccount = async (firstName: string, lastName: string, address: string) => {
        const profile = { firstName, lastName, address, username: this.username };
        try {
            const user = await this.userApiClient.updateProfile(profile);
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.address = user.address;
            this.message = Message.success('Portfolio update successfully');
        } catch (error) {
            this.message = Message.fromError(error);
        }
        return this.getState();
    };

    changeEmail = async (email: string) => {
        try {
            this.message = await this.userApiClient.changeEmail(email);
        } catch (error) {
            this.message = Message.fromError(error);
        }
        return this.getState();
    };

    changePassword = async (password: string) => {
        try {
            if (password.length < 0) {
                throw Error('Password length cannot be zero.');
            }
            this.message = await this.userApiClient.changePassword(password);
        } catch (error) {
            this.message = Message.fromError(error);
        }
        return this.getState();
    };
    changeMyPaymentInfo = async (token: string) => {
        try {
            this.message = await this.userApiClient.changeMyPaymentInfo(token);
        } catch (error) {
            this.message = Message.fromError(error);
        }
        return this.getState();
    };

    deactivate = async () => {
        try {
            this.message = await this.userApiClient.deactivate();
        } catch (error) {
            this.message = Message.fromError(error);
        }

        return this.getState();
    };
    updateMessage = (message?: Message): SettingState => {
        this.message = message;
        return this.getState();
    };
    clearMessage = () => {
        this.message = undefined;
        return this.getState();
    };

    getState(): SettingState {
        return {
            userType: this.userType,
            firstName: this.firstName,
            lastName: this.lastName,
            address: this.address,
            email: this.email,
            message: this.message,
        };
    }
}

interface SettingProps extends RouterAndThemeProps {}
enum SettingTabs {
    UserProfile,
    ChangeEmail,
    ChangePassword,
    ChangeAccountTypeAndDeactivate,
    ChangeCardDetails,
}

export interface SettingState {
    firstName: string;
    lastName: string;
    address: string;
    email: string;
    userType: UserType;
    message?: Message;
}

class Setting extends Component<SettingProps, SettingState> {
    userApiClient: UserAPIClient;
    settingController: SettingController;

    constructor(props: SettingProps) {
        super(props);
        this.userApiClient = AppContext.getInstance().userApiClient;
        const userSession = SessionUtil.findSession();
        this.settingController = new SettingController(userSession?.username!);
        this.state = this.settingController.getState();
    }
    componentDidMount() {
        this.settingController.init().then((_state) => this.setState(_state));
    }

    getDefaultTabToSelect = (): SettingTabs => {
        const hash = this.props.location.hash;
        switch (hash) {
            case '#user-profile':
                return SettingTabs.UserProfile;
            case '#change-email':
                return SettingTabs.ChangeEmail;
            case '#change-password':
                return SettingTabs.ChangePassword;
            case '#change-account-type-deactivate':
                return SettingTabs.ChangeAccountTypeAndDeactivate;
            default:
                return SettingTabs.UserProfile;
        }
    };

    updateProfile = (firstName: string, lastName: string, address: string) => {
        return this.settingController
            .updateAccount(firstName, lastName, address)
            .then((_state) => this.setState(_state));
    };

    changeEmail = (newEmail: string) => {
        this.settingController.changeEmail(newEmail).then((_state) => this.setState(_state));
    };

    changePassword = (password: string) => {
        this.settingController.changePassword(password).then(async (_state) => {
            if (!_state.message?.isError()) {
                await this.logOut('Your account changed password has been changed');
            }
            this.setState(_state);
        });
    };

    onSubmitSuccess = (message: Message) => {
        this.setState(this.settingController.updateMessage(message));
    };

    async logOut(message: string) {
        const userAPIClient: UserAPIClient = AppContext.getInstance().userApiClient;
        await userAPIClient.logout();
        SessionUtil.clearSession();
        this.props.navigate(`/?error=${message}`);
    }

    deactivateAccount = (proceed: boolean) => {
        return this.settingController.deactivate().then(async (_state) => {
            if (!_state.message?.isError()) {
                await this.logOut('Your account have been deactivated');
            }
            this.setState(_state);
        });
    };

    onToken = (token: any) => {
        this.settingController
            .changeMyPaymentInfo(token.id)
            .then((_state) => this.setState(_state));
    };

    render() {
        const { firstName, lastName, address, email, userType } = this.state;
        const userProfile = {
            firstName,
            lastName,
            address,
            userType,
        };
        return (
            <DocumentHeader title={'User Setting'}>
                <Box display={'flex'} my={5} justifyContent={'center'} alignItems={'center'}>
                    <Container>
                        <InflyMessage
                            onClose={() =>
                                this.setState((prevState) => this.settingController.clearMessage())
                            }
                            message={this.state.message}
                        />

                        <InflyTabs
                            value={this.getDefaultTabToSelect()}
                            variant='scrollable'
                            allowScrollButtonsMobile
                            scrollButtons='auto'
                        >
                            <InflyTab label='User Profile'>
                                <UserProfile
                                    onSubmit={this.updateProfile}
                                    key={userProfile.firstName}
                                    {...userProfile}
                                />
                            </InflyTab>
                            <InflyTab label='Change Email'>
                                <ChangeEmail onSubmit={this.changeEmail} email={email} />
                            </InflyTab>
                            <InflyTab label='Change Password'>
                                <ChangePassword onSubmit={this.onSubmitSuccess} />
                            </InflyTab>
                            <InflyTab label='Account Type'>
                                <DeactivateAccount
                                    onDeactivateAccount={this.deactivateAccount}
                                    userType={userType}
                                />
                            </InflyTab>
                            <InflyTab label='Payment Card'>
                                <ChangeCardDetails email={email} onToken={this.onToken} />
                            </InflyTab>
                        </InflyTabs>
                    </Container>
                </Box>
            </DocumentHeader>
        );
    }
}

export default withNavBarAndAuth(Setting);
