export class DateTimeUtils {

    // Many funtions like Date.getHours() return hours in the local browser's timezone. Users could be our site in Pacific timezone
    // but have many logic that depends on hours at Eastern timezone

    static EST_ZONE = "America/New_York"
    static PACIFIC_ZONE = "America/Los_Angeles"
    static IST_TIMEZONE= "Asia/Kolkata";

    private static convertTZ(date: Date, tzString: string): Date {
        // if its 7AM PST and 10AM EST, and users in PST pass EST, it prints 10AM
        let localeString = date.toLocaleString("en-US", {timeZone: tzString})
        // then this constructs date interpreting "10AM" string in local(PST), 
        // so that wehn date.getHours() is called, the local hour is "10AM"
        // essentially, it addin timezone offset by converting to string and parsing from it 
        return new Date(localeString);   
    }

    static getEstDateTime(date: Date): number[]{
        let dt = DateTimeUtils.convertTZ(date, DateTimeUtils.EST_ZONE);
        return [dt.getDate(), dt.getHours(), dt.getMinutes()]
    }


    public static toEstString(date?: Date): string {
        if (date === undefined) return 'NA'; // some TDAmeritrade open position did not have date
        return date.toLocaleString('en-US', {timeZone: 'America/New_York'}) + ' EST';
    }



}