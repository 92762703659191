import {StockNews} from '../models/marketdata/stockNews';
import {InvestflyModel} from '../models/InvestflyModel';
import {Bar, BarInterval} from '../models/marketdata/bar';
import {OptionAnalyticRequest} from '../models/marketdata/optionAnalyticRequest';
import {OptionChain} from '../models/marketdata/optionChain';
import {OptionExpiry} from '../models/marketdata/optionExpiry';
import {FunctionType, OptionGreeks} from '../models/marketdata/optionGreeks';
import {FutureQuote, Quote, QuotesRequest, QuotesResult, StockQuote} from '../models/marketdata/quote';
import {Future, Security, SecurityPriceChange, SymbolSummaryInfo} from '../models/marketdata/security';
import {RestAPIClient} from './restAPIClient';
import { DataParam, DataParamsRequest } from 'api/models/trigger/DataParam';
import { FinancialField } from 'api/models/trigger/FinancialField';


export class MarketDataAPIClient {

    private restApiClient: RestAPIClient;

    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }

    async getQuotes(req: QuotesRequest): Promise<QuotesResult> {
        let reqObj = JSON.parse(JSON.stringify(req, QuotesRequest.stringify));
        const res = await this.restApiClient.post("/marketdata/quotes", reqObj);
        return QuotesResult.parseJSON(res);
    }

    async getQuote(security: Security): Promise<Quote> {
        security.symbol =  security.symbol.replaceAll('^', '%5E');
        const res = await this.restApiClient.post('/marketdata/quote', security);
        return Quote.parseJSON(res);
    }

    async getStockQuote(symbol: string): Promise<StockQuote> {
        symbol = symbol.replaceAll('^', '%5E');
        const res = await this.restApiClient.get(`/marketdata/stockquote?symbol=${symbol}`);
        return StockQuote.parseJSON(res);
    }

    async getFutureQuote(future: Future): Promise<FutureQuote> {
        const res = await this.restApiClient.post(`/marketdata/future/quote`, future);
        return FutureQuote.parseJSON(res);
    }

    async getFutureQuotes(futures: Future[]): Promise<Map<Future, FutureQuote>> {
        const res = await this.restApiClient.post(`/marketdata/future/quotes`, futures);

        const quotes = new Map<Future, FutureQuote>();
        res.map((key: any) => {
            const future = Future.parseJSON(key);
            const quote = FutureQuote.parseJSON(res[key]);
            quotes.set(future, quote);
        });

        return quotes;
    }

    async getGainers(): Promise<SecurityPriceChange[]> {
        const res = await this.restApiClient.get('/marketdata/gainers');
        return InvestflyModel.parseList(res, SecurityPriceChange.parseJSON);
    }

    async getLosers(): Promise<SecurityPriceChange[]> {
        const res = await this.restApiClient.get('/marketdata/losers');
        return InvestflyModel.parseList(res, SecurityPriceChange.parseJSON);
    }

    async getFinancials(ticker: string): Promise<Map<FinancialField, number>> {
        ticker = ticker.replaceAll('^', '%5E');
        const res = await this.restApiClient.get(`/symbol/financial/${ticker}`)
        let resultMap: Map<FinancialField, number> = new Map();
        for(let key in res){
            resultMap.set(FinancialField[key], res[key]);
        }
        return resultMap;
    }

    async getDataParams(dataParamsRequest: DataParamsRequest): Promise<Map<string, Map<string, number>>> {
        const res = await this.restApiClient.post(`/marketdata/compute`, dataParamsRequest);
        let resultMap: Map<string, Map<string, number>> = new Map();
        for(let symbol in res){
            let symbolValues = res[symbol];
            let symbolsMap: Map<string, number> = new Map();
            resultMap.set(symbol, symbolsMap);
            for(let alias in symbolValues){
                symbolsMap.set(alias, symbolValues[alias]);
            }
        }
        return resultMap;
    }

    async getHistoricalBars(ticker: string, barInterval: BarInterval, count?: number): Promise<Bar[]> {
        ticker = ticker.replaceAll('^', '%5E');
        let url = `/marketdata/bars?symbol=${ticker}&barinterval=${barInterval}`;
        if(count !== undefined){
            url = `${url}&count=${count}`;
        }
        const res: object[] = await this.restApiClient.get(url);
        return InvestflyModel.parseList(res, Bar.parseJSON);
    }

    async getOptionExpiryDates(ticker: string): Promise<OptionExpiry[]> {
        ticker = ticker.replaceAll('^', '%5E');
        const res: object[] = await this.restApiClient.get(`/marketdata/optionexpires?symbol=${ticker}`);
        return InvestflyModel.parseList(res, OptionExpiry.parseJSON);
    }

    async getOptionChain(symbol: string, optionExpiry: OptionExpiry): Promise<OptionChain> {
        symbol = symbol.replaceAll('^', '%5E');
        const month: number = optionExpiry.month;
        const day: number = optionExpiry.day;
        const year: number = optionExpiry.year;

        const res = await this.restApiClient.get(`/marketdata/optionchains?symbol=${symbol}&month=${month}&day=${day}&year=${year}`);
        return OptionChain.parseJSON(res)
    }

    async computeOptionGreeks(inputRequest: OptionAnalyticRequest): Promise<OptionGreeks> {
        const res = await this.restApiClient.post(`/marketdata/optionanalytic/greeks`, inputRequest);
        return OptionGreeks.parseJSON(res)
    }

    async computeGreeksGraph(inputRequest: OptionAnalyticRequest, functionType: FunctionType): Promise<Map<string, number[]>> {
        const res: any = await this.restApiClient.post(`/marketdata/optionanalytic/graphs?functionOf=`+functionType, inputRequest);

        const result: Map<string, number[]> = new Map<string, number[]>();
        Object.keys(res).map((key: string) => {
            const value = <number[]> res[key].map((v:any) => Number(v));
            result.set(key, value);
        });

        return result;
    }

    async computePayoff(inputRequest: OptionAnalyticRequest): Promise<number[]> {
        const res: object[] = await this.restApiClient.post(`/marketdata/optionanalytic/payoff`, inputRequest);
        return res.map(r => Number(r));
    }

    async getAvailableFutures(productCode: string): Promise<FutureQuote[]> {
        const res: object[] = await this.restApiClient.get(`/marketdata/future/${productCode}/quotes`);
        return InvestflyModel.parseList(res, FutureQuote.parseJSON);
    }

    async getAllSymbols(): Promise<SymbolSummaryInfo[]> {
        const res = await this.restApiClient.get("/symbol/list");
        return InvestflyModel.parseList(res, SymbolSummaryInfo.parseJSON);
    }

    async getSecuritySummary(symbol: string): Promise<SymbolSummaryInfo> {
        symbol = symbol.replaceAll('^', '%5E');
        const res = await this.restApiClient.get(`/symbol/summary/${symbol}`);
        return SymbolSummaryInfo.parseJSON(res);
    }

    async listIndustries(): Promise<string[]>{
        const res = await this.restApiClient.get("/symbol/industry");
        return res as string[];  
    }

    async getStockNews(symbolsCSV: string): Promise<StockNews[]> {
        symbolsCSV = symbolsCSV.replaceAll('^', '%5E');
        const res: object[] = await this.restApiClient.get("/symbol/news?symbols="+symbolsCSV);
        return InvestflyModel.parseList(res, StockNews.parseJSON);
    }

}