/** @format */

import { Box, FilterOptionsState, TextField, Typography } from '@mui/material';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import { SecurityType, SymbolSummaryInfo } from 'api';
import React from 'react';
import { AppContext } from '../../util/appContext';

interface SymbolFieldProps {
    selectedSymbol?: SymbolSummaryInfo; // when we want to set value by default like Trade from Symbol Page
    onChange: (symbol: SymbolSummaryInfo) => void;
    onClear: () => void;
    includeIndex: boolean;
    disabled?: boolean;
    label?: string;
}

interface SymbolFieldState {
    tickerSymbols: SymbolSummaryInfo[];
    ready: boolean;
    // inputValue is not required for autocomplete to work
}

export class SymbolField extends React.Component<SymbolFieldProps, SymbolFieldState> {
    constructor(props: SymbolFieldProps) {
        super(props);
        this.state = {
            tickerSymbols: undefined!,
            ready: false,
        };
    }

    async componentDidMount() {
        const appContext = AppContext.getInstance();
        let tickerSymbols = appContext.getGlobalState('SymbolField.AllSymbols');
        if (tickerSymbols === undefined) {
            tickerSymbols = await appContext.marketDataApiClient.getAllSymbols();
        }
        this.setState({
            tickerSymbols: tickerSymbols,
            ready: true,
        });
    }

    render() {
        if (!this.state.ready) return <>Loading...</>;
        // When user enters a charater, it calls (1) onInputChange, (2) Render, (3) filterOptions
        // On change is called, when user selects on of the value
        return (
            <Autocomplete
                fullWidth
                id='symbol-select-component'
                autoHighlight
                size={'small'}
                disabled={this.props.disabled}
                // Options
                options={this.state.tickerSymbols}
                groupBy={(option) => option.symbol[0]}
                getOptionLabel={(option) => option.symbol}
                noOptionsText={'No options available'}
                renderOption={(props, option) => (
                    <Box
                        component='li'
                        {...props}
                        width={'100%'}
                        display={'flex'}
                        justifyContent={'space-between'}
                        gap={1}
                    >
                        <Typography width={'40%'} gutterBottom>
                            {' '}
                            {option.symbol}
                        </Typography>
                        <Typography width={'40%'} gutterBottom variant={'caption'}>
                            {option.name}
                        </Typography>
                        <Typography width={'40%'} gutterBottom variant={'caption'} align={'right'}>
                            {option.type}
                        </Typography>
                    </Box>
                )}
                filterOptions={(
                    tickerSymbols: SymbolSummaryInfo[],
                    state: FilterOptionsState<SymbolSummaryInfo>
                ) => {
                    // change to upper case since all symbols and names in the options are already uppercase
                    let inputValue: string = state.inputValue.toUpperCase();

                    let filteredOptions = tickerSymbols.filter((tickerSymbol) => {
                        return this.props.includeIndex || tickerSymbol.type != SecurityType.INDEX;
                    });

                    if (inputValue.length == 0) {
                        // no need to match characters if input text is empty
                        // Also returning all would require all options to be rendered which causes the component to freeze for few secs
                        // so return the first 100
                        return filteredOptions.slice(0, 100);
                    }

                    filteredOptions = filteredOptions.filter((tickerSymbol) => {
                        return (
                            tickerSymbol.name.startsWith(inputValue) ||
                            tickerSymbol.symbol.startsWith(inputValue)
                        );
                    });

                    return filteredOptions;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={this.props.label ?? 'Choose a symbol'}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
                value={this.props.selectedSymbol}
                isOptionEqualToValue={(option, value) => option.symbol == value.symbol}
                onChange={(
                    event: React.SyntheticEvent,
                    value: SymbolSummaryInfo | null | string,
                    reason: AutocompleteChangeReason
                ) => {
                    if ('selectOption' === reason) {
                        let tickerSymbol = value as SymbolSummaryInfo;
                        this.props.onChange(tickerSymbol);
                    }

                    if ('clear' === reason) {
                        this.props.onClear();
                    }
                }}
            />
        );
    }
}
