import { DateTimeUtils, UserError } from "api";
import { Bar, BarInterval } from "api/models/marketdata/bar";
import { RangeSelectorOptions, YAxisOptions } from "highcharts";
import { ChartIndicator } from "./ChartIndicator";
import { DataParam } from "api/models/trigger/DataParam";
import { AppContext } from "util/appContext";


export enum ChartType {
    LINE = 'line',
    CANDLESTICK = 'candlestick',
    HEIKEN_ASHI = 'heikinashi'

}

export enum ZoomSettings {
    ONE_DAY = '1D',
    FIVE_DAYS = '5D',
    ONE_MONTH = '1M',
    SIX_MONTHS = '6M',
    ONE_YEAR = '1Y',
    FIVE_YEARS = '5Y'
}

export class ChartState {

    symbol: string; // depends on props, which also means that state must be discarded when prop changes
    chartType: ChartType
    zoomSettings: ZoomSettings

    chartOptions: Highcharts.Options;

    // we store bars to users can toggle between line and candlestick without reloading bars again
    bars: Bar[];

    // since the compare mode only works with line chart, we don;t have to store bars, but just the closing prices
    compareSymbols: Map<string, number[][]>;
    
    constructor(symbol: string){
        this.symbol = symbol;
        this.compareSymbols = new Map();
    }


    static createDefault(symbol: string,  zoomSettings?: ZoomSettings, chartType?: ChartType): ChartState {
        
        let chartState = new ChartState(symbol);
        let symbolName = chartState.getSymbolDisplayName(symbol);

        chartState.chartType = chartType ?? ChartType.LINE
        chartState.zoomSettings = zoomSettings ?? ZoomSettings.ONE_DAY;

        chartState.chartOptions = ChartState.createDefaultChartOptions(symbolName);
        return chartState;
    }

    static createDefaultChartOptions(symbolName: string){
        return {
            credits: {enabled: false},
            accessibility: {enabled: false},
            chart: {
                height: (9 / 16 * 100) + '%' // 16:9 ratio,
            },
            title: {
                text: symbolName ,
                style: {
                    fontSize:'12px'
                }
            },
            legend: {
                enabled: true,
            },
            navigator: { // this is the lower mini chart that allows to zoom - https://www.highcharts.com/docs/stock/navigator
                enabled: false
            },
            rangeSelector: {
                buttons: []
            }

        }
    }

    static zoomSettingsToBarInterval(zoom: ZoomSettings): BarInterval {
        // note we can go from zoom settings to BarInterval but not the other way around.
        // so we must track the state as zoom settings
        if (zoom == ZoomSettings.ONE_DAY){
            return BarInterval.ONE_MINUTE;
        }else if (zoom == ZoomSettings.FIVE_DAYS) {
            return BarInterval.FIFTEEN_MINUTE;
        }else if (zoom == ZoomSettings.ONE_MONTH){
            return BarInterval.SIXTY_MINUTE;
        }
        else {
            return BarInterval.ONE_DAY;
        }
    }

    static zoomSettingsToCount(zoom: ZoomSettings): number {
        if (zoom == ZoomSettings.ONE_DAY){
            return 400; // 1-min bars
        }else if (zoom == ZoomSettings.FIVE_DAYS){
            return 200; // 15-min bars, (400 / 15) * 5
        }else if (zoom == ZoomSettings.ONE_MONTH){
            return 150; // 1-hour bars, 6 hours per day * 20 trading days per month 
        }else if (zoom == ZoomSettings.SIX_MONTHS){
            return 150; // daily bars, 
        }else if(zoom == ZoomSettings.ONE_YEAR){
            return 300;
        }else { // 5 years
            return 1500;
        }
    }

    clone() {
        let clone = new ChartState(this.symbol);
        Object.assign(clone, this);

        clone.chartOptions = JSON.parse(JSON.stringify(this.chartOptions));
        if(this.bars){
            clone.bars = [...this.bars]
        }
        return clone;
    }

    updateSymbol(newSymbol: string): ChartState{
        this.symbol = newSymbol;
        this.chartOptions = ChartState.createDefaultChartOptions(this.getSymbolDisplayName(newSymbol));
        return this.clone();
    }

    updateChartType(newType: ChartType): ChartState{
        this.chartType = newType;
        return this.updateChartOptionsToDefault();
    }

    async updateZoomSetttings(newZoom: ZoomSettings){
        this.zoomSettings = newZoom;
        this.bars = await this.loadBars(this.symbol);

        for (let symbol of this.compareSymbols.keys()){
            let newBars: Bar[] = await this.loadBars(symbol);
            const compareSeriesData: number[][] = newBars.map(b => [new Date(b.date).getTime(), b.close]);
            this.compareSymbols.set(symbol, compareSeriesData);
        }

        if(this.isCompareMode){
            this.updateChartOptionsToCompareMode();
        }else{
            this.updateChartOptionsToDefault();
        }

        return this.clone();
    }


    async loadBarsAndUpdateChartSeries(): Promise<ChartState>{    
        this.bars = await this.loadBars(this.symbol);
        return this.updateChartOptionsToDefault();
    }

    private async loadBars(symbol: string): Promise<Bar[]>{
        const marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        let barInterval = ChartState.zoomSettingsToBarInterval(this.zoomSettings);
        let count = ChartState.zoomSettingsToCount(this.zoomSettings);

        let bars = await marketDataApiClient.getHistoricalBars(symbol, barInterval, count);
        
        if(barInterval == BarInterval.ONE_MINUTE){
            bars = this.trimOneMinuteData(bars);
        }
        return bars
    }

    trimOneMinuteData(bars: Bar[]): Bar[]{
        let index = bars.length-1;
        let lastBar = bars[index];
        let [lastDay, lastHour, lastMin] = DateTimeUtils.getEstDateTime(lastBar.date); // day,hour,min

        // to to market open time
        while(index > 0){
            let bar = bars[index]
            let [day, hour, min] = DateTimeUtils.getEstDateTime(bar.date);
            if ((hour < 9) || (day != lastDay) || (hour == 9 && min < 30)){
                break;
            } 
            index--; 
        }
        
        bars = bars.slice(index+1);
        while (lastHour < 16){
            let dt = new Date(lastBar.date.getTime() + 60000); // add one minute
            lastBar = new Bar()
            lastBar.symbol = this.symbol
            lastBar.date = dt
            bars.push(lastBar)
            lastHour = DateTimeUtils.getEstDateTime(lastBar.date)[1]
        }
        return bars;
    }


    async addCompareSymbol(symbol: string): Promise<ChartState>{
        this.chartType = ChartType.LINE; // compare mode only makes sense in line chart
        
        let newBars: Bar[] = await this.loadBars(symbol);
        const compareSeriesData: number[][] = newBars.map(b => [new Date(b.date).getTime(), b.close]);
        this.compareSymbols.set(symbol, compareSeriesData);
        
        return this.updateChartOptionsToCompareMode();

    }


    removeCompareSymbol(symbol: string): ChartState{
        this.compareSymbols.delete(symbol);
        
        if(this.compareSymbols.size == 0){
            this.updateChartOptionsToDefault();
        }else{
            let seriesIndex = this.chartOptions.series!.findIndex(ser => ser.id == this.getSymbolDisplayName(symbol));
            this.chartOptions.series!.splice(seriesIndex,1);
        }
        return this.clone();
       
    }


    updateChartOptionsToDefault(): ChartState{    
        // This resets the chart to normal mode
        let chartSerieData:  number[][] = this.chartType === ChartType.LINE ?  
                                this.bars.map(b => [new Date(b.date).getTime(), b.close]) :
                                this.bars.map(b => [new Date(b.date).getTime(), b.open, b.high, b.low, b.close]);

        let volumeSeries = this.bars.map(b => [new Date(b.date).getTime(), b.volume]);


        this.chartOptions.plotOptions = {
            series: {
                showInLegend: true,
                dataGrouping: {
                    enabled: false
                }
            }
        }; 

        this.chartOptions.yAxis = [
            {
                height: '80%',  
                resize: {
                    enabled: true
                }       
            },
            {
                top: '80%',
                height: '20%',
            }
        ];
        this.chartOptions.series = [
            {
                id: 'main-series',
                name: this.getSymbolDisplayName(this.symbol),
                type: this.chartType,
                data: chartSerieData,
            },
            {
                id: 'volume-series',
                name: 'Volume',
                type: 'column',
                data: volumeSeries,
                yAxis: 1
            }
        ]         
        return this.clone()
    }


    private updateChartOptionsToCompareMode(): ChartState{
        const mainSeriesData: number[][] = this.bars.map(b => [new Date(b.date).getTime(), b.close]);

        this.chartOptions.plotOptions = {
            series: {
                compare: 'percent',
                dataGrouping: {
                    enabled: false
                }
            }
        }
        this.chartOptions.yAxis = {
            height: '100%'
        }
       
        this.chartOptions.series = [
            {
                id: 'main-series',
                name: this.getSymbolDisplayName(this.symbol),
                type: this.chartType,
                data: mainSeriesData,
            },
        ];
        
        for(let [s, compareSeries] of this.compareSymbols){     
            this.chartOptions.series.push({
                id: this.getSymbolDisplayName(s),
                name: this.getSymbolDisplayName(s),
                type: 'line',
                data: compareSeries
            })
        }
        return this.clone();
    }

  

    get isCompareMode(): boolean {
        return this.compareSymbols.size > 0;
    }


    private getSymbolDisplayName(symbol: string){
        if(symbol == "^GSPC"){
            return "SP500";
        }else if(symbol == "^DJI"){
            return "DOW";
        }else if (symbol == "^IXIC"){
            return "NASDAQ";
        }else if (symbol == "^RUT"){
            return "RUSSEL2000";
        }else{
            return symbol;
        }
    }

 


    // -----------------------

    

    


    addUpdateIndicators(indicators: DataParam[], chartType: ChartType): Highcharts.Options {
        
        if(indicators.length == 0){
            //return this.addUpdatePriceVolumeSeries(this.bars, chartType);

        }else{

            // When this method is called to add a new indicator, there may already be existing indicator series that are already plotted
            // we don't follow incremental update approach to append the new series and y-axis. Instead, we just delete all series/axis and recreate

            // without this, the indicator legend did not appear
            this.chartOptions.plotOptions = {
                series: {
                    showInLegend: true
                }
            };

            // delete all prior series expect price and volume
            if(this.chartOptions.series!.length > 2){
                this.chartOptions.series!.splice(2,this.chartOptions.series!.length-2);
            }

            // delete all y-axis 
            this.chartOptions.yAxis = [
                {
                    height: 300,         
                },
                {
                    top: 400,
                    height: 100
                }
            ];

            let axisCount = 0;
            for(let metric of indicators){

                let chartSeries = ChartIndicator.getChartSeries(metric);

                if(ChartIndicator.needsNewAxis(metric)){
                    let allYAxis = this.chartOptions.yAxis as YAxisOptions[];
                    allYAxis.push({
                        top: 500 + axisCount*100,
                        height: 100
                    })
                    axisCount++;

                    chartSeries.yAxis = 2 + axisCount - 1; // -1 becuase it's 0 indexed, yAxis = 2 means 3rd axis
                }
                
                this.chartOptions.series!.push(chartSeries);
            }

            this.chartOptions.chart!.height = 600 + axisCount*100;

        }

        return JSON.parse(JSON.stringify(this.chartOptions));
    }
    


}