import { BarInterval } from "../marketdata/bar";
import { QuoteField } from "../marketdata/quote";
import { FinancialField } from "./FinancialField";

export enum DataType {
    QUOTE = "QUOTE",
	FINANCIAL = "FINANCIAL",
	BARS = "BARS",
	INDICATOR = "INDICATOR",
	CONST = "CONST"
}


export class DataParam extends Map<string, any> {

	// used for UI only
	static ALIAS: string = "alias";

    static DATATYPE: string = "datatype";
	static FIELD: string  = "field";
	static SECURITY: string = "security";
	static COUNT: string = "count";
	static LOOKBACK: string = "lookback";
	static VALUE: string = "value"; // for const
	static UNIT: string = "unit";
	static BARINTERVAL: string = "barinterval";
	static PERIOD: string = "period";
	static INDICATOR: string = "indicator";

	static CONST:string = "CONST";
    static B:number = 1000000000;
    static M:number = 1000000;
    static K: number = 1000;
    static P: number = 0.01;


	static parseJSON(obj: {[index:string]: any} ): DataParam{ 
		let dataParam = new DataParam()
		for(let paramAttribute in obj){
			// in javascript, during runtime, Enums are string anyway, so this is probably not needed
			let value = obj[paramAttribute]
			if(paramAttribute === DataParam.DATATYPE){
				dataParam.set(paramAttribute, DataType[value])
			}else if (paramAttribute === DataParam.BARINTERVAL){
				dataParam.set(paramAttribute, BarInterval[value])
			}else{
				dataParam.set(paramAttribute, value)
			}
			
		}
		return dataParam
	}

	static newIndicatorParam(): DataParam {
		let dataParam = new DataParam()
		dataParam.set(DataParam.DATATYPE, DataType.INDICATOR)
		return dataParam;
	}

	static newIndicatorIdParam(indicatorId: string): DataParam {
		let dataParam = new DataParam()
		dataParam.set(DataParam.DATATYPE, DataType.INDICATOR)
		dataParam.set(DataParam.INDICATOR, indicatorId);
		return dataParam;
	}

	static newQuoteParam(quoteField: QuoteField): DataParam {
		let dataParam = new DataParam();
		dataParam.set(DataParam.DATATYPE, DataType.QUOTE);
		dataParam.set(DataParam.FIELD, quoteField);
		dataParam.set(DataParam.ALIAS, quoteField); // all data params need alias
		return dataParam;
	}

	static newFinancialParam(financialField: FinancialField): DataParam {
		let dataParam = new DataParam();
		dataParam.set(DataParam.DATATYPE, DataType.FINANCIAL);
		dataParam.set(DataParam.FIELD, financialField);
		dataParam.set(DataParam.ALIAS, financialField);// all data params need alias
		return dataParam;
	}

	static newConstDataParam(value: number, unit?: string): DataParam {
        let dataParam = new DataParam();
		dataParam.set(DataParam.DATATYPE, DataType.CONST);
        dataParam.set(DataParam.VALUE, value);
		if(unit){
			dataParam.set(DataParam.UNIT, unit);
		}
		let alias =  value.toString();
		if(unit !== undefined){
			let unitLabel = unit;
			alias = alias + unitLabel;
		}
		dataParam.set(DataParam.ALIAS, alias);
        return dataParam;
    }

	static createNumber(value: number, unit?: string): number {
        let multiplier: number = 1;
        if(unit === "B") multiplier = DataParam.B;
        if(unit === "M") multiplier = DataParam.M;
        if (unit === 'K') multiplier = DataParam.K;
        if (unit === '%') multiplier = DataParam.P;
		return value * multiplier;
	}

	static createStringValue(value: number, unit?: string): string {
		let result = value.toString()
		if(unit){
			result = result+unit;
		}
		return result;
	}


    createSetAlias(): string {

        // If user specifies alias "sma5" and maps that to metric in Screener or ExpressionTrigger, then we can't force-set to a diffrent one
        // Hence, alias can be frozen in that sense , so we dont automatically create alias on each parameter update. We have to manually call createAlias


        let alias: string = this.get(DataParam.INDICATOR) as string ?? "";
        if (this.size > 0) {

            let paramNames = [...this.keys()];
            paramNames.sort();
			if(paramNames.includes("alias")){
				paramNames.splice(paramNames.indexOf("alias"), 1);
			}
			if(paramNames.includes("datatype")){
				paramNames.splice(paramNames.indexOf("datatype"), 1);
			}
			if(paramNames.includes("indicator")){
				paramNames.splice(paramNames.indexOf("indicator"), 1);
			}


            if(this.has("barinterval")){
                const barInterval = this.get("barinterval");             
                alias = alias + "_" + this.barIntervalShort(barInterval);
                paramNames.splice(paramNames.indexOf("barinterval"), 1); 
            }


            // handle common parameters first
            if (this.has('period')) {
                const period = this.get("period");
                if(period !== null){
                    alias = alias + "_" + period;
                }
                paramNames.splice(paramNames.indexOf("period"), 1);          
            }




			for(let param of paramNames){
				if(param !== DataParam.LOOKBACK && param !== DataParam.SECURITY){
					const paramVal = this.get(param);
					alias = alias + "_" + paramVal;
				}
			}


			if(this.has(DataParam.LOOKBACK)){
                const lookback = this.get("lookback");
                if(lookback !== null && lookback !== 0){
                    alias = alias + "_" + lookback;
                }
            }



			if(this.has(DataParam.SECURITY)){
                const security = this.get(DataParam.SECURITY);
                if(security !== null ){
                    alias = alias + "_" + security;
                }
            }

        }
        alias = alias.replaceAll('^', '');
		this.set(DataParam.ALIAS, alias)
        return alias
    }

	setParam(key: string, value: any) {
		this.set(key, value);
		this.createSetAlias()
	}

	deleteParam(key: string){
		this.delete(key);
		this.createSetAlias();
	}

	getOrCreateAlias(): string{
		if(this.has(DataParam.ALIAS)){
			return this.get(DataParam.ALIAS);
		}
		return this.createSetAlias();
	}

	private barIntervalShort(barInterval: BarInterval): string{
		if (barInterval == BarInterval.ONE_DAY){
			return "1D";
		}else if(barInterval == BarInterval.ONE_MINUTE){
			return "1MIN";
		}else if(barInterval == BarInterval.FIVE_MINUTE){
			return "5MIN";
		}else if(barInterval == BarInterval.FIFTEEN_MINUTE){
			return "15MIN";
		}else if(barInterval == BarInterval.THIRTY_MINUTE){
			return "30MIN";
		}else if(barInterval == BarInterval.SIXTY_MINUTE){
			return "60MIN";
		}else{
			return barInterval;
		}
	}

	clone(): DataParam {
		return new DataParam(this);
	}

    

}

export class DataParamsRequest {
	symbols: string[]
	dataParams: Map<string, DataParam>

	constructor(symbols: string[], dataParams: Map<string, DataParam>){
		this.symbols = symbols;
		this.dataParams = dataParams;
	}
}