import React, {ReactElement} from 'react';
import SessionUtil from 'util/SessionUtil';
import {UserRole} from 'api';

interface HideElementProps {
    authorizedUsername?: string;
    authorizedRole?: UserRole;
    children?: ReactElement,
    message?:string,
}

function AuthorizedElement(props:HideElementProps) {
    const userInfo = SessionUtil.findUserInfo()
    const username = userInfo?.username ?? "";
    const userRole = userInfo?.userRole ?? "";

    return (
        <>
            {(username === props.authorizedUsername as string || userRole.toString() === props.authorizedRole?.toString() as string) && props.children}
            {username !== props.authorizedUsername && <>{props?.message ?? ""}</>}
        </>

    )
}
export default AuthorizedElement