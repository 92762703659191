import { Stack } from "@mui/material";
import { TimeInterval, TimeUnit } from "api"
import { ITextField } from "containers/common/ITextField";
import { SelectField, SelectOption, selectOptionsFromEnum } from "containers/common/SelectField";
import React, { useEffect, useState } from "react";

export type TimeIntervalCompProps = {
    label?: string;
    timeInterval?: TimeInterval;
    onChange: (timeInterval: TimeInterval|undefined) => void; // pass undefined to indicate user "unset" the time interval
}

export function TimeIntervalComp({label, timeInterval, onChange}: TimeIntervalCompProps): React.JSX.Element {

    // capture state only becuase we don't want to call up with incomplete TimeInterval
    const [editInterval, setEditInterval] = useState(timeInterval?? new TimeInterval());

    useEffect(() => {
        setEditInterval(timeInterval?? new TimeInterval());
    }, [timeInterval])

    function onIntervalValueChange(value: number){
        if(value == undefined){
            onChange(undefined);
        }else{
            editInterval.value = value;
            setEditInterval(editInterval.clone());
            validateAndPropagate(editInterval);
        }

    }

    function onIntervalSelectChange(selectOption?: SelectOption){
        if (selectOption === undefined){
            // user "unset" interval unit
            onChange(undefined);
        }else{
            editInterval.unit = selectOption.value;
            setEditInterval(editInterval.clone());
            validateAndPropagate(editInterval);
        }
    }

    function validateAndPropagate(editInterval: TimeInterval){
     
        if(editInterval.value !== undefined && editInterval.unit !== undefined){
            onChange(editInterval);
        }
    }


    return <Stack direction={"row"} gap={2}>
        <ITextField label={label ?? 'Interval'} value={editInterval.value} onChange={onIntervalValueChange} />
        <SelectField allowUnset={true} label="Unit" value={editInterval.unit} options={selectOptionsFromEnum(TimeUnit)} onSelectChange={onIntervalSelectChange} />
    </Stack>
}