import { InvestflyUtil } from "../../utils/investflyUtil";
import { Security } from "../marketdata/security";
import { SecurityTriggerExpression } from "../trigger/SecurityTriggerExpression";
import { SecurityUniverseSelector } from "../trigger/SecurityUniverseSelector";


export enum TriggerStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export class StockAlertSpec {
    username: string;
    alertName: string;   
    triggerScope: SecurityUniverseSelector;
    trigger: SecurityTriggerExpression;



    public static createDefault(): StockAlertSpec{
        let obj = new StockAlertSpec();
        obj.triggerScope = SecurityUniverseSelector.createDefault();
        obj.trigger = SecurityTriggerExpression.createDefault();
        return obj;
    }

    static singleSecurity(security: Security): StockAlertSpec {
        let obj = new StockAlertSpec();
        obj.triggerScope = SecurityUniverseSelector.singleSecurrity(security);
        obj.trigger = SecurityTriggerExpression.createDefault();
        return obj;
    }

    public static  parseJSON(obj: {[index:string]: any} ): StockAlertSpec {
        let alertSpec = new StockAlertSpec();
        Object.assign(alertSpec, obj);
        alertSpec.triggerScope = SecurityUniverseSelector.parseJSON(obj.triggerScope);
        alertSpec.trigger = SecurityTriggerExpression.parseJSON(obj.trigger);
        return alertSpec;
    }

    public toJSONString(): string {
        return JSON.stringify(this);
    }

    validate(): string[] {
        let errors: string[] = [];
        if(this.triggerScope === undefined){
            errors.push("Security Universe cannot be empty");
        }else{
            //TODO this.triggerScope.validate();
        }

        if(this.trigger === undefined){
            errors.push("Trigger Condition cannot be empty");
        }
        return errors;
    }

    clone(): StockAlertSpec {
        const obj = new StockAlertSpec();
        Object.assign(obj, this);
        obj.triggerScope = this.triggerScope.clone();
        obj.trigger = this.trigger.clone();
        return obj;
    }
}


export class StockAlert extends StockAlertSpec {
    alertId: number;
    scheduledDate: Date;
    status: TriggerStatus; 

    public static  parseJSON(obj: {[index:string]: any} ): StockAlert {
        let stockAlert: StockAlert = new StockAlert();
        let stockAlertSpec: StockAlertSpec = StockAlertSpec.parseJSON(obj); 
        Object.assign(stockAlert, stockAlertSpec);
        stockAlert.scheduledDate = InvestflyUtil.strToDate(obj.scheduledDate)!;
        return stockAlert;
        
    }

    clone(): StockAlert {
        let spec: StockAlertSpec = super.clone();
        let stockAlert: StockAlert = new StockAlert();
        Object.assign(stockAlert, spec);
        stockAlert.scheduledDate = this.scheduledDate;
        return stockAlert;
    }
}

export class TriggeredStock {
    alertId: number;
    symbol: string;
    date: Date;

    public static  parseJSON(obj: {[index:string]: any} ): TriggeredStock {
        let triggeredStock = new TriggeredStock();
        Object.assign(triggeredStock, obj);
        triggeredStock.date = InvestflyUtil.strToDate(obj.date)!;
        return triggeredStock;
    }

}