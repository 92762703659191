import {UserError} from '../common/userError';
import { BarInterval } from '../marketdata/bar';


export class IndicatorSpec {

    indicatorId: string;
    name: string;
    description?: string;

    params: Map<string, IndicatorParamSpec> = new Map<string, IndicatorParamSpec>();
    valueType: IndicatorValueType;


    constructor(){
        this.params = new Map();
    }

    static parseJSON(obj: {[index:string]: any} ): IndicatorSpec {

        let metricDef = new IndicatorSpec();
        metricDef.indicatorId = obj.indicatorId;
        metricDef.name = obj.name;
        metricDef.description = obj.description;
        metricDef.valueType = (<any>IndicatorValueType)[obj.valueType];
        for (const pname in obj.params){
            metricDef.params.set(pname,  IndicatorParamSpec.parseJSON(obj.params[pname]));
        }
        return metricDef;
    }

    addParam(name: string, paramSpec: IndicatorParamSpec): void {
        this.params.set(name, paramSpec);
    }

    static toMetricType(metricType: string): MetricType {
        return (<any>MetricType)[metricType];
    }

    clone(): IndicatorSpec {
        let clone = new IndicatorSpec();
        clone.name = this.name;
        clone.description = this.description;
        clone.valueType = this.valueType;
        clone.params = new Map<string,IndicatorParamSpec>();
        for(let [paramName, paramSpec] of this.params.entries()){
            clone.params.set(paramName, paramSpec.clone());
        }
        return clone;
    }
}

export class IndicatorParamSpec {
    paramType: ParamType;
    required: boolean;
    defaultValue?: any;
    options?: any[];

    static parseJSON(obj: {[index:string]: any} ): IndicatorParamSpec {
        let paramSpec = new IndicatorParamSpec();
        Object.assign(paramSpec, obj);
        paramSpec.paramType = (<any>ParamType)[obj.paramType];
        return paramSpec;
    }

    clone(): IndicatorParamSpec {
        let paramSpec = new IndicatorParamSpec();
        Object.assign(paramSpec, this);
        if(this.options){
            paramSpec.options = [...this.options]
        }
        return paramSpec;
    }

}


export enum ParamType {
    DOUBLE = "DOUBLE",
    INTEGER = "INTEGER", 
    BARINTERVAL = "BARINTERVAL", 
    STRING = "STRING", 
    BOOLEAN = "BOOLEAN"
}

export enum MetricType{
    QUOTE = "QUOTE", 
    TECHNICAL = "TECHNICAL", 
    FUNDAMENTAL = "FUNDAMENTAL",
    NUMBER = "NUMBER"
}


export enum IndicatorValueType {
    PRICE = "PRICE", // Quote, SMA
    PERCENT = "PERCENT", // Earning Surprise, Return on Asset, Price change from yesterday. They are stored as decimal, but UI must multiply by 100
    NUMBER = "NUMBER", // Volume. Ratios are represented as Number because Ratios like P/E or ENTERPRISE_MULTIPLE are reported just as plain number like 18.2 instead of as percent
    BOOLEAN = "BOOLEAN", // Chart Pattern
    RATIO = "RATIO",
}

