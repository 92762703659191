import {InvestflyModel} from "../InvestflyModel";


export class ProfessorClass extends InvestflyModel {
    name: string;
    creator: string;
    createdDate: Date;

    public static  parseJSON(obj: {[index:string]: any} ):ProfessorClass {
        let professorClass: ProfessorClass = new ProfessorClass();
        Object.assign(professorClass, obj);
        return professorClass;
    }
}
