/** @format */

import { Button, ButtonBase, IconButton, MenuItem, Paper, TextField } from '@mui/material';
import InflyTabs, { InflyTab } from 'containers/common/InflyTabs';
import InflyDialog from 'containers/common/InflyDialog';
import {
    Message,
    OPERATION,
    OrderStatus,
    OrderType,
    PendingOrder,
    PortfolioAPIClient,
    PortfolioIdentifier,
    Security,
    SecurityType,
    StockOption,
    SymbolSummaryInfo,
    TradeOrder,
} from 'api';
import React, { Component, ReactElement } from 'react';
import FutureTrade from './FutureTradeForm';
import MultilegTrade from './MultilegTradeForm';
import OptionTrade from './OptionTradeForm';
import StockTrade from './StockTradeForm';
import withScreenSize from '../HOC/WithScreenSize';
import { ScreenSizeProp } from 'util/commonProps';
import { AppContext } from 'util/appContext';
import { PortfolioSelect } from 'containers/common/PortfolioSelect';
import { InflyMessage } from 'containers/common/InflyMessage';
import CommonUtil from 'util/CommonUtil';

export enum TradeTabs {
    STOCK,
    OPTION,
    MULTI_LEG,
    FUTURE,
}

export enum TradeSharesType {
    NO_OF_SHARES = 'NO_OF_SHARES',
    MAX_AMOUNT = 'MAX_AMOUNT',
}

interface TradeProps extends ScreenSizeProp {
    /* There are multiple usecases/dimensions this component supports
     * Security: This can be defined (trade from stock details page) or undefined (trade from portfolio page)
     * Portfolio Id: This can again be defined (trade form portfolio page) or undefined (trade from stock detail page)
     * PendingOrder - this can be specified in case of edit pending order. Or it can be undefined in case of new order
     */

    // If security and portfolio are specified, they must be frozen, and cannot be altered in the trade order thats being submitted
    security?: SymbolSummaryInfo;
    portfolio?: PortfolioIdentifier;
    // specifying pending order is same as specifying both security and portfolio
    pendingOrder?: PendingOrder;
    fullWidth?: boolean;

    // For edit pending order, returns PendingOrder
    // For Cancel, returns Undefined
    onTradeDialogReturn: (result: OrderStatus | OrderStatus[] | PendingOrder | undefined) => void;
    // for single or multiple trade order
    showOnly?: TradeTabs[];
    showDialogOnly?: boolean;

    //for automatically fill option trade order details with StockOptions
    stockOption?: StockOption;
    // button attributes
    label?: string;
    icon?: React.ReactNode;
    variant?: 'text' | 'contained' | 'outlined';

}

interface TradeState {
    message?: Message;
    isDialogOpen: boolean;
    activeTab: TradeTabs;
    tradeOrder: TradeOrder[];

    // portfolioId is needed in state becuase pops.portfolio can  be undefined. Although technically state.tradeOrder already has portfolioId
    // for multileg orders, its more convinienve to store portfolioID explicitly than set it for all tradeOrders. Further, for multileg order
    // the MultilegForm will generate tradeOrder and pass upstream, whereas its logically make more sense to capture portfolioId here in this component
    portfolio: PortfolioIdentifier;
}

class Trade extends Component<TradeProps, TradeState> {
    portfolioApiClient: PortfolioAPIClient;

    constructor(props: TradeProps) {
        super(props);

        this.portfolioApiClient = AppContext.getInstance().portfolioApiClient;
        this.state = this.resetInitialState();
    }

    resetInitialState(): TradeState {
        // since this component instance is used every time the dialog is lauched (its not re-instantiated), we need to make sure
        // that we leave the dialog state in original condition every time it closes (success or cancel). On failure, the dialog stays open

        let portfolio = this.props.portfolio;
        let tradeOrder: TradeOrder;
        let activeTab = TradeTabs.STOCK;
        if (this.props.showOnly && this.props.showOnly.length > 0)
            activeTab = this.props.showOnly[0];
        if (this.props.pendingOrder) {
            tradeOrder = this.props.pendingOrder;
            portfolio = { portfolioId: tradeOrder.portfolioId, brokerType: tradeOrder.brokerType };
            if (tradeOrder.security.securityType === SecurityType.OPTION) {
                activeTab = TradeTabs.OPTION;
            } else if (tradeOrder.security.securityType === SecurityType.FUTURE) {
                activeTab = TradeTabs.FUTURE;
            }
        } else {
            // For all tabs except MultiLeg, we initialize the TradeOrder with provided portoflio and secuirty and pass it down
            // since we set activeTab = STOCK, we can create stub TradeOrder
            tradeOrder = new TradeOrder();
            tradeOrder.orderType = OrderType.CUSTOM_CONDITION;
            if (this.props.portfolio?.portfolioId)
                tradeOrder.portfolioId = this.props.portfolio?.portfolioId;
            if (this.props.portfolio?.brokerType)
                tradeOrder.brokerType = this.props.portfolio?.brokerType;
            if (this.props.security) tradeOrder.security = this.props.security.toStock();
        }
        if (this.props.stockOption) {
            tradeOrder.security = this.props.stockOption;
        }
        return {
            portfolio: portfolio!,
            isDialogOpen: this.props?.showDialogOnly ?? false,
            tradeOrder: [tradeOrder],
            activeTab: activeTab,
            message: undefined,
        };
    }

    onTabSelect = (newValue: TradeTabs) => {
        this.setState({
            activeTab: newValue,
            message: undefined,
        });
        if (newValue == TradeTabs.MULTI_LEG) {
            // for multileg tab, since we don't know how many legs will be there, we don't pass initialized trade order
            this.setState({ tradeOrder: [] });
        } else {
            // We also need reset the state when tab changes, otherwise user puts Symbol=AAPL in Stocks tab and when he goes to Futures tab, he will see AAPL in Futures tab in symbol field
            let tradeOrder = new TradeOrder();
            if (newValue == TradeTabs.FUTURE) {
                tradeOrder.orderType = OrderType.MARKET_ORDER;
            } else {
                tradeOrder.orderType = OrderType.CUSTOM_CONDITION;
            }

            if (this.props.portfolio?.portfolioId)
                tradeOrder.portfolioId = this.props.portfolio?.portfolioId;
            if (this.props.portfolio?.brokerType)
                tradeOrder.brokerType = this.props.portfolio?.brokerType;
            if (this.props.security) tradeOrder.security = this.props.security.toStock();
            this.setState({ tradeOrder: [tradeOrder] });
        }
    };

    isTradeTabDisabled = (tab: TradeTabs): boolean => {
        let isDisabled = false;
        // If PendingOrder or Security is specified, only enable the tab that corresponds to the security
        if (!this.props.pendingOrder && !this.props.security) isDisabled = false; // for new orders, enable all tabs

        if (this.props.showOnly && this.props.showOnly.length > 0) {
            // check showOnly props is defined
            isDisabled = !this.props.showOnly?.includes(tab) ?? true; // false means show only.
        }
        return isDisabled;
    };

    onSubmit = async () => {
        try {
            for (let order of this.state.tradeOrder) {
                order.validate();
            }

            if (this.props.pendingOrder) {
                let pendingOrder = await this.portfolioApiClient.updatePendingOrder(
                    this.state.portfolio.portfolioId,
                    this.state.portfolio.brokerType,
                    this.props.pendingOrder.orderId,
                    this.state.tradeOrder[0]
                );
                this.props.onTradeDialogReturn(pendingOrder);
            } else {
                for (let order of this.state.tradeOrder) {
                    // orders created by MultiLegForm won't have portfolioID/brokerType since they are managed by this component.
                    order.portfolioId = this.state.portfolio.portfolioId;
                    order.brokerType = this.state.portfolio.brokerType;
                }

                if (this.state.tradeOrder.length === 1) {
                    const orderStatus = await this.portfolioApiClient.doTrade(
                        this.state.portfolio.portfolioId,
                        this.state.portfolio.brokerType,
                        this.state.tradeOrder[0]
                    );
                    this.props.onTradeDialogReturn(orderStatus);
                } else {
                    const orderStatus = await this.portfolioApiClient.doMultiTrade(
                        this.state.portfolio.portfolioId,
                        this.state.portfolio.brokerType,
                        this.state.tradeOrder
                    );
                    this.props.onTradeDialogReturn(orderStatus);
                }
            }

            // this component is re-used (no-reinstantiated) when the Trade button is clicked multiple times. So when trade is complete, then it should reset back to original
            // so that previously provided trade order parameters are all erased
            this.setState(this.resetInitialState());
        } catch (error) {
            this.setState({ message: Message.fromError(error) }, CommonUtil.ScrollToMessage);
        }
    };

    onCancel = () => {
        this.setState(this.resetInitialState());
        this.props.onTradeDialogReturn(undefined);
    };
    isEditPendingOrder = (): boolean => {
        return (
            this.state.tradeOrder[0] instanceof PendingOrder &&
            this.state.tradeOrder[0].orderId !== undefined
        );
    };

    render() {
        const activeTab = this.state.activeTab;

        let upgradeOperation: OPERATION | undefined = undefined;
        if (activeTab === TradeTabs.OPTION || activeTab === TradeTabs.FUTURE) {
            upgradeOperation = OPERATION.TRADE_DERIVATIVES;
        }
        const isMobile = this.props.isMobile;

        const renderIconButton = this.props.icon && !this.props.label;
        let tickerSymbol = new SymbolSummaryInfo();
        if (this.props.security) {
            tickerSymbol = this.props.security;
        }
        // if (this.isEditPendingOrder()) {
        //     tickerSymbol.symbol = this.state.tradeOrder[0].security.symbol;
        // TODO: need a handler block of code to set symbol for when this.state.tradeOrder[0] Security Type is Option
        // }
        return (
            <>
                {this.state.isDialogOpen && (
                    <InflyDialog
                        id={'trade-dialog'}
                        tooltip={'Click to stock trade, multileg trade'}
                        title={`Submit Trade Order`}
                        open={this.state.isDialogOpen}
                        okText={this.state.portfolio ? 'Submit Order' : undefined}
                        isScreenSizeDependent={true}
                        maxWidth={false}
                        fullWidth={this.state.portfolio !== undefined}
                        upgradeOperation={upgradeOperation}
                        onExecute={this.onSubmit}
                        onClose={this.onCancel}
                    >
                        {this.state.portfolio === undefined && (
                            <PortfolioSelect
                                onPortfolioSelect={(portfolio: PortfolioIdentifier) => {
                                    this.setState({ portfolio: portfolio });
                                }}
                            />
                        )}

                        {this.state.portfolio && this.state.portfolio.portfolioId && (
                            <Paper variant='outlined'>
                                <InflyMessage
                                    message={this.state.message}
                                    onClose={() => this.setState({ message: undefined })}
                                />

                                <InflyTabs
                                    value={this.state.activeTab}
                                    onTabSelect={this.onTabSelect}
                                    variant='scrollable'
                                    allowScrollButtonsMobile
                                    scrollButtons='auto'
                                >
                                    <InflyTab
                                        label='Stock'
                                        disabled={this.isTradeTabDisabled(TradeTabs.STOCK)}
                                    >
                                        <StockTrade
                                            tradeOrder={this.state.tradeOrder[0]}
                                            presetSecurity={tickerSymbol}
                                            onTradeOrderChange={(tradeOrder: TradeOrder) =>
                                                this.setState({ tradeOrder: [tradeOrder] })
                                            }
                                            onMessage={(message) =>
                                                this.setState(
                                                    { message: message },
                                                    CommonUtil.ScrollToMessage
                                                )
                                            }
                                        />
                                    </InflyTab>

                                    <InflyTab
                                        label='Options'
                                        disabled={this.isTradeTabDisabled(TradeTabs.OPTION)}
                                    >
                                        <OptionTrade
                                            presetSecurity={tickerSymbol}
                                            tradeOrder={this.state.tradeOrder[0]}
                                            onTradeOrderChange={(tradeOrder: TradeOrder) =>
                                                this.setState({ tradeOrder: [tradeOrder] })
                                            }
                                            onMessage={(message) =>
                                                this.setState(
                                                    { message: message },
                                                    CommonUtil.ScrollToMessage
                                                )
                                            }
                                        />
                                    </InflyTab>

                                    <InflyTab
                                        label='Multileg'
                                        disabled={this.isTradeTabDisabled(TradeTabs.MULTI_LEG)}
                                    >
                                        <MultilegTrade
                                            tradeOrders={this.state.tradeOrder}
                                            presetSecurity={tickerSymbol}
                                            onTradeOrderChange={(tradeOrders) =>
                                                this.setState({ tradeOrder: tradeOrders })
                                            }
                                            onMessage={(message) =>
                                                this.setState(
                                                    { message: message },
                                                    CommonUtil.ScrollToMessage
                                                )
                                            }
                                        />
                                    </InflyTab>
{/* 
                                    <InflyTab
                                        label='Futures'
                                        disabled={this.isTradeTabDisabled(TradeTabs.FUTURE)}
                                    >
                                        <FutureTrade
                                            tradeOrder={this.state.tradeOrder[0]}
                                            onTradeOrderChange={(tradeOrder: TradeOrder) =>
                                                this.setState({ tradeOrder: [tradeOrder] })
                                            }
                                            onMessage={(message) =>
                                                this.setState(
                                                    { message: message },
                                                    CommonUtil.ScrollToMessage
                                                )
                                            }
                                        />
                                    </InflyTab> */}
                                </InflyTabs>
                            </Paper>
                        )}
                    </InflyDialog>
                )}

                {this.props?.showDialogOnly !== true && renderIconButton && (
                    <IconButton
                        disabled={
                            this.props.portfolio && this.props.portfolio.portfolioId === undefined
                        }
                        onClick={() => {
                            this.setState({ isDialogOpen: true });
                        }}
                    >
                        {this.props.icon}
                    </IconButton>
                )}
                {this.props?.showDialogOnly !== true && !renderIconButton && (
                    <Button
                        fullWidth={this.props.fullWidth ?? isMobile}
                        disabled={
                            this.props.portfolio && this.props.portfolio.portfolioId === undefined
                        }
                        startIcon={this.props.icon}
                        onClick={(event: any) => {
                            event.preventDefault();
                            this.setState({ isDialogOpen: true });
                        }}
                        variant={this.props.variant ?? 'contained'}
                    >
                        {this.props.label}
                    </Button>
                )}
            </>
        );
    }
}

export default withScreenSize(Trade);
