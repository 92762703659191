import {InvestflyModel} from '../InvestflyModel';
import {UserError} from './userError';

export enum MessageType {
    ERROR = "ERROR",
    INFO = "INFO",
    WARN = "WARN",
    SUCCESS = "SUCCESS",
}

export type Severity = 'success'|'error'|'warning'|'info'

export class Message extends InvestflyModel {
    message: string;
    type: MessageType;

    static parseJSON(obj: {[index:string]: any}): Message {
        let message: Message = new Message();
        Object.assign(message, obj);
        return message;
    }

    static error(errorMsg:string): Message{
        let message: Message = new Message();
        message.type = MessageType.ERROR;
        message.message = errorMsg;
        return message;
    }

    static info(success: string): Message{
        let message: Message = new Message();
        message.type = MessageType.INFO;
        message.message = success;
        return message;
    }

    static warning(success: string): Message{
        let message: Message = new Message();
        message.type = MessageType.WARN;
        message.message = success;
        return message;
    }

    static success(success: string): Message{
        let message: Message = new Message();
        message.type = MessageType.SUCCESS;
        message.message = success;
        return message;
    }

    static fromError(error: Error): Message {
        let message: Message = new Message();
        message.type = MessageType.ERROR;
        if(error instanceof UserError){
            message.message = error.message;
        }else{
            console.log(error);
            message.message ="Unexpected error occurred while processing the request. Please contact us";
        }

        return message;
    }

    isError(): boolean {
        return this.type === MessageType.ERROR;
    }

    getSeverity(): Severity {
        switch(this.type){
            case MessageType.ERROR:
                return 'error';
            case MessageType.INFO:
                return 'info';
            case MessageType.WARN:
                return 'warning';
            case MessageType.SUCCESS:
                return 'success';
            default:
                return 'warning';
        }
    }


}