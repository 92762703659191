/** @format */

import { Button, ButtonProps, DialogContent, FormControl, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import {
    Message,
    SecurityType,
    UserError,
    WatchList,
    WatchListAPIClient,
} from 'api';
import InflyDialog from 'containers/common/InflyDialog';
import { InflyMessage } from 'containers/common/InflyMessage';
import  { SelectOption, SelectField } from 'containers/common/SelectField';
import withScreenSize from 'containers/HOC/WithScreenSize';
import React from 'react';
import { AppContext } from 'util/appContext';
import { ScreenSizeProp } from 'util/commonProps';

/*
 * This component renders a button to passed stocks to user selected watchlist.
 * It prompts user to select a watchlist or create a new one
 * Upon successful completion, it notifies parent component with success message
 */

export interface AddToWatchListProps extends ScreenSizeProp, ButtonProps {
    onActionReturn?: (watchlist: WatchList) => void;
    symbols: string[]; // array to support adding multiple symbols to watchlists
    onMessage: (message: Message) => void;
}

interface AddToWatchListBtnState {
    message?: Message;
    dialogOpen: boolean;
    allWatchLists: WatchList[];
    selectedWatchList?: number;
    newWatchListName?: string; // when add to 'New WatchList' is selected
}

class AddToWatchListButton extends React.Component<AddToWatchListProps, AddToWatchListBtnState> {
    watchListApiClient: WatchListAPIClient;

    constructor(props: AddToWatchListProps) {
        super(props);
        this.watchListApiClient = AppContext.getInstance().watchListApiClient;
        this.state = {
            dialogOpen: false,
            allWatchLists: undefined!,
        };
    }

    componentDidMount(): void {
        this.watchListApiClient.listWatchLists().then((allWatchLists) => {
            this.setState({ allWatchLists: allWatchLists });
        });
    }

    onDialogExecute = async () => {
        let watchListId = this.state.selectedWatchList!;

        if (watchListId === -1) {
            // for creating a new watch list
            if (
                this.state.newWatchListName === undefined ||
                this.state.newWatchListName.length === 0
            ) {
                throw new UserError('Watch List Name cannot be empty');
            }
            let spec = new WatchList();
            spec.name = this.state.newWatchListName!;
            spec.securityType = SecurityType.STOCK;

            let newWatchList = await this.watchListApiClient.createWatchList(spec);
            watchListId = newWatchList.id;
        }

        const allPromises = [];
        for (let ticker of this.props.symbols) {
            const promise = this.watchListApiClient.addSecurityToWatchList(watchListId, ticker);
            allPromises.push(promise);
        }
        let allPromise = Promise.all(allPromises);

        try {
            let watchLists = await allPromise;
            // On success, notify parent component and close the dialog
            this.props.onMessage(
                Message.info('Successfully added to watchlist ' + watchLists[0].name)
            );
            this.setState(
                {
                    dialogOpen: false,
                    selectedWatchList: undefined,
                },
                () => {
                    if (this.props.onActionReturn) {
                        const currentWatchList = watchLists.find((item) => item.id === watchListId);
                        this.props.onActionReturn(currentWatchList!);
                    }
                }
            );
        } catch (error) {
            // on error, we keep the dialog open and display error message
            this.setState({ message: Message.fromError(error) });
        }
        // Needed for ActionButton, which is Dialog's OK button
        return allPromise;
    };

    createOptions(): SelectOption[] {
        let options = this.state.allWatchLists.map((w) => {
            return { label: w.name, value: w.id };
        });
        options.push({ label: 'New WatchList', value: -1 });
        return options;
    }

    render(): React.ReactNode {
        const {
            onActionReturn,
            symbols, // array to support adding multiple symbols to watchlists
            onMessage,
            isMobile,
            ...restButton
        } = this.props;
        return (
            <>
                {this.state.dialogOpen && (
                    <InflyDialog
                        id={'add-to-watchlist'}
                        title={'Add to WatchList'}
                        open={this.state.dialogOpen}
                        okText={'Add'}
                        fullWidth={true}
                        maxWidth={'xs'}
                        onClose={() => this.setState({ dialogOpen: false })}
                        onExecute={this.onDialogExecute}
                    >
                        <Stack gap={1}>
                            <InflyMessage
                                message={this.state.message}
                                onClose={() => this.setState({ message: undefined })}
                            />

                            <SelectField
                                label='Select WatchList'
                                options={this.createOptions()}
                                value={this.state.selectedWatchList}
                                onChange={(event) =>
                                    this.setState({
                                        selectedWatchList: parseInt(event.target.value),
                                    })
                                }
                            />

                            {this.state.selectedWatchList &&
                                this.state.selectedWatchList === -1 && (
                                    <TextField
                                        label='New WatchList Name'
                                        onChange={(event) =>
                                            this.setState({ newWatchListName: event.target.value })
                                        }
                                    />
                                )}
                        </Stack>
                    </InflyDialog>
                )}

                <Button
                    {...restButton}
                    fullWidth={restButton.fullWidth ?? this.props.isMobile}
                    size={'small'}
                    onClick={(event: any) => {
                        event.preventDefault();
                        this.setState({ dialogOpen: true });
                    }}
                >
                    Add to WatchList
                </Button>
            </>
        );
    }
}

export default withScreenSize(AddToWatchListButton);
