/** @format */

import {
    Card,
    CardContent,
    CardHeader,
    CssBaseline,
    GlobalStyles,
    PaletteMode,
    Paper,
} from '@mui/material';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { createAppTheme, createGlobalStyles } from 'AppStyles';
import React, { useEffect, useState } from 'react';
import MainRoutes from './Route';

// This context holds the function that will changes theme, its passed down to theme change button
// The function is set to NO-OP here as defualt, but its overwrriten below
export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function GStyle() {
    return <GlobalStyles styles={createGlobalStyles} />;
}

export default function AppMain() {
    const [mode, setMode] = useState<PaletteMode>('dark');

    // toggleColorMode and theme are both React.Context values that will be passed down with Context.Provider below
    const toggleColorMode = {
        toggleColorMode: () => {
            const newMode = mode === 'light' ? 'dark' : 'light';
            setMode(newMode);
            localStorage.setItem('theme', newMode);
        },
    };

    const theme: Theme = React.useMemo(() => {
        const theme = createAppTheme(mode);
        return theme;
    }, [mode]);

    useEffect(() => {
        const localThemeMode = localStorage.getItem('theme');

        if (localThemeMode) {
            setMode(localThemeMode as PaletteMode);
            localStorage.setItem('theme', localThemeMode);
        } else {
            localStorage.setItem('theme', mode);
        }
    }, []);

    return (
        <ColorModeContext.Provider value={toggleColorMode}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <GStyle />
                    <MainRoutes />
                </ThemeProvider>
            </StyledEngineProvider>
        </ColorModeContext.Provider>
    );
}
