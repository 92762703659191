import {InvestflyUtil} from '../../utils/investflyUtil';
import {TradeOrder} from './tradeOrder';

export class PendingOrder extends TradeOrder{
    orderId: string;
    status: string;
    scheduledDate: Date;

    static parseJSON(obj: {[index:string]: any}): PendingOrder {
        let tradeOrder = TradeOrder.parseJSON(obj);
        let pendingOrder = new PendingOrder();
        Object.assign(pendingOrder, tradeOrder);
        pendingOrder.scheduledDate = InvestflyUtil.strToDate(obj.scheduledDate)!;
        return pendingOrder;
    }
}