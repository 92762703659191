import {IndicatorSpec, MetricType} from '../models/trigger/indicator';
import {UserError} from '../models/common/userError';
import {RestAPIClient} from './restAPIClient';
import {InvestflyModel} from '../models/InvestflyModel';
import { DataParam } from '../models/trigger/DataParam';

const VALIDATORS: Map<string, (params: Map<string, any>) => void> = new Map<string, (params: Map<string, any>) => void>();
VALIDATORS.set("MACDS", (params: Map<string, any>) => {
    const slowPeriod = params.get("slower_ema_period") as number;
    const fastperiod =  params.get("faster_ema_period") as number;
    if(slowPeriod > fastperiod) throw new UserError("slow period must be less than Fast period.")

})
VALIDATORS.set("UBBAND",(params: Map<string, any>) => {
    const period = params.get("period") as number
    const std_dev = params.get("std_dev") as number
    if(std_dev<0) throw new UserError("std_dev must be greater tha zero.")
    if(period<std_dev) throw new UserError("period must be greater then std_dev.")
})

VALIDATORS.set("MACD",(params: Map<string, any>) => {
    const slowPeriod = params.get("slower_ema_period") as number;
    const fastperiod =  params.get("faster_ema_period") as number;
    if(slowPeriod > fastperiod) throw new UserError("slow period must be less than fast period.")
})
VALIDATORS.set("STOCHS",(params: Map<string, any>) => {
    const fast_d_period = params.get("fast_d_period") as number;
    const fast_k_period  =  params.get("fast_k_period") as number;
    if(fast_k_period < fast_d_period) throw new UserError("fast_k_period must be greater than fast_d_period.")

})
VALIDATORS.set("ULTOSC",(params: Map<string, any>) => {
    const average3 = params.get("average3") as number;
    const average2 = params.get("average2") as number;
    const average1 = params.get("average1") as number;
    if(average3 < average2 || average3 < average1) throw new UserError("average3 must be greater than average2 and average1.")
    if(average2<average1) throw new UserError("average2 must be greater than average1.");

})
VALIDATORS.set("PPO",(params: Map<string, any>) => {
    const slowPeriod = params.get("slower_ema_period") as number;
    const fastperiod =  params.get("faster_ema_period") as number;
    if(slowPeriod < fastperiod) throw new UserError("slow period must be greater than fast period.")
})
VALIDATORS.set("STOCHF",(params: Map<string, any>) => {
    const fast_k_period = params.get("fast_k_period") as number;
    const fast_k_sma_period =  params.get("fast_k_sma_period") as number;
    if(fast_k_period < fast_k_sma_period) throw new UserError("fast_k_period must be greater than fast_k_sma_period.")
})

VALIDATORS.set("PREVBAR",(params: Map<string, any>) => {
    const price = params.get("price") as number
    if(price<0) throw new UserError("price must be greater than zero.")
})
VALIDATORS.set("MAX",(params: Map<string, any>) => {
    const price = params.get("price") as number
    if(price<0) throw new UserError("price must be greater than zero.")
})
VALIDATORS.set("MIN",(params: Map<string, any>) => {
    const price = params.get("price") as number
    if(price<0) throw new UserError("price must be greater than zero.")
})

export class IndicatorAPIClient {

    private STANDARD_INDICATORS_CACHE: IndicatorSpec[]; // cache
    private restApiClient: RestAPIClient;

    constructor(restApiClient: RestAPIClient){
        this.restApiClient  = restApiClient;
        this.STANDARD_INDICATORS_CACHE = [];
    }

    async listStandardIndicators(): Promise<IndicatorSpec[]> {
        if (this.STANDARD_INDICATORS_CACHE && this.STANDARD_INDICATORS_CACHE.length > 0){
            return [...this.STANDARD_INDICATORS_CACHE]
        }

        const response: object[] =  await this.restApiClient.get("/indicator/list/standard");
        this.STANDARD_INDICATORS_CACHE = InvestflyModel.parseList(response, IndicatorSpec.parseJSON);
        return [...this.STANDARD_INDICATORS_CACHE];
    }


    async listCustomIndicators(): Promise<IndicatorSpec[]> {
        let response = await this.restApiClient.get("/indicator/list/custom")
        return InvestflyModel.parseList(response, IndicatorSpec.parseJSON);
    }

    async listCustomIndicatorsSamples(): Promise<any> {
        let response = await this.restApiClient.get("/indicator/list/custom/samples")
        return response;
    }

    async getIndicator(id: string): Promise<IndicatorSpec> {
        let response = await this.restApiClient.get(`/indicator/${id}`)
        return IndicatorSpec.parseJSON(response);
    }

    async getCustomIndicatorCode(id: string): Promise<string> {
        let response = await this.restApiClient.get(`/indicator/custom/${id}/code`)
        return response as string;
    }

    async createUpdateCustomIndicator(pythonCode: string): Promise<IndicatorSpec> {
        let response = await this.restApiClient.post("/indicator/custom/update", pythonCode);
        return IndicatorSpec.parseJSON(response);
    }

    async deleteCustomIndicator(id: string): Promise<boolean> {
        let response = await this.restApiClient.delete(`/indicator/custom/${id}`)
        return response as boolean
    }

   
    async computeIndicators(symbol:string, dataParams: Map<string, DataParam>): Promise<{[key: string]: number}> {
        let response = await this.restApiClient.post(`/indicator/compute/${symbol}`, dataParams);
        return response;
    }

    async getIndicatorSeries(symbol: string, dataParam: DataParam): Promise<number[][]> {
        symbol = symbol.replaceAll('^', '%5E');
        let url = `/marketdata/indicatorseries?symbol=${symbol}`;
        let result = await this.restApiClient.post(url, dataParam) as number[][];
        return result;
    }

}
