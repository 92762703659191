import {Message} from '../models/common/message';
import {InvestflyModel} from '../models/InvestflyModel';
import {CompletedGame, Game, GameStatus} from '../models/marketGame/game';
import {UserGameScore} from '../models/marketGame/userGameScore';
import {Portfolio} from '../models/portfolio/portfolio';
import {RestAPIClient} from './restAPIClient';

export class GameAPIClient {

    private restApiClient: RestAPIClient;

    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }

    async createGame(game: Game): Promise<Game> {
        const res: object = await this.restApiClient.post(`/game/create`, game);
        return Game.parseJSON(res);
    }

    async getGame(gameId: number): Promise<Game> {
        const res: object = await this.restApiClient.get(`/game/${gameId}`);
        return Game.parseJSON(res);
    }

    async listMyCreatedGames(): Promise<Game[]>{
        const res: object[] = await this.restApiClient.get(`/game/list/created`);
        return InvestflyModel.parseList(res, Game.parseJSON);
    }

    async listMyEnrolledGames(): Promise<Game[]>{
        const res: object[] = await this.restApiClient.get(`/game/list/enrolled`);
        return InvestflyModel.parseList(res, Game.parseJSON);
    }

    async listMyInvitedGames(): Promise<Game[]>{
        const res: object[] = await this.restApiClient.get(`/game/list/invited`);
        return InvestflyModel.parseList(res, Game.parseJSON);
    }

    async listClassGames(className: string): Promise<Game[]> {
        const res: object[] = await this.restApiClient.get(`/game/list/class?class=${className}`);
        return InvestflyModel.parseList(res, Game.parseJSON);
    }

    async getMyGameScore(gameId: number): Promise<UserGameScore|undefined> {
        const res: object = await this.restApiClient.get(`/game/${gameId}/score`);
        return UserGameScore.parseJSON(res);
    }

    async getAllGameScores(gameId: number): Promise<UserGameScore[]> {
        const res: object[] = await this.restApiClient.get(`/game/${gameId}/list/scores`);
        return InvestflyModel.parseList(res, UserGameScore.parseJSON);
    }


    async downloadGameSummary(gameId: number) {
        await this.restApiClient.get(`/game/${gameId}/download/scores`);
    };

    async deleteGame(gameId: number): Promise<Message> {
        const res: object = await this.restApiClient.delete(`/game/${gameId}`);
        return Message.parseJSON(res);
    }

    async updateGame(game: Game, gameId: number): Promise<Game> {
        const res: object = await this.restApiClient.post(`/game/${gameId}`, game);
        return Game.parseJSON(res);
    }

    async patchGame(game: Game, gameId: number): Promise<Game> {
        const res: object = await this.restApiClient.post(`/game/${gameId}/patch`, game);
        return Game.parseJSON(res);
    }

    async startGame(gameId: number, endDate?: number): Promise<GameStatus> {
        // for repeatable game, endDate is not required
        let url = `/game/${gameId}/start`;
        if(endDate !== undefined )
            url += "?enddate="+endDate

        const res: string = await this.restApiClient.post(url,null);
        return (<any>GameStatus)[res];
    }


    async endGame(gameId: number): Promise<GameStatus> {
        const res: string = await this.restApiClient.post(`/game/${gameId}/end`,null);
        return (<any>GameStatus)[res];
    } 
    
    async joinGame(gameId: number, portfolioId: string): Promise<UserGameScore> {
        const res: object = await this.restApiClient.post(`/game/${gameId}/join?portfolioId=${portfolioId}`);
        return UserGameScore.parseJSON(res)!;
    }

    async assignPortfolioToGame(gameId: number, portfolioId: string): Promise<UserGameScore> {
        const res: object = await this.restApiClient.post(`/game/${gameId}/assign?portfolioId=${portfolioId}`);
        return UserGameScore.parseJSON(res)!;
    }

    async leaveGame(gameId: number): Promise<Message> {
        const res: object = await this.restApiClient.post(`/game/${gameId}/leave`);
        return Message.parseJSON(res);
    }

    async inviteUsers(gameId: number, usernames: string[]): Promise<Map<string, string[]>>{
        const res: object = await this.restApiClient.post(`/game/${gameId}/invite/users`, usernames);
        const resMap: Map<string, string[]> = new Map<string, string[]>();
        Object.assign(resMap, res);
        return resMap;
    }

    async inviteEmails(gameId: number, emails: string[]): Promise<Map<string, string[]>>{
        const res: object = await this.restApiClient.post(`/game/${gameId}/invite/emails`, emails);
        const resMap: Map<string, string[]> = new Map<string, string[]>();
        Object.assign(resMap, res);
        return resMap;
    }

    async getGameEligiblePortfolios(gameId: number): Promise<Portfolio[]> {
        const res: object[] = await this.restApiClient.get(`/game/${gameId}/eligible/portfolios`);
        return InvestflyModel.parseList(res, Portfolio.parseJSON);
    }


    async getCompletedGames(gameId:number): Promise<CompletedGame[]>{
        const res:object[] =await this.restApiClient.get(`/game/${gameId}/completed`);
        return InvestflyModel.parseList(res, CompletedGame.parseJSON);
    }

}