/** @format */

import { UserSession } from 'api';
import React, { Component } from 'react';
import withRouter from 'containers/HOC/WithRouter';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RouteProps } from 'util/commonProps';
import SessionUtil from 'util/SessionUtil';
import Checkout from './Checkout';
import Payment from './Pricing';
import { PricingController } from './Pricing';

interface UpgradeCompState {
    routeInitialized: boolean;
}

class UpgradeComponent extends Component<RouteProps, UpgradeCompState> {
    pricingController: PricingController;

    constructor(props: RouteProps) {
        super(props);
        this.pricingController = new PricingController();
        this.state = {
            routeInitialized: false,
        };
    }

    componentDidMount() {
        const isAuthenticated: boolean = SessionUtil.isUserLoggedIn();
        if (isAuthenticated) {
            const session: UserSession = SessionUtil.findSession()!;
            this.pricingController
                .loadUserProfilePricingPlans(session.username)
                .then((init: boolean) => {
                    this.setState({ routeInitialized: init });
                });
        }
    }

    render() {
        const isAuthenticated: boolean = SessionUtil.isUserLoggedIn();
        const currentUrl = this.props.location.pathname + this.props.location.search;
        if (!isAuthenticated)
            return (
                <Navigate
                    to={`/?toPage=${window.encodeURIComponent(currentUrl)}`}
                    state={{ from: this.props.location }}
                    replace
                />
            );
        if (!this.state.routeInitialized) {
            return;
        } else {
            return (
                <>
                    <Routes>
                        <Route
                            path={'/pricing'}
                            element={
                                <Payment
                                    {...this.props}
                                    pricingController={this.pricingController}
                                />
                            }
                        />
                        <Route
                            path={`/Checkout`}
                            element={
                                <Checkout
                                    {...this.props}
                                    pricingController={this.pricingController}
                                />
                            }
                        />
                    </Routes>
                </>
            );
        }
    }
}

export default withRouter(UpgradeComponent);
