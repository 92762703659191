import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from "@mui/material";
import { ConfigOrScript, TradingStrategyModel } from "api/models/strategy/TradingStrategyModel"
import InflyDialog from "containers/common/InflyDialog";
import InflyHelperText from "containers/common/InflyHelperText";
import React, { useState } from "react";
import { ClosePositionComp, ClosePositionCompProps, OpenPositionComp, StrategyScopeConfigComp } from "./StandardStrategyComp";
import { SecurityUniverseSelector } from "api/models/trigger/SecurityUniverseSelector";
import { ClosePositionConfig, OpenPositionConfig } from "api/models/strategy/StandardStrategyConfig";
import { ITextField } from "containers/common/ITextField";
import { RadioField, SelectOption, selectOptionsFromEnum } from "containers/common/SelectField";
import MonacoEditor from '@monaco-editor/react';
import { Message, OPERATION, StrategyAPIClient } from "api";
import { AppContext } from "util/appContext";
import { InflyMessage } from "containers/common/InflyMessage";
import UpgradeCheckButton from "containers/common/UpgradeCheckButton";
import ReactGA from 'react-ga4';
import { SampleStrategyListDialog } from "./StrategySampleList";
import { InflyIcon } from "containers/common/InflyIcon";
import A from "containers/common/Anchor";


/*
 This file exports both Dialog and Button that manages dialog's open state. This pattern can be used for dialog is re-usable
 by getting laucnhed from Menu items etc. And Button can be used so the parent component don't need to track dialog open state
*/

export type NewStrategyDialogButtonProps = {
    showSample?: boolean;
    btnLabel?: string;
    iconName?: string;
    cloneStrategy?: TradingStrategyModel;
    onCreate: (strategyModel: TradingStrategyModel) => void;
    curStrategiesCount: number;
}
export function NewStrategyDialogButton({btnLabel, iconName, showSample, cloneStrategy, curStrategiesCount,  onCreate}: NewStrategyDialogButtonProps): React.JSX.Element {

    const [strategyDialogOpen, setStrategyDialogOpen] = useState(false);
    const [sampleDialogOpen, setSampleDialogOpen] = useState(false);

    const[editStrategyModel, setEditStratgyModel] = useState<TradingStrategyModel|undefined>(cloneStrategy);

    function onDialogCreateReturn(strategyModel: TradingStrategyModel){
        ReactGA.event('new_strategy_cancelled', {});
        setStrategyDialogOpen(false);
        onCreate(strategyModel);
    }

    function onDialogCancelReturn(){
        ReactGA.event('new_strategy_cancelled', {});
        setStrategyDialogOpen(false);
    }

    function onBtnClick(event: any){
        if(showSample){
            setSampleDialogOpen(true);
        }else{
            setStrategyDialogOpen(true)
        }
      
        ReactGA.event('new_strategy_btn_click', {});
    }

    function onSampleSelect(sampleModel: TradingStrategyModel) {
        setEditStratgyModel(sampleModel);
        setStrategyDialogOpen(true);
        setSampleDialogOpen(false);
    }

    function onCreateFromScratch(){
        setEditStratgyModel(TradingStrategyModel.createDefault());
        setStrategyDialogOpen(true);
        setSampleDialogOpen(false);
    }

    function onSampleDialogCancel(){
        ReactGA.event('new_strategy_cancelled', {});
        setSampleDialogOpen(false);
    }

    return (<>

        {sampleDialogOpen &&
            <SampleStrategyListDialog onSampleSelect={onSampleSelect} onStartFromScratch={onCreateFromScratch} onCancel={onSampleDialogCancel}/>
        }

        {strategyDialogOpen && 
            <NewStrategyDialog onCreate={onDialogCreateReturn} onCancel={onDialogCancelReturn} sampleModel={editStrategyModel}/>
        }
        <UpgradeCheckButton op={OPERATION.CREATE_STRATEGY} currentCount={curStrategiesCount} label={btnLabel ?? 'New Strategy'}>
            <>
            {iconName && <IconButton onClick={onBtnClick}><InflyIcon fontSize={'small'}  name={iconName}/></IconButton>}
            {iconName === undefined && <Button onClick={onBtnClick}>{btnLabel ?? 'New Strategy'}</Button> }
            </>

        </UpgradeCheckButton>
       
    </>);
}

// ------------------------------------------------------------------------

export type NewStrategyDialogProps = {
    sampleModel?: TradingStrategyModel; // to load from samples
    onCreate: (strategyModel: TradingStrategyModel) => void;
    onCancel: () => void;
}

enum NewStrategyStep {
    BASIC_INFO = "BASIC_INFO",
    SELECT_SCOPE = "SELECT_SCOPE",
    OPEN_SETTINGS = "OPEN_SETTINGS",
    CLOSE_SETTINGS = "CLOSE_SETTINGS",
    CUSTOM_CODE = "CUSTOM_CODE"
}

export function NewStrategyDialog({onCreate, sampleModel, onCancel}: NewStrategyDialogProps): React.JSX.Element {
    // Unlike ScreenerModel, creatinng TradingStrategyModel is multi-step and naturally is coupled with Guided-Wizard dialog.
    //  Hence its not separated out from the Dialog Component

    const [currentStep, setCurrentStep] = useState<NewStrategyStep>(NewStrategyStep.BASIC_INFO);
    const [strategyModel, setStrategyModel] = useState<TradingStrategyModel>(sampleModel? sampleModel.clone() : TradingStrategyModel.createDefault());
    const [message, setMessage] = useState<Message>();

    function getProceedLabel(){
        if(currentStep === NewStrategyStep.CLOSE_SETTINGS || currentStep == NewStrategyStep.CUSTOM_CODE){
            return "Create";
        }else{
            return "Next";
        }
    }

    async function onProceedClicked(){
        if(currentStep === NewStrategyStep.BASIC_INFO){
            let error = strategyModel.validateBasicInfo();
            setMessage(error ? Message.error(error): undefined);
            if(!error){
                if(strategyModel.type == ConfigOrScript.CONFIG){
                    setCurrentStep(NewStrategyStep.SELECT_SCOPE);
                }else{
                    setCurrentStep(NewStrategyStep.CUSTOM_CODE);
                }
            }

        }else if(currentStep === NewStrategyStep.SELECT_SCOPE){
            let error = strategyModel.strategyConfig!.scopeConfig.validate();
            setMessage(error ? Message.error(error): undefined);
            if(!error){
                setCurrentStep(NewStrategyStep.OPEN_SETTINGS);
            }
            

        }else if(currentStep === NewStrategyStep.OPEN_SETTINGS){
            let error = strategyModel.strategyConfig!.openPositionConfig.validate();
            setMessage(error ? Message.error(error): undefined);
            if(!error){
                setCurrentStep(NewStrategyStep.CLOSE_SETTINGS);
            }

        }else if(currentStep === NewStrategyStep.CLOSE_SETTINGS || currentStep === NewStrategyStep.CUSTOM_CODE){ 
            let error = strategyModel.validate();
            setMessage(error ? Message.error(error): undefined);
            if(!error){
                try{
                    let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
                    let savedModel: TradingStrategyModel = await strategyApiClient.createStrategy(strategyModel);
                    onCreate(savedModel);
                }catch(er){
                    setMessage(Message.fromError(er));
                }
            }
        }
    }

 

    function onPrevClicked(){
        if(currentStep === NewStrategyStep.CUSTOM_CODE){
            setCurrentStep(NewStrategyStep.BASIC_INFO);
        }else if(currentStep === NewStrategyStep.CLOSE_SETTINGS){
            setCurrentStep(NewStrategyStep.OPEN_SETTINGS);
        }else if(currentStep === NewStrategyStep.OPEN_SETTINGS){
            setCurrentStep(NewStrategyStep.SELECT_SCOPE);
        }else if(currentStep === NewStrategyStep.SELECT_SCOPE){
            setCurrentStep(NewStrategyStep.BASIC_INFO);
        }else{
            setCurrentStep(NewStrategyStep.BASIC_INFO);
        }
    }



    function onBasicInfoChange(strategyModel: TradingStrategyModel){
        setStrategyModel(strategyModel.clone());
    }

    function onScopeChange(scope: SecurityUniverseSelector){
        strategyModel.strategyConfig!.scopeConfig = scope;
        setStrategyModel(strategyModel.clone());
    }

    function onOpenPositionChange(openPosConfig: OpenPositionConfig){
        strategyModel.strategyConfig!.openPositionConfig = openPosConfig;
        setStrategyModel(strategyModel.clone());
    }

    function onClosePositionChange(closePosConfig: ClosePositionConfig) {
        strategyModel.strategyConfig!.closePositionConfig = closePosConfig;
        setStrategyModel(strategyModel.clone());
    }

    function onPythonCodeChange(pythonCode: string){
        strategyModel!.pythonCode = pythonCode;
        setStrategyModel(strategyModel!.clone());
    }

    return (<>

        <Dialog open fullWidth maxWidth='lg'>
            <DialogTitle>New Trading Strategy</DialogTitle>
            <DialogContent>

            <Stack>

                <InflyMessage message={message} onClose={() => setMessage(undefined)} />

                {currentStep == NewStrategyStep.BASIC_INFO &&
                    <BasicInfoStepComp strategyModel={strategyModel} onChange={onBasicInfoChange} />
                }

                {currentStep == NewStrategyStep.SELECT_SCOPE && 
                    <StrategyScopeConfigComp scope={strategyModel.strategyConfig!.scopeConfig} onScopeChange={onScopeChange}/>
                }

                {currentStep == NewStrategyStep.OPEN_SETTINGS && 
                    <OpenPositionComp openPositionConfig={strategyModel.strategyConfig!.openPositionConfig} onChange={onOpenPositionChange} />
                }

                {currentStep == NewStrategyStep.CLOSE_SETTINGS && 
                    <ClosePositionComp closePositionConfig={strategyModel.strategyConfig!.closePositionConfig} onChange={onClosePositionChange} />
                }

                {currentStep == NewStrategyStep.CUSTOM_CODE && 
                    <CustomCodeStrategyStepComp pythonCode={strategyModel.pythonCode!} onCodeChange={onPythonCodeChange} />
                }


            </Stack>


            </DialogContent>

            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={onPrevClicked} disabled={currentStep === NewStrategyStep.BASIC_INFO}>Prev</Button>
                <Button onClick={onProceedClicked}>{getProceedLabel()}</Button>
            </DialogActions>
        </Dialog>

    </>);
}

type BasicInfoStepProps = {
    strategyModel: TradingStrategyModel;
    onChange: (strategyModel: TradingStrategyModel) => void;
}

function BasicInfoStepComp({strategyModel, onChange}: BasicInfoStepProps): React.JSX.Element {

    function onNameChange(name: string){
        strategyModel.strategyName = name;
        onChange(strategyModel);
    }

    function onDescChange(desc: string){
        strategyModel.strategyDesc = desc;
        onChange(strategyModel);
    }

    function onVsibilityChange(selectOption: SelectOption) {
        strategyModel.isPublic = selectOption.value;
        onChange(strategyModel);
    }

    function onStandardCustomTypeChange(selectOption: SelectOption){
        strategyModel.setStrategyType(selectOption.value);
        onChange(strategyModel);
    }

    return <Card>
    <CardHeader title="Basic Info"/>
    <CardContent>
        <Stack spacing={2}>
            <ITextField label='Name' value={strategyModel.strategyName} onChange={onNameChange} />
            <ITextField label="Description (Optional)" fullWidth multiline size={'small'} value={strategyModel.strategyDesc} onChange={onDescChange} />
            <RadioField label="Visibility" helperText="Public strategies are accessible to other users and can be shared via a URL" 
                    options={[{ label: "PRIVATE", value: false }, { label: "PUBLIC", value: true }]} 
                    value={strategyModel.isPublic} 
                    onSelectChange={onVsibilityChange} />
            <RadioField label="Strategy Type" helperText="Select CONFIG to define strategy rules using drag-and-drop UI configuration. Select SCRIPT to define strategy using Python code" 
                        options={selectOptionsFromEnum(ConfigOrScript)} 
                        value={strategyModel.type} 
                        onSelectChange={onStandardCustomTypeChange} />
        </Stack>

        
    </CardContent>
</Card>
}


type CustomCodeStrategyCompProps = {
    pythonCode: string,
    onCodeChange: (pythonCode: string) => void;
}

function CustomCodeStrategyStepComp({pythonCode, onCodeChange}: CustomCodeStrategyCompProps) : React.JSX.Element{


    return (<>
        <Card>
            <CardHeader title='Strategy Code'/>
            <CardContent>
                <Stack gap={2}>

                <Paper elevation={5}>
                            <Stack gap={3} padding={4}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant='h6'> Tip</Typography>
                                    <InflyIcon name='tip' />
                                </Stack>
                                <Typography variant='body2'>
                                   We recommand to use investfly-sdk to develop Python based strategies and indicators locally using your favorite IDE (Pycharm, VSCode) and upload to Investfly using CLI. See  <A target="_blank" href="https://www.investfly.com/guides/docs/index.html">investfl-sdk api docs</A>  for more details
                                </Typography>
                            </Stack>
                        </Paper>

                <MonacoEditor
                            value={pythonCode}
                            language='python'
                            width={`100%`}
                            height={500}
                            theme={'vs-dark'}
                            options={{
                                // lineHeight: 1,
                                // tabIndex: 4,
                                glyphMargin: false,
                                matchBrackets: 'always', // type is  'never' | 'near' | 'always'
                                lineNumbers: 'on', //type is LineNumbersType
                                minimap: { enabled: false }, // type is IEditorMinimapOptions
                                scrollbar: {
                                    // vertical: 'auto',
                                    // horizontal: 'hidden',
                                    // handleMouseWheel: true,
                                }, //type is IEditorScrollbarOptions
                                // tabSize: 4,
                                wordWrap: 'on',
                            }}
                            onChange={(value, event) => {
                                onCodeChange(value as string);
                            }}
                        />
                </Stack>
            </CardContent>
        </Card>
    </>);
}


