import {createTheme, PaletteMode,responsiveFontSizes, Theme} from '@mui/material';

/*
* Our site's theme is based on
* 3 Background Colors - Header, Paper, Default
* 2 Content colors - primary and seconday.
* How to pick colors is described here - https://invstfly.atlassian.net/wiki/spaces/INVESTFLYE/pages/1306656773/Styling+Notes
* Card and Table headers are customzed to have "header" background color
*
*/

declare module '@mui/material/styles' {
    interface TypeBackground {
      header: string;
    }
    interface CommonColors {
        red: string;
        green: string;
        heading: string;
      }
  }


export function createAppTheme(mode: PaletteMode): Theme{

    let theme: Theme = createTheme({
        palette: {
            mode, // same as mode: mode
            ...(mode === 'light') ?
            {
                background: {
                    default: '#d3ecf2',
                    header: '#e9f5f8',
                    paper: '#ffffff',
                },
                primary:{
                    main: '#588157'
                },
                common:{
                    red: 'red',
                    green: 'green',
                    heading: '#087024'
                }

            }: // dark mode values
            {
                background: {
                    default: '#031013',
                    header: '#072026',
                    paper: '#0a2f38',
                },
                primary:{
                    main: '#bccdbc'
                },
                common:{
                    red: '#EE4B2B',
                    green: '#5cfa7f',
                    heading: '#7bbd8c'
                }
            }
        }
    });

    theme = createTheme({
        palette: {...theme.palette},
        typography: {
            fontSize: 14, // Default font size
        },
        components: {
                MuiTableHead: {
                    styleOverrides: {
                        root: {
                            backgroundColor: theme.palette.background.header,
                        }
                    }
                },
                MuiAlert:{
                    styleOverrides:{
                        root: {
                            padding: '5px 12px !important',
                        }
                    }
                },
                MuiTableContainer:{
                    styleOverrides: {
                        root:{
                            [theme.breakpoints.between("xs", "md")]: {
                            padding: '8px 2px 0 2px',
                            borderRadius:0, fontSize: '0.8rem',
                            },

                        }
                    }},
                    MuiTable: {
                        styleOverrides:{

                        }
                    },MuiLink:{
                        styleOverrides:{
                            root: {
                                [theme.breakpoints.between("xs", "md")]: {
                                    fontSize: '0.8rem',
                                }, // Make link inherit the global font size
                            },
                        }
                    },
                    MuiTableCell:{
                        styleOverrides: {
                            root:{
                                [theme.breakpoints.between("xs", "md")]: {
                                    padding: '4px',
                                    fontSize: '0.8rem',
                                },
                        }
                        }
                    }
                ,
                MuiButton: {
                    defaultProps: {
                        variant: 'outlined',
                        size: 'small'
                    }
                },
                MuiIconButton:{
                    defaultProps: {
                        size: 'small'
                    },
                    styleOverrides: {
                        root: {padding:'2px'}
                    }

                },
                MuiTypography:{
                    styleOverrides:{
                        root:{
                            [theme.breakpoints.between("xs", "md")]: {
                                fontSize: '0.8rem',
                            },
                        }
                    }
                },
                MuiTextField:{
                    defaultProps:{
                        size: 'small'
                    },
                    styleOverrides:{
                        root:{
                            [theme.breakpoints.between("xs", "md")]: {
                            fontSize: '0.8rem',
                        }},
                    }
                },MuiChip:{
                    styleOverrides:{
                        root:{
                            [theme.breakpoints.between("xs", "md")]: {
                                fontSize: '0.8rem',

                            }
                        }
                    }
                },
                MuiCard: {
                    defaultProps: {
                        raised: false
                    },
                    styleOverrides: {
                        root:{
                            sx:{paddingLine:1},
                            md:{paddingLine:0},
                            [theme.breakpoints.between("xs", "md")]: {
                                fontSize: '0.8rem',
                            },
                        }
                    }
                },
                MuiListItemIcon:{
                    styleOverrides:{
                        root:{
                            minWidth: 'fit-content',
                            [theme.breakpoints.between("xs", "md")]: {
                                fontSize: '0.8rem',
                            },
                        }
                    }
                },
                MuiButtonBase:{
                    styleOverrides:{
                        root:{
                            [theme.breakpoints.between("xs", "md")]: {
                                padding: '4px',
                            }
                        }
                    }
                },
                MuiCardHeader: {
                    defaultProps:{
                        titleTypographyProps: {
                            variant: 'button'
                        }},
                        styleOverrides: {
                            root: {
                            marginBottom: 10,
                            background: theme.palette.background.header,
                            [theme.breakpoints.between("xs", "md")]: {
                                padding: '9px 12px',
                                fontSize:'0.8rem'
                            }
                        },
                        action:{
                            display:'flex',
                            gap:1,
                            flexDirection:'row',
                            justifyContent:'end',
                            flex: '1 1 auto',
                        }
                    }
                },MuiOutlinedInput:{
                    styleOverrides:{
                        root: {
                            [theme.breakpoints.between("xs", "md")]: {
                                fontSize:'0.8rem !important',
                            }
                        }
                    }
                },
                MuiCardContent:{
                    styleOverrides:{
                        root: {
                            [theme.breakpoints.between("xs", "md")]: {
                                padding: '8px',
                            }
                        }
                    }
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            padding: 8,
                            [theme.breakpoints.between("xs", "md")]: {
                                padding: 0,
                                fontSize: '0.8rem',
                            },
                        }
                    }
                }
        },

    } );
    return responsiveFontSizes(theme, {
        breakpoints: ['md'],
        factor: 2, // This determines how much the font size should grow on smaller screens
    });
}
export function createGlobalStyles(theme: Theme): any {
    return {
        'html, body, #app, main': {
            height: '100%',
            overflow: 'auto',
        }
    }
}
