/** @format */

import React, { Component, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { styled } from '@mui/system';
import Search from './Search';
import CommonUtil from 'util/CommonUtil';
import InflyProgress from './InflyProgress';
import { Box, Grid } from '@mui/material';
import withScreenSize from 'containers/HOC/WithScreenSize';
import { ScreenSizeProp } from 'util/commonProps';

interface Props extends ScreenSizeProp {
    columns: any;
    columnNameForSearch?: string;
    title: string;
    rows: any;
    width?: string;
    tableheight?: string;
    pageSize: number;
    multipleSelection: boolean;
    hideCheckboxUI?: true;
    caption?: any;
    hoverCursor?: boolean;
    onPageSizeChange?: (value: number) => void;

    // this returns the final state, rowId will contain list of rows that ARE selected, and action is the what triggered this change
    onSelectionChange?: (rowId: string[] | string, action: boolean, selectedRowId: any) => void;
    orderBy?: string;
    order?: 'asc' | 'desc';
}

function descendingComparator<T>(a: T | any, b: T | any, orderBy: string) {
    if (typeof b[orderBy] === 'object' || typeof a[orderBy] === 'object') {
        if (
            b[orderBy].props.children &&
            (typeof a[orderBy].props.children !== 'object' ||
                typeof b[orderBy].props.children !== 'object')
        ) {
            if (b[orderBy].props.children < a[orderBy].props.children) {
                return -1;
            }
            if (b[orderBy].props.children > a[orderBy].props.children) {
                return 1;
            }
        } else {
            return 0;
        }
    } else if (orderBy.toLocaleLowerCase().includes('date') && b[orderBy].includes('EST')) {
        return Date.parse(b[orderBy]) - Date.parse(a[orderBy]);
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator(order: string, orderBy: string) {
    return order === 'desc'
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

interface EnhancedTableHeadProps {
    numSelected: number;
    columns: any;
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    multipleSelection: boolean;
    rowCount: number;
    isMobile?: boolean;
}

interface EnhancedTableState {}
class EnhancedTableHead extends Component<EnhancedTableHeadProps, EnhancedTableState> {
    constructor(props: EnhancedTableHeadProps) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            onSelectAllClick,
            order,
            orderBy,
            numSelected,
            rowCount,
            onRequestSort,
            isMobile,
            multipleSelection,
        } = this.props;

        const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    {/* {multipleSelection && (
                        <TableCell padding='checkbox' size='small'>
                            Selection
                        </TableCell>
                    )} */}
                    {this.props.columns.map((column: any, index: number) => {
                        if (column.hide) {
                            return (
                                <td
                                    key={`${index}_${this.props.rowCount}-hidden`}
                                    style={{ display: 'none' }}
                                />
                            );
                        }

                        return (
                            <TableCell
                                size={'small'}
                                key={`${index}_${this.props.rowCount}`}
                                align={index === 0 ? 'center' : column.numeric ? 'right' : 'left'}
                                sortDirection={orderBy === column.field ? order : false}
                            >
                                <TableSortLabel
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            m: { xs: 0, lg: 1 },
                                        },
                                    }}
                                    disabled={column?.sort === false}
                                    active={orderBy === column.field}
                                    direction={orderBy === column.field ? order : 'asc'}
                                    onClick={createSortHandler(column.field)}
                                >
                                    {column.headerName}
                                </TableSortLabel>
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
        );
    }
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    selected: {
        backgroundColor: `${theme.palette.info.light} !important`,
    },
}));

function InflyTable(props: Props) {
    const { rows, columns, pageSize, columnNameForSearch, multipleSelection } = props;
    const [searchText, setSearchText] = React.useState('');
    const [filteredRows, setFilteredRows] = React.useState<any>([]);
    const [tableHeight, setTableHeight] = React.useState(props.tableheight ?? '400px');
    const [order, setOrder] = React.useState<Order>(props?.order ?? 'asc');
    const [orderBy, setOrderBy] = React.useState<any>(props?.orderBy ?? 'id');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);
    const [isFullscreenEnabled, setIsFullscreenEnabled] = React.useState(false);

    useEffect(() => {
        const filterRows = () => {
            if (columnNameForSearch) {
                if (rows) {
                    const filteredRows = rows.slice()?.filter((row: any) => {
                        if (row) {
                            if (typeof row[columnNameForSearch] === 'string') {
                                return row[columnNameForSearch]
                                    ?.toLowerCase()
                                    .includes(searchText.toLowerCase());
                            }
                            if (typeof row[columnNameForSearch] === 'object') {
                                return row[columnNameForSearch]?.props?.children
                                    ?.toLowerCase()
                                    ?.includes(searchText.toLowerCase());
                            } else {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    });
                    setFilteredRows(filteredRows);
                }
            } else {
                setFilteredRows(rows);
            }
        };

        if (isFullscreenEnabled) {
            setRowsPerPage(20);
        } else {
            setRowsPerPage(pageSize);
        }

        filterRows();
    }, [searchText, rows, isFullscreenEnabled]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        event.preventDefault();
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rowIds: string[] = rows.map((n: any) => n.id.toString());
        const isSelectOp: boolean = event.target.checked;
        if (isSelectOp) {
            setSelected(() => [...rowIds]);
        } else {
            setSelected(() => []);
        }
        // the setSelected was delaying the updated, current changed were seen in next new updated request.
        // if (typeof props.onSelectionChange === 'function'){
        //         props.onSelectionChange(selected, isSelectOp);
        // }
    };

    const handleClick = (event: React.MouseEvent<unknown>, rowId: string) => {
        const selectedIndex = selected.indexOf(rowId);
        let mutateSelection: string | React.SetStateAction<string[]> = [...selected];
        if (!multipleSelection && selected[0] !== rowId) {
            mutateSelection = [rowId];
        }

        // UNSELECT OPERATION
        if (selected.includes(rowId)) {
            mutateSelection = CommonUtil.removeFromArray(selected, rowId);
        }
        if (selectedIndex === -1) {
            //it a SELECT operation
            mutateSelection.push(rowId);
        }
        setSelected(mutateSelection);

        if (typeof props.onSelectionChange === 'function') {
            props.onSelectionChange(mutateSelection, selectedIndex === -1, rowId);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        if (props.onPageSizeChange) {
            props.onPageSizeChange(parseInt(event.target.value, 10));
        }
        setPage(0);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    // Format cell data using custom formatting function (if provided)
    const formatCellData = (cellData: any, formatFunction: (arg0: any) => any) => {
        return formatFunction ? formatFunction(cellData) : cellData;
    };

    let tableRows: any;

    if (rows === undefined || filteredRows === undefined) {
        tableRows = (
            <StyledTableRow>
                <TableCell
                    key={`${props.title}_loading`}
                    align='center'
                    colSpan={props.columns.length}
                    padding='checkbox'
                >
                    <InflyProgress /> &nbsp;Loading...
                </TableCell>
            </StyledTableRow>
        );
    } else if (rows.length === 0 || filteredRows.length === 0) {
        tableRows = (
            <StyledTableRow>
                <TableCell align='center' colSpan={props.columns.length} padding='checkbox'>
                    {`There are no ${props.title} to display`}
                </TableCell>
            </StyledTableRow>
        );
    } else {
        tableRows = filteredRows
            ?.sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: any, index: number) => {
                if (row) {
                    const isItemSelected = isSelected(row.id.toString());
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                        <StyledTableRow
                            hover
                            onClick={(event: any) => handleClick(event, row.id.toString())}
                            role='checkbox'
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={`${index}_row`}
                            selected={isItemSelected}
                        >
                            {/* {multipleSelection && (
                                <TableCell padding='checkbox'>
                                    <Checkbox
                                        checked={isItemSelected}
                                        color={'primary'}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </TableCell>
                            )} */}

                            {columns.map((column: any, idx: number) => {
                                const { hide, ...rest } = column;
                                if (!hide) {
                                    return (
                                        <TableCell key={idx + '_cell'}>
                                            {formatCellData(
                                                row[column.field],
                                                column.formatFunction
                                            )}
                                        </TableCell>
                                    );
                                }
                            })}
                        </StyledTableRow>
                    );
                }
            });
    }

    return (
        <>
            <Box component={'div'} id={props.title + 'table'} overflow={'auto'}>
                <Grid
                    container
                    justifyContent={'flex-end'}
                    spacing={1}
                    alignContent={'center'}
                    alignItems={{ md: 'center', xs: 'stretch' }}
                >
                    {rows && rows.length > 0 && typeof columnNameForSearch === 'string' && (
                        <Grid item xs>
                            <Search
                                value={searchText}
                                placeholder={`search ${columnNameForSearch}...`}
                                inputProps={{ 'aria-label': `search ${columnNameForSearch} ` }}
                                onChange={(event: any) => setSearchText(event.target.value)}
                            />
                        </Grid>
                    )}
                </Grid>
                <TableContainer sx={{ padding: 1, borderRadius: 5 }}>
                    <Table
                        sx={{
                            fontSize: '0.8rem !important',
                            borderRadius: 5,
                        }}
                        aria-labelledby='tableTitle'
                        size={'small'}
                        aria-label='enhanced table'
                    >
                        <caption>{props.caption}</caption>
                        <EnhancedTableHead
                            isMobile={props.isMobile}
                            multipleSelection={multipleSelection}
                            numSelected={selected.length}
                            order={order}
                            columns={columns}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows === undefined ? 0 : rows.length}
                        />
                        <TableBody>{tableRows}</TableBody>
                    </Table>
                </TableContainer>

                {rows !== undefined && rows.length > rowsPerPage && (
                    <TablePagination
                        rowsPerPageOptions={
                            rows.length === 0
                                ? [0]
                                : isFullscreenEnabled
                                ? [25]
                                : [5, 10, 15, 20, 25]
                        }
                        component='div'
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Box>
        </>
    );
}
export default withScreenSize(InflyTable);
