/** @format */

import React, { Component, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import {
    Button,
    CardContent,
    CardHeader,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    TextField,
} from '@mui/material';
import {
    Message,
    MessageType,
    OPERATION,
    Security,
    SecurityType,
    StockAlert,
    StockAlertAPIClient,
    StockAlertSpec,
    TriggerStatus,
    UserError,
} from 'api';
import { CreateUpdateMode } from 'util/CommonUtil';
import { AppContext } from 'util/appContext';
import InflyDialog from 'containers/common/InflyDialog';
import { InflyMessage } from 'containers/common/InflyMessage';
import Switch from '@mui/material/Switch';
import withScreenSize from 'containers/HOC/WithScreenSize';
import {SelectField} from 'containers/common/SelectField';
import SampleAlertComponent from './SampleAlertComponent';
import CustomListSymbolsSelect from 'containers/Trigger/CustomListSymbolsSelect';
import { MessageListenerProp, ScreenSizeProp } from 'util/commonProps';
import { TriggeredStock } from 'api';
import { SecurityUniverseSelector, SecurityUniverseType, StandardSymbolsList } from 'api/models/trigger/SecurityUniverseSelector';
import { SecurityUniverseSelectorComp } from 'containers/Trigger/SecurityUniverseSelectorComp';
import { SecurityFilterExpressionComp } from 'containers/Trigger/SecurityFilterExpressionComp';
import { SecurityTriggerExpression } from 'api/models/trigger/SecurityTriggerExpression';
import { ITextField } from 'containers/common/ITextField';
import UpgradeCheckButton from 'containers/common/UpgradeCheckButton';
import { useFetcher } from 'react-router-dom';


interface NewAlertBtnProps {
    curAlertCount?: number
    onComplete: (stockAlert: StockAlert, message?: Message) => void;
    security?: Security; // to prefill security when launched from stock details page
}


export function NewAlertBtn({curAlertCount, security, onComplete}: NewAlertBtnProps): React.JSX.Element {
    const [openDialog, setOpenDialog]  = useState(false);
    const [alertCount, setAlertCount] = useState<number>();

    const onSubmit = (stockAlert: StockAlert, message?: Message) => {
        setOpenDialog(false);
        onComplete(stockAlert, message);
    }

    async function loadAlerts(){
        let stockAlertApiClient = AppContext.getInstance().stockAlertApiClient;
        try{
            let myAlerts = await stockAlertApiClient.listMyAlerts();
            setAlertCount(myAlerts.length);
        }catch(error){
           console.log(error);
        }
    }

    useEffect(() => {
        if(curAlertCount === undefined){
            // load added here so that from stock summary page which has set alert button for stock does not need to list all alerts
            loadAlerts()
        }else{
            // this was also needed so that the button could toggle from upgrade button to normal button when the alert was deleted
            // in the parent component and this component is notfied to refresh
            setAlertCount(curAlertCount);
        }

    }, [curAlertCount])
    
    return <>
        
        {openDialog && <AlertFormComp security={security} onSubmit={onSubmit} onCancel={() => setOpenDialog(false)}/>}

        <UpgradeCheckButton op={OPERATION.CREATE_ALERT} currentCount={alertCount} label='New Alert'>
            <Button onClick={() => setOpenDialog(true)}>New Alert</Button>
        </UpgradeCheckButton>
        

    </>
}

interface AlertFormProps  {
    stockAlert?: StockAlert; // not StockAlertSpec because for edit, we need alertId
    security?: Security; // to prefill security when launched from stock details page
    onSubmit: (stockAlert: StockAlert, message?: Message) => void;
    onCancel: () => void;
}


export function AlertFormComp({ stockAlert, security, onSubmit, onCancel }: AlertFormProps): React.JSX.Element {
    const getDefaultBasedOnProps = (): StockAlertSpec => {
        if(stockAlert != undefined){
            return stockAlert.clone();
        }
        if(security){
            return StockAlertSpec.singleSecurity(security);
        }
        return StockAlertSpec.createDefault()

    }

    const [message, setMessage] = useState<Message | null>(null);
    const [editAlert, setEditAlert] = useState(getDefaultBasedOnProps());
    const createUpdateMode = stockAlert ? CreateUpdateMode.UPDATE : CreateUpdateMode.CREATE;



    useEffect(() => {
        setEditAlert(getDefaultBasedOnProps());
    }, [stockAlert])

    const onDialogSubmit = async () => {
        let messages: string[] = editAlert.validate();
        if (messages.length == 0) {
            let stockAlertApiClient: StockAlertAPIClient = AppContext.getInstance().stockAlertApiClient;
            try {
                if (createUpdateMode === CreateUpdateMode.CREATE) {
                    let createdAlert: StockAlert = await stockAlertApiClient.createStockAlert(editAlert);
                    onSubmit(createdAlert, Message.success("Successfully created Alert"))
                } else {
                    let updatedAlert: StockAlert = await stockAlertApiClient.updateStockAlert(stockAlert!.alertId, editAlert);
                    onSubmit(updatedAlert, Message.success("Successfully updated Alert"))
                }
            } catch (error) {
                let message = Message.fromError(error);
                setMessage(message);
            }
        } else {
            setMessage(Message.error(messages.join(",")));
        }
    }

    const updateAlertName = (alertName: string) => {
        editAlert.alertName = alertName;
        setEditAlert(editAlert.clone());
    }

    const updateAlertScope = (alertScope: SecurityUniverseSelector) => {
        editAlert.triggerScope = alertScope;
        setEditAlert(editAlert.clone());
    }

    const updateAlertTrigger = (securityTrigger?: SecurityTriggerExpression) => {
        editAlert.trigger = securityTrigger!;
        setEditAlert(editAlert.clone());
    }




    return (
        <>
            <InflyDialog
                id='edit-alert'
                open={true}
                maxWidth={"lg"}
                title="Alert Form"
                onClose={onCancel}
                okText={createUpdateMode === CreateUpdateMode.CREATE ? 'Create' : 'Update'}
                onExecute={onDialogSubmit}
            >
                <DialogContent>
                    <InflyMessage message={message} onClose={() => setMessage(null)} />

                    <Card>
                        <CardHeader  title='Alert Setting'  titleTypographyProps={{ variant: 'subtitle1' }}  />

                        <CardContent>

                            <Stack spacing={2}>

                                <ITextField label="Alert Name"  id={'alertName'}  value={editAlert.alertName} onChange={updateAlertName}  />

                                <Divider/>

                                <SecurityUniverseSelectorComp securityUniverseSelector={editAlert.triggerScope} onChange={updateAlertScope} />

                                <Divider/>

                                <SecurityFilterExpressionComp required securityFilterExpression={editAlert.trigger} onChange={updateAlertTrigger} />

                            </Stack>


                        </CardContent>
                    </Card>

                </DialogContent>
            </InflyDialog>
        </>
    );

}


export default withScreenSize(AlertFormComp);
