import { Validator } from "../../utils/Validator";
import { InvestflyModel } from "../InvestflyModel";
import {OptionExpiry} from "./optionExpiry";
import {OptionQuote} from "./quote";
import {FutureProduct} from './futureProduct';
import {FutureQuote, Month} from './quote';
import CommonUtil from "util/CommonUtil";



export enum SecurityType {
    STOCK = "STOCK",
    ETF = "ETF",
    OPTION = "OPTION",
    FUTURE = "FUTURE",
    INDEX = "INDEX"
}

export class Security extends InvestflyModel {

    symbol: string;
    securityType: SecurityType;

    static readonly OPTION_PATTERN: string = "(.+)([\\d]{6})([C|P])([\\d]{8})";

    static getInstance(symbol: string, securityType: SecurityType): Security {
        const security = new Security();
        security.symbol = symbol;
        security.securityType = securityType;
        return security;
    }

    static isOptionSymbol(symbol: string): boolean {
        if(!symbol) return false;
        if(symbol.length < 15) return false;//at least 15 characters
        if(symbol.match(Security.OPTION_PATTERN)) return true;
        return false;
    }

    static parseJSON(obj: object): Security {
        let security: Security = new Security();
        Object.assign(security, obj);
        if(security.securityType === SecurityType.STOCK || security.securityType === SecurityType.ETF || security.securityType == SecurityType.INDEX){
            return Stock.parseJSON(obj);
        }else if(security.securityType === SecurityType.OPTION){
            return StockOption.parseJSON(obj);
        }else if(security.securityType === SecurityType.FUTURE){
            return Future.parseJSON(obj);
        }
        return security;
    }

    static parseJSONArray(secObjArray: object[]): Security[] {
        let securityArray: Security[] = new Array<Security>();
        for(let secObj of secObjArray){
            securityArray.push(Security.parseJSON(secObj));
        }
        return securityArray;
    }

    isStockOrETF(): boolean{
        return this.securityType === SecurityType.STOCK || this.securityType === SecurityType.ETF;
    }

    validate(){
        Validator.validateNotNull("SecuritySymbol", this.symbol);
        Validator.validateNotNull("SecurityType", this.securityType);
    }
}

export class Stock extends Security {

    static getInstance(symbol: string){
        let stock = new Stock();
        stock.symbol = symbol;
        stock.securityType = SecurityType.STOCK;
        return stock;
    }

    static parseJSON(obj: object): Stock {
        let stock = new Stock();
        Object.assign(stock, obj);
        return stock;
    }
}

export enum OptionType {
    CALL = "CALL", PUT = "PUT"
}

export class StockOption extends Security {
    expiry: OptionExpiry;
    strikePrice: number;
    optionType: OptionType;
    stock: string;

    constructor() {
        super();
        this.securityType = SecurityType.OPTION;
    }

    static createStockOption(optionQuote: OptionQuote): StockOption {
        const stockOption: StockOption = new StockOption();
        stockOption.expiry = optionQuote.expiry;
        stockOption.strikePrice = optionQuote.strikePrice;
        stockOption.optionType = optionQuote.optionType;
        stockOption.stock = optionQuote.stock;
        stockOption.symbol = optionQuote.symbol;
        return stockOption;
    }

    static parseJSON(obj: {[index:string]: any}): StockOption {
        let stockOption = new StockOption();
        Object.assign(stockOption, obj);
        stockOption.expiry = OptionExpiry.parseJSON(obj.expiry);
        return stockOption;
    }
}

export class SecurityPriceChange {
    symbol: string;
    lastPrice: number;
    percentChange: number;

    static getInstance(symbol: string, lastPrice: number, percentChange: number): SecurityPriceChange{
        const securityPriceChange = new SecurityPriceChange();
        securityPriceChange.symbol = symbol;
        securityPriceChange.lastPrice = lastPrice;
        securityPriceChange.percentChange = percentChange;
        return securityPriceChange;
    }

    static parseJSON(obj: {[index:string]: any} ): SecurityPriceChange {
        const securityPriceChange = new SecurityPriceChange();
        Object.assign(securityPriceChange, obj);
        securityPriceChange.lastPrice = CommonUtil.roundToTwoDecimal(securityPriceChange.lastPrice);
        securityPriceChange.percentChange = CommonUtil.roundToTwoDecimal(securityPriceChange.percentChange);
        return securityPriceChange;
    }
}




export class Future extends Security {
    productCode: FutureProduct;
    month: Month;
    year: number;

    constructor() {
        super();
        this.securityType = SecurityType.FUTURE;
    }

    static createFuture(quote: FutureQuote): Future {
        const future: Future = new Future();
        future.symbol = quote.symbol;
        future.productCode = quote.productCode;
        future.month = quote.month;
        future.year = quote.year;

        return future;
    }

    static parseJSON(obj: {[index:string]: any} ): Future {
        let future = new Future();
        Object.assign(future, obj);
        return future;
    }
}

export class SymbolSummaryInfo {
    symbol: string;
	type: SecurityType;
	name: string;
	industry: string;

    static parseJSON(obj: {[index:string]: any} ): SymbolSummaryInfo {
        let tickerSymbol = new SymbolSummaryInfo();
        Object.assign(tickerSymbol, obj);
        return tickerSymbol;
    }

    toStock(): Stock {
        let stock = new Stock();
        stock.securityType = this.type;
        stock.symbol = this.symbol;
        return stock;
    }

    
}