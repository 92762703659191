/** @format */

import React, { Component } from 'react';
import { Message, MessageType, UserAPIClient, UserMessage } from 'api';
import { AppContext } from 'util/appContext';
import CommonUtil from 'util/CommonUtil';
import { InflyMessage } from 'containers/common/InflyMessage';
import { ButtonProps, CardHeader, TextField } from '@mui/material';
import InflyDialog from 'containers/common/InflyDialog';
import Button from '@mui/material/Button';
import SessionUtil from '../../util/SessionUtil';

interface FeedbackProps extends ButtonProps {
    purpose: 'feedback' | 'contact';
    onSubmitComplete: (proceed: boolean) => void;
    onCancel?: () => void;
    dialogOkText: string;
}

export class Feedback extends Component<
    FeedbackProps,
    { userMessage: UserMessage; feedbackDialog: boolean; message?: Message }
> {
    userApiClient: UserAPIClient;

    constructor(props: FeedbackProps) {
        super(props);
        this.userApiClient = AppContext.getInstance().userApiClient;
        const user = SessionUtil.findUserInfo();
        const userMessage = UserMessage.parseJSON({
            fullName: user!.firstName + ' ' + user!.lastName,
            fromEmail: user!.email,
            subject: '',
            message: '',
        });
        this.state = {
            userMessage: userMessage,
            feedbackDialog: false,
        };
    }

    setMessage = (message?: Message) => {
        this.setState((prevState) => ({ ...prevState, message }));
    };

    setFeedbackDialog(value: boolean) {
        this.setState((prevState) => ({ ...prevState, feedbackDialog: value }));
    }

    setFeedback = (event: any) => {
        const { value } = event.target;
        const userMessage = this.state.userMessage;
        userMessage.message = value;
        this.setState((prevState) => ({ ...prevState, userMessage }));
    };

    sendFeedbackAndProceed = async () => {
        const res = CommonUtil.validateMessage(this.state.userMessage.message);
        if (typeof res === 'object') {
            return Promise.resolve(this.setState((prevState) => ({ ...prevState, message: res })));
        }
        if (typeof res === 'boolean') {
            const userMessage = this.state.userMessage;
            userMessage.subject = 'User' + this.props.title + 'Feedback';
            let resData: Message | undefined = Message.success(
                'Thank you for your' + this.props.purpose === 'contact'
                    ? 'contacting us'
                    : 'feedback!'
            );
            try {
                resData = await this.userApiClient.feedback(userMessage);
            } catch (error) {
                resData = Message.fromError(error);
            } finally {
                if (resData?.type !== MessageType.ERROR) {
                    this.setFeedbackDialog(false);
                    return this.props.onSubmitComplete(res);
                } else {
                    this.setState({ message: resData });
                }
            }
        }
    };

    render() {
        const { userMessage, message, feedbackDialog } = this.state;
        const { onCancel, onSubmitComplete, dialogOkText, title, children, ...buttonProps } =
            this.props;

        return (
            <>
                <InflyDialog
                    title={this.props.purpose === 'contact' ? 'Contact Us' : 'Feedback'}
                    id='confirmDeactivateAccountDialog'
                    okText={'Send & ' + dialogOkText}
                    open={feedbackDialog}
                    onClose={() => this.setFeedbackDialog(false)}
                    onExecute={this.sendFeedbackAndProceed}
                >
                    {message && (
                        <InflyMessage
                            message={message}
                            onClose={() => this.setMessage(undefined)}
                        />
                    )}

                    <CardHeader
                        title={
                            this.props.purpose === 'contact'
                                ? 'How can we help you'
                                : 'Please provide feedback to help us'
                        }
                        titleTypographyProps={{ variant: 'h6' }}
                    />
                    <TextField
                        fullWidth
                        placeholder={
                            this.props.purpose === 'contact'
                                ? ''
                                : 'What new capability or service would have kept you as our valuable member?'
                        }
                        onChange={this.setFeedback}
                        multiline
                        minRows={6}
                        name={'feedback'}
                        value={userMessage.message}
                        id={'userfeedback'}
                    />
                    {/* </CardContent>
                        </Card> */}
                </InflyDialog>

                <Button
                    {...buttonProps}
                    onClick={(event: any) => {
                        event.preventDefault();
                        this.setFeedbackDialog(true);
                    }}
                >
                    {children}
                </Button>
            </>
        );
    }
}
