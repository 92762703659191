/** @format */

import {
    MarketDataAPIClient,
    Message,
    OptionQuote,
    OptionType,
    OrderDuration,
    OrderType,
    PendingOrder,
    SecurityType,
    StockOption,
    StockQuote,
    SymbolSummaryInfo,
    TradeOrder,
    TradeTrigger,
    TradeType,
} from 'api';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { Component } from 'react';
import CommonUtil from 'util/CommonUtil';
import InflyTable from 'containers/common/InflyTable';
import {SelectField} from 'containers/common/SelectField';
import { SymbolField } from 'containers/common/SymbolField';
import { ControlPoint } from '@mui/icons-material';
import { LabelAndValue } from 'containers/common/LabelAndValue';
import SampleTriggeredTradeOrdersComponent from './AutoTradeSamples';
import { TradeSharesType } from './Trade';
import { AppContext } from 'util/appContext';
import StockOptionChain from 'containers/common/StockOptionChain';
import InflyDialog from 'containers/common/InflyDialog';
import { ActionButton } from 'containers/common/ActionButton';
import A from 'containers/common/Anchor';
import { SecurityFilterExpressionComp } from 'containers/Trigger/SecurityFilterExpressionComp';
import { SecurityTriggerExpression } from 'api/models/trigger/SecurityTriggerExpression';

interface OptionTradeProps {
    tradeOrder: TradeOrder;
    presetSecurity?: SymbolSummaryInfo; // stock symbol seelcted
    onTradeOrderChange: (tradeOrder: TradeOrder) => void;
    onMessage: (message: Message) => void; // passes up message that might generate as user interacts with the form
}

export interface OptionTradeState {
    // this can be inferred from quantity vs maxamount, but when user has not set anything, quantity and amount are undefined, but the radio btn must still be selected
    showOptionChainDialog: boolean;
    tradeSharesType: TradeSharesType;
    tickerSymbol?: SymbolSummaryInfo; // stock symbol selcted
    quote?: StockQuote;
}

export default class OptionTrade extends Component<OptionTradeProps, OptionTradeState> {
    marketDataApiClient: MarketDataAPIClient;

    constructor(props: OptionTradeProps) {
        super(props);
        this.marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        this.state = {
            tradeSharesType: TradeSharesType.NO_OF_SHARES,
            showOptionChainDialog: false,
        };
    }

    async componentDidMount() {
        let { tickerSymbol, quote } = this.state;

        const stockOption = this.getStockOption();
        if (this.isEditPendingOrder() && stockOption !== undefined) {
            console.log('stockOption.stock', stockOption.stock);
            tickerSymbol = await this.marketDataApiClient.getSecuritySummary(stockOption.stock);
        }
        if (this.props.presetSecurity?.symbol) {
            console.log('presetSecurity', this.props.presetSecurity.symbol);
            tickerSymbol = this.props.presetSecurity;
        }

        if (tickerSymbol) {
            quote = await this.marketDataApiClient.getStockQuote(tickerSymbol.symbol);
        }
        this.setState({ tickerSymbol, quote });
    }

    updateTradeOrder = (updater: (tradeOrder: TradeOrder) => void) => {
        const tradeOrder: TradeOrder = this.props.tradeOrder;
        updater(tradeOrder);
        this.props.onTradeOrderChange(tradeOrder);
    };

    onSymbolSelect = async (tickerSymbol: SymbolSummaryInfo) => {
        const quote = await this.marketDataApiClient.getStockQuote(tickerSymbol.symbol);
        this.setState({ quote: quote, tickerSymbol: tickerSymbol, showOptionChainDialog: true });
    };
    onSymbolClear = () => {
        this.setState({
            quote: undefined,
        });
    };

    onExpressionChange = (expression?: SecurityTriggerExpression) => {
        this.updateTradeOrder( (tradeOrder: TradeOrder) => {
            if(tradeOrder.securityTrigger === undefined){
                tradeOrder.securityTrigger = new TradeTrigger();
            }
            tradeOrder.securityTrigger.trigger = expression!;
        })
    }

    onOptionSelect = (optionQuote: OptionQuote) => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            const stockOption: StockOption = StockOption.createStockOption(optionQuote);
            tradeOrder.security = stockOption;
            this.setState({ showOptionChainDialog: false });
        });
        CommonUtil.scrollToElement('TopTrade', 'smooth');
    };

    setQuantityShares = (quantity: number) => {
        if (quantity === 0) quantity = NaN;
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.quantity = quantity;
        });
    };

    setTradeAmount = (amount: number) => {
        if (amount === 0) amount = NaN;
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.maxAmount = amount;
        });
    };

    setTradeType = (tradeType: TradeType) => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.tradeType = tradeType;
        });
    };

    setOrderDuration = (orderDuration: OrderDuration) => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.orderDuration = orderDuration;
        });
    };

    setOrderType = (orderType: OrderType) => {
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.orderType = orderType;
        });
    };

    setStopPrice = (stopPrice: number) => {
        if (stopPrice === 0) stopPrice = NaN;
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.stopPrice = stopPrice;
        });
    };

    setLimitPrice = (limitPrice: number) => {
        if (limitPrice === 0) limitPrice = NaN;
        this.updateTradeOrder((tradeOrder: TradeOrder) => {
            tradeOrder.limitPrice = limitPrice;
        });
    };


    isEditPendingOrder = (): boolean => {
        return (
            this.props.tradeOrder instanceof PendingOrder &&
            this.props.tradeOrder.orderId !== undefined
        );
    };
    getStockOption = (): StockOption | undefined => {
        const stockOption = this.props.tradeOrder.security;
        if (stockOption !== undefined) return stockOption as StockOption;
        return undefined;
    };

    isSampleCarouselOpen = (): boolean => {
        return (
            !this.isEditPendingOrder() &&
            this.props.tradeOrder.orderType === OrderType.CUSTOM_CONDITION &&
            this.props.tradeOrder.securityTrigger?.trigger === undefined
        );
    };

    isDisplayExpressionBuilder = (): boolean => {
        return (
            this.props.tradeOrder.orderType === OrderType.CUSTOM_CONDITION &&
            this.props.tradeOrder.securityTrigger?.trigger !== undefined
        );
    };

    render() {
        const isEditPendingOrder = this.isEditPendingOrder();
        const stockOption: StockOption | undefined = this.getStockOption();
        const tradeOrder = this.props.tradeOrder;
        console.log({ isEditPendingOrder });
        const tradeTypeOptions = Object.keys(TradeType).map((t) => {
            return { label: t.replace('_', ' '), value: t.toString() };
        });

        const orderTypeOptions = Object.keys(OrderType).map((o) => {
            return { label: o.replace('_', ' '), value: o.toString() };
        });
        console.log(this.state.tickerSymbol);
        return (
            <Box component={'form'} width={'100%'} pt={2}>
                <Grid
                    container
                    spacing={1}
                    direction='column'
                    justifyContent={'flex-start'}
                    flexWrap='nowrap'
                >
                    <Grid
                        item
                        xs={12}
                        container
                        direction={{ md: 'row', xs: 'column-reverse' }}
                        spacing={1}
                        justifyContent={'space-between'}
                    >
                        <Grid
                            item
                            md
                            container
                            spacing={2}
                            alignItems={{ xs: 'stretch', md: 'center' }}
                        >
                            {/* {!isEditPendingOrder && ( */}
                            <>
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>Symbol</FormLabel>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Stack
                                        gap={1}
                                        justifyContent={'space-between'}
                                        alignItems={{ xs: 'stretch', md: 'center' }}
                                        flexDirection={{ md: 'row', xs: 'column' }}
                                    >
                                        <SymbolField
                                            disabled={isEditPendingOrder}
                                            includeIndex={false}
                                            selectedSymbol={this.state.tickerSymbol}
                                            onChange={this.onSymbolSelect}
                                            onClear={this.onSymbolClear}
                                        />
                                    </Stack>
                                </Grid>
                            </>
                            {/* )} */}
                            {this.props.presetSecurity && (
                                <Grid item md={2}>
                                    <Button
                                        onClick={() =>
                                            this.setState({
                                                showOptionChainDialog: true,
                                            })
                                        }
                                    >
                                        open option chain
                                    </Button>{' '}
                                </Grid>
                            )}
                            {stockOption && (
                                <Grid item md={6} display={{ md: 'none', xs: 'block' }}>
                                    <Card>
                                        <CardContent>
                                            <Stack
                                                flexDirection={'column'}
                                                gap={1}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                            >
                                                <LabelAndValue
                                                    label={'Stock Option'}
                                                    value={stockOption.symbol ?? 'N/A'}
                                                />
                                                <LabelAndValue
                                                    label={'Option Type'}
                                                    value={stockOption?.optionType ?? 'N/A'}
                                                />
                                                <LabelAndValue
                                                    label={'Strike Price'}
                                                    value={stockOption?.strikePrice ?? 'N/A'}
                                                />
                                                <LabelAndValue
                                                    label={'Expiry'}
                                                    value={stockOption?.expiry?.toString() ?? 'N/A'}
                                                />
                                            </Stack>
                                            <br />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}

                            <Grid
                                item
                                container
                                spacing={2}
                                justifyContent={'space-between'}
                                alignItems='center'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>Quantity</FormLabel>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <TextField
                                        id='tradeSharesTxtField'
                                        fullWidth
                                        type={'number'}
                                        size={'small'}
                                        placeholder={'Enter Trade Quantity'}
                                        value={tradeOrder?.quantity?.toString() ?? ''}
                                        onChange={(event: any) => {
                                            this.setQuantityShares(Number(event.target.value));
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                container
                                spacing={1}
                                justifyContent={'space-between'}
                                alignItems='center'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>Trade Type</FormLabel>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <SelectField
                                        label={'Trade'}
                                        id='tradeTypeOptions'
                                        fullWidth
                                        value={tradeOrder.tradeType}
                                        onChange={(event: any) => {
                                            this.setTradeType(TradeType[event.target.value]);
                                        }}
                                        key='tradeTypeOptions_select'
                                        options={tradeTypeOptions}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container alignItems='center'>
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>Order Duration</FormLabel>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormControl component='fieldset'>
                                        <RadioGroup
                                            row
                                            value={tradeOrder?.orderDuration}
                                            onChange={(event: any) => {
                                                this.setOrderDuration(
                                                    OrderDuration[event.target.value]
                                                );
                                            }}
                                        >
                                            <FormControlLabel
                                                value={OrderDuration.GTC}
                                                control={<Radio id='gtcOrderRadioBtn' />}
                                                label='Good Till Cancelled'
                                                labelPlacement='end'
                                            />

                                            <FormControlLabel
                                                value={OrderDuration.DAY}
                                                control={<Radio id='dayOrderRadioBtn' />}
                                                label='Day'
                                                labelPlacement='end'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                spacing={1}
                                justifyContent={'space-between'}
                                alignItems='center'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormLabel component='legend'>Order&nbsp;Type</FormLabel>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <SelectField
                                        label={'Order'}
                                        id='orderTypeOptions'
                                        fullWidth
                                        value={tradeOrder.orderType}
                                        onChange={(event: any) => {
                                            this.setOrderType(OrderType[event.target.value]);
                                        }}
                                        key='orderTypeOptions_select'
                                        options={orderTypeOptions}
                                    />
                                </Grid>
                            </Grid>

                            {(tradeOrder.orderType === OrderType.STOP_ORDER ||
                                tradeOrder.orderType === OrderType.ONE_CANCEL_OTHER) && (
                                <Grid
                                    item
                                    container
                                    spacing={1}
                                    justifyContent={'space-between'}
                                    alignItems='center'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormLabel component='legend'>Stop Price</FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <FormControl component='fieldset' fullWidth>
                                            <TextField
                                                type={'number'}
                                                id='stopPriceTxtField'
                                                placeholder='E.g: 15.0'
                                                size={'small'}
                                                fullWidth
                                                value={tradeOrder?.stopPrice?.toString()}
                                                onChange={(event: any) => {
                                                    this.setStopPrice(Number(event.target.value));
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}

                            {(tradeOrder.orderType === OrderType.LIMIT_ORDER ||
                                tradeOrder.orderType === OrderType.ONE_CANCEL_OTHER) && (
                                <Grid
                                    item
                                    container
                                    spacing={1}
                                    justifyContent={'space-between'}
                                    alignItems='center'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormLabel component='legend'>Limit Price</FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <FormControl component='fieldset' fullWidth>
                                            <TextField
                                                type='number'
                                                id='limitPriceTxtField'
                                                placeholder='E.g: 20.0'
                                                size={'small'}
                                                fullWidth
                                                value={tradeOrder?.limitPrice?.toString()}
                                                onChange={(event: any) => {
                                                    this.setLimitPrice(Number(event.target.value));
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {stockOption && stockOption.symbol && (
                            <Grid item md={4}>
                                <Card>
                                    <CardContent>
                                        <Stack
                                            display={'flex'}
                                            gap={1}
                                            justifyContent={'flex-start'}
                                            alignItems={'flex-start'}
                                        >
                                            <LabelAndValue
                                                label={'Stock Option'}
                                                value={stockOption?.symbol ?? 'N/A'}
                                            />
                                            <LabelAndValue
                                                label={'Option Type'}
                                                value={stockOption?.optionType ?? 'N/A'}
                                            />
                                            <LabelAndValue
                                                label={'Strike Price'}
                                                value={stockOption?.strikePrice ?? 'N/A'}
                                            />
                                            <LabelAndValue
                                                label={'Expiry'}
                                                value={stockOption?.expiry?.toString() ?? 'N/A'}
                                            />
                                        </Stack>
                                        <br />
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}



                        {this.props.tradeOrder.orderType === OrderType.CUSTOM_CONDITION &&
                            <Grid item sm={12}>
                                <SecurityFilterExpressionComp required securityFilterExpression={this.props.tradeOrder.securityTrigger?.trigger} onChange={this.onExpressionChange}/>
                            </Grid>
                        }


                    </Grid>
                    {!isEditPendingOrder && this.state.tickerSymbol?.symbol && (
                        <InflyDialog
                            maxWidth={false}
                            open={this.state.showOptionChainDialog}
                            onClose={() => this.setState({ showOptionChainDialog: false })}
                        >
                            <StockOptionChain
                                symbol={this.state.tickerSymbol?.symbol as string}
                                onOptionQuoteSelect={this.onOptionSelect}
                            />
                        </InflyDialog>
                    )}
                </Grid>
            </Box>
        );
    }
}

interface OptionQuoteProps {
    quotes: OptionQuote[];
    optionType: OptionType;
    stockSymbol: string;
    onOptionQuoteSelect?: (optionQuote: OptionQuote) => void;
    selectionIcon?: JSX.Element;
}
interface OptionQuoteState {
    pageSize: number;
}
export class OptionQuoteTable extends Component<OptionQuoteProps, OptionQuoteState> {
    quotesMap: Map<string, OptionQuote>;

    constructor(props: OptionQuoteProps) {
        super(props);
        this.quotesMap = new Map<string, OptionQuote>();
        this.state = {
            pageSize: 10,
        };
    }

    getOptionQuoteCofDef() {
        return [
            { field: 'id', headerName: 'ID', hide: true },
            { field: 'securityType', headerName: 'Security Type', hide: true },
            {
                field: 'selection',
                headerName: 'Trade',
                formatFunction: (value: any) => (
                    <IconButton
                        onClick={() => {
                            const selectedQuote: OptionQuote = this.quotesMap.get(
                                value.toString()
                            )!;
                            if (typeof this.props.onOptionQuoteSelect === 'function')
                                this.props.onOptionQuoteSelect(selectedQuote);
                        }}
                    >
                        {this.props.selectionIcon ? (
                            <>{this.props.selectionIcon}</>
                        ) : (
                            <ControlPoint fontSize={'small'} />
                        )}
                    </IconButton>
                ),
                sort: false,

                hide: this.isSelectionColumnAvailable(),
            },
            { field: 'strikePrice', headerName: 'Strike' },
            {
                field: 'symbol',
                headerName: 'Symbol',
                formatFunction: (value: any) => (
                    <A href={`https://finance.yahoo.com/quote/${value}`} newTab={true}>
                        {value}
                    </A>
                ),
            },
            { field: 'last', headerName: 'Last' },
            { field: 'bid', headerName: 'Bid' },
            { field: 'ask', headerName: 'Ask' },
            { field: 'volume', headerName: 'Vol' },
            { field: 'openInterest', headerName: 'Open Interest' },
        ];
    }

    isSelectionColumnAvailable() {
        return this.props.onOptionQuoteSelect !== undefined ? false : true;
    }

    getOptionQuoteRowDef() {
        return this.props?.quotes?.map((q: OptionQuote) => {
            this.quotesMap.set(q.symbol, q);
            return {
                id: q.symbol,
                selection: q.symbol,
                securityType: SecurityType.OPTION,
                strikePrice: q.strikePrice,
                symbol: q.symbol,
                last: q.lastPrice,
                bid: q.bid,
                ask: q.ask,
                volume: q.volume,
                openInterest: q.openInterest,
            };
        });
    }

    render() {
        const props = this.props;
        const optionType = props.optionType;

        const stockSymbol = props.stockSymbol;

        const columns = this.getOptionQuoteCofDef();

        const rows = this.getOptionQuoteRowDef();

        return (
            <Card id={'optionChain'}>
                <CardHeader p={1} title={`${optionType} OPTION: ${stockSymbol}`} />
                <CardContent>
                    <InflyTable
                        hoverCursor={true}
                        title={'option quotes'}
                        rows={rows}
                        columns={columns}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(value: number) => this.setState({ pageSize: value })}
                        multipleSelection={false}
                    />
                </CardContent>
            </Card>
        );
    }
}
