import { InvestflyUtil } from '../../utils/investflyUtil';


export enum BarInterval {
    ONE_MINUTE = "ONE_MINUTE",
    FIVE_MINUTE = "FIVE_MINUTE",
    FIFTEEN_MINUTE = "FIFTEEN_MINUTE",
    THIRTY_MINUTE = "THIRTY_MINUTE",
    SIXTY_MINUTE = "SIXTY_MINUTE",
    ONE_DAY = "ONE_DAY"
}

export class Bar  {
    symbol: string;
    date: Date;
    open: number;
    low: number;
    high: number;
    close: number;
    volume: number;

    barInterval: BarInterval

    static parseJSON(obj: {[index:string]: any} ): Bar {
        let bar = new Bar();
        Object.assign(bar, obj);
        bar.date = InvestflyUtil.strToDate(obj.date)!;
        return bar;
    }
}