import {Message} from '../models/common/message';
import {BacktestResult} from '../models/portfolio/backtestResult';
import {RestAPIClient} from './restAPIClient';

export class BacktestAPIClient {

    private restApiClient: RestAPIClient;

    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }

    async start(strategyId: number): Promise<Message> {
        const response = await this.restApiClient.post(`/backtest/${strategyId}/start`);
        return Message.parseJSON(response);
    }

    async stop(strategyId:number): Promise<Message> {
        const response = await this.restApiClient.post(`/backtest/${strategyId}/stop`, null);
        return Message.parseJSON(response);
    }

    async getResult(strategyId:number): Promise<BacktestResult> {
        const response = await this.restApiClient.get(`/backtest/${strategyId}/result`);
        return BacktestResult.parseJSON(response);
    }


}