import {BrokerAccount} from '../models/portfolio/brokerAccount';
import {RestAPIClient} from './restAPIClient';
import {BrokerType} from '../models/portfolio/portfolio';
import {InvestflyModel} from '../models/InvestflyModel';
import { Message } from '../models/common/message';

export class BrokerAPIClient {

    private restApiClient: RestAPIClient;

    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }

    async getMyConnectedBrokers(): Promise<BrokerAccount[]> {
        const res: object[] = await this.restApiClient.get(`/broker/list`);
        return InvestflyModel.parseList(res, BrokerAccount.parseJSON);
    }

    //tradier
    async authorizeTradierAccess(authorizationCode: string): Promise<BrokerAccount[]> {
        const res: object[] = await this.restApiClient.post(`/broker/tradier/authorize?authorizationCode=${authorizationCode}`, null);
        return InvestflyModel.parseList(res, BrokerAccount.parseJSON);
    }

    async connectTradierAccount(authorizationCode: string, accountId: string): Promise<BrokerAccount> {
        const res: object = await this.restApiClient.post(`/broker/tradier/connect?authorizationCode=${authorizationCode}&accountId=${accountId}`, null);
        return BrokerAccount.parseJSON(res);
    }

    //tdameritrade
    async authorizeTDAmeritradeAccount(authorizationCode: string): Promise<BrokerAccount[]> {
        const res: object[] = await this.restApiClient.post(`/broker/tdAmeritrade/authorize?authorizationCode=${authorizationCode}`, null);
        return InvestflyModel.parseList(res, BrokerAccount.parseJSON);
    }

    async connectTDAmeritradeAccount(authorizationCode: string, accountId: string): Promise<BrokerAccount> {
        const res: object = await this.restApiClient.post(`/broker/tdAmeritrade/connect?authorizationCode=${authorizationCode}&accountId=${accountId}`, null);
        return BrokerAccount.parseJSON(res);
    }

    //trade station
    async authorizeTradeStation(authorizationCode: string): Promise<BrokerAccount[]> {
        const res: object[] = await this.restApiClient.post(`/broker/tradeStation/authorize?authorizationCode=${authorizationCode}`, null);
        return InvestflyModel.parseList(res, BrokerAccount.parseJSON);
    }

    async connectTradeStationAccount(authorizationCode: string, accountId: string): Promise<BrokerAccount> {
        const res: object = await this.restApiClient.post(`/broker/tradeStation/connect?authorizationCode=${authorizationCode}&accountId=${accountId}`, null);
        return BrokerAccount.parseJSON(res);
    }

    //etrade
    async getETradeOAuthURL(username: string): Promise<string> {
        const res: string = await this.restApiClient.post(`/broker/etrade/oAuthURL?username=${username}`, null);
        return res;
    }

    async authorizeETradeAccess(verificationCode: string, username: string): Promise<string[]> {
        const res: string[] = await this.restApiClient.post(`/broker/etrade/authorize?verificationCode=${verificationCode}&username=${username}`, null);
        return res;
    }

    async connectETradeAccount(verificationCode: string, accountId: string): Promise<string[]> {
        const res: string[] = await this.restApiClient.post(`/broker/etrade/connect?verificationCode=${verificationCode}&accountId=${accountId}`, null);
        return res;
    }

    async disconnect(brokerType: BrokerType, accountId: string): Promise<Message> {
        const res = await this.restApiClient.delete(`/broker/${brokerType}/${accountId}/disconnect`);
        return Message.parseJSON(res);
    }

}