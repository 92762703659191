/** @format */

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Grid,
    Hidden,
    Typography,
} from '@mui/material';
import { BrokerType } from 'api';
import React, { Component } from 'react';
import CommonUtil from 'util/CommonUtil';
import { BlinkTextComponent } from 'containers/common/BlinkComponet';
import { PortfolioIdentifierProps } from './PortfolioDashboard';
import { LabelAndValue } from 'containers/common/LabelAndValue';
import { SingleValudDisplayCard } from 'containers/common/SingleValueDisplayCard';



export class PortfolioBalances extends Component<PortfolioIdentifierProps, {}> {
    constructor(props: PortfolioIdentifierProps) {
        super(props);
    }

    render() {
        const overviewData = [
            {
                value: this.props.currentPortfolio.initialAmount ?? 'N/A',
                title: 'Initial Value',
                prefix: '$',
                hide: this.props.currentPortfolio.broker !== BrokerType.INVESTFLY,
            },
            {
                value: this.props.currentPortfolio.currentValue ?? 'N/A',
                title: 'Portfolio Value',
                showBlink: true,
                prefix: '$',
                hide: false,
            },
            {
                value: this.props.currentPortfolio.percentChange ?? 'N/A',
                title: 'Percentage Change',
                postfix: '%',
                showBlink: true,
                hide: this.props.currentPortfolio.broker !== BrokerType.INVESTFLY,
            },
            {
                value: this.props.currentPortfolio.investedAmount ?? 'N/A',
                title: 'Invested Amount ',
                showBlink: true,
                prefix: '$',
                hide: false,
            },
            {
                value: this.props.currentPortfolio.cashBalance ?? 'N/A',
                title: 'Available Cash',
                prefix: '$',
                hide: false,
            },
            {
                value: this.props.currentPortfolio.buyingPower ?? 'N/A',
                title: 'Buying Power',
                prefix: '$',
                hide: false,
            },
        ];
        return (
            <>
                <Hidden mdUp>
                    <Card>
                        <CardHeader title='Portfolio Balance' />
                        <CardContent>
                            <Box>
                                <Typography
                                    component='h1'
                                    sx={{ fontSize: '1.5rem !important' }}
                                    textAlign={'center'}
                                >
                                    ${this.props.currentPortfolio.currentValue}
                                </Typography>
                                <Typography
                                    variant='caption'
                                    textAlign={'center'}
                                    display='block'
                                    gutterBottom
                                >
                                    Portfolio Value
                                </Typography>
                            </Box>
                            {overviewData.map((summary, index) => {
                                if (summary.title !== 'Portfolio Value')
                                    return (
                                        <LabelAndValue
                                            label={summary.title}
                                            value={`${summary?.prefix ?? ''} ${summary.value} ${
                                                summary?.postfix ?? ''
                                            }`}
                                        />
                                    );
                            })}
                        </CardContent>
                    </Card>
                </Hidden>
                <Hidden mdDown>
                    <Grid
                        container
                        justifyContent={{ xs: 'center', md: 'space-between' }}
                        spacing={1}
                    >
                        {overviewData.map((summary) => {
                            if (!summary.hide) {
                                return (
                                    <Grid
                                        item
                                        xs={6}
                                        md={
                                            this.props.currentPortfolio.broker ===
                                            BrokerType.INVESTFLY
                                                ? 2
                                                : 3
                                        }
                                        key={summary.title}
                                    >
                                        <Box height={'100%'}>
                                            <SingleValudDisplayCard
                                                title={summary.title}
                                                prefix={summary?.prefix ?? ''}
                                                value={summary.value}
                                                showBlink={summary.showBlink ? true : undefined}
                                            />
                                        </Box>
                                    </Grid>
                                );
                            }
                        })}
                    </Grid>
                </Hidden>
            </>
        );
    }
}
