import React, {Component} from 'react';

interface Props{}
interface State{}

class InflyProgress extends Component<Props, State> {
    render() {
        return (<>&nbsp;
                <i className="fa fa-circle-notch fa-spin" aria-hidden="true"></i></>
        );
    }
}

export default InflyProgress;