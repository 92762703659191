import { UserError } from "../common/userError";
import {InvestflyModel} from "../InvestflyModel";
import {Security, Stock} from "../marketdata/security";
import { SecurityTriggerExpression } from "../trigger/SecurityTriggerExpression";
import {BrokerType} from "./portfolio";


export enum TradeType {
    BUY = "BUY",
    SELL = "SELL",
    SHORT = "SHORT",
    COVER = "COVER"
}

export enum OrderDuration {
    GTC = "GTC", DAY = "DAY"
}

export enum OrderType {
    MARKET_ORDER = "MARKET_ORDER",
    LIMIT_ORDER = "LIMIT_ORDER",
    STOP_ORDER = "STOP_ORDER",
    CUSTOM_CONDITION = "CUSTOM_CONDITION",
    ONE_CANCEL_OTHER = "ONE_CANCEL_OTHER",
}

export class TradeTrigger {
    stock?: Stock;    
    trigger: SecurityTriggerExpression;

    static parseJSON(obj: {[index:string]: any}): TradeTrigger {
        let tradeTrigger = new TradeTrigger();
        if(obj.security) tradeTrigger.stock = Stock.parseJSON(obj.security);
        tradeTrigger.trigger = SecurityTriggerExpression.parseJSON(obj.trigger);
        return tradeTrigger;
    }

}

export class TradeOrder extends InvestflyModel {
    portfolioId: string;
    brokerType: BrokerType = BrokerType.INVESTFLY;
    tradeType: TradeType = TradeType.BUY;
    security: Security;
    quantity?: number;
    maxAmount?: number;

    orderDuration: OrderDuration = OrderDuration.DAY;
    orderType: OrderType = OrderType.MARKET_ORDER;
    
    limitPrice?: number;
    stopPrice?: number;

    securityTrigger?: TradeTrigger;

    static parseJSON(obj: {[index:string]: any}): TradeOrder {
        let tradeOrder = new TradeOrder();
        Object.assign(tradeOrder, obj);
        tradeOrder.security = Security.parseJSON(obj.security);
        if(obj.securityTrigger) tradeOrder.securityTrigger = TradeTrigger.parseJSON(obj.securityTrigger);
        return tradeOrder;
    }

    validate(): void {
        if(!this.security){
            throw new UserError("Trade Security must be specified");
        }
        if(!this.maxAmount && !this.quantity){
            throw new UserError("Either No of Shares or Cash Amount must be specified");
        }
    }
}

export class SampleTriggeredTradeOrder extends InvestflyModel{
	name: string;
	description: string;
	trigger: SecurityTriggerExpression;

    static parseJSON(obj: {[index:string]: any}): SampleTriggeredTradeOrder {
        let order = new SampleTriggeredTradeOrder();
        Object.assign(order, obj);
        order.trigger = SecurityTriggerExpression.parseJSON(obj.trigger);
        return order;
    }
}
