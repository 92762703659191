import {Visibility} from "../common/commonModel";
import {InvestflyModel} from "../InvestflyModel";


export class PortfolioSpec extends InvestflyModel {
    portfolioId: string;
    userName: string;

    initialAmount: number;
    name: string;
    description: string;
    visibility: Visibility;

    static parseJSON(obj: { [index: string]: any }): PortfolioSpec {
        let portSpec = new PortfolioSpec();
        Object.assign(portSpec, obj);
        return portSpec;
    }

    clone(): PortfolioSpec {
        let clone = new PortfolioSpec()
        Object.assign(clone, this);
        return clone;
    }

    static createDefault(): PortfolioSpec {
        let def = new PortfolioSpec()
        def.visibility = Visibility.PRIVATE;
        def.initialAmount = 100000;
        return def;
    }

    validate(): string[] {
        let errors: string[] = [];
        if(this.initialAmount < 0){
            errors.push("Initial amount must be positive number");
        }
        return errors;
    }
}