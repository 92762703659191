import { useTheme } from '@mui/material/styles';
import React from 'react';
import { ThemeProp } from "util/commonProps";

const withTheme = <P extends object>(Component: React.ComponentType<P>) => {
    
    return (props:  Omit<P, keyof ThemeProp>) => {
        let theme = useTheme();
        return <Component mode={theme.palette.mode} {...props as P}/>;
    };
}

export default withTheme;