import React, { useEffect, useState } from 'react';
import { FilterCondition,  FilterGroup, SecurityTriggerExpression } from "api/models/trigger/SecurityTriggerExpression";
import Stack from '@mui/material/Stack';
import { Alert, Box, Button, FormControl, Grid, IconButton, Paper } from '@mui/material';
import { AppConstant } from 'api';
import A from 'containers/common/Anchor';
import ClearIcon from '@mui/icons-material/Clear';
import InflyHelperText from 'containers/common/InflyHelperText';
import { SelectOption, SelectField } from 'containers/common/SelectField';
import { FilterExpressionComp } from './FilterExpressionComp';
import { ComparisonOperator } from 'api/models/common/ComparisonOperator';
import { DataParam } from 'api/models/trigger/DataParam';

interface SecurityFilterExpressionCompProps {
    required: boolean
    securityFilterExpression?: SecurityTriggerExpression
    onChange:(securityFilterExpression: SecurityTriggerExpression|undefined) => void; // if user un-sets the expression like in close position settings, we send explicit undefined
    includeFinancials?: boolean
}

export function SecurityFilterExpressionComp(props: SecurityFilterExpressionCompProps): React.JSX.Element {

    // Generally, we don't need a state becuase we can just bind to securityFilterExpression coming from props.
    // We keep state so that we can keep "in-progress/invalid" expression separate from validated expression in database 
    const [securityFilterExpression, setSecurityFilterExpression] = React.useState(props.securityFilterExpression ?? SecurityTriggerExpression.createDefault())
    const [errors, setErrors] = React.useState<string[]>([]);


    useEffect( () => {
        setSecurityFilterExpression(props.securityFilterExpression ?? SecurityTriggerExpression.createDefault())
    }, [props.securityFilterExpression])


    function onFilterGroupChange(index: number, filterGroup: FilterGroup|null, changedParam?: DataParam){
        if(filterGroup == null){
            securityFilterExpression.filterGroups.splice(index, 1);
        }else{
            securityFilterExpression.filterGroups[index] = filterGroup;
        }
        if(changedParam){
            // this works for add/update of new params. For delete param, logically the deleted DataParam must be deleted from the map
            // but we can't blindly do it becuase it could be used  by ohter filter groups, hence cleanUpDeclaredMetrics must be run regardless
            securityFilterExpression.dataParams.set(changedParam.get(DataParam.ALIAS), changedParam);
        }

        // also needs to be called when filtergroup/filtercondition is deleted
        securityFilterExpression.cleanUpDeclaredMetrics();

        setSecurityFilterExpression(securityFilterExpression.clone());
        
        validateAndPublishUp();

    }
    

    function addNewFilterGroup(){
        // Generally, when  either new empty FilterGroup or FilterCondition are added, they will be detected as empty and won't be published up
        const newFilterGroup = new FilterGroup();
        newFilterGroup.addFilterCondition(new FilterCondition());
        securityFilterExpression.addFilterGroup(newFilterGroup);
        setSecurityFilterExpression(securityFilterExpression.clone());
        validateAndPublishUp();
    }

    function validateAndPublishUp(){

        if (securityFilterExpression.filterGroups.length == 0 && props.required === false){
            props.onChange(undefined);
        }else{
            const errors: string[] = securityFilterExpression.validate();
            setErrors(errors);
    
            if(errors.length === 0){
                console.log(securityFilterExpression);
                props.onChange(securityFilterExpression);
            }
        }


    }
    
    return <>
    <Stack>

        {errors.length > 0 && <Alert severity='error'>{errors.join()}</Alert>}

        <InflyHelperText
            info={
                <>
                    Type to filter indicators. Once selected, click on chip to edit.
                    View &nbsp;
                    <A
                        sx={{
                            ps: 1,
                            color: (theme) => theme.palette.primary.main,
                            ':hover': {
                                color: (theme) => theme.palette.primary.dark,
                            },
                        }}
                        href={`${AppConstant.ROOT_DOMAIN}/guides/indicator.html`}
                        newTab={true}>
                        all supported indicators
                    </A>
                    . 
                    <A
                        sx={{ float: 'right' }}
                        href={'https://www.youtube.com/watch?v=XxmsVy39JAM'}
                        newTab={true}
                    >
                        Watch Tutorial For Help
                    </A>
                </>
            }
        />

        {securityFilterExpression.filterGroups.map(
            (fg, index) =>
            <Box key={index} width={'100%'}>

                <FilterGroupComp filterGroup={fg} 
                    includeFinancials={props.includeFinancials}
                    dataParams={securityFilterExpression.dataParams} 
                    onChange={ (filterGroup: FilterGroup|null, changedParam?: DataParam) => onFilterGroupChange(index, filterGroup, changedParam) }  />

        </Box>
        )}

        <Button onClick={addNewFilterGroup}  variant={'text'}  sx={{ alignSelf: 'end' }}   >
            + AND FILTER
        </Button>
    </Stack>
</>
}

interface FilterGroupCompProps{
    filterGroup: FilterGroup;
    dataParams: Map<string, DataParam>;
    onChange: (filterGroup: FilterGroup|null, changedParam?: DataParam) => void;
    includeFinancials?: boolean
}
function FilterGroupComp(props: FilterGroupCompProps): React.JSX.Element {


    function addNewFilterCondition(){
        props.filterGroup.addFilterCondition(new FilterCondition())
        props.onChange(props.filterGroup);
    }

    function deleteFilterGroup(){
        props.onChange(null);
    }

    function onFilterConditionChange(index: number, filterCondition: FilterCondition|null, changedParam?: DataParam){
        if(filterCondition == null){
            // DELETE filter condition
            props.filterGroup.filterConditions.splice(index, 1);
        }else{
            props.filterGroup.filterConditions[index] = filterCondition;
        }
        props.onChange(props.filterGroup, changedParam);
    }

    return <Paper variant={'outlined'} elevation={0}>
    <Stack>
        <IconButton sx={{ alignSelf: 'end' }}  onClick={deleteFilterGroup}>
            <ClearIcon fontSize='small' />
        </IconButton>

        {props.filterGroup.filterConditions.map(
            (fc, index) =>
                <Box key={index} width={'100%'} p={1}>

                    <FilterConditionComp 
                        includeFinancials={props.includeFinancials}
                        filterCondition={fc} dataParams={props.dataParams}  
                        onChange={  (filterCondition: FilterCondition|null, changedParam?: DataParam) => onFilterConditionChange(index, filterCondition, changedParam)}/>
                </Box>
        )}
        <Button
            onClick={addNewFilterCondition}
            variant={'text'}
            sx={{ alignSelf: 'end' }} >
            + OR
        </Button>
    </Stack>
</Paper>
}


interface FilterConditionCompProps {
    filterCondition: FilterCondition
    dataParams: Map<string, DataParam>;
    onChange: (filterCondition: FilterCondition|null, changedParam?: DataParam) => void;
    includeFinancials?: boolean
}

function FilterConditionComp(props: FilterConditionCompProps): React.JSX.Element {

    function deleteFilterCondition(){
        props.onChange(null);
    }

    function onOperatorChange(selectedOperator: SelectOption){
        props.filterCondition.operator = selectedOperator.value;
        props.onChange(props.filterCondition);
    }

    function onLeftFilterExpressionChange(filterExpression: string[], changedParam?: DataParam){
        props.filterCondition.leftCondition = filterExpression; // this statement may not be required
        props.onChange(props.filterCondition, changedParam);
    }

    function onRightFilterExpressionChange(filterExpression: string[], changedParam?: DataParam){
        props.filterCondition.rightCondition = filterExpression;
        props.onChange(props.filterCondition, changedParam);
    }

    

    const operators: SelectOption[] = Object.entries(ComparisonOperator).map((keyValue:[string, ComparisonOperator]) => {
            return {label: keyValue[1], value: keyValue[1]}
        }   
    )


    return <Paper variant={'outlined'} elevation={0}>
    <Grid
        container
        justifyContent={{ md: 'space-between', xs: 'center' }}
        alignItems='center'
        flexDirection={{ xs: 'column', md: 'row' }}
        flexWrap={'nowrap'}
    >


            <Grid item  xs={12}   md={5}  id={'left_expression'}   width={{ xs: '100%', md: 'inherit' }} >
                <FilterExpressionComp  
                    includeFinancials={props.includeFinancials}
                    filterExpression={props.filterCondition.leftCondition} 
                    dataParams={props.dataParams}
                    onChange={onLeftFilterExpressionChange}
                    />
            </Grid>
            <Grid item xs={12} md width={{ xs: '100%', md: 'inherit' }}>
                <FormControl component={'fieldset'} fullWidth>

                    <SelectField  id='Operator'   label={'Operator'}
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontSize: '1.2rem',
                            },
                        }}
                        value={props.filterCondition.operator}
                        onSelectChange={onOperatorChange}
                        options={operators}
                    />
                </FormControl>
            </Grid>

            <Grid  item  xs={12}  md={5}   width={{ xs: '100%', md: 'inherit' }}
            >
                <FilterExpressionComp 
                    includeFinancials={props.includeFinancials}
                    filterExpression={props.filterCondition.rightCondition} 
                    dataParams={props.dataParams}
                    onChange={onRightFilterExpressionChange}
                    />
            </Grid>
        
        <Grid
            item
            order={{ xs: '-1', md: 'inherit' }}
            alignSelf={{ xs: 'end', md: 'center' }}
        >
            <IconButton aria-label='delete' onClick={deleteFilterCondition}>
                <ClearIcon fontSize='small' />
            </IconButton>
        </Grid>
    </Grid>
</Paper>
}