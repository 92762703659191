/** @format */

import React, { Component } from 'react';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import CommonUtil from 'util/CommonUtil';
import { RouterAndThemeProps } from 'util/commonProps';
import { CompletedGame, Game, GameAPIClient, GameStatus, Message, UserGameScore } from 'api';
import { AppContext } from 'util/appContext';

import {
    Box,
    Breadcrumbs,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    Link,
    Stack,
    SwipeableDrawer,
} from '@mui/material';
import GameStatics from './GameStatics';
import Leaderboard, { GameWinnersBoard } from './Leaderboard';
import SessionUtil from 'util/SessionUtil';
import { InflyMessage } from 'containers/common/InflyMessage';
import DialogButton from 'containers/common/DialogButton';

import Chip from '@mui/material/Chip';
import DocumentHeader from 'containers/common/DocumentHeader';
import { LoadingCard } from 'containers/common/LoadingCard';
import { ErrorCard } from 'containers/common/ErrorCard';
import AddEditGameBtn from './AddEditGameBtn';
import InviteFriends from 'containers/common/InflyInviteFriends';
import AuthorizedElement from 'containers/common/AuthorizedElement';
import { JoinGameBtn, JoinUpdateMode } from './JoinGameBtn';
import { DataDisplayCard } from 'containers/common/DataDisplayCard';
import { InflyIcon } from 'containers/common/InflyIcon';

export interface GameState {
    message?: Message;
    ready: boolean;
    swipeableDrawerOpen: boolean;
    game: Game;
    userGameScore?: UserGameScore;
    leaderBoard: GameWinnersBoard[];
    gameEndDate?: Date;
}

export enum GameOperation {
    // START is handled in special way because we need endDate
    STOP = 'STOP',
    PAUSE = 'PAUSE',
    LEAVE = 'LEAVE',
    DELETE = 'DELETE',
}
const drawerBleeding = 56;
class GameDetail extends Component<RouterAndThemeProps, GameState> {
    gameApiClient: GameAPIClient;

    constructor(props: RouterAndThemeProps) {
        super(props);
        this.gameApiClient = AppContext.getInstance().gameApiClient;
        this.state = {
            game: undefined!,
            leaderBoard: [],
            ready: false,
            swipeableDrawerOpen: false,
        };
    }

    async componentDidMount() {
        const gameId = Number(CommonUtil.getQueryParam(this.props, 'gameId'));
        if (gameId === undefined) {
            this.setState({
                message: Message.error('Invalid URL. Please use url like /game/<gameid>'),
            });
            return;
        }
        this._loadAllGameDetails(gameId);
    }

    async _loadAllGameDetails(gameId: number) {
        try {
            let game = await this.gameApiClient.getGame(gameId);
            let userGameScore = await this.gameApiClient.getMyGameScore(gameId);

            const res = await this.gameApiClient.getCompletedGames(gameId);
            const filteredLeaderboard = res.filter((item) => item.winners.length > 0);
            let leaderBoard = filteredLeaderboard.map((item: CompletedGame, index) => ({
                startToEndDate: `${item.startDate.toLocaleDateString()} to ${item.startDate.toLocaleDateString()}`,
                winners: [...item.winners],
                selected: index === filteredLeaderboard.length - 1,
            }));

            this.setState({ game, userGameScore, leaderBoard });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        } finally {
            this.setState({ ready: true });
        }
    }

    onEndGameConfirm = async (event: any) => {
        let gameStatus = await this.gameApiClient.endGame(this.state.game.gameId);
        this.setState({ message: Message.info('Game Status: ' + gameStatus) });
        // when game ends, scores are calculated, game status changes, leaderboard changes, so load everything
        this._loadAllGameDetails(this.state.game.gameId);
    };

    onStartGame = async (event: any) => {
        try {
            const gameStatus = await this.gameApiClient.startGame(
                this.state.game.gameId,
                this.state.gameEndDate!.getTime()
            );

            // game object changes, we need to update it
            let game: Game = await this.gameApiClient.getGame(this.state.game.gameId);

            this.setState({ message: Message.info('Game status: ' + gameStatus), game });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    };

    onJoinGameReturn = async (userGameScore?: UserGameScore) => {
        if (userGameScore) {
            // todo - backend does not send this after join
            let userGameScore = await this.gameApiClient.getMyGameScore(this.state.game.gameId);
            this.setState({ userGameScore });
        }
    };

    onLeaveGameConfirm = async (event: any) => {
        let message = await this.gameApiClient.leaveGame(this.state.game.gameId);
        let userGameScore = await this.gameApiClient.getMyGameScore(this.state.game.gameId);
        this.setState({ message, userGameScore });
    };

    onGameEditReturn = async (game?: Game) => {
        if (game) {
            this.setState({ game });
        }
    };

    render() {
        const { game, userGameScore, swipeableDrawerOpen } = this.state;
        const isMobile = this.props.isMobile;

        if (game === undefined) {
            return <LoadingCard title={'Game Details'} />;
        }
        if (game === undefined && this.state.message?.isError()) {
            return (
                <ErrorCard
                    title={'Game Details'}

                    message={this.state.message.message}
                />
            );
        }

        let gameRulesData = new Map<string, string>();

        let rules = this.state.game.rules;
        gameRulesData.set(
            'No of Trades ',
            (rules.minTrade?.toString() ?? 'Any') + ' - ' + (rules.maxTrade?.toString() ?? 'Any')
        );
        gameRulesData.set(
            'Allowed Price Range($)',
            (rules.minPrice?.toString() ?? 'Any') + ' - ' + (rules.maxPrice?.toString() ?? 'Any')
        );
        gameRulesData.set(
            'MarketCap Limit($)',
            (rules.minMarketCap?.toString() ?? 'Any') +
                ' - ' +
                (rules.maxMarketCap?.toString() ?? 'Any')
        );
        gameRulesData.set('Automation Allowed', rules.isAutomationAllowed ? 'Yes' : 'No');
        gameRulesData.set('ShortSell Allowed', rules.isShortSellAllowed ? 'Yes' : 'No');
        gameRulesData.set('Option Trade Allowed', rules.isOptionTradeAllowed ? 'Yes' : 'No');
        gameRulesData.set('Futures Trade Allowed', rules.isFutureTradeAllowed ? 'Yes' : 'No');

        return (
            <DocumentHeader title={'My Game Details'}>
                <Stack gap={2}>
                    <Stack
                        id='heading'
                        gap={2}
                        flexDirection={isMobile ? 'column' : 'row'}
                        justifyContent='space-between'
                    >
                        <Breadcrumbs sx={{ paddingInline: 2, mt: { xs: 2, md: 'inherit' } }}>
                            <Link underline='hover' color='inherit' href='/games'>
                                My Games
                            </Link>
                            <Link color='text.primary' underline='none' aria-current='page'>
                                {CommonUtil.clippedUpto(game.name ?? '', 20)}
                            </Link>
                        </Breadcrumbs>

                        {isMobile && (
                            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                                <Stack
                                    sx={{ paddingInline: 2 }}
                                    gap={1}
                                    flexDirection={'row'}
                                    justifyContent={'space-between'}
                                >
                                    <Chip
                                        label={game.status}
                                        sx={{ width: 'min-content' }}
                                        color={
                                            game.status === GameStatus.STARTED ? 'primary' : 'error'
                                        }
                                    />
                                    {game.status === GameStatus.STARTED && (
                                        <AuthorizedElement
                                            authorizedUsername={this.state.game.creator}
                                        >
                                            <DialogButton
                                                fullWidth={false}
                                                startIcon={<InflyIcon name='stop' />}
                                                onClick={this.onEndGameConfirm}
                                                label='End Game'
                                                className={'mr-2'}
                                                dialogtitle='End Game'
                                                dialogprompt='Are you sure you want to end this game?'
                                            />
                                        </AuthorizedElement>
                                    )}
                                    {game.status === GameStatus.STOPPED && (
                                        <DialogButton
                                            onClick={this.onStartGame}
                                            fullWidth={false}
                                            startIcon={<InflyIcon name='play' />}
                                            label='Start Contest'
                                            color={'primary'}
                                            dialogtitle='Start Contest'
                                        >
                                            <FormControl fullWidth component='fieldset' required>
                                                <FormLabel component='legend'> End Date</FormLabel>
                                                <Input
                                                    type={'date'}
                                                    value={CommonUtil.getDateString(
                                                        this.state.gameEndDate
                                                    )}
                                                    onChange={(event: any) =>
                                                        this.setState({
                                                            gameEndDate: new Date(
                                                                event.target.value
                                                            ),
                                                        })
                                                    }
                                                />
                                            </FormControl>
                                        </DialogButton>
                                    )}
                                </Stack>
                                <Box sx={{ paddingInline: 2 }}>
                                    <IconButton
                                        onClick={() =>
                                            this.setState({
                                                swipeableDrawerOpen: true,
                                            })
                                        }
                                        size='small'
                                        sx={{ alignItems: 'right' }}
                                    >
                                        <InflyIcon name='more' />
                                    </IconButton>
                                </Box>
                                <SwipeableDrawer
                                    anchor='bottom'
                                    open={swipeableDrawerOpen}
                                    onClose={() =>
                                        this.setState({
                                            swipeableDrawerOpen: false,
                                        })
                                    }
                                    onOpen={() =>
                                        this.setState({
                                            swipeableDrawerOpen: true,
                                        })
                                    }
                                    swipeAreaWidth={drawerBleeding}
                                    disableSwipeToOpen={true}
                                    ModalProps={{
                                        keepMounted: true,
                                    }}
                                >
                                    {/* Puller Component */}
                                    <Box
                                        sx={(theme) => ({
                                            width: 30,
                                            height: 6,
                                            backgroundColor: theme.palette.background.header,
                                            borderRadius: 3,
                                            position: 'absolute',
                                            top: 10,
                                            left: 'calc(50% - 15px)',
                                        })}
                                    />
                                    <br />
                                    <Stack gap={2} mt={2}>
                                        <Box width={'100%'}>
                                            {this.state.game.status !== GameStatus.STARTED && (
                                                <AddEditGameBtn
                                                    variant='text'
                                                    startIcon={<InflyIcon name='edit' />}
                                                    label='Edit Game'
                                                    game={this.state.game}
                                                    onCreateGameReturn={this.onGameEditReturn}
                                                />
                                            )}
                                        </Box>

                                        <Box>
                                            <InviteFriends
                                                variant='text'
                                                startIcon={<InflyIcon name='send' />}
                                                label='Invite Friends'
                                                onSuccess={(message: Message) => {
                                                    this.setState({ message });
                                                }}
                                                game={this.state.game!}
                                            />
                                        </Box>
                                    </Stack>
                                </SwipeableDrawer>
                            </Stack>
                        )}
                        {!isMobile && (
                            <Stack direction='row' gap={1} justifyContent='flex-end'>
                                <Chip
                                    label={game.status}
                                    color={game.status === GameStatus.STARTED ? 'primary' : 'error'}
                                />

                                <AuthorizedElement authorizedUsername={this.state.game.creator}>
                                    <>
                                        {game.status === GameStatus.STARTED && (
                                            <DialogButton
                                                fullWidth={isMobile}
                                                onClick={this.onEndGameConfirm}
                                                label='End Game'
                                                className={'mr-2'}
                                                dialogtitle='End Game'
                                                dialogprompt='Are you sure you want to end this game?'
                                            />
                                        )}

                                        {game.status === GameStatus.STOPPED && (
                                            <DialogButton
                                                onClick={this.onStartGame}
                                                label='Start Contest'
                                                color={'primary'}
                                                dialogtitle='Start Contest'
                                            >
                                                <FormControl
                                                    fullWidth
                                                    component='fieldset'
                                                    required
                                                >
                                                    <FormLabel component='legend'>
                                                        {' '}
                                                        End Date
                                                    </FormLabel>
                                                    <Input
                                                        type={'date'}
                                                        value={CommonUtil.getDateString(
                                                            this.state.gameEndDate
                                                        )}
                                                        onChange={(event: any) =>
                                                            this.setState({
                                                                gameEndDate: new Date(
                                                                    event.target.value
                                                                ),
                                                            })
                                                        }
                                                    />
                                                </FormControl>
                                            </DialogButton>
                                        )}

                                        {this.state.game.status !== GameStatus.STARTED && (
                                            <AddEditGameBtn
                                                label='Edit Game'
                                                game={this.state.game}
                                                onCreateGameReturn={this.onGameEditReturn}
                                            />
                                        )}

                                        <InviteFriends
                                            label='Invite Friends'
                                            onSuccess={(message: Message) => {
                                                this.setState({ message });
                                            }}
                                            game={this.state.game!}
                                        />
                                    </>
                                </AuthorizedElement>

                                {this.state.userGameScore === undefined && (
                                    <JoinGameBtn
                                        game={this.state.game}
                                        mode={JoinUpdateMode.JOIN}
                                        onJoinGameReturn={this.onJoinGameReturn}
                                    />
                                )}

                                {this.state.userGameScore !== undefined &&
                                    this.state.game.creator !==
                                        SessionUtil.findSession()?.username && (
                                        <DialogButton
                                            onClick={this.onLeaveGameConfirm}
                                            label='Leave Game'
                                            className={'mr-2'}
                                            dialogtitle='Leave Game'
                                            dialogprompt='Are you sure you want to end this game?'
                                        />
                                    )}
                            </Stack>
                        )}
                    </Stack>

                    <InflyMessage
                        message={this.state.message}
                        onClose={() => this.setState({ message: undefined })}
                    />

                    <Grid container spacing={2} justifyContent='space-between'>
                        <Grid item sm={7} xs={12}>
                            <GameStatics game={game!} userGameScore={userGameScore} />
                        </Grid>

                        <Grid item sm={5} xs={12}>
                            <Leaderboard leaderboard={this.state.leaderBoard} />
                        </Grid>

                        <Grid item sm={7} xs={12}>
                            <DataDisplayCard title='Rules' data={gameRulesData} />
                        </Grid>
                    </Grid>
                </Stack>
            </DocumentHeader>
        );
    }
}

export default withNavBarAndAuth(GameDetail);
