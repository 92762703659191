import { Stack, Typography, Grid, Card, CardContent, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from "@mui/material";
import { OPERATION, StrategyAPIClient } from "api";
import { TradingStrategyModel } from "api/models/strategy/TradingStrategyModel";
import { InflyIcon } from "containers/common/InflyIcon";
import { LoadingCard } from "containers/common/LoadingCard";
import React from "react";
import { useEffect, useState } from "react";
import { AppContext } from "util/appContext";
import { NewStrategyDialog, NewStrategyDialogButton } from "./NewStrategyDialog";
import UpgradeCheckButton from "containers/common/UpgradeCheckButton";
import { SampleScreenerList } from "containers/Screener/ScreenerSampleList";


export interface CreateStrategyFromSampleListProps {
    onCreate: (strategyModel: TradingStrategyModel) => void;
    strategyCount: number;
}
export function CreateStrategyFromSampleList({onCreate, strategyCount}:CreateStrategyFromSampleListProps ): React.JSX.Element {
        // Wrapper around SampleListComponent to create strategy based on selected sample, so StrategyList page is simplified
        // This launches New Strategy Dialog, when the sample is selected and calls the prop's onCreate handler when the new strategy dialog is submitted

        const [dialogOpen, setDialogOpen] = useState(false);
        const [sampleModel, setSampleModel] = useState<TradingStrategyModel>();
    
        function onSampleSelect(sampleModel: TradingStrategyModel){
            setDialogOpen(true);
            setSampleModel(sampleModel);
        }
        function onCancel(){
            setDialogOpen(false);
        }
    
        return <>
            {dialogOpen && 
                    <NewStrategyDialog onCreate={onCreate} onCancel={onCancel} sampleModel={sampleModel}/>
                }
         <SampleStrategyList onSampleSelect={onSampleSelect} strategyCount={strategyCount}/>
        </>
}



export interface SampleStrategyListDialogProps {
    // the on* method are 3 dialog button handlers
    onSampleSelect: ( sampleModel: TradingStrategyModel) => void,
    onStartFromScratch: () => void;
    onCancel: () => void;
    strategyCount?: number; // to decide whether to show upgrade btn
}
export function SampleStrategyListDialog({onSampleSelect, onStartFromScratch, onCancel, strategyCount}: SampleStrategyListDialogProps): React.JSX.Element {
    // to show the sample list in a a dialog
    return <Dialog open fullWidth maxWidth='lg'>
        <DialogTitle>Select Sample</DialogTitle>
        <DialogContent>
            <Paper>
                <SampleStrategyList onSampleSelect={onSampleSelect} strategyCount={strategyCount}/>
            </Paper>
            
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onCancel()}>Cancel</Button>
            <Button onClick={() => onStartFromScratch()}>Start from Scratch</Button>
        </DialogActions>
    </Dialog>
}


export interface SampleStrategyListProps {
    onSampleSelect: (strategyModel: TradingStrategyModel) => void;
    strategyCount?: number; // to decide whether to show upgrade btn
}

export function SampleStrategyList({onSampleSelect, strategyCount}: SampleStrategyListProps): React.JSX.Element {

    const [sampleStrategyList, setSampleStrategyList] = useState<TradingStrategyModel[]>();
    const [curStrategyCount, setCurStrategyCount] = useState<number>();

    async function loadSampleList(){
        try{
            let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
            let sampleList: TradingStrategyModel[] = await strategyApiClient.listSampleStrategies()
            setSampleStrategyList(sampleList);
        }catch(error){
        }
    }
    useEffect( () => {
        loadSampleList();
    }, [])

    async function loadCurStrategyCount(){
        if(strategyCount === undefined){
            let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
            let myStrategies: TradingStrategyModel[] = await strategyApiClient.listStrategies();
            setCurStrategyCount(myStrategies.length)
        }else{
            setCurStrategyCount(strategyCount);
        }
    }

    useEffect( () => {
        loadCurStrategyCount();
    }, [strategyCount])

    if(sampleStrategyList === undefined || curStrategyCount === undefined){
        return  <LoadingCard title='Screener Stragies' />
    }

    function createSampleStrategyCard(sampleModel: TradingStrategyModel): React.JSX.Element {
        return <Card raised elevation={5}>
            <CardContent>
                <Stack gap={2} >
                    <Stack direction={'row'} justifyContent={'space-between'}>

                        <Stack gap={1} direction={'row'}>
                            <InflyIcon name={'strategies'} />
                            <Typography variant='subtitle1' fontWeight={'bold'}  color={"common.heading"} >{sampleModel.strategyName}</Typography>
                        </Stack>
                        <UpgradeCheckButton op={OPERATION.CREATE_STRATEGY} currentCount={curStrategyCount} label={'Select'}>
                            <Button onClick={() => onSampleSelect(sampleModel)}> Select </Button>
                        </UpgradeCheckButton>
                    </Stack>
                    <Typography variant='body2' >{sampleModel.strategyDesc}</Typography>
                </Stack>
            </CardContent>
        </Card>
    }


    return <Stack gap={3}>
    <Typography variant='body2'>Trading Strategy allows you to automatically buy or sell stocks based on your predefined rules. Once you create a trading strategy, you can backtest against historical data or deploy to virtual portfolio (forward testing) or connected brokerage account. Start by selecting a template below or click New Strategy button above.</Typography>
    <Grid container spacing={2}>
        {sampleStrategyList!.map(strategyModel => {
            return <Grid key={strategyModel.strategyName} item xs={12} md={6}>
                {createSampleStrategyCard(strategyModel)}
            </Grid>
        })}
    </Grid>
</Stack>

}