/** @format */

import React, { Component } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { RouterAndThemeProps } from 'util/commonProps';
import CommonUtil from 'util/CommonUtil';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import { InflyMessage } from 'containers/common/InflyMessage';
import {SelectField }from 'containers/common/SelectField';
import logo from 'static/images/short_Investfly.png';
import { ArrowRightAlt } from '@mui/icons-material';
import TRADIER from 'static/images/trdLogo.png';
import TDAMERITRADE from 'static/images/td_am_logo.svg';
import TRADESTATION from 'static/images/tradestation_logo.png';

import { BrokerAccount, BrokerAPIClient, BrokerType, Message, UserError } from 'api';
import { AppContext } from 'util/appContext';

export interface ConnectBrokerState {
    authorizedAccounts: BrokerAccount[];
    accountToConnect: string;
    connectedAccount?: BrokerAccount;
    message?: Message;
}

export class ConnectBrokerController {
    private brokerAPIClient: BrokerAPIClient;
    authorizedAccounts: BrokerAccount[];
    accountToConnect: string;
    connectedAccount?: BrokerAccount;
    message?: Message;
    brokerType: BrokerType;
    authorizationCode: string;

    constructor(brokerType: BrokerType, authorizationCode: string) {
        this.brokerAPIClient = AppContext.getInstance().brokerApiClient;
        this.brokerType = brokerType;
        this.authorizedAccounts = [];
        this.accountToConnect = '';
        this.authorizationCode = authorizationCode;
    }

    async init(): Promise<ConnectBrokerState> {
        const state = await this.authorizeAccess();
        if (state.authorizedAccounts.length === 1) {
            this.setAccountToConnect(state.authorizedAccounts[0].accountId);
            await this.connectAccount();
        }
        return this.getState();
    }

    async authorizeAccess(): Promise<ConnectBrokerState> {
        try {
            if (!this.authorizationCode || this.authorizationCode === '') {
                throw new UserError(
                    'Unable to get authorization code. You must authorize the Infly to access your broker account'
                );
            }

            if (this.brokerType === BrokerType.TRADIER) {
                this.authorizedAccounts = await this.brokerAPIClient.authorizeTradierAccess(
                    this.authorizationCode
                );
            } else if (this.brokerType === BrokerType.TDAMERITRADE) {
                this.authorizedAccounts = await this.brokerAPIClient.authorizeTDAmeritradeAccount(
                    this.authorizationCode
                );
            } else if (this.brokerType === BrokerType.TRADESTATION) {
                this.authorizedAccounts = await this.brokerAPIClient.authorizeTradeStation(
                    this.authorizationCode
                );
            } else {
                throw new Error('Invalid broker ' + this.brokerType);
            }

            // This is also used for Reconnect flow. During reconnect, we already have the account ID in the backend, so backend returns a connected brokerAccount

            if (this.authorizedAccounts.length === 1) {
                if (this.authorizedAccounts[0].connected === true) {
                    this.connectedAccount = this.authorizedAccounts[0];
                }
            }
        } catch (error) {
            this.message = Message.fromError(error);
        }

        return this.getState();
    }

    setAccountToConnect(accountId: string): ConnectBrokerState {
        this.accountToConnect = accountId;
        return this.getState();
    }

    async connectAccount(): Promise<ConnectBrokerState> {
        try {
            if (this.brokerType === BrokerType.TRADIER) {
                this.connectedAccount = await this.brokerAPIClient.connectTradierAccount(
                    this.authorizationCode,
                    this.accountToConnect
                );
            } else if (this.brokerType === BrokerType.TDAMERITRADE) {
                this.connectedAccount = await this.brokerAPIClient.connectTDAmeritradeAccount(
                    this.authorizationCode,
                    this.accountToConnect
                );
            } else if (this.brokerType === BrokerType.TRADESTATION) {
                this.connectedAccount = await this.brokerAPIClient.connectTradeStationAccount(
                    this.authorizationCode,
                    this.accountToConnect
                );
            } else {
                throw new Error('Invalid broker ' + this.brokerType);
            }
        } catch (error) {
            this.message = Message.fromError(error);
        }

        return this.getState();
    }

    clearMessage(): ConnectBrokerState {
        this.message = undefined;
        return this.getState();
    }

    getState(): ConnectBrokerState {
        return {
            authorizedAccounts: this.authorizedAccounts,
            accountToConnect: this.accountToConnect,
            connectedAccount: this.connectedAccount,
            message: this.message,
        };
    }
}

interface ConnectBrokerProps extends RouterAndThemeProps {}

/* This is the callback handler for broker callback urls */

class ConnectBroker extends Component<ConnectBrokerProps, ConnectBrokerState> {
    ConnectBrokerController: ConnectBrokerController;

    constructor(props: ConnectBrokerProps) {
        super(props);
        const authorizationCode = CommonUtil.getQueryParam(props, 'code');
        const broker = this._getBroker()!;
        this.ConnectBrokerController = new ConnectBrokerController(broker, authorizationCode);
        this.state = this.ConnectBrokerController.getState();
    }

    _getBroker = (): BrokerType | null => {
        const brokerName = CommonUtil.getQueryParam(this.props, 'brokerName').toLowerCase();
        if (brokerName === 'tradier') {
            return BrokerType.TRADIER;
        } else if (brokerName === 'tamer') {
            return BrokerType.TDAMERITRADE;
        } else if (brokerName === 'tradestation') {
            return BrokerType.TRADESTATION;
        }

        return null;
    };

    async componentDidMount() {
        // Note, this can be both connect and reconnect

        this.ConnectBrokerController.init().then((state) => {
            this.setState(state);

            if (state.connectedAccount !== undefined) {
                this.props.navigate(
                    '/portfolio/' +
                        state.connectedAccount?.brokerType +
                        '/' +
                        state.connectedAccount?.accountId
                );
            }
        });
    }

    connectAccount = () => {
        this.ConnectBrokerController.connectAccount().then((state) => {
            if (state.connectedAccount !== undefined) {
                this.props.navigate(
                    '/portfolio/' +
                        state.connectedAccount?.brokerType +
                        '/' +
                        state.connectedAccount?.accountId
                );
            } else {
                this.setState(state); // for error messages
            }
        });
    };

    getlogoSrc = () => {
        switch (this._getBroker() as string) {
            case 'TRADIER':
                return TRADIER;
            case 'TDAMERITRADE':
                return TDAMERITRADE;
            case 'TRADESTATION':
                return TRADESTATION;
        }
    };

    getAccountIdOptions = () => {
        let accountIdOptions = [{ label: 'Select AccountId', value: '' }];

        if (this.state.authorizedAccounts)
            this.state.authorizedAccounts
                .map((a) => {
                    return { label: a.accountId, value: a.accountId };
                })
                .forEach((opt: any) => accountIdOptions.push(opt));
        return accountIdOptions;
    };

    render() {
        // Conditional rendering is not needed because if the connect is successful, user is already redirected to account detail page from above functions
        const state = this.state;

        return (
            <Card>
                <CardHeader
                    title={'Connect account to ' + this._getBroker()?.replaceAll('_', '') ?? '---'}
                />
                <CardContent>
                    <InflyMessage
                        message={this.state.message}
                        onClose={() => this.setState(this.ConnectBrokerController.clearMessage())}
                    />
                    <Stack justifyContent={'center'} alignItems={'center'}>
                        <Box
                            component='article'
                            m={1}
                            p={1}
                            bgcolor={'background.paper'}
                            border={1}
                            borderRadius='borderRadiuspx'
                            borderColor={'grey.A700'}
                        >
                            <Grid
                                container
                                spacing={1}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Grid item>
                                    <img
                                        src={logo}
                                        alt={'logo'}
                                        style={{
                                            display: 'block',
                                            margin: 'auto auto',
                                            textAlign: 'center',
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <ArrowRightAlt fontSize={'large'} />
                                </Grid>
                                <Grid item>
                                    <img
                                        src={this.getlogoSrc()}
                                        alt={'tradier logo'}
                                        width={'160px'}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <Typography
                                style={{ textAlign: 'center' }}
                                variant={'subtitle1'}
                                component={'p'}
                                gutterBottom
                            >
                                Select an account to connect
                            </Typography>
                            <Grid
                                container
                                spacing={1}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Grid item>
                                    <SelectField
                                        label={'Portfolio Id'}
                                        className='m-2'
                                        options={this.getAccountIdOptions()}
                                        value={state.accountToConnect}
                                        onChange={(event: any) =>
                                            this.setState(
                                                this.ConnectBrokerController.setAccountToConnect(
                                                    event.target.value
                                                )
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        className='m-2'
                                        color='primary'
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            this.connectAccount();
                                        }}
                                    >
                                        Connect Account
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        );
    }
}

export default withNavBarAndAuth(ConnectBroker);
