import {InvestflyUtil} from '../../utils/investflyUtil';
import {InvestflyModel} from '../InvestflyModel';
import {Security} from '../marketdata/security';
import {TradeType} from './tradeOrder';

export class CompletedTrade extends InvestflyModel{
    security: Security;
    orderID: number;
    date: Date;
    price: number;
    quantity: number;
    tradeType: TradeType;

    static parseJSON(obj: {[index:string]: any}): CompletedTrade {
        let trade = new CompletedTrade();
        Object.assign(trade, obj);
        trade.security = Security.parseJSON(obj.security);
        trade.date = InvestflyUtil.strToDate(obj.date)!;
        return trade;
    }
}