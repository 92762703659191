/** @format */

import { Card, CardContent, CardHeader } from '@mui/material';
import { Stack } from '@mui/system';
import { Message, Portfolio, Visibility } from 'api';
import AuthorizedElement from 'containers/common/AuthorizedElement';
import { LabelAndValue } from 'containers/common/LabelAndValue';
import React from 'react';
import { CreateEditPortfolioBtn } from './CreateEditPortfolio';
import { PortfolioIdentifierProps } from './PortfolioDashboard';
import { DataDisplayCard } from 'containers/common/DataDisplayCard';
import { title } from 'process';

export class PortfolioSummary extends React.Component<PortfolioIdentifierProps, {}> {
    constructor(props: PortfolioIdentifierProps) {
        super(props);
    }

    onPortfolioEditReturn = (portfolio?: Portfolio) => {
        if (portfolio) {
            this.props.onMessage(Message.success('Portfolio Successfully Updated'));
        }
    };

    render(): React.ReactNode {
        const overviews = new Map<string, any>();
        overviews.set('Name', this.props.currentPortfolio.name);
        overviews.set('Description', this.props.currentPortfolio.description);
        overviews.set('Visibility', Visibility[this.props.currentPortfolio.visibility]);

        return (
            <DataDisplayCard
                cardActionHeader={
                    <AuthorizedElement authorizedUsername={this.props.currentPortfolio.userName}>
                        <CreateEditPortfolioBtn
                            portfolio={this.props.currentPortfolio.toPortfolioSpec()}
                            btnLabel='Edit' onComplete={(p, m) => {this.onPortfolioEditReturn(p)}} 
                        />
                    </AuthorizedElement>
                }
                title='Overview'
                data={overviews}
            />
        );
    }
}
