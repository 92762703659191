import {InvestflyModel} from '../InvestflyModel';


export enum Frequency {
    ONE_TIME = "ONE_TIME",
    DAY = "DAY",
    WEEK = "WEEK", 
    MONTH = "MONTH", 
    YEAR = "YEAR"
}

export class PaymentPlan extends InvestflyModel {

    amount: number;
    interval: number;
    frequency: Frequency;

    freeTrial: boolean = false;

    constructor(){
        super();
    }

    public getAmountString(): string {
        if(this.amount == 0 ) return "FREE";
        
        let str = "$"+this.amount
        if(this.frequency != Frequency.ONE_TIME){
            str += " /"+this.frequency.toString().toLowerCase();
        }

        return str;
    }

    static parseJSON(obj: {[index:string]: any} ): PaymentPlan {
        let paymentPlan = new PaymentPlan();
        Object.assign(paymentPlan, obj);
        return paymentPlan;
    }
}