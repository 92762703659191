interface InvesflyObject {
    [index: string]: any;
}

export class InvestflyModel {
    
    public parseJSON(obj: InvesflyObject): InvestflyModel {
        // This only handles primitive types
        Object.assign(this, obj);

        //for complex types, override it
        return this;
    }

    static parseList<T>(list: InvesflyObject[], func: (obj: InvesflyObject) => T|undefined ): T[] {
        let result: T[] = [];
        for(let obj of list){
           let t = func(obj);
           if(t){
            result.push(t);
           }
         
        }
        return result;
    }

    static parseSet<T>(list: InvesflyObject[], func: (obj: InvesflyObject) => T ): Set<T> {
        let result: Set<T> = new Set();
        for(let obj of list){
          result.add(func(obj));
        }
        return result;
    }

}

