/** @format */

import React, { Component, useEffect, useState } from 'react';
import {
    Button,
    ButtonProps,
    Card,
    CardContent,
    CardHeader,
    DialogContent,
    Divider,
    IconButton,
    Tooltip,
    Typography,

} from '@mui/material';
import { Message, Portfolio, PortfolioAPIClient, PortfolioSpec, Visibility } from 'api';
import { InflyMessage } from 'containers/common/InflyMessage';
import { CreateUpdateMode } from 'util/CommonUtil';
import InflyDialog from 'containers/common/InflyDialog';
import { AppContext } from 'util/appContext';
import { Stack } from '@mui/system';
import { InflyIcon } from 'containers/common/InflyIcon';
import ReactGA from 'react-ga4';
import { ITextField } from 'containers/common/ITextField';
import { RadioField, selectOptionsFromEnum } from 'containers/common/SelectField';


interface CreateEditPortfolioBtnProps {
    portfolio?:  PortfolioSpec; // spec also has portfolioId
    onComplete: (stockAlert: Portfolio, message?: Message) => void;
    btnLabel?: string; // either button label or icon must be specified
    iconName?: string;
}


export function CreateEditPortfolioBtn({portfolio, onComplete: onSubmit, iconName, btnLabel}: CreateEditPortfolioBtnProps){
    const [dialogOpen, setDialogOpen] = useState(false);

    const onDialogSubmit = (portfolioVal: Portfolio, message?: Message) => {
        setDialogOpen(false)
        onSubmit(portfolioVal,  message);
    }

    const onBtnClick = (event: any) => {
        ReactGA.event('create_edit_portfolio_btn_click', {});
        setDialogOpen(true)
    }

    return <>
        {dialogOpen &&
            <CreateEditPortfolioDialog  portfolio={portfolio} onComplete={onDialogSubmit} onCancel={ () => setDialogOpen(false)}/>
        }

            {iconName && 
            <Tooltip title='Create virtual portfolio'>
                <IconButton onClick={onBtnClick}>
                    <InflyIcon name={iconName} />
                </IconButton>
            </Tooltip>
        }

        {btnLabel && 
            <Tooltip title='Create virtual portfolio'>
                <Button onClick={onBtnClick}> {btnLabel} </Button>
            </Tooltip>}
        
    </>
}

interface PortfolioDialogProps {
    portfolio?:  PortfolioSpec; // spec also has portfolioId
    onComplete: (stockAlert: Portfolio, message?: Message) => void;
    onCancel: () => void

}

export function CreateEditPortfolioDialog({portfolio, onComplete: onComplete, onCancel}: PortfolioDialogProps) {

    const [message, setMessage] = useState<Message | null>(null);
    const [editPortfolio, setEditPortfolio] = useState(portfolio ? portfolio.clone(): PortfolioSpec.createDefault());
    const createUpdateMode = portfolio ? CreateUpdateMode.UPDATE : CreateUpdateMode.CREATE;

    useEffect(() => {
        setEditPortfolio(portfolio ? portfolio.clone(): PortfolioSpec.createDefault());
    }, [portfolio])

    const setProperty = (propName: string, propVal: any) => {
        editPortfolio[propName] = propVal
        setEditPortfolio(editPortfolio.clone());
    }

    const onDialogSubmit = async () => {
        let errors = editPortfolio.validate()
        if (errors.length == 0){
            let portfolioApiClient = AppContext.getInstance().portfolioApiClient;
            try{
                if(createUpdateMode == CreateUpdateMode.CREATE){
                    let createdPortfolio = await portfolioApiClient.createNewPortfolio(editPortfolio);
                    onComplete(createdPortfolio, Message.success("Portfolio Created Sucessfully"))
                }else{
                    let updatedPortfolio = await portfolioApiClient.updateVirtualPortfolio(editPortfolio);
                    onComplete(updatedPortfolio, Message.success("Portfolio Created Sucessfully"))
                }
            }catch(ex){
                setMessage(Message.fromError(ex));
            }
        }else{
            setMessage(Message.error(errors.join(",")));
        }
    }

    return (
        <>
            <InflyDialog
                id='edit-portfolio'
                open={true}
                maxWidth={"md"}
                title="Virtual Portfolio"
                onClose={onCancel}
                okText={createUpdateMode === CreateUpdateMode.CREATE ? 'Create' : 'Update'}
                onExecute={onDialogSubmit}
            >
                <DialogContent>
                    <InflyMessage message={message} onClose={() => setMessage(null)} />

                    <Card>
                        <CardHeader  title='Portfolio Details'  titleTypographyProps={{ variant: 'subtitle1' }}  />

                        <CardContent>

                            <Stack spacing={2}>

                               {createUpdateMode == CreateUpdateMode.CREATE &&
                                <Typography> A virtual portfolio is a paper trading account used to test a trading strategy.</Typography>
                               } 

                                <ITextField required label="Portfolio Name"  id={'portfolioName'}  value={editPortfolio.name} onChange={(val) => setProperty('name', val)}  />
                                <ITextField label="Portfoio Description"  id={'portfolioDesc'} multiline  value={editPortfolio.description} onChange={(val) => setProperty('description', val)}  />

                                <Divider/>

                                {createUpdateMode == CreateUpdateMode.CREATE &&
                                    <>
                                        <ITextField label='Initial Cash Amount' type='number' value={editPortfolio.initialAmount} onChange={val => setProperty('initialAmount', val)} />
                                        <Divider/>
                                    </>
                                }



                                <RadioField label="Visibility" options={selectOptionsFromEnum(Visibility)} value={editPortfolio.visibility} onSelectChange={so => setProperty('visibility', so.value)} />

                                <Divider/>

                                


                            </Stack>


                        </CardContent>
                    </Card>

                </DialogContent>
            </InflyDialog>
        </>
    );

}

