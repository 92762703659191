/** @format */

import {
    Box,
    Breadcrumbs,
    Button,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    IconButton,
    Link,
    Stack,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import { ErrorCard } from 'containers/common/ErrorCard';
import { LoadingCard } from 'containers/common/LoadingCard';
import UpgradeCheckButton from 'containers/common/UpgradeCheckButton';
import {
    Message,
    OPERATION,
    ScreenerModel,
    ScreenerResult,

} from 'api';
import { MultiSelectField, SelectField, SelectOption, selectOptionsFromEnum } from 'containers/common/SelectField';
import CommonUtil, { CreateUpdateMode } from 'util/CommonUtil';
import MultiStockTrade from '../Trade/MultiStockTrade';
import withNavBarAndAuth from '../HOC/NavBarWrapper';

import EmptyCard from '../common/EmptyCard';
import { InflyMessage } from '../common/InflyMessage';
import { AppContext } from 'util/appContext';


import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { InflyIcon } from 'containers/common/InflyIcon';
import { Label } from '@mui/icons-material';
import { LabelAndValue } from 'containers/common/LabelAndValue';
import { DataParam, DataParamsRequest, DataType } from 'api/models/trigger/DataParam';
import { useParams, useNavigate } from 'react-router-dom';
import DialogButton from 'containers/common/DialogButton';
import InflyDialog from 'containers/common/InflyDialog';
import { FinancialField } from 'api/models/trigger/FinancialField';
import { stringify } from 'querystring';
import { DataDisplayCard } from 'containers/common/DataDisplayCard';
import A from 'containers/common/Anchor';
import ReactGA from 'react-ga4';
import { ScreenerFormDialog } from './ScreenerForm';



export function ScreenerDetailsPage(): React.JSX.Element {

    let { screenerId } = useParams();

    const [screenerModel, setScreenerModel] = useState<ScreenerModel | null>();
    const [screenerResult, setScreenerResult] = useState<ScreenerResult | null>();
    const [openScreenerEdit, setOpenScreenerEdit] = useState<boolean>(false);

    const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]);
    const [tradeDialogOpen, setTradeDialogOpen] = useState(false);

    const [additionalIndicators, setAdditionalIndicators] = useState<DataParam[]>([]);
    const [additionalIndicatorValues, setAdditionalIndicatorValues] = useState<Map<string, Map<string, number>>>();


    const [message, setMessage] = useState<Message>();

    useEffect(() => {

        ReactGA.event('screener_page_view', {});

        async function fetchScreener(screenerId: number) {
            const screenerApiClient = AppContext.getInstance().screenerApiClient;
            try {
                const scModel: ScreenerModel = await screenerApiClient.getScreener(screenerId);
                setScreenerModel(scModel);
            } catch (error) {
                setScreenerModel(null)
                setMessage(Message.fromError(error));
            }

            try {
                const scResult: ScreenerResult = await screenerApiClient.refreshScreener(screenerId)
                setScreenerResult(scResult);
            } catch (error) {
                setScreenerResult(null);
                setMessage(Message.fromError(error));
            }
        }
        if (screenerId) {
            fetchScreener(CommonUtil.strToInteger(screenerId)!);
        } else {
            setMessage(Message.error("Invalid URL - The url must be /screener/screenerId"))
        }

    }, [screenerId])


    useEffect(() => {
        let interval = setInterval(async () => {
            if (screenerModel) {
                const screenerApiClient = AppContext.getInstance().apiClientFactory.screenerApiClient;
                try {
                    const scResult: ScreenerResult = await screenerApiClient.refreshScreener(screenerModel.screenerId)
                    setScreenerResult(scResult);
                } catch (error) {
                    setScreenerResult(null);
                    setMessage(Message.fromError(error));
                }
            }

        }, 30000)
        return () => clearInterval(interval);
    }, []);


    if (screenerModel === undefined) {
        return <LoadingCard title={'Stock Screener'} />
    }

    if (screenerModel === null) {
        return <ErrorCard message={message?.message!} />
    }

    function getResultColumns(): GridColDef[] {
        let resultColumns: GridColDef[] = [
            { field: 'Symbol', headerName: 'Symbol', renderCell:(params: GridRenderCellParams) => <A href={`/stockdetail/${params.value}`} target={'_blank'}>{params.value}</A> },
        ];

        if (screenerModel?.screenerScope.industries && screenerModel?.screenerScope.industries.length > 0) {
            resultColumns.push({ field: 'Industry', headerName: 'Industry', width: 400 });
        }

        for (let [alias, dp] of screenerModel!.query.dataParams.entries()) {
            const dataType = dp.get(DataParam.DATATYPE);
            if (dataType !== DataType.CONST) {
                resultColumns.push({ field: alias, headerName: alias, flex: 1 })
            }
        }

        for (let dp of additionalIndicators) {
            const alias = dp.getOrCreateAlias();
            resultColumns.push({ field: alias, headerName: alias })
        }

        return resultColumns;
    }

    function getResultRows() {
        let rows: Map<string, any>[] = screenerResult!.results;
        for (let row of rows) {
            const symbol: string = row.get("Symbol")! as string;
            let addValues: Map<string, number> | undefined = additionalIndicatorValues?.get(symbol);
            if (addValues) {
                for (const [key, value] of addValues.entries()) {
                    row.set(key, value);
                }
            }
        }
        // convert list of map to list of objects to feeed to datagrid
        let dataGridRows = rows.map(row => Object.fromEntries(row.entries()));
        return dataGridRows;

    }


    function onEditReturn(screener: ScreenerModel, result?: ScreenerResult, message?: Message) {
        setScreenerModel(screener);
        if (result) {
            setScreenerResult(result);
        }
        setMessage(message);
        setOpenScreenerEdit(false);
    }

    async function onSelectIndicators(indicators: DataParam[]) {
        setAdditionalIndicators(indicators);
        const marketDataApiClient = AppContext.getInstance().apiClientFactory.marketDataAPIClient;
        let symbols: string[] = screenerResult!.results.map(row => row.get('symbol'));
        let dataParamsMap = new Map(indicators.map(dp => [dp.getOrCreateAlias(), dp]));
        let dataParamsRequest = new DataParamsRequest(symbols, dataParamsMap);
        let response = await marketDataApiClient.getDataParams(dataParamsRequest);
        setAdditionalIndicatorValues(response);

    }

    function CustomToolbar(): React.JSX.Element {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <SelectIndicatorsBtn indicators={additionalIndicators} onChange={onSelectIndicators} />
                <Button disabled={selectedSymbols.length == 0} onClick={(event) => setTradeDialogOpen(true)}>Trade</Button>
                <GridToolbarExport />

            </GridToolbarContainer>
        );
    }



    return (
        <>
            {openScreenerEdit && screenerModel && <ScreenerFormDialog
                onSubmit={onEditReturn}
                onCancel={() => setOpenScreenerEdit(false)}
                screener={screenerModel} />
            }

            {tradeDialogOpen && <MultiStockTrade
                symbol={selectedSymbols}
                onSuccessReturn={(message: Message) => setMessage(message)}
                onCancel={() => setTradeDialogOpen(false)}
            />}

            <Stack spacing={1}>

                <Breadcrumbs>
                    <A href='/screener/list'> Screener List  </A>
                    <Typography>  {screenerModel.screenerId}  </Typography>
                </Breadcrumbs>

                <InflyMessage message={message} onClose={() => setMessage(undefined)} />

                <DataDisplayCard title={'ScreenerDetails'}
                    cardActionHeader={<IconButton onClick={() => setOpenScreenerEdit(true)} disabled={screenerModel === null || screenerModel === undefined}>
                        <InflyIcon name='edit' />
                    </IconButton>}
                    data={new Map([["Name", screenerModel.name], ["Description", screenerModel.description], ["Query", screenerModel.query.toString()]])}>

                </DataDisplayCard>

                {screenerResult === undefined && 
                    <LoadingCard title='Screener Results'/>
                }

                {screenerResult === null && message &&
                    <ErrorCard title='Screener Run Error' message={message.message}/>
                }

                {screenerResult &&
                    <Card>
                        <CardHeader title={'Screener Results'} />
                        <CardContent>
                            <Box sx={{ height: 1200, width: '100%' }}>

                            

                                    <DataGrid
                                        rows={getResultRows()}
                                        getRowId={row => row['Symbol']}
                                        columns={getResultColumns()}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 20 } },
                                        }}
                                        pageSizeOptions={[5, 10, 20]}
                                        checkboxSelection
                                        disableRowSelectionOnClick
                                        onRowSelectionModelChange={(ids) => {
                                            let symobls: string[] = ids as string[];
                                            setSelectedSymbols(symobls);
                                        }}
                                        slots={{
                                            toolbar: CustomToolbar,
                                        }}
                                    />
                            

                            </Box>
                        </CardContent>
                    </Card>
                }

            </Stack>

        </>
    )
}

function SelectIndicatorsBtn({ indicators, onChange }: { indicators: DataParam[], onChange: (indicators: DataParam[]) => void }): React.JSX.Element {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedIndicators, setSelectedIndicators] = useState(indicators);

    useEffect(() => {
        setSelectedIndicators(indicators);
    }, [indicators])


    function onDialogExecute() {
        setDialogOpen(false);
        onChange(selectedIndicators);
    }

    const financialOptions: SelectOption[] = selectOptionsFromEnum(FinancialField);
    const values: SelectOption[] = selectedIndicators.map(dp => dp.get(DataParam.ALIAS))

    function onSelectChange(selectOptions: SelectOption[]) {
        const dataParams: DataParam[] = selectOptions.map(s => DataParam.newFinancialParam(FinancialField[s.value]))
        setSelectedIndicators(dataParams);
    }

    return (<>

        {dialogOpen &&
            <InflyDialog
                open={dialogOpen}
                maxWidth="md"
                fullWidth
                title="Select Indicators"
                okText='Apply'
                onExecute={onDialogExecute}
                onClose={() => setDialogOpen(false)}
            >
                <Stack>
                    <MultiSelectField options={financialOptions} value={values} onSelectChange={onSelectChange} />
                </Stack>
            </InflyDialog>
        }

        <Button onClick={() => setDialogOpen(true)}>Select Indicators</Button>
    </>)

}

export default withNavBarAndAuth(ScreenerDetailsPage);
