import {InvestflyModel} from '../InvestflyModel';

export class OptionExpiry extends InvestflyModel {
    month: number;
    day: number;
    year: number;

    toString(): string {
        return this.year + "-" + this.month + "-" + this.day;
    }

    static fromString(arg: string): OptionExpiry {
        const data: string[] = arg.split("-");

        const optionExpiry = new OptionExpiry();
        optionExpiry.year = Number(data[0]);
        optionExpiry.month = Number(data[1]);
        optionExpiry.day = Number(data[2]);

        return optionExpiry;
    }


    static parseJSON(obj: {[index:string]: any}): OptionExpiry {
        let optionExpiry = new OptionExpiry();
        Object.assign(optionExpiry, obj);
        return optionExpiry;
    }
}