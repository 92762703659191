/** @format */

import React, { Component } from 'react';
import {
    MarketDataAPIClient,
    Message,
    OptionChain,
    OptionExpiry,
    OptionType,
    OptionQuote,
} from '../../api';
import CommonUtil from '../../util/CommonUtil';
import { Card, CardContent, CardHeader, FormControl, Stack } from '@mui/material';
import { AppContext } from '../../util/appContext';
import { LoadingCard } from './LoadingCard';
import { ErrorCard } from './ErrorCard';
import {SelectField} from './SelectField';
import InflyTabs, { InflyTab } from './InflyTabs';
import { OptionQuoteTable } from '../Trade/OptionTradeForm';
import { InflyIcon } from './InflyIcon';

interface StockOptionChainProps {
    symbol: string;
    onOptionQuoteSelect?: (optionQuote: OptionQuote) => void;
}

interface StockOptionChainState {
    selectedOptionExpiry?: OptionExpiry;
    ready: boolean;
    message?: Message;
    optionChains: Map<string, OptionChain>; //key is symbol+expiry
    expiries: Map<string, OptionExpiry[]>;
    tab: any;
}

class StockOptionChain extends Component<StockOptionChainProps, StockOptionChainState> {
    marketDataAPIClient: MarketDataAPIClient;

    constructor(props: StockOptionChainProps) {
        super(props);
        this.marketDataAPIClient = AppContext.getInstance().marketDataApiClient;

        this.state = {
            selectedOptionExpiry: undefined!,
            optionChains: new Map(),
            ready: false,
            expiries: new Map(),
            tab: OptionType.CALL,
        };
    }

    componentDidMount = async () => {
        await this.getOptionExpiryChain();
    };
    getOptionExpiryChain = async () => {
        try {
            let { expiries, optionChains, ...rest } = this.state;
            let _expiries: OptionExpiry[] = await this.marketDataAPIClient.getOptionExpiryDates(
                this.props.symbol
            );
            expiries.set(this.props.symbol, _expiries);
            let optionChain = await this.marketDataAPIClient.getOptionChain(
                this.props.symbol,
                _expiries[0]
            );
            const key = this.createOptionChainKey(this.props.symbol, _expiries[0]);
            optionChains.set(key, optionChain);
            this.setState({
                expiries,
                optionChains,
                selectedOptionExpiry: _expiries[0],
            });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        } finally {
            this.setState({ ready: true });
        }
    };
    getOptionExpiry = async () => {
        try {
            let { expiries, ...rest } = this.state;
            const _expiries: OptionExpiry[] = await this.marketDataAPIClient.getOptionExpiryDates(
                this.props.symbol
            );
            expiries.set(this.props.symbol, _expiries);
            this.setState({ ...rest, expiries: expiries, selectedOptionExpiry: _expiries[0] });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    };
    setSelectedOptionExpiry = (selectedOptionExpiry: OptionExpiry) => {
        this.setState({ selectedOptionExpiry }, async () => {
            await this.getOptionChain();
        });
    };

    createOptionChainKey = (symbol: string, optionExpiry: OptionExpiry) => {
        return symbol + '_' + optionExpiry;
    };

    getOptionChain = async () => {
        try {
            if (this.state.selectedOptionExpiry) {
                const key = this.createOptionChainKey(
                    this.props.symbol,
                    this.state.selectedOptionExpiry
                );
                let { optionChains } = this.state;
                if (optionChains && optionChains.get(key)) {
                    return Promise.resolve(optionChains.get(key)!);
                }
                const optionChain = await this.marketDataAPIClient.getOptionChain(
                    this.props.symbol,
                    this.state.selectedOptionExpiry!
                );
                optionChains.set(key, optionChain);
                this.setState({ optionChains }, () =>
                    CommonUtil.scrollToElement('optionChain', 'smooth')
                );
            } else {
                throw Error('Please select option expiry first to load the option chain list');
            }
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    };

    expiriesOptions = () => {
        return (
            this.state.expiries?.get(this.props.symbol)?.map((o) => {
                return { label: o.toString(), value: o.toString() };
            }) ?? [{ label: 'No option available', value: '' }]
        );
    };

    render() {
        if (!this.state.ready) {
            return <LoadingCard title={'Option Chain'} />;
        }
        if (this.state.ready && this.state.message?.isError()) {
            return (
                <ErrorCard
                    title={'Option Chain'}
                    message={this.state.message?.message}
                />
            );
        }
        const optionChainKey = this.createOptionChainKey(
            this.props.symbol,
            this.state.selectedOptionExpiry!
        );
        const optionChain = this.state.optionChains.get(optionChainKey);
        let expiriesOptions = this.expiriesOptions();

        return (
            <>
                <Card>
                    <CardHeader
                        title={'Options'}
                        action={
                            <FormControl component='fieldset'>
                                <SelectField
                                    label={'Expiry Date'}
                                    id='expiryOptions'
                                    fullWidth
                                    value={this.state.selectedOptionExpiry!}
                                    onChange={(event: any) =>
                                        this.setSelectedOptionExpiry(
                                            OptionExpiry.fromString(event.target.value)
                                        )
                                    }
                                    key='expiryOptions_select'
                                    options={expiriesOptions}
                                />
                            </FormControl>
                        }
                    />
                    <CardContent>
                        <InflyTabs
                            variant='scrollable'
                            allowScrollButtonsMobile
                            scrollButtons='auto'
                        >
                            <InflyTab label={OptionType.CALL}>
                                <OptionQuoteTable
                                    selectionIcon={<InflyIcon name='send' fontSize='small' />}
                                    optionType={OptionType.CALL}
                                    onOptionQuoteSelect={this.props.onOptionQuoteSelect}
                                    stockSymbol={this.props.symbol}
                                    quotes={optionChain ? optionChain.calls : []}
                                />
                            </InflyTab>
                            <InflyTab label={OptionType.PUT}>
                                <OptionQuoteTable
                                    selectionIcon={<InflyIcon name='send' fontSize='small' />}
                                    optionType={OptionType.PUT}
                                    onOptionQuoteSelect={this.props.onOptionQuoteSelect}
                                    stockSymbol={this.props.symbol}
                                    quotes={optionChain ? optionChain.puts : []}
                                />
                            </InflyTab>
                        </InflyTabs>
                    </CardContent>
                </Card>
            </>
        );
    }
}

export default StockOptionChain;
