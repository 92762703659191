/** @format */

import { TabPanel } from '@mui/lab';
import { Box, Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import { Message } from 'api';
import React from 'react';
import withScreenSize from 'containers/HOC/WithScreenSize';
import { ScreenSizeProp } from 'util/commonProps';

interface InflyTabProps extends TabProps {
    label: string; // this is used to render the top <Tan>
    value?: any;
    children: any;
}

export function InflyTab(props: InflyTabProps) {
    return <TabPanel value={props.value}>{props.children}</TabPanel>;
}

interface InflyTabsProps extends TabsProps, ScreenSizeProp {
    // specify "value" property to set activeTab
    children: any;
    onTabSelect?: (newValue: any) => void;
}

interface InflyTabsState {
    selectedTab: number;
}

class InflyTabs extends React.Component<InflyTabsProps, InflyTabsState> {
    constructor(props: InflyTabsProps) {
        super(props);
        this.state = { selectedTab: props.value ?? 0 };
    }

    handleChange = (event: React.SyntheticEvent, newValue: number) => {
        this.setState({ selectedTab: newValue });
        if (this.props.onTabSelect) {
            const children = [this.props.children].flat();
            let value = children[newValue].props.value ?? newValue;
            this.props.onTabSelect(value);
        }
    };

    componentDidUpdate(
        prevProps: Readonly<InflyTabsProps>,
        prevState: Readonly<InflyTabsState>,
        snapshot?: any
    ) {
        if (prevProps.value !== this.props.value) {
            this.setState({ selectedTab: this.props.value });
        }
    }

    render(): React.ReactNode {
        const { children, onTabSelect, value, isMobile, ...tabsProps } = this.props;

        // When tabs are conditionally rendered, the un-rendered tabs will be False
        let tabs = children.filter((childNode: any) => typeof childNode !== 'boolean');

        return (
            <>
                <Box>
                    <Tabs
                        value={this.state.selectedTab}
                        onChange={this.handleChange}
                        sx={{ borderBottom: 1, marginBottom: 1, borderColor: 'divider' }}
                        scrollButtons={isMobile}
                        allowScrollButtonsMobile={isMobile}
                        variant={isMobile ? 'scrollable' : 'standard'}
                        {...tabsProps}
                    >
                        {tabs.map((tab: any, index: number) => {
                            return (
                                <Tab
                                    label={tab.props.label}
                                    id={tab.props.label}
                                    disabled={tab.props.disabled ?? false}
                                    key={`${tab.props.label}_${index}`}
                                />
                            );
                        })}
                    </Tabs>

                    {tabs[this.state.selectedTab].props.children}
                </Box>
            </>
        );
    }
}

export default withScreenSize(InflyTabs);
