/** @format */

import { Box, Paper, Stack, Typography } from '@mui/material';
import { AppConstant } from 'api';
import React, { Component } from 'react';
import A from 'containers/common/Anchor';

const ROOT_DOMAIN = AppConstant.ROOT_DOMAIN;

export default class DesktopFooter extends Component {
    render() {
        return (
            <Stack
                flexDirection={{ md: 'row', xs: 'column' }}
                justifyContent={{ md: 'space-between', xs: 'center' }}
                alignItems={{ xs: 'space-between', md: 'center' }}
                // to set background color of Paper compaper
                component={Paper}
                fontSize={'1rem'}
                position={{ md: 'absolute' }}
                // width value is set 100% beacuse of position property
                width={{ md: '100%' }}
                bottom={{ md: 0 }}
                left={{ md: 0 }}
            >
                <Stack flexDirection={'row'} gap={2} padding={'10px 5px'}>
                    <A href={`${ROOT_DOMAIN}/guides/market.html`} newTab={true}>
                        Guides
                    </A>

                    <A href={`${ROOT_DOMAIN}/affiliate.html`} newTab={true}>
                        Affiliate
                    </A>

                    <A href={'/upgrade/pricing'}>Pricing</A>

                    <A href={`${ROOT_DOMAIN}/about.html`} newTab={true}>
                        About&nbsp;Us
                    </A>

                    <A href={`${ROOT_DOMAIN}/contact_us.html`} newTab={true}>
                        Contact&nbsp;Us
                    </A>

                    <A href={`${ROOT_DOMAIN}/blog/blog.html`} newTab={true}>
                        Blog
                    </A>

                    <A href={`${ROOT_DOMAIN}/guides/about.html`} newTab={true}>
                        Guides
                    </A>
                </Stack>
                <Stack
                    fontSize={'1rem'}
                    lineHeight={'12px'}
                    textAlign={'center'}
                    justifyContent={'flex-end'}
                >
                    <Typography component={'p'} margin={[1, 0]} textAlign={'center'}>
                        <A href={`${ROOT_DOMAIN}/terms_service.html`}>Terms of Use</A> &nbsp;
                        Copyright © <span>{new Date().getFullYear()}</span>&nbsp;
                        <A href={ROOT_DOMAIN}>Investfly</A>&nbsp;All Rights Reserved.
                    </Typography>
                </Stack>
            </Stack>
        );
    }
}
