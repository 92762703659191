import { InvestflyUtil } from "api/utils/investflyUtil";
import {InvestflyModel} from "../InvestflyModel";
import { DatedValue } from "../common/commonModel";

export class PerformanceMetrics extends InvestflyModel {

    netReturn: number;
    annualizedReturn: number;
    profitFactor: number;


    totalTrades: number;
    winRatioPct: number;
    avgProfitPerTradePct: number;
    avgLossPerTradePct: number;
    meanReturnPerTradePct: number;
    sharpeRatioPerTrade: number;

    maxDrawdownPct: number;
    portfolioValues: DatedValue[];


    public static  parseJSON(obj: {[index:string]: any} ): PerformanceMetrics {
        let perf: PerformanceMetrics = new PerformanceMetrics();
        Object.assign(perf, obj);
        perf.portfolioValues = InvestflyModel.parseList(obj.portfolioValues, DatedValue.parseJSON);
        return perf;
    }
}