import {InvestflyModel} from '../InvestflyModel';
import {BacktestStatus} from './backtestStatus';
import {MatchedTrade} from './matchedTrade';
import {PerformanceMetrics} from './performanceMetrics';

export class BacktestResult {

    strategyId: number;
    status: BacktestStatus;

	matchedTrades?: MatchedTrade[];
	performance?: PerformanceMetrics;
	
    static parseJSON(obj: {[index:string]: any}): BacktestResult{
        let result = new BacktestResult();
        result.strategyId = obj.strategyId;
        result.status = BacktestStatus.parseJSON(obj.status);
        result.matchedTrades = InvestflyModel.parseList(obj.matchedTrades, MatchedTrade.parseJSON);
        if(obj.performance) result.performance = PerformanceMetrics.parseJSON(obj.performance);
        
        return result;
    }

}
