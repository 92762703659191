import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Stack,
    Grid,
} from '@mui/material';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import A from 'containers/common/Anchor';
import { Message, OPERATION, StrategyAPIClient, USER_LIMITS, User } from 'api';
import { AppContext } from '../../util/appContext';
import { TradingStrategyModel } from 'api/models/strategy/TradingStrategyModel';
import { NewStrategyDialogButton } from './NewStrategyDialog';
import { useNavigate } from 'react-router-dom';
import { ListItemDisplayCard } from 'containers/common/ListItemDisplayCard';
import DialogButton from 'containers/common/DialogButton';
import { InflyIcon } from 'containers/common/InflyIcon';
import { InflyMessage } from 'containers/common/InflyMessage';
import { LoadingCard } from 'containers/common/LoadingCard';
import { ErrorCard } from 'containers/common/ErrorCard';
import InflyTabs, { InflyTab } from 'containers/common/InflyTabs';
import ReactGA from 'react-ga4';
import { CreateStrategyFromSampleList } from './StrategySampleList';
import SessionUtil from 'util/SessionUtil';
import { NewScreenerDialogButton } from 'containers/Screener/ScreenerForm';

const TAB_MY_STRATEGY = "My"
const TAB_SAMPLE = "Sample"

export function StraetegyListPage(): React.JSX.Element {

    const navigate = useNavigate();

    const [strategyList, setStrategyList] = useState<TradingStrategyModel[]>();
    // generally, we don't need to track selefcted tab state as InflyTab handles it. But here, we want to show sample when NewStrategy is clicked from
    // My Strateiges tab, but not when its clicked from the sample tab, thats why we track state
    const [selectedTab, setSelectedTab] = useState<string>();

    const [message, setMessage] = useState<Message>();

    async function loadStrategyList() {
        try {
            let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
            let stList = await strategyApiClient.listStrategies();
            setStrategyList(stList);

            if(stList.length > 0){
                setSelectedTab(TAB_MY_STRATEGY);
            }else{
                setSelectedTab(TAB_SAMPLE);
            }
        } catch (error) {
            setMessage(Message.fromError(error))
        }
    }

    useEffect(() => {
        ReactGA.event('strategylist_page_view', {});
        loadStrategyList();
    }, [])


    if (strategyList === undefined) {
        if (message) {
            return <ErrorCard message={message.message} />
        } else {
            return <LoadingCard title='Strategy List' />
        }
    }

    function onNewStrategyCreate(strategyModel: TradingStrategyModel) {
        setStrategyList([strategyModel, ...strategyList!])
        navigate(`/strategy/${strategyModel.strategyId}`)
    }

    async function onDeleteConfirm(strategyId: number): Promise<any> {
        try {
            let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
            const message = await strategyApiClient.deleteStrategy(strategyId);
            await loadStrategyList();
            setMessage(message);
        } catch (error) {
            setMessage(Message.fromError(error));
        }
    }

    function createDeleteButton(strategyModel: TradingStrategyModel): React.JSX.Element {
        return <DialogButton
            dialogprompt='Are you sure you want to delete this strategy?'
            dialogtitle={'Delete Strategy'}
            color={'error'}
            startIcon={
                <InflyIcon name='delete' fontSize={'small'} />
            }
            buttonType={'iconButton'}
            onClick={() => onDeleteConfirm(strategyModel.strategyId)}
        />
    }

    function createCloneButton(strategyModel: TradingStrategyModel): React.JSX.Element|undefined {
        // the upgrade check button does not work with icons, so we will return button only if the operation is allowed
        const userInfo: User = SessionUtil.findUserInfo() as User;
        const userType = userInfo.userType;
        const limit: number  = USER_LIMITS[userType][OPERATION.CREATE_STRATEGY] as number;
        const isOperationAllowed =  strategyList!.length === undefined || strategyList!.length < limit;
        if (!isOperationAllowed){
            return undefined;
        }
        let cloneModel = strategyModel.clone();
        cloneModel.strategyName = cloneModel.strategyName + " Copy";
        cloneModel.strategyId = undefined!;
        return <NewStrategyDialogButton showSample={false} cloneStrategy={cloneModel} iconName="copy" onCreate={onNewStrategyCreate} curStrategiesCount={strategyList!.length}/>

    }

    function createDeploymentInfo(strategyModel: TradingStrategyModel): Map<string, any> {
        let deploymentInfo = new Map<string, any>();
        if(strategyModel.portfolioId){
            deploymentInfo.set("Deployed To Account:", <A href={`/portfolio/${strategyModel!.brokerType}/${strategyModel!.portfolioId}`} >  <InflyIcon name='link' color='success'/> </A>  );
        }else{
            deploymentInfo.set("Deployed To Account:", <InflyIcon name='unLink' color='error'/>);
        }
        
        return deploymentInfo;

    }


    function onTabSelect(newTabSelection: any ){
        setSelectedTab(newTabSelection);
    }

    return (<Stack spacing={2}>
        <Card>
            <CardHeader title={'Strtegy List'}
                action={<Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Typography variant={'subtitle2'}>
                        <A href={'https://www.youtube.com/watch?v=baPp4rAepbA'} newTab={true}>
                            Need Help? Watch Tutorial
                        </A>
                    </Typography>
                    <NewStrategyDialogButton showSample={selectedTab !== TAB_SAMPLE} onCreate={onNewStrategyCreate} curStrategiesCount={strategyList!.length}/>
                </Stack>}
            />

            <CardContent>
                <Stack gap={2}>
                    <InflyMessage message={message} onClose={() => setMessage(undefined)} />

                    <InflyTabs onTabSelect={onTabSelect}>
                        {strategyList.length > 0 &&
                            <InflyTab label='My Strategies' value={TAB_MY_STRATEGY}>
                                <Grid container spacing={2}>
                                    {strategyList.map(strategyModel => {
                                        return <Grid key={strategyModel.strategyId} item xs={12} md={6}>
                                            <ListItemDisplayCard
                                                title={strategyModel.strategyName}
                                                href={`/strategy/${strategyModel.strategyId}`}
                                                iconName={strategyModel.isPublic ? 'public' : 'private'}
                                                description={strategyModel.strategyDesc}
                                                deleteBtn={createDeleteButton(strategyModel)}
                                                cloneBtn={createCloneButton(strategyModel)}
                                                labelValues={createDeploymentInfo(strategyModel)}
                                            />
                                        </Grid>
                                    }
                                    )}
                                </Grid>
                            </InflyTab>
                        }

                        <InflyTab label='Strategy Templates' value={TAB_SAMPLE}>
                            <CreateStrategyFromSampleList onCreate={onNewStrategyCreate} strategyCount={strategyList.length}/>
                        </InflyTab>
                    </InflyTabs>
                </Stack>

            </CardContent>
        </Card>
    </Stack>);
}

export default withNavBarAndAuth(StraetegyListPage);
