/** @format */

import {
    AppBar,
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Dialog,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Slide,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import A from 'containers/common/Anchor';
import { InflyIcon } from 'containers/common/InflyIcon';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { NavigateProp, ThemeProp } from 'util/commonProps';
import { ColorModeContext } from 'App';
import { UserAPIClient } from 'api/services/userAPIClient';
import SessionUtil from 'util/SessionUtil';
import { AppContext } from 'util/appContext';
import mobileDark from 'static/images/mobile-logo-dark.png';
import mobileWhite from 'static/images/mobile-logo-white.png';

interface MobileBottomNavigationProps extends ThemeProp, NavigateProp {
    toggleColorMode: () => any;
}
type NavigationValue =
    | 'alerts'
    | 'portfolios'
    | 'screeners'
    | 'watchList'
    | 'marketNews'
    | 'marketdata';

interface MobileBottomNavigationState {
    value: NavigationValue;
    moreOption: boolean;
}
const SlideTransitionComponent = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='left' ref={ref} {...props} />;
});

class MobileBottomNav extends React.Component<
    MobileBottomNavigationProps,
    MobileBottomNavigationState
> {
    constructor(props: MobileBottomNavigationProps) {
        super(props);
        this.state = { value: 'marketdata', moreOption: false };
    }

    onLogout = () => {
        const userAPIClient: UserAPIClient = AppContext.getInstance().userApiClient;
        userAPIClient.logout().then(() => {
            SessionUtil.clearSession();
            this.props.navigate('/');
        });
    };

    render() {
        const logo = this.props.mode === 'dark' ? mobileWhite : mobileDark;
        const BottomNavigationItems = [
            {
                value: 'dashboard_one',
                label: 'Dashboard',
                href: '/dashboard',
            },
            {
                label: 'Market',
                value: 'market',
                href: '/marketdata#market',
            },
            {
                value: 'strategies',
                label: 'Strategies',
                href: '/strategy/list',
            },
            // {
            //     label: 'Portfolios',
            //     value: 'portfolioList',
            //     href: '/portfolio/list',
            // },
            {
                label: 'WatchLists',
                value: 'watchList',
                href: '/marketdata#watchlist',
            },
            // {
            //     label: 'Screeners',
            //     value: 'screeners',
            //     href: '/screener/list',
            // },
            {
                label: 'Alerts',
                value: 'alerts',
                href: '/marketdata#alerts',
            },
            {
                label: 'more',
                value: 'more',
            },
            {
                value: 'affiliate',
                label: 'Referrals',
                href: '/myreferrals',
            },
            {
                value: 'stockdetail',
                label: 'stockdetail',
                href: '/stockdetail',
            },
            {
                value: 'indicator',
                label: 'Custom',
                href: '/indicator/list',
            },
            {
                label: 'Games',
                value: 'myGameList',
                href: '/games',
            },
            {
                label: 'User Profile',
                value: 'userProfile',
                href: '/user/setting',
            },
            {
                label: 'Switch Theme',
                value: this.props.mode,
                onClick: () => this.props.toggleColorMode(),
            },
            {
                label: 'Log out',
                value: 'logout',
                onClick: () => this.onLogout(),
            },
        ];

        return (
            <>
                <Dialog
                    fullScreen
                    open={this.state.moreOption}
                    onClose={() => this.setState({ moreOption: false })}
                    TransitionComponent={SlideTransitionComponent}
                >
                    <AppBar
                        sx={(theme: Theme) => ({
                            position: 'relative',
                            color: theme.palette.mode === 'light' ? 'black' : 'white',
                        })}
                    >
                        <Toolbar>
                            <IconButton
                                edge='start'
                                color='inherit'
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    this.setState({ moreOption: false });
                                }}
                                aria-label='close'
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography ml={2} flex={1} variant='h6' component='div'>
                                More actions
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <List>
                        {BottomNavigationItems.filter((item) => item.label !== 'more').map(
                            (item, index) => (
                                <React.Fragment key={item.value + '_' + index}>
                                    <ListItemButton
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            if (item.onClick) {
                                                item.onClick();
                                            }
                                            if (item.href) {
                                                this.props.navigate(item.href);
                                                this.setState({ moreOption: false });
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <InflyIcon name={item.value} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item.label}
                                            primaryTypographyProps={{
                                                variant: 'body1',
                                                marginX: 2,
                                            }}
                                        />
                                    </ListItemButton>
                                    <Divider
                                        sx={{
                                            borderWidth:
                                                index === 7
                                                    ? 3
                                                    : index === BottomNavigationItems.length - 2
                                                    ? 0
                                                    : 1,
                                        }}
                                    />
                                </React.Fragment>
                            )
                        )}
                    </List>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <A href={'https://www.investfly.com'} newTab={true}>
                                        <Box
                                            component='img'
                                            src={logo}
                                            width={100}
                                            alt='investfly logo'
                                        />
                                    </A>
                                }
                                secondary={`Copyright © ${new Date().getFullYear()} Infly LLC `}
                                secondaryTypographyProps={{ align: 'center' }}
                                primaryTypographyProps={{ align: 'center', variant: 'body1' }}
                            />
                        </ListItem>
                    </List>
                </Dialog>
                <Paper
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        p: 0,
                        right: 0,
                        display: {
                            xs: 'flex',
                            md: 'none',
                        },
                    }}
                    elevation={3}
                >
                    <BottomNavigation
                        showLabels
                        value={this.state.value}
                        onChange={(event, newValue) => this.setState({ value: newValue })}
                        sx={{
                            width: '100%',
                            position: 'absolute',
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}
                    >
                        {BottomNavigationItems.slice(0, 6).map((item) => (
                            <BottomNavigationAction
                                label={item.label}
                                value={item.value}
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    if (item.href) {
                                        this.props.navigate(item.href);
                                    } else {
                                        this.setState({ moreOption: true });
                                    }
                                }}
                                icon={<InflyIcon name={item.value} fontSize={'large'} />}
                                key={item.value}
                                sx={{
                                    padding: 1 / 3,
                                    minWidth: 'min-content',
                                    maxWidth: 'max-content',
                                }}
                            />
                        ))}
                    </BottomNavigation>
                </Paper>
            </>
        );
    }
}

// Wrapper below to inject ColorModeContext.toggleColorMode function usings React Hooks

type MobileButtomNavBarProp = Omit<MobileBottomNavigationProps, 'toggleColorMode'>;

export default function MobileButtomNavBar(props: MobileButtomNavBarProp) {
    let colorModeContext = React.useContext(ColorModeContext);
    return <MobileBottomNav {...props} toggleColorMode={colorModeContext.toggleColorMode} />;
}
