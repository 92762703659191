/** @format */

import React, { Component } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    Hidden,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { Game, GameAPIClient, GameStatus, Message, UserGameScore } from 'api';
import { AppContext } from 'util/appContext';
import { InflyMessage } from 'containers/common/InflyMessage';
import { RouteProps, ScreenSizeProp } from 'util/commonProps';
import AddEditGameBtn from './AddEditGameBtn';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import { LoadingCard } from 'containers/common/LoadingCard';
import DocumentHeader from 'containers/common/DocumentHeader';
import A from 'containers/common/Anchor';
import DialogButton from 'containers/common/DialogButton';
import { InflyIcon } from 'containers/common/InflyIcon';
import { JoinGameBtn, JoinUpdateMode } from './JoinGameBtn';
import { LabelAndValue } from 'containers/common/LabelAndValue';
import CommonUtil from 'util/CommonUtil';
import { ListItemDisplayCard } from 'containers/common/ListItemDisplayCard';
import ReactGA from 'react-ga4';

interface MyGamesProps {}

interface MyGamesState {
    createdGames: Game[];
    invitedGames: Game[];
    joinedGames: Game[];

    message?: Message;
    ready: boolean;
}

class MyGames extends Component<MyGamesProps, MyGamesState> {
    gameApiClient: GameAPIClient;

    constructor(props: MyGamesProps) {
        super(props);
        this.gameApiClient = AppContext.getInstance().gameApiClient;
        this.state = {
            createdGames: [],
            invitedGames: [],
            joinedGames: [],
            ready: false,
        };
    }

    async componentDidMount() {
        ReactGA.event('gameslist_page_view', {});
        this._reloadAllGames();
    }

    async _reloadAllGames() {
        try {
            const createdGames = await this.gameApiClient.listMyCreatedGames();
            const invitedGames = await this.gameApiClient.listMyInvitedGames();
            const joinedGames = await this.gameApiClient.listMyEnrolledGames();
            this.setState({ createdGames, invitedGames, joinedGames });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        } finally {
            this.setState({ ready: true });
        }
    }

    onCreateGameComplete = async (game?: Game) => {
        if (game) {
            let createdGames = await this.gameApiClient.listMyCreatedGames();
            let joinedGames = await this.gameApiClient.listMyEnrolledGames();
            this.setState({ createdGames, joinedGames });
        }
    };

    onCardAction = async (message: Message) => {
        this.setState({ message });
        await this._reloadAllGames();
    };

    onGameDeleteConfirm = async (game: Game) => {
        try{
            let message = await this.gameApiClient.deleteGame(game.gameId);
            await this._reloadAllGames();
            this.setState({message})
        }catch(error){
            this.setState({message: Message.fromError(error)});
        }

    };

    createDeleteButton(game: Game): React.JSX.Element {
        return <DialogButton
        dialogprompt='Are you sure you want to delete this game?'
        dialogtitle={'Delete Game'}
        color={'error'}
        startIcon={
            <InflyIcon  name='delete'  fontSize={'small'}  />
        }
        buttonType={'iconButton'}
        onClick={() => this.onGameDeleteConfirm(game)}
    />
    }

    render() {
        if (!this.state.ready) {
            return <LoadingCard title={'Games'}  />;
        }

        return (
            <DocumentHeader title={'My Game List'}>
                <Stack gap={2}>

                    <InflyMessage  message={this.state.message}  onClose={() => this.setState({ message: undefined })}  />

                    <Card>
                        <CardHeader
                            title='Created Games'
                            action={
                                <Hidden smDown>
                                    <AddEditGameBtn
                                        label='Create Game'
                                        onCreateGameReturn={this.onCreateGameComplete}
                                    />
                                </Hidden>
                            }
                        />
                        <CardContent>
                            <Hidden smUp>
                                <AddEditGameBtn
                                    startIcon={<InflyIcon name='add' />}
                                    label='Create Game'
                                    onCreateGameReturn={this.onCreateGameComplete}
                                />
                            </Hidden>
                            <Grid container spacing={2} justifyContent='space-between' mt={1}>
                                {this.state.createdGames.map((game: Game, index: number) => {
                                    return (
                                        <Grid key={game.gameId} item md={6} xs={12}>
                                            <ListItemDisplayCard 
                                                title={game.name} 
                                                href={`/game/${game.gameId}`}
                                                iconName={game.isPrivateGame ? 'private' : 'public'} 
                                                deleteBtn={this.createDeleteButton(game)}
                                                />
                                        </Grid>
                                    );
                                })}

                                {this.state.createdGames.length === 0 && (
                                    <Paper elevation={5}>
                                        <Stack gap={3} padding={4}>
                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                <Typography variant='h6'> Tip</Typography>
                                                <InflyIcon name='tip' />
                                            </Stack>
                                            <Typography variant='body2'>
                                            Host and run stock picking contest, algo trading strategy competition with Investfly Games.  Once you create a game, you can invite new users to join, monitor leaderboard, and get social!
                                            </Typography>

                                            <Typography variant='body2'>
                                                Click on Create Game to create a new game.
                                            </Typography>
                                        </Stack>
                                    </Paper>
                                )}

          
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader title='Invited Games' />
                        <CardContent>
                            <Grid container spacing={2} justifyContent='space-between'>
                                {this.state.invitedGames.map((game: Game, index: number) => {
                                    return (
                                        <Grid key={game.gameId} item md={6} xs={12}>
                                            <ListItemDisplayCard 
                                                title={game.name} 
                                                href={`/game/${game.gameId}`}
                                                iconName={game.isPrivateGame ? 'private' : 'public'} 
                                                />

                                        </Grid>
                                    );
                                })}

                                {this.state.invitedGames.length === 0 && (
                                    <Grid item>
                                        <Typography>You have no game invitations</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader title='Joined Games' />
                        <CardContent>
                            <Grid container spacing={2} justifyContent='space-between'>
                                {this.state.joinedGames.map((game: Game, index: number) => {
                                    return (
                                        <Grid key={game.gameId} item md={6} xs={12}>
                                            <Paper variant='outlined'>
                                            <ListItemDisplayCard 
                                                title={game.name} 
                                                href={`/game/${game.gameId}`}
                                                iconName={game.isPrivateGame ? 'private' : 'public'} 
                                                />
                                            </Paper>
                                        </Grid>
                                    );
                                })}

                                {this.state.joinedGames.length === 0 && (
                                    <Grid item>
                                        <Typography>You have not joined any games</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Stack>
            </DocumentHeader>
        );
    }
}

enum GameCardGroup {
    CREATED = 'CREATED',
    INVITED = 'INVITED',
    JOINED = 'JOINED',
}

interface GameCardProps {
    group: GameCardGroup;
    game: Game;
    onAnyAction: (message: Message) => void; // when JoinGame, Delete etc are called, the entire list must be re-loaded
}



export default withNavBarAndAuth(MyGames);
