export enum FutureType {
    EQUITY_INDEX = "EQUITY_INDEX",
    ENERGY = "ENERGY",
    METALS = "METALS",
    CURRENCY = "CURRENCY",
    INTEREST = "INTEREST",
    AGRICULTURE = "AGRICULTURE"
}

export enum FutureProduct {
    //EQUITY_INDEX
    ES = "ES", NQ = "NQ", YM = "YM", EMD = "EMD", FT1 = "FT1", FT5 = "FT5", IBV = "IBV", RS1 = "RS1", RSG = "RSG",
    RSV = "RSV", TRI = "TRI",
    // CURRENCY
    _6B = "_6B", _6C = "_6C", _6E = "_6E", _6A = "_6A", _6M = "_6M", _6L = "6L", _6R = "6R", E7 = "E7", M6E = "M6E",
    M6B = "M6B", CNH = "CNH", MCD = "MCD", BDI = "BDI",
    //METALS
    GC = "GC", SI = "SI", HG = "HG", PL = "PL", PA = "PA", MGC = "MGC", QC = "QC", QI = "QI", QO = "QO", SIL = "SIL",
    TIO = "TIO", ZNC = "ZNC",
    //ENERGY
    BZ = "BZ",CL = "CL",EH = "EH",HH = "HH",HO = "HO",NG = "NG",QG = "QG",QM = "QM", RB = "RB",
    //AGRICULTURE
    CB = "CB",CSC = "CSC",DC = "DC",GF = "GF",KE = "KE",ZL = "ZL",
    //INTEREST
    B1U = "B1U",F1U = "F1U",N1U = "N1U",
}


export class FutureProductDetail {
    futureProduct: FutureProduct;
    futureType: FutureType;
    description: string;
    unit: string;
    contractSize: number;


    static fillFutureProduct(futureProduct: FutureProduct, description: string, futureType: FutureType, unit: string, contractSize: number): FutureProductDetail {
        let futureProductDetail = new FutureProductDetail();
        futureProductDetail.futureProduct = futureProduct;
        futureProductDetail.description = description;
        futureProductDetail.unit = unit;
        futureProductDetail.contractSize = contractSize;
        futureProductDetail.futureType = futureType;
        return futureProductDetail;
    }

    static parseJSON(obj: { [index: string]: any }): FutureProductDetail {
        let futureProduct = new FutureProductDetail();
        Object.assign(futureProduct, obj);
        return futureProduct;
    }
}

export const FUTURE_PRODUCTS = new Map<FutureType, FutureProductDetail[]>();
//EQUITY_INDEX
FUTURE_PRODUCTS.set(FutureType.EQUITY_INDEX, [
    FutureProductDetail.fillFutureProduct(FutureProduct.ES, 'S&P E-Mini Futures', FutureType.EQUITY_INDEX, 'S&P 500 Index', 50),
    FutureProductDetail.fillFutureProduct(FutureProduct.NQ, 'E-mini NASDAQ 100 Futures', FutureType.EQUITY_INDEX, 'NASDAQ-100 Index', 20),
    FutureProductDetail.fillFutureProduct(FutureProduct.YM, 'Dow Industrial Futures', FutureType.EQUITY_INDEX, 'Dow Jones Industrial Average', 5),
    FutureProductDetail.fillFutureProduct(FutureProduct.EMD, 'S&P Midcap E-mini Futures', FutureType.EQUITY_INDEX, 'S&P MidCap 400 Index', 100),
    FutureProductDetail.fillFutureProduct(FutureProduct.FT1, 'E-mini FTSE 100 Index (GBP) Futures', FutureType.EQUITY_INDEX, 'FTSE 100 Index', 10),
    FutureProductDetail.fillFutureProduct(FutureProduct.FT5, 'E-mini FTSE China 50 Index Futures', FutureType.EQUITY_INDEX, 'FTSE China 50 Index', 2),
    FutureProductDetail.fillFutureProduct(FutureProduct.IBV, 'USD-Denominated Ibovespa Index Futures', FutureType.EQUITY_INDEX, 'Ibovespa Index', 1),
    FutureProductDetail.fillFutureProduct(FutureProduct.RS1, 'E-mini Russell 1000 Index Futures', FutureType.EQUITY_INDEX, 'Russell 1000 Index', 50),
    FutureProductDetail.fillFutureProduct(FutureProduct.RSG, 'E-mini Russell 1000 Growth Index Futures', FutureType.EQUITY_INDEX, 'Russell 1000 Growth Index', 50),
    FutureProductDetail.fillFutureProduct(FutureProduct.RSV, 'E-mini Russell 1000 Value Index Futures', FutureType.EQUITY_INDEX, 'Russell 1000 Value Index', 50),
    FutureProductDetail.fillFutureProduct(FutureProduct.TRI, 'S&P 500 Total Return Index Futures', FutureType.EQUITY_INDEX, 'S&P 500 Total Return Index', 25),
]);
//CURRENCY
FUTURE_PRODUCTS.set(FutureType.CURRENCY, [
    FutureProductDetail.fillFutureProduct(FutureProduct._6B, 'British Pound Futures', FutureType.CURRENCY, 'British pounds', 62500),
    FutureProductDetail.fillFutureProduct(FutureProduct._6C, 'Canadian Dollar Futures', FutureType.CURRENCY, 'Canadian Dollar', 100000),
    FutureProductDetail.fillFutureProduct(FutureProduct._6E, 'Euro FX Futures', FutureType.CURRENCY, 'Euro', 125000),
    FutureProductDetail.fillFutureProduct(FutureProduct._6A, 'Australian Dollar Futures', FutureType.CURRENCY, 'Australian dollars', 100000),
    FutureProductDetail.fillFutureProduct(FutureProduct._6M, 'Mexican Peso Futures', FutureType.CURRENCY, 'Mexican pesos', 500000),
    FutureProductDetail.fillFutureProduct(FutureProduct._6L, 'Brazilian Real Futures', FutureType.CURRENCY, 'Brazilian reais', 100000),
    FutureProductDetail.fillFutureProduct(FutureProduct._6R, 'Russian Ruble Futures', FutureType.CURRENCY, 'Russian rubles', 2500000),
    FutureProductDetail.fillFutureProduct(FutureProduct.E7, 'E-mini Euro FX Futures', FutureType.CURRENCY, 'Euro', 62500),
    FutureProductDetail.fillFutureProduct(FutureProduct.M6E, 'E-micro Euro/American Dollar Futures', FutureType.CURRENCY, 'Euros', 125000),
    FutureProductDetail.fillFutureProduct(FutureProduct.M6B, 'E-micro British Pound/American Dollar Futures', FutureType.CURRENCY, 'British pounds', 6250),
    FutureProductDetail.fillFutureProduct(FutureProduct.CNH, 'Standard-Size USD/Offshore RMB(CNH) Futures', FutureType.CURRENCY, 'USD', 100000),
    FutureProductDetail.fillFutureProduct(FutureProduct.MCD, 'E-micro Canadian Dollar/American Dollar Futures', FutureType.CURRENCY, 'CAD', 10000),
    FutureProductDetail.fillFutureProduct(FutureProduct.BDI, 'CME Bloomberg Dollar Spot Index Futures', FutureType.CURRENCY, 'Bloomberg Dollar Spot IndexSM', 100),
]);
//ENERGY
FUTURE_PRODUCTS.set(FutureType.ENERGY, [
    FutureProductDetail.fillFutureProduct(FutureProduct.BZ, 'Brent Last Day Financial Futures', FutureType.ENERGY, 'barrels', 1000),
    FutureProductDetail.fillFutureProduct(FutureProduct.CL, 'Crude Oil Futures', FutureType.ENERGY, 'Barrels', 1000),
    FutureProductDetail.fillFutureProduct(FutureProduct.EH, 'Ethanol Futures', FutureType.ENERGY, 'Gallons', 29000),
    FutureProductDetail.fillFutureProduct(FutureProduct.HH, 'Natural Gas(Henry Hub) Last-day Financial Futures', FutureType.ENERGY, 'million British thermal units (mmBtu)', 10000),
    FutureProductDetail.fillFutureProduct(FutureProduct.HO, 'NY Harbor ULSD Futures', FutureType.ENERGY, 'Gallons', 42000),
    FutureProductDetail.fillFutureProduct(FutureProduct.NG, 'Henry Hub Natural Gas Futures', FutureType.ENERGY, 'million British thermal units (mmBtu)', 10000),
    FutureProductDetail.fillFutureProduct(FutureProduct.QG, 'E-mini Natural Gas Futures', FutureType.ENERGY, 'million British thermal units (mmBtu)', 2500),
    FutureProductDetail.fillFutureProduct(FutureProduct.QM, 'E-mini Crude Oil Futures', FutureType.ENERGY, 'Barrels', 500),
    FutureProductDetail.fillFutureProduct(FutureProduct.RB, 'RBOB Gasoline Futures', FutureType.ENERGY, 'Gallons', 42000),
]);
//METALS
FUTURE_PRODUCTS.set(FutureType.METALS, [
    FutureProductDetail.fillFutureProduct(FutureProduct.GC, 'Gold Futures', FutureType.METALS, 'Troy ounces', 100),
    FutureProductDetail.fillFutureProduct(FutureProduct.SI, 'Silvers Futures', FutureType.METALS, 'Troy ounces', 5000),
    FutureProductDetail.fillFutureProduct(FutureProduct.HG, 'Copper Futures', FutureType.METALS, 'Pounds', 25000),
    FutureProductDetail.fillFutureProduct(FutureProduct.PL, 'Platinum Futures', FutureType.METALS, 'Troy ounces', 50),
    FutureProductDetail.fillFutureProduct(FutureProduct.PA, 'Palladium Futures', FutureType.METALS, 'Troy ounces', 100),
    FutureProductDetail.fillFutureProduct(FutureProduct.MGC, 'E-micro Gold Futures', FutureType.METALS, 'Troy ounces', 10),
    FutureProductDetail.fillFutureProduct(FutureProduct.QC, 'E-mini Copper Futures', FutureType.METALS, 'Pounds', 12500),
    FutureProductDetail.fillFutureProduct(FutureProduct.QI, 'E-mini Silver Futures', FutureType.METALS, 'Troy ounces', 2500),
    FutureProductDetail.fillFutureProduct(FutureProduct.QO, 'E-mini Gold Futures', FutureType.METALS, 'Troy ounces', 50),
    FutureProductDetail.fillFutureProduct(FutureProduct.SIL, '1000-oz Silver Futures', FutureType.METALS, 'Troy ounces', 1000),
    FutureProductDetail.fillFutureProduct(FutureProduct.TIO, 'Iron Ore 62% Fe', FutureType.METALS, 'Dry metric tons', 500),
    FutureProductDetail.fillFutureProduct(FutureProduct.ZNC, 'Zinc Futures', FutureType.METALS, 'Metric tons', 25),
]);
// INTEREST
FUTURE_PRODUCTS.set(FutureType.INTEREST, [
    FutureProductDetail.fillFutureProduct(FutureProduct.B1U, '30-Year USD MAC Swap Futures', FutureType.INTEREST, 'Point', 1000),
    FutureProductDetail.fillFutureProduct(FutureProduct.F1U, '5-Year USD MAC Swap Futures', FutureType.INTEREST, 'Point', 1000),
    FutureProductDetail.fillFutureProduct(FutureProduct.N1U, '10-Year USD MAC Swap Futures', FutureType.INTEREST, 'Point', 1000),
]);
//AGRICULTURE
FUTURE_PRODUCTS.set(FutureType.AGRICULTURE, [
    FutureProductDetail.fillFutureProduct(FutureProduct.CB, 'Cash-settled Butter Futures', FutureType.AGRICULTURE, 'Pounds', 20000),
    FutureProductDetail.fillFutureProduct(FutureProduct.CSC, 'Cash-Settled Cheese Futures', FutureType.AGRICULTURE, 'Pounds', 20000),
    FutureProductDetail.fillFutureProduct(FutureProduct.DC, 'Class III Milk Futures', FutureType.AGRICULTURE, 'Dollars per hundredweight', 2000),
    FutureProductDetail.fillFutureProduct(FutureProduct.GF, 'Feeder Cattle Futures', FutureType.AGRICULTURE, 'Pounds', 50000),
    FutureProductDetail.fillFutureProduct(FutureProduct.KE, 'KC HRW Wheat Futures', FutureType.AGRICULTURE, 'bushels', 5000),
    FutureProductDetail.fillFutureProduct(FutureProduct.ZL, 'Soyabean Oil Futures', FutureType.AGRICULTURE, 'Pounds', 60000),
]);


