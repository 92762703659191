/** @format */

import { CompletedTrade, Message, PortfolioAPIClient } from 'api';
import React, { Component } from 'react';
import InflyTable from 'containers/common/InflyTable';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import CommonUtil from 'util/CommonUtil';
import { InflyMessage } from 'containers/common/InflyMessage';
import { AppContext } from '../../util/appContext';
import { PortfolioIdentifierProps } from './PortfolioDashboard';
import A from 'containers/common/Anchor';

interface TradeHistoryState {
    completedTrades: CompletedTrade[];
    pageSize: number;
}

class TradeHistory extends Component<PortfolioIdentifierProps, TradeHistoryState> {
    portfolioApiClient: PortfolioAPIClient;

    constructor(props: PortfolioIdentifierProps) {
        super(props);
        this.portfolioApiClient = AppContext.getInstance().portfolioApiClient;
        this.state = {
            completedTrades: [],
            pageSize: 10,
        };
    }

    async componentDidMount() {
        this.loadCompletedTrades();
    }

    async loadCompletedTrades() {
        let completedTrades = await this.portfolioApiClient.getCompletedTrades(
            this.props.currentPortfolio.portfolioId,
            this.props.currentPortfolio.broker
        );
        this.setState({ completedTrades: completedTrades });
    }

    getCompletedTradeCofDef = () => {
        return [
            { field: 'id', headerName: 'ID', hide: true },
            { field: 'date', headerName: 'Date' },
            { field: 'securityType', headerName: 'Security Type', hide: true },
            {
                field: 'symbol',
                headerName: 'Symbol',

                formatFunction: (value: any) => (
                    <A href={`/stockdetail/${value}`} newTab={true} id='href-cell'>
                        {value}
                    </A>
                ),
            },
            { field: 'tradeType', headerName: 'Trade Type' },
            { field: 'price', headerName: 'Close Price' },
            { field: 'quantity', headerName: 'Qty' },
        ];
    };

    getCompletedTradeRows = () => {
        return this.state.completedTrades?.map((ct: CompletedTrade, index: any) => ({
            id: index,
            date: CommonUtil.toEstString(ct.date).replace(/,/g, ' '),
            symbol: ct.security.symbol,
            securityType: ct.security.securityType,
            tradeType: `${ct.tradeType[0]}${ct.tradeType.slice(1).toLowerCase()}`,
            price: ct.price,
            quantity: ct.quantity,
        }));
    };

    downloadTradeHistoryAsCSV = () => {
        const headers = {
            date: 'Date',
            symbol: 'Symbol',
            securityType: 'Security Type',
            tradeType: 'Trade Type',
            price: 'Price',
            quantity: 'Quantity',
        };

        let formattedData: object[] = [];
        this.state.completedTrades?.forEach((ct: CompletedTrade, index: any) =>
            formattedData.push({
                date: CommonUtil.toEstString(ct.date).replace(/,/g, ' '),
                symbol: ct.security.symbol,
                securityType: ct.security.securityType,
                tradeType: `${ct.tradeType[0]}${ct.tradeType.slice(1).toLowerCase()}`,
                price: ct.price,
                quantity: ct.quantity,
            })
        );
        CommonUtil.exportCSVFile(headers, formattedData, 'Trade History');
    };

    componentWillUnmount() {
        // fix error: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        return (
            <Card>
                <CardHeader
                    titleTypographyProps={{ variant: 'button' }}
                    title={<>Trade&nbsp;History</>}
                    action={
                        <Button
                            className=''
                            id='downloadResult'
                            onClick={(event: any) => {
                                event.preventDefault();
                                this.downloadTradeHistoryAsCSV();
                            }}
                        >
                            {' '}
                            Download CSV
                        </Button>
                    }
                />
                <CardContent>
                    <InflyTable
                        title={'completed trades'}
                        multipleSelection={false}
                        order={'desc'}
                        orderBy={'date'}
                        rows={this.getCompletedTradeRows()}
                        columns={this.getCompletedTradeCofDef()}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(value: number) =>
                            this.setState({
                                pageSize: value,
                            })
                        }
                        columnNameForSearch={'symbol'}
                    />
                </CardContent>
            </Card>
        );
    }
}

export default TradeHistory;
