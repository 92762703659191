import React from 'react';
import ReactDOM from 'react-dom/client';
import AppMain from './App';
import {AppContext} from './util/appContext';
import ReactGA from 'react-ga4';
import {AppConstant} from 'api';

const root = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
);
ReactGA.initialize(AppConstant.GOOGLE_ANALYTICS_ID);
let appContext = AppContext.getInstance();
appContext.establishSession().then(()=>{
    return (root.render(
            <AppMain />
    ))

})

