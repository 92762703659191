/** @format */

import DocumentHeader from 'containers/common/DocumentHeader';
import React, { Component, useEffect, useState } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { Message, IndicatorAPIClient } from 'api';
import { AppContext } from 'util/appContext';
import { LoadingCard } from 'containers/common/LoadingCard';
import DialogButton from 'containers/common/DialogButton';

import SessionUtil from 'util/SessionUtil';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import { IndicatorSpec } from 'api/models/trigger/indicator';
import { useNavigate } from 'react-router-dom';
import { InflyMessage } from 'containers/common/InflyMessage';
import { ListItemDisplayCard } from 'containers/common/ListItemDisplayCard';
import { InflyIcon } from 'containers/common/InflyIcon';
import { IndicatorCreateEditDialog, NewIndicatorBtn } from './IndicatorCodeEditor';
import { ErrorCard } from 'containers/common/ErrorCard';
import A from 'containers/common/Anchor';


export function IndicatorListPage(): React.JSX.Element {

    const [custIndicators, setCustIndicators] = useState<IndicatorSpec[]>();
    const [message, setMessage] = useState<Message>();

    const[editIndicator, setEditIndicator] = useState<IndicatorSpec>(); // indicator that's selected for edit


    async function loadMyCustomIndicators(){
        try{
            let custIndicatorsList: IndicatorSpec[] = await AppContext.getInstance().indicatorApiClient.listCustomIndicators()
            setCustIndicators(custIndicatorsList);
        }catch(error){
            setMessage(Message.fromError(error));
        }
        
    }

    useEffect(() => {
        loadMyCustomIndicators()
    }, [])

 

    if(custIndicators === undefined){
        if(message){
            return <ErrorCard message={message.message} />
        }else{
            return <LoadingCard title='Custom Indicators'/>
        }
        
    }


    async function onDeleteConfirm(indicatorId: string): Promise<any> {
        try{
            let indicatorApiClient: IndicatorAPIClient =  AppContext.getInstance().indicatorApiClient
            let success: boolean = await indicatorApiClient.deleteCustomIndicator(indicatorId);
            if(success){
                setMessage(Message.success("Indicator deleted successfully"));
            }
            loadMyCustomIndicators();
        }catch(error){
            setMessage(Message.fromError(error));
        }
    }


    function createDeleteButton(indicatorSpec: IndicatorSpec): React.JSX.Element {
        return <DialogButton
        dialogprompt='Are you sure you want to delete this indicator?'
        dialogtitle={'Delete Indicator'}
        color={'error'}
        startIcon={
            <InflyIcon  name='delete'  fontSize={'small'}  />
        }
        buttonType={'iconButton'}
        onClick={() => onDeleteConfirm(indicatorSpec.indicatorId)}
    />
    }

    const indicatorClicked = (indicatorSpec: IndicatorSpec) => {
        setEditIndicator(indicatorSpec)
    }

    const onEditIndicatorDialogReturn = (newSpec: IndicatorSpec) => {
        let index = custIndicators.findIndex(spec => spec.indicatorId === newSpec.indicatorId);
        if(index >= 0){
            // if classname is not changed
            custIndicators.splice(index, 1, newSpec)
            setCustIndicators([...custIndicators]);
        }else{
            // when ClassName is changed, it changes IndicatorId, which is functionally same as creating a new indicator, so we must append to the list
            setCustIndicators([...custIndicators, newSpec]);
        }

        setEditIndicator(undefined)

    }

    const onNewIndicatorDialogReturn = (newSpec: IndicatorSpec) => {
       setCustIndicators([...custIndicators, newSpec]);
    }

    return (<>

        {editIndicator && <IndicatorCreateEditDialog indicatorId={editIndicator.indicatorId} onCancel={() => setEditIndicator(undefined)} onSuccess={onEditIndicatorDialogReturn} />}

        <Card>
            <CardHeader title={'Custom Indicators'} action={<NewIndicatorBtn onSuccess={onNewIndicatorDialogReturn}/>}/>

            <CardContent>  

                <InflyMessage message={message} onClose={() => setMessage(undefined)} />

                <Grid container spacing={2}>

                    {custIndicators.map( indicatorSpec => {
                        return <Grid key={indicatorSpec.indicatorId} item xs={12} md={6}>

                            <ListItemDisplayCard 
                                title={indicatorSpec.indicatorId} 
                                action = {() => indicatorClicked(indicatorSpec)}
                                iconName={'indicator'} 
                                description={indicatorSpec.name + " - "  + indicatorSpec.description} 
                                deleteBtn={createDeleteButton(indicatorSpec)}
                                />

                        </Grid>
                    })}

                {custIndicators.length === 0 && (
                        <Paper elevation={5}>
                            <Stack gap={3} padding={4}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant='h6'> Tip</Typography>
                                    <InflyIcon name='tip' />
                                </Stack>
                                <Typography variant='body2'>
                                   Investfly already support most commonly used technical indicators (e.g SMA, RSI, MACD). You can also define your own custom technical indicator by writing a few lines of Python code.
                                   Custom Indicators are supported everywhere standard indicators are - you can plot them in price chart, use them in stock screeener, stock alerts or trading strategy.
                                </Typography>

                                <Typography variant='body2'>
                                    You can click 'New Indicator' button above to create a new indicator, but we recommand to use investfly-sdk to develop Python based strategies and indicators locally using your favorite IDE (Pycharm, VSCode) and upload to Investfly using CLI. See  <A target="_blank" href="https://www.investfly.com/guides/docs/index.html">investfl-sdk api docs</A>  for more details
                                </Typography>
                            </Stack>
                        </Paper>
                    )}
                    
                </Grid>
            </CardContent>
        </Card>
    </>);

}

export default withNavBarAndAuth(IndicatorListPage);
