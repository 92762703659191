import {AppConstant} from '../appConstant';
import {UserSession} from '../models/user/userSession';
import {PortfolioAPIClient} from './portfolioAPIClient';
import {GameAPIClient} from './gameAPIClient';

import {ScreenerAPIClient} from './screenerAPIClient';

import {InflyClientId, InflyClientToken, RestAPIClient} from './restAPIClient';
import {UserAPIClient} from './userAPIClient';

import {MarketDataAPIClient} from './marketDataAPIClient';
import {StockAlertAPIClient} from './stockAlertAPIClient';
import {BrokerAPIClient} from './brokerAPIClient';
import {ClassAPIClient} from './classAPIClient';
import {BacktestAPIClient} from './backtestAPIClient';
import {IndicatorAPIClient} from './IndicatorAPIClient';
import {StrategyAPIClient} from './StrategyAPIClient';
import {WatchListAPIClient} from '../index';


export class ApiClientFactory {

    private static INSTANCE?: ApiClientFactory;

    restApiClient: RestAPIClient;
    userApiClient: UserAPIClient;
    portfolioApiClient: PortfolioAPIClient;
    brokerApiClient: BrokerAPIClient;
    strategyApiClient: StrategyAPIClient;

    gameApiClient: GameAPIClient;
    classApiClient: ClassAPIClient;

    watchListApiClient: WatchListAPIClient;
    stockAlertAPIClient: StockAlertAPIClient;
    screenerApiClient: ScreenerAPIClient;
    marketDataAPIClient: MarketDataAPIClient;
    indicatorAPIClient: IndicatorAPIClient;
    backtestApiClient: BacktestAPIClient;

    private constructor(){
        this.restApiClient = new RestAPIClient(AppConstant.API_URL);
        this.userApiClient = new UserAPIClient(this.restApiClient);
        this.portfolioApiClient = new PortfolioAPIClient(this.restApiClient);
        this.brokerApiClient = new BrokerAPIClient(this.restApiClient);
        this.gameApiClient = new GameAPIClient(this.restApiClient);
        this.classApiClient = new ClassAPIClient(this.restApiClient);
        this.stockAlertAPIClient = new StockAlertAPIClient(this.restApiClient);
        this.strategyApiClient = new StrategyAPIClient(this.restApiClient);
        this.screenerApiClient = new ScreenerAPIClient(this.restApiClient);
        this.marketDataAPIClient = new MarketDataAPIClient(this.restApiClient);
        this.indicatorAPIClient = new IndicatorAPIClient(this.restApiClient);
        this.backtestApiClient = new BacktestAPIClient(this.restApiClient);
        this.watchListApiClient = new WatchListAPIClient(this.restApiClient);
    }

    public static getInstance(): ApiClientFactory{
        if (this.INSTANCE) return this.INSTANCE as ApiClientFactory;
        this.INSTANCE = new ApiClientFactory();
        return this.INSTANCE;
    }

    public setSession(session: UserSession) {
        if(session.clientId && session.clientToken) {
            this.restApiClient.setHeader(InflyClientId, session.clientId);
            this.restApiClient.setHeader(InflyClientToken, session.clientToken);
        }
    }

}