import React from 'react';
import {FormHelperText, FormHelperTextProps} from '@mui/material';

interface HelperTextProps {
    info: any
}

type InflyHelperText = HelperTextProps & FormHelperTextProps

function InflyHelperText(props: InflyHelperText) {
    if (props.info === null || props.info === undefined) return null;
    const formHelperTextProps = {...props};
    delete formHelperTextProps.info;

    return (
        <>
            <FormHelperText id="my-helper-text" color="primary" {...formHelperTextProps} component={'i'}>
                {props.info}
            </FormHelperText>
        </>);
}

export default InflyHelperText;