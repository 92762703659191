/** @format */

import Button from '@mui/material/Button';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import React from 'react';
import InflyDialog from './InflyDialog';
import { DialogContent, IconButton } from '@mui/material';
import withScreenSize from 'containers/HOC/WithScreenSize';
import { ScreenSizeProp } from 'util/commonProps';

type Props = DefaultComponentProps<any> &
    ScreenSizeProp & {
        dialogtitle: string;
        dialogprompt: string; // eitehr dialog prompt can be used or children can be used for dialog content
        label?: any;
        open?: true;
        okText?: string;
        disabled?: boolean;
        onClick: () => Promise<any>;
        startIcon?: React.ReactNode;
        color?:
            | 'inherit'
            | 'primary'
            | 'secondary'
            | 'success'
            | 'error'
            | 'info'
            | 'warning'
            | string;
        buttonType?: 'iconButton' | 'button';
    };

type State = {
    showDialog: boolean;
};

class DialogButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { showDialog: props?.open ?? false };
    }

    onDialogOKClick = (): Promise<any> => {
        let promise = this.props.onClick();
        if (promise) {
            // this check shoudl not be needed ideally, but if the props.onClick does not return promise
            promise.finally(() => this.setState({ showDialog: false }));
        } else {
            this.setState({ showDialog: false });
        }
        return promise;
    };

    render() {
        const showDialog = this.state.showDialog;
        const {
            dialogtitle,
            dialogprompt,
            dialogContentWidth,
            onClick,
            startIcon,
            children,
            maxWidth,
            okText,
            buttonType,
            isMobile,
            ...rest
        } = this.props;
        const { fullWidth, ...iconrest } = rest;
        return (
            <>
                {this.state.showDialog && (
                    <InflyDialog
                        id={`confirmationDialog_${this.props?.label ?? this.props.dialogtitle}`}
                        open={showDialog}
                        title={dialogtitle}
                        onClose={() => this.setState({ showDialog: false })}
                        okText={this.props.okText}
                        onExecute={this.onDialogOKClick}
                    >
                        <DialogContent>
                            {dialogprompt}
                            {children}
                        </DialogContent>
                    </InflyDialog>
                )}
                {this.props.buttonType === 'iconButton' ? (
                    <IconButton
                        {...iconrest}
                        id={`iconButton_${this.props?.label ?? this.props.dialogtitle}`}
                        onClick={(event: any) => {
                            event.preventDefault();
                            this.setState({ showDialog: true });
                        }}
                    >
                        {startIcon}
                    </IconButton>
                ) : (
                    <Button
                        {...rest}
                        fullWidth={fullWidth ?? isMobile}
                        startIcon={startIcon ? (startIcon as React.ReactNode) : <></>}
                        onClick={(event: any) => {
                            event.preventDefault();
                            this.setState({ showDialog: true });
                        }}
                        id={`normalButton_${this.props?.label ?? this.props.dialogtitle}`}
                    >
                        {this.props.label}
                    </Button>
                )}
            </>
        );
    }
}
export default withScreenSize(DialogButton);
