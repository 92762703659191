/** @format */

import { Box, Paper, Typography } from '@mui/material';
import { AppConstant } from 'api';
import React, { Component } from 'react';
import withRouter from 'containers/HOC/WithRouter';
import StripeCheckout from 'react-stripe-checkout';
import investfly from 'static/images/short_Investfly.png';
import { RouteProps } from 'util/commonProps';

interface ChangeCardDetailsProps extends RouteProps {
    email: string;
    onToken: (token: any) => void;
}

class ChangeCardDetails extends Component<ChangeCardDetailsProps, {}> {
    constructor(props: ChangeCardDetailsProps) {
        super(props);
    }
    render() {
        return (
            <Paper
                elevation={0}
                square={false}
                sx={{
                    paddingLine: 3,
                    gap: 2,
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Typography variant={'h5'} align={'center'}>
                    Change Card Details
                </Typography>

                <Box style={{ width: `fit-content`, margin: 'auto', marginBottom: 10 }}>
                    <StripeCheckout
                        ComponentClass={'stripeChangeDetails'}
                        panelLabel={'Update Card Details'}
                        triggerEvent={'onClick'}
                        label={'Change Card Details'}
                        email={this.props.email}
                        description={`Change Card Details`}
                        image={investfly}
                        locale='auto'
                        name='Infly LLC'
                        stripeKey={AppConstant.STRIPE_KEY}
                        token={this.props.onToken}
                    />
                </Box>
            </Paper>
        );
    }
}

export default withRouter(ChangeCardDetails);
