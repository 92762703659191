import { InvestflyModel } from "../InvestflyModel";
import { UserRole, UserType } from "./user";

export class UserSession extends InvestflyModel{
    username: string;
    clientId: string;
    clientToken: string;
    expirationTime: string;

    rememberMe: boolean;
    newUser: boolean;

    static parseJSON(obj: {[index:string]: any} ): UserSession {
        let session = new UserSession();
        Object.assign(session, obj);
        return session;
    }
} 
