import {Message, MessageType, SymbolSummaryInfo, TradeType, UserError} from 'api';
import {RouteProps as RP} from './commonProps';
import SessionUtil from './SessionUtil';

declare global{
    interface Navigator{
        msSaveBlob:(blob: Blob,fileName:string) => boolean
       }
}


export interface TradeTypeOption {
    label: string,
    value: string,
}


export class ScrollOptions {
    behavior?: string;
    block?: string;//start, center,end or nearest
}

export enum CreateUpdateMode {
    CREATE, UPDATE, TEMPLATE
}

export interface FilterOptionItem {
    key: string;
    value: string;
}
export default class CommonUtil {
    private static _Interval: any;
    private static monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    private static weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    public static parseToNumberArray(values: string[]): number[] {
        let numberArray = [];
        for (var i = 0; i < values.length; i++) {
            numberArray.push(parseInt(values[i]));
        }
        return numberArray;
    }

    public static getEnumKeyByEnumValue<T extends {[index:string]:string}>(myEnum:T, enumValue:string):keyof T|null {
        let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
        return keys.length > 0 ? keys[0] : null;
    }

    public static generateRandomString(length: number): string {
        let result = ' ';
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    public static removeFromArray<T>(arr: T[], itemToRemove: T) {
        const index: number = arr.indexOf(itemToRemove);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }


    public static formatTableRowData(value: any) {
        if (typeof value === 'undefined' || (typeof value === 'object' && typeof value.props.children === 'undefined')) return 'N/A';
        if (typeof value === 'number' && value.toString().split('').length === 1 && value === 0) return '-';
        if (typeof value === "object" && value.props.children.toString().split("").length === 1 && value.props.children === 0) return '-'
        if (typeof value === 'number') return this.numberWithCommas(value) ;
        return value;
    }

    public static roundToTwoDecimal(value: number): number {
        return Math.round(value * 100) / 100;
    }

    public static upToTwoDecimal(value: number){
        return Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value);
    }
    public static getTypographyColor=(message:Message)=>{
        switch (message.getSeverity()) {
            case 'error':
                return 'error';
            case 'info':
            case 'warning':
                return 'danger';
            default:
                return 'primary';
        }
    }
    public static isObjectEmpty<T>(_object?: T): boolean {
        if (_object === undefined) return true;
        return _object  // Verify object presence
            && Object.keys(_object).length === 0  // Verify object content
            && Object.getPrototypeOf(_object) === Object.prototype;

    }

    public static getLastItem<T>(obj: T[]): T {
        return obj.slice(-1)[0];
    }

    public static getDateString(date?: Date): string {
        let dateStr = "";
        if (date === undefined) return dateStr;
        try {
            dateStr = date.toISOString().split('T')[0];
        } catch (error) {
            dateStr = date.toString().split('T')[0]
        }
        return dateStr;
    }

    public static getObject = <T>(array: T[], property: keyof T, value: typeof property) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i][property]) {
                return array[i][property];
            }
        }
        return null;
    };

    public static openFullScreen(elementId: string){
        const elem = document.getElementById(elementId);
        if(elem){
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            }
            if(document.fullscreenElement){
                document.exitFullscreen();
            }
        }
    }

    public static getUserVisibleErrorMsg(error: Error): string {
        if (error instanceof UserError) {
            return error.message;
        } else {
            return "Unexpected error occurred while processing your request. Please report this problem to us. We apologize for the inconvenience.";
        }
    }

    public static createGenericErrorMessge(actualError: string): Message {
        let message: Message = new Message();
        message.type = MessageType.ERROR;
        message.message = "Unexpected error occurred while processing your request. Please report this problem to us. We apologize for the inconvenience.";
        return message;
    }

    public static errorToMessage(error: Error): Message {
        let errorMsg = CommonUtil.getUserVisibleErrorMsg(error);
        let message: Message = new Message();
        message.type = MessageType.ERROR;
        message.message = errorMsg;
        return message;
    }

    public static randomBgColor(): string {
        const bgColors = ['#0094A7', '#004050', '#2f4050', '#348FE2', '#5CB85C', '#E46B19', '#029E5A', '#16A085', '#2C445F', '#305D8C', '#24ADE4', '#006666', '#72C02C'],
            random = Math.floor(Math.random() * 12);
        return bgColors[random];
    }

    public static stringArrayToNumberArray(items: string[]|string): number[]|number {
        if(typeof items === "string") return Number(items);
        if (this.isArray(items) && items.length >0) {
            return items.map((item) => Number(item));
        }
        return [];
    }

    public static bindThis(_this: any, methodNames: string[]) {
        return (methodNames.forEach(item => {
            _this[item] = _this[item].bind(_this);
        }))
    }

    public static getEstTime(): Date {
        let offset = -4.0; // EST
        let clientDate = new Date();
        let utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
        return new Date(utc + (3600000 * offset));
    }

    public static toEstString(date?: Date): string {
        if (date === undefined) return 'NA'; // some TDAmeritrade open position did not have date
        return date.toLocaleString('en-US', {timeZone: 'America/New_York'}) + ' EST';
    }

    public static getMonthName(date: Date): string {
        return this.monthNames[date.getMonth()];
    }

    public static getWeekDayName(date: Date): string {
        return this.weekday[date.getDay()];
    }

    public static getAM_PM(date: Date): string {
        return (date.getHours() >= 12) ? 'PM' : 'AM';
    }

    public static countdown(date: Date, elementId: string) {
        if (this._Interval) {
            clearInterval(this._Interval);
        }

        // Set the date we're counting down to
        let countDownDate = date.getTime();
        // let countDownDate = new Date("Jan 5, 2022 15:37:25").getTime();

        const element: HTMLElement = document.getElementById(`${elementId}`)!;
        // Update the count down every 1 second
        this._Interval = setInterval(() => {

            // Get today's date and time
            const now = new Date().getTime();

            // Find the distance between now and the count down date
            const timeDistance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(timeDistance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDistance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDistance % (1000 * 60)) / 1000);
            // Output the result in an element with id="demo"
            if (element) {
                element.innerHTML = `${days} days ${hours}:${minutes}:${seconds}`;
            }
            // If the count down is over, write some text.
            if (timeDistance < 0) {
                clearInterval(this._Interval);
                // Optional
                if (element) {
                    element.innerHTML = `00:00:00`;
                }
            }

        }, 1000);
    }

    public static redirectToExternalURL(url: string) {
        window.location.href = url;
    }

    public static jsonToArray(obj: object) {
        return Object.keys(obj).map(function (i) {
            return [Number(i), obj[i]];
        });
    };

    public static getTradeTypeOption(): TradeTypeOption[] {
        return Object.keys(TradeType).map(t => {
            let label = "";

            if (t === TradeType.BUY) label = "Buy to open";
            else if (t === TradeType.SELL) label = "Buy to close";
            else if (t === TradeType.SHORT) label = "Sell to open";
            else if (t === TradeType.COVER) label = "Sell to close";

            return {label: label, value: t.toString()}
        })
    }

    public static getQueryParam(props: RP, paramName: string): string {
        const paramValue = props.params[paramName];

        if (paramValue)
            return window.decodeURIComponent(paramValue);

        const searchParam = props.location.search.replace("?", "");

        const matches = searchParam.split("&").map(p => p.split("=")).filter(p => p[0] === paramName).map(p => p[1]);

        if (matches.length === 1)
            return window.decodeURIComponent(matches[0]);

        return "";
    }

    public static numberWithCommas(amount: number|string|undefined) {
        if (amount === undefined || amount.toString() === '') return 'N/A';
        if (typeof amount == 'string') return amount;

        return Intl.NumberFormat('en-US').format(amount);
    }

    public static isInteger(number: number) {
        return Number(number) === number && number % 1 === 0;
    }

    public static isFloat(number: number) {
        return Number(number) === number && number % 1 !== 0;
    }

    public static numberWithLabel(n: number) : string{
        let num = Number(n);
        let isInTrillion = false;
        let isInBillion = false;
        let isInMillion = false;

        if (num > 1000000000000) {
            isInTrillion = true;
            num = num / 1000000000000;
        } else if (num > 1000000000) {
            isInBillion = true;
            num = num / 1000000000;
        } else if (num > 1000000) {
            isInMillion = true;
            num = num / 1000000;
        }
        if (this.isFloat(num))
            num = Number(num.toFixed(4))
        else if (this.isInteger(num))
            num = Number(num.toFixed(0));
        if (isInTrillion)
            return num + "T";
        else if (isInBillion)
            return num + "B";
        else if (isInMillion)
            return num + "M";
        else
            return CommonUtil.numberWithCommas(num);

    }

    public static validatePhoneNumber(value: string): boolean {
        if (value === "") return false;
        return value.match(/^\+\d{1,3}[\-]\d{3}\d{3}\d{4}$/) !== null;
    }

    public static validateCountryCode(value: string): boolean {
        if (value === '') return false;
        return value.match(/^\+\d{1,3}$/) !== null;
    }

    public static validateMobileNumber(value: string) {
        if (value === '') return false;
        return value.match(/^\d{3}\d{3}\d{4}$/) !== null;
    }

    public static getSymbols(filterItem?: FilterOptionItem): SymbolSummaryInfo[] {
        const tickerSymbolStr = SessionUtil.getFromSessionStorage('allSymbols');
        const tickerSymbol = JSON.parse(tickerSymbolStr ?? '');
        if (tickerSymbol && tickerSymbol.length > 0) {
            return [...tickerSymbol.filter((tickerSymbol: SymbolSummaryInfo) => {
                if (filterItem) {
                    return tickerSymbol[filterItem.key] === filterItem.value;
                }
                return true;
            })];
        }
        return [];
    }

    public static getStringArrayOfEmailsOrUsername(values: string): string[] {
        let to: string[];
        // default separator will be comma, other wise it will send empty array.

        if (values.includes(' ')) {
            to = values.split(' ');
        } else if (values.includes('\n')) {
            to = values.split('\n');
        } else {
            to = values.split(",");
        }
        return to
    }

    public static isValidUsername(username: string) {
        const userNameFormat = /^[a-z0-9_\.]+$/;
        return userNameFormat.test(username.trim());
    }

    public static isValidEmail(email: string) {
        const mailFormat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return mailFormat.test(email.trim());
    }





    public static convertToCSV(objArray: any): string {
        const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line !== '') line += ','

                line += array[i][index];
            }

            str += line + '\r\n';
        }
        return str;
    }



    public static exportCSVFile(headers: object, formattedItems: any, fileTitle: string) {
        if (headers) {
            formattedItems.unshift(headers);
        }
        //    convert object to JSON
        const jsonObject = JSON.stringify(formattedItems);
        const csv = this.convertToCSV(jsonObject);
        const exportedFilenmae = fileTitle + '.csv' || 'export.csv';
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    public static isArray(obj: any): boolean {
        return Array.isArray(obj)
    };


    static ScrollToMessage = () => CommonUtil.scrollToElement("AlertMessage", 'smooth');

    //behaviour: //auto or smooth
    //block: //start, center,end or nearest
    public static scrollToElement(elementId: string, behavior: string = 'auto', block: string = 'center') {
        var element = document.getElementById(elementId);

        if (element) {
            const scrollOptions = new ScrollOptions();
            scrollOptions.behavior = behavior;
            scrollOptions.block = block;

            var optJson = {};
            Object.assign(optJson, scrollOptions);
            setTimeout(() => element && element.scrollIntoView(optJson), 300);
        }
    }

    public static isPromise(p: any) {
        return typeof p === 'object' && typeof p.then === 'function';


    }

    public static isReturnPromise(f: any) {
        return !!(f &&
            f.constructor.name === 'AsyncFunction' ||
            (typeof f === 'function' && this.isPromise(f())));

    }

    public static findPosition(obj: any) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return currenttop;
        }
        return 0;
    }


    public static clippedUpto(value: string, upto: number) {
        if (value === undefined) return '';
        return `${value.slice(0, Math.min(upto, value.length))}${value.length > upto ? '...' : ''}`;
    }


    public static containsAll(args: any[]) {
        const output: any = [];
        const containObject: any = {};
        let array, item, contain;
        // for each array passed as an argument to the function
        for (let i = 0; i < args.length; i++) {
            array = args[i].data;

            // for each element in the array
            for (let j = 0; j < array.length; j++) {
                item = "-" + array[j][0];
                contain = containObject[item] ?? 0;

                // if contain is exactly the number of previous arrays,
                // then increment by one so we count only one per array
                if (contain === i) {
                    containObject[item] = contain + 1;
                }
            }
        }
        // now collect all results that are in all arrays
        for (item in containObject) {
            if (containObject.hasOwnProperty(item) && containObject[item] === args.length) {
                output.push(Number(item.replace('-', '')));
            }
        }
        return (output);
    }

    static validateMessage(message: string): Message | true {
        let trimmedMessage = message.trim();
        // test for white space
        if (trimmedMessage === '' || trimmedMessage === ' ') return Message.error('feedback message cannot be empty');
        return true;
    }

    static getIconColor(name: string): 'error' | 'primary' | 'success' | 'warning' | 'info' | 'inherit' | 'action' | 'disabled' | 'secondary' | undefined {
        if (name === 'link') return 'primary';
        if (name === 'unLink') return 'error';
        return 'inherit';
    }

    // These functions will convert as well as do validations
    static strToNumber(value: string): number|undefined {
        // needed because Number("") = 0 and wont work for us
        if(value == "" ) return undefined;
        let n = Number(value); // Note this parses "1." as "1" which makes it impossible to enter decimals.
        if(Number.isNaN(n)){
            throw new UserError(value + " is not a number");
        }
        return n;
    }

    static strToInteger(value: string): number|undefined {
        if(value === "") return undefined;
        let n = CommonUtil.strToNumber(value);
        if(!Number.isInteger(n)){
            throw new UserError(value + " is not an integer");
        }
        return n;
    }

    static strToPositiveInteger(value: string): number|undefined {
        if(value === "") return undefined;
        let n = CommonUtil.strToInteger(value)!;
        if(n < 0){
            throw new UserError(value + " must be greater than zero");
        }
        return n;
    }
}