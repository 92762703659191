import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import React from 'react';
import{ StockDetailComp } from './StockDetail';
import { RouterAndThemeProps } from 'util/commonProps';
import CommonUtil from 'util/CommonUtil';
import DocumentHeader from 'containers/common/DocumentHeader';


interface StockDetailViewProps extends RouterAndThemeProps {}

const StockDetailView = (props: StockDetailViewProps) => {
    let symbol = CommonUtil.getQueryParam(props, 'symbol');

    return (
        <DocumentHeader title={'Stock View'}>
            <StockDetailComp  selectedSymbol={symbol} onMessage={m => {}}/>
        </DocumentHeader>
    );
};
export default withNavBarAndAuth(StockDetailView);
