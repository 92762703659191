import {InvestflyUtil} from '../../utils/investflyUtil';
import {InvestflyModel} from '../InvestflyModel';
import {UserGameScore} from './userGameScore';

export enum RepeatIntervalUnit{
    DAYS = "DAYS", MONTHS = "MONTHS"
}

export enum GameStatus {
    STOPPED = "STOPPED", STARTED = "STARTED", PAUSED = "PAUSED"
}


export class Game extends InvestflyModel{
    gameId: number;
    name: string;
    userGameScore?: UserGameScore
    creator: string;
    startDate?: Date;
    endDate?: Date;
    rules: Rules= new Rules();
    isPrivateGame: boolean=false;
    users: number;
    isRepeatable: boolean=false;
    repeatInterval?: number;
    repeatIntervalUnit?: RepeatIntervalUnit;
    codeToJoin?:string;
    status?: GameStatus;

    public static  parseJSON(obj: {[index:string]: any} ): Game {
        let game: Game = new Game();
        Object.assign(game, obj);
        if(obj.userGameScore){
            game.userGameScore = UserGameScore.parseJSON(obj.userGameScore);
        }
        game.startDate = InvestflyUtil.strToDate(obj.startDate);
        game.endDate = InvestflyUtil.strToDate(obj.endDate);
        
        game.rules = Rules.parseJSON(obj.rules);
        return game;
    }

    clone(): Game {
        const obj =  JSON.parse(JSON.stringify(this));
        return Game.parseJSON(obj);
    }
}


export class CompletedGame extends InvestflyModel{
    startDate: Date;
    endDate: Date;
    winners:UserGameScore[];

    public static  parseJSON(obj: {[index:string]: any} ): CompletedGame {
        let board: CompletedGame = new CompletedGame();
        board.startDate = InvestflyUtil.strToDate(obj.startDate)!;
        board.endDate = InvestflyUtil.strToDate(obj.endDate)!;
        board.winners = InvestflyModel.parseList(obj.winners, UserGameScore.parseJSON);
        return board;
    }
}


export class Rules{
    gameCash: number=1000000;
    isAutomationAllowed: boolean=false;
    isFutureTradeAllowed: boolean=false;
    isOptionTradeAllowed: boolean=false;
    isShortSellAllowed: boolean=false;
    maxPortfolioCount: number=0;
    minTrade?: number;
    maxTrade?: number;
    minPrice?: number;
    maxPrice?:number;
    minMarketCap?:string;
    maxMarketCap?:string;
    public static  parseJSON(obj: {[index:string]: any} ): Rules {
        let rules: Rules = new Rules();
        Object.assign(rules, obj);
        return rules;
    }
}