import {Message} from '../models/common/message';
import {InvestflyModel} from '../models/InvestflyModel';
import {Game} from '../models/marketGame/game';
import {ProfessorClass} from '../models/marketGame/professorClass';
import {User} from '../models/user/user';
import {RestAPIClient} from './restAPIClient';

export class ClassAPIClient {

    private restApiClient: RestAPIClient;

    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }

    async createClass(className: string): Promise<ProfessorClass> {
        const res: object = await this.restApiClient.post(`/class/create`, className);
        return ProfessorClass.parseJSON(res);
    }

    async deleteClass(className: string): Promise<Message> {
        const res: object = await this.restApiClient.delete(`/class/${className}`);
        return Message.parseJSON(res);
    }


    async listMyClasses(): Promise<ProfessorClass[]>{
        const res: object[] = await this.restApiClient.get(`/class/list`);
        return InvestflyModel.parseList(res, ProfessorClass.parseJSON);
    };

    async inviteStudentsUsers(className: string, usernames: string[]): Promise<Map<string, string[]>>{
        const res: object = await this.restApiClient.post(`/class/${className}/invite/users`, usernames);
        const resMap: Map<string, string[]> = new Map<string, string[]>();
        Object.assign(resMap, res);
        return resMap;
    };

    async inviteStudentsEmails(className: string, emails: string[]): Promise<Map<string, string[]>>{
        const res: object = await this.restApiClient.post(`/class/${className}/invite/emails`, emails);
        const resMap: Map<string, string[]> = new Map<string, string[]>();
        Object.assign(resMap, res);
        return resMap;
    };

    async listOfStudents(className: string): Promise<User[]> {
        const res: object[] = await this.restApiClient.get(`/class/${className}/students`);
        return InvestflyModel.parseList(res, User.parseJSON);
    }

    async removeStudent(className: string, email: string): Promise<boolean> {
        return await this.restApiClient.delete(`/class/${className}/students`, email);
    }

    async addGame(className: string, game: Game): Promise<Game>{
        const res: object = await this.restApiClient.post(`/class/${className}/contest`, game);
        return Game.parseJSON(res);
    }

    // For delete and edit game/contest, use API on GameAPIClient direclty


}