/** @format */

import React, { Component } from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { RouterAndThemeProps } from 'util/commonProps';
import InflyTable from 'containers/common/InflyTable';
import CommonUtil from 'util/CommonUtil';
import { Message, UserAPIClient } from 'api';
import { AppContext } from 'util/appContext';
import CopyToClipboard from '../common/CopyToClipboard';
import InviteFriends from '../common/InflyInviteFriends';
import withNavBarAndAuth from '../HOC/NavBarWrapper';
import DocumentHeader from '../common/DocumentHeader';
import { InflyMessage } from '../common/InflyMessage';

interface UserReferralsProps extends RouterAndThemeProps {}

interface UserReferralsState {
    referrals?: any[];
    ready: boolean;
    message?: Message;
    myReferralsDialogOpen: boolean;
    pageSize: number;
}

class UserReferrals extends Component<UserReferralsProps, UserReferralsState> {
    userApiClient: UserAPIClient;
    private textArea: HTMLElement | null;

    constructor(props: UserReferralsProps) {
        super(props);
        this.userApiClient = AppContext.getInstance().userApiClient;
        this.state = {
            referrals: undefined!,
            ready: true,
            myReferralsDialogOpen: false,
            pageSize: 10,
        };
    }

    async componentDidMount() {
        let { ready, referrals, message } = this.state;
        try {
            referrals = await this.userApiClient.myReferred();
        } catch (error) {
            message = Message.fromError(error);
        } finally {
            ready = false;
            this.setState({ referrals, message, ready });
        }
    }

    getReferralsRows = () => {
        const referrals = this.state.referrals;
        return referrals?.map((ref, index: any) => {
            return {
                id: ref.username,
                username: ref.username,
                firstName: ref.firstName,
                lastName: ref.lastName,
                joinDate: CommonUtil.getDateString(new Date(ref.joinDate)),
                userType: ref.userType,
            };
        });
    };

    getCofDef = (): any => {
        return [
            { field: 'id', headerName: 'ID', hide: true },
            { field: 'username', headerName: 'Username' },
            { field: 'firstName', headerName: 'First Name' },
            { field: 'lastName', headerName: 'Last Name' },
            { field: 'joinDate', headerName: 'Join Date' },
            { field: 'userType', headerName: 'User Type' },
        ];
    };
    clearMessage = () => {
        this.setState({ message: undefined });
    };

    render() {
        //TODO: show list of referrals.
        const username = JSON.parse(localStorage.userSession).username;

        return (
            <DocumentHeader title={'My Referrals'}>
                <Card>
                    <CardHeader title={<>Investfly&nbsp;Affiliate&nbsp;Program</>} />
                    <InflyMessage
                        message={this.state.message}
                        onClose={() => this.clearMessage()}
                    />
                    <CardContent>
                        <Typography gutterBottom>
                            Investfly affiliate program rewards you for referring users to
                            investfly. You get paid up to $20 for each PREMIUM user and up to $50
                            for each ELITE user who continue membership post their free trial
                            period. You will be paid each month if your account is above $100
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: { md: 2, xs: 0 },
                                alignItems: 'center',
                                flexDirection: { md: 'row', xs: 'column' },
                            }}
                        >
                            <InviteFriends
                                label={'Invite Now'}
                                size={'large'}
                                onSuccess={(message) => this.setState({ message: message })}
                            />
                            <Typography variant={'h5'}>OR</Typography>
                            <Typography>SHARE THIS LINK: </Typography>
                            <CopyToClipboard
                                label={`https://www.investfly.com?syref=${username}`}
                                text={`https://www.investfly.com?syref=${username}`}
                                onCopy={(text: string) => text}
                            />
                        </Box>
                        <br />
                        <InflyTable
                            title={'User Referrals'}
                            columnNameForSearch={'username'}
                            multipleSelection={false}
                            order={'asc'}
                            orderBy={'username'}
                            rows={this.getReferralsRows()}
                            pageSize={this.state.pageSize}
                            onPageSizeChange={(value: number) => this.setState({ pageSize: value })}
                            columns={this.getCofDef()}
                        />
                    </CardContent>
                </Card>
            </DocumentHeader>
        );
    }
}

export default withNavBarAndAuth(UserReferrals);
