/** @format */

import { LoadingCard } from 'containers/common/LoadingCard';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import {
    BrokerType,
    Message,
    Portfolio,
    PortfolioAPIClient,
    PortfolioIdentifier,
    UserError,
} from 'api';
import React from 'react';
import { RouterAndThemeProps } from 'util/commonProps';
import CommonUtil from 'util/CommonUtil';
import PortfolioHeader from './PortfolioHeader';
import DocumentHeader from 'containers/common/DocumentHeader';
import { InflyMessage } from 'containers/common/InflyMessage';
import InflyTabs, { InflyTab } from 'containers/common/InflyTabs';
import ClosedPositions from './ClosedPositions';
import { PendingOrders } from './PendingOrders';
import { PortfolioBalances } from './PortfolioBalances';
import TradeHistory from './TradeHistory';
import Performance from './Performance';
import OpenPositionsComp from './OpenPositions';
import { AppContext } from 'util/appContext';
import { Grid, Stack } from '@mui/material';
import { PortfolioSummary } from './PortfolioSummary';
import { PortfolioErrorCard } from './PortfolioErrorCard';
import { PortfolioAutomationCard } from './PortfolioAutomationCard';
import ReactGA from 'react-ga4';

export interface PortfolioIdentifierProps {
    currentPortfolio: Portfolio;
    onMessage: (message: Message) => void;
}

export interface PortfolioDashboardState {
    currentPortfolio: Portfolio;
    message?: Message;
    ready: boolean;
    portfolioError: boolean;
    activeTab: number;
}

class PortfolioDashboard extends React.Component<RouterAndThemeProps, PortfolioDashboardState> {
    portfolioApiClient: PortfolioAPIClient;
    timer: any;

    constructor(props: RouterAndThemeProps) {
        super(props);
        this.portfolioApiClient = AppContext.getInstance().portfolioApiClient;

        this.state = {
            currentPortfolio: undefined!,
            ready: false,
            portfolioError: false,
            activeTab: 0,
        };
    }

    async componentDidMount() {
        this.init();
        ReactGA.event('portfolio_page_view', {});
    }


    init = async () => {
        let { message } = this.state;
        try {
            const portfolioId: string = CommonUtil.getQueryParam(this.props, 'portfolioId');
            const brokerTypeStr = CommonUtil.getQueryParam(this.props, 'brokerType');
            const brokerType = BrokerType[brokerTypeStr];

            if (portfolioId === undefined || brokerType === undefined) {
                throw new UserError(
                    'URL Invalid. Please use URL like /portfolio/BROKER/portfolioID'
                );
            } else {
                await this.loadPortfolioState({ portfolioId: portfolioId, brokerType: brokerType });
            }
        } catch (error) {
            this.clearTimer();
        } finally {
            this.setState({ ready: true }, () => this.startTimer());
        }
    };
    loadPortfolioState = async (portfolioIdentifier: PortfolioIdentifier) => {
        let { currentPortfolio, message, portfolioError } = this.state;
        try {
            currentPortfolio = await this.portfolioApiClient.getPortfolio(
                portfolioIdentifier.portfolioId,
                portfolioIdentifier.brokerType
            );
            if (!currentPortfolio.currentValue || currentPortfolio.currentValue === 0) {
                console.log("Portfolio Quote issue detected");
                console.log(currentPortfolio);
                message = Message.warning(
                    'Quotes from some stocks in your open positions cannot be retrieved. Please contact us to fix your portfolio'
                );
            }
            portfolioError = false;
        } catch (error) {
            // Mainly, when broker connections are in bad state. We still display top header with breadcrumbs and portfolio select
            let currentPortfolio = new Portfolio();
            portfolioError = true;
            message = Message.fromError(error);
            currentPortfolio.portfolioId = portfolioIdentifier.portfolioId;
            currentPortfolio.broker = portfolioIdentifier.brokerType;
            this.clearTimer();
        } finally {
            this.setState({ portfolioError, message, currentPortfolio });
        }
    };

    onPortfolioChange = (portfolio: PortfolioIdentifier) => {
        this.loadPortfolioState({
            portfolioId: portfolio.portfolioId,
            brokerType: portfolio.brokerType,
        }).then(() => {
            this.setState({ message: undefined });
            this.props.navigate('/portfolio/' + portfolio.brokerType + '/' + portfolio.portfolioId);
        });
    };

    clearTimer = () => {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = undefined;
        }
    };

    startTimer = () => {
        if (!this.timer) {
            this.timer = setInterval(() => this.init(), 10000);
        }
    };

    componentWillUnmount() {
        this.clearTimer();
    }

    onTradeMessage = (message: Message) => {
        // Trade will change portfolio state, so much increase a version
        let isScheduled = message.message.includes('Scheduled');
        let completed = message.message.includes('Completed');
        this.setState(
            {
                message: message,
                activeTab: isScheduled ? 4 : completed ? 0 : this.state.activeTab,
            },
            CommonUtil.ScrollToMessage
        );
    };

    onPortfolioUpdate = async (message: Message) => {
        const portfolio = await this.portfolioApiClient.getPortfolio(
            this.state.currentPortfolio.portfolioId,
            this.state.currentPortfolio.broker
        );
        this.setState(
            {
                currentPortfolio: portfolio,
                message: message,
            },
            CommonUtil.ScrollToMessage
        );
    };

    onMessage = (message: Message) => {
        this.setState({ message: message }, CommonUtil.ScrollToMessage);
    };

    render() {
        if (!this.state.ready) {
            return <LoadingCard title={'Portfolio Details'}  />;
        }
        if (this.state.ready && this.state?.currentPortfolio === undefined) {
            return (
                <InflyMessage
                    message={this.state.message}
                    onClose={() => this.setState({ message: undefined })}
                />
            );
        }
        return (
            <DocumentHeader title={'Portfolio Dashboard '}>
                <Stack gap={2}>
                    <PortfolioHeader
                        currentPortfolio={this.state.currentPortfolio}
                        onPortfolioSelect={this.onPortfolioChange}
                        onMessage={this.onTradeMessage}
                        {...this.props}
                    />

                    <InflyMessage
                        message={this.state.message}
                        onClose={() => this.setState({ message: undefined })}
                    />

                    {this.state.portfolioError ? (
                        <PortfolioErrorCard
                            portfolio={this.state.currentPortfolio.toPortfolioIdentifier()}
                            onMessage={this.onMessage}
                        />
                    ) : (
                        <>
                            <Grid container spacing={2}>
                                {this.state.currentPortfolio.broker === BrokerType.INVESTFLY && (
                                    <Grid item xs={12} md={6}>
                                        <PortfolioSummary
                                            currentPortfolio={this.state.currentPortfolio}
                                            key={this.state.currentPortfolio.portfolioId}
                                            onMessage={this.onPortfolioUpdate}
                                        />
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    md={
                                        this.state.currentPortfolio.broker === BrokerType.INVESTFLY
                                            ? 6
                                            : 12
                                    }
                                >
                                    <PortfolioAutomationCard portfolio={this.state.currentPortfolio} onDeployUndeployComplete={ message => {this.setState({message})}}/>


                                </Grid>
                            </Grid>
                            <PortfolioBalances
                                currentPortfolio={this.state.currentPortfolio}
                                key={this.state.currentPortfolio.portfolioId}
                                onMessage={this.onMessage}
                            />

                            <InflyTabs
                                value={this.state.activeTab}
                                variant='scrollable'
                                allowScrollButtonsMobile
                                scrollButtons='auto'
                            >
                                <InflyTab label='Open Positions'>
                                    <OpenPositionsComp
                                        currentPortfolio={this.state.currentPortfolio}
                                        {...this.props}
                                        key={this.state.currentPortfolio.portfolioId}
                                        onMessage={this.onTradeMessage}
                                    />
                                </InflyTab>

                                <InflyTab label='Closed Positions'>
                                    <ClosedPositions
                                        currentPortfolio={this.state.currentPortfolio}
                                        {...this.props}
                                        key={this.state.currentPortfolio.portfolioId}
                                        onMessage={this.onMessage}
                                    />
                                </InflyTab>

                                {this.state.currentPortfolio.broker === BrokerType.INVESTFLY && (
                                    <InflyTab label='Trade History'>
                                        <TradeHistory
                                            currentPortfolio={this.state.currentPortfolio}
                                            {...this.props}
                                            key={this.state.currentPortfolio.portfolioId}
                                            onMessage={this.onMessage}
                                        />
                                    </InflyTab>
                                )}
                                {this.state.currentPortfolio.broker === BrokerType.INVESTFLY && (
                                    <InflyTab label='Performance'>
                                        <Performance
                                            currentPortfolio={this.state.currentPortfolio}
                                            {...this.props}
                                            key={this.state.currentPortfolio.portfolioId}
                                            onMessage={this.onMessage}
                                        />
                                    </InflyTab>
                                )}

                                <InflyTab label='Pending Orders'>
                                    <PendingOrders
                                        currentPortfolio={this.state.currentPortfolio}
                                        {...this.props}
                                        key={this.state.currentPortfolio.portfolioId}
                                        onMessage={this.onMessage}
                                        onTradeDialogOpenClose={(isOpen: boolean) =>
                                            isOpen ? this.clearTimer() : this.startTimer()
                                        }
                                    />
                                </InflyTab>
                            </InflyTabs>
                        </>
                    )}
                </Stack>
            </DocumentHeader>
        );
    }
}

export default withNavBarAndAuth(PortfolioDashboard);
