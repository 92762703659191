export class UserMessage {

     // no need to set following two if logged-in user is making the request. 
    fullName: string; 
    fromEmail: string;

    subject: string;
    message: string;

    static parseJSON(obj: {[index:string]: any} ): UserMessage {
        let message = new UserMessage();
        Object.assign(message, obj);
        return message;
    }
}