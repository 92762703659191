/** @format */

import {
    Card,
    CardHeader,
    Divider,
    CardContent,
    Box,
    Stack,
    Typography,
    Hidden,
} from '@mui/material';
import { title } from 'process';
import React, { ReactNode } from 'react';
import CommonUtil from 'util/CommonUtil';
import { LabelAndValue, LabelValueData } from './LabelAndValue';
import { Elements } from '@stripe/react-stripe-js';

export interface DataDisplayCardProps {
    children?: ReactNode;
    title: string | JSX.Element;
    data: Map<string, string|number|React.ReactElement|undefined>;
    cardActionHeader?: any;
}

export function DataDisplayCard(props: DataDisplayCardProps) {
    let dataArray = Array.from(props.data);

    return (
        <Card>
            <CardHeader title = {props.title} action={props.cardActionHeader}/>
            <CardContent>
                {props?.children}
                <Stack divider={<Divider orientation='horizontal' flexItem />} spacing={1}>
                    {dataArray.map((keyValue: [string, string | number | React.ReactElement| undefined], index: number) => (
                        <LabelAndValue key={index} label={keyValue[0]} value={keyValue[1]} />
                    ))}
                </Stack>
            </CardContent>
        </Card>
    );
}
