import { ComparisonOperator } from "../common/ComparisonOperator";
import { FinancialField } from "./FinancialField";
import { InvestflyModel } from "../InvestflyModel";
import { InvestflyUtil } from "api/utils/investflyUtil";

export class FinancialQuery {

    queryConditions: FinancialCondition[];

    clone(): FinancialQuery{
        let clone = new FinancialQuery();
        clone.queryConditions = this.queryConditions.map(c => c.clone());
        return clone;
    }

    validate(): string | undefined {
        if(this.queryConditions.length == 0){
            return "At least one financial condition is required";
        }
        for(let c of this.queryConditions){
            let error = c.validate();
            if(error){
                return error;
            }
        }
    }

    static getDefault(): FinancialQuery{
        let obj = new FinancialQuery();
        obj.queryConditions = [new FinancialCondition()];
        return obj;
    }


    static parseJSON(jsonObj: {[index:string]: any}): FinancialQuery {
        let financialQuery = new FinancialQuery()
        financialQuery.queryConditions = InvestflyModel.parseList(jsonObj.queryConditions, FinancialCondition.parseJSON)
        return financialQuery;
    }

}


export class FinancialCondition {

    financialField: FinancialField;
    operator: ComparisonOperator = ComparisonOperator.GREATER_THAN;
    value: FinancialField | string;

    clone(): FinancialCondition{
        let obj = new FinancialCondition();
        Object.assign(obj, this);
        return obj;
    }

    validate(): string|null {
        if(this.financialField === undefined && this.value === undefined){
            return "Financial Condition cannot be empty";
        }
        if(this.financialField == undefined){
            return "Left Condition must be specified";
        }
        if(this.value == undefined){
            return "Right Condition must be specified";
        }
        return null;
    }

    static parseJSON(jsonObj: {[index:string]: any}): FinancialCondition {
        let financialCondition = new FinancialCondition()
        financialCondition.financialField = FinancialField[jsonObj.financialField]
        financialCondition.operator = ComparisonOperator[jsonObj.operator]
        let rightValueStr: string = jsonObj.value

        if(InvestflyUtil.isStringEnum(FinancialField, rightValueStr)){
            financialCondition.value = FinancialField[rightValueStr]
        }else{
            financialCondition.value = rightValueStr
        }
        
        return financialCondition;
    }

    
}

