import React from 'react';
import { Helmet } from "react-helmet";
export default function DocumentHeader(props:{
    children: any; title:string
}){

    return (<>
        <Helmet>
            <title>{props.title}</title>
        </Helmet>
        {
        props.children
        }
    </>)
}