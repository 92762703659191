/** @format */

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    LinearProgress,
    Stack,
    Typography,
} from '@mui/material';
import {
    BacktestAPIClient,
    BacktestResult,
    JobStatus,
    Message,
    OPERATION,
} from 'api';
import React, { useEffect, useRef, useState } from 'react';
import { AppContext } from 'util/appContext';
import withScreenSize from 'containers/HOC/WithScreenSize';
import PortfolioChart from '../Portfolio/PortfolioChart';
import withRouter from '../HOC/WithRouter';
import { TradingStrategyModel } from 'api/models/strategy/TradingStrategyModel';
import { DataDisplayCard } from 'containers/common/DataDisplayCard';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import UpgradeCheckButton from 'containers/common/UpgradeCheckButton';
import InflyHelperText from 'containers/common/InflyHelperText';


type BacktestCompProps = {
    strategyModel: TradingStrategyModel;
    onMessage: (message: Message) => void;
}


function BacktestComp({strategyModel, onMessage}: BacktestCompProps): React.JSX.Element {

    const [backtestResult, setBacktestResult] = useState<BacktestResult>();
    const timerRef = useRef(0);

    async function loadBacktestResult(){
        let backtestApiClient: BacktestAPIClient = AppContext.getInstance().backestApiClient;
        let backtestResult: BacktestResult = await backtestApiClient.getResult(strategyModel.strategyId);
        setBacktestResult(backtestResult);
        let curStatus = backtestResult.status.jobStatus;

        if(curStatus === JobStatus.QUEUED || curStatus === JobStatus.INITIALIZING || curStatus === JobStatus.RUNNING){
            if(timerRef.current == 0){
                let intervalId = setInterval(loadBacktestResult, 5000)
                timerRef.current = intervalId as any as number;
            }
        }else{
            if(timerRef.current > 0){
                clearInterval(timerRef.current);
                timerRef.current = 0;
            }
        }
    }

    useEffect(() => {
        loadBacktestResult();

        return () => {
            if(timerRef.current > 0){
                clearInterval(timerRef.current);
                timerRef.current = 0;
            }
        }

    }, [strategyModel.strategyId])

    if(!backtestResult){
        return <></>
    }



    function getClosedTradesColDef(): GridColDef[] {
        return [
            {field: 'id', headerName: 'ID'},
            {field: 'security', headerName: 'Symbol', valueGetter: (params) => params.value.symbol},
            {field: 'position', headerName: 'Position'},
            {field: 'quantity', headerName: 'Quantity'},
            {field: 'openDate', headerName: 'Open Date', width: 200, valueFormatter: (params: GridValueFormatterParams<Date>) => {
                return `${params.value.toLocaleString()}`;
            }},
            {field: 'closeDate', headerName: 'Close Date', width: 200, valueFormatter: (params: GridValueFormatterParams<Date>) => {
                return `${params.value.toLocaleString()}`;
            }},
            {field: 'openPrice', headerName: 'Open Price'},
            {field: 'closePrice', headerName: 'Close Price'},
            {field: 'percentChange', headerName: 'PL(%)'}

        ];
    }

    function getRowDef(){
        return backtestResult!.matchedTrades!.map((row, index) => {
            row['id'] = index;
            return row;
        })
    }

    async function startBacktest(){
        try{
            let backtestApiClient: BacktestAPIClient = AppContext.getInstance().backestApiClient;
            let message = await backtestApiClient.start(strategyModel.strategyId);
            await loadBacktestResult();
            onMessage(message);
        }catch(ex){
            onMessage(Message.fromError(ex));
        }
    }

    async function stopBacktest(){
        try{
            let backtestApiClient: BacktestAPIClient = AppContext.getInstance().backestApiClient;
            let message = await backtestApiClient.stop(strategyModel.strategyId);
            await loadBacktestResult();
            onMessage(message);
        }catch(ex){
            onMessage(Message.fromError(ex));
        }
    }

    function getStartStopAction(): React.JSX.Element {
        let curStatus = backtestResult?.status.jobStatus
        if( curStatus == JobStatus.NOT_STARTED || curStatus == JobStatus.COMPLETE || curStatus == JobStatus.ERROR){
            return <UpgradeCheckButton op={OPERATION.BACKTEST_STRATEGY} label='Start'> 
                <Button onClick={startBacktest}>Start</Button>
            </UpgradeCheckButton>
        }else{
            return <Button onClick={stopBacktest}>Stop</Button>
        }
    }


    let portfolioReturn: Map<string, number> = new Map();
    let performance = backtestResult.performance!
    portfolioReturn.set('Net Return (%)', performance.netReturn);
    portfolioReturn.set('Annualized Return (%)', performance.annualizedReturn);
    portfolioReturn.set('Profit Factor (Gross Profit / Gross Loss)', performance.profitFactor);
    portfolioReturn.set('Max Drawdown (%)', performance.maxDrawdownPct);


    portfolioReturn.set('Total Trades', performance.totalTrades);
    portfolioReturn.set('Win Rate (%)', performance.winRatioPct);
    portfolioReturn.set('Avg. Profit Per Trade (%)', performance.avgProfitPerTradePct);
    portfolioReturn.set('Avg Loss Per Trade (%)', performance.avgLossPerTradePct);
    portfolioReturn.set('Mean Return Per Trade (%)', performance.meanReturnPerTradePct);
    portfolioReturn.set('Sharpe Ratio Per Trade', performance.sharpeRatioPerTrade);

    return (<>
        <Card raised>
            
            <CardHeader title='Backtest' action={getStartStopAction()}/>
            
            <CardContent>
            
            <Stack spacing={2}>

                <InflyHelperText info="Click the start button above to backtest your strategy against historical data. After the backtest is complete, you can compare the equity curve with any benchmark (e.g SPY) of your choice and view strategy performance metrics." />

                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography>Backtest Status: {backtestResult.status.jobStatus}</Typography>
                    {(backtestResult!.status.jobStatus === JobStatus.RUNNING || backtestResult!.status.jobStatus === JobStatus.INITIALIZING) &&
                        <Typography>{Math.round( backtestResult.status.percentComplete ?? 0 )}%</Typography>
                    }
                </Stack>

                {(backtestResult!.status.jobStatus === JobStatus.RUNNING || backtestResult!.status.jobStatus === JobStatus.INITIALIZING)  &&
                        <LinearProgress variant='determinate' value={Math.round( backtestResult.status.percentComplete ?? 0 )} />
                }

                <PortfolioChart height={600} dailyEquityValue={backtestResult.performance!.portfolioValues}  onMessage={onMessage} />

                <DataDisplayCard title={'Performance Metrics'} data={portfolioReturn} />
              
                <Card>
                    <CardHeader title='Closed Trades' />
                    <CardContent>
                        <DataGrid 
                            rows={getRowDef()} 
                            columns={getClosedTradesColDef()} 
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            pageSizeOptions={[10]}
                            disableRowSelectionOnClick
                            columnVisibilityModel={{
                                id: false
                              }}
                            />
                    </CardContent>
                </Card>   

            </Stack>
            </CardContent>
        </Card>

    </>)
}

export default withRouter(withScreenSize(BacktestComp));
