/** @format */

import {
    Message,
    OrderStatus,
    PendingOrder,
    PortfolioAPIClient,
    SecurityType,
} from 'api';
import React, { Component } from 'react';

import InflyTable from 'containers/common/InflyTable';
import { Card, CardContent, CardHeader } from '@mui/material';
import CommonUtil from 'util/CommonUtil';
import AuthorizedElement from 'containers/common/AuthorizedElement';
import SessionUtil from 'util/SessionUtil';
import DialogButton from 'containers/common/DialogButton';

import { InflyIcon } from '../common/InflyIcon';
import { AppContext } from '../../util/appContext';
import { PortfolioIdentifierProps } from './PortfolioDashboard';
import Trade, { TradeTabs } from 'containers/Trade/Trade';
import A from 'containers/common/Anchor';

interface PendingOrderProps extends PortfolioIdentifierProps {
    onTradeDialogOpenClose: (isOpen: boolean) => void;
}

interface PendingOrdersState {
    pendingOrders: PendingOrder[];
    selectedPendingOrder?: PendingOrder;
    pageSize: number;
}

export class PendingOrders extends Component<PendingOrderProps, PendingOrdersState> {
    loggedInUsername: string;
    portfolioApiClient: PortfolioAPIClient;

    constructor(props: PendingOrderProps) {
        super(props);
        this.portfolioApiClient = AppContext.getInstance().portfolioApiClient;
        this.loggedInUsername = SessionUtil.findSession()?.username!;
        this.state = {
            pendingOrders: [],
            pageSize: 10,
        };
    }

    componentDidMount() {
        this.loadPendingOrders();
    }

    async loadPendingOrders() {
        let pendingOrders = await this.portfolioApiClient.getPendingOrders(
            this.props.currentPortfolio.portfolioId,
            this.props.currentPortfolio.broker
        );
        this.setState({ pendingOrders: pendingOrders });
    }

    cancelPendingOrder = async (orderId: string) => {
        const message = await this.portfolioApiClient.cancelPendingOrder(
            this.props.currentPortfolio.portfolioId,
            this.props.currentPortfolio.broker,
            orderId
        );
        this.loadPendingOrders();
        this.props.onMessage(message);
    };

    getPendingOrderCofDef = () => {
        const portfoliosOwner = this.props.currentPortfolio.userName;
        return [
            { field: 'id', headerName: 'ID', hide: true },
            { field: 'scheduledDate', headerName: 'Date' },
            { field: 'securityType', headerName: 'Security Type', hide: true },
            {
                field: 'symbol',
                headerName: 'Symbol',

                formatFunction: (value: any) => (
                    <A href={`/stockdetail/${value}`} newTab={true} id='href-cell'>
                        {value}
                    </A>
                ),
            },

            { field: 'qty_amt', headerName: 'Qty/Amount' },
            { field: 'tradeType', headerName: 'Trade Type' },
            { field: 'orderType', headerName: 'Order Type' },
            { field: 'status', headerName: 'Status' },
            {
                field: 'action',

                sort: false,
                formatFunction: (value: any) => (
                    <AuthorizedElement authorizedUsername={this.props.currentPortfolio.userName}>
                        <>
                            {value.status === 'ACTIVE' ? (
                                <>
                                    <Trade
                                        icon={<InflyIcon name={'edit'} />}
                                        key={value.orderId}
                                        variant='text'
                                        showOnly={[
                                            TradeTabs[
                                                value.security.securityType as string
                                            ] as TradeTabs,
                                        ]}
                                        pendingOrder={value}
                                        portfolio={this.props.currentPortfolio.toPortfolioIdentifier()}
                                        onTradeDialogReturn={(
                                            status:
                                                | OrderStatus
                                                | OrderStatus[]
                                                | PendingOrder
                                                | undefined
                                        ) => {
                                            if (status) {
                                                const pendingOrder = status as PendingOrder;
                                                this.props.onMessage(
                                                    Message.info(
                                                        'Pending Order ' +
                                                            pendingOrder.orderId +
                                                            '  has been updated'
                                                    )
                                                );
                                            }
                                            this.props.onTradeDialogOpenClose(false);
                                        }}
                                    />

                                    <DialogButton
                                        buttonType={'iconButton'}
                                        dialogtitle={`Cancel Order for ${value.security.symbol}`}
                                        dialogprompt={`Are you sure you want to cancel order for ${value.security.symbol}?`}
                                        color={'warning'}
                                        startIcon={<InflyIcon name={'cancel'} />}
                                        onClick={() => this.cancelPendingOrder(value.orderId)}
                                    />
                                </>
                            ) : (
                                <DialogButton
                                    buttonType={'iconButton'}
                                    dialogtitle={`Delete Order for ${value.security.symbol}`}
                                    dialogprompt={`Are you sure you want to delete order for ${value.security.symbol}?`}
                                    color={'error'}
                                    startIcon={<InflyIcon name={'delete'} />}
                                    onClick={() => this.cancelPendingOrder(value.orderId)}
                                />
                            )}
                        </>
                    </AuthorizedElement>
                ),
                headerName: 'Action',
                hide: portfoliosOwner !== this.loggedInUsername,
            },
        ];
    };

    getPendingOrderRows = () => {
        return this.state.pendingOrders?.map((o: PendingOrder, index: any) => ({
            id: index,
            scheduledDate: CommonUtil.toEstString(o.scheduledDate).replace(/,/g, ' '),
            qty_amt: o.quantity
                ? `${o.quantity} units`
                : o?.maxAmount
                ? `$ ${o?.maxAmount}`
                : 'N/A',
            status: o.status,
            tradeType: o.tradeType,
            securityType: o.security.securityType,
            symbol: o.security.symbol,
            orderType: o.orderType,
            action: o,
        }));
    };

    componentWillUnmount() {
        // fix error: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    getTradeTabNameFromSecurityType = (securityType: SecurityType): TradeTabs[] => {
        let showOnly: TradeTabs[] = [];
        if (securityType === SecurityType.STOCK || securityType.toString() === 'ETF') {
            showOnly.push(TradeTabs.STOCK);
        }
        if (securityType === SecurityType.OPTION) {
            showOnly.push(TradeTabs.OPTION);
        }
        if (securityType === SecurityType.FUTURE) {
            showOnly.push(TradeTabs.FUTURE);
        }
        return showOnly;
    };

    render() {
        return (
            <Card>
                <CardHeader title={<>Pending&nbsp;Trade</>} />
                <CardContent>
                    <InflyTable
                        title={'pending orders'}
                        multipleSelection={false}
                        rows={this.getPendingOrderRows()}
                        order={'desc'}
                        orderBy={'scheduledDate'}
                        columns={this.getPendingOrderCofDef()}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(value: number) =>
                            this.setState({
                                pageSize: value,
                            })
                        }
                        columnNameForSearch={'symbol'}
                    />
                </CardContent>
            </Card>
        );
    }
}
