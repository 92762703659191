import {Message} from "../models/common/message";

import {BrokerAccount} from "../models/portfolio/brokerAccount";
import {CompletedTrade} from "../models/portfolio/completedTrade";
import {MatchedTrade} from "../models/portfolio/matchedTrade";
import {OpenPosition} from "../models/portfolio/openPosition";
import {OrderStatus} from "../models/portfolio/orderStatus";
import {PendingOrder} from "../models/portfolio/pendingOrder";
import {PerformanceMetrics} from "../models/portfolio/performanceMetrics";
import {BrokerType, Portfolio} from "../models/portfolio/portfolio";
import {PortfolioSpec} from "../models/portfolio/portfolioSpec";
import {SampleTriggeredTradeOrder, TradeOrder} from "../models/portfolio/tradeOrder";
import {Topportfolio} from "../models/portfolio/topportfolio";
import {RestAPIClient} from "./restAPIClient";
import {PortfolioFilter} from "../models/portfolio/portfolioFilter";
import { InvestflyModel } from "../models/InvestflyModel";

export class PortfolioAPIClient {

    private restApiClient: RestAPIClient;

    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }

    async getSampleTriggeredTradeOrders(): Promise<SampleTriggeredTradeOrder[]>{
        const res: object[] = await this.restApiClient.get(`/portfolio/trade/sample`);
        return InvestflyModel.parseList(res, SampleTriggeredTradeOrder.parseJSON);
    } 

    async getMyPortfolios(): Promise<Portfolio[]>  {
        const res: object[] = await this.restApiClient.get(`/portfolio/list`);
        return InvestflyModel.parseList(res, Portfolio.parseJSON);
    }

    async getPortfolio(portfolioId: string, brokerType: BrokerType):Promise<Portfolio> {
        const res = await this.restApiClient.get(`/portfolio/${brokerType}/${portfolioId}`);
        return Portfolio.parseJSON(res);
    }



    async createNewPortfolio(spec: PortfolioSpec):Promise<Portfolio> {
        const res = await this.restApiClient.post(`/portfolio/investfly/create`, spec);
        return Portfolio.parseJSON(res)
    }

    async doTrade(portfolioId: string, brokerType: BrokerType, tradeOrder: TradeOrder): Promise<OrderStatus> {
        const res = await this.restApiClient.post(`/portfolio/${brokerType}/${portfolioId}/trade`, tradeOrder);
        return OrderStatus.parseJSON(res)
    }


    async doMultiTrade(portfolioId: string, brokerType: BrokerType, tradeOrders: TradeOrder[]): Promise<OrderStatus[]> {
        const res: object[] = await this.restApiClient.post(`/portfolio/${brokerType}/${portfolioId}/multitrade`, tradeOrders);
        return InvestflyModel.parseList(res, OrderStatus.parseJSON);
    }

    async getPendingOrder(brokerType: BrokerType, orderId: string): Promise<PendingOrder> {
        const res = await this.restApiClient.get(`/portfolio/${brokerType}/pending/${orderId}/multitrade`);
        return PendingOrder.parseJSON(res)
    }

    async getPendingOrders(portfolioId: string, brokerType: BrokerType): Promise<PendingOrder[]> {
        const res: object[] = await this.restApiClient.get(`/portfolio/${brokerType}/${portfolioId}/pending`);
        return InvestflyModel.parseList(res, PendingOrder.parseJSON);
    }

    async cancelPendingOrder(portfolioId: string, brokerType: BrokerType, orderId: string): Promise<Message> {
        const res = await this.restApiClient.delete(`/portfolio/${brokerType}/${portfolioId}/pending/${orderId}`);
        return Message.parseJSON(res);
    }

    async updatePendingOrder(portfolioId: string, brokerType: BrokerType, orderId: string, order: TradeOrder): Promise<PendingOrder> {
        const res = await this.restApiClient.post(`/portfolio/${brokerType}/${portfolioId}/pending/${orderId}`, order);
        return PendingOrder.parseJSON(res)
    }

    async getCompletedTrades(portfolioId: string, brokerType: BrokerType): Promise<CompletedTrade[]> {
        const res: object[] = await this.restApiClient.get(`/portfolio/${brokerType}/${portfolioId}/trades`);
        return InvestflyModel.parseList(res, CompletedTrade.parseJSON);
    }

    async getCompletedTradesForOpenPosition(portfolioId: string, brokerType: BrokerType, 
                                    symbol: string, positionType: string, 
                                    openQuantity: number): Promise<CompletedTrade[]> {
        const res: object[] = await this.restApiClient.get(`/portfolio/${brokerType}/${portfolioId}/tradesfind?symbol=${symbol}&positionType=${positionType}&openQuantity=${openQuantity}`);
        return InvestflyModel.parseList(res, CompletedTrade.parseJSON);
    }

    async getMatchedTrades(portfolioId: string, brokerType: BrokerType): Promise<MatchedTrade[]> {
        const res: object[] = await this.restApiClient.get(`/portfolio/${brokerType}/${portfolioId}/closedtrades`);
        return InvestflyModel.parseList(res, MatchedTrade.parseJSON);
    }



    async getUserPortfolios(username: string): Promise<Portfolio[]> {
        const res: object[] = await this.restApiClient.get(`/portfolio/user/${username}`);
        return InvestflyModel.parseList(res, Portfolio.parseJSON);
    }

    async getPortfolioStocks(portfolioId: string, brokerType: BrokerType): Promise<OpenPosition[]> {
        const res: object[] = await this.restApiClient.get(`/portfolio/${brokerType}/${portfolioId}/portfoliostocks`);
        return InvestflyModel.parseList(res, OpenPosition.parseJSON);
    }

    async deleteVirtualPortfolio(portfolioId: string): Promise<Message> {
        const res = await this.restApiClient.delete(`/portfolio/investfly/${portfolioId}`);
        return Message.parseJSON(res);
    }

    async updateVirtualPortfolio(portfolioSpec: PortfolioSpec): Promise<Portfolio> {
        const res = await this.restApiClient.post(`/portfolio/investfly/${portfolioSpec.portfolioId}`, portfolioSpec);
        return Portfolio.parseJSON(res);
    }

    async patchVirtualPortfolio(portfolioSpec: PortfolioSpec): Promise<Portfolio> {
        const res = await this.restApiClient.post(`/portfolio/investfly/${portfolioSpec.portfolioId}/patch`, portfolioSpec);
        return Portfolio.parseJSON(res);
    }

    async getPerformance(portfolioId: string): Promise<PerformanceMetrics> {
        const res = await this.restApiClient.get(`/portfolio/investfly/${portfolioId}/performance`);
        return PerformanceMetrics.parseJSON(res);
    }



}