import {InvestflyUtil} from '../../utils/investflyUtil';
import {InvestflyModel} from '../InvestflyModel';
import {Security} from '../marketdata/security';
import {PositionType} from './positionType';

export class OpenPosition extends InvestflyModel{
    security: Security;
    position: PositionType;
    avgPrice: number;
    quantity: number;
    purchaseDate: Date;

    currentValue: number;
    currentPrice: number;
    profitLoss: number;
    percentChange: number;

    static parseJSON(obj: {[index:string]: any}): OpenPosition {
        let openPosition = new OpenPosition();
        Object.assign(openPosition, obj);
        openPosition.security = Security.parseJSON(obj.security);
        openPosition.purchaseDate = InvestflyUtil.strToDate(obj.purchaseDate)!;
        return openPosition;
    }
}