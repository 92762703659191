/** @format */

import { Box, FormControl, FormLabel, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { Component } from 'react';
import withRouter from 'containers/HOC/WithRouter';
import { ActionButton } from 'containers/common/ActionButton';
import InflyHelperText from 'containers/common/InflyHelperText';
import { RouteProps } from 'util/commonProps';

interface ChangeEmailProps extends RouteProps {
    onSubmit: Function;
    email: string;
}

interface ChangeEmailState {
    newEmail: string;
}

class ChangeEmail extends Component<ChangeEmailProps, ChangeEmailState> {
    constructor(props: ChangeEmailProps) {
        super(props);
        this.state = { newEmail: '' };
    }

    changeEmail = async () => {
        return this.props.onSubmit(this.state.newEmail);
    };

    onChange = (event: any) => {
        this.setState({ newEmail: event.target.value });
    };

    render() {
        const { newEmail } = this.state;

        return (
            <Paper elevation={0} square={false} sx={{ paddingInline: 3 }}>
                <Typography variant={'h5'} align={'center'}>
                    Change Email
                    <br />{' '}
                </Typography>
                <form>
                    <Box my={1}>
                        <InflyHelperText
                            info={
                                'Please enter new email. You will receive a link to confirm your email and' +
                                ' complete the change'
                            }
                        />
                    </Box>
                    <Grid container spacing={1} justifyContent='flex-start' alignItems={'center'}>
                        <Grid xs={12} item>
                            <FormControl component='fieldset' fullWidth>
                                <FormLabel component='legend'> Current Email</FormLabel>
                                <Typography
                                    component={'i'}
                                    variant={'body1'}
                                    color={'textPrimary'}
                                    gutterBottom
                                >
                                    {' '}
                                    {this.props.email}
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} item>
                            <FormControl component='fieldset' fullWidth>
                                <FormLabel component='legend'> New Email </FormLabel>
                                <TextField
                                    placeholder={'E.g. abc@example.com'}
                                    onChange={this.onChange}
                                    value={newEmail}
                                    id={'email'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} item>
                            <ActionButton
                                onClick={this.changeEmail}
                                color='primary'
                                id='changeEmailBtn'
                            >
                                Change Email
                            </ActionButton>
                        </Grid>
                    </Grid>
                    <br />
                </form>
            </Paper>
        );
    }
}

export default withRouter(ChangeEmail);
