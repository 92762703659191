import React from 'react';
import {RouterAndThemeProps} from 'util/commonProps';
import SessionUtil from 'util/SessionUtil';
import Navbar from 'containers/Navbar/Navbar';
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom';
import { ColorModeContext } from 'App';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/*
* All Top Level "Pages" with URL/Routes will be wrapped by this wrapper. In addition to wrapping with the NavBar,
* this will also pass most commonly used parameters (route paramters, theme mode and screen size) and props
* They are being passed becuase most components are implemented as class components and acccessing them is easier with Hooks
*/

const withNavBarAndAuth = <P extends object>(Component: React.ComponentType<P>) => {

    // The Type of the props is (P - RouterAndThemeProps) because router and theme properties are added by this HOC
    // whereas the remaining (if any) must be specified at delcaration of the component
    return function NavBarAndAuthWrapper(props: Omit<P, keyof RouterAndThemeProps>) {

        let location = useLocation();
        let navigate = useNavigate(); 
        let params = useParams(); 
        let theme = useTheme();
        


        const isXS = useMediaQuery('(min-width:0px)');
        const isSM = useMediaQuery('(min-width:600px)');
        const isMD = useMediaQuery('(min-width:900px)');
        const isLG = useMediaQuery('(min-width:1200px)');
        const isXL = useMediaQuery('(min-width:1536px)');
        const isMobile = isXS && !isMD;

        const isAuthenticate = SessionUtil.isUserLoggedIn();

        if (isAuthenticate) {
            return <Navbar  mode={theme.palette.mode} navigate={navigate} location={location} params={params} isMobile={isMobile}>
                <Component {...props as P}  mode={theme.palette.mode} navigate={navigate} location={location} params={params}  isMobile={isMobile} />
            </Navbar>;
        }

        const currentUrl = location.pathname + location.search;

        return <Navigate to={`/?toPage=${window.encodeURIComponent(currentUrl)}`} state={{from: location}} replace/>;
    };

}

export default withNavBarAndAuth