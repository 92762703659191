/** @format */

import { Box, Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import DocumentHeader from './DocumentHeader';
import { AppContext } from '../../util/appContext';

function EmptyCard(props: { title: string; emptyMsg: string; actionProps?: any[]; children?: any }) {
    return (
        <DocumentHeader title={props.title}>
            <Card variant='outlined' elevation={0}>
                <CardHeader
                    title={props.title}
                    action={ props.actionProps ?
                        <Grid
                            container
                            justifyContent={'flex-end'}
                            gap={2}
                            direction={{ md: 'row', xs: 'column' }}
                            alignItems={{ md: 'center', xs: 'stretch' }}
                        >
                            {props.actionProps.map((actionProp, index) => (
                                <Grid item key={`empty_list_page_action_${index}`}>
                                    {actionProp}
                                </Grid>
                            ))}
                        </Grid> : <></>
                    }
                />
                <CardContent>
                    <Stack gap={2}>
                        <Typography variant='body2'>
                            {props.emptyMsg}
                        </Typography>
                        
                        {props.children}
                    </Stack>
                </CardContent>
            </Card>
        </DocumentHeader>
    );
}

export default EmptyCard;
