import {InvestflyModel} from '../InvestflyModel';
import {Message} from '../common/message';

export enum Status {
    SCHEDULED = 'SCHEDULED',
    EXPIRED = 'EXPIRED',
    ERROR = 'ERROR',
    COMPLETED = 'COMPLETED'
}

export class OrderStatus extends InvestflyModel {
    orderId: string;
    status: Status;
    message: Message;

    static parseJSON(obj: { [index: string]: any }): OrderStatus {
        let orderStatus = new OrderStatus();
        Object.assign(orderStatus, obj);
        return orderStatus;
    }

    getDefaultMessage() : Message{

        if(this.message) return this.message;


        if (this.status === Status.COMPLETED) {
            return Message.success('Order Completed: ' + this.orderId);
        }
        if (this.status === Status.SCHEDULED) {
            return Message.info('Order Scheduled: ' + this.orderId);
        }
        if (this.status === Status.EXPIRED) {
            return Message.warning('Order Expired: ' + this.orderId);
        }

        // this will never happen becuase the backend throws Exception when order fails instead of sending OrderStaus with FAILED status
        // this may be used if this object is used to represent pending order status or by returning limit orderstaus from broker
        return Message.error('Order Failed: ' + this.orderId);      
    }

    static getMultiMessage(orderStatusArr: OrderStatus[]): Message {
        if(orderStatusArr.length === 1) return orderStatusArr[0].getDefaultMessage();

        let completed: string = "";
        let scheduled: string = "";
        let expired: string = "";
        let failed: string = "";
        for(let orderStatus of orderStatusArr){
            if(orderStatus.status == Status.COMPLETED) completed = completed + ","+orderStatus.orderId;
            if(orderStatus.status == Status.SCHEDULED) scheduled = scheduled + ","+orderStatus.orderId;
            if(orderStatus.status == Status.EXPIRED) expired = expired + ","+orderStatus.orderId;
            if(orderStatus.status == Status.ERROR) failed = failed + ","+orderStatus.orderId;
        }
        
        let all = "";
        if(completed.length > 0){
            all = all + " COMPPLETED: " + completed;
        }
        if(all.length > 0){
            all = all +", ";
        }
        if(scheduled.length > 0){
            all = all + "SCHEDULED: " + scheduled;
        }
        if(all.length > 0){
            all = all +", ";
        }
        if(expired.length > 0){
            all = all + "EXPIRED: " + expired;
        }
        if(all.length > 0){
            all = all +", ";
        }
        if(failed.length > 0){
            all = all + "FAILED: " + failed;
        }

        return Message.info(all);
    }

    static getMessage(orderStatus: OrderStatus|OrderStatus[]){
        if(orderStatus instanceof OrderStatus){
            return orderStatus.getDefaultMessage();
        }else{
            return OrderStatus.getMultiMessage(orderStatus as OrderStatus[]);
        }
    }

}