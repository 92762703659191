/** @format */

import React, { Component } from 'react';
import { MarketDataAPIClient, Message, StockNews } from '../../api';
import CommonUtil from '../../util/CommonUtil';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { AppContext } from '../../util/appContext';
import { LoadingCard } from '../common/LoadingCard';
import { ErrorCard } from '../common/ErrorCard';

interface StockNewsListProps {
    symbol: string;
}

interface StockNewsListState {
    news: StockNews[];
    ready: boolean;
    message?: Message;
}

class StockNewsList extends Component<StockNewsListProps, StockNewsListState> {
    marketDataAPIClient: MarketDataAPIClient;

    constructor(props: StockNewsListProps) {
        super(props);
        this.marketDataAPIClient = AppContext.getInstance().marketDataApiClient;

        this.state = {
            news: undefined!,
            ready: false,
        };
    }

    componentDidMount() {
        this.marketDataAPIClient
            .getStockNews(this.convertIndexToETF(this.props.symbol))
            .then((news: StockNews[]) => this.setState({ news: news }))
            .catch((error) => this.setState({ message: Message.fromError(error) }))
            .finally(() => this.setState({ ready: true }));
    }

    convertIndexToETF(symbol: string) {
        // since Polygon does not support index, we use corresponding ETF
        if (symbol === '^GSPC') return 'SPY';
        if (symbol === '^IXIC') return 'QQQ';
        if (symbol === '^RUT') return 'IWM';
        return symbol;
    }

    render() {
        if (!this.state.ready) {
            return <LoadingCard title={'Stock News'} />;
        }
        if (this.state.ready && this.state.message?.isError()) {
            return (
                <ErrorCard title={'Stock News'} message={this.state.message?.message} />
            );
        }
        return (
            <>
                {this.state.news.map((n: StockNews, i: number) => {
                    let description = CommonUtil.clippedUpto(n.description ?? '', 125);
                    const publishedWeekDay = CommonUtil.getWeekDayName(n.publishedDate);
                    const publishedMonth = CommonUtil.getMonthName(n.publishedDate);
                    const publishedHM = `${n.publishedDate.getHours()}:${n.publishedDate.getMinutes()} ${CommonUtil.getAM_PM(
                        n.publishedDate
                    )}`;
                    const publishedYear = n.publishedDate.getFullYear();
                    const publishedDate = `${publishedWeekDay}, ${publishedMonth}, ${publishedYear} at ${publishedHM}`;
                    return (
                        <Card key={n.title + i}>
                            <CardActionArea
                                sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1 }}
                                href={n.articleUrl}
                                target='_blank'
                            >
                                <CardMedia
                                    component='img'
                                    loading={'lazy'}
                                    sx={{
                                        marginLeft: 1,
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        height: 100,
                                        width: 100,
                                    }}
                                    image={n.imageUrl}
                                    alt={n.title}
                                />
                                <CardContent>
                                    <Typography variant='body1' gutterBottom component='div'>
                                        {n.title}
                                        <Typography
                                            variant='overline'
                                            textTransform={'inherit'}
                                            component={'div'}
                                        >
                                            {publishedDate}
                                        </Typography>
                                    </Typography>

                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                        overflow={'hidden'}
                                        whiteSpace={'normal'}
                                        textOverflow={'ellipsis'}
                                    >
                                        {description}&nbsp;
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    );
                })}
            </>
        );
    }
}

export default StockNewsList;
