import {InvestflyModel} from '../InvestflyModel';
import {OptionExpiry} from './optionExpiry';
import {OptionQuote} from './quote';


export class OptionChain extends InvestflyModel {
    underlyingPrice: number;
    expiry: OptionExpiry;

    puts: OptionQuote[];
    calls: OptionQuote[];


    static parseJSON(obj: {[index:string]: any} ): OptionChain{
        let chain = new OptionChain();
        Object.assign(chain, obj);

        chain.expiry = OptionExpiry.parseJSON(obj.expiry);
        chain.puts = InvestflyModel.parseList(obj.puts, OptionQuote.parseJSON);
        chain.calls = InvestflyModel.parseList(obj.calls, OptionQuote.parseJSON);
        
        return chain;
    }
}