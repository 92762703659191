import { Button, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { Message, OrderType, PositionType, StrategyAPIClient, TimeInterval } from "api";
import { SecurityTriggerExpression } from "api/models/trigger/SecurityTriggerExpression";
import { SecurityUniverseSelector } from "api/models/trigger/SecurityUniverseSelector";
import { ClosePositionConfig, OpenPositionConfig, StandardStrategyConfig } from "api/models/strategy/StandardStrategyConfig";
import { ITextField } from "containers/common/ITextField";
import { SelectField, SelectOption, selectOptionsFromEnum } from "containers/common/SelectField";
import { SecurityFilterExpressionComp } from "containers/Trigger/SecurityFilterExpressionComp";
import { SecurityUniverseSelectorComp } from "containers/Trigger/SecurityUniverseSelectorComp";
import { TimeIntervalComp } from "containers/Trigger/TimeIntervalComp";
import React from "react";
import { AppContext } from "util/appContext";
import { ActionButton } from "containers/common/ActionButton";
import InflyHelperText from "containers/common/InflyHelperText";
import { SectionHeader } from "containers/common/SectionHeader";


type StandardStrategyCompProps = {
    strategyId: number,
    strategyConfig: StandardStrategyConfig,
    onChange: (strategyConfig: StandardStrategyConfig, startBacktest: boolean) => void;
    onMessage: (message: Message) => void;
}
export function StandardStrategyConfigComp({strategyId, strategyConfig, onChange, onMessage}: StandardStrategyCompProps): React.JSX.Element {

    function onScopeChange(securityUniverseSelector: SecurityUniverseSelector){
        strategyConfig.scopeConfig = securityUniverseSelector;
        onChange(strategyConfig, false);
    }

    function onOpenPositionChange(openPositionConfig: OpenPositionConfig){
        strategyConfig.openPositionConfig = openPositionConfig;
        onChange(strategyConfig, false);
    }

    function onClosePositionChange(closePositionConfig: ClosePositionConfig){
        strategyConfig.closePositionConfig = closePositionConfig;
        onChange(strategyConfig, false);
    }

    async function onStrategySave(startBacktest: boolean): Promise<void>{

        let error = strategyConfig.validate();
        if(error){
            onMessage(Message.error(error));
        }else{
            try{
                let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
                let newConfig: StandardStrategyConfig = await strategyApiClient.updateStrategyConfig(strategyId, strategyConfig);
                onChange(newConfig, startBacktest);
                onMessage(Message.success("Strategy Settings Changes Saved"));
            }catch(err){
                onMessage(Message.fromError(err));
            }
        }
    }
    
    return <Stack spacing={2}>
        <Card raised>
            <CardHeader title='Strategy Settings' action={<Stack direction={'row'} spacing={1}>
                <ActionButton label="Save" onClick={() => onStrategySave(false)}></ActionButton>
                <ActionButton label="Save and Backtest" onClick={() => onStrategySave(true)}></ActionButton>
            </Stack> } />
            <CardContent>
                <Stack>

                    <StrategyScopeConfigComp scope={strategyConfig.scopeConfig} onScopeChange={onScopeChange} />
                    <OpenPositionComp openPositionConfig={strategyConfig.openPositionConfig} onChange={onOpenPositionChange} />
                    <ClosePositionComp closePositionConfig={strategyConfig.closePositionConfig} onChange={onClosePositionChange} />

                </Stack>

            </CardContent>
        </Card>



    </Stack>
}

export type StrategyScopeConfigCompProps = {
    scope: SecurityUniverseSelector;
    onScopeChange: (scope: SecurityUniverseSelector) => void;
}

export function StrategyScopeConfigComp({scope, onScopeChange}: StrategyScopeConfigCompProps): React.JSX.Element {
    return <>
        <Card>
                        <CardHeader title="Strategy Universe Selection"/>
                        <CardContent>
                            <Stack spacing={2}>
                                <InflyHelperText info={"Universe Selection narrows down securities against which strategy rules are continuously applied in real time. Limiting the securities makes the strategy perform faster."} />
                                <SecurityUniverseSelectorComp securityUniverseSelector={scope} onChange={onScopeChange} />
                            </Stack>
                            
                        </CardContent>
                    </Card>
    </>
}


type OpenPositionCompProps = {
    openPositionConfig: OpenPositionConfig;
    onChange: (openPositionConfig: OpenPositionConfig) => void;
}
export function OpenPositionComp({openPositionConfig, onChange}: OpenPositionCompProps): React.JSX.Element {

    function onPercentAllocationChange(allocation: number){
        openPositionConfig.percentAllocation = allocation;
        onChange(openPositionConfig);
    }

    function onOrderTypeChange(selectOption: SelectOption){
        openPositionConfig.orderType = selectOption.value;
        onChange(openPositionConfig);
    }

    function onPositionTypeChange(selectOption: SelectOption){
        openPositionConfig.positionType = selectOption.value;
        onChange(openPositionConfig);
    }

    function onOpenExpressionChange(expression: SecurityTriggerExpression|undefined){
        openPositionConfig.openExpression = expression!;
        onChange(openPositionConfig);
    } 

    const supportedOrderTypes = selectOptionsFromEnum(OrderType).filter(so => so.value == OrderType.MARKET_ORDER || so.value == OrderType.LIMIT_ORDER)

    return  <Card>
            <CardHeader title='Open Position Settings'/>
            <CardContent>
                <Stack spacing={5}>

                <Stack spacing={2}>
                    <SectionHeader header="Trade Order Settings" />
                    <InflyHelperText info="Specify if you want to open long or short postion and the order type. Config based strategy can only open either long or short position when the condition specified below is met." />
                    <Stack direction={"row"} spacing={5} justifyContent={'flex-start'}>
                        <SelectField width={'120px'} label='Position Type' value={openPositionConfig.positionType} options={selectOptionsFromEnum(PositionType)} onSelectChange={onPositionTypeChange}/>
                        <SelectField label='Order Type' value={openPositionConfig.orderType} options={supportedOrderTypes} onSelectChange={onOrderTypeChange}/>
                    </Stack>
                </Stack>

                <Stack spacing={2}>
                    <InflyHelperText info="Specify the percent of the buying power you want to invest in one open position. For e.g, if you specify 5% and you have $10,000 in your account, each open position will be allocated $500." />
                    <ITextField type="number" label="Percent Allocation" value={openPositionConfig.percentAllocation} onChange={onPercentAllocationChange}/>  
                </Stack>
               
                <Stack spacing={2}>
                    <SectionHeader header="Open Position Condition" />
                    <InflyHelperText info="Specify the condition for opening a new position. A new position is opened when this condition transitions from False to True for any security (i.e it is event based)" />
                    <SecurityFilterExpressionComp required securityFilterExpression={openPositionConfig.openExpression} onChange={onOpenExpressionChange} />
                </Stack>
                
                </Stack>
            </CardContent>
        </Card>
}


export type ClosePositionCompProps = {
    closePositionConfig: ClosePositionConfig;
    onChange: (closePositionConfig: ClosePositionConfig) => void;
}

export function ClosePositionComp({closePositionConfig, onChange}: ClosePositionCompProps): React.JSX.Element {

    function onTargetProfitChange(value: number){
        closePositionConfig.standardCloseCriteria.targetProfit = value;
        onChange(closePositionConfig);
    }

    function onStopLossCHange(value: number){
        closePositionConfig.standardCloseCriteria.stopLoss = value;
        onChange(closePositionConfig);
    }

    function onTrailingStopChange(value: number){
        closePositionConfig.standardCloseCriteria.trailingStop = value;
        onChange(closePositionConfig);
    }

    function onTimeoutChnage(timeInterval?: TimeInterval){
        closePositionConfig.standardCloseCriteria.timeout = timeInterval;
        onChange(closePositionConfig);
    }

    function onCloseExpressionChange(expression: SecurityTriggerExpression|undefined){
        // undefined is received when user deletes the expression like in close position settings
        closePositionConfig.closeExpression = expression;
        onChange(closePositionConfig);
    }

    function onOrderTypeChange(selectOption: SelectOption){
        closePositionConfig.orderType = selectOption.value;
        onChange(closePositionConfig);
    }

    const supportedOrderTypes = selectOptionsFromEnum(OrderType).filter(so => so.value == OrderType.MARKET_ORDER || so.value == OrderType.LIMIT_ORDER)

    return <Card>
        <CardHeader title='Close Position Settings' />
        <CardContent>

        <Stack spacing={5}>

            <Stack spacing={2}>
                <SectionHeader header="Standard Close Criteria" />
                <InflyHelperText info="Specify target profit, stop loss, trailing stop loss or timeout to exit open position. At least one is required. " />
                <Stack direction={"row"} justifyContent={'flex-start'} spacing={5}>
                    <ITextField label='Target Profit' type="number" value={closePositionConfig.standardCloseCriteria.targetProfit} onChange={onTargetProfitChange}/>
                    <ITextField label='Stop Loss' type="number" value={closePositionConfig.standardCloseCriteria.stopLoss} onChange={onStopLossCHange}/>
                    
                </Stack>
                <Stack  direction={"row"} justifyContent={'flex-start'} spacing={5}>
                    <ITextField label='Trailing Stop ' type="number" value={closePositionConfig.standardCloseCriteria.trailingStop} onChange={onTrailingStopChange}/>
                    <TimeIntervalComp label="Timeout Interval" timeInterval={closePositionConfig.standardCloseCriteria.timeout} onChange={onTimeoutChnage}/>
                </Stack>
            </Stack>


            <Stack spacing={2}>
                <SectionHeader header="Close Position Condition" />
                <InflyHelperText info="Specify order type and condition to close an open position. An open position is closed when the condition below changes from False to True (i.e event based)." />
                <SelectField label='Order Type' value={closePositionConfig.orderType} options={supportedOrderTypes} onSelectChange={onOrderTypeChange}/>
                <SecurityFilterExpressionComp required={false} securityFilterExpression={closePositionConfig.closeExpression} onChange={onCloseExpressionChange} />
            </Stack>

            

            </Stack>

        </CardContent>

    </Card>
}


