/** @format */

import React from 'react';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

interface CopyToClipboardState {
    isCopied: boolean;
}

interface CopyToClipboardProps {
    label: string;
    text: string;
    onCopy?: (...args: any) => void;
}

class CopyToClipboard extends React.Component<CopyToClipboardProps, CopyToClipboardState> {
    textarea: any;

    constructor(props: CopyToClipboardProps) {
        super(props);
        this.textarea = null;
        this.state = { isCopied: false };
    }

    copy() {
        const textarea = this.textarea;
        let { text, onCopy } = this.props;

        if (onCopy) {
            textarea.value = onCopy.call(this, text);
        }
        textarea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'SUCCESSFUL' : 'UNSUCCESSFUL';
            console.log(msg + ' COPING DATA');
            this.setState({ isCopied: true });
        } catch (err) {
            console.log(`Oops, unable to copy (${err.message})`);
        }
    }

    render() {
        const { label } = this.props;

        return (
            <>
                <textarea
                    ref={(textarea) => {
                        this.textarea = textarea;
                    }}
                    style={{ display: 'none' }}
                />
                <Stack flexDirection={{ md: 'row', xs: 'column' }}>
                    <Typography
                        onClick={(event: any) => {
                            event.preventDefault();
                            this.copy();
                        }}
                        component={'span'}
                        fontSize={'1rem'}
                        borderRadius={6}
                        minWidth={'max-content'}
                        sx={(theme) => {
                            return {
                                paddingInline: '5px',
                                cursor: 'copy',
                                backgroundColor:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.grey.A100
                                        : theme.palette.grey.A700,
                                color: theme.palette.mode === 'dark' ? 'black' : 'white',
                            };
                        }}
                    >
                        {label}
                    </Typography>
                    {this.state.isCopied && (
                        <Typography variant={'caption'} component={'span'} align={'center'}>
                            &nbsp;copied &#10004;
                        </Typography>
                    )}
                </Stack>
            </>
        );
    }
}

export default CopyToClipboard;
