import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, ListItemIcon, ListItemText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SignalCellular0BarOutlined } from '@mui/icons-material';
import InflyCarousel from './InflyCarousel';
import { InflyIcon } from './InflyIcon';

export type MenuOption = {
    icon?: any
    label: string, 
    onClick: () => void;
}

export type MoreMenuProps = {
    menuOptions : MenuOption[];
}

export function MoreMenu({menuOptions}: MoreMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (mo: MenuOption) => {
    setAnchorEl(null);
    mo.onClick();
  };

  return (
    <>
      <IconButton  onClick={handleClick} >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {menuOptions.map(mo => {
            return <MenuItem key={mo.label} onClick={() => handleClose(mo)}>
              {mo.icon &&
                            <ListItemIcon>
                            <InflyIcon name={mo.icon} />
                          </ListItemIcon>
              }
              <ListItemText>{mo.label}</ListItemText>
              </MenuItem>
        })}
      </Menu>
    </>
  );
}