import { UserType } from "./user";

export enum OPERATION {
    CREATE_PORTFOLIO = "CREATE_PORTFOLIO",
    CREATE_ALERT = "CREATE_ALERT",
    CREATE_STRATEGY = "CREATE_STRATEGY",
    DEPLOY_STRATEGY = "DEPLOY_STRATEGY",
    BACKTEST_STRATEGY = "BACKTEST_STRATEGY",
    CREATE_SCREENER = "CREATE_SCREENER",
    ADD_CUSTOMINDICATOR = "ADD_CUSTOMINDICATOR",
    CONNECT_BROKER = "CONNECT_BROKER",
    CREATE_WATCHLIST = "CREATE_WATCHLIST",
    CUSTOM_TRIGGER_TRADE = "CUSTOM_TRIGGER_TRADE",
    TRADE_DERIVATIVES  = "TRADE_DERIVATIVES"// Options and Futures
}

let STANDARD_USER_LIMITS :{[index:string]: number|boolean} = {};
STANDARD_USER_LIMITS[OPERATION.CREATE_PORTFOLIO] = 1;
STANDARD_USER_LIMITS[OPERATION.CREATE_ALERT] = 1;
STANDARD_USER_LIMITS[OPERATION.CREATE_SCREENER] = 1;
STANDARD_USER_LIMITS[OPERATION.CREATE_WATCHLIST] = 1;
STANDARD_USER_LIMITS[OPERATION.CREATE_STRATEGY] = 1;
STANDARD_USER_LIMITS[OPERATION.ADD_CUSTOMINDICATOR] = false;
STANDARD_USER_LIMITS[OPERATION.CUSTOM_TRIGGER_TRADE] = false;
STANDARD_USER_LIMITS[OPERATION.DEPLOY_STRATEGY] = false;
STANDARD_USER_LIMITS[OPERATION.BACKTEST_STRATEGY] = false;
STANDARD_USER_LIMITS[OPERATION.TRADE_DERIVATIVES] = false;
STANDARD_USER_LIMITS[OPERATION.CONNECT_BROKER] = false;
STANDARD_USER_LIMITS[OPERATION.CUSTOM_TRIGGER_TRADE] = false;


let PREMIUM_USER_LIMITS :{[index:string]: number|boolean} = {};
PREMIUM_USER_LIMITS[OPERATION.CREATE_PORTFOLIO] = 5;
PREMIUM_USER_LIMITS[OPERATION.CREATE_ALERT] = 5;
PREMIUM_USER_LIMITS[OPERATION.CREATE_SCREENER] = 5;
PREMIUM_USER_LIMITS[OPERATION.CREATE_WATCHLIST] = 5;
PREMIUM_USER_LIMITS[OPERATION.CREATE_STRATEGY] = 5;
PREMIUM_USER_LIMITS[OPERATION.CUSTOM_TRIGGER_TRADE] = true;
PREMIUM_USER_LIMITS[OPERATION.ADD_CUSTOMINDICATOR] = true;
PREMIUM_USER_LIMITS[OPERATION.DEPLOY_STRATEGY] = true;
PREMIUM_USER_LIMITS[OPERATION.BACKTEST_STRATEGY] = true;
PREMIUM_USER_LIMITS[OPERATION.TRADE_DERIVATIVES] = true;
PREMIUM_USER_LIMITS[OPERATION.CONNECT_BROKER] = false;


let ELITE_USER_LIMITS :{[index:string]: number|boolean} = {};
ELITE_USER_LIMITS[OPERATION.CREATE_PORTFOLIO] = 10;
ELITE_USER_LIMITS[OPERATION.CREATE_ALERT] = 10;
ELITE_USER_LIMITS[OPERATION.CREATE_SCREENER] = 10;
ELITE_USER_LIMITS[OPERATION.CREATE_WATCHLIST] = 10;
ELITE_USER_LIMITS[OPERATION.CREATE_STRATEGY] = 10;
ELITE_USER_LIMITS[OPERATION.CUSTOM_TRIGGER_TRADE] = true;
ELITE_USER_LIMITS[OPERATION.ADD_CUSTOMINDICATOR] = true;
ELITE_USER_LIMITS[OPERATION.DEPLOY_STRATEGY] = true;
ELITE_USER_LIMITS[OPERATION.BACKTEST_STRATEGY] = true;
ELITE_USER_LIMITS[OPERATION.TRADE_DERIVATIVES] = true;
ELITE_USER_LIMITS[OPERATION.CONNECT_BROKER] = true;


export const USER_LIMITS :{[index:string]: {[index:string]: number|boolean}} = {};
USER_LIMITS[UserType.STANDARD] = STANDARD_USER_LIMITS;
USER_LIMITS[UserType.PREMIUM] = PREMIUM_USER_LIMITS;
USER_LIMITS[UserType.ELITE] = ELITE_USER_LIMITS;
