import { Message } from "../common/message";
import { Security, SecurityType } from "../marketdata/security";
import { FinancialQuery } from "./FinancialQuery";

export enum SecurityUniverseType {
    STANDARD_LIST = "STANDARD_LIST",
    CUSTOM_LIST = "CUSTOM_LIST",
    FUNDAMENTAL_QUERY = "FUNDAMENTAL_QUERY"
}

export class SecurityUniverseSelector {
    universeType: SecurityUniverseType;
    customList?: SecurityList;
    financialQuery?: FinancialQuery;
    standardList?: StandardSymbolsList;

    static createDefault(): SecurityUniverseSelector {
        let obj = new SecurityUniverseSelector();
        obj.universeType = SecurityUniverseType.STANDARD_LIST;
        obj.standardList = StandardSymbolsList.SP_100;
        return obj;
    }

    static singleSecurrity(security: Security): SecurityUniverseSelector {
        let obj = new SecurityUniverseSelector();
        obj.universeType = SecurityUniverseType.CUSTOM_LIST;
        obj.customList = SecurityList.singleSecurity(security);
        return obj;
    }

    setUniverseType(universeType: SecurityUniverseType){
        this.universeType = universeType;
        if(universeType == SecurityUniverseType.STANDARD_LIST){
            this.standardList = StandardSymbolsList.SP_100;
            this.customList = undefined;
            this.financialQuery = undefined;
        }else if(universeType == SecurityUniverseType.CUSTOM_LIST){
            this.customList = SecurityList.getDefault();
            this.standardList = undefined;
            this.financialQuery = undefined;
        }else if(universeType == SecurityUniverseType.FUNDAMENTAL_QUERY){
            this.financialQuery = FinancialQuery.getDefault();
            this.standardList = undefined;
            this.customList = undefined;
        }
    }

    clone(){
        let obj = new SecurityUniverseSelector();
        Object.assign(obj, this);
        if(this.customList){
            obj.customList = this.customList.clone();
        }
        if(this.financialQuery){
            obj.financialQuery = this.financialQuery.clone();
        }
        return obj;
    }

    validate(): string|undefined{
        if(!this.universeType){
            return "Security Universe Type is required";
        }
        if(this.universeType === SecurityUniverseType.STANDARD_LIST){
            if(!this.standardList){
                return "Standard List Name is required";
            }
        }else if(this.universeType === SecurityUniverseType.CUSTOM_LIST){
            if(!this.customList){
                return "Custom List is required";
            }
            return this.customList.validate();
        }else if(this.universeType === SecurityUniverseType.FUNDAMENTAL_QUERY){
            if(!this.financialQuery){
                return "Financial Query is required";
            }
            return this.financialQuery.validate();
        }
        
    }

    static parseJSON(obj: {[index:string]: any}): SecurityUniverseSelector {
        let securityUniverseSelector = new SecurityUniverseSelector()
        securityUniverseSelector.universeType = SecurityUniverseType[obj.universeType]
        if(obj.standardList){
            securityUniverseSelector.standardList = StandardSymbolsList[obj.standardList]
        }
        if(obj.customList){
            securityUniverseSelector.customList = SecurityList.parseJSON(obj.customList)
        }
        if(obj.financialQuery){
            securityUniverseSelector.financialQuery = FinancialQuery.parseJSON(obj.financialQuery)
        }
        return securityUniverseSelector;
    }
}



export class SecurityList {
    securityType: SecurityType;
    symbols: string[];

    clone(): SecurityList{
        let obj = new SecurityList();
        obj.securityType = this.securityType;
        obj.symbols = [...this.symbols];
        return obj;
    }

    static singleSecurity(security: Security): SecurityList {
        let obj = new SecurityList();
        obj.securityType = security.securityType;
        obj.symbols = [security.symbol];
        return obj;
    }

    static getDefault(): SecurityList {
        let obj = new SecurityList();
        obj.securityType = SecurityType.STOCK;
        obj.symbols = [];
        return obj;
    }

    validate(): string | undefined {
        if(!this.securityType){
            return "SecurityType is required for custom securityList";
        }
        if(!this.symbols){
            return "Security Symbols are required for custom securityList";
        }
        if(this.symbols.length === 0){
            return "At least one symbol is required for custom securityList";
        }
    }

    static parseJSON(obj: {[index:string]: any}): SecurityList {
        let securityList: SecurityList = new SecurityList();
        Object.assign(securityList, obj);
        return securityList;
    }
}

export enum StandardSymbolsList {
    SP_100 = "SP_100", 
    SP_500 = "SP_500", 
    NASDAQ_100 = "NASDAQ_100",
    NASDAQ_COMPOSITE = "NASDAQ_COMPOSITE",
    RUSSELL_1000 = "RUSSELL_1000",
    DOW_JONES_INDUSTRIALS = "DOW_JONES_INDUSTRIALS",
    ETFS = "ETFS",
    STOCKS = "STOCKS" // only used for screener
}


