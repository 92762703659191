import {RestAPIClient} from './restAPIClient';
import {Message} from '../models/common/message';
import {BrokerType} from '../models/portfolio/portfolio';
import {InvestflyModel} from '../models/InvestflyModel';
import { DeploymentLog, TradingStrategyModel } from '../models/strategy/TradingStrategyModel';
import { StrategyLog } from '../models/strategy/StrategyLog';
import { StandardStrategyConfig } from 'api/models/strategy/StandardStrategyConfig';


export class StrategyAPIClient {

    private restApiClient: RestAPIClient;

    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }

    async getStrategy(strategyId: number): Promise<TradingStrategyModel> {
        const res = await this.restApiClient.get(`/strategy/${strategyId}`);
        return TradingStrategyModel.parseJSON(res);
    }

    async getStrategyLogs(strategyId: number): Promise<DeploymentLog[]> {
        const res = await this.restApiClient.get(`/strategy/${strategyId}/logs`);
        return InvestflyModel.parseList(res, DeploymentLog.parseJSON);
    }

    async findStrategy(portfolioId: string, brokerType: BrokerType): Promise<TradingStrategyModel | null> {
        const res = await this.restApiClient.get(`/strategy/find?portfolio=${portfolioId}&broker=${brokerType}`);
        if(res == null) return null;
        return TradingStrategyModel.parseJSON(res);
    }

    async listSampleStrategies(): Promise<TradingStrategyModel[]> {
        const res = await this.restApiClient.get(`/strategy/list/sample`);
        return InvestflyModel.parseList(res, TradingStrategyModel.parseJSON);
    }

    async listStrategies(): Promise<TradingStrategyModel[]> {
        const res = await this.restApiClient.get(`/strategy/list`);
        return InvestflyModel.parseList(res, TradingStrategyModel.parseJSON);
    }

    async createStrategy(automation: TradingStrategyModel): Promise<TradingStrategyModel> {
        const res = await this.restApiClient.post(`/strategy/create`, automation);
        return TradingStrategyModel.parseJSON(res);
    }

    async updateNameDescriptionPublic(strategyId: number, automation: TradingStrategyModel): Promise<TradingStrategyModel> {
        const res = await this.restApiClient.post(`/strategy/${strategyId}/update`, automation);
        return TradingStrategyModel.parseJSON(res);
    }

    async updateStrategyConfig(strategyId: number, automation: StandardStrategyConfig): Promise<StandardStrategyConfig> {
        const res = await this.restApiClient.post(`/strategy/${strategyId}/update/config`, automation);
        return StandardStrategyConfig.parseJSON(res);
    }

    async updateStrategyCode(strategyId: number, pythonCode: string): Promise<string> {
        const res = await this.restApiClient.post(`/strategy/${strategyId}/update/code`, pythonCode);
        return res;
    }

    async cloneStrategy(strategyId: number): Promise<TradingStrategyModel> {
        const res = await this.restApiClient.post(`/strategy/${strategyId}/clone`, null);
        return TradingStrategyModel.parseJSON(res);
    }

    async deleteStrategy(strategyId: number): Promise<Message> {
        const res = await this.restApiClient.delete(`/strategy/${strategyId}`);
        return Message.parseJSON(res);
    }

    async deployStrategy(strategyId: number, portfolioId: string, brokerType: BrokerType, ): Promise<TradingStrategyModel> {
        const res = await this.restApiClient.post(`/strategy/${strategyId}/deploy?portfolio=${portfolioId}&broker=${brokerType}`, null);
        return TradingStrategyModel.parseJSON(res);
    }

    async unDeployStrategy(strategyId: number): Promise<TradingStrategyModel> {
        const res = await this.restApiClient.post(`/strategy/${strategyId}/undeploy`, null);
        return TradingStrategyModel.parseJSON(res);
    }

}