/** @format */

import { Box, Breadcrumbs, Grid, IconButton, Link } from '@mui/material';
import withScreenSize from 'containers/HOC/WithScreenSize';
import { Message, OrderStatus, PendingOrder, PortfolioIdentifier } from 'api';
import React from 'react';
import { PortfolioIdentifierProps } from './PortfolioDashboard';
import { ScreenSizeProp } from 'util/commonProps';
import Trade from 'containers/Trade/Trade';
import SendIcon from '@mui/icons-material/Send';
import AuthorizedElement from 'containers/common/AuthorizedElement';

interface PortfolioHeaderProps extends PortfolioIdentifierProps, ScreenSizeProp {
    onPortfolioSelect: (portfolioId: PortfolioIdentifier) => void;
}

type PortfolioHeaderState = {
    portfolioDialogOpen: boolean;
};

class PortfolioHeader extends React.Component<PortfolioHeaderProps, PortfolioHeaderState> {
    constructor(props: PortfolioHeaderProps) {
        super(props);
        this.state = {
            portfolioDialogOpen: false,
        };
    }

    render() {
        const propsPortolio = this.props.currentPortfolio;
        return (
            <>
                <Grid
                    container
                    flexDirection={{
                        xs: 'column',
                        md: 'row',
                    }}
                    marginTop={{ xs: 2, md: 0 }}
                    gap={2}
                    justifyContent='space-between'
                    alignItems={'center'}
                >
                    <Grid item>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link underline='hover' color='inherit' href='/portfolio/list'>
                                All Portfolios
                            </Link>
                            <Link underline='none' color='text.primary' aria-current='page'>
                                {propsPortolio.name ?? propsPortolio.portfolioId}
                            </Link>
                        </Breadcrumbs>
                    </Grid>

                    <Grid
                        item
                        container
                        justifyContent={'flex-end'}
                        gap={1}
                        direction={{ md: 'row', xs: 'column' }}
                        alignItems={{ md: 'center', xs: 'stretch' }}
                        flex={[1, 1, 'max-content']}
                    >
                        <Grid item>
                            <AuthorizedElement
                                authorizedUsername={this.props.currentPortfolio.userName}
                            >
                                <Trade
                                    label='Submit Order'
                                    icon={<SendIcon />}
                                    portfolio={propsPortolio.toPortfolioIdentifier()}
                                    onTradeDialogReturn={(
                                        result:
                                            | OrderStatus
                                            | OrderStatus[]
                                            | PendingOrder
                                            | undefined
                                    ) => {
                                        if (result) {
                                            // filter out undefined
                                            let orderStatus = result as OrderStatus | OrderStatus[];
                                            this.props.onMessage(
                                                OrderStatus.getMessage(orderStatus)
                                            );
                                        }
                                    }}
                                />
                            </AuthorizedElement>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default PortfolioHeader;
