import {UserType} from '../../index';
import {InvestflyModel} from '../InvestflyModel';
import {PaymentPlan} from './paymentPlan';

export class PaymentDetail extends InvestflyModel {
    userType: UserType;
    token: string;
    paymentPlan: PaymentPlan;
    couponCode: string;

    static parseJSON(obj: {[index:string]: any} ): PaymentDetail {
        let paymentDetail = new PaymentDetail();
        Object.assign(paymentDetail, obj);
        paymentDetail.paymentPlan = PaymentPlan.parseJSON(obj.paymentPlan);
        return paymentDetail;
    }
}