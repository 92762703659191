/** @format */

import { Button, Stack } from '@mui/material';
import { AppConstant, OPERATION } from 'api';
import UpgradeCheckButton from 'containers/common/UpgradeCheckButton';
import React from 'react';
import tradierShortLogo from 'static/images/tradier-broker-short-logo.png';

import SessionUtil from 'util/SessionUtil';


export function TradierConnectButton(): React.JSX.Element {

    function getTradierAuthUrl() {
        const username = SessionUtil.findSession()?.username;
        const tradierClientId = AppConstant.TRADIER_CLIENT_ID;
        return (
            'https://api.tradier.com/v1/oauth/authorize?client_id=' +
            tradierClientId +
            '&scope=read,market,trade,stream&state=' +
            username
        );
    }

    return (
        <UpgradeCheckButton op={OPERATION.CONNECT_BROKER} label='Connect Broker'>

            <Stack id='TraderBroker-container' direction={'row'}>
                <img
                    className='brokerLogo'
                    src={tradierShortLogo}
                    width={30}
                    alt='Tradier Logo'
                />
                <Stack flexDirection={'row'} gap={1}>
                    <Button color='primary' href={getTradierAuthUrl()} target='_blank'>
                        Connect
                    </Button>
                </Stack>

            </Stack>

        </UpgradeCheckButton>
    )

}


