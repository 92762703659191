/** @format */

import { Box, Card, CardContent, CardHeader, CircularProgress, Stack, Theme } from '@mui/material';
import React from 'react';
import { SxProps } from '@mui/system';
import DocumentHeader from './DocumentHeader';

export interface LoadingCardProps {
    title: string;
    sx?: SxProps<Theme>;
}

export function LoadingCard(props: LoadingCardProps) {
    return (
        <DocumentHeader title={`${props.title}`}>
            <Card sx={{ ...props.sx }}>
                <CardHeader title={props.title} />
                <CardContent>
                    <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <CircularProgress />
                    </Stack>
                </CardContent>
            </Card>
        </DocumentHeader>
    );
}
