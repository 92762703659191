import {Message} from "../models/common/message";
import {RestAPIClient} from "./restAPIClient";
import {StockAlert, StockAlertSpec, TriggeredStock} from "../models/stockAlert/stockAlert";
import {InvestflyModel} from "../models/InvestflyModel";


export class StockAlertAPIClient {

    private restApiClient: RestAPIClient;

    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }

    async getSampleStockAlerts(): Promise<StockAlertSpec[]> {
        const res  = await this.restApiClient.get(`/alert/sample`);
        return InvestflyModel.parseList(res, StockAlertSpec.parseJSON);
    }

    async listMyAlerts(): Promise<StockAlert[]>{
        const res  = await this.restApiClient.get(`/alert/list`);
        return InvestflyModel.parseList(res, StockAlert.parseJSON);
    }

    async listUserAlerts(username: string): Promise<StockAlert[]>{
        const res  = await this.restApiClient.get(`/alert/${username}/list`);
        return InvestflyModel.parseList(res, StockAlert.parseJSON);
    }

    async createStockAlert(stockAlert: StockAlertSpec): Promise<StockAlert>{
        const res  = await this.restApiClient.post(`/alert/create`, stockAlert);
        return StockAlert.parseJSON(res);
    };

    async getStockAlert(alertId: number): Promise<StockAlert>{
        const res  = await this.restApiClient.get(`/alert/${alertId}`);
        return StockAlert.parseJSON(res);
    };

    async updateStockAlert(alertId: number, stockAlert: StockAlertSpec): Promise<StockAlert>{
        const res  = await this.restApiClient.post(`/alert/${alertId}`, stockAlert);
        return StockAlert.parseJSON(res);
    };

    async deleteStockAlert(alertId: number): Promise<Message>{
        const res  = await this.restApiClient.delete(`/alert/${alertId}`);
        return Message.parseJSON(res);
    };

    async getTriggeredStocks(alertId: number): Promise<TriggeredStock[]>{
        let url = "/alert/"+alertId+"/triggeredtickers";

        const res: object[]  = await this.restApiClient.get(url);
        return InvestflyModel.parseList(res, TriggeredStock.parseJSON);
    };

    async activateAlert(alertId: number): Promise<Message>{
        const res  = await this.restApiClient.post(`/alert/${alertId}/activate`, null);
        return Message.parseJSON(res);
    };

    async deactivateAlert(alertId: number): Promise<Message>{
        const res  = await this.restApiClient.post(`/alert/${alertId}/deactivate`, null);
        return Message.parseJSON(res);
    };
}