import CommonUtil from "util/CommonUtil";
import { InvestflyUtil } from "../../utils/investflyUtil";
import { InvestflyModel } from "../InvestflyModel";
import {FutureProduct} from "./futureProduct";
import {OptionExpiry} from "./optionExpiry";
import {Security, OptionType} from "./security";


export enum QuoteField {
    LASTPRICE = "LASTPRICE",
    DAY_OPEN = "DAY_OPEN",
    DAY_HIGH = "DAY_HIGH",
    DAY_LOW = "DAY_LOW",
    DAY_VOLUME = "DAY_VOLUME",
    PREV_DAY_CLOSE = "PREV_DAY_CLOSE",
    DAY_CHANGE  = "DAY_CHANGE",
    DAY_CHANGE_PCT = "DAY_CHANGE_PCT",
    DAY_CHANGE_OPEN  = "DAY_CHANGE_OPEN"

}


export class Quote  {
    symbol: string
    date: Date;
    lastPrice: number;
    volume: number;

    static parseJSON(obj: {[index:string]: any}): Quote{
        let quote = new Quote();
        Object.assign(quote, obj);
        quote.date = InvestflyUtil.strToDate(obj.date)!;
        quote.lastPrice = CommonUtil.roundToTwoDecimal(quote.lastPrice)
        return quote;
    }
}

export class StockQuote extends Quote {
    dayOpen: number;
    dayHigh: number;
    dayLow: number;
    prevClose: number;
    todayChange: number;
    todayChangePct: number;
    static parseJSON(obj: {[index:string]: any}): StockQuote{
        let quote = Quote.parseJSON(obj);
        let stockQuote = new StockQuote();
        Object.assign(stockQuote, quote);
        if(stockQuote.todayChange !== undefined){
            stockQuote.todayChange = CommonUtil.roundToTwoDecimal(stockQuote.todayChange);
        }
        if(stockQuote.todayChangePct !== undefined){
            stockQuote.todayChangePct = CommonUtil.roundToTwoDecimal(stockQuote.todayChangePct);
        }
        if(stockQuote.prevClose !== undefined){
            stockQuote.prevClose = CommonUtil.roundToTwoDecimal(stockQuote.prevClose);
        }
        if(stockQuote.dayOpen !== undefined){
            stockQuote.dayOpen = CommonUtil.roundToTwoDecimal(stockQuote.dayOpen);
        }
        if(stockQuote.dayHigh){
            stockQuote.dayHigh = CommonUtil.roundToTwoDecimal(stockQuote.dayHigh);
        }
        if(stockQuote.dayLow){
            stockQuote.dayLow = CommonUtil.roundToTwoDecimal(stockQuote.dayLow);
        }
        return stockQuote;
    }

    static getQuoteFieldDesc(quoteField: QuoteField): string{
        if(quoteField == QuoteField.LASTPRICE){
            return "Last Market Price"
        }else if(quoteField == QuoteField.DAY_OPEN){
            return "Current Trading Day Open Price";
        }else if (quoteField === QuoteField.DAY_HIGH){
            return "Current Trading Day High Price";
        }else if (quoteField === QuoteField.DAY_LOW){
            return "Current Trading Day Low Price";
        }else if (quoteField === QuoteField.DAY_VOLUME){
            return "Current Trading Day Volume";
        }else if (quoteField === QuoteField.PREV_DAY_CLOSE){
            return "Previous Trading Day Closing Price";
        }else if (quoteField === QuoteField.DAY_CHANGE){
            return "Change from previous day closing price";
        }else if (quoteField === QuoteField.DAY_CHANGE_PCT){
            return "Percent change from previous day closing price";
        }else if (quoteField === QuoteField.DAY_CHANGE_OPEN){
            return "Price change from current day's open price";
        }else{
            throw new Error("Unknown quote field " + quoteField)
        }
    }
}

export class QuotesRequest {
    securities: Set<Security>;

    constructor(){
        this.securities = new Set<Security>();
    }

    addSecurity(security: Security): void{
        this.securities.add(security);
    }

    static stringify(key: string, value: any){
        if (key == "securities"){
            let s = value as Set<string>;
            return [...s];
        }else{
            return value;
        }
    }

}

export class QuotesResult {
    count: number;
    quotes: Quote[];

    static parseJSON(obj: {[index:string]: any}): QuotesResult {
        let result = new QuotesResult();
        Object.assign(result, obj);
        let func = Quote.parseJSON;
        if(obj.quotes && obj.quotes.length > 0 && "todayChange" in obj.quotes[0]){
            func = StockQuote.parseJSON;
        }
        result.quotes = InvestflyModel.parseList(obj.quotes, func);
        return result;
    }

}


export class OptionQuote extends Quote {
    bid: number;
    ask: number;
    openInterest: number;
    expiry: OptionExpiry;
    strikePrice: number;
    optionType: OptionType;
    inTheMoney: boolean;
    stock: string;

    static parseJSON(obj: {[index:string]: any}):OptionQuote {
        let quote = Quote.parseJSON(obj);
        let optionQuote = new OptionQuote();
        Object.assign(optionQuote, quote);
        optionQuote.expiry = OptionExpiry.parseJSON(obj.expiry);
        return optionQuote;
    }
}


export enum Month {
    JANUARY = "JANUARY",
    FEBRUARY = "FEBRUARY",
    MARCH = "MARCH",
    APRIL = "APRIL",
    MAY = "MAY",
    JUNE = "JUNE",
    JULY = "JULY",
    AUGUST = "AUGUST",
    SEPTEMBER = "SEPTEMBER",
    OCTOBER = "OCTOBER",
    NOVEMBER = "NOVEMBER",
    DECEMBER = "DECEMBER"
}


export class FutureQuote extends Quote {
    productCode: FutureProduct;
    month: Month;
    year: number;
    expiryDate: Date;

    openPrice: number;
    highPrice: number;
    lowPrice: number;
    priceChange: number;

    hasOptions: boolean;

    static parseJSON(obj: {[index:string]: any}):FutureQuote {
        let quote = Quote.parseJSON(obj);
        let futureQuote = new FutureQuote();

        Object.assign(futureQuote, quote);

        futureQuote.expiryDate = InvestflyUtil.strToDate(obj.expiryDate)!;

        return futureQuote;
    }
}