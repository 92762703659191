/** @format */

import React, { Component } from 'react';
import withRouter from '../HOC/WithRouter';
import DocumentHeader from '../common/DocumentHeader';
import ChangePasswordForm from './setting/ChangePasswordForm';
import { Box, Stack } from '@mui/material';
import { RouteProps } from '../../util/commonProps';

interface NewPasswordProps extends RouteProps {}

export class NewPassword extends Component<NewPasswordProps, {}> {
    constructor(props: NewPasswordProps) {
        super(props);
    }

    onSubmitSuccess() {
        const redirectToLogin = () => this.props.navigate('/');
        setTimeout(function () {
            redirectToLogin();
        }, 5000);
    }

    render() {
        return (
            <DocumentHeader title={'Change Password'}>
                <Stack
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    p={'100px'}
                >
                    <ChangePasswordForm onSubmit={() => this.onSubmitSuccess()} />
                </Stack>
            </DocumentHeader>
        );
    }
}

export default withRouter(NewPassword);
