/** @format */

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { InflyMessage } from 'containers/common/InflyMessage';
import { RouteProps } from 'util/commonProps';

import { Card, Stack } from '@mui/material';
import { AppConstant, Message, UserAPIClient, UserRole, UserSpec } from 'api';
import captchBg from 'static/images/captcha-bg.png';
import desktopDark from 'static/images/desktop-logo-dark.png';
import desktopWhite from 'static/images/desktop-logo-white.png';
import A from 'containers/common/Anchor';
import { AppContext } from 'util/appContext';
import CommonUtil from 'util/CommonUtil';
import withRouter from 'containers/HOC/WithRouter';
import DocumentHeader from 'containers/common/DocumentHeader';
import ReactGA from 'react-ga4';
import { ActionButton } from 'containers/common/ActionButton';
import { SelectField, selectOptionsFromEnum } from 'containers/common/SelectField';

interface RegisterFormProps extends RouteProps {}

interface RegisterFormState {
    firstName: { value: string; isError: true | undefined };
    lastName: { value: string; isError: true | undefined };
    username: { value: string; isError: true | undefined };
    email: { value: string; isError: true | undefined };
    password: { value: string; isError: true | undefined };
    confirmPassword: { value: string; isError: true | undefined };
    captchaValue: string;
    captcha: { value: string; isError: true | undefined };
    message?: Message;
    agree: { value: boolean; isError: true | undefined };
    role: { value: UserRole; isError: true | undefined };
    referrer: string;
}
const ROOT_DOMAIN = AppConstant.ROOT_DOMAIN;
const ALLCHARACTERS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
];
class RegisterForm extends React.Component<RegisterFormProps, RegisterFormState> {
    userApiClient: UserAPIClient;
    constructor(props: RegisterFormProps) {
        super(props);
        this.userApiClient = AppContext.getInstance().userApiClient;
        const email = CommonUtil.getQueryParam(this.props, 'email');
        const ref = CommonUtil.getQueryParam(this.props, 'syref');
        const role = CommonUtil.getQueryParam(this.props, "role");
        this.state = {
            firstName: { value: '', isError: undefined },
            lastName: { value: '', isError: undefined },
            username: { value: '', isError: undefined },
            email: { value: email, isError: undefined },
            password: { value: '', isError: undefined },
            confirmPassword: { value: '', isError: undefined },
            captchaValue: '',
            referrer: ref,
            role: {value: role ? UserRole[role] : UserRole.TRADER, isError: undefined},
            captcha: { value: '', isError: undefined },
            agree: { value: false, isError: undefined },
        };
    }
    componentDidMount() {
        this.generateCaptcha();
        ReactGA.event('register_form_view', {});
    }

    generateCaptcha = () => {
        let captcha = '';
        for (let i = 0; i < 6; i++) {
            //getting 6 random characters from the array
            let randomCharacter = ALLCHARACTERS[Math.floor(Math.random() * ALLCHARACTERS.length)];
            captcha += randomCharacter.toString();
        }
        captcha = captcha.split('').join(' ');
        this.setState((prevState) => ({ ...prevState, captchaValue: captcha }));
    };

    onChange = (event: any) => {
        const { name, value } = event.target;
        if (name) {
            this.setState((prevState) => {
                if ([name]['isError']) {
                    return { ...prevState, [name]: { value, isError: undefined } };
                }
                return { ...prevState, [name]: { value } };
            });
        }
    };
    handleSubmit = async () => {
        if (this.validateForm()) {
            try {
                const newUser: any = {
                    firstName: this.state.firstName.value,
                    lastName: this.state.lastName.value,
                    username: this.state.username.value,
                    password: this.state.password.value,
                    referrer: this.state.referrer,
                    email: this.state.email.value,
                    userRole: this.state.role.value,
                };
                await this.userApiClient.register(newUser);
                this.setState((prevState) => ({
                    ...prevState,
                    message: Message.info(
                        'User registration successful.Please Check your mail to activation'
                    ),
                }));

                ReactGA.event('register_success', {});

                window.location.replace(
                    `${ROOT_DOMAIN}/welcome.html?username=${this.state.username.value}&email=${this.state.email.value}&role=${this.state.role.value}`
                );
            } catch (error) {
                console.log({ error });
                this.setState((prevState) => ({ ...prevState, message: Message.fromError(error) }));
            }
        }
    };

    validateForm = () => {
        let isValid = true;
        if (this.state.firstName.value === '') {
            this.state.firstName.isError = true;
        }
        if (this.state.lastName.value === '') {
            this.state.lastName.isError = true;
        }
        if (this.state.email.value === '') {
            this.state.email.isError = true;
        }
        if (this.state.username.value === '') {
            this.state.username.isError = true;
        }
        if (this.state.password.value === '') {
            this.state.password.isError = true;
        }
        if (this.state.confirmPassword.value === '') {
            this.state.confirmPassword.isError = true;
        }
        if (this.state.captchaValue !== this.state.captcha.value.split('').join(' ')) {
            this.state.captcha.isError = true;
        }

        if (
            this.state.email.isError ||
            this.state.captcha.isError ||
            this.state.firstName.isError ||
            this.state.lastName.isError ||
            this.state.password.isError ||
            this.state.confirmPassword.isError
        ) {
            isValid = false;
            this.generateCaptcha();
            this.setState((prevState) => ({
                ...prevState,
                message: Message.error('fill all marked (*) field'),
            }));
        }
        return isValid;
    };

    clearMessage = () => {
        let _state = this.state;
        //filter key to which has only isError variable.
        const keys = Object.keys(_state).filter((item) => {
            if (item !== 'captchaValue' && item !== 'referrer') return item;
        });

        keys.map((item) => {
            if (_state.username.isError) {
                _state.username.value = ''; // reset username value, if it is username related error
            }
            _state[item].isError = undefined;
        });

        this.setState(() => ({ ..._state, message: undefined }));
    };
    checkUserValidity = async (username: string) => {
        try {
            const user = await this.userApiClient.checkUsername(username);
            if (user) {
                this.setState((prevState) => ({
                    ...prevState,
                    username: { value: username, isError: true },
                    message: Message.error('Sorry, user already exist'),
                }));
            }
        } catch (error) {
            this.setState((prevState) => ({
                ...prevState,
                username: { value: username, isError: true },
                message: Message.fromError(error),
            }));
        } finally {
        }
    };
    validateConfirmPasswordObBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (this.state.password.value === event.target.value) {
            this.clearMessage();
        } else {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    password: { value: prevState.password.value, isError: true },
                    confirmPassword: { value: prevState.confirmPassword.value, isError: true },
                    message: Message.error("Password doesn't match"),
                };
            });
        }
    };

    validateCaptchaOnblur = (event: any) => {
        if (event.target.value.split('').join(' ') !== this.state.captchaValue) {
            this.generateCaptcha();
            this.setState((prevState) => {
                return {
                    ...prevState,
                    captcha: { value: '', isError: true },
                    message: Message.error("captcha doesn't match"),
                };
            });
        } else {
            this.setState((prevState) => ({
                ...prevState,
                message: undefined,
                captcha: { value: prevState.captcha.value, isError: undefined },
            }));
        }
    };

    validateUserOnBlur = (event: any) => {
        if (event.target.value === '') {
            this.setState((prevState) => ({
                ...prevState,
                username: { value: event.target.value, isError: true },
                message: Message.error('Username field is required'),
            }));
        }

        if (/^[a-zA-Z0-9]([._](?![._])|[a-zA-Z0-9]){2,30}$/.test(event.target.value)) {
            this.checkUserValidity(event.target.value);
        } else {
            this.setState((prevState) => ({
                ...prevState,
                username: { value: event.target.value, isError: true },
                message: Message.error(
                    'Username can only contains letters, numbers, underscore and 3 to 30 characters'
                ),
            }));
        }
    };
    render() {
        const mode = localStorage.getItem('theme');
        const logo = mode === 'dark' ? desktopWhite : desktopDark;
        const {
            firstName,
            lastName,
            email,
            message,
            password,
            confirmPassword,
            captcha,
            username,
            agree,
            captchaValue,
        } = this.state;
        const submitDisabled = Boolean(
            !agree.value ||
                captcha.isError ||
                firstName?.isError ||
                lastName?.isError ||
                email?.isError ||
                username.isError ||
                password?.isError ||
                confirmPassword?.isError ||
                captcha?.isError
        );
        return (
            <DocumentHeader title={'User Registration'}>
                <Stack justifyContent='center' minHeight='100%' alignItems={'center'}>
                    <Card elevation={5}>
                        <Stack
                            flexDirection='column'
                            alignItems='center'
                            gap={1}
                            maxWidth={520}
                            padding={[6, 4.8]}
                            justifyContent='center'
                        >
                            <A
                                href={`https://www.investfly.com/${this.props.location.search}`}
                                newTab
                            >
                                <Box component='img' mb={1} src={logo} alt='investfly logo' />
                            </A>

                            <Box component='form'>
                                {message && (
                                    <InflyMessage
                                        message={message}
                                        onClose={() => this.clearMessage()}
                                    />
                                )}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            error={firstName.isError}
                                            autoComplete='given-name'
                                            name='firstName'
                                            required
                                            value={firstName.value}
                                            onChange={this.onChange}
                                            size={'medium'}
                                            fullWidth
                                            id='firstName'
                                            label='First Name'
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            error={lastName.isError}
                                            fullWidth
                                            onChange={this.onChange}
                                            id='lastName'
                                            value={lastName.value}
                                            size={'medium'}
                                            label='Last Name'
                                            name='lastName'
                                            autoComplete='family-name'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            size={'medium'}
                                            error={username.isError}
                                            onChange={this.onChange}
                                            id='username'
                                            value={username.value}
                                            label='username'
                                            name='username'
                                            onBlur={this.validateUserOnBlur}
                                            autoComplete='username'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            error={email.isError}
                                            fullWidth
                                            size={'medium'}
                                            onChange={this.onChange}
                                            value={email.value}
                                            id='email'
                                            label='Email Address'
                                            name='email'
                                            autoComplete='email'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={password.isError}
                                            name='password'
                                            size={'medium'}
                                            onChange={this.onChange}
                                            label='Password'
                                            value={password.value}
                                            type='password'
                                            id='password'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={confirmPassword.isError}
                                            name='confirmPassword'
                                            size={'medium'}
                                            onChange={this.onChange}
                                            value={confirmPassword.value}
                                            label='Confirm Password'
                                            type='password'
                                            onBlur={this.validateConfirmPasswordObBlur}
                                            id='confirmPassword'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectField 
                                        name="role"
                                        label="Role" 
                                        fullWidth 
                                        options={selectOptionsFromEnum(UserRole)} 
                                        value={this.state.role.value}
                                        onChange={this.onChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            onChange={this.onChange}
                                            name={'agree'}
                                            id={'agree'}
                                            control={
                                                <Checkbox value={agree.value} color='primary' />
                                            }
                                            label={
                                                <>
                                                    I agree to{' '}
                                                    <A
                                                        href={`${ROOT_DOMAIN}/terms-of-service${this.props.location.search}`}
                                                        newTab={true}
                                                    >
                                                        Terms and Conditions
                                                    </A>{' '}
                                                </>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box
                                            component={'div'}
                                            height={'100%'}
                                            sx={{ userSelect: 'none' }}
                                            borderRadius={'5px'}
                                            position={'relative'}
                                        >
                                            <Box
                                                component={'img'}
                                                width={'100%'}
                                                height={'55px'}
                                                sx={{ objectFit: 'cover', opacity: '0.95' }}
                                                borderRadius={'5px'}
                                                src={captchBg}
                                                alt='Captch Background'
                                            />
                                            <Typography
                                                component={'span'}
                                                variant={'h5'}
                                                sx={{
                                                    transform: 'translate(-50%, -50%)',
                                                }}
                                                position={'absolute'}
                                                left={'50%'}
                                                color={'blue'}
                                                width={'max-content'}
                                                top={'50%'}
                                                letterSpacing={{ md: 15, sm: 10, xs: 3 }}
                                                fontFamily={'Noto Serif, serif'}
                                                textAlign={'center'}
                                                id='captcha'
                                            >
                                                {captchaValue}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={captcha.isError}
                                            name='captcha'
                                            size={'medium'}
                                            onChange={this.onChange}
                                            value={captcha.value}
                                            onBlur={this.validateCaptchaOnblur}
                                            label='Enter Captcha'
                                            type='text'
                                            id='captcha'
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    fullWidth
                                    size={'large'}
                                    variant='contained'
                                    onClick={(event: any) => {
                                        event.preventDefault();
                                        this.generateCaptcha();
                                    }}
                                    sx={{ my: 2 }}
                                >
                                    Reload captcha
                                </Button>
                                <ActionButton   
                                    label="Sign Up"                                  
                                    fullWidth
                                    variant='contained'
                                    size={'large'}
                                    sx={{ my: 2 }}
                                    onClick={() => {
                                        return this.handleSubmit();
                                    }}
                                    disabled={submitDisabled}>
                                    
                                </ActionButton>


                                <Grid container justifyContent='center'>
                                    <Grid item>
                                        <Typography component={'p'} align={'center'}>
                                            Already have an account?
                                            <A href={`/${this.props.location.search}`}> Sign in</A>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                        <Stack
                            gap={2}
                            justifyContent={'space-between'}
                            padding={[0, 2.8]}
                            flexDirection={'row'}
                        >
                            <A href={`${ROOT_DOMAIN}${this.props.location.search}`} newTab>
                                ©&nbsp;Investfly
                            </A>

                            <A href={`${ROOT_DOMAIN}/contact${this.props.location.search}`} newTab>
                                Contact
                            </A>

                            <A
                                href={`${ROOT_DOMAIN}/terms-of-service${this.props.location.search}`}
                                newTab
                            >
                                Privacy & terms
                            </A>
                        </Stack>
                    </Card>
                </Stack>
            </DocumentHeader>
        );
    }
}

export default withRouter(RegisterForm);
