/** @format */

import React from 'react';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InflyDialog from '../common/InflyDialog';
import withScreenSize from 'containers/HOC/WithScreenSize';
import  { SelectField, SelectOption } from '../common/SelectField';
import { DialogContent } from '@mui/material';
import { ScreenSizeProp } from 'util/commonProps';
import { ITextField } from 'containers/common/ITextField';

interface Props extends ScreenSizeProp {
    value?: number; // needed to load pre-existing values
    unit?: string;
    onClose: (value?: number, unit?: string) => void;
}

interface State {
    error?: string;
    value: number;
    unit?: string;
}

class NumberDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const value = props.value !== undefined ? props.value : 0;

        this.state = { error: undefined, value: value, unit: props.unit };
    }

    onCancel = () => {
        this.props.onClose();
    };

    onSubmit = () => {
        if (this.state.value === undefined) {
            this.setState({ error: 'Value is required' });
        } else {
            this.props.onClose(this.state.value, this.state.unit);
            this.setState({ error: undefined });
        }
    };

    onUnitChange = (selectedUnit: SelectOption) => {
        this.setState({unit: selectedUnit.value})
    };

    onNumberChange = (val: number) => {
        this.setState({value: val});
    }

    render() {
        const isMobile = this.props.isMobile;
        const isDesktop = !isMobile;

        let unitOptions = [
            { label: 'None', value: '' },
            { label: 'K', value: 'K' },
            { label: 'M', value: 'M' },
            { label: 'B', value: 'B' },
        ];
        return (
            <>
                <InflyDialog
                    id={`number input dialog`}
                    open={true}
                    onClose={this.onCancel}
                    title={'Specify Numeric Value and Unit'}
                    okText={'Ok'}
                    onExecute={this.onSubmit}>
                        
                    <DialogContent>
                        <Grid container spacing={3} direction='row'>
                            {this.state.error && (
                                <Grid item>
                                    <Alert
                                        onClose={() => {
                                            this.setState({ error: undefined });
                                        }}
                                        severity='error'
                                    >
                                        {this.state.error}
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item key='value' md={8}>
                                <ITextField
                                    required
                                    name='value'
                                    id='value'
                                    key='value'
                                    type={'number'}
                                    value={this.state.value}
                                    onChange={this.onNumberChange}
                                />
                            </Grid>
                            <Grid item key='unit' md={2}>
                                <SelectField
                                    id='unit'
                                    name='unit'
                                    value={this.state.unit ?? ''}
                                    onSelectChange={this.onUnitChange}
                                    options={unitOptions ?? []}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </InflyDialog>
            </>
        );
    }
}

export default withScreenSize(NumberDialog);
