/** @format */

import React, { Component } from 'react';
import {
    Box,
    Button,
    ButtonProps,
    Checkbox,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Input,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {
    ClassAPIClient,
    Game,
    GameAPIClient,
    GameStatus,
    Message,
    MessageType,
    RepeatIntervalUnit,
    Validator,
} from 'api';
import CommonUtil, { CreateUpdateMode } from 'util/CommonUtil';
import {SelectField, SelectOption, selectOptionsFromEnum} from 'containers/common/SelectField';
import { InflyMessage } from 'containers/common/InflyMessage';
import InflyDialog from 'containers/common/InflyDialog';
import { AppContext } from 'util/appContext';
import SessionUtil from 'util/SessionUtil';
import { ITextField } from 'containers/common/ITextField';

interface CreateGameProps extends ButtonProps {
    game?: Game;
    label?: string;
    className?: string;
    onCreateGameReturn: (game?: Game) => void;
}

export interface GameFormState {
    game: Game;
    message?: Message;
    open: boolean;
}

export default class AddEditGameBtn extends Component<CreateGameProps, GameFormState> {
    gameApiClient: GameAPIClient;
    classApiClient: ClassAPIClient;

    constructor(props: CreateGameProps) {
        super(props);

        this.gameApiClient = AppContext.getInstance().gameApiClient;
        this.classApiClient = AppContext.getInstance().classApiClient;

        let game = this._getInitialGameState();

        this.state = {
            open: false,
            game: game,
        };
    }

    _getInitialGameState(): Game {
        let game = this.props.game;
        if (game === undefined) {
            game = new Game();
            game.creator = SessionUtil.findUserInfo()?.username!;
        } else {
            game = game.clone();
        }
        return game;
    }

    onGameBasicDetailsChange(name: string, value: any) {
        let game = this.state.game;
        if (name === 'isRepeatable') {
            if (game.isRepeatable) {
                game.repeatIntervalUnit = RepeatIntervalUnit.DAYS;
            } else {
                game.repeatIntervalUnit = undefined;
                game.repeatInterval = undefined;
            }
        }
        if (name === 'repeatInterval') {
            value = CommonUtil.strToPositiveInteger(value);
        }
        game[name] = value;
        this.setState({ game });
    }

    onGameRulesChange(name: string, value: any) {
        try {
            let game = this.state.game;
            if (
                name === 'gameCash' ||
                name === 'minTrade' ||
                name === 'maxTrade' ||
                name === 'minPrice' ||
                name === 'maxPrice'
            ) {
                value = CommonUtil.strToPositiveInteger(value);
            }
            game.rules[name] = value;
            this.setState({ game });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    }

    onSubmit = async (event: any) => {
        try {
            this.validateGame();

            if (this.props.game) {
                // update
                let game = await this.gameApiClient.updateGame(
                    this.state.game,
                    this.state.game.gameId
                );
                this.props.onCreateGameReturn(game);
            } else {
                // create

                let game: Game;
                if(this.props.className !== undefined){
                    game = await this.classApiClient.addGame(this.props.className, this.state.game);
                }else{
                    game = await this.gameApiClient.createGame(this.state.game);
                }
                
                this.props.onCreateGameReturn(game);
            }

            let game = this._getInitialGameState();

            this.setState({ open: false, game, message: undefined });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    };

    onCancel = () => {
        let game = this._getInitialGameState();

        this.setState({ open: false, game, message: undefined });
        this.props.onCreateGameReturn(undefined);
    };

    validateGame() {
        let game = this.state.game;
        Validator.validateNotNull('Name', game.name);

        if (game.endDate && game.endDate < new Date()) {
            throw new Error('Please set the the expire date to future date');
        }
        if (game.creator.length === 0) {
            throw new Error('creator name is empty');
        }
    }

    render() {
        let game = this.state.game;

        const mode = this.props.game ? CreateUpdateMode.UPDATE : CreateUpdateMode.CREATE;
        const okText = mode == CreateUpdateMode.CREATE ? 'New Game' : 'Edit';

        const startDate = CommonUtil.getDateString(game.startDate);
        const endDate = CommonUtil.getDateString(game.endDate);
        const intervalUnitOption = [
            {
                label: 'Days',
                value: RepeatIntervalUnit.DAYS,
                disabled: false,
            },
            {
                label: 'Months',
                value: RepeatIntervalUnit.MONTHS,
                disabled: false,
            },
        ];
        const { game: propsGame, label, onCreateGameReturn, ...buttonRest } = this.props;
        return (
            <>
                <InflyDialog
                    id='update-game-properties'
                    open={this.state.open}
                    isScreenSizeDependent={true}
                    title={mode === CreateUpdateMode.CREATE ? 'Create New Game' : 'Update Game'}
                    onClose={this.onCancel}
                    tooltip={
                        game && game.status === GameStatus.STARTED
                            ? 'cannot edit this game because it is running. '
                            : okText
                    }
                    disabled={game && game.status === GameStatus.STARTED}
                    okText={okText}
                    onExecute={this.onSubmit}
                >
                    <DialogContent>
                        <Stack gap={2}>
                            <InflyMessage
                                message={this.state.message}
                                onClose={() => this.setState({ message: undefined })}
                            />

                            <ITextField
                                label='Name'
                                onChange={(value?: string) =>
                                    this.onGameBasicDetailsChange('name', value)
                                }
                                value={game.name}
                            />

                            <ITextField
                                type={'number'}
                                label='Starting Game Points'
                                onChange={(value?: number) =>
                                    this.onGameRulesChange('gameCash', value)
                                }
                                value={game.rules.gameCash}
                            />

                            <Divider />

                            <FormControl>
                                <FormLabel>Game Type</FormLabel>
                                <RadioGroup
                                    name='isRepeatable'
                                    row
                                    aria-label='radio-btn-game-type'
                                    value={game.isRepeatable.toString()}
                                    onChange={(event: any) =>
                                        this.onGameBasicDetailsChange(
                                            event.target.name,
                                            event.target.value === 'true'
                                        )
                                    }
                                >
                                    <FormControlLabel
                                        value={'false'}
                                        control={<Radio id='isRepeatable' />}
                                        label='One Time Game'
                                        labelPlacement='end'
                                    />
                                    <FormControlLabel
                                        value={'true'}
                                        control={<Radio id='isRepeatable' />}
                                        label='Repeatable Game'
                                        labelPlacement='end'
                                    />
                                </RadioGroup>
                            </FormControl>

                            {game.isRepeatable && (
                                <Box display='flex' justifyContent={'space-between'}>
                                    <ITextField
                                        label='Repeat Interval'
                                        onChange={(value?: number) =>
                                            this.onGameBasicDetailsChange('repeatInterval', value)
                                        }
                                        value={game.repeatInterval}
                                    />

                                    <SelectField
                                        options={selectOptionsFromEnum(RepeatIntervalUnit)}
                                        value={game.repeatIntervalUnit}
                                        onSelectChange={(selectOption: SelectOption) => {
                                            let curGame = this.state.game;
                                            curGame.repeatIntervalUnit = selectOption.value;
                                            this.setState({game: curGame});
                                        }}
                                        name={'repeatIntervalUnit'}
                                    />
                                </Box>
                            )}

                            <Box display={'flex'} justifyContent='space-between'>
                                <FormControl>
                                    <FormLabel>Start Date</FormLabel>
                                    <Input
                                        type={'date'}
                                        value={startDate.length > 0 ? startDate : ''}
                                        onChange={(event: any) => {
                                            this.onGameBasicDetailsChange(
                                                'startDate',
                                                new Date(event.target.value)
                                            );
                                        }}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>End Date</FormLabel>
                                    <Input
                                        type={'date'}
                                        value={endDate.length ? endDate : ''}
                                        onChange={(event: any) =>
                                            this.onGameBasicDetailsChange(
                                                'endDate',
                                                new Date(event.target.value)
                                            )
                                        }
                                    />
                                </FormControl>
                            </Box>

                            <Box>
                                <Typography variant='h6'>Rules</Typography>
                                <Divider />
                            </Box>

                            <Box display={'flex'} justifyContent='space-between' gap={1}>
                                <ITextField
                                    type={'number'}
                                    label='Min Trades'
                                    onChange={(value?: number) =>
                                        this.onGameRulesChange('minTrade', value)
                                    }
                                    value={game.rules.minTrade}
                                />

                                <ITextField
                                    type={'number'}
                                    label='Max Trades'
                                    onChange={(value?: number) =>
                                        this.onGameRulesChange('maxTrade', value)
                                    }
                                    value={game.rules.maxTrade}
                                />
                            </Box>

                            <Box display={'flex'} justifyContent='space-between' gap={1}>
                                <ITextField
                                    type={'number'}
                                    label='Min Security Price'
                                    value={game.rules.minPrice}
                                    onChange={(value?: number) =>
                                        this.onGameRulesChange('minPrice', value)
                                    }
                                />
                                <ITextField
                                    type={'number'}
                                    label='Max Security Price'
                                    value={game.rules.maxPrice}
                                    onChange={(value?: number) =>
                                        this.onGameRulesChange('maxPrice', value)
                                    }
                                />
                            </Box>

                            <Box display={'flex'} justifyContent='space-between' gap={1}>
                                <ITextField
                                    label='Min  MarketCap'
                                    onChange={(value?: string) =>
                                        this.onGameRulesChange('minMarketCap', value)
                                    }
                                    value={game.rules.minMarketCap}
                                />

                                <ITextField
                                    label='Max MarketCap'
                                    onChange={(value?: string) =>
                                        this.onGameRulesChange('maxMarketCap', value)
                                    }
                                    value={game.rules.maxMarketCap}
                                />
                            </Box>

                            <Divider />

                            <Box display={'flex'} justifyContent='space-between' gap={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={'isShortSellAllowed'}
                                            checked={game.rules.isShortSellAllowed}
                                            onChange={(event: any) =>
                                                this.onGameRulesChange(
                                                    event.target.name,
                                                    event.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label='Allow Short Sell'
                                    labelPlacement='end'
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={'isAutomationAllowed'}
                                            checked={game.rules.isAutomationAllowed}
                                            onChange={(event: any) =>
                                                this.onGameRulesChange(
                                                    event.target.name,
                                                    event.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label='Allow Automated Trade'
                                    labelPlacement='end'
                                />
                            </Box>

                            <Box display={'flex'} justifyContent='space-between' gap={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={'isOptionTradeAllowed'}
                                            checked={game.rules.isOptionTradeAllowed}
                                            onChange={(event: any) =>
                                                this.onGameRulesChange(
                                                    event.target.name,
                                                    event.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label='Allow Option Trade'
                                    labelPlacement='end'
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={'isFutureTradeAllowed'}
                                            checked={game.rules.isFutureTradeAllowed}
                                            onChange={(event: any) =>
                                                this.onGameRulesChange(
                                                    event.target.name,
                                                    event.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label='Allow Future Trade'
                                    labelPlacement='end'
                                />
                            </Box>
                        </Stack>
                    </DialogContent>
                </InflyDialog>

                <Button
                    {...buttonRest}
                    id='createGameButton'
                    onClick={(event: any) => {
                        event.preventDefault();
                        this.setState({ open: true });
                    }}
                    size={'small'}
                    color={'primary'}
                >
                    {label}
                </Button>
            </>
        );
    }
}
