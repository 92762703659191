/** @format */

import { Box, Container, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import DesktopFooter from './DesktopFooter';
import { ChildrenProp, RouterAndThemeProps } from 'util/commonProps';
import DesktopAppBar from './TopAppBar';
import DesktopSideNav from './DesktopSideNav';
import MobileButtomNavBar from './MobileBottomNav';

interface NavBarProps extends RouterAndThemeProps, ChildrenProp {}

export default function Navbar(props: NavBarProps) {
    const { mode, isMobile } = props;

    return (
        <Stack   flexDirection={{ xs: 'column',  md: 'row',  }}  >
            
            <DesktopAppBar mode={mode} navigate={props.navigate} isMobile={props.isMobile} />

            {!isMobile && <DesktopSideNav />}

            <Box component={'main'} flexGrow={1} p={{ xs: 0, md: 2 }} >
                <Toolbar />

                {/* Actual Page */}
                <Container maxWidth={false} sx={{ p: { xs: 0, md: 2 } }}>
                    {props.children}
                </Container>

                <Box height={50} />

                {!isMobile && <DesktopFooter />}

                <Box
                    height={72}
                    display={{
                        xs: 'block',
                        md: 'none',
                    }}
                />

                {isMobile && <MobileButtomNavBar navigate={props.navigate} mode={mode} />}
            </Box>
        </Stack>
    );
}
