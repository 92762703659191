/** @format */

import React, { Component } from 'react';
import { RouteProps, RouterAndThemeProps } from 'util/commonProps';
import withRouter from 'containers/HOC/WithRouter';
import { AppContext } from 'util/appContext';
import { Message, User, UserAPIClient, UserSession } from 'api';
import SessionUtil from 'util/SessionUtil';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Stack,
    Typography,
} from '@mui/material';
import CommonUtil from 'util/CommonUtil';
import { DoneAll, ReportGmailerrorred } from '@mui/icons-material';
import DocumentHeader from 'containers/common/DocumentHeader';

interface ConfirmPasswordChangeProps extends RouteProps {}

class ConfirmPasswordChange extends Component<ConfirmPasswordChangeProps, { message?: Message }> {
    userAPIClient: UserAPIClient;
    constructor(props: ConfirmPasswordChangeProps) {
        super(props);
        this.userAPIClient = AppContext.getInstance().userApiClient;
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const email = params.get('email');
        const resetCode = params.get('resetcode');
        try {
            const session: UserSession = await this.userAPIClient.confirmPasswordChange(
                email as string,
                resetCode as string
            );
            SessionUtil.saveSession(session);

            const user: User = await this.userAPIClient.getUserProfile(session.username);
            SessionUtil.saveUserInfo(user);

            this.props.navigate(SessionUtil.getAfterConfirmChangePassword(this.props));
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
            console.log(error);
        }
    }

    render() {
        const message = this.state?.message;
        return (
            <DocumentHeader title={'Confirm Password Change'}>
                {message && (
                    <Box p={'100px'}>
                        <Card>
                            <CardHeader title={'Change password'} />
                            <CardContent>
                                <Stack
                                    height='200px'
                                    flexDirection='column'
                                    alignItems='center'
                                    justifyContent='space-between'
                                >
                                    {!message.isError() && (
                                        <DoneAll color={'primary'} fontSize={'large'} />
                                    )}
                                    {message.isError() && (
                                        <ReportGmailerrorred color={'error'} fontSize={'large'} />
                                    )}
                                    <Typography
                                        variant={'h6'}
                                        color={CommonUtil.getTypographyColor(message)}
                                    >
                                        {message.message ??
                                            'Unexpected error occurred. Please contact us'}
                                    </Typography>
                                    <CardActions>
                                        <Button
                                            size='small'
                                            onClick={(event: any) => {
                                                event.preventDefault();
                                                this.props.navigate('/');
                                            }}
                                        >
                                            Go Home
                                        </Button>
                                    </CardActions>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Box>
                )}
            </DocumentHeader>
        );
    }
}

export default withRouter(ConfirmPasswordChange);
