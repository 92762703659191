import {User, UserRole, UserSession, UserType} from 'api';
import {RouteProps as RP} from './commonProps';
import CommonUtil from './CommonUtil';

export default class SessionUtil {

    public static saveInSessionStorage(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }
    public static isStorageExist(key: string): boolean {
        return Boolean(sessionStorage.getItem(key))
    }
    public static getFromSessionStorage(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    public static removeFromSessionStorage(key: string) {
        sessionStorage.removeItem(key);
    }

    public static saveInLocalStorage(key: string, value: string){
        localStorage.setItem(key, value);
    }

    public static getFromLocalStorage(key: string){
        return localStorage.getItem(key);
    }

    public static removeFromLocalStorage(key: string){
        localStorage.removeItem(key);
    }

    public static saveSession(session: UserSession) {
        localStorage.setItem('userSession', JSON.stringify(session));
    }

    public static saveUserInfo(user: User) {
        localStorage.setItem('userInfo', JSON.stringify(user));
    }

    public static getApplicationTheme(): string | null {
        return localStorage.getItem('theme');
    }

    public static clearSession() {
        localStorage.clear();
        sessionStorage.clear();
    }


    public static findSession(): UserSession | null {
        const obj = localStorage.getItem('userSession');
        if (obj === null || obj === undefined) return null;
        const userSession = new UserSession();
        if (obj) userSession.parseJSON(JSON.parse(obj));
        return userSession;
    }

    public static findUserInfo(): User | null {
        const obj = localStorage.getItem("userInfo");
        if (obj === null || obj === undefined) return null;
        const user = new User();
        if (obj) user.parseJSON(JSON.parse(obj));
        return user;
    }

    public static isUserLoggedIn(): boolean {
        const userSession = SessionUtil.findSession();
        const userInfo = SessionUtil.findUserInfo();
        return userSession != null && userInfo != null;
    }

    public static getAfterConfirmChangePassword(props?: RP) {
        if (props) {
            const toPageUrl = CommonUtil.getQueryParam(props, "toPage");

            if (toPageUrl)
                return toPageUrl;
        }
        return '/changepassword?resetcodestatus=success';

    }


    public static getAfterLoginPage(props ?: RP) {

        if (props) {
            const toPageUrl = CommonUtil.getQueryParam(props, "toPage");

            if (toPageUrl)
                return toPageUrl;
        }
        const userInfo: User = SessionUtil.findUserInfo()!;

        if (userInfo.userRole === UserRole.INSTRUCTOR) {
            return "/professor/dashboard";
        } else {
            //if (userInfo.userType === UserType.PREMIUM || userInfo.userType === UserType.ELITE) {
                return '/dashboard';
            //} else { // STANDARD
             //   return "/upgrade/pricing";
            //}
        }
    }
}