/** @format */

import React, { Component, useEffect, useState } from 'react';
import { Breadcrumbs, Button, Card, CardContent, CardHeader, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import CommonUtil from 'util/CommonUtil';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import {
    BacktestAPIClient,
    Message,
    StrategyAPIClient,
} from 'api';
import { AppContext } from 'util/appContext';
import { TradingStrategyModel } from 'api/models/strategy/TradingStrategyModel';
import { StandardStrategyConfigComp } from './StandardStrategyComp';
import { useParams } from 'react-router-dom';
import { StandardStrategyConfig } from 'api/models/strategy/StandardStrategyConfig';
import { ITextField } from 'containers/common/ITextField';
import { RadioField, SelectOption } from 'containers/common/SelectField';
import { InflyMessage } from 'containers/common/InflyMessage';
import Backtest from './Backtest';
import { StrategyDeploymentCard } from './StrategyDeploymentCard';
import { CustomCodeStrategyComp } from './CustomCodeStrategyComp';
import A from 'containers/common/Anchor';
import { ActionButton } from 'containers/common/ActionButton';
import ReactGA from 'react-ga4';
import InflyTabs, { InflyTab } from 'containers/common/InflyTabs';



export function StrategyDetailPage(): React.JSX.Element {

    let { strategyId } = useParams();
    const [strategyModel, setStrategyModel] = useState<TradingStrategyModel>();
    const [message, setMessage] = useState<Message>();

    const[selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {

        ReactGA.event('strategy_page_view', {});

        async function loadStrategy(strategyId: number) {
            let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
            let strategyModel = await strategyApiClient.getStrategy(strategyId);
            setStrategyModel(strategyModel);
        }
        if (strategyId) {
            loadStrategy(CommonUtil.strToInteger(strategyId)!)
        } else {
            setMessage(Message.error("Invalid URL - The url must be /strategy/StrategyId"))
        }


    }, [strategyId])

    if (strategyModel === undefined) {
        return <></>
    }


    function onStandardConfigChange(standardConfig: StandardStrategyConfig, runBacktest: boolean) {
        strategyModel!.strategyConfig = standardConfig;
        setStrategyModel(strategyModel!.clone())
        setMessage(Message.warning("You have unsaved changes. Please click Save on Strategy Settings"));
        if (runBacktest){
            startBacktest()
            setSelectedTab(1);
        }
    }

    function onBasicInfoChange(newStrategyModel: TradingStrategyModel) {
        setStrategyModel(newStrategyModel.clone());
    }

    function onDeployUndeployComplete(newStrategyModel: TradingStrategyModel) {
        if (newStrategyModel.portfolioId) {
            setMessage(Message.success("Succesfully deployed strategy"))
        } else {
            setMessage(Message.success("Succesfully undeployed strategy"))
        }
        setStrategyModel(newStrategyModel!.clone());
    }

    function onPythonCodeChange(pythonCode: string, runBacktest: boolean) {
        strategyModel!.pythonCode = pythonCode;
        setStrategyModel(strategyModel!.clone());
        setMessage(Message.warning("You have unsaved code changes. Please click Save to save strategy code"));
        if (runBacktest){
            startBacktest()
            setSelectedTab(1);
        }
    }

    async function startBacktest(){
        try{
            let backtestApiClient: BacktestAPIClient = AppContext.getInstance().backestApiClient;
            let message = await backtestApiClient.start(strategyModel!.strategyId);
            setMessage(message);
        }catch(ex){
            setMessage(Message.fromError(ex));
        }
    }

    function onTabSelect(tab: number){
        setSelectedTab(tab);
    }

    return (<>

        <Stack>

            <Breadcrumbs>
                <A href='/strategy/list'> Strategy List  </A>
                <Typography>  {strategyModel.strategyId}  </Typography>
            </Breadcrumbs>


            <InflyMessage message={message} onClose={() => setMessage(undefined)} />


            <InflyTabs onTabSelect={onTabSelect} value={selectedTab}>

                <InflyTab label='Settings' value={0}>

                    <BasicInfoCard strategyModel={strategyModel} onChange={onBasicInfoChange} onMessage={message => setMessage(message)} />

                    {strategyModel.strategyConfig &&
                        <StandardStrategyConfigComp strategyId={strategyModel.strategyId} strategyConfig={strategyModel.strategyConfig!} onChange={onStandardConfigChange} onMessage={message => setMessage(message)} />
                    }

                    {!strategyModel.strategyConfig &&
                        <CustomCodeStrategyComp strategyId={strategyModel.strategyId} pythonCode={strategyModel.pythonCode!} onCodeChange={onPythonCodeChange} onMessage={message => setMessage(message)} />
                    }

                </InflyTab>


                <InflyTab label='Backtest' value={1}>
                    <Backtest strategyModel={strategyModel} onMessage={message => setMessage(message)} />
                </InflyTab>

                <InflyTab label='Deployment' value={2}>
                    <StrategyDeploymentCard strategyModel={strategyModel} onDeployUndeployComplete={onDeployUndeployComplete} />
                </InflyTab>

            </InflyTabs>


        </Stack>
    </>);
}

// ------------------------------------------------BasicInfoCard-------------------------------------------------------------------------------


type BasicInfoCompProps = {
    onMessage: (message: Message) => void;
    strategyModel: TradingStrategyModel;
    onChange: (strategyModel: TradingStrategyModel) => void;
}

export function BasicInfoCard({ strategyModel, onChange, onMessage }: BasicInfoCompProps): React.JSX.Element {

    function onNameChange(name: string) {
        strategyModel.strategyName = name;
        onChange(strategyModel);
    }

    function onDescChange(desc: string) {
        strategyModel.strategyDesc = desc;
        onChange(strategyModel);
    }

    function onVsibilityChange(selectOption: SelectOption) {
        strategyModel.isPublic = selectOption.value;
        onChange(strategyModel);
    }

    async function onBasicInfoChange() {
        try {
            let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
            let newModel: TradingStrategyModel = await strategyApiClient.updateNameDescriptionPublic(strategyModel.strategyId, strategyModel);
            onChange(newModel);
            onMessage(Message.success("Overview Changes Saved"));
        } catch (error) {
            onMessage(Message.fromError(error));
        }

    }

    return <Card>
        <CardHeader title="Overview" action={<ActionButton label="Save" onClick={onBasicInfoChange}></ActionButton>} />
        <CardContent>
            <Stack spacing={2}>

                <ITextField label='Name' value={strategyModel.strategyName} onChange={onNameChange} />
                <ITextField label="Description" fullWidth multiline size={'small'} value={strategyModel.strategyDesc} onChange={onDescChange} />

                <RadioField label="Visibility" options={[{ label: "PRIVATE", value: false }, { label: "PUBLIC", value: true }]} value={strategyModel.isPublic} onSelectChange={onVsibilityChange} />
            </Stack>


        </CardContent>
    </Card>
}


export default withNavBarAndAuth(StrategyDetailPage);