/** @format */

import React, { Component, useEffect, useState } from 'react';

import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import { InflyMessage } from 'containers/common/InflyMessage';
import {
    BrokerAccount,
    Message,
    OPERATION,
    Portfolio,
    Visibility,
} from 'api';
import { AppContext } from 'util/appContext';
import { LoadingCard } from 'containers/common/LoadingCard';
import DocumentHeader from 'containers/common/DocumentHeader';
import UpgradeCheckButton from 'containers/common/UpgradeCheckButton';
import { CreateEditPortfolioBtn } from 'containers/Portfolio/CreateEditPortfolio';
import DialogButton from 'containers/common/DialogButton';
import { InflyIcon } from '../common/InflyIcon';

import { ListItemDisplayCard } from 'containers/common/ListItemDisplayCard';
import { TradierConnectButton } from 'containers/Broker/ConnectBrokerBtn';
import { useNavigate } from 'react-router-dom';
import { ErrorCard } from 'containers/common/ErrorCard';
import A from 'containers/common/Anchor';
import ReactGA from 'react-ga4';


export function PortfolioListPage(): React.JSX.Element {

    const [portfolioList, setPortfolioList] = useState<Portfolio[]>();
    const [brokerList, setBrokerList] = useState<BrokerAccount[]>();
    const [message, setMessage] = useState<Message>();

    async function loadPortfolios() {
        let portfolioAPIClient = AppContext.getInstance().portfolioApiClient;
        try {
            let pList = await portfolioAPIClient.getMyPortfolios();
            setPortfolioList(pList);
        } catch (error) {
            setMessage(Message.fromError(error));
        }
    }

    async function loadBrokerAccounts() {
        let brokerAPIClient = AppContext.getInstance().brokerApiClient;
        try {
            let bList = await brokerAPIClient.getMyConnectedBrokers();
            setBrokerList(bList);
        } catch (error) {
            setMessage(Message.fromError(error));
        }
    }

    useEffect(() => {
        ReactGA.event('portfoliolist_page_view', {});
        loadPortfolios();
        loadBrokerAccounts();
    }, [])


    if (portfolioList === undefined || brokerList == undefined) {
        if (message) {
            return <ErrorCard message={message.message} />
        } else {
            return <LoadingCard title='Strategy List' />
        }
    }

    // All code below are guaranteed to have portfolioList and brokerLIst defined


    function createPortfolioDeleteBtn(portfolio: Portfolio): React.JSX.Element {
        return <DialogButton
            dialogprompt='Are you sure you want to delete this portfolio?'
            dialogtitle='Delete Portfolio'
            color={'error'}
            startIcon={
                <InflyIcon name='delete' fontSize={'small'} />
            }
            buttonType={'iconButton'}
            onClick={() => deleletePortfolio(portfolio.portfolioId)}
        />
    }

    async function deleletePortfolio(portfolioId: string) {
        let portfolioAPIClient = AppContext.getInstance().portfolioApiClient;
        try {
            await portfolioAPIClient.deleteVirtualPortfolio(portfolioId);
            loadPortfolios();
        } catch (error) {
            setMessage(Message.fromError(error));
        }
    }

    function onPortfolioAddReturn(newPortfolio: Portfolio) {
        setPortfolioList([...portfolioList!, newPortfolio])
    }

    function createBrokerDisconnectBtn(brokerAccount: BrokerAccount): React.JSX.Element {
        return <DialogButton
            dialogprompt='Are you sure you want to disconect this account?'
            dialogtitle='Disconnect Account'
            color={'error'}
            startIcon={
                <InflyIcon name='delete' fontSize={'small'} />
            }
            buttonType={'iconButton'}
            onClick={() => disconnectBrokerAccount(brokerAccount)}
        />
    }

    async function disconnectBrokerAccount(brokerAccount: BrokerAccount) {
        let brokerAPIClient = AppContext.getInstance().brokerApiClient;
        try {
            await brokerAPIClient.disconnect(brokerAccount.brokerType, brokerAccount.accountId);
            loadBrokerAccounts()
        } catch (error) {
            setMessage(Message.fromError(error));
        }
    }




    return <DocumentHeader title='Virtual Portfolios and  Brokeres'>

        <Stack gap={2}>
            <InflyMessage message={message} onClose={() => setMessage(undefined)} />

            <Card>
                <CardHeader
                    title={<>My&nbsp;Broker Account</>}
                    // we still this button even if user has already connected in case user has multiple Tradier accounts, or financial advisor is managing different users accounts
                    // TODO - we just have to check what happsns if the same acccount is connected again.
                    action={<TradierConnectButton />}
                />
                <CardContent>
                    <Grid container spacing={2}   >
                        {brokerList.map((brokerAccount: BrokerAccount) => {
                            return (
                                <Grid item md={6} xs={12} key={brokerAccount.accountId}>

                                    <ListItemDisplayCard
                                        key={brokerAccount.accountId}
                                        title={brokerAccount.accountId}
                                        href={`/portfolio/${brokerAccount.brokerType}/${brokerAccount.accountId}`}
                                        iconName={'private'}
                                        deleteBtn={createBrokerDisconnectBtn(brokerAccount)}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>

                    {brokerList.length === 0 && (
                        <Paper elevation={5}>
                            <Stack gap={3} padding={4}>
                                <Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography variant='h6'> Tip</Typography>
                                        <InflyIcon name='tip' />
                                    </Stack>
                                    <Divider/>
                                </Stack>
  
                                <Typography variant='body2'>
                                    Connecting your broker account with Investfly enables you to submit automated conditional trade orders, trade multiple stocks directly from stock screen, and to deploy automated trading strategy.
                                </Typography>

                                <Typography variant='body2'>
                                    Investfly currently supports <A href='https://tradier.com/' newTab>Tradier Brokerage</A>. Click on 'Connect Broker' button above to connect your Tradier Account with Investfly.
                                </Typography>
                            </Stack>
                        </Paper>
                    )}


                </CardContent>
            </Card>


            <Card>
                <CardHeader
                    title={<>Virtual&nbsp;Portfolios</>}
                    action={<UpgradeCheckButton
                        op={OPERATION.CREATE_PORTFOLIO}
                        currentCount={portfolioList.length ?? 0}
                        // current no of portfolio, sreenrs etc. Passed when limit is a number, skipped when limit is a boolean
                        label={<>New&nbsp;Portfolio</>}
                    >
                        <CreateEditPortfolioBtn btnLabel='New Portfolio' onComplete={(p, m) => { onPortfolioAddReturn(p) }} />
                    </UpgradeCheckButton>}
                />

                <CardContent>
                    <Grid container spacing={2}  >
                        {portfolioList.map((portfolio: Portfolio) => {
                            return (
                                <Grid item md={6} xs={12} key={portfolio.portfolioId}>
                                    <ListItemDisplayCard
                                        key={portfolio.portfolioId}
                                        title={portfolio.name}
                                        href={`/portfolio/${portfolio.broker}/${portfolio.portfolioId}`}
                                        iconName={portfolio.visibility === Visibility.PUBLIC ? 'public' : 'private'}
                                        deleteBtn={createPortfolioDeleteBtn(portfolio)}
                                        labelValues={new Map([["Current Value", portfolio.currentValue], ["Buying Power", portfolio.buyingPower]])}
                                    />

                                </Grid>
                            );
                        })}
                    </Grid>

                    {portfolioList.length === 0 && (
                        <Paper elevation={5}>
                            <Stack gap={3} padding={4}>
                                <Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant='h6'> Tip</Typography>
                                    <InflyIcon name='tip' />
                                </Stack>
                                <Divider />

                                </Stack>

                                <Typography variant='body2'>
                                    Virtual Porfolios are paper trading accounts used to test trading strategies. You can create any number of virtual portfolios allowed by your membership level.
                                </Typography>

                                <Typography variant='body2'>
                                    Click on New Portfolio button above to create your first virtual portfolio.
                                </Typography>
                            </Stack>
                        </Paper>
                    )}

                </CardContent>
            </Card>

        </Stack>

    </DocumentHeader>

}


export default withNavBarAndAuth(PortfolioListPage);
