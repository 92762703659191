import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Stack, Paper, Button, IconButton, Alert } from '@mui/material';
import { ComparisonOperator } from 'api/models/common/ComparisonOperator';
import { FinancialField } from 'api/models/trigger/FinancialField';
import { FinancialCondition, FinancialQuery } from 'api/models/trigger/FinancialQuery';
import { SecurityList, SecurityUniverseSelector, SecurityUniverseType, StandardSymbolsList } from 'api/models/trigger/SecurityUniverseSelector';
import { ITextField } from 'containers/common/ITextField';
import { RadioField, SelectField, SelectOption, selectOptionsFromEnum } from 'containers/common/SelectField';
import React, { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import NumberDialog from './NumberDialog';
import { DataParam } from 'api/models/trigger/DataParam';
import { InvestflyUtil } from 'api';
import InflyHelperText from 'containers/common/InflyHelperText';

type SecurityUniverseSelectorCompProps = {
    securityUniverseSelector: SecurityUniverseSelector;
    onChange: (securityUniverseSelector: SecurityUniverseSelector) => void;
}

export function SecurityUniverseSelectorComp({securityUniverseSelector, onChange}: SecurityUniverseSelectorCompProps): React.JSX.Element {

    const onUniverseTypeChange = (selectOption: SelectOption) => {
        let newSelector = new SecurityUniverseSelector();
        newSelector.setUniverseType(selectOption.value);
        onChange(newSelector);
    };

    const onStandardListChange = (standardList: StandardSymbolsList) => {
        securityUniverseSelector.standardList = standardList;
        onChange(securityUniverseSelector);
    }

    const onCustomListChange = (customList: SecurityList) => {
        securityUniverseSelector.customList = customList;
        onChange(securityUniverseSelector);
    }

    const onFinancialQueryChange = (financialQuery: FinancialQuery) => {
        securityUniverseSelector.financialQuery = financialQuery;
        onChange(securityUniverseSelector);
    }

    const type = securityUniverseSelector.universeType;

    return <Stack>

            <RadioField label="Security Universe Type" 
                helperText="Select Standard List to use Investfly provided list like SP_100, Custom List to provide your own symbols or Fundamental Query to use Financial Indicators like MarketCap, P/E Ratio"
                options={selectOptionsFromEnum(SecurityUniverseType)} 
                value={type} onSelectChange={onUniverseTypeChange}/>


            
            { type == SecurityUniverseType.STANDARD_LIST && 
                <StandardSymbolsListComp standardList={securityUniverseSelector.standardList!} onChange={onStandardListChange}/>
            }

            { type == SecurityUniverseType.CUSTOM_LIST && 
                <CustomListComp customList={securityUniverseSelector.customList!} onChange={onCustomListChange} />
            }

            { type == SecurityUniverseType.FUNDAMENTAL_QUERY && 
                <FinancialQueryComp financialQuery={securityUniverseSelector.financialQuery!} onChange={onFinancialQueryChange} />
            }
        

    </Stack>
}

type StandardListCompProps = {
    standardList: StandardSymbolsList;
    onChange: (standardList: StandardSymbolsList) => void;
}
function StandardSymbolsListComp({standardList, onChange}: StandardListCompProps): React.JSX.Element {

    let options = Object.values(StandardSymbolsList)
        .filter(v => v !== StandardSymbolsList.STOCKS)
        .map(v => {
        return {label: v, value: v}
    })
    function onSelectChange(selectOption: SelectOption){
        let newList: StandardSymbolsList = selectOption.value;
        onChange(newList);
    }

    return <SelectField value={standardList} options={options} onSelectChange={onSelectChange}/>
}

type CustomListCompProps = {
    customList: SecurityList;
    onChange: (customList: SecurityList) => void;
}
function CustomListComp({customList, onChange}: CustomListCompProps): React.JSX.Element {

    const csv = customList.symbols.join(",")

    const onSymbolsCsvChange = (csv: string) => {
        let symbolsArr: string[] = csv.split(",");
        customList.symbols = symbolsArr;
        onChange(customList);
    }

    return <ITextField label="Enter symbols separated by comma" value={csv} onChange={onSymbolsCsvChange}/>
}

type FinancialQueryCompProps = {
    financialQuery: FinancialQuery;
    onChange: (financialQuery: FinancialQuery) => void;
}
function FinancialQueryComp(props:FinancialQueryCompProps ):  React.JSX.Element {

    // we use state because we don't want to bubble up incomplete/inprogess financialQuery where only left condition is specified
    const [financialQuery, setFinancialQuery] = useState(props.financialQuery);

    const [editNumberIndex, setEditNumberIndex] = useState<number|null>(null); // instead of storing boolean, store index into financialQuery.queryCondtions
    const [errors, setErrors] = React.useState<string>();

    useEffect( () => {
        setFinancialQuery(props.financialQuery);
    }, [props.financialQuery])


    const financialFieldOptions: SelectOption[] = Object.values(FinancialField).map(v => {
        return {label: v, value: v}
    })

    const valueOptions: SelectOption[] = [{label: "NUMBER", value:"NUMBER"}, ...financialFieldOptions]

    const operatorOptions = Object.values(ComparisonOperator).map(v => {
        return {label: v, value: v}
    })

    const onLeftSelectChange = (index: number, selectOption: SelectOption) => {
        financialQuery.queryConditions[index].financialField = selectOption.value;
        validateAndPublish(financialQuery);
    }

    const onRightSelectChange = (index: number, selectOption: SelectOption) => {
        if(selectOption.label === "NUMBER"){
            setEditNumberIndex(index);
        }else{
            financialQuery.queryConditions[index].value = selectOption.value;
            validateAndPublish(financialQuery);
        }
    }


    function onNumberDialogReturn(value?: number, unit?: string){
        if(value !== undefined){
            financialQuery.queryConditions[editNumberIndex!].value = DataParam.createStringValue(value, unit);
            validateAndPublish(financialQuery);
        }

        setEditNumberIndex(null);
    }

    const onOperatorChange =  (index: number, selectOption: SelectOption) => {
        financialQuery.queryConditions[index].operator = selectOption.value;
        validateAndPublish(financialQuery);
    }

    const onAddNewFinancialConidtion = (event: any) => {
        financialQuery.queryConditions.push(new FinancialCondition());
        validateAndPublish(financialQuery);
    }

    const onDeleteFilterCondition = (index: number) => {
        financialQuery.queryConditions.splice(index, 1);
        validateAndPublish(financialQuery);
    }

    function validateAndPublish(financialQuery: FinancialQuery){
        let errors = financialQuery.validate();
        if(errors == null){
            props.onChange(financialQuery);
        }else{
            setFinancialQuery(financialQuery.clone());
        }
        setErrors(errors);

    }

    function createValueOptions(value: string | FinancialField): SelectOption[]{
        
        let result: SelectOption[] = [...valueOptions]
        // we must append the currently selected number in the options so it can be selected
        if(!InvestflyUtil.isStringEnum(FinancialField, value)){
           result.push({label: value, value: value}) 
        }
        return result;

    }

    return  <Stack spacing={1}>

            {errors != null && <Alert severity='error'>{errors}</Alert>}

            <InflyHelperText  info={"Select one of the Financial Metric or select 'Number' to specify a numeric value"} />

            {editNumberIndex !== null &&   <NumberDialog  onClose={onNumberDialogReturn}/>   }
        

            {financialQuery.queryConditions.map((qc, index) => {
                return <Stack key={index} direction={"row"} spacing={1}>
                    <SelectField options={financialFieldOptions}
                        value={qc.financialField} 
                        onSelectChange={(selectOption: SelectOption) => onLeftSelectChange(index, selectOption)} />
                    <SelectField options={operatorOptions} 
                        value={qc.operator} 
                        onSelectChange={(selectOption: SelectOption) => onOperatorChange(index, selectOption)} />
                    <SelectField options={createValueOptions(qc.value)} 
                        value={qc.value} 
                        onSelectChange={(selectOption: SelectOption) => onRightSelectChange(index, selectOption)} />
                    <IconButton onClick={event => onDeleteFilterCondition(index)}>
                            <ClearIcon fontSize='small' />
                    </IconButton>
                </Stack>
            })}

        <Button onClick={onAddNewFinancialConidtion}  variant={'text'} sx={{ alignSelf: 'start' }}>
            + ADD CONDITION
        </Button>   

        </Stack>
   
}




