/** @format */

import A from 'containers/common/Anchor';
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import SessionUtil from './util/SessionUtil';

export default function ErrorPage() {
    const isAuthenticate = SessionUtil.isUserLoggedIn();
    return (
        <Stack
            id='error-page'
            justifyContent={'center'}
            flex={[1, 0]}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <Typography variant={'h1'} fontSize={'42px'} lineHeight={'52px'}>
                Page not found!
            </Typography>
            <p>Sorry, but the page you were looking for could not be found.</p>
            <p>
                You can{' '}
                <A href={isAuthenticate ? '/' : 'https://www.investfly.com'}>
                    return to our front page
                </A>
                , or <A href={'www.investfly.com/contact_us.html.com'}>contact us</A> if you can't
                find what you're looking for.
            </p>
        </Stack>
    );
}
