import { InvestflyUtil } from "api/utils/investflyUtil";

export enum Visibility {
    PRIVATE = "PRIVATE", 
    PUBLIC = "PUBLIC"
}

export class DatedValue {
    date: Date
    value: number

    static parseJSON(obj: {[index:string]: any}): DatedValue{
        let datedValue: DatedValue = new DatedValue()
        datedValue.date = InvestflyUtil.strToDate(obj.date)!;
        datedValue.value = obj.value;
        return datedValue;
    }
}