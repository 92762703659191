import {Message} from "../models/common/message";
import { InvestflyModel } from "../models/InvestflyModel";
import {AffiliateAccount} from "../models/user/affiliateAccount";
import {PaymentDetail} from "../models/user/paymentDetail";
import {PaymentPlan} from "../models/user/paymentPlan";
import {SearchUserCriteria} from "../models/user/searchUserCriteria";
import {User, UserType} from "../models/user/user";
import { UserMessage } from "../models/user/UserMessage";
import {UserSession} from "../models/user/userSession";
import {UserSpec} from "../models/user/userSpec";
import {InvestflyUtil} from '../utils/investflyUtil';
import {InflyClientId, InflyClientToken, RestAPIClient} from './restAPIClient';


export class UserAPIClient {

    private restApiClient: RestAPIClient;

    constructor(restApiClient: RestAPIClient) {
        this.restApiClient = restApiClient;
    }

    async register(userSpec: UserSpec): Promise<string> {
        return this.restApiClient.post("/user/register", userSpec);
    }
    
    async login(username: string, password: string, rememberme: boolean = false): Promise<UserSession> {
        const authorization = `Basic ${InvestflyUtil.encode(username + ':' + password)}`;

        let headers: Map<string, string> = new Map<string, string>();
        headers.set('Content-Type', 'application/json');
        headers.set('Authorization', authorization);

        const response = await this.restApiClient.fetch(`/user/login?remember=${rememberme}`, 'POST', null, headers);
        const userSession: UserSession = UserSession.parseJSON(response);
        this.restApiClient.setHeader(InflyClientId, userSession.clientId);
        this.restApiClient.setHeader(InflyClientToken, userSession.clientToken);

        return userSession;
    }

    async logout (): Promise<void> {
        const response = await this.restApiClient.post(`/user/logout`, null);
        this.restApiClient.removeHeader(InflyClientId);
        this.restApiClient.removeHeader(InflyClientToken);
    }

    async checkSession(): Promise<UserSession> {
        try{
            const response = await this.restApiClient.get('/user/session');
            const userSession: UserSession =  UserSession.parseJSON(response);
            return userSession;
        }catch(error) {
            this.restApiClient.removeHeader(InflyClientId);
            this.restApiClient.removeHeader(InflyClientToken);
            throw error;
        }
    }

    async activate(username: string, activationCode: string): Promise<UserSession> {
        const response = await this.restApiClient.post(`/user/activate?user=${username}&code=${activationCode}`, null);
        const userSession: UserSession = UserSession.parseJSON(response);
        this.restApiClient.setHeader(InflyClientId, userSession.clientId);
        this.restApiClient.setHeader(InflyClientToken, userSession.clientToken);
        return userSession;
    }

    async deactivate(): Promise<Message> {
        const response = await this.restApiClient.post(`/user/deactivate`);
        return Message.parseJSON(response);
    }
    async myReferred():Promise<any>{
        return this.restApiClient.get("/user/referrals");
    }
    async checkUsername(username: string): Promise<Boolean> {
        return this.restApiClient.get(`/user/check?username=${username}`);
    }
    async feedback(message:UserMessage):Promise<Message>{
        const response = this.restApiClient.post("/user/contact", message);
        return Message.parseJSON(response);
    }
    async searchByPrefix(prefix: string): Promise<string[]> {
        return this.restApiClient.get(`/user/search?prefix=${prefix}`);
    }

    async searchByCriteria(searchCriteria: SearchUserCriteria): Promise<User[]> {
        const response:object[] = await this.restApiClient.post(`/user/search`, searchCriteria);
        return InvestflyModel.parseList(response, User.parseJSON);
    }

    async getRecentUsers(): Promise<User[]> {
        const response:object[] = await this.restApiClient.get(`/user/recentusers`);
        return InvestflyModel.parseList(response, User.parseJSON);
    }

    async getNewestUsers(): Promise<User[]> {
        const response:object[] = await this.restApiClient.get(`/user/newusers`);
        return InvestflyModel.parseList(response, User.parseJSON);
    }

    async forgetPassword(email:string): Promise<Message> {
        const response = await this.restApiClient.post(`/user/resetpassword?email=${email}`,null);
        return Message.parseJSON(response);
    }

    async changePassword(newPassword:string): Promise<Message> {
        const response = await this.restApiClient.post(`/user/changepass`, newPassword);
        return Message.parseJSON(response);
    }

    async confirmPasswordChange(email: string, code: string): Promise<UserSession> {
        const response = await this.restApiClient.post(`/user/resetpasswordcode?email=${email}&resetcode=${code}`, null);

        const userSession: UserSession = UserSession.parseJSON(response);
        this.restApiClient.setHeader("investfly-client-id", userSession.clientId);
        this.restApiClient.setHeader("investfly-client-token", userSession.clientToken);

        return userSession;
    }

    async getUserProfile(username: string): Promise<User> {
        let obj:object = await this.restApiClient.get(`/user/${username}/profile`);
        return User.parseJSON(obj);
    }

    async getQualifyingPlans(couponCode: string): Promise<Map<UserType, PaymentPlan>> {
        const url = (couponCode && couponCode !== "")? "/user/paymentPlans?couponCode="+couponCode: "/user/paymentPlans";
        const response: object = await this.restApiClient.get(url);

        const resMap: Map<UserType, PaymentPlan> = new Map<UserType, PaymentPlan>();
        Object.keys(response).map( (k: string) => {
            const userType: UserType = (<any>UserType)[k];
            const value: any = (<any>response)[k];
            const paymentPlan: PaymentPlan = PaymentPlan.parseJSON(value);
            resMap.set(userType, paymentPlan);
        });
        return resMap;
    }

    //csv of emails
    async inviteUsers(emails: string[]): Promise<Map<string, string[]>> {
        const response: object = await this.restApiClient.post(`/user/invite`, emails);
        const resMap: Map<string, string[]> = new Map<string, string[]>();
        Object.assign(resMap, response);
        return resMap;
    }

    async changeEmail(newEmail: string): Promise<Message> {
        const response = await this.restApiClient.post(`/user/changeemail?newEmail=${newEmail}`,null);
        return Message.parseJSON(response);
    }

    async confirmEmailChange(username: string, email: string, activationCode: string): Promise<Message> {
        const response = await this.restApiClient.post(`/user/confirmemailchange?username=${username}&email=${email}&code=${activationCode}`, null);
        return Message.parseJSON(response);
    }

    async updateProfile(profile: any): Promise<User> {
        const response = await this.restApiClient.post(`/user/profile`, profile);
        return User.parseJSON(response);
    }

    async patchProfile(userSpec: UserSpec): Promise<User> {
        const response = await this.restApiClient.post(`/user/profile/patch`, userSpec);
        return User.parseJSON(response);
    }

    async changeRole(role: string): Promise<User> {
        const response = await this.restApiClient.post(`/user/changerole?newRole=${role}`, null);
        return User.parseJSON(response);
    }

    async changeMyPaymentInfo(token: string): Promise<Message> {
        const response = await this.restApiClient.post(`/user/changepaymentinfo?token=${token}`, null);
        return Message.parseJSON(response);
    }

    async changeMyUserType(paymentDetail: PaymentDetail): Promise<Message> {
        const response = await this.restApiClient.post(`/user/changeaccount`, paymentDetail);
        return Message.parseJSON(response);
    }

    async isEligibleForAction(featureName:string): Promise<any> {
        let url="/user/isEligibile?feature="+featureName;
        return await this.restApiClient.post(url,null);
    };

}   