import React, {Component} from 'react';
import {Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography} from '@mui/material';
import {Game, GameStatus, UserGameScore} from 'api';
import CommonUtil from 'util/CommonUtil';


interface GameStaticsProps {
    game: Game;
    userGameScore?: UserGameScore;
    isJoined?: boolean
}

interface GameStaticsState {
    days: number;
    hours: number;
    mins: number;
    secs: number;
}

export default class GameStatics extends Component<GameStaticsProps, GameStaticsState> {

    timer: any;

    constructor(props: GameStaticsProps) {
        super(props);
        this.state = this.calcRemainingTime();
    }

    get sup() {
        let sup = 'N/A'
        const rank = this.props.userGameScore?.rank as number
        if (rank === 1) {
            sup = 'st'
        } else if (rank === 2) {
            sup = 'nd'
        } else if (rank === 3) {
            sup = 'rd'
        } else if (rank > 3) {
            sup = 'th'
        }
        return sup;
    }

    get startStop() {
        return (this.props.game.status === GameStatus.STARTED || this.props.game.status === GameStatus.PAUSED) ? "Ends" : "Starts";

    }

    componentDidMount() {
        this.timer = setInterval(this.updateRemainingTime, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    updateRemainingTime = () => {
        this.setState(this.calcRemainingTime());
    }

    calcRemainingTime = (): GameStaticsState => {
        const now: Date = CommonUtil.getEstTime();
        const gameDate: Date | undefined = (this.props.game.status === GameStatus.STARTED || this.props.game.status === GameStatus.PAUSED)
            ? this.props.game.endDate! : this.props.game.startDate;

        if (gameDate === undefined || now.getTime() > gameDate.getTime()) {
            // mainly for game in STOPPED status and no new start Date set, the game is not scheduled to start, so don't show timer
            return {
                days: -1,
                hours: -1,
                mins: -1,
                secs: -1
            }
        }
        let diffMillis: number = Math.round(Math.abs(now.getTime() - gameDate.getTime()) / 1000);

        const DAYS_IN_MILLIS = 60 * 60 * 24;
        const days = Math.floor(diffMillis / (DAYS_IN_MILLIS));

        diffMillis = diffMillis - days * DAYS_IN_MILLIS;

        const hours = Math.floor(diffMillis / (60 * 60));
        diffMillis = diffMillis - hours * 60 * 60;

        const mins = Math.floor(diffMillis / 60);
        diffMillis = diffMillis - mins * 60;

        return {
            days: days,
            hours: hours,
            mins: mins,
            secs: diffMillis
        };

    }

    render() {
        const {userGameScore} = this.props;


        return <>
            <Card>
                <CardHeader
                    title={<><Typography>
                        <Typography component={'i'} className="fa fa-trophy" title="my Game"/>
                        &nbsp;Game&nbsp;Statistics
                    </Typography></>}/>

                <Divider/>
                <CardContent>
                    <Grid container direction={'row'} justifyContent="space-between" alignItems="flex-start">
                        <Grid item>
                            <Typography variant={'body1'} gutterBottom> Your Rank</Typography>
                            <Box color={'text.primary'} p={1} borderRadius="5px" bgcolor={'primary.dark'}
                                 width={1} textAlign={'center'}>
                                {userGameScore?.rank ?
                                    <Typography variant={'body1'} component={'p'}>
                                        <Typography variant={'body1'} component={'span'}
                                                    id="userGameRank">
                                            {userGameScore?.rank as number}
                                            <sup>
                                                {this.sup}
                                            </sup>
                                        </Typography>
                                    </Typography>
                                    :
                                    <Typography variant={'body1'} gutterBottom>
                                        <Typography component={'span'}>N/A</Typography>
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography variant={'body1'} gutterBottom> Your Points</Typography>
                            <Box color={'text.primary'} p={1} borderRadius="5px" bgcolor={'primary.dark'}
                                 width={1} textAlign={'center'}>
                                {userGameScore?.rank ?
                                    <Typography variant={'body1'} component={'p'}>
                                        <Typography component={'span'}
                                                    id="totalPoints">{CommonUtil.numberWithCommas(userGameScore?.totalGamePoints as number)}</Typography>
                                    </Typography>
                                    :
                                    <Typography variant={'body1'} gutterBottom>
                                        <Typography component={'span'}>N/A</Typography>
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                        {
                            this.state.days != -1 &&
                            <Grid item>
                                <Typography variant={"body1"}> {this.startStop} in</Typography>
                                <Box color={"primary.main"} fontSize={25} component={'span'}>
                                    {this.state.days} days
                                </Box>
                                <Box component={'span'} fontSize={17} display={'block'}
                                     color={'text.disabled'}>{this.state.hours}:{this.state.mins}:{this.state.secs}</Box>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
            </Card>
        </>;
    }
}
