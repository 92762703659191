import {InvestflyModel} from '../InvestflyModel';

export enum FunctionType {
    UNDERLYING_PRICE = "UNDERLYING_PRICE", 
    VOLATILITY = "VOLATILITY", 
    DIVIDEND_YIELD = "DIVIDEND_YIELD", 
    RISK_FREE_RATE = "RISK_FREE_RATE"
}

export class OptionGreeks extends InvestflyModel {
    value: number;
    delta: number;
    gamma: number;
    vega: number;
    theta: number;
    rho: number;

    static parseJSON(obj: {[index:string]: any}): OptionGreeks {
        let greeks = new OptionGreeks();
        Object.assign(greeks, obj);
        return greeks;
    }
}