import React, { useEffect, useState } from 'react';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import { Button, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import {
    Message,
    OPERATION,
    ScreenerAPIClient,
    ScreenerModel,
    ScreenerResult,
    USER_LIMITS,
    User,
} from 'api';
import { AppContext } from 'util/appContext';
import { InflyMessage } from 'containers/common/InflyMessage';
import DialogButton from 'containers/common/DialogButton';
import { useNavigate } from 'react-router-dom';
import { NewScreenerDialogButton } from './ScreenerForm';
import { ListItemDisplayCard } from 'containers/common/ListItemDisplayCard';
import { InflyIcon } from 'containers/common/InflyIcon';
import { ErrorCard } from 'containers/common/ErrorCard';
import { LoadingCard } from 'containers/common/LoadingCard';
import InflyTabs, { InflyTab } from 'containers/common/InflyTabs';
import ReactGA from 'react-ga4';
import { CreateSampleScreenerListComp, SampleScreenerList } from './ScreenerSampleList';
import SessionUtil from 'util/SessionUtil';

const TAB_MY_SCREENER = "My"
const TAB_SAMPLE = "Sample"

export function ScreeenrListPage(): React.JSX.Element {

    const navigate = useNavigate();

    const [screenerList, setScreenerList] = useState<ScreenerModel[]>(); 
    const [message, setMessage] = useState<Message>();

    // generally, we don't need to track selefcted tab state as InflyTab handles it. But here, we want to show sample when NewScreener is clicked from
    // MyScreener tab, but not when its clicked from the sample tab, thats why we track state
    const [selectedTab, setSelectedTab] = useState<string>();

    async function loadScreenerList() {
        try {
            let screenerApiClient: ScreenerAPIClient = AppContext.getInstance().screenerApiClient;
            let stList: ScreenerModel[] = await screenerApiClient.getMyScreeners();
            setScreenerList(stList);
            if(stList.length > 0){
                setSelectedTab(TAB_MY_SCREENER);
            }else{
                setSelectedTab(TAB_SAMPLE);
            }
        } catch (error) {
            setMessage(Message.fromError(error));
        }
    }

    useEffect(() => {
        ReactGA.event('screenerlist_page_view', {});
        loadScreenerList();
    }, [])

    if (screenerList === undefined) {
        if (message) {
            return <ErrorCard message={message.message} />
        } else {
            return <LoadingCard title='Screener List' />
        }
    }

    function onCreateDialogReturn(screener: ScreenerModel, result?: ScreenerResult, message?: Message) {
        setScreenerList([screener, ...screenerList!]);
        navigate(`/screener/${screener.screenerId}`)
    }

    async function onDeleteConfirm(screenerId: number): Promise<any> {
        try {
            const screenerApiClient = AppContext.getInstance().screenerApiClient;
            const message = await screenerApiClient.deleteScreener(screenerId);
            await loadScreenerList();
            setMessage(message);
        } catch (error) {
            setMessage(Message.fromError(error));
        }
    }

    function createDeleteButton(screenerModel: ScreenerModel): React.JSX.Element {
        return <DialogButton
            dialogprompt='Are you sure you want to delete this screener?'
            dialogtitle={'Delete Screener'}
            color={'error'}
            startIcon={
                <InflyIcon name='delete' fontSize={'small'} />
            }
            buttonType={'iconButton'}
            onClick={() => onDeleteConfirm(screenerModel.screenerId)}
        />
    }

    function createCloneButton(screenerModel: ScreenerModel): React.JSX.Element|undefined {
        // the upgrade check button does not work with icons, so we will return button only if the operation is allowed
        const userInfo: User = SessionUtil.findUserInfo() as User;
        const userType = userInfo.userType;
        const limit: number  = USER_LIMITS[userType][OPERATION.CREATE_SCREENER] as number;
        const isOperationAllowed =  screenerList!.length === undefined || screenerList!.length < limit;
        if (!isOperationAllowed){
            return undefined;
        }
        let cloneScreener = screenerModel.clone();
        cloneScreener.screenerId = undefined!;
        cloneScreener.name = cloneScreener.name + " Copy";
        return <NewScreenerDialogButton showSample={false} cloneScreener={cloneScreener} iconName='copy' onSubmit={onCreateDialogReturn} curScreenerCount={screenerList!.length}  />
    }

    function onTabSelect(newTabSelection: any ){
        setSelectedTab(newTabSelection);
    }


    return (<>

        <Card>
            <CardHeader title={'Screener List'}
                action={<NewScreenerDialogButton showSample={selectedTab !== TAB_SAMPLE} onSubmit={onCreateDialogReturn} curScreenerCount={screenerList.length} />}
            />

            <CardContent>
                <Stack gap={2}>
                    <InflyMessage message={message} onClose={() => setMessage(undefined)} />

                    <InflyTabs onTabSelect={onTabSelect}>
                        {screenerList.length > 0 &&
                            <InflyTab label='My Screeners' value={TAB_MY_SCREENER}>
                                <Grid container spacing={2}>
                                    {screenerList.map(screenerModel => {
                                        return <Grid key={screenerModel.screenerId} item xs={12} md={6}>
                                            <ListItemDisplayCard
                                                title={screenerModel.name}
                                                href={`/screener/${screenerModel.screenerId}`}
                                                iconName={screenerModel.isPublic ? 'public' : 'private'}
                                                description={screenerModel.description}
                                                deleteBtn={createDeleteButton(screenerModel)}
                                                cloneBtn={createCloneButton(screenerModel)}
                                            />
                                        </Grid>
                                    })}
                                </Grid>
                            </InflyTab>
                        }

                        <InflyTab label='Screener Templates' value={TAB_SAMPLE}>
                            <CreateSampleScreenerListComp screenerCount={screenerList.length} onCreateReturn={onCreateDialogReturn}/>
                        </InflyTab>
                    </InflyTabs>
                </Stack>
            </CardContent>
        </Card>
    </>);
}




export default withNavBarAndAuth(ScreeenrListPage);
