/** @format */

import { AppBar, Box, Button, IconButton, Stack, Theme, Toolbar } from '@mui/material';
import { SymbolSummaryInfo } from 'api/models/marketdata/security';
import { SymbolField } from 'containers/common/SymbolField';
import desktopDark from 'static/images/desktop-logo-dark.png';
import desktopWhite from 'static/images/desktop-logo-white.png';
import mobileDark from 'static/images/mobile-logo-dark.png';
import mobileWhite from 'static/images/mobile-logo-white.png';
import React from 'react';
import { UserRole, UserType } from 'api/models/user/user';
import styles from './AppBarStyles.module.css';
import SessionUtil from 'util/SessionUtil';
import { NavLink } from 'react-router-dom';
import { Message } from 'api/models/common/message';
import { Feedback } from 'containers/User/Feedback';
import InviteFriends from 'containers/common/InflyInviteFriends';
import { InflyMessage } from 'containers/common/InflyMessage';
import LogoutIcon from '@mui/icons-material/Logout';
import { NavigateProp, ScreenSizeProp } from 'util/commonProps';
import { Brightness2Rounded, WbIncandescentRounded } from '@mui/icons-material';
import { ColorModeContext } from 'App';
import { ThemeProp } from 'util/commonProps';
import { UserAPIClient } from 'api/services/userAPIClient';
import { AppContext } from 'util/appContext';
import ReactGA from 'react-ga4';

interface TopAppBarProps extends ThemeProp, NavigateProp, ScreenSizeProp {}

interface TopAppBarState {
    message?: Message;
}

function ToogleThemeButton(props: ThemeProp) {
    // Extracted into its own Functional Component to use React ColortModeContext using Hooks
    let colorModeContext = React.useContext(ColorModeContext);
    return (
        <IconButton
            color='inherit'
            aria-controls='dar-light-mode'
            aria-haspopup='true'
            onClick={(event: any) => {
                event.preventDefault();
                colorModeContext.toggleColorMode();
            }}
            size='small'
        >
            {props.mode === 'dark' ? (
                <WbIncandescentRounded fontSize={'small'} />
            ) : (
                <Brightness2Rounded fontSize={'small'} />
            )}
        </IconButton>
    );
}

class TopAppBar extends React.Component<TopAppBarProps, TopAppBarState> {
    constructor(props: TopAppBarProps) {
        super(props);
        this.state = { message: undefined };
    }

    onSearchSymbol = (symbol: string) => {
        if (symbol.length > 0) {
            ReactGA.event('search_stock_symbol', {});
            let url = `/stockdetail/${symbol.replaceAll('^', '%5E')}`;
            this.props.navigate(url);
            if (window.location.pathname.includes('stockdetail')) {
                // while navigating to stock detail page from the stock detail page with different stock symbol
                //doesn't reload the page, only stock symbol is change from url
                //to reload the page forcefully below code is necessary.
                window.location.reload();
            }
        }
    };

    onFeedbackReturn = (success: boolean) => {
        if (success) {
            this.setState({ message: Message.success('Your message was successfully sent') });
        } else {
            this.setState({ message: undefined });
        }
    };

    onLogout = (event: any) => {
        event.preventDefault();
        const userAPIClient: UserAPIClient = AppContext.getInstance().userApiClient;
        userAPIClient.logout().then(() => {
            SessionUtil.clearSession();
            this.props.navigate('/');
        });
    };

    render(): React.ReactNode {
        const usersession = SessionUtil.findSession();
        const user = SessionUtil.findUserInfo()!;
        const initials = user.firstName[0] + user.lastName[0];

        const logo = this.props.isMobile
            ? this.props.mode === 'light'
                ? mobileDark
                : mobileWhite
            : this.props.mode === 'light'
            ? desktopDark
            : desktopWhite;

        return (
            <>
                <InflyMessage
                    isSnackBar={true}
                    message={this.state.message}
                    onClose={() => this.setState({ message: undefined })}
                />

                <AppBar
                    position='fixed'
                    color='inherit'
                    enableColorOnDark={true}
                    sx={(theme: Theme) => ({
                        zIndex: theme.zIndex.drawer + 1,
                        boxShadow: 0,
                    })}
                >
                    <Toolbar>
                        <img src={logo} height={30} alt={' investfly'} />
                        <Stack
                            flexGrow={1}
                            gap={1}
                            mx={{ xs: 1, md: 4 }}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Box flex={[1, 1, 'max-content']}>
                                <SymbolField
                                    includeIndex={true}
                                    onChange={(tickerSymbol: SymbolSummaryInfo) => {
                                        this.onSearchSymbol(tickerSymbol.symbol);
                                    }}
                                    onClear={() => {}}
                                />
                            </Box>
                        </Stack>
                        {/*this item is to make the space between the logo and the right side of the navbar */}
                        <Stack
                            display={{
                                xs: 'none',
                                md: 'flex',
                            }}
                            flexDirection={'row'}
                            gap={2}
                        >


                            {user.userType == UserType.STANDARD && user.userRole == UserRole.TRADER &&
                                <Button variant='contained' color='secondary' onClick={() => this.props.navigate("/upgrade/pricing")}>Start 7-day Free Trial</Button>
                            }

                            {(user?.userType === UserType.ELITE ||
                                user?.userType === UserType.PREMIUM) && (
                                <InviteFriends
                                    color={'warning'}
                                    variant={'contained'}
                                    onSuccess={(message) => this.setState({ message: message })}
                                    label={'Get FREE Month'}
                                />
                            )}


                            <Feedback purpose='contact'
                                id={'feedbackBtn'}
                                variant={'contained'}
                                size={'medium'}
                                onSubmitComplete={this.onFeedbackReturn}
                                title={'Feature Improvement'}
                                dialogOkText={'Close'}
                            >
                                Need Help
                            </Feedback>

                        </Stack>

                        {!this.props.isMobile && <ToogleThemeButton mode={this.props.mode} />}

                        {!this.props.isMobile && (
                            <NavLink
                                to={'/user/setting'}
                                id={'profile'}
                                className={styles.profileImage}
                            >
                                <div id='profileImage'>{initials}</div>
                            </NavLink>
                        )}

                        <IconButton aria-label='logout' onClick={this.onLogout} size='small'>
                            <LogoutIcon fontSize={'small'} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </>
        );
    }
}

export default TopAppBar;
