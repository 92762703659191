/** @format */

import { Button, Stack } from '@mui/material';
import {
    BrokerAccount,
    GameAPIClient,
    Message,
    Portfolio,
    PortfolioIdentifier,
    UserGameScore,
    Visibility,
} from 'api';
import { Game } from 'api/models/marketGame/game';
import InflyDialog from 'containers/common/InflyDialog';
import { InflyMessage } from 'containers/common/InflyMessage';
import { PortfolioSelect } from 'containers/common/PortfolioSelect';
import React from 'react';
import { AppContext } from 'util/appContext';

export enum JoinUpdateMode {
    JOIN = 'JOIN',
    UPDATE = 'UPDATE',
}
interface JoinGameBtnProps {
    game: Game;
    mode: JoinUpdateMode;
    onJoinGameReturn: (gameScore?: UserGameScore) => void;
}

interface JoinGameProps {
    open: boolean;
    selectedPortfolio?: PortfolioIdentifier;
    message?: Message;
}

export class JoinGameBtn extends React.Component<JoinGameBtnProps, JoinGameProps> {
    gameApiClient: GameAPIClient;

    constructor(props: JoinGameBtnProps) {
        super(props);
        this.gameApiClient = AppContext.getInstance().gameApiClient;
        this.state = {
            open: false,
        };
    }

    isEligibleForGame = (portfolio: Portfolio | BrokerAccount): boolean => {
        if (portfolio instanceof BrokerAccount) return false;
        if (portfolio.visibility === Visibility.PRIVATE) return false;
        return true;
    };

    onDialogClose = () => {
        this.setState({ open: false });
        this.props.onJoinGameReturn(undefined);
    };

    onDialogExecute = async (event: any) => {
        if (this.state.selectedPortfolio === undefined) {
            this.setState({ message: Message.error('Portfolio must be selected') });
            return;
        }
        try {
            if (this.props.mode === JoinUpdateMode.JOIN) {
                let score = await this.gameApiClient.joinGame(
                    this.props.game.gameId,
                    this.state.selectedPortfolio.portfolioId
                );
                this.props.onJoinGameReturn(score);
            } else {
                let score = await this.gameApiClient.assignPortfolioToGame(
                    this.props.game.gameId,
                    this.state.selectedPortfolio.portfolioId
                );
                this.props.onJoinGameReturn(score);
            }

            this.setState({ open: false });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    };

    render(): React.ReactNode {
        return (
            <>
                <InflyDialog
                    open={this.state.open}
                    onClose={this.onDialogClose}
                    onExecute={this.onDialogExecute}
                >
                    <Stack>
                        <InflyMessage
                            message={this.state.message}
                            onClose={() => this.setState({ message: undefined })}
                        />

                        <PortfolioSelect
                            currentSelection={this.state.selectedPortfolio}
                            isValid={this.isEligibleForGame}
                            onPortfolioSelect={(selectedPortfolio: PortfolioIdentifier) =>
                                this.setState({ selectedPortfolio })
                            }
                        />
                    </Stack>
                </InflyDialog>

                <Button
                    onClick={(event: any) => {
                        event.preventDefault();
                        this.setState({ open: true });
                    }}
                >
                    {this.props.mode === JoinUpdateMode.JOIN ? 'Join Now' : 'Change Portfolio'}
                </Button>
            </>
        );
    }
}
