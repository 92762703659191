import { ComparisonOperator } from "../common/ComparisonOperator";
import { DataParam } from "./DataParam";


export class SecurityTriggerExpression{
    
    filterGroups: FilterGroup[];
    dataParams: Map<string, DataParam>;

    constructor(){
        this.filterGroups = [];
        this.dataParams = new Map<string, DataParam>;
    }

    public static createDefault(): SecurityTriggerExpression{
        let screenerExpression = new SecurityTriggerExpression();
        screenerExpression.addFilterGroup(FilterGroup.emptyFilterGroup());
        return screenerExpression;
    }

    addFilterGroup(filterGroup: FilterGroup): void{
        this.filterGroups.push(filterGroup);
    }

    removeFilterGroup(index: number): void {
        this.filterGroups.splice(index, 1);
    }

    updateFilterGroup(index: number, filterGroup: FilterGroup): void {
        this.filterGroups[index] = filterGroup;
    }

    cleanUpDeclaredMetrics(){
        let usedMetrics: Set<string> = this.getUsedMetricAliases();
        for(let alias of this.dataParams.keys()){
            if(!usedMetrics.has(alias)){
                this.dataParams.delete(alias);
            }
        }
    }

    getUsedMetricAliases(): Set<string> {
        let metrics: Set<string> = new Set();
        for(let group of this.filterGroups){
            metrics = new Set([...group.getUsedMetricAliases(), ...metrics]);
        }
        return metrics;
    }

    clone(): SecurityTriggerExpression {
        const clone = new SecurityTriggerExpression();
        for(let key of this.dataParams.keys()){
            clone.dataParams.set(key, this.dataParams.get(key)!.clone());
        }
        clone.filterGroups = this.filterGroups.map(fg => fg.clone());
        return clone;
    }

    toString(): string {
        let result: string = "";
        let size: number = this.filterGroups.length;
        let index: number;
        for(index=0; index < size; index++){
            result += this.filterGroups[index].toString();
            if(index < size-1){
                result += " && ";
            }        
        }
        return result;
    }

    isValid(): boolean {
        return this.validate().length === 0;
    }

    validate(): string[]{
        let result: string[] = [];
        for(let fg of this.filterGroups){
            result = [...result, ...fg.validate()];
        }
        return result;
    }

    static parseJSON(obj: {[index:string]: any}): SecurityTriggerExpression {
        let screenerExpression = new SecurityTriggerExpression()
        for(let fg of obj.filterGroups){
            screenerExpression.addFilterGroup(FilterGroup.parseJSON(fg))
        }
        for(let paramKey in obj.dataParams){
            let dataParam: DataParam = DataParam.parseJSON(obj.dataParams[paramKey]);
            dataParam.set(DataParam.ALIAS, paramKey);
            screenerExpression.dataParams.set(paramKey, dataParam)
        }
        return screenerExpression
    }

    
}

export class FilterGroup {
    filterConditions: FilterCondition[]

    constructor(){
        this.filterConditions = [];
    }

    static emptyFilterGroup(){
        let filterGroup = new FilterGroup();
        filterGroup.addFilterCondition(new FilterCondition());
        return filterGroup;
    }


    addFilterCondition(filterCondition: FilterCondition): void {
        this.filterConditions.push(filterCondition);
    }

    removeFilterCondition(index: number): void{
        this.filterConditions.splice(index, 1);
    }

    updateFilterCondition(index: number, filterCondition: FilterCondition): void{
        this.filterConditions[index] = filterCondition;
    }

    getUsedMetricAliases(): Set<string> {
        let metrics: Set<string> = new Set();
        for(let cond of this.filterConditions){
            metrics = new Set([...cond.getUsedMetricAliases(), ...metrics]);
        }
        return metrics;
    }

    toString(): string {
        let result: string = "(";
        let size: number = this.filterConditions.length;
        let index: number;
        for(index=0; index < size; index++){
            result += this.filterConditions[index].toString();
            if(index < size-1){
                result += " || ";
            }        
        }
        result += ")";
        return result;
    }

    isValid(): boolean {
        return this.validate().length === 0;
    }

    validate(): string[]{
        let result: string[] = [];
        if(this.filterConditions.length == 0) {
            result.push('AND Filter should have at least one criteria')
        }else{
            for(let fc of this.filterConditions){
                result = [...result, ...fc.validate()];
            }
        }
        return result;
    }

    clone(): FilterGroup {
        const filterGroup = new FilterGroup();
        filterGroup.filterConditions = this.filterConditions.map(fc => fc.clone());
        return filterGroup;
    }

    static parseJSON(obj: {[index:string]: any}):  FilterGroup {
        let filterGroup = new FilterGroup()
        for( let fc of obj.filterConditions){
            filterGroup.addFilterCondition(FilterCondition.parseJSON(fc))
        }
        return filterGroup;
    }
}

export class FilterCondition {

    leftCondition: string[];
    operator: ComparisonOperator;
    rightCondition: string[];

    constructor(){
        this.operator = ComparisonOperator.GREATER_THAN;
        this.leftCondition = [];
        this.rightCondition = [];
    }

    setComparisonOperator(operator: ComparisonOperator){
        this.operator = operator;
    }

    getUsedMetricAliases(): Set<string> {
        return new Set([...this.getExpressionUsedMetricAliases(this.leftCondition), 
                        ...this.getExpressionUsedMetricAliases(this.rightCondition)]);
    }


    getExpressionUsedMetricAliases(expression: string[]): Set<string> {
        let metrics: Set<string> = new Set();
        for(let expr of expression){
            if(!FilterCondition.isOperator(expr)){
                metrics.add(expr);
            }
        }
        return metrics;
    }

    toString(): string {
        let result: string = "(";
        result += this.leftCondition.join(" ")
        result += ` ${this.operator} `;
        result += this.rightCondition.join(" ")
        result += ")";
        return result;
    }

    isValid(): boolean {
        return this.validate().length === 0;
     }

    validate(): string[]{

        if(this.leftCondition.length == 0 && this.rightCondition.length == 0){
            return ["Expression cannnot be empty"];
        }

        let result: string[] = [...FilterCondition.validateExpression(this.leftCondition).map(err => "LeftExpression: "+err), 
                                ...FilterCondition.validateExpression(this.rightCondition).map(err => "RightExpression: "+err)];
        if(this.operator === null || this.operator === undefined){
            result.push("Operator must be defined");
        }
        return result;
    }

    clone(): FilterCondition {
        const filterCondition = new FilterCondition();
        filterCondition.leftCondition = [...this.leftCondition];
        filterCondition.rightCondition = [...this.rightCondition];
        filterCondition.operator = this.operator;
        return filterCondition;
    }

    static parseJSON(obj: {[index:string]: any}):  FilterCondition {
        let filterCondition = new FilterCondition()
        filterCondition.leftCondition = obj.leftCondition;
        filterCondition.operator = ComparisonOperator[obj.operator];
        filterCondition.rightCondition = obj.rightCondition;
        return filterCondition;
    }

    static validateExpression(expression: string[]): string[] {

        let size: number = expression.length;
        if (size === 0 ) return ["Expression cannot be empty"];

        if (FilterCondition.isOperator(expression[size-1])){
            return ["Expression cannot end with an operator"];
        }
        
        let isPrevOperator: boolean = false;
        let isPrevMetric: boolean = false;

        // Note: a + ( b + c) is valid that mean + and ( are  consequtive
        for(let i=0; i < size; i++){
            let isCurOperator: boolean = FilterCondition.isOperator(expression[i]);
            let isCurMetric: boolean = FilterCondition.isMetric(expression[i]);
            if(isPrevOperator && isCurOperator){ // two subsequent operator is invalid
                return ["Two consecutive expression items cannot be operators"];
            }
            if(isPrevMetric && isCurMetric){
                return ["Two consecutive expression items cannot be metrics"];
            }
            isPrevOperator = isCurOperator;
            isPrevMetric = isCurMetric;
        }
        return [];
    }


    static isOperator(item: string){
        return item === Operator.PLUS ||
            item === Operator.MINUS || 
            item === Operator.MULTIPLY || 
            item === Operator.DIVIDE
    }

    static isParenthesis(item: string){
        return item === Operator.START_BRACKET || item === Operator.END_BRACKET;
    }

    static isMetric(item: string){
        return !this.isOperator(item) && !this.isParenthesis(item);
    }


}

export class Operator {
    static readonly PLUS:string = "+";
    static readonly MINUS:string = "-";
    static readonly MULTIPLY:string = "*";
    static readonly DIVIDE:string = "/";
    static readonly START_BRACKET:string = "(";
    static readonly END_BRACKET:string = ")";
}

