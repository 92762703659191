/** @format */

import {
    Box,
    Paper,
    Grid,
    Card,
    CardMedia,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
    Stack,
    Divider,
    CardContent,
    CardHeader,
} from '@mui/material';
import { ProfessorClass } from 'api';
import A from 'containers/common/Anchor';
import React from 'react';
import AddClassBtn from './AddClassBtn';
import { InflyIcon } from 'containers/common/InflyIcon';

interface Props {
    onNewClassCreate: (clazz?: ProfessorClass) => void;
}

export function NoClassBox(props: Props) {
    return (
        <Card>
            <CardHeader action={<Stack direction={'row'} spacing={2}>
                <Typography variant={'subtitle2'}>
                        <A href={'https://youtu.be/UaIrOvLkdLk'} newTab={true}>
                            Watch Instructor Tutorial
                        </A>
                    </Typography>
                <AddClassBtn label={'Add Class'} onReturn={props.onNewClassCreate } />
                </Stack>}></CardHeader>
            <CardContent>
            <Paper elevation={5}>
            <Stack gap={3} padding={4}>
                <Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant='h6'> Tip</Typography>
                        <InflyIcon name='tip' />
                    </Stack>
                    <Divider />
                </Stack>

                <Typography variant='body2'>
                    Create a virtual classroom to manage group of students in your classroom. We recommend you name the class to match the course code (e.g FY24-FINANCE-101). After you create a class, invite students to join the class. Once they join, you can track their portfolio and view performance.
                </Typography>

                <Typography variant='body2'>
                    Click on 'Create Class' button above to create a new class.
                </Typography>

            </Stack>
        </Paper>
            </CardContent>
        </Card>

    );
}
