/** @format */

import AccountDashboard from './containers/Portfolio/PortfolioDashboard';
import GameDetail from './containers/Game/GameDetail';
import MyGames from './containers/Game/MyGames';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ConfirmActivateAccount from './containers/Outer/ConfirmActivateAccount';
import NewPassword from './containers/User/NewPassword';
import ConfirmPasswordChange from './containers/Outer/ConfirmPasswordChange';
import Login from './containers/Outer/LoginForm';
import UpgradeComponent from './containers/Upgrade/Upgrade';
import Register from './containers/Outer/RegisterForm';
import MyPortfolioList from './containers/Portfolio/PortfolioList';
import ConfirmChangeEmail from './containers/Outer/ConfirmChangeEmail';
import ProfessorDashboard from 'containers/Professor/ProcessorDashBoard';
import StrategiesLists from 'containers/Strategies/StrategiesList';
import ConnectBroker from 'containers/Broker/ConnectBrokerCallBack';
import StrategyDetailComponent from 'containers/Strategies/StrategyDetail';
import UserReferrals from './containers/User/UserReferrals';
import Screener from './containers/Screener/Screener';
import ScreenerList from './containers/Screener/ScreenerList';
import ErrorPage from 'ErrorPage';
import Setting from 'containers/User/setting/Setting';
import ForgetPassword from 'containers/Outer/ForgetPassword';
import IndicatorListPage  from 'containers/CustomerIndicator/Indicators';
import Dashboard from 'containers/Dashboard/Dashboard';
import StockDetailsView from 'containers/StockDetails/StockDetailsView';
import { TestPage } from 'containers/TestPage';
import ChartPage from 'containers/StockDetails/ChartPage';
const MainRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='register' element={<Register />} />
                <Route path='activate' element={<ConfirmActivateAccount />} />
                <Route path='forgotpassword' element={<ForgetPassword />} />
                <Route path='/changepassword' element={<NewPassword />} />

                <Route path='upgrade/*' element={<UpgradeComponent />} />

                <Route path={`/user/setting`} element={<Setting />} />
                <Route path={`/newpassword`} element={<ConfirmPasswordChange />} />
                <Route path={`user/confirm-new-email`} element={<ConfirmChangeEmail />} />

                <Route path='portfolio/:brokerType/:portfolioId/' element={<AccountDashboard />} />
                <Route path='broker/:brokerName' element={<ConnectBroker />} />

                <Route path='/indicator/list' element={<IndicatorListPage />} />

                <Route path='portfolio/list' element={<MyPortfolioList />} />
                <Route path='strategy/list' element={<StrategiesLists />} />
                <Route path='strategy/:strategyId' element={<StrategyDetailComponent />} />
                <Route path='games' element={<MyGames />} />

                <Route path='screener/list' element={<ScreenerList />} />

                {/*if screenerId is passed this will be route*/}
                <Route path='screener' element={<Screener />} />
                <Route path='screener/:screenerId' element={<Screener />} />

                <Route path='dashboard' element={<Dashboard />} />
                <Route path='stockdetail' element={<StockDetailsView />} />
                <Route path='stockdetail/:symbol' element={<StockDetailsView />} />
                <Route path='chart/:symbol' element={<ChartPage />} />

                

                <Route path='game/:gameId' element={<GameDetail />} />
                <Route path='myreferrals' element={<UserReferrals />} />
                <Route path={`/professor/dashboard`} element={<ProfessorDashboard />} />

                <Route path="/test" element={<TestPage />} />
                
                <Route path='*' element={<ErrorPage />} />
                {/*  test page for charts*/}
            </Routes>
        </BrowserRouter>
    );
};
export default MainRoutes;
