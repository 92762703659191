import React from 'react';
import SessionUtil from 'util/SessionUtil';
import {Navigate} from 'react-router-dom';


const withEmptySession  = <P extends object> (Component: React.ComponentType<P>) => {

    return function NoSessionValidator (props: any) {
        const isAuthenticate = SessionUtil.isUserLoggedIn();
        if (isAuthenticate) {
            return <Navigate to={`${SessionUtil.getAfterLoginPage()}`} replace/>;
        }
        return <Component {...props as P} />;
    }

}

export default withEmptySession;