import { UserError } from "../models/common/userError";

export class Validator {

    static validateNotEmpty(fieldName: string, array: any[]) {
        if(array.length === 0){
            throw new UserError(fieldName + "cannot be empty");
        }

    }

    static validateNumericRange(fieldName: string, value: number, min: number, max: number) {
        if(value < min || value > max){
            throw new UserError(fieldName + " must be between " + min + " and " + max);
        }

    }

    static validateMiniumimValue(fieldName: string, value: number , minimum: number) {
        if(value < minimum){
            throw new UserError(fieldName + " must be at least " + minimum +". Provided: " + value);
        }
    }
    static validateAtMostOneIsNotNull(groupName: string, ...values: any[]) {
        let notNullCount = 0;
        for(let val of values){
            if (val) notNullCount++;
        }
        if(notNullCount > 1) {
            throw new UserError("At most only one value for " + groupName +" can be specified");
        }
    }
    static validateAtLeastOneIsNotNull(groupName: string, ...values: any[]) {
        let notNullCount = 0;
        for(let val of values){
            if (val) notNullCount++;
        }
        if(notNullCount === 0) {
            throw new UserError("At least one value for " + groupName +" must be specified");
        }
    }

    static validateNotNull(fieldName: string, value: any){
        if(value === undefined || value === null){
            throw new UserError(fieldName +" must be specified");
        }
    }

    static validatePossibleObjectValues(fieldName: string, value: any, possibleValues: any[]){
        for(let pval of possibleValues){
            if(value == pval){
                return true;
            }
        }
        throw new UserError(fieldName + " must be one of [" + possibleValues.join(',')+"]")
    }

    static validatePositive(fieldName:string, value: number){
        if(value < 0){
            throw new UserError(fieldName + " cannot be negative");
        }
    }
}