import { Stack, Divider, Chip, Typography, Button } from "@mui/material";
import { WatchList, SymbolSummaryInfo, Message, OPERATION } from "api";
import DialogButton from "containers/common/DialogButton";
import { ITextField } from "containers/common/ITextField";
import InflyDialog from "containers/common/InflyDialog";
import InflyHelperText from "containers/common/InflyHelperText";
import { SymbolField } from "containers/common/SymbolField";
import UpgradeCheckButton from "containers/common/UpgradeCheckButton";
import React from "react";
import { useState } from "react";
import { Watch } from "typescript";
import { AppContext } from "util/appContext";
import ReactGA from 'react-ga4';



export interface NewWatchListBtnProps {
    onCreate: (watchList: WatchList) => void;
    curWatchListCount: number;
}

export function NewWatchListBtn({onCreate, curWatchListCount}: NewWatchListBtnProps): React.JSX.Element {
    const [dialogOpen, setDialogOpen] = useState(false);

    function onWatchListCreate(watchList: WatchList){
        setDialogOpen(false);
        onCreate(watchList);
    }

    function onDialogCancelReturn(){
        setDialogOpen(false);
    }

    function onBtnClick(event: any){
        setDialogOpen(true)
        ReactGA.event('new_watchlist_btn_click', {});
    }
    return (<>
        {dialogOpen && 
            <CreateWatchListDialog onCreate={onWatchListCreate} onCancel={onDialogCancelReturn}/>
        }
        <UpgradeCheckButton op={OPERATION.CREATE_WATCHLIST} currentCount={curWatchListCount} label='New WatchList'>
            <Button onClick={onBtnClick}>New WatchList</Button>
        </UpgradeCheckButton>
        
    </>);
}

type CreateWatchListDialogProps = {
    onCreate: (watchList: WatchList) => void;
    onCancel: () => void;
}

export function CreateWatchListDialog({ onCreate, onCancel }: CreateWatchListDialogProps): React.JSX.Element {

    const [watchListName, setWatchListName] = useState<string>();
    const [symbols, setSelectedSymbols] = useState<string[]>([]);

    async function onDialogExecute() {
        let watchListApiClient = AppContext.getInstance().watchListApiClient;
        let watchList: WatchList = WatchList.createDefaultStockList(watchListName!);
        for (let s of symbols) {
            watchList.addSymbolForCreate(s);
        }
        watchList = await watchListApiClient.createWatchList(watchList);
        onCreate(watchList);
    }

    function onSymbolSelect(symbol: SymbolSummaryInfo) {
        setSelectedSymbols([...symbols, symbol.symbol]);
    }



    return <>
        <InflyDialog title='New WatchList' open={true} onExecute={onDialogExecute} onClose={onCancel} okText='Create' fullWidth maxWidth={'md'}>
            <Stack spacing={2}>
                <ITextField label="WatchList Name" value={watchListName} onChange={(newName: string) => setWatchListName(newName)} />
                <Divider />
                <InflyHelperText info={'Select symbols for the new watchlist'} />
                <SymbolField includeIndex={false} onClear={() => { }} onChange={onSymbolSelect} />
                <Stack direction={"row"} spacing={2}>
                    {symbols.map(s => {
                        return <Chip key={s} label={s} />
                    })}
                </Stack>

            </Stack>

        </InflyDialog>
    </>

}

export type DeleteWatchListDialogProps = {
    watchListId: number,
    onDelete: (message: Message) => void;
    onCancel: () => void;
}

export function DeleteWatchListDialog({watchListId, onDelete, onCancel}: DeleteWatchListDialogProps): React.JSX.Element {
    async function onDialogExecute(){
        let watchListApiClient = AppContext.getInstance().watchListApiClient;
        let message = await watchListApiClient.deleteWatchList(watchListId);
        onDelete(message);
    }
    return <InflyDialog open={true} title='Delete WatchList' onExecute={onDialogExecute} onClose={onCancel} okText="Delete">
        <Typography>Are you sure you want to delete this watchlist?</Typography>
    </InflyDialog>
}
