import {
    Box,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    BarInterval,
    Message,
    OrderStatus,
    PendingOrder,
    PortfolioIdentifier,
    SecurityType,
    StockAlert,
    StockQuote,
    SymbolSummaryInfo,
} from 'api';
import { BlinkTextComponent } from 'containers/common/BlinkComponet';
import { LabelAndValue } from 'containers/common/LabelAndValue';
import React, { useEffect, useRef, useState } from 'react';
import { AppContext } from 'util/appContext';
import { DataParam, DataType } from 'api/models/trigger/DataParam';
import { FinancialField } from 'api/models/trigger/FinancialField';
import { LoadingCard } from 'containers/common/LoadingCard';
import Trade, { TradeTabs } from 'containers/Trade/Trade';
import AddToWatchListButton from 'containers/WatchList/AddToWatchListButton';
import { NewAlertBtn } from 'containers/Alert/AlertForm';
import { InflyIcon } from 'containers/common/InflyIcon';



interface StockSummaryProps {
    securitySummary: SymbolSummaryInfo;
    onMessage: (message?: Message) => void;
    selectedPortfolioId?: PortfolioIdentifier;
    
}

export function StockSummary({securitySummary, selectedPortfolioId, onMessage}: StockSummaryProps): React.JSX.Element {
    
    const [quote, setQuote] = useState<StockQuote>();
    const [financials, setFinancials] = useState<Map<FinancialField, number>>();
    const [range52week, setRange52Week] = useState<{ [key: string]: number}>();

    const timerRef = useRef(0);

    async function loadQuote(){
        let marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        let stockQuote = await marketDataApiClient.getStockQuote(securitySummary.symbol);
        setQuote(stockQuote)
    }

    async function loadSummaryData(){
            
        await loadQuote();

        let marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        if(securitySummary.type == SecurityType.STOCK){ /// doesn't make sense for ETF and INDEX
            let financialsData = await  marketDataApiClient.getFinancials(securitySummary.symbol);
            setFinancials(financialsData);
        }

        let indicatorApiClient = AppContext.getInstance().indicatorApiClient;
        let minParam = DataParam.parseJSON({ 'datatype': DataType.INDICATOR, 'indicator': 'MIN', 'period': 250, 'barinterval': BarInterval.ONE_DAY});
        let maxParam = DataParam.parseJSON({'datatype': DataType.INDICATOR, 'indicator': 'MAX', 'period': 250, 'barinterval': BarInterval.ONE_DAY});
        let dataParams: Map<string, DataParam>  = new Map();
        dataParams.set("min250", minParam);
        dataParams.set("max250", maxParam);
        let response = await indicatorApiClient.computeIndicators(securitySummary.symbol, dataParams);
        setRange52Week(response);
    }

    useEffect(() => {
        loadSummaryData();
        let intervalId = setInterval(loadQuote, 10000);
        timerRef.current = intervalId as any as number;

        return () => {
            if(timerRef.current > 0){
                clearInterval(timerRef.current);
                timerRef.current = 0;
            }
        }
    }, [securitySummary.symbol])

    
    const onTradeDialogReturn = (tradeResult: OrderStatus | OrderStatus[] | PendingOrder | undefined) =>  {
        if(tradeResult){
            let orderStatus = tradeResult as OrderStatus;
            onMessage(orderStatus.getDefaultMessage());
        }
    }

    const onAddToWatchListReturn = (stockAlert: StockAlert, message?: Message) => {
        if(message){
            onMessage(message);
        }
    }

    const createCardHeaderActions = () : React.JSX.Element => {

        return <Stack direction={'row'} gap={1}>
            { securitySummary.type != SecurityType.INDEX &&
                <Trade label='Trade' security={securitySummary} onTradeDialogReturn={onTradeDialogReturn} portfolio={selectedPortfolioId} showOnly={[TradeTabs.STOCK]}/>
            }

            { securitySummary.type != SecurityType.INDEX &&
                <AddToWatchListButton symbols={[securitySummary.symbol]} onMessage={onMessage} onActionReturn={() => {}}/>
            }

            { securitySummary.type != SecurityType.INDEX &&
                <NewAlertBtn security={securitySummary.toStock()} onComplete={onAddToWatchListReturn}/>
            }

        </Stack>
    }

    if(!quote){
        return <LoadingCard title='Stock Summary' />
    }

    let summaryDataMap = new Map<string, any>();
    summaryDataMap.set("Day Range", `$${quote.dayLow} - $${quote.dayHigh}`)
    summaryDataMap.set("Volume", quote.volume);

    if(securitySummary.type == SecurityType.STOCK){
        summaryDataMap.set("Market Cap", financials?.get(FinancialField.MARKET_CAP))
    }
    if(range52week){
        summaryDataMap.set("52 Week Range", `$${range52week["min250"]} - $${range52week["max250"]}`)
    }

    
    return <Card>
        <CardHeader title='Summary Info' action={ createCardHeaderActions()}/>
        <CardContent>
            <Stack flexDirection={'row'}  justifyContent={'space-between'}   gap={{ xs: 2, md: 4 }}  >
                        <Box width={'60%'}>
                            <Typography
                                variant='h6'
                                sx={{
                                    fontWeight: 700,
                                    fontSize: { xs: '1.1rem !important', md: 'inherit' },
                                    textAlign: 'left',
                                }}
                            >
                                {`${securitySummary.name} (${securitySummary.symbol})`}
                            </Typography>
                            <Typography>
                                {securitySummary.type === SecurityType.STOCK
                                    ? `${securitySummary.industry}`
                                    : undefined}
                            </Typography>

                                    <BlinkTextComponent  sx={{   fontWeight: 700,   fontSize: '1.6rem !important',  }}
                                        value={quote.todayChange as number}   >
                                        
                                        ${quote.lastPrice} &nbsp;
                                        
                                        {quote.todayChange !== undefined &&
                                                    <Typography  component={'span'}   color={
                                                    quote.todayChange === 0
                                                        ? 'inherit'
                                                        : (quote.todayChange as number) > 0
                                                        ? 'common.green'
                                                        : 'common.red'
                                                }   >
                                                ${quote.todayChange} (
                                                {quote.todayChangePct}
                                                %)
                                            </Typography>
                                        }
          

                                    </BlinkTextComponent>

                                    <Typography variant='body2'>
                                        Previous Close: ${quote.prevClose}, Open: $
                                        {quote.dayOpen}
                                    </Typography>                                      
                        </Box>

                        <Stack flex={'1 auto'}  width={'40%'}>
                            {
                                Array.from(summaryDataMap)?.map((keyValue: any[], index: number) => (
                                    <LabelAndValue
                                        key={index}
                                        label={keyValue[0]}
                                        value={keyValue[1]}
                                    />
                                ))}
                        </Stack>
                    </Stack>
        </CardContent>
    </Card>
}


export function LightSummary({symbol}: {symbol: string}): React.JSX.Element {
    const [securitySummary, setSecuritySummary] = useState<SymbolSummaryInfo>();
    const [quote, setQuote] = useState<StockQuote>();
    const timerRef = useRef(0);

    async function loadQuote(){
        let marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        let stockQuote = await marketDataApiClient.getStockQuote(symbol);
        setQuote(stockQuote)
    }
    async function loadSecuritySummary() {
        let marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        let summary: SymbolSummaryInfo = await marketDataApiClient.getSecuritySummary(symbol);
        setSecuritySummary(summary);
    }
    
    useEffect(() => {
        loadSecuritySummary();
        loadQuote();

        let intervalId = setInterval(loadQuote, 10000);
        timerRef.current = intervalId as any as number;

        return () => {
            if(timerRef.current > 0){
                clearInterval(timerRef.current);
                timerRef.current = 0;
            }
        }

    }, [symbol])

    if(securitySummary === undefined || quote === undefined){
        return <LoadingCard title='Stock Summary' />
    }

    let summaryDataMap = new Map<string, any>();
    summaryDataMap.set("Day Range", `$${quote.dayLow} - $${quote.dayHigh}`)
    summaryDataMap.set("Previous Close", `$${quote.prevClose}`)
    summaryDataMap.set("Open", `$${quote.dayOpen}`)


    return <Card>
        <CardHeader title='Summary Info' action={
            <Tooltip title='More Stock Details'>
            <IconButton href={`/stockdetail/${symbol}`}>
                <InflyIcon name="openInNew" />
            </IconButton>
            </Tooltip> 
        }/>
        <CardContent>
            <Stack flexDirection={'row'}  justifyContent={'space-between'}   gap={{ xs: 2, md: 4 }}  >
                        <Box width={'60%'}>
                            <Typography
                                variant='h6'
                                sx={{
                                    fontWeight: 700,
                                    fontSize: { xs: '1.1rem !important', md: 'inherit' },
                                    textAlign: 'left',
                                }}
                            >
                                {`${securitySummary.name} (${securitySummary.symbol})`}
                            </Typography>

                                    <BlinkTextComponent  sx={{   fontWeight: 700,   fontSize: '1.6rem !important',  }}
                                        value={quote.todayChange as number}   >
                                        
                                        ${quote.lastPrice} &nbsp;
                                        
                                        <Typography  component={'span'}   color={
                                                quote.todayChange === 0
                                                    ? 'inherit'
                                                    : (quote.todayChange as number) >= 0
                                                    ? 'common.green'
                                                    : 'common.red'
                                            }   >
                                            ${quote.todayChange} (
                                            {quote.todayChangePct}
                                            %)
                                        </Typography>

                                    </BlinkTextComponent>
                                    
                        </Box>

                        <Stack flex={'1 auto'}  width={'40%'}>
                            {
                                Array.from(summaryDataMap)?.map((keyValue: any[], index: number) => (
                                    <LabelAndValue
                                        key={index}
                                        label={keyValue[0]}
                                        value={keyValue[1]}
                                    />
                                ))}
                        </Stack>
                    </Stack>
        </CardContent>
    </Card>




}