/** @format */

import React, { Component, useEffect, useState } from 'react';
import InflyDialog from 'containers/common/InflyDialog';
import { InflyMessage } from 'containers/common/InflyMessage';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
} from '@mui/material';
import {
    Message,
    OPERATION,
    ScreenerModel,
    ScreenerResult,
    ScreenerScope,
    SecurityType,
    UserError,
} from 'api';
import { CreateUpdateMode } from 'util/CommonUtil';
import { AppContext } from 'util/appContext';
import { MultiSelectField, RadioField, SelectField, SelectOption, selectOptionsFromString } from 'containers/common/SelectField';
import withScreenSize from 'containers/HOC/WithScreenSize';
import { ScreenSizeProp } from 'util/commonProps';
import { ITextField } from 'containers/common/ITextField';
import { SecurityFilterExpressionComp } from 'containers/Trigger/SecurityFilterExpressionComp';
import { SecurityTriggerExpression } from 'api/models/trigger/SecurityTriggerExpression';
import UpgradeCheckButton from 'containers/common/UpgradeCheckButton';

import ReactGA from 'react-ga4';
import { ActionButton } from 'containers/common/ActionButton';
import { SampleScreenerList, SampleScreenerListDialog } from './ScreenerSampleList';
import { InflyIcon } from 'containers/common/InflyIcon';

export enum ScreenerSecurityType {
    STOCKS = 'STOCKS',
    ETFS = 'ETFS',
}

interface NewScreenerButtonProps {
    showSample?: boolean;
    cloneScreener?: ScreenerModel;
    btnLabel?: string;
    iconName?: string;
    onSubmit: (
        screener: ScreenerModel,
        result?: ScreenerResult, // if only screener metadata is updated in Update mode, screeer won't be run
        message?: Message
    ) => void;
    curScreenerCount: number;
}


export function NewScreenerDialogButton({showSample, cloneScreener, curScreenerCount, btnLabel, iconName, onSubmit}: NewScreenerButtonProps): React.JSX.Element {
    const [screenerFormDialogOpen, setScreenerFormDialogOpen] = useState(false);
    const [sampleDialogOpen, setSampleDialogOpen] = useState(false);

    const [editScreener, setEditScreener] = useState<ScreenerModel|undefined>(cloneScreener);

    function onDialogCreateReturn(strategyModel: ScreenerModel, result?: ScreenerResult, message?: Message){
        setScreenerFormDialogOpen(false);
        onSubmit(strategyModel, result, message);
    }

    function onDialogCancelReturn(){
        ReactGA.event('new_screener_cancelled', {});
        setScreenerFormDialogOpen(false);
    }

    function onBtnClick(event: any){
        if(showSample){ 
            setSampleDialogOpen(true);
        }else{
            setScreenerFormDialogOpen(true);
        }

        ReactGA.event('new_edit_screener_btn_click', {});
    }

    function onSampleSelect(sampleModel: ScreenerModel){
        setEditScreener(sampleModel);
        setSampleDialogOpen(false);
        setScreenerFormDialogOpen(true);
    }

    function onCreateFromScratch(){
        setEditScreener(ScreenerModel.createDefault());
        setSampleDialogOpen(false);
        setScreenerFormDialogOpen(true);
    }

    function onSampleDialogCancel(){
        ReactGA.event('new_screener_cancelled', {});
        setSampleDialogOpen(false);
    }

    return (<>

        {sampleDialogOpen && 
            <SampleScreenerListDialog onSampleSelect={onSampleSelect} onStartFromScratch={onCreateFromScratch} onCancel={onSampleDialogCancel}/>
        }

        {screenerFormDialogOpen && 
            <ScreenerFormDialog onSubmit={onDialogCreateReturn} onCancel={onDialogCancelReturn} screener={editScreener}/>
        }

        <UpgradeCheckButton op={OPERATION.CREATE_SCREENER} currentCount={curScreenerCount} label={btnLabel ?? 'New Screener'}>
            <>
                {iconName && <IconButton onClick={onBtnClick}><InflyIcon fontSize={'small'}  name={iconName}/></IconButton>}
                {iconName === undefined && <Button onClick={onBtnClick}> {btnLabel ?? 'New Screener'} </Button> }
            </>
            
        </UpgradeCheckButton>
        
    </>);
}




interface ScreenerFormDialogProps {
    screener?: ScreenerModel; // provided screener could be existing screener (UPDATE usecase) or it could also sample (CREATE)
    onCancel: () => void;
    onSubmit: (
        screener: ScreenerModel,
        result?: ScreenerResult, // if only screener metadata is updated in Update mode, screeer won't be run
        message?: Message
    ) => void;
}



export function ScreenerFormDialog({ screener, onSubmit, onCancel }: ScreenerFormDialogProps): React.JSX.Element {

    const [message, setMessage] = useState<Message | null>(null);
    const [editScreener, setEditScreener] = useState(screener !== undefined ? screener.clone() : ScreenerModel.createDefault());
    const createUpdateMode = (screener && screener.screenerId) ? CreateUpdateMode.UPDATE : CreateUpdateMode.CREATE;

    useEffect(() => {
        setEditScreener(screener !== undefined ? screener.clone() : ScreenerModel.createDefault());
    }, [screener])


    function onScreenerModelChange(screenerModel: ScreenerModel){
        setEditScreener(screenerModel.clone());
    }


    const onDialogSubmit = async () => {
        let errors = editScreener.validate();
        if(errors.length > 0){
            setMessage(Message.error(errors.join(",")));
            return;
        }

        const screenerApiClient = AppContext.getInstance().screenerApiClient;
        let isError: boolean = false;

        if(createUpdateMode == CreateUpdateMode.UPDATE){
            const updateMetadata = editScreener.name !== screener?.name || editScreener.description !== screener.description || editScreener.description !== screener.description;
            if(updateMetadata){
                try{
                    const updatedScreener: ScreenerModel = await screenerApiClient.updateScreenerMetadata(editScreener);
                    setEditScreener(updatedScreener)
                }catch(error){
                    isError = true;
                    setMessage(Message.fromError(error));
                }
            }         
        }

        // this is valid for both create and update mode
        const updateQuery = editScreener.query !== screener?.query;

        let screenerResult: ScreenerResult| undefined = undefined;
        if(updateQuery){

            let validationErors: string[] = editScreener.query.validate();
            if(validationErors.length > 0){
                isError = true;
                setMessage(Message.error(validationErors.join()));
            }else{
                try{
                    screenerResult = await screenerApiClient.runScreener(editScreener)
                    editScreener.screenerId = screenerResult.screenerId.valueOf();
                }catch(error){
                    isError = true;
                    setMessage(Message.fromError(error));
                }
            }
        }

        if(!isError){
            const action = createUpdateMode == CreateUpdateMode.CREATE ? "Created" : "Updated";
            onSubmit(editScreener, screenerResult, Message.success(`Screener ${action}`))
        }
    }


    return (
        <Dialog open fullWidth maxWidth='lg'>
            <DialogTitle>Screener Form</DialogTitle>
            <DialogContent>
                <Stack>
                    <InflyMessage message={message} onClose={() => setMessage(null)} />
                    <ScreenerFormComp screenerModel={editScreener} onChange={onScreenerModelChange}></ScreenerFormComp>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <ActionButton label="Submit" onClick={onDialogSubmit}/>
            </DialogActions>
        </Dialog>
    );

}

interface ScreenerFormProps {
    screenerModel: ScreenerModel
    onChange: (screenerModel: ScreenerModel) => void;
}

export function ScreenerFormComp({screenerModel, onChange}: ScreenerFormProps): React.JSX.Element {

    const updateScreenerName = (screenerName: string) => {
        screenerModel.name = screenerName;
        onChange(screenerModel);
    }

    const updateScreenerDesc = (desc: string) => {
        screenerModel.description = desc;
        onChange(screenerModel);
    }

    const onVsibilityChange = (selectOption: SelectOption) => {
        screenerModel.isPublic = selectOption.value;
        onChange(screenerModel);
    }

    const onScreenerScopeChange = (screenerScope: ScreenerScope) => {
        screenerModel.screenerScope = screenerScope;
        onChange(screenerModel);
    }

    const onScreenerQueryChange = (expression?: SecurityTriggerExpression) => {
        screenerModel.query = expression!;
        onChange(screenerModel);
    }

    return <Stack>
    
        <Card>
            <CardHeader title='Screener Setting' titleTypographyProps={{ variant: 'subtitle1' }} />
            <CardContent>

                <Stack spacing={2}>

                    <ITextField label="Screener Name (Required)" size={'small'} value={screenerModel.name} onChange={updateScreenerName} />
                    <ITextField label="Description (Optional)" multiline size={'small'} value={screenerModel.description} onChange={updateScreenerDesc} />

                    <RadioField helperText="Public screeners are accessible by other users via a URL" label="Visibility" 
                        options={[{ label: "PRIVATE", value: false }, { label: "PUBLIC", value: true }]} value={screenerModel.isPublic} onSelectChange={onVsibilityChange} />
                    <Divider />

                    <ScreenerScopeComp screenerScope={screenerModel.screenerScope} onChange={onScreenerScopeChange} />

                    <Divider />

                    <SecurityFilterExpressionComp required securityFilterExpression={screenerModel.query} onChange={onScreenerQueryChange} includeFinancials={true} />

                </Stack>

            </CardContent>
        </Card>

    </Stack>
}

type ScreenerScopeProps = {
    screenerScope: ScreenerScope
    onChange: (screenerScope: ScreenerScope) => void;
}

export function ScreenerScopeComp({ screenerScope, onChange }: ScreenerScopeProps) : React.JSX.Element{

    const [industries, setIndustries] = useState<string[]>([]);

    const fetchIndustries = async () => {
        const allIndustries = await AppContext.getInstance().apiClientFactory.marketDataAPIClient.listIndustries();
        setIndustries(allIndustries);
    }

    useEffect(() => {
        fetchIndustries();
    }, []);

    const onSecurityTypeChange = (selectOption: SelectOption) => {
        screenerScope.setSecurityType(SecurityType[selectOption.value]);
        onChange(screenerScope);
    }

    const onIndustryChange = (selectedOptions: SelectOption[]) => {
        const selectedIndustries: string[] = selectedOptions.map(so => so.value);
        screenerScope.industries = selectedIndustries;
        onChange(screenerScope);
    }

    const industryOptions: SelectOption[] = selectOptionsFromString(industries);

    return <Stack spacing={2}>

        <RadioField helperText="Specify whether you want to screen for stocks or ETFs." label="Security Type" options={selectOptionsFromString(["STOCK", "ETF"])} value={screenerScope.securityType} onSelectChange={onSecurityTypeChange} />

        {screenerScope.securityType == SecurityType.STOCK &&
            <MultiSelectField label="Industies" options={industryOptions} value={screenerScope.industries!} onSelectChange={onIndustryChange} />
        }

    </Stack>
}


