/** @format */

import React, { Component } from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Chip,
    DialogContent,
    Stack,
    Typography,
} from '@mui/material';
import InflyTable from 'containers/common/InflyTable';
import A from 'containers/common/Anchor';
import {
    ClassAPIClient,
    Game,
    GameAPIClient,
    Message,
    ProfessorClass,
    User,
    UserError,
    UserGameScore,
} from 'api';
import { AppContext } from 'util/appContext';
import InflyDialog from 'containers/common/InflyDialog';
import DialogButton from 'containers/common/DialogButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { InflyMessage } from 'containers/common/InflyMessage';
import CommonUtil from 'util/CommonUtil';
import { ActionButton } from 'containers/common/ActionButton';
import { LoadingCard } from 'containers/common/LoadingCard';
import InviteFriends from 'containers/common/InflyInviteFriends';

interface StudentSummaryProps {
    currentClass: ProfessorClass;
    currentGame?: Game; // optional becuase class may not have any game
    onMessage: (message: Message) => void;
}

interface StudentSummaryState {
    classStudents: User[];
    userGameScores?: UserGameScore[];
    reminderDialogOpen: boolean;
    pageSize: number;
}

export default class StudentSummary extends Component<StudentSummaryProps, StudentSummaryState> {
    classAPIClient: ClassAPIClient;
    gameAPIClient: GameAPIClient;

    constructor(props: StudentSummaryProps) {
        super(props);
        this.classAPIClient = AppContext.getInstance().classApiClient;
        this.gameAPIClient = AppContext.getInstance().gameApiClient;
        this.state = {
            classStudents: undefined!,
            reminderDialogOpen: false,
            pageSize: 25,
        };
    }

    componentDidMount = async () => {
        await this._loadStudentsAndScores();
    };

    async _loadStudentsAndScores() {
        try {
            let classStudents: User[] = await this.classAPIClient.listOfStudents(
                this.props.currentClass.name
            );
            let userGameScores: UserGameScore[] | undefined = undefined;

            if (this.props.currentGame) {
                userGameScores = await this.gameAPIClient.getAllGameScores(
                    this.props.currentGame.gameId
                );
            }

            this.setState({ classStudents, userGameScores });
        } catch (error) {
            this.props.onMessage(Message.fromError(error));
        }
    }

    getUnregisteredStudents = (): User[] => {
        // unregister students don't have firstname, lastname, joindate etc
        return this.state.classStudents.filter((u) => u.joinDate === undefined);
    };

    getRegisteredStudentColDef = () => {
        return [
            { field: 'email', headerName: 'Email', type: 'string' },
            { field: 'username', headerName: 'Username', type: 'string' },
            { field: 'firstname', headerName: 'First Name', type: 'string' },
            { field: 'lastname', headerName: 'Last Name', type: 'string' },
            {
                field: 'portfolio',
                headerName: 'Portfolio',
                formatFunction: (value: UserGameScore) => {
                    if (value?.portfolioName === undefined) return <i>-</i>;
                    return (
                        <A href={`/portfolio/INVESTFLY/${value.portfolioId}`} newTab={true}>
                            {value.portfolioName}
                        </A>
                    );
                },
            },
            { field: 'gamename', headerName: 'Game Name', type: 'string' },
            { field: 'gamepoints', headerName: 'Game Points' },
            {
                field: 'action',
                sort: false,

                formatFunction: (value: any) => (
                    <DialogButton
                        onClick={() => this.removeStudent(value.email)}
                        color='error'
                        buttonType={'iconButton'}
                        dialogtitle='Cancel Invitation'
                        startIcon={<DeleteForeverIcon fontSize='small' />}
                        dialogprompt={`Are you sure, you want to cancel the invitation to [${
                            value.email ?? ''
                        }] ?`}
                    />
                ),
                headerName: 'Action',
            },
        ];
    };

    getRegisteredStudentRowDef = () => {
        let students: User[] = this.state.classStudents;
        let scoresByUserName = new Map<string, UserGameScore>();
        if (this.state.userGameScores) {
            scoresByUserName = new Map(
                this.state.userGameScores.map((gameScore) => [gameScore.username, gameScore])
            );
        }

        //rows
        return students?.map((item: User, index: number) => {
            const userGameSummary = scoresByUserName.get(item.username);
            return {
                id: item.email,
                email: item.email,
                username: item.username ? item.username : '-',
                firstname: item.firstName ? item.firstName : '-',
                lastname: item.lastName ? item.lastName : '-',
                portfolio: userGameSummary,
                gamename: userGameSummary?.gameName ?? '-',
                gamepoints: userGameSummary?.totalGamePoints ?? 0,
                action: item,
            };
        });
    };

    onReminderDialogReturn = async (studentRemoved: boolean) => {
        // called from reminder dialog
        let classStudents = this.state.classStudents;
        if (studentRemoved) {
            classStudents = await this.classAPIClient.listOfStudents(this.props.currentClass.name);
        }
        this.setState({ reminderDialogOpen: false, classStudents });
    };

    removeStudent = async (email: string) => {
        // called from table delete icon This could be registered or un-registered email. If registered, student may also have usergamescore. So we need to load both
        const success: boolean = await this.classAPIClient.removeStudent(
            this.props.currentClass.name,
            email
        );
        await this._loadStudentsAndScores();
        let message = success
            ? Message.success('Student removed')
            : Message.error('Failed to remove selected student');
        this.props.onMessage(message);
    };

    onInviteSuccess = async (message: Message) => {
        let classStudents: User[] = await this.classAPIClient.listOfStudents(
            this.props.currentClass.name
        );
        this.setState({ classStudents });
        this.props.onMessage(message);
    };

    downloadAsCSV = (event: any) => {
        event.preventDefault();

        // header for CSV file.
        const headers = {
            username: 'Username',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            portfolio: 'Portfolio',
            gameName: 'Game Name',
            gamePoints: 'Game Points',
        };
        const { userGameScores } = this.state;
        //get registered users only
        const registeredStudent = this.state.classStudents;
        //rows of data
        const formattedData = registeredStudent?.map((item: User, index: number) => {
            const userGameSummary = userGameScores?.find((gps) => gps.username === item.username);
            return {
                username: item.username ? item.username : '-',
                firstName: item.firstName ? item.firstName : '-',
                lastName: item.lastName ? item.lastName : '-',
                email: item.email,
                portfolio: userGameSummary?.portfolioId ? userGameSummary.portfolioName : '-',
                gameName: userGameSummary?.gameName ?? '-',
                gamePoints: userGameSummary?.totalGamePoints ?? '-',
            };
        });

        CommonUtil.exportCSVFile(headers, formattedData, 'Students Summary');
    };

    render() {
        if (this.state.classStudents === undefined) {
            return <LoadingCard title='Class Students'  />;
        }

        const unregisteredStudents = this.getUnregisteredStudents();
        const numUnregisterd = unregisteredStudents.length;
        const studentsRows = this.getRegisteredStudentRowDef();
        const columnDefs = this.getRegisteredStudentColDef();

        return (
            <>
                {this.state.reminderDialogOpen && (
                    <ReminderDialog
                        currentClass={this.props.currentClass}
                        open={this.state.reminderDialogOpen}
                        onReturn={this.onReminderDialogReturn}
                    />
                )}

                <Card>
                    <CardHeader
                        title={
                            <Stack direction={'row'} gap={1} alignItems='center'>
                                <Typography variant={'h6'} display='inline'>
                                    Student Summary{' '}
                                </Typography>
                                <Chip
                                    size={'small'}
                                    label={numUnregisterd}
                                    onClick={() => {
                                        if (numUnregisterd > 0) {
                                            this.setState({ reminderDialogOpen: true });
                                        }
                                    }}
                                />
                                Unregistered Student(s)
                                <InviteFriends
                                    label='Invite Students'
                                    professorClass={this.props.currentClass}
                                    onSuccess={this.onInviteSuccess}
                                />
                            </Stack>
                        }
                        titleTypographyProps={{ variant: 'body1' }}
                        action={
                            <Button id='downloadResult' onClick={this.downloadAsCSV}>
                                {' '}
                                Download CSV
                            </Button>
                        }
                    ></CardHeader>

                    <InflyTable
                        title={'Registered Student'}
                        rows={studentsRows}
                        columns={columnDefs}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(value: number) =>
                            this.setState({
                                pageSize: value,
                            })
                        }
                        columnNameForSearch={'username'}
                        multipleSelection={false}
                    />
                </Card>
            </>
        );
    }
}

interface ReminderDialogProps {
    currentClass: ProfessorClass;
    open: boolean;
    onReturn: (studentRemoved: boolean) => void;
}

interface ReminderDialogState {
    unregisteredStudents: User[];
    selectedEmails: string[];
    message?: Message;

    // In this  particular case, we want to display success message in the dialog isntead of bubbling the the message all the way to ProfesssorDashboard
    // thats why props.onReturn() is not immediately called from Action buttons (send reminger and cancel invitation)
    studentRemoved: boolean;
    pageSize: number;
}

class ReminderDialog extends React.Component<ReminderDialogProps, ReminderDialogState> {
    classAPIClient: ClassAPIClient;

    constructor(props: ReminderDialogProps) {
        super(props);
        this.classAPIClient = AppContext.getInstance().classApiClient;
        this.state = {
            unregisteredStudents: [],
            studentRemoved: false,
            selectedEmails: [],
            pageSize: 20,
        };
    }

    async componentDidMount() {
        try {
            const allStudents: User[] = await this.classAPIClient.listOfStudents(
                this.props.currentClass.name
            );
            const unregisteredStudents = allStudents.filter((u) => u.joinDate === undefined);
            this.setState({ unregisteredStudents });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    }

    onSelectionChange = (rowId: string[] | string, isSelected: boolean, selectedRowId: any) => {
        let selectedEmails = rowId as string[];
        this.setState({ selectedEmails });
    };

    sendReminderEmail = async () => {
        try {
            const res = await this.classAPIClient.inviteStudentsEmails(
                this.props.currentClass.name,
                this.state.selectedEmails
            );
            const message = this.parseInvitationResponse(res);
            this.setState({ message, studentRemoved: false });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    };

    cancelInvitation = async () => {
        try {
            if (this.state.selectedEmails.length === 0) {
                throw new UserError('Please select one or more emails from below');
            }

            let failedEmails: string[] = [];
            let studentRemoved = true;
            for (let email of this.state.selectedEmails) {
                let success = await this.classAPIClient.removeStudent(
                    this.props.currentClass.name,
                    email
                );
                studentRemoved = studentRemoved || success;
                if (!success) {
                    failedEmails.push(email);
                }
            }
            const allStudents: User[] = await this.classAPIClient.listOfStudents(
                this.props.currentClass.name
            );
            const unregisteredStudents = allStudents.filter((u) => u.joinDate === undefined);

            let message =
                failedEmails.length === 0
                    ? Message.success('Cancelled selected invitations')
                    : Message.error('Cancelling invitations failed for ' + failedEmails.join());

            this.setState({ message, unregisteredStudents, studentRemoved });
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
        }
    };

    parseInvitationResponse(res: Map<string, string[]>): Message {
        let message: Message = Message.info('Unknown error occurred. Please contact us');

        if (res.hasOwnProperty('invited') && res['invited'].length > 0)
            message = Message.success('Invitation Sent to [' + res['invited'].join(',') + ']. ');

        if (res.hasOwnProperty('exists') && res['exists'].length > 0)
            message = Message.info('Already invited [' + res['exists'].join(',') + ']. ');

        if (res['success'] && res['success'].length > 0)
            message = Message.success('Invitation Sent to [' + res['success'].join(',') + ']. ');

        if (res.hasOwnProperty('failed') && res['failed'].length > 0)
            message = Message.error('Could not invite [' + res['failed'].join(',') + '].');

        return message;
    }

    getUnRegisteredStudentRowDef = () => {
        return this.state.unregisteredStudents?.map((item: User, index: number) => {
            return {
                id: item.email,
                email: item.email,
            };
        });
    };

    getUnRegisteredStudentColDef = () => {
        return [
            { field: 'id', headerName: 'ID', hide: true },
            { field: 'email', headerName: 'Email', type: 'string' },
        ];
    };

    render(): React.ReactNode {
        const unregisteredRows = this.getUnRegisteredStudentRowDef();
        const unregistedColumns = this.getUnRegisteredStudentColDef();

        return (
            <InflyDialog
                open={this.props.open}
                onClose={() => this.props.onReturn(this.state.studentRemoved)}
                title={'Manage Unregistered Students'}
            >
                <DialogContent>
                    <Stack gap={2}>
                        <InflyMessage
                            message={this.state.message}
                            onClose={() => this.setState({ message: undefined })}
                        />

                        <ButtonGroup>
                            <DialogButton
                                onClick={() => this.sendReminderEmail()}
                                disabled={
                                    this?.state?.selectedEmails === undefined ||
                                    this?.state?.selectedEmails?.length === 0
                                }
                                color='primary'
                                label={`Resend Invitation`}
                                dialogtitle='Resend Invitation'
                                dialogprompt={`Are you sure, you want to resend the invitation?`}
                            />

                            <DialogButton
                                onClick={() => this.cancelInvitation()}
                                color='secondary'
                                disabled={
                                    this?.state?.selectedEmails === undefined ||
                                    this?.state?.selectedEmails?.length === 0
                                }
                                label={`Cancel Invitation`}
                                dialogtitle='Cancel Invitation'
                                dialogprompt={`Are you sure you want to cancel invitation?`}
                            />
                        </ButtonGroup>

                        <InflyTable
                            title={'UnRegistered Student'}
                            width='700px'
                            onSelectionChange={this.onSelectionChange}
                            rows={unregisteredRows}
                            columns={unregistedColumns}
                            pageSize={this.state.pageSize}
                            onPageSizeChange={(value: number) =>
                                this.setState({
                                    pageSize: value,
                                })
                            }
                            multipleSelection={true}
                        />
                    </Stack>
                </DialogContent>
            </InflyDialog>
        );
    }
}
