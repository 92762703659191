import { Box, Button, Card, CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { InflyIcon } from "./InflyIcon";
import DialogButton from "./DialogButton";
import { LabelAndValue } from "./LabelAndValue";
import A from "./Anchor";

export type ListItemDisplayCardProps = {
    iconName: string,
    title: string,
    // either href or action must be specified, what to do when title is clicked
    href?: string,
    action?: () => void,
    deleteBtn?: React.JSX.Element,
    cloneBtn?: React.JSX.Element
    description?: string,
    labelValues?: Map<string, any>
}

export function ListItemDisplayCard({iconName, title, href, action, deleteBtn, cloneBtn, description, labelValues}: ListItemDisplayCardProps): React.JSX.Element {
    return <Card raised elevation={5}>
        <CardContent>
            <Stack spacing={2}>

            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={2}>
                    <InflyIcon  name={iconName} />
                    {href &&
                        <A href={href}>{title}</A>
                    }
                    {action && 
                        <Button variant={'text'} onClick={action}>{title}</Button>
                    }               
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    {cloneBtn &&
                        cloneBtn
                    }
                    {deleteBtn &&
                        deleteBtn
                    }

                </Stack>
    
            </Stack>

            {description && description.length > 0 && 
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography fontStyle={"italic"} variant='body2'>{description}</Typography>
                </Stack>
            }

            <Divider />

            {labelValues && labelValues.size > 0 &&
                <>
                <Stack>
                        {Array.from(labelValues.entries()).map( ([label, value], index) =>  <LabelAndValue key={label} label={label} value={value} />)}
                    </Stack>
                    <Divider />
                </>
                    
                    
            }


            
            </Stack>



        </CardContent>
    </Card>
}