/** @format */

import React, { Component } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Message, UserAPIClient } from 'api';
import { ActionButton } from 'containers/common/ActionButton';
import withRouter from 'containers/HOC/WithRouter';
import { RouteProps } from 'util/commonProps';
import { AppContext } from '../../../util/appContext';
import { InflyMessage } from '../../common/InflyMessage';
import { InflyIcon } from '../../common/InflyIcon';

interface ChangePasswordProps extends RouteProps {
    onSubmit?: Function;
}

interface ChangePasswordState {
    password: string;
    confirmPassword: string;
    confirmPasswordError?: string;
    message?: Message;
}

class ChangePasswordForm extends Component<ChangePasswordProps, ChangePasswordState> {
    userApiClient: UserAPIClient;

    constructor(props: ChangePasswordProps) {
        super(props);
        this.userApiClient = AppContext.getInstance().userApiClient;
        this.state = {
            password: '',
            confirmPassword: '',
        };
    }

    changePassword = async () => {
        let { password, message } = this.state;
        try {
            message = await this.userApiClient.changePassword(password);
            if (typeof this.props.onSubmit === 'function') {
                this.props.onSubmit();
            }
        } catch (error) {
            message = Message.fromError(error);
        } finally {
            this.setState({ message });
        }
    };

    onConfirmPasswordChangeCallback = () => {
        if (
            this.state.confirmPassword.length > 0 &&
            this.state.password !== this.state.confirmPassword
        ) {
            this.setState({ confirmPasswordError: "Password doesn't match" });
        } else {
            this.setState({ confirmPasswordError: undefined });
        }
    };

    render() {
        const { password, confirmPassword } = this.state;
        const params = new URLSearchParams(this.props.location.search);
        const resetCodeStatus = params.get('resetcodestatus');

        return (
            <>
                <Paper
                    elevation={0}
                    square={false}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 'max-content',
                        paddingInline: 3,
                    }}
                >
                    <Stack
                        sx={{
                            width: { md: 'max-content', xs: '100%' },
                            height: 'max-content',
                            gap: 1,
                        }}
                    >
                        <Typography variant={'h5'} align={'center'}>
                            Change Password{' '}
                        </Typography>
                        <br />
                        {this.props.location.pathname === '/changepassword' &&
                            resetCodeStatus === 'success' && (
                                <Typography variant={'subtitle1'} align={'center'}>
                                    Please provide and confirm new password below
                                </Typography>
                            )}
                        <InflyMessage
                            message={this.state.message}
                            onClose={() => this.setState({ message: undefined })}
                        />
                        <form>
                            <br />
                            <Grid container spacing={2} justifyContent='flex-start'>
                                <Grid xs={12} item>
                                    <FormControl component='fieldset' fullWidth>
                                        <FormLabel component='legend'> New Password</FormLabel>
                                        <TextField
                                            type='password'
                                            placeholder='New Password'
                                            onChange={(event) =>
                                                this.setState({ password: event.target.value })
                                            }
                                            value={password}
                                            id={'password'}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} item>
                                    <FormControl
                                        component='fieldset'
                                        fullWidth
                                        error={Boolean(this.state.confirmPasswordError)}
                                    >
                                        <FormLabel component='legend'>Confirm Password</FormLabel>
                                        <TextField
                                            placeholder='Confirm Password'
                                            type='password'
                                            helperText={this.state.confirmPasswordError}
                                            error={Boolean(this.state.confirmPasswordError)}
                                            onChange={(event) => {
                                                this.setState(
                                                    {
                                                        confirmPassword: event.target.value,
                                                        message: undefined,
                                                        confirmPasswordError: undefined,
                                                    },
                                                    () => this.onConfirmPasswordChangeCallback()
                                                );
                                            }}
                                            value={confirmPassword}
                                            id={'confirmPassword'}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} item>
                                    {this.props.location.pathname === '/changepassword' && (
                                        <Button
                                            sx={{ marginBlock: 1 }}
                                            size={'small'}
                                            variant='text'
                                            onClick={(event: any) => {
                                                event.preventDefault();
                                                this.props.navigate('/');
                                            }}
                                        >
                                            <InflyIcon name={'goBack'} fontSize={'small'} />
                                            &nbsp;Login
                                        </Button>
                                    )}
                                    <ActionButton
                                        color={'primary'}
                                        disabled={
                                            this.state.message?.isError() ||
                                            Boolean(this.state.confirmPasswordError)
                                        }
                                        onClick={this.changePassword}
                                        id='changePasswordBtn'
                                        label='Change Password'
                                    >
                                    </ActionButton>
                                </Grid>
                            </Grid>
                            <br />
                        </form>
                    </Stack>
                </Paper>
            </>
        );
    }
}

export default withRouter(ChangePasswordForm);
