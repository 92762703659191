import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import React from "react";
import CommonUtil from "util/CommonUtil";
import { BlinkTextComponent } from "./BlinkComponet";

interface SingleValudDisplayCardProps {
    title: string;
    prefix?: string;
    value: number | string;
    showBlink?: true | undefined;
}

export function SingleValudDisplayCard(props: SingleValudDisplayCardProps) {
    return (
        // setting height 100% will make sure that all cards are equal in height
        <Card elevation={3}>
            <CardActionArea>
                <CardContent>
                    <Typography textAlign={'center'} gutterBottom>
                        {props.showBlink ? (
                            <BlinkTextComponent
                                value={props.value as number}
                                isTextColor={props.prefix === '%' ? true : undefined} >
                                {props.value}&nbsp;{props.prefix}
                            </BlinkTextComponent>
                        ) : (
                            <Typography component={'span'}>
                                {props.prefix === '$' && props.prefix}&nbsp;
                                {CommonUtil.numberWithCommas(props.value)}
                            </Typography>
                        )}
                    </Typography>
                    <Typography
                        variant='subtitle1'
                        textAlign={'center'}
                        fontSize={'1rem'}
                        color={'textSecondary'}
                        gutterBottom
                    >
                        {props.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}