/** @format */

import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import {
    ApiClientFactory,
    MarketDataAPIClient,
    Message,
    QuotesRequest,
    Security,
    SecurityType,
    StockQuote,
} from 'api';
import React from 'react';
import { BlinkTextComponent } from 'containers/common/BlinkComponet';
import { AppContext } from 'util/appContext';
import { ErrorCard } from 'containers/common/ErrorCard';
import { LoadingCard } from 'containers/common/LoadingCard';
import A from 'containers/common/Anchor';
import withScreenSize from 'containers/HOC/WithScreenSize';


export interface IndexStatsState {
    ready: boolean;
    quotes: StockQuote[];
    message?: Message;
}
export interface IndexStatsProps {
    onClick?: (name: any) => void;
}
export class IndexStatsComp extends React.Component<IndexStatsProps, IndexStatsState> {
    marketDataApiClient: MarketDataAPIClient;
    timer: any;

    constructor(props: IndexStatsProps) {
        super(props);
        this.marketDataApiClient = ApiClientFactory.getInstance().marketDataAPIClient;
        this.state = { quotes: [], ready: false };
    }

    init = async () => {
        await this.fetchQuotes();
    };

    async fetchQuotes() {
        let { message, quotes } = this.state;
        try {
            let req = new QuotesRequest();
            req.addSecurity(Security.getInstance('^DJI', SecurityType.INDEX));
            req.addSecurity(Security.getInstance('^GSPC', SecurityType.INDEX));
            req.addSecurity(Security.getInstance('^IXIC', SecurityType.INDEX));
            req.addSecurity(Security.getInstance('^RUT', SecurityType.INDEX));

            let res = await this.marketDataApiClient.getQuotes(req);
            quotes = res.quotes as StockQuote[];
        } catch (error) {
            message = Message.fromError(error);
        } finally {
            this.setState({ quotes: quotes, message: message, ready: true }, () =>
                this.startTimer()
            );
        }
    }

    getName(symbol: string): string {
        if (symbol === '^DJI') return 'DOW';
        if (symbol === '^GSPC') return 'SP500';
        if (symbol === '^IXIC') return 'NASDAQ';
        if (symbol === '^RUT') return 'RUSSEL200';
        return symbol;
    }
    getSymbol(name: string): string {
        if (name === 'DOW') return '^DJI';
        if (name === 'SP500') return '^GSPC';
        if (name === 'NASDAQ') return '^IXIC';
        if (name === 'RUSSEL200') return '^RUT';
        return name;
    }

    getQuoteForIndex(symbol: string): StockQuote | undefined {
        return this.state.quotes.find((quote) => quote.symbol === symbol);
    }

    componentDidMount() {
        this.init();
    }
    startTimer = () => {
        if (!this.timer) {
            this.timer = setInterval(() => {
                this.fetchQuotes();
            }, 10000);
        }
    };
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        if (!this.state.ready) {
            return <LoadingCard title={'Index Stats'}  />;
        }

        if (this.state.quotes.length === 0 && this.state.message?.isError()) {
            return (
                <ErrorCard title={'Index Stats'} message={this.state.message.message}
                />
            );
        }


        return (
            <Card>
                <Stack
                    direction={'row'}
                    flexWrap={'wrap'}
                    spacing={1}
                    justifyContent={'space-around'}
                    alignItems={'stretch'}
                >
                    <IndexStatCard
                        onClick={(name) =>
                            this.props.onClick && this.props.onClick(this.getSymbol(name))
                        }
                        name={this.getName('^DJI')}
                        key={'DJI'}
                        quote={this.getQuoteForIndex('^DJI')}
                    />

                    <IndexStatCard
                        onClick={(name) =>
                            this.props.onClick && this.props.onClick(this.getSymbol(name))
                        }
                        name={this.getName('^GSPC')}
                        key={'GSPC'}
                        quote={this.getQuoteForIndex('^GSPC')}
                    />

                    <IndexStatCard
                        onClick={(name) =>
                            this.props.onClick && this.props.onClick(this.getSymbol(name))
                        }
                        name={this.getName('^IXIC')}
                        key={'IXIC'}
                        quote={this.getQuoteForIndex('^IXIC')}
                    />

                    <IndexStatCard
                        onClick={(name) =>
                            this.props.onClick && this.props.onClick(this.getSymbol(name))
                        }
                        name={this.getName('^RUT')}
                        key={'RUT'}
                        quote={this.getQuoteForIndex('^RUT')}
                    />
                </Stack>
            </Card>
        );
    }
}


export interface IndexCardProps {
    onClick?: (name: any) => void;
    name: string;
    quote?: StockQuote; // if its undefined, its still loading
}

export function IndexStatCard(props: IndexCardProps) {
    return (
        <Box
            display={'flex'}
            sx={{ cursor: 'pointer' }}
            onClick={() => props.onClick && props.onClick(props.name)}
        >
            {props.quote ? (
                <>
                    <A>{props.name}</A>: &nbsp;
                    {props.quote?.lastPrice} &nbsp;

                    {props.quote.todayChangePct !== undefined &&
                                        <BlinkTextComponent
                                        value={props.quote?.todayChange as number}
                                        isTextColor={true}
                                        align={'left'}
                                    >
                                        <Typography component={'span'} fontWeight={'bold'} display={'block'}>
                                            {`(${props.quote?.todayChangePct}%)`}
                                        </Typography>
                                    </BlinkTextComponent>
                    }

                </>
            ) : (
                <CircularProgress />
            )}
        </Box>
    );
}

