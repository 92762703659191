/** @format */

import {
    ButtonGroup,
    Card,
    CardHeader,
    Grid,
    IconButton,
    Typography,
    Button,
    SwipeableDrawer,
    Box,
} from '@mui/material';
import { Stack } from '@mui/system';
import withNavBarAndAuth from 'containers/HOC/NavBarWrapper';
import {
    Message,
    BrokerAccount,
    Security,
    SecurityType,
    PortfolioIdentifierName,
} from 'api';
import DocumentHeader from 'containers/common/DocumentHeader';
import React, { useEffect, useState } from 'react';
import { AppContext } from 'util/appContext';
import  { AlertList } from '../Alert/MyAlerts';
import { StockListCard } from './StockListCard';
import { IndexStatsComp } from './IndexStats';
import SessionUtil from 'util/SessionUtil';
import { InflyMessage } from 'containers/common/InflyMessage';
import {StockDetailComp} from 'containers/StockDetails/StockDetail';
import A from 'containers/common/Anchor';
import { PortfolioDisplayCard } from './PortfolioDisplayCard';
import ReactGA from 'react-ga4';

export function Dashboard(): React.JSX.Element {


    const [selectedSecurity, setSecuritySecurity] = useState<Security>(Security.getInstance('^GSPC', SecurityType.INDEX))

    // undefined indicates uninitialized state, null indicates user does not have any portfolio or account
    const [selectedPortfolioId, setSelectedPortfolioId] = useState<PortfolioIdentifierName | undefined | null>();

    const [message, setMessage] = useState<Message | undefined>();

    async function loadAndSelectFirstPortfolio() {
        let portfolioApiClient = AppContext.getInstance().portfolioApiClient;

        let portfolios = await portfolioApiClient.getMyPortfolios();
        if (portfolios.length > 0) {
            let selectedPortfolio = portfolios[0];
            let selectedPortfolioId: PortfolioIdentifierName = selectedPortfolio.toPortfolioIdentifierName();
            setSelectedPortfolioId(selectedPortfolioId);
            SessionUtil.saveInLocalStorage("dashboard-portfolio-select", JSON.stringify(selectedPortfolioId));

        } else {
            // if user has virtual portfolio and one of it is selected, we can avoid api calls to get broker accounts
            let brokerApiClient = AppContext.getInstance().brokerApiClient;
            let brokerAccounts: BrokerAccount[] = await brokerApiClient.getMyConnectedBrokers();
            if (brokerAccounts.length > 0) {
                let selectedAccount = brokerAccounts[0]
                let selectedPortfolioId: PortfolioIdentifierName = selectedAccount.toPortfolioIdentifierName()
                setSelectedPortfolioId(selectedPortfolioId);
                SessionUtil.saveInLocalStorage("dashboard-portfolio-select", JSON.stringify(selectedPortfolioId));

            } else {
                setSelectedPortfolioId(null); // user does not have any portfolio or account
            }
        }
    }

    useEffect(() => {

        ReactGA.event('dashboard_page_view', {});

        let portfolioIdenntifierJson: string | null = SessionUtil.getFromLocalStorage("dashboard-portfolio-select");
        if (portfolioIdenntifierJson) {
            // this portfolio may be deleted and obsolete. The only way to prevent this it to make another getPortfolio() API call to check it, 
            // but this will make one extra useless API call in most normal case when the portfolio is still valid. So, we will let the PortfolioDisplayCard 
            // which has to make getPortfolio() to load details catch the exception and call onPortfolioChange if its not valid 
            let selectedPortfolioId: PortfolioIdentifierName = JSON.parse(portfolioIdenntifierJson);
            setSelectedPortfolioId(selectedPortfolioId);
        } else {
            loadAndSelectFirstPortfolio();
        }

    }, [])

    const onPortfolioChange = (newPortfolioId: PortfolioIdentifierName | null) => {
        setSelectedPortfolioId(newPortfolioId);
        if (newPortfolioId) {
            SessionUtil.saveInLocalStorage("dashboard-portfolio-select", JSON.stringify(newPortfolioId));
        } else {
            // null is passed when session stored portfolio has been deleted and there aren't any more portfolio
            SessionUtil.removeFromLocalStorage("dashboard-portfolio-select");
        }
    }

    const handleSetMessage = (message: Message | undefined) => {
        setMessage(message)
    }

    const handleStockSelection = (symbol: string, securityType: SecurityType) => {
        setSecuritySecurity(Security.getInstance(symbol, securityType));
    }

    return <DocumentHeader title={'Dashboard'}>

        <Grid container spacing={1}>


            <Grid item xs={12}>
                <Typography gutterBottom={false}>
                    <A href='https://www.youtube.com/watch?v=mKuHglZMvhU&feature=youtu.be' newTab>
                        New to Investfly? Watch Tutorial
                    </A>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <IndexStatsComp onClick={(symbol) => handleStockSelection(symbol, SecurityType.INDEX)} />
            </Grid>

            <Grid item xs={12}>
                {/* This is snackbar message, so its placement in code does not matter, but if we decide to display as regular message, this is probably the right place */}
                <InflyMessage message={message} onClose={() => setMessage(undefined)} isSnackBar />
            </Grid>


            <Grid item xs={12} md={4} xl={3}>
                <Stack gap={1}>
                    <PortfolioDisplayCard selectedPortfolioId={selectedPortfolioId} onPortfolioChange={onPortfolioChange} onMessage={handleSetMessage} />
                    {/* This is technically not correct becaues the symbol may be ETF instead of STOCK. See comment of StockListCard, there is no way of knowing, it must be revalidated later */}
                    <StockListCard onStockSelection={(symbol) => handleStockSelection(symbol, SecurityType.STOCK)} />
                    <AlertList onMessage={handleSetMessage} />
                </Stack>
            </Grid>


            <Grid item xs={12} md={8} xl={9}>
                <StockDetailComp  selectedSymbol={selectedSecurity.symbol} selectedPortfolioId={selectedPortfolioId?? undefined } onMessage={handleSetMessage}  />

            </Grid>

        </Grid>

    </DocumentHeader>

}


export default withNavBarAndAuth(Dashboard);
