import { Button, Card, CardContent, CardHeader, Paper, Stack, Typography } from "@mui/material";
import { Message, StrategyAPIClient } from "api";
import MonacoEditor from '@monaco-editor/react';
import React from "react";
import { AppContext } from "util/appContext";
import { ActionButton } from "containers/common/ActionButton";
import { InflyIcon } from "containers/common/InflyIcon";
import A from "containers/common/Anchor";

type CustomCodeStrategyCompProps = {
    strategyId: number,
    pythonCode: string,
    onCodeChange: (pythonCode: string, runBacktest: boolean) => void;
    onMessage: (message: Message) => void;
}

export function CustomCodeStrategyComp({strategyId, pythonCode, onCodeChange, onMessage}: CustomCodeStrategyCompProps) : React.JSX.Element{

    async function onCodeChangeSave(runBacktest: boolean){
        let error = validatePythonCode();
        if(error){
            onMessage(Message.error(error));
        }else{
            try{
                let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
                let newCode = await strategyApiClient.updateStrategyCode(strategyId, pythonCode);
                onCodeChange(newCode,runBacktest);
                onMessage(Message.success("Strategy Code Changes Saved"))
            }catch(ex){
                onMessage(Message.fromError(ex));
            }
        }

    }

    function validatePythonCode(): string|undefined{
        if(!pythonCode || pythonCode.length === 0){
            return "Python code is required";
        }
        if(!pythonCode.match(/class\s+\w+\(TradingStrategy\)/)){
            return "Code must consist of a Python class that extends TradingStrategy";
        }
    }

    return (<>
        <Card>
            <CardHeader title='Strategy Code' action={<Stack direction={'row'} spacing={1}>
                    <ActionButton label="Save" onClick={() => onCodeChangeSave(false)}></ActionButton>
                    <ActionButton label="Save And Backtest" onClick={() => onCodeChangeSave(true)}></ActionButton>
                </Stack>}/>
            <CardContent>

                <Stack gap={2}>

                    <Paper elevation={5}>
                            <Stack gap={3} padding={4}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant='h6'> Tip</Typography>
                                    <InflyIcon name='tip' />
                                </Stack>
                                <Typography variant='body2'>
                                   We recommand to use investfly-sdk to develop Python based strategies and indicators locally using your favorite IDE (Pycharm, VSCode) and upload to Investfly using CLI. See  <A target="_blank" href="https://www.investfly.com/guides/docs/index.html">investfl-sdk api docs</A>  for more details
                                </Typography>
                            </Stack>
                        </Paper>

                <MonacoEditor
                            value={pythonCode}
                            language='python'
                            width={`100%`}
                            height={800}
                            theme={'vs-dark'}
                            options={{
                                // lineHeight: 1,
                                // tabIndex: 4,
                                glyphMargin: false,
                                matchBrackets: 'always', // type is  'never' | 'near' | 'always'
                                lineNumbers: 'on', //type is LineNumbersType
                                minimap: { enabled: false }, // type is IEditorMinimapOptions
                                scrollbar: {
                                    // vertical: 'auto',
                                    // horizontal: 'hidden',
                                    // handleMouseWheel: true,
                                }, //type is IEditorScrollbarOptions
                                // tabSize: 4,
                                wordWrap: 'on',
                            }}
                            onChange={(value, event) => {
                                onCodeChange(value as string, false);
                            }}
                        />
                </Stack>
            </CardContent>
        </Card>
    </>);
}