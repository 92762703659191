/** @format */

import {
    Box,
    Button,
    ButtonGroup,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {
    MarketDataAPIClient,
    Message,
    OptionChain,
    OptionExpiry,
    OptionQuote,
    OptionType,
    SecurityType,
    Stock,
    StockOption,
    StockQuote,
    SymbolSummaryInfo,
    TradeOrder,
    TradeType,
    UserError,
} from 'api';
import React, { Component } from 'react';
import CommonUtil from 'util/CommonUtil';
import { ActionButton } from 'containers/common/ActionButton';
import { DeleteForever } from '@mui/icons-material';
import {SelectField} from 'containers/common/SelectField';
import { SymbolField } from 'containers/common/SymbolField';
import InflyHelperText from 'containers/common/InflyHelperText';
import { AppContext } from 'util/appContext';
import { TradeSharesType } from './Trade';
import { OptionQuoteTable } from './OptionTradeForm';
import { ITextField } from 'containers/common/ITextField';
import StockOptionChain from 'containers/common/StockOptionChain';

interface MultiLegTradeProps {
    tradeOrders: TradeOrder[];
    presetSecurity?: SymbolSummaryInfo;
    onTradeOrderChange: (tradeOrder: TradeOrder[]) => void;
    onMessage: (message: Message) => void; // passes up message that might generate as user interacts with the form
}

export interface MultiLegTradeState {
    tickerSymbol?: SymbolSummaryInfo;
    tradeSharesType: TradeSharesType;

    quote?: StockQuote;
}

export default class MultilegTrade extends Component<MultiLegTradeProps, MultiLegTradeState> {
    marketDataApiClient: MarketDataAPIClient;

    constructor(props: MultiLegTradeProps) {
        super(props);
        this.marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        this.state = {
            tradeSharesType: TradeSharesType.NO_OF_SHARES,
        };
    }
    componentDidMount(): void {
        if (this.props.presetSecurity && this.props.presetSecurity.symbol) {
            this.setState({ tickerSymbol: this.props.presetSecurity });
        }
    }

    onSymbolSelect = async (tickerSymbol: SymbolSummaryInfo) => {
        const quote = await this.marketDataApiClient.getStockQuote(tickerSymbol.symbol);
        this.setState({ quote: quote, tickerSymbol: tickerSymbol });
    };
    onSymbolClear = () => {
        this.setState({
            quote: undefined,
        });
    };

    onAddStockLegOpen = () => {
        if (this.state.tickerSymbol === undefined)
            throw new UserError('Please select stock symbol first');

        let multilegOrders = this.props.tradeOrders;

        const stockLegOrder = new TradeOrder();
        stockLegOrder.security = new Stock();
        stockLegOrder.security.securityType = this.state.tickerSymbol.type;
        stockLegOrder.security.symbol = this.state.tickerSymbol.symbol;

        // add to the beginning of the array. Stock Leg is the first order in the array
        multilegOrders.unshift(stockLegOrder);
        this.props.onTradeOrderChange(multilegOrders);
    };

    removeStockLeg = () => {
        if (!this.hasStockLeg()) {
            throw new UserError('Stock Leg does not exist');
        }
        let existingOrders = this.props.tradeOrders;
        existingOrders.shift(); // remove first element from array
        this.props.onTradeOrderChange(existingOrders);
    };

    _updateStockLegOrder = (updater: (stockLegOrder: TradeOrder) => void) => {
        if (!this.hasStockLeg()) {
            throw new UserError('Stock Leg does not exist');
        }

        let multilegOrders = this.props.tradeOrders;
        const stockLegOrder = multilegOrders[0];
        updater(stockLegOrder);
        this.props.onTradeOrderChange(multilegOrders);
    };

    hasStockLeg = (): boolean => {
        let multilegOrders = this.props.tradeOrders;
        if (multilegOrders.length === 0) return false;
        return multilegOrders[0].security.isStockOrETF();
    };

    setStockLegQuantityOrAmount = (num: number) => {
        this._updateStockLegOrder((stockLegOrder) => {
            if (this.state.tradeSharesType === TradeSharesType.NO_OF_SHARES) {
                stockLegOrder.quantity = num;
            } else {
                stockLegOrder.maxAmount = num;
            }
        });
    };

    setStockLegTradeType = (tradeType: TradeType) => {
        this._updateStockLegOrder((stockLegOrder) => (stockLegOrder.tradeType = tradeType));
    };

    addOptionLeg = (optionQuote: OptionQuote) => {
        const stockOption: StockOption = StockOption.createStockOption(optionQuote);
        const optionTradeOrder = new TradeOrder();
        optionTradeOrder.security = stockOption;
        let multilegOrders = this.props.tradeOrders;
        multilegOrders.push(optionTradeOrder);
        this.props.onTradeOrderChange(multilegOrders);
        CommonUtil.scrollToElement('optionLegsContainer', 'smooth');
    };

    removeOptionLeg = (optionSymbol: string) => {
        let multilegOrders = this.props.tradeOrders;
        multilegOrders = multilegOrders.filter((order) => order.security.symbol !== optionSymbol);
        this.props.onTradeOrderChange(multilegOrders);
    };

    setOptionLegTradeQuantity = (optionSymbol: string, quantity: number) => {
        let multilegOrders = this.props.tradeOrders;
        let optionOrder = multilegOrders.find((order) => order.security.symbol === optionSymbol);
        if (!optionOrder) throw new UserError('Option Leg ' + optionSymbol + ' cannot be found');
        optionOrder.quantity = quantity;
        this.props.onTradeOrderChange(multilegOrders);
    };

    setOptionLegTradeType = (optionSymbol: string, tradeType: TradeType) => {
        let multilegOrders = this.props.tradeOrders;
        let optionOrder = multilegOrders.find((order) => order.security.symbol === optionSymbol);
        if (!optionOrder) throw new UserError('Option Leg ' + optionSymbol + ' cannot be found');
        optionOrder.tradeType = tradeType;
        this.props.onTradeOrderChange(multilegOrders);
    };

    render() {
        const hasStockLeg = this.hasStockLeg();
        return (
            <Box component={'form'} width={'100%'} pt={2}>
                <Grid container direction='column' flexWrap={'nowrap'} spacing={2}>
                    <Grid item xs={12}>
                        <Stack
                            gap={1}
                            alignItems={{ xs: 'stretch', md: 'center' }}
                            flexDirection={{ md: 'row', xs: 'column' }}
                        >
                            <FormLabel component='legend'>Enter&nbsp;Symbol</FormLabel>
                            <SymbolField
                                includeIndex={false}
                                selectedSymbol={this.state.tickerSymbol}
                                onClear={this.onSymbolClear}
                                onChange={this.onSymbolSelect}
                            />
                            <ButtonGroup>
                                <Button
                                    fullWidth
                                    disabled={this.state.tickerSymbol === undefined || hasStockLeg}
                                    onClick={(event: any) => {
                                        event.preventDefault();
                                        this.onAddStockLegOpen();
                                    }}
                                >
                                    Add&nbsp;Stock&nbsp;Leg
                                </Button>
                            </ButtonGroup>
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Typography variant={'body1'} gutterBottom>
                            Equity Leg
                        </Typography>
                        <Divider />

                        {hasStockLeg ? (
                            <Box component={'div'} mt={2}>
                                <StockLeg
                                    tradeOrder={this.props.tradeOrders[0]}
                                    tradeSharesType={this.state.tradeSharesType}
                                    onTradeSharesTypeChange={(tradeSharesType) =>
                                        this.setState({ tradeSharesType: tradeSharesType })
                                    }
                                    onQuantityAmountChange={this.setStockLegQuantityOrAmount}
                                    onTradeTypeChange={this.setStockLegTradeType}
                                    onLegRemove={this.removeStockLeg}
                                />
                            </Box>
                        ) : (
                            <InflyHelperText
                                info={
                                    'Select symbol, click on "Add Stock Leg" button to add stock leg'
                                }
                            />
                        )}
                    </Grid>

                    <Grid item id='optionLegsContainer'>
                        <Typography variant={'body1'} gutterBottom>
                            Option Leg
                        </Typography>
                        <Divider />
                        <Stack gap={1} mt={2}>
                            {this.props?.tradeOrders && this.props?.tradeOrders.length > 0 ? (
                                this.props?.tradeOrders
                                    ?.filter(
                                        (tradeOrder) =>
                                            tradeOrder.security.securityType === SecurityType.OPTION
                                    )
                                    ?.map((tradeOrder: TradeOrder) => {
                                        let optionSymbol = tradeOrder.security.symbol;
                                        return (
                                            <OptionLeg
                                                key={optionSymbol}
                                                tradeOrder={tradeOrder!}
                                                onQuantityChange={(quantity) =>
                                                    this.setOptionLegTradeQuantity(
                                                        optionSymbol,
                                                        quantity
                                                    )
                                                }
                                                onTradeTypeChange={(tradeType) =>
                                                    this.setOptionLegTradeType(
                                                        optionSymbol,
                                                        tradeType
                                                    )
                                                }
                                                onLegRemove={(optionSymbol: string) =>
                                                    this.removeOptionLeg(optionSymbol)
                                                }
                                            />
                                        );
                                    })
                            ) : (
                                <InflyHelperText
                                    info={
                                        'Select symbol, click on "Get Option Chain" button to add option chain'
                                    }
                                />
                            )}
                        </Stack>
                    </Grid>
                    <Grid item />
                    {this.state.tickerSymbol?.symbol && (
                        <Grid item xs={12} id='OptionChainList'>
                            <StockOptionChain
                                symbol={this.state.tickerSymbol.symbol}
                                onOptionQuoteSelect={this.addOptionLeg}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        );
    }
}

interface StockLegOrderProps {
    tradeOrder: TradeOrder;
    tradeSharesType: TradeSharesType;
    onTradeSharesTypeChange: (tradeSharesType: TradeSharesType) => void;
    onQuantityAmountChange: (quantityOrAmount: number) => void;
    onTradeTypeChange: (tradeType: TradeType) => void;
    onLegRemove: () => void;
}

class StockLeg extends Component<StockLegOrderProps, {}> {
    constructor(props: StockLegOrderProps) {
        super(props);
    }

    getTradeTypeOption() {
        return Object.keys(TradeType).map((t) => {
            return { label: t.replace('_', ' '), value: t.toString() };
        });
    }

    render() {
        const props = this.props;
        const { tradeOrder, tradeSharesType } = props;
        const tradeSecurity = tradeOrder.security;

        const tradeTypeOptions = this.getTradeTypeOption();

        return (
            <Stack>
                <Grid
                    container
                    justifyContent={{ xs: 'center', md: 'flex-start' }}
                    alignItems={{ md: 'center', xs: 'flex-start' }}
                    spacing={2}
                >
                    <Grid item md={2}>
                        <FormLabel component='legend'>Symbol</FormLabel>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                row
                                value={tradeSharesType}
                                onChange={(event: any) => {
                                    props.onTradeSharesTypeChange(
                                        TradeSharesType[event.target.value]
                                    );
                                }}
                            >
                                <FormControlLabel
                                    value={TradeSharesType.NO_OF_SHARES}
                                    control={<Radio id='quantityRadioBtn' />}
                                    label='No of Shares'
                                    labelPlacement='end'
                                />

                                <FormControlLabel
                                    value={TradeSharesType.MAX_AMOUNT}
                                    control={<Radio id='amountRadioBtn' />}
                                    label='Cash Amount'
                                    labelPlacement='end'
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item md={4}>
                        <FormLabel component='legend'>Position</FormLabel>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent={{ xs: 'center', md: 'flex-start' }}
                    alignItems={{ md: 'center', xs: 'flex-start' }}
                    spacing={2}
                >
                    <Grid item md={2}>
                        <Chip label={tradeSecurity.symbol} sx={{ width: 'fit-content' }} />
                    </Grid>

                    <Grid item md={6}>
                        <FormControl component={'fieldset'} fullWidth>
                            <ITextField
                                id='tradeSharesTxtField'
                                type={'number'}
                                size={'small'}
                                placeholder={
                                    tradeSharesType === TradeSharesType.NO_OF_SHARES
                                        ? 'Enter Quantity'
                                        : 'Enter Amount'
                                }
                                value={
                                    tradeSharesType === TradeSharesType.NO_OF_SHARES
                                        ? tradeOrder.quantity
                                        : tradeOrder.maxAmount
                                }
                                onChange={(value?: number | string) => {
                                    props.onQuantityAmountChange(value as number);
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={4}>
                        <FormControl component={'fieldset'} fullWidth>
                            <SelectField
                                label={'Order'}
                                sx={{ width: '100%' }}
                                id='orderTypeOptions'
                                value={tradeOrder.tradeType}
                                onChange={(event: any) => {
                                    props.onTradeTypeChange(TradeType[event.target.value]);
                                }}
                                key='orderTypeOptions_select'
                                options={tradeTypeOptions}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Stack>
        );
    }
}

interface OptionLegOrderProps {
    tradeOrder: TradeOrder;
    onQuantityChange: (quantity: number) => void;
    onTradeTypeChange: (tradeType: TradeType) => void;
    onLegRemove: (optionSymbol: string) => void;
}

const OptionLeg = (props: OptionLegOrderProps) => {
    const { tradeOrder } = props;
    const tradeSecurity: StockOption = tradeOrder.security as StockOption;
    const tradeTypeOptions = CommonUtil.getTradeTypeOption();

    return (
        <Grid container alignItems={'center'} spacing={1}>
            <Grid item>
                <Chip label={tradeSecurity.symbol} />
            </Grid>
            <Grid item>
                <Chip
                    label={
                        tradeSecurity.expiry.year +
                        '-' +
                        tradeSecurity.expiry.month +
                        '-' +
                        tradeSecurity.expiry.day
                    }
                />
            </Grid>
            <Grid item>
                <Chip label={tradeSecurity.strikePrice} />
            </Grid>
            <Grid item>
                <Chip label={tradeSecurity.optionType} />
            </Grid>
            <Grid item>
                <FormControl component={'fieldset'} fullWidth>
                    <ITextField
                        type={'number'}
                        id='tradeSharesTxtField'
                        placeholder={'Enter Quantity'}
                        size={'small'}
                        value={tradeOrder?.quantity}
                        onChange={(value?: number) => {
                            props.onQuantityChange(value as number);
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl component={'fieldset'} fullWidth>
                    <SelectField
                        label={'Trade'}
                        id='tradeTypeOptions'
                        value={tradeOrder.tradeType}
                        onChange={(event: any) => {
                            props.onTradeTypeChange(TradeType[event.target.value]);
                        }}
                        key='orderTypeOptions_select'
                        options={tradeTypeOptions}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <IconButton
                    color='error'
                    size={'small'}
                    onClick={() => props.onLegRemove(tradeSecurity.symbol)}
                >
                    <DeleteForever fontSize={'small'} />
                </IconButton>
            </Grid>
        </Grid>
    );
};
