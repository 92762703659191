import { InvestflyUtil } from "../../utils/investflyUtil";

export class StockNews {

	publishedDate: Date;
	author: string;
	title: string;
	description: string;
	ampUrl: string;
	articleUrl: string;
	imageUrl: string;
	
    static parseJSON(obj: {[index:string]: any} ): StockNews {
        let news = new StockNews();
        Object.assign(news, obj);
        news.publishedDate = InvestflyUtil.strToDate(obj.publishedDate)!;
        return news;
    }

}