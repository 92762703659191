/** @format */

import { Message, MessageType } from 'api';
import React from 'react';
import Alert from '@mui/material/Alert';
import { Box, Snackbar } from '@mui/material';
import ScrollHere from './ScrollHere';

export interface Props {
    id?: string;
    message?: Message | null;
    onClose?: () => void;
    isSnackBar?: true;
}
type Severity = 'success' | 'error' | 'warning' | 'info';

export function InflyMessage(props: Props) {
    // the 'severity' property of Alert component only accept 'Severity' type.
    let severity: Severity = 'warning';
    if (props.message) {
        switch (props.message.type) {
            case MessageType.ERROR:
                severity = 'error';
                break;
            case MessageType.INFO:
                severity = 'info';
                break;
            case MessageType.WARN:
                severity = 'warning';
                break;
            case MessageType.SUCCESS:
                severity = 'success';
                break;
            default:
                severity = 'warning';
        }
    }
    if (props.message === undefined || props.message === null) return <></>;
    if (props?.isSnackBar)
        return (
            <>
                <Box m={1}>
                    <Snackbar open={true} onClose={props.onClose} autoHideDuration={6000}>
                        <Alert onClose={props.onClose} severity={severity}>
                            {props.message?.message}
                        </Alert>
                    </Snackbar>
                </Box>
            </>
        );
    return (
        <Box mb={1} id={props?.id ?? 'AlertMessage'}>
            <Alert onClose={props.onClose} severity={severity}>
                {props.message?.message}
            </Alert>
        </Box>
    );
}
