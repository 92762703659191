import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Stack,
} from '@mui/material';
import UpgradeCheckButton from 'containers/common/UpgradeCheckButton';
import {
    Message,
    StockAlert,
} from 'api';
import React, { Component, useEffect, useState } from 'react';

import DialogButton from 'containers/common/DialogButton';
import { ErrorCard } from 'containers/common/ErrorCard';
import { LoadingCard } from 'containers/common/LoadingCard';
import { AppContext } from 'util/appContext';
import { InflyIcon } from 'containers/common/InflyIcon';
import { ListItemDisplayCard } from 'containers/common/ListItemDisplayCard';
import { AlertFormComp, NewAlertBtn } from './AlertForm';
import { GeneratedAlertsCountLink } from './TriggeredStocks';
import EmptyCard from 'containers/common/EmptyCard';


interface AlertListProps {
    onMessage: (message?: Message) => void;
}

export function AlertList({onMessage}: AlertListProps): React.JSX.Element {
    
    const [alertList, setAlertList] = useState<StockAlert[]>();
    const [ready, setReady] = useState(false);
    const [editAlert, setEditAlert] = useState<StockAlert>();

    async function loadAlerts(){
        let stockAlertApiClient = AppContext.getInstance().stockAlertApiClient;
        try{
            let myAlerts = await stockAlertApiClient.listMyAlerts();
            setAlertList(myAlerts);
        }catch(error){
            onMessage(Message.fromError(error));
        }finally{
            setReady(true);
        }
    }
    useEffect(() => {
        loadAlerts();
    }, [])

    if(!ready){
        return <LoadingCard title='My Stock Alerts' />
    }

    if(!alertList){
        return <ErrorCard message='Error loading alerts'/>
    }


    const onNewAlertDialogReturn = (stockAlert: StockAlert, message?: Message) => {
        setAlertList([stockAlert, ...alertList])
        if(message){
            onMessage(message);
        }
    }

    if(alertList.length == 0){
        return <EmptyCard title="Stock Alerts"  emptyMsg="Set up alerts to get notified when any stock in your list satisfy a condition">
            <Box>
                <NewAlertBtn curAlertCount={alertList.length} onComplete={onNewAlertDialogReturn}/>
            </Box>     
        </EmptyCard>
    }

    const onDeleteConfirm = async (alertId: number) => {
        let stockAlertApiClient = AppContext.getInstance().stockAlertApiClient;
        let msg = await stockAlertApiClient.deleteStockAlert(alertId)
        loadAlerts();
        onMessage(msg);
    }

    const createDeleteButton = (stockAlert: StockAlert): React.JSX.Element => {
        return <DialogButton
                dialogprompt='Are you sure you want to delete this alert?'
                dialogtitle={'Delete Alert'}
                color={'error'}
                startIcon={
                    <InflyIcon  name='delete'  fontSize={'small'}  />
                }
                buttonType={'iconButton'}
                onClick={() => onDeleteConfirm(stockAlert.alertId)}
            />
    }

    const createGeneratedAlertLink = (stockAlert: StockAlert): React.JSX.Element => {
        return <GeneratedAlertsCountLink stockAlert={stockAlert} onMessage={onMessage}/>;
    }

    const createLabelValuesMap = (stockAlert: StockAlert ): Map<string, any> => {
        let labelValues = new Map();
        labelValues.set("Generated Alerts", createGeneratedAlertLink(stockAlert));
        return labelValues;
    }


    const alertClicked = (stockAlert: StockAlert) => {
        setEditAlert(stockAlert);
    }
    const onEditDialogComplete = (stockAlert: StockAlert, message?: Message) => {
        let index = alertList.findIndex(alert => alert.alertId === stockAlert.alertId);
        alertList.splice(index, 1, stockAlert)
        setAlertList([...alertList]);
        if(message){
            onMessage(message);
        }
        setEditAlert(undefined); // close dialog
    }

    const onEditDialogCancel = () => {
        setEditAlert(undefined); // close dialog
    }

    return <> 

        {editAlert && <AlertFormComp stockAlert={editAlert} onSubmit={onEditDialogComplete} onCancel={onEditDialogCancel}/>}

        <Card>
        <CardHeader title='Stock Alerts' action={<NewAlertBtn curAlertCount={alertList.length} onComplete={onNewAlertDialogReturn}/>} />
        <CardContent>
            <Stack gap={1} >
                {alertList.map(alert => {
                    return <ListItemDisplayCard 
                                key={alert.alertId}
                                title={alert.alertName} 
                                action={() => alertClicked(alert)}
                                iconName={'alerts'} 
                                deleteBtn={createDeleteButton(alert)}
                                labelValues={createLabelValuesMap(alert)}
                                />
                })}
            </Stack>
        </CardContent>
    </Card>
    </>
}


