import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { BrokerAccount, Message, NotFoundException, Portfolio, PortfolioIdentifierName } from "api";
import { TradingStrategyModel } from "api/models/strategy/TradingStrategyModel";
import { TradierConnectButton } from "containers/Broker/ConnectBrokerBtn";
import { CreateEditPortfolioBtn } from "containers/Portfolio/CreateEditPortfolio";
import { BlinkTextComponent } from "containers/common/BlinkComponet";
import { DataDisplayCard } from "containers/common/DataDisplayCard";
import EmptyCard from "containers/common/EmptyCard";
import { InflyIcon } from "containers/common/InflyIcon";
import { LoadingCard } from "containers/common/LoadingCard";
import { PortfolioSelect } from "containers/common/PortfolioSelect";
import UpgradeCheckButton from "containers/common/UpgradeCheckButton";
import React, { useEffect, useState } from "react";
import { AppContext } from "util/appContext";

export interface PortfolioDisplayCardProps {
    // SelectedPortfoli state has to be lifted up to dashboard page component so that Trade button in stock details can have access to portfolio
    selectedPortfolioId: PortfolioIdentifierName | undefined | null,

    // null is passed when the session-stored-portfolio has been deleted and user does not have any more portfolio
    onPortfolioChange: (selectedPortfolioId: PortfolioIdentifierName|null) => void;
    onMessage: (message: Message) => void;
}

export function PortfolioDisplayCard({ selectedPortfolioId, onPortfolioChange, onMessage }: PortfolioDisplayCardProps): React.JSX.Element {

    const [selectedPortfolio, setSelectedPortfolio] = useState<Portfolio | null | undefined>()
    const [deployedStrategy, setDeployedStrategy] = useState<TradingStrategyModel | null>()


    async function loadPortfolioDetails(selectedPortfolioId: PortfolioIdentifierName) {

        let portfolioApiClient = AppContext.getInstance().portfolioApiClient;
        let strategyApiClient = AppContext.getInstance().strategyApiClient;

        try{

            let selectedPortfolioDetails: Portfolio = await portfolioApiClient.getPortfolio(selectedPortfolioId.portfolioId, selectedPortfolioId.brokerType);
            setSelectedPortfolio(selectedPortfolioDetails);
    
            let strategy: TradingStrategyModel | null = await strategyApiClient.findStrategy(selectedPortfolioId.portfolioId, selectedPortfolioId.brokerType)
            setDeployedStrategy(strategy);

        }catch(error){
            if (error instanceof NotFoundException){
                // this could happen if selectedPortfolio stored in session has been deleted. We handle it here instead of Dashboard to avoid making extra API call in normal case
                let portfolios = await portfolioApiClient.getMyPortfolios();
                if(portfolios.length > 0){
                    onPortfolioChange(portfolios[0].toPortfolioIdentifierName());
                }else{
                    let brokerApiClient = AppContext.getInstance().brokerApiClient;
                    let brokerAccounts: BrokerAccount[] = await brokerApiClient.getMyConnectedBrokers();
                    if(brokerAccounts.length > 0){
                        onPortfolioChange(brokerAccounts[0].toPortfolioIdentifierName());
                    }else{
                        onPortfolioChange(null);
                    }
                }
            }else{
                onMessage(Message.fromError(error));
            }
        }
    }

    useEffect(() => {
        if (selectedPortfolioId) {
            loadPortfolioDetails(selectedPortfolioId);
        } else if (selectedPortfolioId === null) {
            setSelectedPortfolio(null);
        }
    }, [selectedPortfolioId])


    const onPortfolioSelect = (newPortfolioId: PortfolioIdentifierName) => {
        loadPortfolioDetails(newPortfolioId)
        onPortfolioChange(newPortfolioId);
    }


    const onPortfolioCreate = (newPortfolio: Portfolio, message: Message|undefined) => {
        setSelectedPortfolio(newPortfolio);
        setDeployedStrategy(null); // when portfolio is just created, strategy won't be deployed to it
        onPortfolioChange(newPortfolio.toPortfolioIdentifierName());
        if(message){
            onMessage(message);
        }
    }


    if (selectedPortfolio === undefined || selectedPortfolioId === undefined) {
        return <LoadingCard title="My Portfolios" />
    }

    if (selectedPortfolio === null || selectedPortfolioId === null) {
        return <EmptyCard title="My Portfolios"
            emptyMsg="You do not have any virtual portfolio or connected broker account">

            <Stack direction={'row'} gap={2}>
                <CreateEditPortfolioBtn btnLabel="New Portfolio" onComplete={onPortfolioCreate} />
                <Divider orientation="vertical" variant="middle" />
                <TradierConnectButton />
            </Stack>
        </EmptyCard>
    }



    let details: Map<string, any> = new Map();
    details.set("BuyingPower ($)", selectedPortfolio?.buyingPower);
    details.set(
        'PortfolioValue ($)',
        <BlinkTextComponent value={selectedPortfolio?.currentValue ?? 0} isTextColor={true}>
            &nbsp; {selectedPortfolio?.currentValue}
        </BlinkTextComponent>
    );
    details.set('CashBalance ($)', selectedPortfolio?.cashBalance ?? 'NaN');
    details.set(
        'Automation Status',
        <Stack direction={'row'} gap={1} alignItems={'center'}>
            <InflyIcon
                name={deployedStrategy ? 'link' : 'unLink'}
                color={deployedStrategy ? 'success' : 'error'}
            />
            <Typography component={'span'} variant={'body2'}>
                {deployedStrategy?.strategyName ?? ''}
            </Typography>
        </Stack>
    );

    return <>

        <DataDisplayCard
            title={<PortfolioSelect currentSelection={selectedPortfolioId} onPortfolioSelect={onPortfolioSelect} />}
            data={details}
            cardActionHeader={<IconButton

                href={`/portfolio/${selectedPortfolio.broker}/${selectedPortfolio.portfolioId}`}
                target='_blank'
            >
                <InflyIcon
                    name='openInNew'
                    fontSize='small'
                />
            </IconButton>}
        />

    </>
}