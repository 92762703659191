/** @format */

import React, { useEffect, useRef } from 'react';
import styles from './BlinkComponent.module.css';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { CardHeader, CardHeaderProps } from '@mui/material';

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    if (ref.current) return ref.current;
    return value;
}

interface BlinkTextComponentProps extends TypographyProps {
    value: number;
    isTextColor?: true;
}

export function BlinkTextComponent(props: BlinkTextComponentProps) {
    const prevTodaysChange: number = usePrevious(props.value as number) as number;

    function getAnimateClassName() {
        return prevTodaysChange < (props.value as number)
            ? styles.increaseAnimate
            : prevTodaysChange > (props.value as number)
            ? styles.decreaseAnimate
            : 'normal';
    }

    function getStyleColor() {
        return props.value === 0 ? 'inherit' : (props.value as number) > 0 ? 'common.green' : 'common.red';
    }

    const { value, isTextColor, ...typographyRestProps } = props;

    return (
        <>
            <Typography
                variant={'body2'}
                color={isTextColor ? getStyleColor() : 'inherit'}
                {...typographyRestProps}
                className={getAnimateClassName()}
            >
                {props.children}
            </Typography>
        </>
    );
}

interface BlinkCardHeaderComponentProps extends CardHeaderProps {
    value: number;
}

export function BlinkCardHeaderComponent(props: BlinkCardHeaderComponentProps) {
    const prevTodaysChange: number = usePrevious(props.value as number) as number;

    function getAnimateClassName() {
        return prevTodaysChange < (props.value as number)
            ? 'increaseAnimate'
            : prevTodaysChange > (props.value as number)
            ? 'decreaseAnimate'
            : 'normal';
    }
    const { value, ...cardHeaderProps } = props;
    return (
        <>
            <CardHeader {...cardHeaderProps} />
        </>
    );
}
