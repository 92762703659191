

import { Button, Stack } from "@mui/material"
import { Message, OPERATION, Portfolio, StrategyAPIClient } from "api"
import { TradingStrategyModel } from "api/models/strategy/TradingStrategyModel"
import { UnDeployStrategyDialogComp } from "containers/Strategies/StrategyDeploymentCard"
import A from "containers/common/Anchor"
import AuthorizedElement from "containers/common/AuthorizedElement"
import { DataDisplayCard } from "containers/common/DataDisplayCard"
import InflyDialog from "containers/common/InflyDialog"
import { InflyMessage } from "containers/common/InflyMessage"
import { SelectField, SelectOption } from "containers/common/SelectField"
import UpgradeCheckButton from "containers/common/UpgradeCheckButton"
import React from "react"
import { useEffect, useState } from "react"
import { AppContext } from "util/appContext"

type PortfolioAutomationCardProps = {
    portfolio: Portfolio
    onDeployUndeployComplete: (message: Message) => void
}

export function PortfolioAutomationCard({portfolio, onDeployUndeployComplete}: PortfolioAutomationCardProps ): React.JSX.Element {

    const [deployDialogOpen, setDeployDialogOpen] = useState(false);
    const [undeployDialogOpen, setUndeployDialogOpen] = useState(false);

    // undefined = uninitialized state, null means no strategy deployed to this portfolio
    const [strategy, setStrategy] = useState<TradingStrategyModel|undefined|null>();

    useEffect( () => {
        async function findDeploymentInfo() {
            let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient; 
            let strategy: TradingStrategyModel|null = await strategyApiClient.findStrategy(portfolio.portfolioId, portfolio.broker)
            setStrategy(strategy)
        }
        findDeploymentInfo();

    }, [portfolio])

    function getDeployUndeployAction(): React.JSX.Element {
        if(strategy){
            return <AuthorizedElement  authorizedUsername={portfolio.userName} >
                <Stack spacing={1} direction={"row"}>
                    <Button onClick={e => setUndeployDialogOpen(true)}>Undeploy</Button>
                </Stack></AuthorizedElement>
        }else if (strategy == null) {
            return  <AuthorizedElement  authorizedUsername={portfolio.userName} >
                    <UpgradeCheckButton op={OPERATION.DEPLOY_STRATEGY} label='Deploy'>
                        <Button onClick={e => setDeployDialogOpen(true)}>Deploy</Button>
                    </UpgradeCheckButton> 
            </AuthorizedElement>
        }else{
            return <></>
        }
    }

    function getDeploymentInfo(): Map<string, string> {
        let info = new Map();
        if(strategy === undefined){
            return info;
        }
        info.set("Status", strategy ? "Deployed" : "Undeployed");
        if(strategy){
            info.set("Deployed Strategy", <A href={`/strategy/${strategy.strategyId}`} >{strategy.strategyName}</A> );
        }
        return info;
    }

    function onUndeployReturn(newStrategyModel: TradingStrategyModel){
        setUndeployDialogOpen(false);
        setStrategy(null)
        onDeployUndeployComplete(Message.success("Strategy undeployed from this portfolio"));
    }

    function onDeployReturn(deployedStrategy: TradingStrategyModel){
        setDeployDialogOpen(false);
        setStrategy(deployedStrategy);
        onDeployUndeployComplete(Message.success("Strategy deployed from this portfolio"));
    }

    

    return(
        <>

            { undeployDialogOpen && 
               <UnDeployStrategyDialogComp strategyModel={strategy!} onUnDeploy={onUndeployReturn} onCancel={() => setUndeployDialogOpen(false)} />
            }

            {deployDialogOpen && 
                <DeployStrategyDialogComp portfolio={portfolio} onDeploy={onDeployReturn} onCancel={() => setDeployDialogOpen(false)}/>
            }

            <DataDisplayCard title='Deployment' 
                    data={getDeploymentInfo()} 
                    cardActionHeader={getDeployUndeployAction()}/>
        </>)
    
}

// ------- Deploy Dialog -----



type DeployStrategyDialogProps = {
    portfolio: Portfolio;
    onDeploy: (strategyModel: TradingStrategyModel) => void;
    onCancel: () => void;
}

function DeployStrategyDialogComp({portfolio, onDeploy, onCancel}: DeployStrategyDialogProps): React.JSX.Element {


    const [message, setMessage] = useState<Message>();
    const [strategyList, setStrategyList] = useState<TradingStrategyModel[]>();
    const [selectedStrategy, setSelectedStrategy] = useState<number>()

    useEffect(() => {
        async function loadUndeployedStrategies(){
            let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
            let strategyList: TradingStrategyModel[] = await strategyApiClient.listStrategies();
            strategyList = strategyList.filter(st => st.portfolioId == undefined); // Filter out already deployed strategies
            setStrategyList(strategyList)
        }
        loadUndeployedStrategies()
    }, [])


    async function onExecute(){
        let strategyApiClient: StrategyAPIClient = AppContext.getInstance().strategyApiClient;
        if(selectedStrategy === undefined){
            setMessage(Message.error("Please select a strategy first"));
            return;
        }
        try{
            let newStrategyModel: TradingStrategyModel = await strategyApiClient.deployStrategy(selectedStrategy, portfolio.portfolioId, portfolio.broker)
            onDeploy(newStrategyModel);
        }catch(error){
            setMessage(Message.fromError(error));
        }
    }

    function createStrategyListOptions(): SelectOption[] {
        if(strategyList == undefined) return []
        return strategyList.map( st => {
            return {label: st.strategyName, value: st.strategyId}
        })
    }

    return <>
        <InflyDialog open title='Deploy Strategy' onClose={onCancel} onExecute={onExecute} okText='Deploy'>
            <Stack spacing={1}>
                <InflyMessage message={message} onClose={() => setMessage(undefined)} />
                <SelectField options={createStrategyListOptions()} onSelectChange={selectedOption => setSelectedStrategy(selectedOption.value)}/>
            </Stack>
        </InflyDialog>  
    </>
}
