import { Box, Card, CardContent, CardHeader, Stack } from "@mui/material";
import withNavBarAndAuth from "containers/HOC/NavBarWrapper";
import StockChart from "./StockChart";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ChartType, ZoomSettings } from "./ChartState";
import CommonUtil from "util/CommonUtil";
import { LightSummary } from "./StockSummary";
import { ErrorCard } from "containers/common/ErrorCard";



function ChartPage(): React.JSX.Element {

    let [searchParams, setSearchParams] = useSearchParams();

    let { symbol } = useParams();
    const zoomStr: string|null = searchParams.get("zoom");
    let zoomSettings:ZoomSettings|undefined = zoomStr !== null ? ZoomSettings[CommonUtil.getEnumKeyByEnumValue(ZoomSettings, zoomStr)!] : undefined;
    let chartTypeStr: string|null = searchParams.get("charttype");
    let chartType:ChartType|undefined = chartTypeStr !== null ? ChartType[CommonUtil.getEnumKeyByEnumValue(ChartType, chartTypeStr)!] : undefined;

    if(symbol == undefined){
        return <ErrorCard title="Stock Chart" message="Invalid URL. URL must be /chart/<SYMBOL>"/>
    }


    return <Stack>

        <LightSummary symbol={symbol!} />

    
            <Box component={'div'} id={'interactive-stock-chart'}>
                            <StockChart
                                mode={'dark'}
                                allowFullScreen={false}
                                zoomSettings={zoomSettings}
                                chartType={chartType}
                                symbol={symbol!}
                            />
                        </Box>
        
    </Stack>


}

export default withNavBarAndAuth(ChartPage);