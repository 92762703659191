/** @format */

import { Button, ButtonProps, Typography } from '@mui/material';
import { OPERATION, User, USER_LIMITS } from 'api';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import SessionUtil from 'util/SessionUtil';
import ReactGA from 'react-ga4';

interface UpgradeCheckButtonProps extends ButtonProps {
    op: OPERATION;
    currentCount?: number; // current no of portfolio, sreenrs etc. Passed when limit is a number, skipped when limit is a boolean
    children: ReactElement;
    label: string | React.ReactNode;
}

export default function UpgradeCheckButton(props: UpgradeCheckButtonProps) {
    const history = useNavigate();
    const { op, currentCount, children, label, ...btnProps } = props;
    const userInfo: User = SessionUtil.findUserInfo() as User;
    const userType = userInfo.userType;
    const limit: number | boolean = USER_LIMITS[userType][op];

    const isBoolean = typeof limit === 'boolean';
    const isOperationAllowed = isBoolean
        ? limit
        : currentCount === undefined || currentCount < limit;

    if (isOperationAllowed) {
        return props.children;
    } else {
        return (
            <Button
                color={'secondary'}
                variant='contained'
                {...btnProps}
                onClick={(event: any) => {
                    event.preventDefault();
                    ReactGA.event("upgradecheck_"+props.op.toLowerCase(), {});
                    history('/upgrade/pricing');
                }}
                sx={{
                    position: 'relative',
                    marginInline: 1,
                }}
            >
                {label}
                <Typography
                    sx={{
                        position: 'absolute',
                        zIndex: '100',
                        width: `calc(100% + 1ch)`,
                        height: '41px',
                        overflow: 'hidden',
                        right: '-5px',
                        top: '-5px',
                        textAlign: 'center',
                        margin: 0,
                    }}
                >
                    <Typography
                        component={'span'}
                        sx={{
                            display: 'block',
                            transform: 'rotate(-35deg)',
                            width: '50px',
                            top: '5px',
                            fontSize: '10px !important',
                            font: '11px/14px Roboto, helvetica, sans-serif',
                            fontWeight: 'normal',
                            color: '#FFF',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            background: 'linear-gradient(#FB7925 0%, #ED8225 100%)',
                            boxShadow: '0 4px 6px -5px rgba(0, 0, 0, 0.6)',
                            position: 'absolute',
                            right: `calc(100% - 7ch)`,
                            margin: '0 !important',
                            '& ::after, & ::before': {
                                content: `""`,
                                position: 'absolute',
                                bottom: '-3px',
                                zIndex: '-1',
                                borderLeft: ' 3px solid #FB7925',
                                borderRight: '3px solid transparent',
                                borderBottom: '3px solid transparent',
                                borderTop: '3px solid #FB7925',
                            },
                        }}
                    >
                        &nbsp; UP
                    </Typography>
                    <Typography
                        component={'span'}
                        sx={(theme) => ({
                            display: 'block',
                            transform: 'rotate(-35deg)',
                            width: '50px',
                            top: '23px',
                            fontSize: '10px !important',
                            font: '11px/14px Roboto, helvetica, sans-serif',
                            fontWeight: 'normal',
                            color: '#FFF',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            background: `linear-gradient(#FB7925 0%, #ED8225 100%)`,
                            boxShadow: '0 4px 6px -5px rgba(0, 0, 0, 0.6)',
                            position: 'absolute',
                            right: '-7px',
                            margin: '0 !important',
                            '& ::after, & ::before': {
                                content: `""`,
                                position: 'absolute',
                                bottom: '-3px',
                                zIndex: '-1',
                                borderLeft: ' 3px solid #FB7925',
                                borderRight: '3px solid transparent',
                                borderBottom: '3px solid transparent',
                                borderTop: '3px solid #FB7925',
                            },
                        })}
                    >
                        &nbsp; RQD
                    </Typography>
                </Typography>
            </Button>
        );
    }
}
