import { Message } from "../common/message";
import { TimeInterval, TimeUnit } from "../common/timeInterval";
import { PositionType } from "../portfolio/positionType";
import { OrderType } from "../portfolio/tradeOrder";
import { SecurityTriggerExpression } from "../trigger/SecurityTriggerExpression";
import { SecurityUniverseSelector } from "../trigger/SecurityUniverseSelector";


export class StandardStrategyConfig {

    scopeConfig: SecurityUniverseSelector;
    openPositionConfig: OpenPositionConfig;
    closePositionConfig: ClosePositionConfig;

    static createDefault(): StandardStrategyConfig {
        let defObj = new StandardStrategyConfig();
        defObj.scopeConfig = SecurityUniverseSelector.createDefault();
        defObj.openPositionConfig = OpenPositionConfig.createDefault();
        defObj.closePositionConfig = ClosePositionConfig.createDefault();
        return defObj;
    }

    clone(): StandardStrategyConfig {
        let clone = new StandardStrategyConfig();
        Object.assign(clone, this);
        clone.scopeConfig = this.scopeConfig.clone();
        clone.openPositionConfig = this.openPositionConfig.clone();
        clone.closePositionConfig = this.closePositionConfig.clone();
        return clone;
    }

    validate(): string|undefined {
        if(!this.scopeConfig){
            return "SecurityUniveseSelection is required";
        }
        let error = this.scopeConfig.validate();
        if(error){
            return error;
        }

        if(!this.openPositionConfig){
            return "Open Position Setting is required";
        }
        error = this.openPositionConfig.validate();
        if(error){
            return error;
        }

        if(!this.closePositionConfig){
            return "Close Position Setting is required";
        }
        error = this.closePositionConfig.validate();
        if(error){
            return error;
        }
    }

    static parseJSON(obj: {[index:string]: any} ): StandardStrategyConfig {
        let standardStrategyConfig = new StandardStrategyConfig()
        standardStrategyConfig.scopeConfig = SecurityUniverseSelector.parseJSON(obj.scopeConfig)
        standardStrategyConfig.openPositionConfig = OpenPositionConfig.parseJSON(obj.openPositionConfig)
        standardStrategyConfig.closePositionConfig = ClosePositionConfig.parseJSON(obj.closePositionConfig)
        return standardStrategyConfig;
    }



}

export class OpenPositionConfig {
    positionType: PositionType;
    orderType: OrderType;
    openExpression: SecurityTriggerExpression;
    percentAllocation: number;

    static createDefault(): OpenPositionConfig {
        let config = new OpenPositionConfig();
        config.positionType = PositionType.LONG;
        config.percentAllocation = 10;
        config.orderType = OrderType.MARKET_ORDER;
        config.openExpression = SecurityTriggerExpression.createDefault();
        return config;
    }

    clone(): OpenPositionConfig {
        let clone = new OpenPositionConfig();
        Object.assign(clone, this);
        if(this.openExpression){
            clone.openExpression = this.openExpression.clone();
        }
        return clone;
    }

    validate(): string|undefined {
        if(!this.positionType){
            return "PositionType is required";
        }
        if(!this.orderType){
            return "OrdeType is required";
        }
        if(!this.openExpression){
            return "OpenExpression is required";
        }
        if(!this.percentAllocation){
            return "Percent Allocation is required";
        }
        let errors: string[] = this.openExpression.validate();
        if(errors.length > 0){
            return errors.join(",");
        }
    }

    static parseJSON(obj: {[index:string]: any} ): OpenPositionConfig {
        let openPositionConfig = new OpenPositionConfig();
        openPositionConfig.percentAllocation = obj.percentAllocation;
        openPositionConfig.orderType = OrderType[obj.orderType];
        openPositionConfig.positionType = PositionType[obj.positionType];
        openPositionConfig.openExpression = SecurityTriggerExpression.parseJSON(obj.openExpression);
        return openPositionConfig;
    }
}

export class ClosePositionConfig {
    standardCloseCriteria: StandardCloseCriteria;
    orderType: OrderType;
    closeExpression?: SecurityTriggerExpression;

    static createDefault(): ClosePositionConfig {
        let def = new ClosePositionConfig();
        def.standardCloseCriteria = StandardCloseCriteria.createDefault();
        def.orderType = OrderType.MARKET_ORDER;
        return def;
    }

    clone(): ClosePositionConfig {
        let clone = new ClosePositionConfig();
        Object.assign(clone, this);
        if(this.standardCloseCriteria){
            clone.standardCloseCriteria = this.standardCloseCriteria.clone();
        }
        if(this.closeExpression){
            clone.closeExpression = this.closeExpression.clone();
        }
        return clone;
    }

    validate(): string | undefined{
        if(!this.standardCloseCriteria){
            return "Standard Close Criteria is required";
        }else{
            let standardCriteriaError = this.standardCloseCriteria.validate();
            if (standardCriteriaError){
                return standardCriteriaError;
            }
        }
        if(!this.orderType && this.closeExpression){
            return "OrderType is required when close expression is used";
        }
        if(this.closeExpression){
            let errors: string[] = this.closeExpression.validate();
            if(errors.length > 0){
                return errors.join(",");
            }
        }
    }

    static parseJSON(obj: {[index:string]: any} ):  ClosePositionConfig {
        let closePositionConfig = new ClosePositionConfig()
        closePositionConfig.standardCloseCriteria = StandardCloseCriteria.parseJSON(obj.standardCloseCriteria)
        closePositionConfig.orderType = OrderType[obj.orderType]
        if(obj.closeExpression){
            closePositionConfig.closeExpression = SecurityTriggerExpression.parseJSON(obj.closeExpression)
        }
        return closePositionConfig
    }
}

export class StandardCloseCriteria {
    targetProfit?: number;
    stopLoss?: number;
    trailingStop?: number;
    timeout?: TimeInterval; 

    static createDefault(): StandardCloseCriteria{
        let obj = new StandardCloseCriteria();
        obj.targetProfit = 5;
        obj.stopLoss = -5;
        obj.timeout = TimeInterval.fromValue(5, TimeUnit.DAYS);
        return obj;
    }

    clone(): StandardCloseCriteria {
        let clone = new StandardCloseCriteria();
        Object.assign(clone, this);
        if(this.timeout){
            clone.timeout = this.timeout.clone();
        }
        return clone;
    }

    validate(): string | undefined{
        if(this.targetProfit !== undefined && this.targetProfit <= 0){
            return "Target Profit must be positive";
        }
        if(this.stopLoss !== undefined && this.stopLoss >= 0){
            return "Stop Loss must be negative";
        }
        if(this.trailingStop !== undefined && this.trailingStop >= 0){
            return "Trailing Stop Loss must be negative";
        }
    }

    static parseJSON(obj: {[index:string]: any} ): StandardCloseCriteria {
        let standardCloseCriteria = new StandardCloseCriteria()
        Object.assign(standardCloseCriteria, obj)
        if (obj.timeout){
            standardCloseCriteria.timeout = TimeInterval.parseJSON(obj.timeout)
        }
        return standardCloseCriteria 
    }
}



