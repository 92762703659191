import { UserError } from "api";
import {  IndicatorParamSpec, IndicatorSpec, MetricType, ParamType } from "api/models/trigger/indicator";
import { SeriesOptionsType } from "highcharts";
import { ChartType } from "./ChartState";
import { DataParam } from "api/models/trigger/DataParam";

class IndicatorInfo {
    name: string;
    needsAxis: boolean;
    needsOHLC: boolean;
}


const SUPPORTED_INDICATORS = new Map<string, IndicatorInfo>();

// overlays
SUPPORTED_INDICATORS.set("SMA", {name:"SMA", needsAxis: false, needsOHLC: false});
SUPPORTED_INDICATORS.set("EMA", {name:"SMA", needsAxis: false, needsOHLC: false});
SUPPORTED_INDICATORS.set("BBAND", {name:"BBAND", needsAxis: false, needsOHLC: false});
SUPPORTED_INDICATORS.set("PSAR", {name:"PSAR", needsAxis: false, needsOHLC: true});

// all needs new axis
SUPPORTED_INDICATORS.set("RSI", {name:"RSI", needsAxis: true, needsOHLC: false});
SUPPORTED_INDICATORS.set("ATR", {name:"ATR", needsAxis: true, needsOHLC: true});
SUPPORTED_INDICATORS.set("CCI", {name:"CCI", needsAxis: true, needsOHLC: true});
SUPPORTED_INDICATORS.set("CMO", {name:"CMO", needsAxis: true, needsOHLC: false});
SUPPORTED_INDICATORS.set("WILLR", {name:"WILLR", needsAxis: true, needsOHLC: true});


export class ChartIndicator {

    // API https://api.highcharts.com/highstock/series.bb


    static getChartSeries(metric: DataParam): SeriesOptionsType {

        let name = metric.get(DataParam.INDICATOR);
        let period: number|undefined = metric.get("period");
        if (name === "SMA"){
            return {
                name: metric.getOrCreateAlias(),
                type: 'sma',
                linkedTo: 'main-series',
                params: {
                    period: period!
                 }
            }
        }

        if(name === "EMA") {
            return {
                name: metric.getOrCreateAlias(),
                type: 'ema',
                linkedTo: 'main-series',
                params: {
                    period: period!
                 }
            }
        }

        if(name === "RSI") {
            return {
                name: metric.getOrCreateAlias(),
                type: 'rsi',
                linkedTo: 'main-series',
                params: {
                    period: period!
                 }
            }
        }

        if(name === "WILLR") {
            return {
                name: metric.getOrCreateAlias(),
                type: 'williamsr',
                linkedTo: 'main-series',
                params: {
                    period: period!
                 }
            }
        }

        if(name === "ATR") {
            return {
                name: metric.getOrCreateAlias(),
                type: 'atr',
                linkedTo: 'main-series',
                params: {
                    period: period!
                 }
            }
        }

        if(name === "CCI") {
            return {
                name: metric.getOrCreateAlias(),
                type: 'cci',
                linkedTo: 'main-series',
                params: {
                    period: period!
                 }
            }
        }

        if(name === "CMO") {
            return {
                name: metric.getOrCreateAlias(),
                type: 'cmo',
                linkedTo: 'main-series',
                params: {
                    period: period!
                 }
            }
        }

        if(name === "BBAND") {
            return {
                name: metric.getOrCreateAlias(),
                type: 'bb',
                linkedTo: 'main-series',
                params: {
                    period: period!,
                    standardDeviation: metric.get("std_dev")!
                 }
            }
        }

        if(name === "PSAR") {
            return {
                name: metric.getOrCreateAlias(),
                type: 'psar',
                linkedTo: 'main-series',
                params: {
                    initialAccelerationFactor: metric.get("min_af")!,
                    maxAccelerationFactor: metric.get("max_af")!
                 }
            }
        }

        throw new UserError("Unknown indicator provided: " + name);
    }

    static needsNewAxis(metric: DataParam): boolean { 
        let name = metric.get(DataParam.INDICATOR);
        return SUPPORTED_INDICATORS.get(name)!.needsAxis;
    }

    static isSupported(name: string, chartType: ChartType): boolean {
        let indicatorInfo = SUPPORTED_INDICATORS.get(name);
        if(indicatorInfo === undefined) return false;
        return chartType !== 'line' || !indicatorInfo.needsOHLC;
    }
    

    static getChartMetricDefs(): IndicatorSpec[] {
        let result: IndicatorSpec[] = [];

        // THe param names must match "our" param names instead of high chart param names because MetricParamFieldInfo uses 
        // param naming convention to determine what options to show and what default value to set. E,g: param with name
        // "std_dev" has options of [1,2,3,4,5] whereas param with name "period" has different options
        
        let bb: IndicatorSpec = new IndicatorSpec();
        bb.name = "Bollinger Bands (BBAND)";
        bb.description = "Bollinger Bands are volatility indicators that consist of: an N-period moving average and upper and lower bands";
        bb.addParam("period", new IndicatorParamSpec());
        bb.addParam("std_dev",new IndicatorParamSpec());
        result.push(bb);



        return result;
        
    }
}