/** @format */

import React from 'react';
import {
    ArrowBack,
    ArrowBackIosNew,
    Brightness2Rounded,
    Cancel,
    CheckCircle,
    DashboardRounded,
    DeleteForever,
    Edit,
    Error,
    Games,
    Handshake,
    Info,
    LanguageOutlined,
    LineAxis,
    Link,
    LinkOff,
    ListAlt,
    Lock,
    Logout,
    ManageAccounts,
    MoreHoriz,
    Newspaper,
    NotificationsNoneRounded,
    Person,
    Public,
    RocketLaunch,
    SignalCellular0BarOutlined,
    ViewList,
    Warning,
    WbIncandescentRounded,
    Dataset,
    Apps,
    OpenInNew,
    Create,
    BarChart,
    Payments,
    ArrowForwardIos,
    AddAlert,
    AddToQueue,
} from '@mui/icons-material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CompareIcon from '@mui/icons-material/Compare';
import { SvgIconProps } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import StopIcon from '@mui/icons-material/Stop';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Icons = {
    copy: ContentCopyIcon,
    send: Payments,
    multiTrade: CurrencyExchangeIcon,
    save: SaveIcon,
    private: Lock,
    cancel: Cancel,
    public: Public,
    indicators: BarChart,
    myGameList: Games,
    link: Link,
    play: PlayArrowIcon,
    compare: CompareIcon,
    unLink: LinkOff,
    viewDetails: Info,
    addAlert: AddAlert,
    addToWatchlist: AddToQueue,
    deploy: RocketLaunch,
    autoTrade: AutoGraphIcon,
    strategies: LineAxis,
    discard: NotInterestedIcon,
    dashboard: DashboardRounded,
    dashboard_one: Apps,
    alerts: NotificationsNoneRounded,
    market: LanguageOutlined,
    watchList: ListAlt,
    portfolioDetails: ManageAccounts,
    more: MoreHoriz,
    marketNews: Newspaper,
    userProfile: Person,
    screeners: SignalCellular0BarOutlined,
    portfolioList: ViewList,
    error: Error,
    info: Info,
    success: CheckCircle,
    completed: CheckCircle,
    warning: Warning,
    scheduled: Warning,
    delete: DeleteForever,
    edit: Edit,
    logout: Logout,
    dark: WbIncandescentRounded,
    light: Brightness2Rounded,
    back: ArrowBackIosNew,
    affiliate: Handshake,
    goBack: ArrowBack,
    prev: ArrowBackIosNew,
    next: ArrowForwardIos,
    stop: StopIcon,
    stockdetail: InsertChartIcon,
    add: AddIcon,
    group: GroupIcon,
    create: Create,
    indicator: Dataset,
    openInNew: OpenInNew,
    tip: LightbulbIcon
};

type IconNames = keyof typeof Icons | string;

interface InvflyIconProps extends SvgIconProps {
    name: IconNames;
}

export const InflyIcon = (props: InvflyIconProps) => {
    const { name, ...rest } = props;
    const Icon = Icons[name];
    return Icon ? <Icon {...rest} /> : <>{name}</>;
};
