/** @format */

import React from 'react';
import Highcharts, { SeriesOptionsType } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import BrandLight from 'highcharts/themes/brand-light';
import DarkUnica from 'highcharts/themes/dark-unica';
import Stock from 'highcharts/modules/stock';
import {
    Bar,
    BarInterval,
    DatedValue,
    MarketDataAPIClient,
    Message,
    PerformanceMetrics,
    SymbolSummaryInfo,
    UserError,
} from 'api';
import { InflyMessage } from '../common/InflyMessage';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    FormControl,
    Menu,
    MenuItem,
    PaletteMode,
    Stack,
} from '@mui/material';
import { InflyIcon } from '../common/InflyIcon';
import { SymbolField } from '../common/SymbolField';
import { AppContext } from '../../util/appContext';
import withTheme from 'containers/HOC/WithTheme';

Stock(Highcharts);

Highcharts.setOptions({
    time: {
        timezoneOffset: 240,
        //timezone: 'America/New_York' this does not work
    },
});

interface ChartProps {
    mode: PaletteMode;
    dailyEquityValue: DatedValue[];
    onMessage: (message: Message) => void;
    height?: number
}

interface ChartState {
    chartOptions: Highcharts.Options;

    compareAnchorEl: null | HTMLElement;
    compareSymbol: string[];

    mode: PaletteMode;
    chartVersion: number;
}

class PortfolioChart extends React.Component<ChartProps, ChartState> {
    marketDataApiClient: MarketDataAPIClient;
    chartRef: React.RefObject<any>;

    constructor(props: ChartProps) {
        super(props);

        this.marketDataApiClient = AppContext.getInstance().marketDataApiClient;
        this.chartRef = React.createRef();

        this.state = {
            compareSymbol: [],
            compareAnchorEl: null,
            mode: props.mode,
            chartVersion: 1,
            chartOptions: {
                credits: { enabled: false },
                accessibility: { enabled: false },
                chart: {
                    height: props.height??600,
                    spacingRight: 20,
                },
                title: {
                    text: `Portfolio Performance`,
                    style: {
                        fontSize: '14px',
                    },
                },
                legend: {
                    enabled: true,
                },
                navigator: {
                    // this is the lower mini chart that allows to zoom - https://www.highcharts.com/docs/stock/navigator
                    enabled: false,
                },
            },
        };

        if (this.props.mode == 'light') {
            BrandLight(Highcharts);
        } else {
            DarkUnica(Highcharts);
        }
    }

    componentDidMount() {
        this.setInitialChartState();
    }

    componentDidUpdate( prevProps: Readonly<ChartProps>,  prevState: Readonly<ChartState>,  snapshot?: any  ): void {
        if (this.props.dailyEquityValue.length > prevProps.dailyEquityValue.length) {
            let chart = this.chartRef.current.chart;
            let priceSeries = chart.series[0];
            let chartData = this.props.dailyEquityValue.map( dv => [dv.date.getTime(), dv.value])
            let oldLength = priceSeries.xData.length;
            for (let i = oldLength; i < chartData.length; i++) {
                priceSeries.addPoint(
                    [chartData[i][0], chartData[i][1]],
                    false /* dont update chart */,
                    false /* don't shift */
                );
            }

            chart.redraw();
        }
        if(this.props.dailyEquityValue.length == 0 && prevProps.dailyEquityValue.length > 0){
            this.setInitialChartState();
            let chart = this.chartRef.current.chart;
            chart.redraw();
        }
    }

    static getDerivedStateFromProps(props: ChartProps, state: ChartState) {
        if (props.mode !== state.mode) {
            if (props.mode == 'light') {
                BrandLight(Highcharts);
                Highcharts['_modules']['Extensions/Themes/BrandLight.js'].apply();
            } else {
                DarkUnica(Highcharts);
                Highcharts['_modules']['Extensions/Themes/DarkUnica.js'].apply();
            }
            return {
                mode: props.mode,
                chartVersion: state.chartVersion + 1,
            };
        }

        return null;
    }

    setInitialChartState() {
        let chartData = this.props.dailyEquityValue.map( dv => [dv.date.getTime(), dv.value])

        let currentOptions = this.state.chartOptions;
        currentOptions.plotOptions = {
            series: {
                showInLegend: true, // needed to show legend for compare
            },
        };

        currentOptions.yAxis = {
            height: '100%',
        };

        currentOptions.series = [
            {
                id: 'main-series',
                name: 'Portfolio Performance',
                type: 'line',
                data: chartData,
            },
        ];

        this.setState({
            chartOptions: JSON.parse(JSON.stringify(currentOptions)),
            compareSymbol: [],
            chartVersion: this.state.chartVersion + 1,
        });
    }

    onCompareClick = async (symbol: SymbolSummaryInfo) => {
        this.setState({ compareAnchorEl: null });

        try {
            let compareSymbols = this.state.compareSymbol;
            const compareBars = await this.marketDataApiClient.getHistoricalBars(
                symbol.symbol,
                BarInterval.ONE_DAY
            );
            compareSymbols.push(symbol.symbol);

            let chartOptions = this.state.chartOptions;

            if (compareBars.length === 0) {
                throw new UserError(
                    'Provided symbol does not have any bars to compare. Select different symbol or interval'
                );
            }

            chartOptions.plotOptions = {
                series: {
                    compare: 'percent',
                },
            };

            await this._updateAndSetCompareSeries(compareSymbols, chartOptions);

            this.setState({
                chartVersion: this.state.chartVersion + 1,
                compareSymbol: compareSymbols,
                chartOptions: {
                    ...JSON.parse(JSON.stringify(chartOptions)),
                    yAxis: {
                        // must be set here to resolve "this" correctly
                        labels: {
                            formatter: function () {
                                return (this.value as number > 0 ? ' + ' : '') + this.value + '%';
                            },
                        },
                    },
                },
            });
        } catch (error) {
            this.props.onMessage(Message.fromError(error));
        }
    };

    async _updateAndSetCompareSeries(compareSymbols: string[], chartOptions: Highcharts.Options) {
        // First, we must align the dates between portfolio equity values and bars
        // Secondly, this gets tricky due to backtest usecase (where this chart is also used) where backtest can run with minute bars (for 1-week) or day bars (1-year)
        // So (1) we  have to decide to get either DAILY or MINUTE bars to compare, (2) we can't reset hour:min:sec to 0:0:0 to align

        // Highchart compare works by INDEPENDENTLTY plotting each series's percent change by comparing against the first data point
        // So its just two independent series plotted on the percent y-axis. This means perfect alignment of start date and points
        // between series are not technically required for highchart render to work. However, we should align to make chart appear sensible
        // Without start-point alignment, stock graph would render for 5 years in x-axis, whereas portoflio may render only for few weeks as tiny curve on the right
        // Without point-to-oint alignment, on mouse-over on the chart, the series whose point is absent on the mouse pointer dims in contrast to indicate there is no data point

        let portfolioData: [number, number][] = this.props.dailyEquityValue.map( dv => [dv.date.getTime(), dv.value])
        if (portfolioData.length < 2) {
            console.warn('Portfolio Equity Value has less than 2 data points');
            return;
        }

        // First analyze the intervals in the portoflio equity values to see what interval they have
        let diffSumsAvg = 0;
        let i = 1;
        for (i = 1; i < 10 && i < portfolioData.length; i++) {
            let diff = portfolioData[i][0] - portfolioData[i - 1][0];
            diffSumsAvg += diff;
        }
        diffSumsAvg = diffSumsAvg / i;

        let useDailyBars = false;
        if (diffSumsAvg > 1 * 60 * 60 * 100 /* 1 hour in ms */) {
            useDailyBars = true;
        }

        if (useDailyBars) {
            portfolioData = portfolioData.map((point: [number, number]) => {
                return [this._setHourMinSecToZero(point[0]), point[1]];
            });
        }

        chartOptions.series = [
            {
                id: 'main-series',
                name: 'Portfolio Performance',
                type: 'line',
                data: portfolioData,
            },
        ];

        for (let compareSymbol of compareSymbols) {
            let compareBars = await this.marketDataApiClient.getHistoricalBars(
                compareSymbol,
                useDailyBars? BarInterval.ONE_DAY : BarInterval.ONE_MINUTE
            );
            let stockData: [number, number][] = compareBars.map((bar) => [
                bar.date.getTime(),
                bar.close,
            ]);

            // assume bars start before than portfolio first date and find the first bar that is after the first portfolio date to clip stock data
            let i = 0;
            while (i < stockData.length && stockData[i][0] < portfolioData[0][0]) {
                i++;
            }
            if (i < stockData.length) {
                stockData = stockData.slice(i, stockData.length);
            } else {
                console.error(" Last Bar Date is BEFORE portfolio's first date. Data are disjoint");
            }

            if (useDailyBars) {
                stockData = stockData.map((point: [number, number]) => {
                    return [this._setHourMinSecToZero(point[0]), point[1]];
                });
            }

            chartOptions.series.push({
                id: compareBars[0].symbol,
                name: compareBars[0].symbol,
                type: 'line',
                data: stockData,
            });
        }
    }

    _setHourMinSecToZero(timestamp: number): number {
        let date = new Date(timestamp);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.getTime();
    }

    onCompareChipDelete = (symbol: string) => {
        let compareSymbols = this.state.compareSymbol!;
        let index = compareSymbols.findIndex((s) => s === symbol);
        compareSymbols.splice(index, 1);

        if (compareSymbols.length === 0) {
            this.setInitialChartState();
        } else {
            let chartOptions = this.state.chartOptions;
            chartOptions.series!.splice(index + 1, 1);

            this.setState({ chartOptions: JSON.parse(JSON.stringify(chartOptions)) });
        }
    };

    render() {
        let isCompareMode = this.state.compareSymbol.length > 0;
        let isCompareMenuOpen = Boolean(this.state.compareAnchorEl);

        return (
            <>
                <Stack>
                    <Stack direction='row' spacing={2}>
                        <Button
                            id='basic-button'
                            size={'small'}
                            startIcon={<InflyIcon name={'add'} fontSize={'small'} />}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.preventDefault();
                                this.setState({ compareAnchorEl: event.currentTarget });
                            }}
                        >
                            Compare
                        </Button>

                        <Menu
                            id='basic-menu'
                            anchorEl={this.state.compareAnchorEl}
                            open={isCompareMenuOpen}
                            onClose={() => this.setState({ compareAnchorEl: null })}
                        >
                            <MenuItem>
                                <Box minWidth={500}>
                                    <SymbolField
                                        includeIndex={true}
                                        onClear={() => {}}
                                        onChange={this.onCompareClick}
                                    />
                                </Box>
                            </MenuItem>
                        </Menu>

                        {this.state.compareSymbol &&
                            this.state.compareSymbol.map((symbol) => {
                                return (
                                    <Chip
                                        key={symbol}
                                        label={symbol}
                                        onDelete={(event: any) => this.onCompareChipDelete(symbol)}
                                    />
                                );
                            })}
                    </Stack>

                    <HighchartsReact
                        key={this.state.chartVersion}
                        ref={this.chartRef}
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={this.state.chartOptions}
                    />
                </Stack>
            </>
        );
    }
}

export default withTheme(PortfolioChart);
