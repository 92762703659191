/** @format */

import React, { Component } from 'react';
import { Message, MessageType, UserAPIClient } from 'api';
import withRouter from 'containers/HOC/WithRouter';
import CommonUtil from 'util/CommonUtil';
import { AppContext } from 'util/appContext';
import { RouteProps, RouterAndThemeProps } from 'util/commonProps';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Stack,
    Typography,
} from '@mui/material';
import { DoneAll, ReportGmailerrorred } from '@mui/icons-material';
import DocumentHeader from 'containers/common/DocumentHeader';

interface ConfirmChangeEmailProps extends RouteProps {}

class ConfirmChangeEmail extends Component<ConfirmChangeEmailProps, { message?: Message }> {
    userAPIClient: UserAPIClient;

    constructor(props: ConfirmChangeEmailProps) {
        super(props);
        this.userAPIClient = AppContext.getInstance().userApiClient;
    }

    async componentDidMount() {
        try {
            const email = CommonUtil.getQueryParam(this.props, 'email');
            const resetCode = CommonUtil.getQueryParam(this.props, 'resetcode');
            const username = JSON.parse(localStorage.userSession).username;
            const _message = await this.userAPIClient.confirmEmailChange(
                username,
                email,
                resetCode
            );
            this.setState({ message: Message.success(_message.message) });

            if (_message.type === MessageType.SUCCESS || _message.type === MessageType.INFO) {
                this.props.navigate('/user/setting#change-email');
            } else {
                console.log(_message.message);
            }
        } catch (error) {
            this.setState({ message: Message.fromError(error) });
            console.log(error);
        }
    }

    render() {
        const message = this.state?.message;
        return (
            <DocumentHeader title={'Confirm Email Change'}>
                {message && (
                    <Box p={'100px'}>
                        <Card>
                            <CardHeader title={'Change Email'} />
                            <CardContent>
                                <Stack
                                    alignItems='center'
                                    height='200px'
                                    justifyContent='space-between'
                                >
                                    {!message.isError() && <DoneAll fontSize={'large'} />}
                                    {message.isError() && (
                                        <ReportGmailerrorred fontSize={'large'} />
                                    )}
                                    <Typography
                                        variant={'h6'}
                                        color={CommonUtil.getTypographyColor(message)}
                                    >
                                        {message.message ??
                                            'Unexpected error occurred. Please contact us'}
                                    </Typography>
                                    <CardActions>
                                        <Button
                                            size='small'
                                            onClick={(event: any) => {
                                                event.preventDefault();
                                                this.props.navigate('/');
                                            }}
                                        >
                                            Go Home
                                        </Button>
                                    </CardActions>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Box>
                )}
            </DocumentHeader>
        );
    }
}

export default withRouter(ConfirmChangeEmail);
