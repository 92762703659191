/** @format */

import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Indicators from 'highcharts/indicators/indicators';
import AllIndicators from 'highcharts/indicators/indicators-all';
import HeiksenAshi from 'highcharts/modules/heikinashi';
import BrandLight from 'highcharts/themes/brand-light';
import DarkUnica from 'highcharts/themes/dark-unica';
import Stock from 'highcharts/modules/stock';
import {
    Box,
    Button,
    Chip,
    IconButton,
    Menu,
    MenuItem,
    PaletteMode,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from '@mui/material';
import {
    Bar,
    BarInterval,
    SymbolSummaryInfo,
} from 'api';
import { AppContext } from 'util/appContext';
import {SelectField, SelectOption} from 'containers/common/SelectField';
import { SymbolField } from '../common/SymbolField';

import { InflyIcon } from '../common/InflyIcon';


import { ChartState, ChartType, ZoomSettings } from './ChartState';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CommonUtil from 'util/CommonUtil';


Indicators(Highcharts);
AllIndicators(Highcharts);
HeiksenAshi(Highcharts);
Stock(Highcharts);
DarkUnica(Highcharts);


// Highcharts.setOptions({
//     time: {
//         timezoneOffset: 240,
//         //timezone: 'America/New_York' this does not work
//     },
// });

interface ChartProps {
    mode: PaletteMode;
    symbol: string;
    zoomSettings?: ZoomSettings;
    chartType?: ChartType
    allowFullScreen: boolean

}


export default function StockChart({symbol, zoomSettings, chartType, allowFullScreen}: ChartProps): React.JSX.Element {

    const chartRef: React.RefObject<any> = useRef(null);
    const [chartState, setChartState] = useState<ChartState>(ChartState.createDefault(symbol,  zoomSettings, chartType));

    // ChartState.compareMode and this are slighly different. This determines if the SymbolField is displayed in Menu
    // So when the  chart is in compare mode, but the SymbolField Menu is closed (i.e this is null)
    const [compareAnchorEl, setCompareAnchorEl] = useState<Element|null>(null);

    // After compare mode exited, y-axis labels were still showing percentage, this was a hack to revert to original y-axis
    const [chartVersion, setChartVersion] = useState(0);

    const timerRef = useRef(0);
    // this is required because the chartstate that is access in the timer call back handler is the state from closure, which stale
    let chartStateRef = useRef<ChartState>(chartState);
    useEffect(() => {
        chartStateRef.current = chartState; 
    }, [chartState])

    async function loadBars(){
        let newState = await chartState.loadBarsAndUpdateChartSeries()
        setChartState(newState);
    }

    useEffect(() => {
        let newstate = chartState.updateSymbol(symbol);
        setChartState(newstate);
        loadBars();
        startRealTimeRefresh();

        return () => {
            if(timerRef.current > 0){
                clearInterval(timerRef.current);
                timerRef.current = 0;
            }
        }

    }, [symbol])


    async function updateIntradayChart(){

        let chartState = chartStateRef.current;
        
        if (chartState.zoomSettings == ZoomSettings.ONE_DAY && !chartState.isCompareMode) {
            //console.log("adding new data point to the chart");
            const marketDataApiClient = AppContext.getInstance().marketDataApiClient;
            let chart = chartRef.current.chart;
            let priceSeries = chart.series[0];
            let volumeSeries = chart.series[1];
            // This is little complex because we append dummy values until market close time for intraday chart to match Yahoo finance intraday chart
            // so appending new data point means inserting into the middle, and possibly removing the next dummy point
            let index: number = priceSeries.yData.length-1;
            if(chartState.chartType == ChartType.LINE){
                while(index >= 0 && priceSeries.yData[index] == null){
                    index--;
                }
            }else{
                while(index >= 0 && priceSeries.yData[index][0] == null){
                    index--;
                }
            } 
            //console.log("Index="+index);
            let lastValidDate = priceSeries.xData[index];
            let firstNullDate = priceSeries.xData[index+1];
            //console.log("LastValidDate="+new Date(lastValidDate));
            //console.log("FirstNullDate="+new Date(firstNullDate));
            
            let addedDate: Date|null = null;

            if (chartState.chartType == ChartType.LINE) {
                let stockQuote = await marketDataApiClient.getStockQuote(chartState.symbol);
                let x = stockQuote.date.getTime();
                let y = stockQuote.lastPrice;

                //console.log("QuoteDate="+ stockQuote.date);
                //console.log(stockQuote);

                if (stockQuote.date.getTime() > lastValidDate) {
                    //console.log("Appending point");
                    // Note that we don't specify index to insert point, but x-value which is date, and it inserts the date at the right position
                    // When LastValidDate < QuoteDate < FirstNullDate, then it inserts it at the next NULL position
                    // However, when LastValidDate < FirstNullDate < QuoteDate, it inserts after one or more NULL position, causing a hole
                    // [10, 11, null, null, 13, null, null]
                    // Example, minute bars were loaded up to: 10AM, and the quote recived at 3PM
                    // The null is then removed below, when we call removePoint(index+1) 
                    priceSeries.addPoint([x, y], false /* update chart*/, false /* don't shift */);
                    //console.log(priceSeries.yData.slice(index-10, index+10));

                    // adding 10-sec quote interval volume does not make sense. Besides we also need to track previous sum to compute volume delta

                    addedDate = stockQuote.date;       
                }
            } else {

                let now = new Date();
                let seconds = now.getSeconds();
                //console.log("seconds = " + seconds);
                if(seconds > 10 && seconds < 20){
                    // since this runs each 10 seconds, there no need to poll each 10 seconds for new minute bar
                    let lastBar: Bar[] = await marketDataApiClient.getHistoricalBars( chartState.symbol, BarInterval.ONE_MINUTE,  1 );
                    //console.log("Bars Lenth = " + lastBar.length);
                    if (lastBar.length === 1) {
                        //console.log("LastBarTIme = " + lastBar[0].date);
                        if (lastBar[0].date.getTime() > lastValidDate) {
                            //console.log("Add candlebar");
                            priceSeries.addPoint(
                                [
                                    lastBar[0].date.getTime(),
                                    lastBar[0].open,
                                    lastBar[0].high,
                                    lastBar[0].low,
                                    lastBar[0].close,
                                ], false,  false );
    
                            volumeSeries.addPoint(   [lastBar[0].date.getTime(), lastBar[0].volume], false,   false );
                            addedDate = lastBar[0].date;
                        }
                    }
                }
            }

            if(addedDate){
                    // Also remove the dummy point if the quote date is after the dummy date
                    index = index + 1; // start with first null date
                    // while loop required because there can be multiple NULL values between the last good data point and the new one that is added if the new quote date is many minutes after the latest good min bar date
                    while(index < priceSeries.yData.length && addedDate.getTime() >=  priceSeries.xData[index]){    
                        let toRemove = chartState.chartType == ChartType.LINE ?  priceSeries.yData[index] == null : priceSeries.yData[index][0] == null;
                        if(toRemove){ // needed to avoid removing the datapoint that was just added 
                            priceSeries.removePoint(index); // removing point shifts remaining array left, so its like doing index++

                            if(chartState.chartType != ChartType.LINE){
                                volumeSeries.removePoint(index);
                            }
                            
                        }else{
                            break; // required to prevent infinite loop, we could do index++.
                        }
                    }
                    chart.redraw();
            }

            //console.log(priceSeries.yData.slice(index-10, index+10));

            //console.log("------------");
        }
    }

    function startRealTimeRefresh(){
        if(timerRef.current == 0){       
            console.log("starting realtime refresh");     
            let intervalId = setInterval(updateIntradayChart, 10000);
            timerRef.current = intervalId as any as number;
        }
    }

    async function onZoomSettingsChange(event: React.MouseEvent<HTMLElement>,  zoomStr: string) {
        let zoomSettings:ZoomSettings = ZoomSettings[CommonUtil.getEnumKeyByEnumValue(ZoomSettings, zoomStr)!];
        let newState = await chartState.updateZoomSetttings(zoomSettings);
        setChartState(newState);
    }

    function onChartTypeChange(selectOption: SelectOption){
        let newState = chartState.updateChartType(selectOption.value);
        setChartState(newState);
    }

    async function onCompareClick(symbol: SymbolSummaryInfo){
        setCompareAnchorEl(null)
        let newState = await chartState.addCompareSymbol(symbol.symbol);
        setChartState(newState);
    }

    function onCompareSymbolRemove(symbol: string){
        let newState = chartState.removeCompareSymbol(symbol);
        if(newState.isCompareMode == false){
            setChartVersion(chartVersion+1);
        }
        setChartState(newState);
    }



    const chartTypeOptions: SelectOption[] = [{label: 'Line Chart', value: "line"}, {label: 'Candlestick', value: 'candlestick'},  {label: 'Heikin-Ashi', value: 'heikinashi'}]
    return <Stack gap={1} id="stockchart-box">
        <br/>

        <Stack direction={'row'} justifyContent={'space-between'}>

        <Stack direction={'row'} gap={2} alignItems={'center'}>
     


            <ToggleButtonGroup size='small' exclusive value={chartState.zoomSettings} onChange={onZoomSettingsChange}>
                {
                    Object.entries(ZoomSettings).map(([key, value]) => {
                        return <ToggleButton key={key} value={value} >{value}</ToggleButton>
                    })
                }
            </ToggleButtonGroup>

            <SelectField label='Chart Type' disabled={chartState.isCompareMode} options={chartTypeOptions} value={chartState.chartType} onSelectChange={onChartTypeChange}></SelectField>

            <Button  id='basic-button' size={'small'}   
                    startIcon={  <InflyIcon name={'add'} fontSize={'small'} />   }
                    onClick={(  event: React.MouseEvent<HTMLButtonElement> ) => {event.preventDefault(); setCompareAnchorEl(event.currentTarget);   }}>
                    Compare
            </Button>
            <Menu id='basic-menu' anchorEl={compareAnchorEl}  open={compareAnchorEl != null} onClose={() => setCompareAnchorEl(null)} >
                <MenuItem>
                    <Box minWidth={500}>
                        <SymbolField
                            includeIndex={true}
                            onClear={() => {}}
                            onChange={onCompareClick}
                        />
                    </Box>
                </MenuItem>
            </Menu>

            {Array.from(chartState.compareSymbols.keys()).map( symbol => {
                return <Chip key={symbol} label={symbol} onDelete={() => onCompareSymbolRemove(symbol)} />
            })}
        </Stack>

        {allowFullScreen &&
            <Tooltip  title={'Expand Chart'}>
                <IconButton href={`/chart/${symbol}?zoom=${chartState.zoomSettings}&charttype=${chartState.chartType}`}>
                    <InflyIcon name="openInNew" />
                    </IconButton>  
            </Tooltip>

       
        }


        </Stack>

        
        <HighchartsReact
            key={chartVersion}
            ref={chartRef}
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={chartState.chartOptions}
        />

    </Stack>

}
