/** @format */

import React, { Component } from 'react';
import { UserGameScore } from 'api';
import {
    Avatar,
    Badge,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import  { SelectOption, SelectField } from 'containers/common/SelectField';
import A from 'containers/common/Anchor';
import { v4 as uuid4 } from 'uuid';
import CommonUtil from '../../util/CommonUtil';
import { ScreenSizeProp } from 'util/commonProps';
import withScreenSize from 'containers/HOC/WithScreenSize';

interface LeaderboardState {
    winners: UserGameScore[];
    currentDate: string;
}

export interface GameWinnersBoard {
    startToEndDate: string;
    selected: boolean;
    winners: UserGameScore[];
}

interface LeaderboardProps extends ScreenSizeProp {
    leaderboard?: GameWinnersBoard[];
}

class Leaderboard extends Component<LeaderboardProps, LeaderboardState> {
    constructor(props: LeaderboardProps) {
        super(props);
        this.state = { winners: [], currentDate: undefined! };
    }

    onChange = (event: any) => {
        const value = event.target.value;
        if (this.props.leaderboard) {
            const filteredLeaderboard = this.filteredLeaderboard(value);
            if (filteredLeaderboard && filteredLeaderboard?.length > 0) {
                this.setState({
                    winners: filteredLeaderboard[0]?.winners,
                    currentDate: event.target.value,
                });
            }
        }
    };

    filteredLeaderboard = (value: any) => {
        return this.props.leaderboard?.filter((lb) => value === lb.startToEndDate);
    };

    getWinnerOnLeaderboard = () => {
        const { winners } = this.state;
        const { leaderboard } = this.props;

        let WinnerOnLeaderboard: UserGameScore[] = [];
        if (leaderboard && typeof leaderboard[leaderboard.length - 1] !== 'undefined') {
            WinnerOnLeaderboard = leaderboard[leaderboard.length - 1].winners;
        }
        if (winners.length > 0) {
            WinnerOnLeaderboard = winners;
        }
        return WinnerOnLeaderboard;
    };

    render() {
        let leaderboardOption = this.getLeaderboardOption();

        return (
            <>
                <Card>
                    <CardHeader
                        title={<>Leaderboard</>}
                        action={
                            !this.props.isMobile && (
                                <SelectField
                                    value={this.state.currentDate}
                                    options={leaderboardOption}
                                    onChange={this.onChange}
                                />
                            )
                        }
                    />
                    {this.props.isMobile && (
                        <SelectField
                            value={this.state.currentDate}
                            options={leaderboardOption}
                            onChange={this.onChange}
                        />
                    )}
                    <Divider />
                    {this.getWinnerOnLeaderboard().length === 0 && (
                        <Typography align={'center'}>Theres' no Winner</Typography>
                    )}
                    <CardContent>
                        {this.getWinnerOnLeaderboard().map((winner, index) => (
                            <Grid
                                container
                                wrap='nowrap'
                                spacing={2}
                                key={uuid4() + index + winner.username}
                            >
                                <Grid item>
                                    <Badge
                                        badgeContent={++index}
                                        color={'primary'}
                                        overlap={'circular'}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                        <Avatar>
                                            {winner.firstName[0]}
                                            <small>{winner.lastName[0]}</small>
                                        </Avatar>
                                    </Badge>
                                </Grid>
                                <Grid item xs>
                                    <Typography variant={'body1'}>
                                        <A
                                            href={`/user/profile/${winner.username}`}
                                        >{`${winner.firstName} ${winner.lastName}`}</A>
                                    </Typography>
                                    <Typography
                                        variant={'caption'}
                                        color={'textSecondary'}
                                        component={'span'}
                                    >
                                        {CommonUtil.numberWithCommas(
                                            winner.totalGamePoints as number
                                        )}
                                        &nbsp;Points
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </CardContent>
                </Card>
            </>
        );
    }

    private getLeaderboardOption() {
        const { leaderboard } = this.props;
        let leaderboardOption: SelectOption[] = [];
        leaderboard?.forEach((lb) =>
            leaderboardOption.push({ label: lb.startToEndDate, value: lb.startToEndDate })
        );
        return leaderboardOption;
    }
}

export default withScreenSize(Leaderboard);
