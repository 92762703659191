import {PortfolioSpec} from "./portfolioSpec";

export enum BrokerType {
    TRADIER = "TRADIER",
    INVESTFLY = "INVESTFLY",
    TDAMERITRADE = "TDAMERITRADE",
    TRADESTATION = "TRADESTATION",
    ETRADE = "ETRADE"
}

export interface PortfolioIdentifier {
    portfolioId: string,
    brokerType: BrokerType
}

export interface PortfolioIdentifierName extends PortfolioIdentifier {
    name: string;
}


export class Portfolio extends PortfolioSpec{
    broker: BrokerType = BrokerType.INVESTFLY;
    dateCreated?: Date;

    cashBalance: number;
    cashUsable: number;

    currentValue: number;
    percentChange: number;
    investedAmount: number;
    buyingPower: number;
    marginRequirement: number;
    optionBuyingPower: number;

    unsettledFunds: number;


    public static  parseJSON(obj: {[index:string]: any} ): Portfolio {
        let portSpec = PortfolioSpec.parseJSON(obj);
        let portfolio: Portfolio = new Portfolio();
        Object.assign(portfolio, portSpec);
        return portfolio;
    }

    toPortfolioSpec(): PortfolioSpec {
        const spec: PortfolioSpec = new PortfolioSpec();

        spec.portfolioId = this.portfolioId;
        spec.name = this.name;
        spec.userName  = this.userName;
        spec.description = this.description;
        spec.initialAmount = this.initialAmount;
        spec.visibility = this.visibility;
        return spec;

    }

    toPortfolioIdentifier(): PortfolioIdentifier {
        return   {portfolioId: this.portfolioId, brokerType: this.broker};
    }

    toPortfolioIdentifierName(): PortfolioIdentifierName {
        return   {portfolioId: this.portfolioId, brokerType: this.broker, name: this.name};
    }

    toPortfolioIdentifierStr(): string {
        return `${this.portfolioId}_${this.broker}`;
    }

}