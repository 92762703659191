import React from 'react';
import {Link, LinkProps,Theme} from '@mui/material';
import {SxProps} from '@mui/system';
interface AnchorProps {
    newTab?: boolean;    sx?: SxProps<Theme>;
}

const A = (props: (AnchorProps & LinkProps)) => {
const{ href, children, newTab, ...rest } = props


    if (newTab === true || href?.startsWith("http")) {
        return <Link href={href} underline={'none'} target="_blank" rel="noopener noreferrer" {...rest}>{children}</Link>;
    }

    return <Link href={href} underline={'none'} {...rest}>{children}</Link>
}

export default A;