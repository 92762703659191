import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { ScreenSizeProp } from 'util/commonProps';



const withScreenSize = <P extends object>(Component: React.ComponentType<P>) => {
    return (props:  Omit<P, keyof ScreenSizeProp>) => {
        const isXS = useMediaQuery('(min-width:0px)');
        const isSM = useMediaQuery('(min-width:600px)');
        const isMD = useMediaQuery('(min-width:900px)');
        const isLG = useMediaQuery('(min-width:1200px)');
        const isXL = useMediaQuery('(min-width:1536px)');

        const isMobile = isXS && !isMD;

        return <Component isMobile={isMobile} {...props as P}/>;
    };
}

export default withScreenSize;