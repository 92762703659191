/** @format */

import React, { Component } from 'react';
import { Box, Button, DialogContent, FormControl, FormLabel, TextField } from '@mui/material';
import { ClassAPIClient, Message, MessageType, ProfessorClass } from 'api';
import { InflyMessage } from 'containers/common/InflyMessage';
import InflyDialog from 'containers/common/InflyDialog';
import { AppContext } from 'util/appContext';

interface CreateClassOrGameProps {
    label: string;
    onReturn: (clazz?: ProfessorClass) => void;
}

interface ProfessorClassFormState {
    className?: string;
    message?: Message;
    open: boolean;
}

export default class AddClassBtn extends Component<
    CreateClassOrGameProps,
    ProfessorClassFormState
> {
    classAPIClient: ClassAPIClient;

    constructor(props: CreateClassOrGameProps) {
        super(props);
        this.classAPIClient = AppContext.getInstance().classApiClient;
        this.state = { open: false };
    }

    onCancel = () => {
        this.setState({ open: false });
        this.props.onReturn(undefined);
    };

    onSubmit = async () => {
        const professorClass = await this.classAPIClient.createClass(this.state.className!);
        this.props.onReturn(professorClass);
    };

    render() {
        return (
            <>
                {this.state.open && (
                    <InflyDialog
                        id={'newClass'}
                        open={this.state.open}
                        title={`Create New Class`}
                        onClose={this.onCancel}
                        okText={'Create'}
                        maxWidth={false}
                        onExecute={this.onSubmit}
                    >
                        <DialogContent>
                            <Box component={'div'} width={530} p={1}>
                                <InflyMessage
                                    message={this.state.message}
                                    onClose={() => this.setState({ message: undefined })}
                                />

                                <FormControl fullWidth component='fieldset'>
                                    <FormLabel component='legend' required>
                                        {' '}
                                        Class Name
                                    </FormLabel>
                                    <TextField
                                        size={'small'}
                                        fullWidth
                                        name={'className'}
                                        onChange={(event: any) =>
                                            this.setState({ className: event.target.value })
                                        }
                                        value={this.state.className}
                                    />
                                </FormControl>
                            </Box>
                        </DialogContent>
                    </InflyDialog>
                )}

                <Button sx={{justifyContent: "center"}}
                    onClick={(event: any) => {
                        event.preventDefault();
                        this.setState({ open: true });
                    }}
                >
                    {this.props.label}
                </Button>
            </>
        );
    }
}
